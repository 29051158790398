import * as React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import SubScribeEndIco from '../../assets/imgs/re-subscribe.svg';
import "./SubscribeEnd";
export const SubscribeEnd: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>End Subscribe</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="text-center mt-5">
                <div className={'my-4'}>
                    <img src={SubScribeEndIco} width={"250px"} />
                </div>
                <div className="not-found-container mx-auto">
                    <h4 className="not-found-header" style={{ fontSize: '30px' }}>Sorry, The subscription has expired</h4>
                    <p className="sub-header" style={{ fontSize: '17px' }}>
                        We are sorry, The subscription has expired, Renew your subscription to use our services
                    </p>
                    <NavLink to="/" className="btn btn-primary mt-2">
                        Go back home
                    </NavLink>
                </div>
            </div>
        </>
    );
};
