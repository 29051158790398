import Axios from "axios";
import {objectToFormData} from "../util/objectToFormData";
import {MediaFile} from "./common/MediaFile";
import {PaginatedRequest} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface ICountry {
  id: number;
  nameEn: string;
  nameAr: string;
  isoAlpha2: string;
  isoAlpha3: string;
  key: string;
}
export interface ICity {
  id: number,
  nameEn: string,
  nameAr: string,
}
export interface Location {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  banner: {
    fileName: string;
    contentType: string;
    fullUrl: string;
    sizeInBytes: number;
  };
  status: number;
}

export interface IAddEditLocation{
  name: string;
  country: number;
  cityId: number;
  cityArea: string;
  address: string;
  points: {
    longitude: string;
    latitude: string;
  };
  description: string;
  isActive: boolean;
  media: File | null;
}


export interface Ticket extends Location {
  startsAfter: Ticket;
  startDateTime: string;
  endDateTime: string;
}

export interface Price {
  displayPrice: string;
  displayTotalPrice: string;
  amount: number;
  taxesId: Array<number>;
  totalPrice?: number;
}
export interface LocationCategory {
  id: number;
  index?: number | null;
  name: string;
  pcTypeId : number;
  pcType: string;
  type: number;
  changeable: boolean;
}

export interface GetTicketsRequest extends PaginatedRequest {
  eventId: number;
}

export const deleteLocationById = async (id: number) => {
  return (await Axios.delete(`/api/Locations/${id}`)).data;
};

export interface ILocationDropdownItem {
  id: number;
  name: string;
}


export const AddLocation = async (payload: IAddEditLocation): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  // data.images?.forEach((image) => {
  //   formData.append("images", image);
  // });
  const response = await Axios.post(
      "api/Locations",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
  );
  return response.data;
};

export const EditLocation = async (locationId: number, payload: IAddEditLocation): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  const response = await Axios.put(
      "api/Locations/?id="+locationId,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
  );
  return response.data;
};

export const GetLocation = async (locationId: number): Promise<any> => {
  const response = await Axios.get(
      "api/Locations/"+locationId,
  );
  return response.data;
};

export const GetLocations = async (request?:PaginatedRequest): Promise<PaginatedResult<
    Location
    >> => {
  const response = await Axios.get<PaginatedResult<Location>>(
      "api/Locations",
    {params:request}
  );
  return response.data;
};

export const GetCountriesDropDownList = async (): Promise<ICountry[]> => {
  const response = await Axios.get<ICountry[]>(
    "api/Countries/DropdownList"
  );
  return response.data;
};

export const GetCitiesOfCountry = async (countryId: number) : Promise<ICity[]> => {
  const response = await Axios.get<ICountry[]>(
      "api/Cities/DropdownList?CountryId="+countryId
  );
  return response.data;
}


export const GetLocationsDropdownList = async (text?:string): Promise<Array<
    ILocationDropdownItem
    >> => {
  const response = await Axios.get<Array<ILocationDropdownItem>>(
      "api/Locations/Search",{params:{text}}
  );
  return response.data;
};

export interface ILocationImage {
  LocationImageId: number;
  mediaDto: MediaFile;
}

export const GetLocationImages = async (
  LocationId: number
): Promise<ILocationImage[]> => {
  const response = await Axios.get(`api/Locations/${LocationId}/Images`);
  return response.data;
};

