import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { PaginatedResult } from "./common/PaginatedResult";
import { PaginatedRequest } from "./common/PaginatedRequest";

export interface News {
    id: number;
    name: string;
    shortDescription: string;
    description: string;
    banner: {
        fileName: string;
        contentType: string;
        fullUrl: string;
        sizeInBytes: number;
    };
    status: number;
}

export interface IAddEditPost{
    pTypeId: number;
    authorsIds: any[];
    IsCurrentUserIncluded: boolean;
    postCategoryId: number;
    title: string;
    authorId?: number;
    content?: string;
    isDraft: boolean;
    tags: number[];
    media: any; // You can replace 'any' with the specific type of the 'Media' array if known
    postDateTime?:string;

}

export interface PostType {
    id: number;
    name: string;
}

export interface IPost {
    id: number;
    pType: {
        id: number;
        name: string;
    };
    postCategory: {
        id: number;
        pType: number;
        name: string;
    };
    title: string;
    postTime: string;
    status: {
        id: number;
        name: string;
    };
    authors: {
        id: number;
        email: string;
        displayName: string;
    }[];
    commentsCount: number;
    likesCount: number;
    currentUserLike: boolean;
    media: {
        fileName: string;
        contentType: string;
        fullUrl: string;
        sizeInBytes: number;
    };
    tags: {
        id: number;
        pType: number;
        name: string;
    }[];
    content: string | null;
}

export const GetPost = async (postId: number) : Promise<IPost> => {
    const response = await Axios.get(
        "api/Posts/"+postId,
    );
    return response.data;

};

export const AddPost = async (payload: IAddEditPost): Promise<any> => {
    const formData = objectToFormData(payload);
    const response = await Axios.post(
        "api/Posts",
        formData,
        {
            headers: { "Content-Type": "multipart/form-data" },
        }
    );
    return response.data;
};

export const EditPost = async (postId: number, payload: IAddEditPost): Promise<any> => {
    const formData = objectToFormData(payload);
    const response = await Axios.put(
        `api/Posts/${postId}`,
        formData,
        {
            headers: { "Content-Type": "multipart/form-data" },
        }
    );
    return response.data;
};

export const ChangePostsStatus = async (postId: number, statusId: number): Promise<PostType[]> => {
    const response = await Axios.put(
        `api/Posts/ChangePostStatus/${postId}`,
        {StatusId: statusId},
        {
            headers: { "Content-Type": "application/json" },
        }
    );
    return response.data;
};

export const ChangePostPin = async (post: any): Promise<void> => {
    const response = await Axios.put(
        `api/Posts/ChangePostPin/${post.id}`,
        {isPinned: !post.isPinned},
        {
            headers: { "Content-Type": "application/json" },
        }
    );
    return response.data;
};

export const deletePost = async (postId: number)=>{
    const response = await Axios.delete(
      `api/Posts/${postId}`,
      {
          headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;

}

export const GetPostsByCategory = async (pTypeId: number,request?:PaginatedRequest): Promise<PaginatedResult<IPost>> => {
    const response = await Axios.get(
        "api/Posts?PTypeId="+pTypeId,
      {params:request}
    );
    return response.data;
};

export const GetPostsStatus = async (): Promise<PostType[]> => {
    const response = await Axios.get(
        "api/Posts/PublishStatus",
    );
    return response.data;
};