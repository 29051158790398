import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { reorder } from "util/reorder";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import {
    getSponsorGroups,
    SponsorGroup,
    SponsorGroupsRequest
} from "../api/SponsorGroups";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import { NormalizedPagination } from "./NormalizedPagination";
import { sponsorGroupsListSchema } from "./Schemas";

export interface SponsorGroupsState {
    [key: string]: SponsorGroup | {};

    pagination: {
        //TODO: exclude or includes sponsors
        byEventId: NormalizedPagination & {
            [key: string]: { reorderIds?: string[] };
        };
        byEventForSelectListQuery: NormalizedPagination;
    };
}

const initialState: SponsorGroupsState = {
    pagination: { byEventId: {}, byEventForSelectListQuery: {} }
};

interface RequestSponsorGroupsPaginationAction {
    eventId: number;
}

interface ReceiveSponsorGroupsPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

interface ReorderSponsorGroupLocalAction {
    eventId: number;
    currentIndex: number;
    newIndex: number;
}

const sponsorGroupsSlice = createSlice({
    name: "sponsorGroups",
    initialState,
    reducers: {
        requestSponsorGroupsPaginationByEventId(
            state,
            action: PayloadAction<RequestSponsorGroupsPaginationAction>
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    isFetching: true
                }
            };
        },
        requestSelectListQuerySponsorGroups(
            state,
            action: PayloadAction<RequestSponsorGroupsPaginationAction>
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventForSelectListQuery = {
                ...state.pagination.byEventForSelectListQuery,
                [eventId.toString()]: {
                    ...state.pagination.byEventForSelectListQuery[
                        eventId.toString()
                    ],
                    isFetching: true
                }
            };
        },
        receiveSponsorGroupsPaginationByEventId(
            state,
            action: PayloadAction<ReceiveSponsorGroupsPaginationAction>
        ) {
            const { eventId, ...rest } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    ...action.payload,
                    reorderIds: action.payload.ids,
                    isFetching: false
                }
            };
        },
        receiveSelectListQuerySponsorGroups(
            state,
            action: PayloadAction<ReceiveSponsorGroupsPaginationAction>
        ) {
            const { eventId, ...rest } = action.payload;
            state.pagination.byEventForSelectListQuery = {
                ...state.pagination.byEventForSelectListQuery,
                [eventId.toString()]: {
                    ...state.pagination.byEventForSelectListQuery[
                        eventId.toString()
                    ],
                    ...action.payload,
                    isFetching: false
                }
            };
        },
        reorderSponsorGroupsByEventIdLocal(
            state,
            action: PayloadAction<ReorderSponsorGroupLocalAction>
        ) {
            const oldIds = state.pagination.byEventId[action.payload.eventId.toString()]?.reorderIds;
            if (!oldIds) return;

            state.pagination.byEventId[
                action.payload.eventId.toString()
            ].reorderIds = reorder(
                oldIds,
                action.payload.currentIndex,
                action.payload.newIndex
            );
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["sponsorGroups"]
            };
        })
    })
});

export default sponsorGroupsSlice.reducer;

const {
    requestSponsorGroupsPaginationByEventId,
    receiveSponsorGroupsPaginationByEventId,
    reorderSponsorGroupsByEventIdLocal,

    requestSelectListQuerySponsorGroups,
    receiveSelectListQuerySponsorGroups
} = sponsorGroupsSlice.actions;

export { reorderSponsorGroupsByEventIdLocal };
export const fetchPaginatedSponsorGroupsByEventId = (
    eventId: number,
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestSponsorGroupsPaginationByEventId({ eventId }));
        const result = await getSponsorGroups(eventId, {
            ...request,
            excludes: ["Sponsors"]
        });

        const normalized = normalize(result.items, sponsorGroupsListSchema);

        dispatch(addEntities(normalized.entities as any));
        dispatch(
            receiveSponsorGroupsPaginationByEventId({
                eventId,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveSponsorGroupsPaginationByEventId({
                eventId,
                // error: e.toString(),
                ids: getState().sponsorGroups.pagination.byEventId[
                    eventId.toString()
                ]?.ids,
                pageIndex: getState().sponsorGroups.pagination.byEventId[
                    eventId.toString()
                ]?.pageIndex,
                pageSize: getState().sponsorGroups.pagination.byEventId[
                    eventId.toString()
                ]?.pageSize,
                totalItemsCount: getState().sponsorGroups.pagination.byEventId[
                    eventId.toString()
                ]?.totalItemsCount
            })
        );
    }
};

export const fetchSponsorGroupSelectList = (
    eventId: number,
    request: PaginatedRequest & SponsorGroupsRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestSelectListQuerySponsorGroups({ eventId }));
        const result = await getSponsorGroups(eventId, {
            ...request,
            excludes: ["Sponsors"]
        });

        const normalized = normalize(result.items, sponsorGroupsListSchema);
        dispatch(addEntities(normalized.entities as any));
        dispatch(
            receiveSelectListQuerySponsorGroups({
                eventId,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveSelectListQuerySponsorGroups({
                eventId,
                // error: e.toString(),
                ...getState().sponsorGroups.pagination
                    .byEventForSelectListQuery[eventId.toString()]
            })
        );
    }
};
