import Axios from "axios";
import { Moment } from "moment";
import { PaginatedRequestWithFilter } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface IMembershipAreaSession {
  id: number;
  sessionNumber: string;
  createdDate: Moment;
  userFullName: string;
  checkedIn: boolean | null;
  location?: string;
  status?: string
}

export enum SessionTicketStatus {
  Unchecked = 0,
  Checked = 1,
  Canceled = 2
}

export interface ChekinStatus {
  id: number;
  name: string;
}

export interface CheckinData {
  securityStamp?: string;
  userId?: number;
  orderProductId?: number;
  comment?: string;
}


export const getAllAttendencees = async (
  request: PaginatedRequestWithFilter
): Promise<PaginatedResult<IMembershipAreaSession>> => {
  const response = await Axios.get(
    `api/UserCheckins/Attendance`,
    {
      params: request,
    }
  );

  return response.data;
};

export const getCheckinsStatus = async (): Promise<ChekinStatus[]> => {
  const response = await Axios.get(
    `api/UserCheckins/CheckinsStatus`,
  );

  return response.data;
};

export const getAllSessions = async (
  request: PaginatedRequestWithFilter
): Promise<PaginatedResult<IMembershipAreaSession>> => {
  const response = await Axios.get(
    `api/UserCheckins/Sessions`,
    {
      params: request,
    }
  );

  return response.data;
};

export const getExportData = async (
  request: PaginatedRequestWithFilter
): Promise<string> => {
  const response = await Axios.get(
    `/api/UserCheckins/AttendanceExport`,
    {
      params: request,
    }
  );

  return response.data;
};

export interface ISessionBarCode {
  barCode: string;
}

export const getSessionBarCode = async (
  sessionId: number
): Promise<ISessionBarCode> => {
  const response = await Axios.get(
    `api/MembershipAreaSessions/${sessionId}/GetBarCode`
  );
  return response.data;
};

export interface ISessionNote {
  note: string;
}

export const getSessionNote = async (
  sessionId: number
): Promise<ISessionNote> => {
  const response = await Axios.get(
    `api/MembershipAreaSessions/${sessionId}/Note`
  );
  return response.data;
};

export interface IUpdateSessionNote extends ISessionNote {
  sessionId: number;
}

export const updateSessionNote = async (
  request: IUpdateSessionNote
): Promise<any> => {
  const response = await Axios.post(
    `api/MembershipAreaSessions/UpdateNote`,
    request
  );
  return response.data;
};

export const toggleSessions = async (sessionId: number, statusId: number): Promise<any> => {
  const response = await Axios.put(
    `api/UserCheckins/ChangeStatus/${sessionId}`, { statusId }
  );
  return response.data;
};

export const checkinSession = async (sessionId: number): Promise<any> => {
  const response = await Axios.put(
    `api/UserCheckins/DoSessionCheckin/${sessionId}`
  );
  return response.data;
};

export const checkIn = async (data: CheckinData): Promise<any> => {
  const response = await Axios.post(
    `api/UserCheckins/DoCheckin`, data
  );
  return response.data;
};
