import {MainCard} from "components/MainCard";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Helmet from "react-helmet";
import {FaEdit, FaPlus, FaSpinner, FaTrash} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {
    Button, CardBody,
    CardHeader, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import {ManageEventParams} from "scenes/ManageEventParams";
import {ManageSponsors} from "scenes/ManageEventSponsors/ManageSponsors";
import {AddGlobalSponsorFormModal} from "./AddGlobalSponsorFormModal";
import {deleteSponsor, getGlobalSponsors} from "../../api/GlobalSponsors";
import {Loader} from "../../components/Loader";
import {DataTable} from "../../components/DataTable";
import {EmptyListState} from "../../components/EmptyListState";
import EmptyImg from "../../assets/imgs/re-subscribe.svg";
import {CellProps} from "react-table";
import {Product} from "../../api/ProductsApi";
import {Presenter} from "../../api/Presenters";
import {MediaFile} from "../../api/common/MediaFile";
import {deleteCourseById} from "../../api/CoursesApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";

export const ManageGlobalSponsors: React.FC = () => {

    const params = useParams<ManageEventParams>();
    const eventId = Number(params.eventId);
    const [addSponsorIsOpen, setAddSponsorIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const [sponsors, setSponsors] = useState<any[]>([]);
    const [sponsorSelection, setSponsorSelection] = useState<{
        sponsor: any,
        action: string
    } | null>(null);

    const toggleAddNewSponsor = () => {
        setSponsorSelection(null)
        setAddSponsorIsOpen(oldState => !oldState);
    };

    const onSponsorUpdated = () => {
        setSponsorSelection(null);
        setAddSponsorIsOpen(oldState => !oldState);
        fetchSponsors();
    };

    const fetchSponsors = () => {
        getGlobalSponsors().then(resp => {
            if (resp.items?.length > 0) setSponsors(resp.items);
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        setLoading(true);
        fetchSponsors();
    }, []);


    const handleSponsorDelete = async () => {
        if (!sponsorSelection || !sponsorSelection.sponsor) return;
        setDeleteInProgress(true);
        try {
            await deleteSponsor(sponsorSelection.sponsor.id);
            toast.success(
                <ToastMessage>
                    Sponsor &quot;{sponsorSelection.sponsor.name}{" "} was deleted successfully.
                </ToastMessage>
            );
            setSponsorSelection(null);
            fetchSponsors();
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while deleting sponsor &quot;
                    {sponsorSelection.sponsor.name}{" "}
                    &quot;, please try again later
                </ToastMessage>
            );
        } finally {
            setDeleteInProgress(false);
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "#",
                Cell: (ctx: CellProps<Product>) => {
                    return ctx.row.index + 1
                }
            },
            {
                Header: "Logo",
                accessor: "logo",
                Cell: function renderAvatar(ctx: CellProps<Presenter>) {
                    const avatar = ctx.cell.value as
                        | MediaFile
                        | null
                        | undefined;
                    if (!avatar) return null;
                    return (
                        <img
                            src={avatar.fullUrl}
                            className="rounded-circle bg-white border mx-1"
                            width={50}
                            height={50}
                        />
                    );
                }
            },
            {
                Header: "Name",
                accessor: "name",
                canSort: true
            },
            {
                Header: "Description",
                accessor: "description",
                canSort: true
            },
            {
                Header: "Actions",
                Cell: function renderCourseActions(ctx: { row: { original: any; }; }) {
                    const sponsor = ctx.row.original;
                    if (!sponsor) return null;
                    return (
                        <div>
                            <Button
                                size="sm"
                                color="primary"
                                outline={true}
                                onClick={() =>
                                    setSponsorSelection({
                                    sponsor: sponsor,
                                    action: "edit"
                                })}
                            >
                                <FaEdit/>
                            </Button>{" "}
                            <Button
                                size="sm"
                                color="danger"
                                outline={true}
                                onClick={() =>
                                    setSponsorSelection({
                                    sponsor: sponsor,
                                    action: "delete"
                                })}
                            >
                                <FaTrash/>
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [sponsors]
    );

    return (
        <div>
            <Helmet title="Sponsors"/>

            <MainCard>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        <span>Sponsors</span>
                        <div>
                            <Button
                                className="btn btn-success"
                                onClick={toggleAddNewSponsor}
                            > Add new Sponsor <FaPlus/>
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    {loading ?
                        <Loader isLoading={true}/>
                        :
                        <DataTable
                            columns={columns}
                            data={sponsors}
                            emptyState={<div className={"text-center"}>
                                <EmptyListState
                                    image={EmptyImg}
                                    title={"There are no sponsors"}
                                />
                            </div>}
                        />
                    }

                </CardBody>

            </MainCard>
            {
                (addSponsorIsOpen || sponsorSelection?.action==="edit")
                    &&
                <AddGlobalSponsorFormModal
                    isOpen={addSponsorIsOpen || sponsorSelection?.action==="edit"}
                    toggle={toggleAddNewSponsor}
                    sponsorToEdit={sponsorSelection?.sponsor}
                    onSponsorUpdated={onSponsorUpdated}
                />
            }

            {sponsorSelection?.action ==="delete"
                &&
            <Modal size={"lg"} isOpen={sponsorSelection?.action ==="delete"} unmountOnClose>
                <ModalHeader toggle={()=>{setSponsorSelection(null)}}>
                    Delete Course
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete &quot;
                    {sponsorSelection?.sponsor?.name}{" "}
                    &quot;?
                </ModalBody>

                <ModalFooter className={"space-between"}>
                    <Button onClick={()=>{setSponsorSelection(null);}}>
                        No, keep &quot;
                        {sponsorSelection?.sponsor?.name} {" "}
                        &quot;
                    </Button>
                    <Button
                        color="danger"
                        onClick={handleSponsorDelete}
                        disabled={deleteInProgress}
                    >
                        {deleteInProgress && (
                            <span>
                                <FaSpinner className="icon-spin" />
                            </span>
                        )}{" "}
                        Yes, Delete &quot;
                        {sponsorSelection?.sponsor?.name}{" "}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
            }
        </div>
    );
};
