import React from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import "./OrderProductRow.css"
import FallbackImage from "./assets/imagefallback.png";

const OrderProductRow = ({product,increment,decrement,remove,index,maxQuantity}:any)=>{
  const canIncrement = !maxQuantity || product.quantity < maxQuantity


  return (<div className="d-flex flex-row w-100 px-2 py-2 bg-light align-items-center gap-3 justify-content-evenly mb-2">
    <span className='col-1 text-center '>{index+1}</span>

      <span className='col-3 text-nowrap text-truncate'>{product.name}</span>
      <div className="counter-wrap justify-content-around col-2 text-center">
        <div className='d-flex flex-row'>
          <div className="counter-action" onClick={decrement}>
            <FaMinus className='text-primary' />

          </div>
          <div className="counter-number">{product.qty}</div>
          <div className={`counter-action ${canIncrement ?'text-primary': 'text-secondary cursor-na'}`} onClick={() => {if(canIncrement) increment(product)}}>
            <FaPlus />
          </div>
        </div>

      </div>
      <div className='col-4 text-center gap-2 '>
        <span className={`${product.discountAmount !== 0 ? 'text-primary':'' }`}>{product.total} SAR</span>
      {product.discountAmount !== 0 ? <small className='text-decoration-line-through text-danger'> {product.total + product.discountAmount } SAR</small>:'' }
    </div>
    <div className='col-1 text-center' onClick={remove}>
      <FaTrash className='text-danger' />
    </div>
  </div>)
}

export default OrderProductRow;