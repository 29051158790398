import React, { useEffect, useState } from "react";
import { getInvoiceByOrderCode, getOrderByCode, printOrderInvoice } from "../../api/OrdersApi";
import { useParams } from "react-router-dom";
import { Button, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import { MainCard } from "../../components/MainCard";
import "./OrderDetails.component.css";
import { formatSimpleDate } from "../../util/dateUtil";
import printJS from "print-js";
import { FaPrint } from "react-icons/fa";

interface CustomerImage {
    fileName: string;
    contentType: string;
    fullUrl: string;
    sizeInBytes: number;
}

interface Customer {
    id: number;
    email: string;
    displayName: string;
    image: CustomerImage;
    phoneNumber?:string;

}

interface Product {
    id: number;
    name: string;
    description: string;
    checkedInDate: string;
    location: Location;
    unit: string;
    couponCode: string;
    qty: number;
    price: number;
    subTotal: number;
    discountPercentage: number;
    discountAmount: number;
    couponDiscountPercentage: number;
    couponDiscountAmount: number;
    subTotalAfterDiscount: number;
    vatAmount: number;
    otherTaxesAmount: number;
    total: number;
    media:any;
}

interface OrderDetails {
    subTotal: number;
    allowancePercentage: number;
    allowanceAmount: number;
    totalDiscounts: number;
    totalVATAmount: number;
    totalOtherTaxesAmount: number;
    total: number;
    id: number;
    client: Customer;
    status: {
        id: number;
        name: string;
    };
    createDate: string;
    updateDate: string;
    products: Product[];
    answers: any[];
    invoice:any;
    payments: any[];
}

export const OrderDetailsComponent: React.FC = () => {
    const params = useParams<{ code: string }>();
    const orderCode = params.code;
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [invoice,setInvoice] = useState<any>(null);

    const [order, seTOrder] = useState<OrderDetails | null>(null)

    useEffect(() => {
        getOrderByCode(orderCode ?? "").then(order => {
            seTOrder(order);
        }).finally(() => setLoading(true));
        getInvoiceByOrderCode(orderCode!).then((invoice:any)  => {
            setInvoice(invoice)
        })
    }, [])


    const toggleTabs = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const handlePrint = () => {
        if(invoice)
        printOrderInvoice(invoice.code).then((response:any) =>{
            printJS({printable:response,type:'raw-html'})
        });
    };

    return (
        <div>
            <Helmet title="Orders details"/>
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>Order {order?.id}</div>
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        <NavItem style={{flex: 2}}>
                            <NavLink
                                style={{ backgroundColor: "#f3f3f3", cursor: 'pointer', color: activeTab === "1" ? "#FFF" : "#4e71bf" }}
                                className={classnames({active: activeTab === "1"})}
                                onClick={() => {
                                    toggleTabs("1");
                                }}
                            >
                                <small>Order Details</small>
                            </NavLink>
                        </NavItem>

                        {invoice && <NavItem style={{ flex: 2 }}>
                            <NavLink
                              style={{ backgroundColor: "#f3f3f3", cursor: 'pointer', color: activeTab === "2" ? "#FFF" : "#4e71bf" }}
                              className={classnames({ active: activeTab === "2" })}
                              onClick={() => {
                                  toggleTabs("2");
                              }}
                            >
                                <small>Invoice </small>
                            </NavLink>
                        </NavItem>}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="order-detail">
                                <div className="details-myprofile">
                                    <h3>Customer :</h3>
                                    <ul>
                                        <li> Name :<span> {order?.client.displayName}</span></li>
                                        <li> E-mail :<span>{order?.client.email}</span></li>
                                        <li> Mobile no. :<span> {order?.client.phoneNumber}</span></li>
                                        <li> Country : <span> Saudi Arabia</span></li>
                                    </ul>
                                </div>
                                <div className="status-updated">
                                    <div className="sub-order-detail">
                                        <h3>Order Status :</h3>
                                        <ul>
                                            <li><span className={`stats onpre-order badge ${order?.status.name?.toLowerCase() === 'paid' ?'bg-success':'bg-secondary'}`}>{order?.status.name}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sub-order-detail">
                                        <h3>Updated on :</h3>
                                        <ul>
                                            <li>{formatSimpleDate(order?.updateDate)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details-myprofile">
                                    <h3>Payments :</h3>

                                    {order?.payments && order?.payments.map(payment=>
                                        <ul>
                                            <li> Payment Method :<span>{payment.paymentMethod?.name}</span></li>
                                            <li> Amount :<span>{payment.amount} SAR</span></li>
                                            <li> Date : <span> {formatSimpleDate(payment.date)}</span></li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="invoice">

                                <div className="details-invoice">
                                    <ul>
                                        {invoice &&
                                        <li>
                                            <Button
                                          className="my-4"
                                          block
                                          color="primary"
                                          outline
                                          onClick={handlePrint}
                                        ><FaPrint/> Print
                                        </Button></li>}
                                        <li>{formatSimpleDate(invoice?.createDate)}</li>
                                        <li> INVOICE {invoice?.id}</li>
                                        <li> {order?.client.displayName}</li>
                                        <li> {order?.client.email}</li>
                                    </ul>
                                </div>
                                <table className="table">
                                    <tbody>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {order?.products && order.products.map((product:any)=>
                                        <tr>
                                            <td>{product.name}</td>
                                            <td> {product.id}</td>
                                            <td>{product.price} SAR</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <div className="invoice-price">
                                    <ul>
                                        <li>
                                            <h3>Subtotal</h3> <span> {invoice?.subTotal} SAR</span>
                                        </li>
                                        <li>
                                            <h3>Tax</h3> <span> {invoice?.totalVATAmount} SAR </span>
                                        </li>
                                        <li>
                                            <h3>Discount</h3> <span>-{invoice?.totalDiscounts} SAR</span>
                                        </li>
                                        <li>
                                            <h3>Total</h3> <span> {invoice?.total} SAR </span>
                                        </li>
                                    </ul>
                                </div>
                                <p>Please pay within 15 days, Thank You for your business.</p>
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </MainCard>

        </div>
    );
}