import React, {ChangeEvent, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Loader} from "../../../components/Loader";
import Select from "react-select";
import "./../custom.scss";
import {addPostTag, editPostTag, GetPostTypes, PostTag} from "../../../api/PostTagsApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../../components/ToastMessages";
import {PostType} from "../../../api/PostsApi";

export enum ModalStatus {
    __CREATE__, __EDIT__
}

interface IAddEditNewsTagsProps {
    isOpen: boolean,
    onClose?: () => void,
    onUpdated?: () => void,
    item?: PostTag | null,
    modalStatus: ModalStatus,
}

interface IAddEditPostTagsState {
    data: PostTag,
    loading: boolean,
    formValid: boolean
}

const initState: IAddEditPostTagsState = {
    data: {
        name: '',
        id: 0,
        pType: '',
    },
    formValid: false,
    loading: false
}

const AddEditPostTags: React.FC<IAddEditNewsTagsProps> = (props) => {
    const [state, setState] = useState<IAddEditPostTagsState>(
        !!props.item ? {...initState, data: props.item , formValid: true, loading: false} : initState);

    const [postTypes, setPostTypes] = React.useState<PostType[]>();

    const fetchPostTypes = () => {
        GetPostTypes().then(types=> setPostTypes(types));
    }

    useEffect(()=> {
        fetchPostTypes();
    }, [])


    const submitHandler = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const editCase = state.data.id;
        let submitPromise = editCase ? editPostTag(state.data) : addPostTag(state.data);
        submitPromise.then(
            (resp)=>{
                toast.success(
                    <ToastMessage>
                        Tag has been {editCase ? "edited" : "added"} successfully
                    </ToastMessage>
                );

                props.onClose?.();
            }
        ).catch(err=>{
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong
                    &quot;, please try again later.
                </ToastMessage>
            )
        })
    }

    const changeHandlerName = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;

        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, name },
            formValid: prevState.data?.name?.length > 0
        }));
    }

    const changeProductType = (type: any) => {
        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, pTypeId : type.id, pType: type.name },
            formValid: type.name.length > 0
        }));
    }

    return (
        <Modal style={{ height: '400px' }} unmountOnClose isOpen={props.isOpen} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>
                {
                    props.modalStatus === ModalStatus.__EDIT__ ? " Edit Tag" : "Add Tag"
                }
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="catName"> Name * </label>
                    <input className={'form-control'}
                           onChange={changeHandlerName}
                           value={state.data.name} id={'catName'} />
                </div>

                <div className="form-group">
                    <label htmlFor="catName"> Type * </label>
                    <Select
                        isDisabled={!!state.data.id}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id + ""}
                        options={postTypes}
                        value={postTypes?.find(pct=>pct.name === state.data.pType || pct.id === state.data.pTypeId)}
                        onChange={(option) => {
                            changeProductType(option)
                        }}
                        placeholder="Select a Tag"
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary"
                        onClick={props.onClose}
                > Cancel </button>
                <button onClick={submitHandler} className="btn btn-primary"
                        disabled={!state.formValid}> Save </button>
                {
                    state.loading ? <Loader isLoading={true} /> : null
                }
            </ModalFooter>
        </Modal>
    );
}

export default AddEditPostTags;
