import {SideNavContext} from "Contexts/SideNavContext";
import {getUserInfoClaims, UserInfoClaims} from "api/userInfo";
import {NavMenuUserInfo} from "components/NavMenuUserInfo";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {Collapse, Container, Navbar, NavbarToggler} from "reactstrap";
import {RootState} from "../store";
import {Data_Format_EMS} from "../types/date-format-type";
import "./NavMenu.css";
import ChangePassword from "../scenes/ProfileSettings/ChangePassword";

interface NavMenuState {
  collapsed: boolean;
}

export const NavMenu: React.FC = () => {
  const params = useParams<{ eventId: string }>();
  const eventId = Number(params.eventId);

  const eventInfoState = useSelector((state: RootState) => state.eventDetails);
  const activeEvent =
    eventInfoState.eventId === eventId ? eventInfoState : null;

  const [collapsed, setCollapsed] = useState(true);
  const [userClaims, setUserClaims] = useState<UserInfoClaims | null>(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  //TODO: move to store
  useEffect(() => {
    let tryAgainTimeout: NodeJS.Timeout | null = null;

    function loadUserInfoClaims() {
      getUserInfoClaims()
        .then((r) => setUserClaims(r))
        .catch((_) => (tryAgainTimeout = setTimeout(loadUserInfoClaims, 3000)));
    }

    loadUserInfoClaims();
    return function cleanUp() {
      tryAgainTimeout && clearTimeout(tryAgainTimeout);
    };
  }, []);

  const changePasswordHandler = () => {
    setOpenChangePassword(!openChangePassword);
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);


  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm bg-white border-bottom py-4"
        light
      >
        <Container fluid>
            <Collapse
                className="d-sm-inline-flex flex-sm-row-reverse space-between"
                isOpen={!collapsed}
                navbar
            >
              <ul className="navbar-nav flex-grow">
                <li>
                  <NavMenuUserInfo userClaims={userClaims} changePassword={changePasswordHandler} />
                </li>
              </ul>

              <h6 className={"m-0"}>
                {" " + moment(activeEvent?.result?.startDate)
                    .local()
                    .format(Data_Format_EMS)
                }
                {activeEvent?.result && " - " +activeEvent?.result?.name}
              </h6>
            </Collapse>
          {/*<EventNameContext.Consumer>*/}
          {/*  {value =><h5>{value ? `${value}` : ""}</h5>}*/}
          {/*</EventNameContext.Consumer>*/}

          <SideNavContext.Consumer>
            {({ toggle }) => (
              <NavbarToggler onClick={toggle} className="mr-2" />
            )}
          </SideNavContext.Consumer>

        </Container>
      </Navbar>

      <ChangePassword toggle={changePasswordHandler} isOpen={openChangePassword} />
    </header>
  );
};
