/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  addPlan,
  editPlan,
  getPlan,
  getSessionsByMembership,
  IAddEditMembershipPlanModel,
  IDurationPlan,
  SearchSessions,
  uploadMembershipImage
} from "../../api/ProductsApi";
import { getTaxesDropdownList, ITax } from "../../api/TaxApi";
import { Loader } from "../../components/Loader";

import moment from "moment";
import ReactDatetime from "react-datetime";
//
import emptyStateSpeakerImage from "assets/imgs/duration.svg";
import { EmptyListState } from 'components/EmptyListState';
import DurationPlanComponent from "./DurationPlanComponent";
import { BiTimeFive } from "react-icons/bi";
import CKEditor from "../../components/SendEmail/CKEditor";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";
import Select from "react-select";
import TooltipHintComponent from "../../components/TooltipHint/TooltipHintComponent";
import { AiOutlineDelete } from "react-icons/ai";

export enum ModalStatus {
  __CREATE__, __EDIT__
}

export interface IAddEditMembershipPlanProps {
  item?: IAddEditMembershipPlanModel | null;
  isOpen: boolean;
  close?: () => unknown;
  onUpdated?: () => unknown;
  state: ModalStatus;
}


export interface IAddEditMembershipPlanState {
  data: IAddEditMembershipPlanModel;
  formValid: boolean;
  loading: boolean;
  tax: any;
  taxes: Array<ITax>;
  _taxes: Array<{ label: string, value: number }>,
  previewImage: any,
  imageChanged: boolean,
  sessions: Array<any>
}

const initData: any = {
  id: 0,
  name: "",
  endDateTime: undefined,
  startDateTime: undefined,
  durationsPlans: [],
  image: undefined,
  sessionsIds: []
}

class AddEditMembershipPlan extends React.Component<IAddEditMembershipPlanProps,
  IAddEditMembershipPlanState> {
  constructor(props: IAddEditMembershipPlanProps) {
    super(props);
    this.state = {
      data: initData,
      formValid: false,
      loading: false,
      tax: null,
      taxes: [],
      _taxes: [],
      previewImage: null,
      imageChanged: false,
      sessions: []
    }
  }

  componentWillMount() {
    if (this.props.item) {
      this.setState({ data: this.props.item });

      const planId = this.props.item.id;
      if (planId) {
        getPlan(planId).then((plan: any) => this.setState({
          data: {
            id: plan.id,
            name: plan.name,
            description: plan.description,
            availabilityStartDateTime: plan.availabilityStartDateTime,
            availabilityEndDateTime: plan.availabilityEndDateTime,
            sessionsIds: plan.sessionsIds,
            image: plan.image,
            isPublished: plan.isPublished,
            durationsPlans: [...plan.durationsPlans.map((value: any) => {
              return {
                ...value,
                price: {
                  amount: value.price?.amount,
                  discount: value.price?.discount,
                  isDiscountPercentage: value.price?.isDiscountPercentage,
                  taxesIds: value.price?.taxes?.map((tax: any) => tax.id) ?? []
                }
              }
            })]
          }
        }, () => {
          getSessionsByMembership(planId).then(inculededSessions => {
            if (inculededSessions.items && inculededSessions.items.length > 0) {
              const sessionIds = inculededSessions.items.map(it => it.id);
              const data = this.state.data;
              data.sessionsIds = sessionIds ?? [];
              this.setState({ data: data });
            }
          })
        }))
      }
      if (this.props.item.image) {
        const image = this.props.item.image as any
        this.setState({ previewImage: image.fullUrl })
      }
    }

    this.fetchTaxes();
    this.fetchSessions(null);
  }

  changeHandler = (e: any) => {
    const name = e.target.name;
    const val: string | boolean = e.target.value;
    let data: IAddEditMembershipPlanModel = this.state.data;
    data = { ...data, [name]: val };
    this.setState({ data }, () => {
      this.validationHandler();
    });
  }

  validationHandler = () => {
    if (this.state.data.name && this.state.data.name.length > 0
    ) {
      this.setState({ formValid: true });
    } else {
      this.setState({ formValid: false });
    }
  }

  fetchSessions = (text: string | null) => {
    if (text == null || text.length > 2)
      SearchSessions(text ?? "").then(sessions => this.setState({ sessions: sessions }))
  }

  fetchTaxes = () => {
    getTaxesDropdownList().then(r => {
      const _taxes = r.map(item => ({ label: `${item.name} :  ${item.value} %  `, value: item.id }))
      this.setState({ taxes: r, _taxes });

    });
  }


  submitHandler = () => {
    this.setState({ loading: true })
    if (this.props.state === ModalStatus.__CREATE__) {
      this.addPlanHandler();
    } else {
      // todo: edit plan.
      this.editPlanHandler();
    }

  }

  addPlanHandler = () => {
    addPlan(this.state.data).then(res => {
      this.setState({ loading: false })
      if (this.state.data.image) {
        uploadMembershipImage(res.id, this.state.data.image).then(up => {
          toast.success(
            <ToastMessage>
              Membership added successfully !
            </ToastMessage>
          );

          this.props.onUpdated?.();
        }).catch(err => {
          toast.error(
            <ToastMessage type="Error">
              The membership has been added,
              Something went wrong while uploading image.
            </ToastMessage>
          );
        });
      } else {
        toast.success(
          <ToastMessage>
            Membership added successfully !
          </ToastMessage>
        );
        this.props.onUpdated?.();
      }

    }, (error: any) => {
      this.setState({ loading: false })

      toast.error(
        <ToastMessage type="Error">
          Something went wrong while adding membership plan,
          Please try again later.
        </ToastMessage>
      );
    })
  }


  editPlanHandler = () => {
    const { image, id, ...data } = { ...this.state.data }
    editPlan(this.state.data.id!, data).then(_ => {
      this.setState({ loading: false })
      if (this.state.data.image && this.state.imageChanged) {
        uploadMembershipImage(this.state.data.id!, this.state.data.image).then(up => {
          toast.success(
            <ToastMessage>
              Membership updated successfully !
            </ToastMessage>
          );

          this.props.onUpdated?.();
        }).catch(err => {
          toast.error(
            <ToastMessage type="Error">
              The membership has been updated,
              Something went wrong while uploading image.
            </ToastMessage>
          );
        });
      } else {
        toast.success(
          <ToastMessage>
            Membership updated successfully !
          </ToastMessage>
        );
        this.props.onUpdated?.();
      }
    }, (error: any) => {
      this.setState({ loading: false })
    })
  }


  generateImagePreview = (
    file: File,
    setter: (value: string | null) => any
  ) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setter(reader.result as string);
    };

    if (file.type.match("image.*")) {
      reader.readAsDataURL(file);
    } else {
      setter(null);
    }
  };

  addDurationPlanComponentHandler = () => {
    const { data } = this.state;
    const { durationsPlans } = data;

    let max = 0;
    if (durationsPlans && durationsPlans.length > 0 && durationsPlans[durationsPlans.length - 1]) {
      max = ((durationsPlans[durationsPlans.length - 1]?.index ?? 0) + 1 ?? 0);
    }

    const durationPlan = {
      id: 0, checkInLimits: 0, durationInDays: 0,
      price: { amount: 0, taxesIds: [], displayPrice: '', displayTotalPrice: '' },
      index: max, isActive: true, autoApplyBigCoupon: true, autoApplySmallCoupon: false
    } as IDurationPlan;
    data.durationsPlans.push(durationPlan);
    this.setState({ data });
  }

  removeDurationPlanComponentHandler = (index: number) => {
    const { data } = this.state;
    if (this.props.state === ModalStatus.__CREATE__) {
      data.durationsPlans = data.durationsPlans.filter(x => {
        return x.index !== index;
      });
    } else {
      data.durationsPlans = data.durationsPlans.filter(x => {
        return x.id !== index;
      });
    }

    // data.durationsPlan = durationPlans;
    this.setState({ data: { ...data } });
    // var el :any = document.getElementById(`duration${index}`);
    // if(el) {
    //     el.remove();
    // }
  }

  render() {
    // @ts-ignore
    // @ts-ignore
    return (
      <Modal size={"lg"} unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>
          {
            this.props.state === ModalStatus.__EDIT__ ? " Edit Membership Plan" : "Add Membership Plan"
          }
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label> Name *</label>
                <input value={this.state.data.name} name={"name"} type="text" className="form-control"
                       onChange={this.changeHandler}
                />
              </div>
              <div className="form-group">
                <label> Description </label>
                <CKEditor
                  changeContent={(x: any) => {
                    let { data } = this.state;
                    if (data) {
                      data.description = x;
                    } else {
                      data = { description: x, ...this.state.data };
                    }

                    this.setState({ data });
                  }}
                  content={this.props.item?.description}
                />
              </div>
              <div className="row">
                <div className="col form-group">
                  <label> Availability Start date </label>
                  <ReactDatetime
                    value={this.state.data?.availabilityStartDateTime ? moment(this.state.data.availabilityStartDateTime) : undefined}
                    onChange={value => this.setState({
                      data: {
                        ...this.state.data,
                        availabilityStartDateTime: value ? (value as moment.Moment) : undefined
                      }
                    })}
                  />
                </div>
                <div className="col form-group">
                  <label> Availability End date </label>
                  <ReactDatetime
                    value={this.state.data?.availabilityEndDateTime ? moment(this.state.data.availabilityEndDateTime) : undefined}
                    onChange={value => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          availabilityEndDateTime: value ? (value as moment.Moment) : undefined
                        }
                      })
                    }}
                  />
                </div>

              </div>

              <FormGroup>
                <label> Banner
                  <TooltipHintComponent text="images of product will display in your site."/>
                </label>

                <Input
                  type="file"
                  // value={this.state.data.image?.name}
                  accept="image/*"
                  onChange={e => {
                    this.setState(
                      {
                        data: {
                          ...this.state.data,
                          image: e.target?.files
                            ? e.target?.files[0]
                            : undefined,
                        },
                        imageChanged: true,
                      }
                    );
                    e.target.files &&
                    this.generateImagePreview(
                      e.target.files[0],
                      (val: string | null) => {
                        this.setState({ previewImage: val });
                      }
                    );
                  }}
                />
              </FormGroup>

              {this.state.previewImage && (
                <div
                  className="rounded thumbnail img-container"
                  style={{ height: 265, }}
                >
                  <div className="d-none remove-container">
                    <button
                      onClick={() => {
                        this.setState({
                          ...this.state, previewImage: null,
                          data: { ...this.state.data, image: undefined }
                        })
                      }}
                      className="remove-btn btn btn-danger">
                      <AiOutlineDelete/>
                    </button>
                  </div>
                  <img
                    src={this.state.previewImage}
                    className="rounded thumbnail"
                    style={{ height: 265, width: "100%" }}
                  />
                </div>
              )}

              <FormGroup>
                <Label>
                  Included Sessions
                </Label>
                <Select
                  isMulti={true}
                  options={this.state.sessions || []}
                  value={this.state.data.sessionsIds && this.state.sessions.filter((p: any) => this.state.data.sessionsIds.includes(p.id))}
                  onInputChange={(input: any) => this.fetchSessions(input)}
                  onChange={(o: any) => {
                    const { data } = this.state;
                    data.sessionsIds = o.map((p: { id: any; }) => p.id);
                    this.setState({ data });
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id + ""}
                />
              </FormGroup>

              <div style={{ overflow: 'hidden', overflowY: 'auto', marginTop: "10px" }}>
                {
                  this.state.data?.durationsPlans && this.state.data?.durationsPlans.length > 0 ? this.state.data?.durationsPlans.map((item, index: number) => {
                      return (
                        <>
                          <DurationPlanComponent _taxes={this.state._taxes}
                                                 taxes={this.state.taxes}
                                                 index={index}
                                                 removeFn={() => this.removeDurationPlanComponentHandler(item.id !== 0 ? item.id : (item.index ?? index))}
                                                 item={item}
                          />
                        </>

                      );
                    }) :
                    <div className="text-center">
                      <EmptyListState
                        image={emptyStateSpeakerImage}
                        title={"You have no Durations yet"}

                      />
                    </div>
                }

                <div style={{
                  display: "flex",
                  justifyContent: "center", marginTop: "10px"
                }}>
                  <button className='btn btn-outline-secondary'
                          onClick={this.addDurationPlanComponentHandler}
                  ><BiTimeFive/> Add Duration
                  </button>
                </div>
              </div>

              <FormGroup switch>
                <Label style={{ marginRight: "5px" }}>Published</Label>

                <Input
                  type="switch"
                  checked={this.state.data?.isPublished}
                  id={"isOnHomePage"}
                  onChange={e => {
                    this.setState({ data: { ...this.state.data, isPublished: e.target.checked } })

                  }}
                />
              </FormGroup>
            </div>



          </div>

        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary"
                  onClick={this.props.close}
          > Cancel
          </button>
          <button className="btn btn-primary"
                  onClick={this.submitHandler}
          > Save
          </button>
          {
            this.state.loading ? <Loader isLoading={true}/> : null
          }
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddEditMembershipPlan;