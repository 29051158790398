import Axios from "axios";
import {objectToFormData} from "../util/objectToFormData";
import {ContactInfo} from "./common/ContactInfo";
import {IDropdownList} from "./common/DropdownList";
import {GeoLocation} from "./common/GeoLocation";
import {MediaFile} from "./common/MediaFile";
import {NewResourceResult} from "./common/NewResourceResult";
import {PaginatedRequest} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface EventRequest extends PaginatedRequest {
    status?: "*" | "Published" | "Offline";
}

export interface Event {
    id: number;
    name: string;
    description: string | null;
    startDate: string;
    endDate: string;

    banner: MediaFile | null;
    contact: ContactInfo | null;

    isDefault: boolean;
    timeZone: string;
    status: "Published" | "Offline";
    location: any | GeoLocation | null;
    index?: number | null;
    allowMembershipArea?:boolean | null;
    isOnHomePage: boolean;
}

export interface AddEditEventRequest {
    name: string;
    description: string | null;

    banner: File | null;
    startDateTime: string;
    endDateTime: string;
    location: GeoLocation | null;
    contact: ContactInfo | null;
    status: "Published" | "Offline";
    isOnHomePage: boolean;
}

export const getEvents = async (
    request: EventRequest
): Promise<PaginatedResult<Event>> => {
    const result = await Axios.get<PaginatedResult<Event>>("/api/Events", {
        params: request
    });

    return result.data;
};

export const getEventById = async (eventId: number) => {
    const result = await Axios.get<Event>(`/api/events/${eventId}`);

    return result.data;
};

export const addNewEvent = async (
    request: AddEditEventRequest
): Promise<NewResourceResult<number>> => {
    const formData = objectToFormData(request);
    const result = await Axios.post<NewResourceResult<number>>(
        "/api/events",
        formData,
        {
            headers: { "Content-Type": "multipart/form-data" }
        }
    );

    return result.data;
};

export const editEvent = async (
    eventId: number,
    request: AddEditEventRequest
): Promise<void> => {
    const formData = objectToFormData(request);
    await Axios.put("/api/events/" + eventId, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    });
};

export const deleteEvent = async (eventId: number): Promise<any> => {
    const result = await Axios.delete("/api/events/" + eventId);
    return result.data;
};

export const setEventAsDefault = async (eventId: number): Promise<void> => {
    await Axios.put(`/api/events/${eventId}/default`);
};



// get event as dropdown list.
export interface IEventDropdownList extends IDropdownList{
    
}


export const getEventsDropdownlist = async () : Promise<Array<IEventDropdownList>> => {
   const result =  await Axios.get<Array<IEventDropdownList>>('/api/Events/dropdownlist');
   return result.data;
    
}