import React, {useEffect, useState} from 'react';
import {Loader} from 'components/Loader';
import Helmet from 'react-helmet';
import {FaEdit, FaPlus, FaSpinner, FaTrashAlt} from 'react-icons/fa';
import {Button, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import noData from '../../../assets/imgs/no_data.svg';
import {MainCard} from '../../../components/MainCard';
import {deletePostTagById, GetAllPostsTags, PostTag} from "../../../api/PostTagsApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../../components/ToastMessages";
import AddEditTag, {ModalStatus} from "./AddEditTag";
import { PaginationFooter } from "../../../components/PaginationFooter";

const PostTagsComponent: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<PostTag[]>([]);
    const [openAddEditModal, setOpenAddEditModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState<PostTag | null>(null);
    const [itemToDelete, setItemToDelete] = useState<PostTag | null>(null);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
    const [maximumPerPage]=useState(20);

    useEffect(() => {
        fetchTags();
    }, [page]);

    const fetchTags = () => {
        GetAllPostsTags({pageIndex:page,limit:maximumPerPage}).then(categories => {
            setData([...categories.items]);
            setCount((categories as any).totalItemsCount)
        })
    };

    const closeAddEditModal = () => {
        setOpenAddEditModal(false);
    };

    const onUpdateModal = () => {
        setOpenAddEditModal(false);
        fetchTags();
    };

    const displayAddEditModal = (item: PostTag) => {
        setItemToEdit({...item});
        setOpenAddEditModal(true);
    };


    const handleDelete = () => {
        if (!itemToDelete) return;
        deletePostTagById(itemToDelete?.id).then(r => {
                toast.success(
                    <ToastMessage>
                        Tag &quot;{itemToDelete.name}{" "} was deleted successfully.
                    </ToastMessage>
                );
                setItemToDelete(null);
                fetchTags();
            }
        ).catch(err => {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while deleting tag &quot;
                    {itemToDelete.name}{" "}
                    &quot;, please try again later
                </ToastMessage>
            );
        }).finally(() => setDeleteInProgress(false))

    };

    return (
        <div>
            {openAddEditModal ? (
                <AddEditTag
                    isOpen={true}
                    onClose={() => {
                        setOpenAddEditModal(false);
                        setItemToEdit(null);
                        fetchTags();
                    }}
                    onUpdated={fetchTags}
                    item={itemToEdit}
                    modalStatus={itemToEdit ? ModalStatus.__EDIT__ : ModalStatus.__CREATE__}
                />
            ) : null}
            <Helmet title="Tags"/>
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>News / Cases Tags</div>
                    <div>
                        <Button
                            onClick={e => {
                                e.preventDefault();
                                setOpenAddEditModal(true);
                            }}
                            color={'primary'}
                        >
                            <FaPlus/> Add Tag
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    {data.length > 0 ? (
                        <div>
                            <table className={"table table-response"}>
                                <thead className="thead-dark">
                                <tr>
                                    <td>#</td>
                                    <td>Name</td>
                                    <td>Type</td>
                                    <td>Actions</td>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item: PostTag, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.pType}</td>
                                            <td className="position-relative">
                                                <>
                                                    <Button
                                                        size={"sm"}
                                                        outline={true}
                                                        color={"primary"}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            displayAddEditModal(item);
                                                        }}
                                                    >
                                                        <FaEdit/>
                                                    </Button>{" "}
                                                    <Button
                                                        size={"sm"}
                                                        outline={true}
                                                        color={"danger"}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            setItemToDelete(item);
                                                        }}
                                                    >
                                                        <FaTrashAlt/>
                                                    </Button>
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={4}>
                                        <PaginationFooter
                                          onPageClicked={setPage}
                                          pageIndex={page}
                                          totalItemsCount={count}
                                          pageSize={maximumPerPage}
                                          pageItemsCount={data.length}
                                        />
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    ) : loading ? (
                        <div>
                            <Loader isLoading={true}/>
                        </div>
                    ) : (
                        <div className={'text-center'}>
                            <img src={noData} width={"50%"} height={"200px"} alt={'no data'}/>
                            <p className={'mt-4'}>No Item Exists</p>
                        </div>
                    )}
                </CardBody>
            </MainCard>

            <Modal isOpen={!!itemToDelete} unmountOnClose>
                <ModalHeader toggle={() => {
                    setItemToDelete(null)
                }}>
                    Delete Tag
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete &quot;
                    {itemToDelete?.name}{" "}
                    &quot;?
                </ModalBody>

                <ModalFooter className={"space-between"}>
                    <Button onClick={() => {
                        setItemToDelete(null)
                    }}>
                        No, keep &quot;
                        {itemToDelete?.name}{" "}
                        &quot;
                    </Button>
                    <Button
                        color="danger"
                        onClick={handleDelete}
                        disabled={deleteInProgress}
                    >
                        {deleteInProgress && (
                            <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
                        )}{" "}
                        Yes, Delete &quot;
                        {itemToDelete?.name}{" "}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PostTagsComponent;
