import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../api/common/PaginatedResult";
import {
    getSpeakersForEvent,
    Speaker,
    SpeakersForEventRequest
} from "../api/Speakers";
import { AppThunk } from "./index";

export interface EventSpeakersListState {
    isBusy: boolean;
    eventId: number | null;
    speakersResult: PaginatedResult<Speaker> | null;
    error: string | null;
}

const initialState: EventSpeakersListState = {
    error: null,
    isBusy: false,
    speakersResult: null,
    eventId: null
};

interface ReceiveEventSpeakersListAction {
    error: string | null;
    speakerResult: PaginatedResult<Speaker> | null;
    eventId: number | null;
}

const EventSpeakersList = createSlice({
    name: "EventSpeakersList",
    initialState,
    reducers: {
        requestEventSpeakersList: (state, action: PayloadAction) => {
            state.isBusy = true;
        },
        recieveEventSpeakersList: (
            state,
            action: PayloadAction<ReceiveEventSpeakersListAction>
        ) => {
            state.error = action.payload.error;
            state.eventId = action.payload.eventId;
            state.isBusy = false;
            state.speakersResult = action.payload.speakerResult;
        }
    }
});

export default EventSpeakersList.reducer;

const {
    requestEventSpeakersList,
    recieveEventSpeakersList
} = EventSpeakersList.actions;

export const loadEventSpeakersList = (
    request: SpeakersForEventRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestEventSpeakersList());
        const result = await getSpeakersForEvent(request);
        dispatch(
            recieveEventSpeakersList({
                eventId: request.eventId,
                error: null,
                speakerResult: result
            })
        );
    } catch (e: any) {
        dispatch(
            recieveEventSpeakersList({
                error: e.toString(),
                speakerResult: null,
                eventId: request.eventId
            })
        );
    }
};
