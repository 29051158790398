import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalize} from "normalizr";
import {PaginatedRequest} from "../api/common/PaginatedRequest";
import {getPaymentGateways, PaymentGateway} from "../api/PaymentGatewaysApi";
import {addEntities, AddEntitiesAction} from "./Actions";
import {AppThunk} from "./index";
import {NormalizedPaginationItem} from "./NormalizedPagination";
import {paymentGatewaySchema} from "./Schemas";

export interface PaymentGatewaysState {
    [key: string]: PaymentGateway | {};

    pagination: {
        list: NormalizedPaginationItem | null;
    };
}

const initialState: PaymentGatewaysState = {
    pagination: { list: null }
};

interface ReceiveFetchPaymentGatewaysAction {
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const paymentGatewaySlice = createSlice({
    name: "paymentGateways",
    initialState,
    reducers: {
        requestFetchPaymentGateways(state, action) {
            state.pagination.list = {
                ...state.pagination.list,
                isFetching: true
            };
        },
        receiveFetchPaymentGateways(
            state,
            action: PayloadAction<ReceiveFetchPaymentGatewaysAction>
        ) {
            state.pagination.list = {
                ...state.pagination.list,
                ...action.payload,
                isFetching: false
            };
        }
    },

    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["paymentGateways"]
            };
        })
    })
});

export default paymentGatewaySlice.reducer;

const {
    requestFetchPaymentGateways,
    receiveFetchPaymentGateways
} = paymentGatewaySlice.actions;

export const fetchPaginatedPaymentGateways = (
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchPaymentGateways({}));

        const result = await getPaymentGateways(request);
        const normalizedResult = normalize(
            result,
            Array(paymentGatewaySchema)
        );

        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            receiveFetchPaymentGateways({
                error: undefined,
                pageIndex: request.pageIndex,
                pageSize: request.limit,
                totalItemsCount: normalizedResult.result.totalItemsCount,
                ids: normalizedResult.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchPaymentGateways({
                ...getState().paymentGateways.pagination.list,
             
            })
        );
    }
};
