import {Card, Container, Nav, NavItem, NavLink} from "reactstrap";
import {FaEnvelope, FaGlobe, FaTwitter} from "react-icons/fa";
import React from "react";

export const Footer = () => {
    return <footer className="footer">
        <Card color="white" className="border-0 rounded-0">
            <Container fluid>
                <div className="py-3">
                    <div className="d-flex justify-content-between small">
                        <div>
                            <Nav>
                                <NavItem className="nav-link">
                                    Developed by{" "}
                                    <a
                                        href="https://ptit.com.sa"
                                        title="Go to Perfect Touch website"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Perfect Touch
                                    </a>
                                </NavItem>
                            </Nav>
                        </div>

                        <div>
                            <Nav>
                                <NavItem>
                                    <NavLink
                                        href="https://ptit.com.sa"
                                        title="Go to Perfect Touch Website"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaGlobe/> Visit
                                        our site
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="https://twitter.com/ptit_sa"
                                        title="Go to Perfect Touch Twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter/> Follow
                                        us @ptit_sa
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="mailto:info@ptit.com.sa"
                                        title="Send an Email to info@ptit.com.sa"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaEnvelope/>{" "}
                                        Contact us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
            </Container>
        </Card>
    </footer>;
}
