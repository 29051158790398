import React from "react";
import { GetPaymentGatewaysPos, PaymentMethod2, PaymentOption } from "../../api/PaymentGatewaysApi";
import {Alert, Container} from "reactstrap";
import { useEffect, useState } from "react";
import { Payment } from "../../api/OrdersApi";

interface PaymentPlanComponentProps {
  amount: any | null,
  selectedPayments: Payment[],
  setSelectedPayments: (selectedPayments: Payment[]) => void;
}

export interface SelectedPayment {
  paymentGatewayId: number,
  paymentMethodId: number,
  amount: number,
  note: string
}

export const OrderPayment = ({ amount, selectedPayments, setSelectedPayments}: PaymentPlanComponentProps) => {

  const [paymentMethods,setPaymentMethods] = useState<any[]>([]);

  useEffect(() => {
    GetPaymentGatewaysPos().then(response => setPaymentMethods(response))
  },[])

  const isSelected = (gatewayId: number, methodId: number) => {
    return selectedPayments.some(payment => payment.paymentGatewayId === gatewayId && payment.paymentMethodId === methodId);
  }

  const handleSetSelectedPayment = (gatewayId: number, methodId: number) => {
    const selectedPaymentsEdit = [...selectedPayments]
    if(isSelected(gatewayId,methodId)){

      const index = selectedPayments.findIndex(payment => payment.paymentMethodId === methodId && payment.paymentGatewayId === gatewayId)
      selectedPaymentsEdit.splice(index,1)

    }else{
      selectedPaymentsEdit.push({
        paymentGatewayId: gatewayId,
        paymentMethodId: methodId,
        amount: 0,
        note: ''
      })
    }
    setSelectedPayments(selectedPaymentsEdit);

  }

  const handleSetPaymentAmount = (gatewayId: number, methodId: number, amount: string) => {
    const updatedPayments = selectedPayments.map(payment => {
      if (payment.paymentGatewayId === gatewayId && payment.paymentMethodId === methodId) {
        return {
          ...payment,
          amount: parseFloat(amount)
        };
      }
      return payment;
    });
    setSelectedPayments(updatedPayments);
  }

  return (
    <>
      {amount === 0 ?
        <Alert className="m-3" color={'primary'}>Nothing to pay for</Alert>
        :
        paymentMethods && paymentMethods.length > 0 ?
          <Container>
            <Alert className="mt-2" color={'primary'}>Amount to pay {" "}
              <b>{amount}</b>
            </Alert>
            <ul style={{listStyle: 'none', padding: '0'}}>
              {
                paymentMethods.map((pm: PaymentOption, i: number) => {
                  return (
                    <li key={i} className="mt-4">
                      <div className="cat-title mx-3">
                        <strong> {pm.name} </strong>
                      </div>
                      <div className="services row mt-4">
                        {
                          pm.paymentMethods?.length &&
                          pm.paymentMethods.map((pgm: PaymentMethod2, i: number) => {
                            return (
                              <div key={i} className="col-md-2 col-sm-4 mx-3"
                                   style={{
                                     cursor: 'pointer',
                                     height: '200px',
                                     width: '150px',
                                     display: 'flex',
                                     alignItems: 'center',
                                     flexWrap: 'wrap',
                                     border: isSelected(pm.id, pgm.id) ?
                                       "1px solid #1c8879" : "1px dashed #ddd",
                                     boxShadow: isSelected(pm.id, pgm.id) ? "0 0 26px rgb(28 136 121 / 12%)" : "none"
                                   }}

                              >
                                <div
                                  onClick={() => handleSetSelectedPayment(pm.id, pgm.id)}>
                                  <div className="service-img">
                                    <img src={pgm.imageUrl ? pgm.imageUrl : ""}/>
                                  </div>
                                  <div className="service-details row">
                                    <div
                                      className="col-sm-12 text-center external-link">
                                      {pgm.name}
                                    </div>
                                  </div>

                                </div>
                                <div className="col-sm-12 text-center external-link">
                                  {isSelected(pm.id, pgm.id) &&
                                  <input
                                      type='number'
                                      onChange={(e) => handleSetPaymentAmount(pm.id, pgm.id, e.target.value)}
                                      style={{width: '100%'}}
                                      onClick={e => e.preventDefault()}/>}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </Container>
          : <Alert>No Payment Method available</Alert>
      }
    </>

  )
}
export default OrderPayment;