import { UserInfoClaims } from "api/userInfo";
import * as React from "react";
import {FaChevronDown, FaLock, FaPowerOff, FaUnlock, FaUserAlt, FaUserLock} from "react-icons/fa";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {useNavigate} from "react-router-dom";
import { useAppDispatch } from "../../configureStore";
import { logout } from "../../store/auth/authSlice";

export interface NavMenuUserInfoProps {
  userClaims: UserInfoClaims | null;
  changePassword: () => void;
}

export const NavMenuUserInfo: React.FC<NavMenuUserInfoProps> = ({userClaims, changePassword}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToProfile = () => {
    navigate("/ProfileSettings");
  };
  const loadingState = <div>Loading ...</div>;
  const userClaimsState = (
    <div>
      {/*props.userClaims?.picture && (
                <img
                    src={props.userClaims?.picture}
                    alt="Logged in avatar"
                    className="bg-light avatar"
                />
            )*/}

      {/*<div className="avatar avatar--initial-avatar d-inline-block">*/}
      {/*    <span>{props.userClaims?.name[0]}</span>*/}
      {/*</div>*/}

      <FaUserAlt />
      <span className="align-middle"> Hello, {userClaims?.name}</span>
      <FaChevronDown className="btn-icon__caret" />
    </div>
  );
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="border-0 btn-icon btn-light">
        {userClaims ? userClaimsState : loadingState}
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem onClick={goToProfile}>Profile settings</DropdownItem>
        <DropdownItem href="https://crm.ptit.com.sa/" target="_blank">
          Customer area
        </DropdownItem>

        <DropdownItem
          href="https://crm.ptit.com.sa/knowledge-base/category/ems"
          target="_blank"
        >
          Watch a video tutorial
        </DropdownItem>

        <DropdownItem divider />

        <DropdownItem className="btn-icon" onClick={()=>changePassword()}>
          <FaLock />
          Change password
        </DropdownItem>

        <DropdownItem
          color={"outline-danger"}
          className="btn-icon btn-outline-danger"
          onClick={(e) => {

            dispatch(logout())
            navigate("/login")
          }
          }
        >
          <FaPowerOff />
          Sign out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
