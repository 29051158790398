import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { MediaFile } from "./common/MediaFile";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedResult } from "./common/PaginatedResult";

export interface ContactInfo {
    email: string;
    phoneNumber: string;
    linkedIn: string;
    faceBook: string;
    twitter: string;
    website: string;
}

export interface AddEditGlobalSponsorRequest {
    sponsorId?: number | null;
    name: string;
    description: string;
    logo: string | null;
    banner: string | null;
    contactInfo: ContactInfo;
    isOnHomePage: boolean;
}

export interface Sponsor {
    id: number;
    name: string;
    description: string;
    logo: MediaFile;
    banner: MediaFile;
    contact: ContactInfo;
    index?: number | null;
}

export const getGlobalSponsors = async (): Promise<PaginatedResult<Sponsor>> => {
    const result = await Axios.get<PaginatedResult<Sponsor>>(
        `/api/sponsors/`
    );
    return result.data;
};

export const getSponsorById = async (
    sponsorId: number
): Promise<any> => {
    const result = await Axios.get<any>(
        `/api/sponsors/${sponsorId}`
    );
    return result.data;
};

export const addSponsor = async (
    request: AddEditGlobalSponsorRequest
): Promise<NewResourceResult<number>> => {
    const { ...others } = request;
    const formData = objectToFormData(others);

    const result = await Axios.post<NewResourceResult<number>>(
        `/api/sponsors`,
        formData,
        {
            headers: { "Content-Type": "multipart/form-data" }
        }
    );

    return result.data;
};

export const updateSponsor = async (
    sponsorId: number,
    request: AddEditGlobalSponsorRequest
): Promise<{}> => {
    const {...others } = request;
    const formData = objectToFormData(others);

    const result = await Axios.put(`/api/sponsors/${sponsorId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    });

    return result.data;
};

export const deleteSponsor = async (sponsorId: number) => {
    await Axios.delete(`/api/sponsors/${sponsorId}`);
};
