import moment from "moment";
import * as React from "react";
import Helmet from "react-helmet";
import {FaEdit, FaPlus, FaTrash, FaTrashAlt} from "react-icons/fa";
import {Button, CardBody, CardHeader} from "reactstrap";
import {getPlans, IAddEditMembershipPlanModel, IDurationPlan} from "../../api/ProductsApi";
import {Loader} from "../../components/Loader";
import {MainCard} from "../../components/MainCard";
import AddEditMembershipPlan, {ModalStatus} from "./AddEditMembershipPlan";
import DeleteMembershipPlan from "./DeleteMembershipPlan";


import {EmptyListState} from "components/EmptyListState";
import {FiEye} from "react-icons/fi";
import DurationPlanViewComponent from "./DurationPlanViewComponent";

import emptyStateSpeakerImage from "assets/imgs/membership_plan.svg";
import { PaginationFooter } from "../../components/PaginationFooter";
import { useState } from "react";

export const ManageMembershipAreaPlans: React.FC = props => {

    const [plans, setPlans] = React.useState<Array<IAddEditMembershipPlanModel>>([]);
    const [openAddEditModal, setOpenAddEditModal] = React.useState<boolean>(false);
    const [itemToEdit, setItemToEdit] = React.useState<IAddEditMembershipPlanModel | null>(null);
    const [itemToDelete, setItemToDelete] = React.useState<IAddEditMembershipPlanModel | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [durationPlanView, setDurationPlanView] = React.useState<Array<IDurationPlan> | null>(null);
    const [planNameOfDurationPlan, setPlanNameOfDurationPlan] = React.useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [maximumPerPage] = useState(20);
    const fetchPlans = () => {
        setLoading(true);
        getPlans({pageIndex: page, limit: maximumPerPage}).then(data => {
            setLoading(false);
            setPlans(data.items);
            setCount((data as any).totalItemsCount)
        }).catch(err => {
            setLoading(false)
        })
    }
    React.useEffect(() => {
        fetchPlans();
    }, [setPlans,page])

    const openAddEditModalHandler = () => {
        setOpenAddEditModal(true);
    }

    const closeAddEditModalHandler = () => {
        setOpenAddEditModal(false);
        setItemToEdit(null);
    }

    const onDeleteHandler = () => {
        fetchPlans();
    }


    const onCancelDeleteHandler = () => {
        setItemToDelete(null);
    }


    const openDeleteModal = (itemToDelete: IAddEditMembershipPlanModel) => {
        setItemToDelete(itemToDelete);
    }

    const onUpdateAddEditModal = () => {
        setOpenAddEditModal(false);
        setItemToEdit(null);
        fetchPlans();
    }


    const openDurationPlanToView = (items: Array<IDurationPlan>, planName: string) => {
        setPlanNameOfDurationPlan(planName);
        setDurationPlanView(items);
    }

    const closeDurationPlanView = () => {
        setDurationPlanView(null);
        setPlanNameOfDurationPlan(null);
    }

    return (
        <div>
            <Helmet title="Membership Plans"/>

            {
                durationPlanView && planNameOfDurationPlan ?
                    <DurationPlanViewComponent DurationsPlan={durationPlanView} close={closeDurationPlanView}
                                               open={true} planName={planNameOfDurationPlan}/>
                    :
                    null
            }


            {
                openAddEditModal ?
                    <AddEditMembershipPlan
                        close={closeAddEditModalHandler}
                        item={itemToEdit}
                        onUpdated={onUpdateAddEditModal}
                        isOpen={openAddEditModal}
                        state={itemToEdit != null ? ModalStatus.__EDIT__ : ModalStatus.__CREATE__}

                    />
                    : null
            }


            {
                itemToDelete ?
                    <DeleteMembershipPlan cancelDeleting={onCancelDeleteHandler} itemToDelete={itemToDelete}
                                          onUpdated={onDeleteHandler}/> : null
            }
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>Membership Plans</div>
                    <div>
                        <Button
                            onClick={openAddEditModalHandler}
                            color={'primary'} size={'sm'}>
                            <FaPlus/> Add Membership Plan
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        plans.length > 0 && !loading ?
                            <table className={'table table-response'}>
                                <thead className="thead-dark">
                                <tr>
                                    <td>
                                        #
                                    </td>
                                    <td>
                                        Name
                                    </td>
                                    {/*<td>*/}
                                    {/*    Description*/}
                                    {/*</td>*/}
                                    {/*<td>*/}
                                    {/*    Quantity*/}
                                    {/*</td>*/}
                                    <td>
                                        Availability Start Date
                                    </td>
                                    <td>
                                        Availability End Date
                                    </td>
                                    <td>
                                        Actions
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    plans.map((plan: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td> {plan.name} </td>
                                                {/*<td>*/}
                                                {/*    <div dangerouslySetInnerHTML={{__html: plan.description}}>*/}
                                                {/*    </div>*/}
                                                {/*</td>*/}
                                                {/*<td> {plan.totalQuantity} </td>*/}
                                                <td>{plan.availabilityStartDateTime && moment(plan.availabilityStartDateTime).format("DD/MM/YYYY hh:mm")}</td>
                                                <td> {plan.availabilityEndDateTime && moment(plan.availabilityEndDateTime).format("DD/MM/YYYY hh:mm")}  </td>
                                                <td>
                                                    {plan.changeable ?
                                                    <>
                                                        <Button
                                                            color="primary"
                                                            outline={true}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setItemToEdit(plan);
                                                                setOpenAddEditModal(true);
                                                            }}
                                                        >
                                                            <FaEdit/>
                                                        </Button>{" "}

                                                        <Button
                                                            color="danger"
                                                            outline={true}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setItemToDelete(plan)
                                                            }}
                                                        >
                                                            <FaTrash/>
                                                        </Button>
                                                    </> : "N/A"
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={5}>
                                        <PaginationFooter
                                          onPageClicked={setPage}
                                          pageIndex={page}
                                          totalItemsCount={count}
                                          pageSize={maximumPerPage}
                                          pageItemsCount={plans.length}
                                        />
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            :
                            !loading ?
                                <div className={'text-center'}>
                                    <EmptyListState
                                        image={emptyStateSpeakerImage}
                                        title={"You have no Membership plans yet"}
                                        callToActionButton={{
                                            text: "Add your first membership plan",
                                            onClick: () => {
                                                openAddEditModalHandler()
                                            }
                                        }
                                        }

                                    />
                                </div> : null
                    }

                    {
                        loading ?
                            <Loader isLoading={true}/>
                            :
                            null
                    }
                </CardBody>
            </MainCard>
        </div>
    );
}