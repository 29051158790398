import {RemoveProductFromCoupon} from 'api/CouponApi';
import {Loader} from 'components/Loader';
import {ToastMessage} from 'components/ToastMessages';
import React, {Component} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


interface OwnProps {
    isOpen: boolean;
    close?: () => void;
    productId: number;
    couponId: number;
    onUpdate?: () => void;
}

type Props = OwnProps;
export default class ConfirmDeleteProduct extends Component<Props, any> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }

    }
    onConfirmHandler = () => {
        this.setState({ loading: true });
        RemoveProductFromCoupon(this.props.productId, this.props.couponId).then(_ => {
            this.setState({ loading: false })
            toast.success(
                <ToastMessage>
                    Delete product Successfully !
                </ToastMessage>
            )

            this.props.close?.();
            this.props.onUpdate?.();
        }, _ => {
            this.setState({ loading: false })
            toast.error(<ToastMessage>
                Error has been happened !
            </ToastMessage>)
        })
    }
    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ToastContainer />
                {this.state.loading ? <Loader isLoading={true} /> : null}
                <ModalHeader> Remove Product Form Coupon </ModalHeader>
                <ModalBody>
                    Are you sure for Remove Product from Coupon.
                </ModalBody>
                <ModalFooter className={"space-between"}>
                        <button className="btn btn-secondary" onClick={this.props.close}>
                            Cancel
                        </button>
                    <button
                        onClick={this.onConfirmHandler}
                        className="btn btn-danger">
                        Confirm
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}
