/* eslint-disable react/no-deprecated */
// @ts-ignore
import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteCurrency } from "../../api/CurrencyApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";

interface IDeleteCurrencyProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    currencyToDelete: {
        id: number,
        code: string,
        symbol: string
    }
}


interface IDeleteCurrencyState {
    __loading__: boolean;
    __id__: number;
}

class DeleteCurrencyModal extends Component<IDeleteCurrencyProps, IDeleteCurrencyState> {
    constructor(props: IDeleteCurrencyProps) {
        super(props);
        this.state = {
            __loading__: false,
            __id__: 0,
        }
    }

    componentWillMount() {

        if (this.props.currencyToDelete) {
            this.setState({ __id__: this.props.currencyToDelete.id });

        }
    }

    handleDeleting = () => {
        this.setState({ __loading__: true })
        deleteCurrency(this.state.__id__).then(r => {
            this.setState({ __loading__: false })
            toast.success(
                <ToastMessage type="Success">
                    Deleted Successfully !
                </ToastMessage>
            );
            if (this.props.onUpdated) {
                this.props.onUpdated();
            }
            if (this.props.cancelDeleting) {
                this.props.cancelDeleting();
            }

        }).catch(error => {
            this.setState({ __loading__: false })
            toast.error(
                <ToastMessage type="Error">
                    Error Has been happened !
                </ToastMessage>
            );
        })
    }

    render() {
        return (
            <Modal toggle={this.props.cancelDeleting} isOpen={!!this.props.currencyToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Currency
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ? <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.currencyToDelete &&
                        `${this.props.currencyToDelete.code || ""}`}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.currencyToDelete &&
                            `${this.props.currencyToDelete.code || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDeleting}>
                        Yes, Delete &quot;
                        {this.props.currencyToDelete &&
                            `${this.props.currencyToDelete.code || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteCurrencyModal;