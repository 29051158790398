import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AbstractType,
    AbstractTypesRequest,
    getAbstractTypes
} from "../api/AbstractTypes";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { AppThunk } from "./index";

//TODO: move the whole thing to abstractTypes

export interface SelectAbstractTypesListState {
    isBusy: boolean;
    abstractTypesResult: PaginatedResult<AbstractType> | null;
    error: string | null;
    eventId: number | null;
    request: AbstractTypesRequest | null;
}

const initialState: SelectAbstractTypesListState = {
    error: null,
    isBusy: false,
    abstractTypesResult: null,
    eventId: null,
    request: null
};

interface ReceiveSelectAbstractTypesListAction {
    error: string | null;
    abstractTypesResult: PaginatedResult<AbstractType> | null;
    eventId: number | null;
    request: AbstractTypesRequest | null;
}

const SelectAbstractTypesList = createSlice({
    name: "SelectPresentersList",
    initialState,
    reducers: {
        requestSelectAbstractTypesList: (state, action: PayloadAction) => {
            state.isBusy = true;
        },
        receiveSelectAbstractTypesList: (
            state,
            action: PayloadAction<ReceiveSelectAbstractTypesListAction>
        ) => {
            state.error = action.payload.error;
            state.isBusy = false;
            state.abstractTypesResult = action.payload.abstractTypesResult;
            state.request = action.payload.request;
            state.eventId = action.payload.eventId;
        }
    }
});

export default SelectAbstractTypesList.reducer;

const {
    requestSelectAbstractTypesList,
    receiveSelectAbstractTypesList
} = SelectAbstractTypesList.actions;

export const loadSelectAbstractTypesList = (
    eventId: number,
    request: AbstractTypesRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestSelectAbstractTypesList());
        const result = await getAbstractTypes(eventId, request);
        dispatch(
            receiveSelectAbstractTypesList({
                error: null,
                abstractTypesResult: result,
                eventId: eventId,
                request: request
            })
        );
    } catch (e) {
        dispatch(
            receiveSelectAbstractTypesList({
                error: "e.toString()",
                abstractTypesResult: null,
                eventId: eventId,
                request: request
            })
        );
    }
};

export const reloadSelectAbstractTypes = (): AppThunk => async (
    dispatch,
    getState
) => {
    const selectAbstractTypeState = getState().selectAbstractTypesList;

    if (selectAbstractTypeState.request && selectAbstractTypeState.eventId) {
        dispatch(
            loadSelectAbstractTypesList(
                selectAbstractTypeState.eventId,
                selectAbstractTypeState.request
            )
        );
    }
};
