import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    Committee,
    CommitteeByEventRequest,
    getCommitteeByEvent
} from "../api/Committees";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { AppThunk } from "./index";

export interface EventCommitteeListState {
    isBusy: boolean;
    request: CommitteeByEventRequest | null;
    result: PaginatedResult<Committee> | null;
    error: string | null;
}

const initialState: EventCommitteeListState = {
    isBusy: false,
    request: null,
    result: null,
    error: null
};

interface ReceiveCommitteeListAction {
    request: CommitteeByEventRequest;
    error: string | null;
    result: PaginatedResult<Committee> | null;
}

const EventCommitteeListSlice = createSlice({
    name: "EventCommitteeListSlice",
    initialState,
    reducers: {
        requestCommitteeList(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveCommitteeList(
            state,
            action: PayloadAction<ReceiveCommitteeListAction>
        ) {
            state.isBusy = false;
            state.request = action.payload.request;
            state.error = action.payload.error;
            state.result = action.payload.result;
        }
    }
});

export default EventCommitteeListSlice.reducer;

const {
    requestCommitteeList,
    receiveCommitteeList
} = EventCommitteeListSlice.actions;

export const loadCommitteeByEvent = (
    request: CommitteeByEventRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestCommitteeList());
        const comittees = await getCommitteeByEvent(request);
        dispatch(
            receiveCommitteeList({
                request: request,
                error: null,
                result: comittees
            })
        );
    } catch (e) {
        dispatch(
            receiveCommitteeList({
                error: "e.toString()",
                result: null,
                request: request
            })
        );
    }
};

export const reloadCommitteeByEventList = (): AppThunk => async (
    dispatch,
    getState
) => {
    const request = getState().eventCommitteeList.request;
    if (request) {
        dispatch(loadCommitteeByEvent(request));
    }
};
