import { Middleware } from "redux";

export const handleAuthStatusCodesMiddleware: Middleware = api => next => action => {
    switch (action.type) {
        case "INVALID_SUBSCRIPTION":
            api.dispatch(("/renew-subscribe") as any);
            break;
        case "UNAUTHORIZED": {

            //TODO: resend refresh token
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            const originalRequest = action.payload.config;
            window.location.href = "/login";

            break;
        }
        case "FORBIDDEN":
            window.location.href = "/forbidden";
            break;
    }
    return next(action);
};
