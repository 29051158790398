import Axios from "axios";
import {PaginatedResult} from "./common/PaginatedResult";
import {PostType} from "./PostsApi";
import {IDropdownList} from "./common/DropdownList";
import { PaginatedRequest } from "./common/PaginatedRequest";

export interface PostTag {
    id: number;
    name: string;
    pType: string;
    pTypeId?: number;
}


export const addPostTag = async (request: PostTag): Promise<PostTag> => {

    const response = await Axios.post(`/api/PostsTags`,
        request,
    );

    return response.data;
};

export const editPostTag = async (request: PostTag): Promise<PostTag> => {
    const response = await Axios.put(`/api/PostsTags/`+request.id,
        request,
    );

    return response.data;
};

export const deletePostTagById = async (id: number) => {
    return (await Axios.delete(`/api/PostsTags/${id}`)).data;
};

export const GetAllPostsTags = async ( request?:PaginatedRequest
): Promise<PaginatedResult<PostTag>> => {
    const response = await Axios.get<PaginatedResult<PostTag>>(
        "api/PostsTags",
      {params:request}
    );
    return response.data;
};

export const GetDropdownListByType = async (typeId: number): Promise<IDropdownList[]> => {
    const response = await Axios.get<IDropdownList[]>(
        "api/PostsTag/GetDropdownListByType?typeId="+typeId
    );
    return response.data;
};

export const GetPostTypes = async (): Promise<PostType[]> => {
    const response = await Axios.get<PostType[]>(
        "api/Posts/PostType"
    );
    return response.data;
};