import Axios from "axios";


interface Item {
  name: string;
  value: string;

}


export const getPlaceHolders = async (): Promise<Item[]> => {
  const result = await Axios.get<Item[]>(
    `/api/Messaging/Placeholders`,
  );

  return result.data;
};

export const getDeliveryStatus = async (): Promise<any[]> => {
  const result = await Axios.get<any[]>(
    `/api/Messaging/DeliveryStatus`,
  );

  return result.data;
};

export const getMessages = async (request: any): Promise<any> => {
  const result = await Axios.get<any>(
    `/api/Messaging`, { params: { ...request } }
  );

  return result.data;
};

export const sendEmail = async (body: any) => {
  await Axios.post<any>(
    `/api/Messaging/SendEmail`, body
  );

};

export const sendSms = async (body: any) => {
  await Axios.post<any>(
    `/api/Messaging/SendSms`, body
  );

};


