/* eslint-disable react/no-deprecated */
import classnames from "classnames";
import * as React from 'react';
import { AiOutlineArrowLeft, AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
    IRecipientDropdownList,
    IRecipientListDropdownList,
    getRecipientDropdownList,
    getRecipientListDropdownList
} from "../../api/RecipientLists";
import { CreateRegistrants, IWebinarRegistrants } from "../../api/WebinarApi";
import { Loader } from "../../components/Loader";
import RecipientsTableModal from "../../components/ReceipientGroup/RecipientsTableModal";
import { ToastMessage } from "../../components/ToastMessages";


interface AddRegistrantsModelProps {
    webinarId: number,
    close?: () => unknown;
    onUpdated?: () => unknown;
    isOpen: boolean
}

interface AddRegistrantsModelState {
    data: IWebinarRegistrants,
    loading: boolean,
    recipients: Array<IRecipientDropdownList>,
    recipientsList: Array<IRecipientListDropdownList>,
    activeTab: string;
    recipientsListEditable?: { id: number, name: string } | null;
    deSelectIds: Array<{ deSelectedIds: Array<number>, recipientListId: number }>,
    recipientListId?: number | null,
    openRecipientsTbl: boolean,

}

class AddRegistrantsModel extends React.Component<AddRegistrantsModelProps, AddRegistrantsModelState> {
    constructor(props: AddRegistrantsModelProps) {
        super(props);
        this.state = {
            loading: false,
            data: {
                email: '',
                address: null,
                firstName: '',
                lastName: null,

            },
            recipients: [],
            recipientsList: [],
            activeTab: "1",
            recipientsListEditable: null,
            openRecipientsTbl: false,
            deSelectIds: [],
        }
    }





    // Recipient list 
    fetchRecipientListHandler = () => {

        if (this.state.recipientsList.length > 0)
            return;
        this.setState({ loading: true })

        getRecipientListDropdownList().then((recipientsList: IRecipientListDropdownList[]) => {
            this.setState({ loading: false })

            this.setState({ recipientsList })
        }).catch(error => {
            this.setState({ loading: false })
        })
    }



    // fetch recipients.

    fetchRecipientsHandler = () => {
        this.setState({ loading: true })
        getRecipientDropdownList().then(recipients => {
            this.setState({ recipients, loading: false }, () => {
                ;
            });
        }).catch(error => {
            this.setState({ loading: false })
        })
    }


    onChangeHandler = (e: any) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let { data } = this.state;
        data = { ...data, [name]: value };
        this.setState({ data }, () => {
            ;
        });
    }

    onSubmitHandler = () => {
        const { data } = this.state;
        if (this.state.deSelectIds && this.state.deSelectIds.length > 0) {
            data.recipientGroupAsRegistrants = this.state.deSelectIds.map((item, index) => {
                return {
                    recipientListId: item.recipientListId,
                    deSelectedIds: item.deSelectedIds
                }
            })
        }
        CreateRegistrants(this.props.webinarId, data).then(res => {
            this.props.onUpdated?.();
            this.props.close?.();
            toast.success(
                <ToastMessage>
                    Add Registrant Successfully !
                </ToastMessage>
            )
        }).catch(error => {
            toast.error(
                <ToastMessage>
                    Error has been happened !
                </ToastMessage>
            )
        })
    }
    toggleTabs = (tab: any) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    // formatOptionLabel

    formatOptionLabelHandler = (option: any, labelMeta: any) => {

        return (
            <div className={'d-flex'}>
                <option value={option.value} className={"test"}>
                    {option.label}


                </option>

                {
                    labelMeta.context === 'value' ? <button
                        onClick={e => {
                            e.preventDefault();
                            // TODO : Open Recipient table
                            this.setState({ recipientsListEditable: { id: option.value, name: option.label }, openRecipientsTbl: true }, () => {

                            })
                        }}
                        className={'btn css-xb97g8'}> <FaEdit /> </button> : null
                }
                {
                    labelMeta.context === 'value' ?
                        this.state.deSelectIds.length > 0 && this.state.deSelectIds.find(x => x.recipientListId === option.value) ?
                            <div className={'flex-2'}>

                                <small style={{ color: 'red' }}> {this.state.deSelectIds.find(x => x.recipientListId === option.value)?.deSelectedIds.length.toString() + " deselected"} </small>
                            </div>
                            : null
                        : null
                }
            </div>
        );
    }


    closeRecipientsListEditable = () => {
        this.setState({ recipientsListEditable: null, openRecipientsTbl: false })
    }

    deSelectedRecipientsIdHandler = (deSelectId: { deSelectedIds: Array<number>, recipientListId: number }) => {

        let { deSelectIds } = this.state;
        deSelectIds = deSelectIds.filter(x => x.recipientListId !== deSelectId.recipientListId);
        if (deSelectId.deSelectedIds.length > 0) {
            deSelectIds.push(deSelectId);
        }
        this.setState({ deSelectIds, recipientListId: deSelectId.recipientListId }, () => {
            ;
        });
    }

    componentWillMount() {
        this.fetchRecipientsHandler();

    }
    render() {
        const recipients = this.state.recipients.map((item, key) => {
            return {
                value: item.id,
                label: item.name
            }
        });

        const recipientListOptions = this.state.recipientsList.map((item, key) => {
            return {
                value: item.id,
                label: item.name
            }
        });

        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    <i> <AiOutlineUsergroupAdd /> </i>  Add Registrant
                </ModalHeader>

                <ModalBody>


                    {/* Recipient table */}
                    {
                        this.state.openRecipientsTbl && this.state.recipientsListEditable ?
                            <RecipientsTableModal
                                sendDeSelectIds={this.state.deSelectIds.find(d => d.recipientListId === (this.state.recipientsListEditable ? this.state.recipientsListEditable.id : 0))}
                                close={this.closeRecipientsListEditable}
                                isOpen={this.state.openRecipientsTbl} recipientListId={this.state.recipientsListEditable.id}
                                recipientListName={this.state.recipientsListEditable.name} receiveDeSelectIds={this.deSelectedRecipientsIdHandler} />
                            : null
                    }


                    <Nav tabs>
                        <NavItem style={{ flex: 2 }}>
                            <NavLink
                                style={{ backgroundColor: '#f3f3f3' }}
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTabs('1'); }}
                            >
                                New Registrant
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ flex: 2 }}>
                            <NavLink
                                style={{ backgroundColor: '#f3f3f3' }}
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTabs('2'); }}
                            >
                                Recipients
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">

                            <div className="form-group mt-4">
                                <label htmlFor={'email'}> Email * </label>
                                <input type="email"
                                    onBlur={this.onChangeHandler}
                                    className={'form-control'} name="email" id="email" />
                            </div>
                            <div className="form-group">
                                <label htmlFor={'firstName'}> First Name * </label>
                                <input type="text"
                                    onBlur={this.onChangeHandler}
                                    className={'form-control'} name="firstName" id="firstName" />
                            </div>
                            <div className="form-group">
                                <label htmlFor={'lastName'}> Last Name  </label>
                                <input type="text"
                                    onBlur={this.onChangeHandler}
                                    className={'form-control'} name="lastName" id="lastName" />
                            </div>
                            <div className="form-group">
                                <label htmlFor={'address'}> Address  </label>
                                <input type="address"
                                    onBlur={this.onChangeHandler}
                                    className={'form-control'} name="address" id="address" />
                            </div>


                            <div className="form-group mt-4  d-flex ">
                                <button className="btn btn-light  mx-1 flex-grow-1"
                                    onClick={this.props.close}
                                >
                                    <i > <AiOutlineArrowLeft /> </i>
                                    Cancel </button>
                                <button
                                    onClick={this.onSubmitHandler}
                                    className="btn btn-primary mx-1 flex-grow-1" > Save </button>

                            </div>
                        </TabPane>
                        <TabPane tabId={"2"}>
                            <div className={'form-group'}>

                                <div className={'form-group'}>
                                    <label> Recipient Group : </label>
                                    <Select

                                        isMulti={true}
                                        options={recipientListOptions}
                                        onMenuOpen={this.fetchRecipientListHandler}
                                        isSearchable={true}
                                        formatOptionLabel={this.formatOptionLabelHandler}
                                        onChange={val => {
                                            ;
                                        }}
                                    />
                                </div>
                                <label> Recipients : </label>
                                <Select
                                    isMulti={true}
                                    options={recipients}
                                    onMenuOpen={this.fetchRecipientsHandler}
                                    isSearchable={true}
                                    onChange={val => {
                                        ;
                                    }}
                                />
                            </div>

                            <div className="form-group mt-4  d-flex ">
                                <button className="btn btn-light  mx-1 flex-grow-1"
                                    onClick={this.props.close}
                                >
                                    <i > <AiOutlineArrowLeft /> </i>
                                    Cancel </button>
                                <button
                                    onClick={this.onSubmitHandler}
                                    className="btn btn-primary mx-1 flex-grow-1" > Save </button>

                            </div>
                        </TabPane>
                    </TabContent>



                    {
                        this.state.loading ? <Loader isLoading={true} /> : null
                    }
                </ModalBody>

            </Modal>
        );
    }
}

export default AddRegistrantsModel;