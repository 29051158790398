import { SearchCategories } from 'api/ProductCategoriesApi';
import {
  AddJsonAddonProduct,
  AddJsonItemProduct,
  AddJsonSessionProduct,
  AddJsonTicketProduct,
  fetchAddonsList,
  GetProductDropdownList,
  IProductDropdownItem,
  ISearchItem,
  Product,
  ProductType,
  SearchItemsForAddons,
  SessionProduct,
  Ticket,
  UploadProductMedia
} from 'api/ProductsApi';
import { getTaxesDropdownList, ITax } from 'api/TaxApi';
import { Loader } from 'components/Loader';
import { ToastMessage } from 'components/ToastMessages';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { nameof } from 'util/nameof';
import './AddProductComponentStyle.css';
import { FaSpinner } from "react-icons/fa";
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { AddEditSessionForm, SpeakerSelectItem } from "../ManageEventSessions/AddEditSessionFormModal";
import { FormatOptionLabelMeta } from "react-select/base";
import { StringTruncate } from "../../components/StringTruncate";
import { getSpeakersWithoutEvent, searchSpeakers, Speaker } from "../../api/Speakers";
import { GetLocationsDropdownList, ILocationDropdownItem } from "../../api/LocationsApi";
import moment, { Moment } from "moment";
import {
  extractProductAddon,
  extractProductItem,
  extractProductSession,
  extractProductTicket
} from "../../util/productsUtil";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import AsyncSelect from "react-select/async";

export const AddProductComponent: React.FC<any> = ({ isOpen, toggle }) => {
  const [successfulAdd, setSuccessfulAdd] = useState(false);
  const [product, setProduct] = React.useState<Product | null>(null);
  const [categories, setCategories] = React.useState<Array<{ value: number, label: string, pcTypeId: number }>>([]);
  // initalize when change category
  const [productType, setProductType] = React.useState<ProductType | null>(null);
  const [showQuantity, setShowQuantity] = useState(false);

  const [selectedSpeakers, setSelectedSpeakers] = useState<number[]>([]);
  const [selectSpeakerListState, setSelectSpeakerListState] = useState<SpeakerSelectItem[]>([]);

  const [taxes, setTaxes] = React.useState<Array<{ value: number, label: string }>>([]);
  const [taxesData, setTaxesData] = React.useState<Array<ITax>>([]);

  const [speakers, setSpeakers] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [addons, setAddons] = useState<any[]>([]);

  const [selectedItem, setSelectedItem] = useState("Fixed amount");

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const taxesFetching = () => {
    getTaxesDropdownList().then(data => {
      if (data) {

        const taxes = data.map((item: ITax) => {
          return {
            value: item.id,
            label: `${item.name} (${item.value}%)`
          };
        });
        setTaxesData(data);
        setTaxes(taxes);
      }
    })
  }

  const [addonsList, setAddonsList] = useState<IProductDropdownItem[]>([]);
  const [itemsForAddons, setItemsForAddons] = useState<ISearchItem[]>([]);

  const [productsList, setProductsList] = useState<IProductDropdownItem[]>([]);
  const [locationsList, setLocationsList] = useState<ILocationDropdownItem[]>([]);

  const locationsFetching = () => {
    GetLocationsDropdownList().then(data => {
      setLocationsList([...data])
    }).catch(err => {
    });
  };


  const categoriesFetching = () => {
    SearchCategories(true).then(data => {
      if (data) {
        const categories = data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            pcTypeId: item.pcTypeId
          };
        })
        setCategories(categories);
      }
    })
  };

  const renderSpeakerOption = useCallback(
    (
      option: SpeakerSelectItem,
      labelMeta: FormatOptionLabelMeta<SpeakerSelectItem>
    ) => (
      <div className="d-flex align-items-center">
        {option.profilePic ? (
          <img
            src={option.profilePic.fullUrl}
            width={30}
            height={30}
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        ) : (
          <div
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        )}
        <div>
          {option.label}
          <div className="text-muted small">
            <StringTruncate
              text={option.bio || ""}
              truncateSize={30}
            />
          </div>
        </div>
      </div>
    ),
    []
  );

  const mapSpeakerToSpeakerSelectItem = (
    speaker: Speaker
  ): SpeakerSelectItem => {
    return {
      label: `${speaker.title || ""} ${speaker.firstName
      } ${speaker.middleName || ""} ${speaker.lastName}`,
      value: speaker.id,
      bio: speaker.bio,
      profilePic: speaker.profilePicture || null
    };
  };

  const speakersFetching = () => {
    getSpeakersWithoutEvent()
      .then(data => setSelectSpeakerListState(data.items.map(mapSpeakerToSpeakerSelectItem)))
  }


  const addonsFetching = () => {
    fetchAddonsList().then(products => setAddonsList([...products])).catch(err => {
    })
  }

  const productsFetching = () => {
    GetProductDropdownList().then(products => setProductsList([...products])).catch(err => {
    })
  }

  const itemsForAddonsFetching = (text: string | null) => {
    if (text && text.length > 2 || text == null)
      SearchItemsForAddons(text ?? "").then(items => {
        setItemsForAddons(items);
      })
  }

  const handleCategoryChange = (cat: any) => {
    if (cat.pcTypeId === ProductType.addon_on) itemsForAddonsFetching(null);
    if (cat.pcTypeId === ProductType.session) setShowQuantity(true);
  }

  const loadDependencies = () => {
    categoriesFetching();
    taxesFetching();
    speakersFetching();
    locationsFetching();
    // productsFetching();
    addonsFetching();
  }

  useEffect(() => {
    if (isOpen) {
      loadDependencies();
    }
  }, [isOpen])

  const calcTotalPrice = () => {
    const _taxes: Array<ITax> = [];
    if (!product?.price) return;

    const amount = product.price.amount;
    const discount = product.price.discount;
    const discountPercentage = product.price.isDiscountPercentage;
    let totalTax = 0;
    if (product.price.taxesIds) {
      for (let i = 0; i < product.price.taxesIds.length; i++) {
        let _tax = taxesData.find((item: ITax) => item.id === product.price.taxesIds[i]);
        if (_tax)
          _taxes.push(_tax)
      }
      _taxes.forEach((tax: ITax) => {
        totalTax += tax.value ?? tax.rate;
      })

    }
    let totalPrice = amount;

    if (discount) {
      if (discountPercentage) totalPrice -= amount * discount / 100;
      else totalPrice -= discount;
    }

    if (totalTax > 0) {
      totalPrice = +(+totalPrice * (1 + (totalTax / 100))).toFixed(2);
    }

    const price = product.price;
    price.amount = amount;
    price.totalPrice = totalPrice;
    price.displayTotalPrice = "" + totalPrice;
    setProduct({ ...product, price: price })
  }

  const onChangeHandler = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setProduct((old: any) => {

      if (!old) {
        old = {};
      }
      old = { ...old, [name]: value }
      return old;
    });

    objValidator();
  }


  // check if object ready to submit or not.
  const [loading, setLoading] = React.useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = React.useState<boolean>(false);

  const objValidator = () => {
    if (product
      && product.name
      && product.name.length > 0
      && product.price
      && (productType !== 1 || ((product as Ticket).startDateTime && (product as Ticket).endDateTime))
      && product.categoryId > 0) {
      setReadyToSubmit(true)
    } else {
      setReadyToSubmit(false)

    }

  }

  const addProductHandler = () => {

    let addPromise = null;
    setLoading(true);
    const taxesIds = product?.price?.taxesIds ?? [];
    const assignedAddons = product?.assignedAddons ?? [];
    setTimeout(() => {
      product && setProduct({
          ...product,
          price: { ...product?.price, taxesIds: taxesIds },
          assignedAddons: assignedAddons
        }
      )
    }, 0);
    switch (productType) {

      case ProductType.item:
        addPromise = addItemHandler();
        break;
      case ProductType.session:
        addPromise = addSessionHandler();
        break;
      case ProductType.ticket:
        addPromise = addTicketHandler();
        break;
      case ProductType.addon_on:
        addPromise = addAddonHandler();
        break;
      default:
        addPromise = addItemHandler();
        break;
    }


    addPromise.then((resp: any) => {
      if (resp && product?.images) {
        UploadProductMedia(resp, previewImages).then(r => {
          setLoading(false);
          toast.success(
            <ToastMessage>
              Product added successfully !
            </ToastMessage>
          );
          handleRedirect();
        });
      } else {
        setLoading(false);
        toast.success(
          <ToastMessage>
            Product added successfully !
          </ToastMessage>
        );
        handleRedirect();
      }
    }).catch((err: any) => {
      setLoading(false)
      toast.error(
        <ToastMessage>
          Error adding product !
        </ToastMessage>
      );
    })
  }

  // add ticket handler

  const addTicketHandler = (): Promise<any> => {

    const productTicket = extractProductTicket(product, locations[0]);
    return AddJsonTicketProduct(productTicket);
  }

  // add session handler

  const addSessionHandler = (): Promise<any> => {
    const productSession = extractProductSession(product, product?.categoryId ?? 4, speakers, locations);
    return AddJsonSessionProduct(productSession);
  }
  // add item handler

  const addItemHandler = (): Promise<any> => {
    const productItem = extractProductItem(product);
    return AddJsonItemProduct(productItem);
  }

  const addAddonHandler = (): Promise<any> => {
    const productAddon = extractProductAddon(product, product?.categoryId ?? 9);
    return AddJsonAddonProduct(productAddon);
  }

  const handleRedirect = () => {
    handleToggle(true);
  }

  // working with images context.

  const [previewImages, setPreviewImages] = React.useState<string[]>([]);
  const [imagesFiles, setImagesFile] = React.useState<Array<File>>([]);
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };


  // use effect for image

  React.useEffect(() => {
    setProduct(old => {

      return { ...old, images: imagesFiles, mediaList: imagesFiles } as Product
    })
  }, [imagesFiles])

  const handleImagesChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {

      const fileList = e.target.files as FileList;


      if (fileList && fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i] as File;


          if (file.type.startsWith("image/")) {
            if (typeof FileReader !== "undefined") {
              const fileReader = new FileReader();
              fileReader.onloadend = () => {

                setImagesFile(old => {
                  return [...old, file]
                })

                setPreviewImages(old => {
                  const newOne = fileReader.result as string;
                  return [...old, newOne];
                })


              }


              fileReader.readAsDataURL(file);
            }
          }

        }
      } else {
        setPreviewImages([]);
        setImagesFile([]);
      }


    },
    [setPreviewImages],
  )


  const form = (formProps: FormikProps<any>) => (
    <Form>
      <ModalBody>
        <FormGroup>
          <Label> Name* </Label>
          <Input name={nameof<Product>("name")}
                 onChange={onChangeHandler}/>
        </FormGroup>
        {/* <FormGroup>
                    <Label> Name Arabic </Label>
                    <Input name={nameof<Product>("nameAr")}
                           onChange={onChangeHandler}/>
                </FormGroup>*/}

        <FormGroup>
          <Label> Description </Label>
          <Input
            name={nameof<Product>("description")}
            onChange={onChangeHandler}/>

        </FormGroup>
        <FormGroup>
          <Label> Category </Label>
          <Select
            isMulti={false}
            options={categories}
            onChange={(o: any) => {
              handleCategoryChange(o);
              setProduct((old: any) => {

                old = { ...old };
                if (old && !old.category)
                  old.category = {};
                old.categoryId = o.value;
                
                setProductType(o.pcTypeId);
                return old;
              });
              objValidator();

              // let prod = product;
              // if(!prod) prod = {} as any;
              // if(prod && !prod.category) prod.category = {} as any;
              // if(prod) {
              //     prod.category['id'] = o.value ;
              //     setProduct(prod);
              // }
            }}/>
        </FormGroup>

        {product &&
        <>
            <Row>
                <Col sm={12} md={6} xl={6}>
                    <FormGroup>
                        <Label>Availability Start Date Time</Label>
                        <ReactDatetime
                            dateFormat={"DD/MM/YYYY"}
                            onChange={value => {
                              setProduct({
                                  ...product,
                                  availabilityStartDateTime: moment(value).toISOString()
                                }
                              )
                              objValidator();
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    <FormGroup>
                        <Label> Availability End Date Time </Label>
                        <ReactDatetime
                            dateFormat={"DD/MM/YYYY"}
                            onChange={(value: Moment | string) => {
                              setProduct({
                                  ...product,
                                  availabilityEndDateTime: moment(value).toISOString()
                                }
                              )
                              objValidator();
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
        }

        {
          product && productType === 1 &&
          <>
              <FormGroup>
                  <Label>
                      Locations
                  </Label>
                  <AsyncSelect
                      isClearable
                      isMulti
                      defaultOptions={locationsList}
                      value={locations}
                      onChange={options => {
                        setLocations(options as any[]);
                      }}
                      className="form-control flex-2"
                      placeholder="Select Location"
                      getOptionLabel={(option) => option.name}
                      loadOptions={async (val) => {
                        return await GetLocationsDropdownList(val);
                      }}

                  />
                  <ErrorMessage
                      name={"locations"}
                      render={error => (
                        <FormFeedback className="d-block">
                          {error}
                        </FormFeedback>
                      )}/>
              </FormGroup>
              <Row>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Start Date Time</Label>
                          <ReactDatetime
                              dateFormat={"DD/MM/YYYY"}
                              onChange={value => {
                                setProduct((old: any) => {
                                  if (!old) old = {};
                                  old = old as Ticket | SessionProduct;
                                  old.startDateTime = moment(value).toDate();
                                  ;
                                  return old;
                                })

                                objValidator();

                              }}
                          />
                      </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label> End Date Time </Label>
                          <ReactDatetime
                              dateFormat={"DD/MM/YYYY"}
                              onChange={(value: Moment | string) => {
                                setProduct((old: any) => {
                                  if (!old) old = {};
                                  old = old as Ticket | SessionProduct;
                                  old.endDateTime = moment(value).toDate();
                                  return old;
                                })
                                objValidator();

                              }}
                          />
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Limit Per Day</Label>
                          <Input type="number" name={nameof<Ticket>("perDayLimit")}

                                 onChange={e => {
                                   e.preventDefault();
                                   const value = e.target.value as unknown as number;
                                   setProduct(prevState => {
                                     let newState: any = {}
                                     if (prevState) newState = { ...prevState }
                                     newState.perDayLimit = value;
                                     return newState;
                                   })
                                 }}/>
                      </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Days Limit</Label>
                          <Input type="number" name={nameof<Ticket>("daysNoLimit")}
                                 onChange={e => {
                                   e.preventDefault();
                                   const value = e.target.value as unknown as number;
                                   setProduct(prevState => {
                                     let newState: any = {}
                                     if (prevState) newState = { ...prevState }
                                     newState.daysNoLimit = value;
                                     return newState;
                                   })
                                 }}/>
                      </FormGroup>
                  </Col>
              </Row>
          </>
        }

        {product && productType === 3  &&
        <>
            <FormGroup>
                <Label>
                    Instructors
                </Label>
                <Field
                    render={({ field }: FieldProps) => (
                      <AsyncSelect
                        isClearable
                        isMulti
                        defaultOptions={selectSpeakerListState}
                        value={speakers}
                        onChange={options => {
                          const selected = options.map(op => op.value);
                          setSpeakers(options as any[]);
                          setProduct({ ...product, instructors: selected })
                        }}
                        className="form-control flex-2"
                        placeholder="Select Customer"
                        formatOptionLabel={renderSpeakerOption}
                        loadOptions={async (val) => {
                          return (await searchSpeakers(val)).map(mapSpeakerToSpeakerSelectItem);
                        }}

                      />
                    )}
                    name={nameof<AddEditSessionForm>(
                      "selectedSpeakers"
                    )}/>
                <ErrorMessage
                    name={nameof<AddEditSessionForm>(
                      "selectedSpeakers"
                    )}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}/>
            </FormGroup>

            <FormGroup>
                <Label>
                    Locations
                </Label>
                <AsyncSelect
                    isClearable
                    isMulti
                    defaultOptions={locationsList}
                    value={locations}
                    onChange={options => {
                      setLocations(options as any[]);
                    }}
                    className="form-control flex-2"
                    placeholder="Select Location"
                    getOptionLabel={(option) => option.name}
                    loadOptions={async (val) => {
                      return await GetLocationsDropdownList(val);
                    }}

                />
                <ErrorMessage
                    name={"locations"}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}/>
            </FormGroup>
        </>
        }
        {

          product && (productType === 0 || productType === 3) &&
          <FormGroup>
              <Label>
                  Assigned Addons
              </Label>
              <AsyncSelect
                  isClearable
                  isMulti
                  defaultOptions={addonsList}
                  value={addons}
                  onChange={options => {
                    const selected = options.map(op => op.id);
                    setAddons(options as any[]);
                    setProduct({ ...product, assignedAddons: selected })
                  }}
                  className="form-control flex-2"
                  placeholder="Select Addons"
                  getOptionLabel={(option) => option.nameEn}
                  loadOptions={async (val) => {
                    return await fetchAddonsList(val);
                  }}

              />
              <ErrorMessage
                  name={"assignedAddons"}
                  render={error => (
                    <FormFeedback className="d-block">
                      {error}
                    </FormFeedback>
                  )}/>
          </FormGroup>
        }
        {product && productType === 5 &&
        <FormGroup>
            <Label>
                Related Products
            </Label>
            <Select
                isMulti={true}
                options={itemsForAddons}
                onInputChange={itemsForAddonsFetching}
                onChange={(o: any) => {
                  const productIds = o.map((p: { id: any; }) => p.id);
                  setProduct({ ...product, assignedToProducts: productIds });
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
            />
            <ErrorMessage
                name={"relatedProducts"}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}/>
        </FormGroup>
        }

        {/* Add Set of images. */}
        <ImageUploader images={previewImages} setImages={setPreviewImages}/>


        {/*<FormGroup>
                    <label> Product Images
                        <TooltipHintComponent text="images of product will display in your site."/>
                    </label>

                    <Input
                        type="file"
                        multiple
                        value={undefined}
                        accept="image/*"
                        onChange={handleImagesChange}/>

                </FormGroup>

                {previewImages && previewImages.length > 0 ?

                    <div className="images-container"
                         style={{padding: '10px', border: '1px dashed red'} as React.CSSProperties}
                    >
                        <Slider {...sliderSettings}>
                            {previewImages.map((img, i) => {
                                return (
                                    <div className="p-2 mx-1 w-auto img-container"
                                         key={i.toString()}
                                    >
                                        <div className="d-none remove-container">
                                            <button
                                                onClick={e => {
                                                    setPreviewImages(old => {
                                                        old.splice(i, 1);

                                                        return [...old];
                                                    });
                                                    setImagesFile(old => {
                                                        old?.splice(i, 1);

                                                        return old;
                                                    });
                                                    clearFileInput(e);
                                                }}

                                                className="remove-btn btn btn-danger">
                                                <AiOutlineDelete/>
                                            </button>
                                        </div>

                                        <img
                                            src={img}
                                            className="rounded thumbnail"
                                            style={{
                                                height: 265,
                                                width: '100%',
                                                padding: '5px',
                                                boxShadow: '0px 0px 2px rgba(0,0,0,0.2)'
                                            }}/>
                                    </div>
                                );
                            })}

                        </Slider>

                    </div> :
                    null}

                */}
        <button
          className={"btn btn-outline-secondary"}
          onClick={e => {
            e.preventDefault();
            ;
          }}
        >
          Display Images File
        </button>

        {/* end images  */}

        <Button
          className="my-4"
          block
          color="secondary"
          outline
          onClick={e => {
            e.preventDefault();
            setShowQuantity(!showQuantity)
          }}
        >
          {showQuantity ? "Hide" : "Add"} Quantity
        </Button>
        {showQuantity ? <>
            <FormGroup>
              <Label> Minimum Quantity Per Order </Label>
              <Input type="number" name={nameof<Product>('minQTYPerOrder')}
                     onChange={onChangeHandler}/>
            </FormGroup>

            <FormGroup>
              <Label> Maximum Quantity Per Order </Label>
              <Input type="number"
                     name={nameof<Product>('maxQTYPerOrder')}
                     onChange={onChangeHandler}/>
            </FormGroup>


            <FormGroup>
              <Label> Total Quantity* </Label>
              <Input type="number"
                     name={nameof<Product>('totalQTY')}
                     onChange={onChangeHandler}/>
            </FormGroup>
          </>
          : null
        }
        <FormGroup>
          <Label> Price* </Label>
          <Input type="number"

                 onChange={e => {
                   e.preventDefault();
                   const value = e.target.value as unknown as number;
                   setProduct((old: any) => {

                     if (!old || !old.price)
                       old = { ...old, price: {} };
                     old.price.amount = value;
                     if (value <= 0) {
                       old.price.taxesIds = [];
                       old.price.discount = 0;
                     }
                     return old;
                   });
                   setTimeout(() => {
                     calcTotalPrice();
                   }, 500);

                   objValidator();
                 }}/>
        </FormGroup>

        <FormGroup>
          <Label> Discount </Label>
          <InputGroup>
            <Input type="number"
                   disabled={!product?.price || product?.price.amount <= 0}
                   value={product?.price?.discount}
                   onChange={e => {
                     e.preventDefault();
                     const value = e.target.value as unknown as number;
                     // if(!product?.price || value > product?.price?.amount) return;
                     setProduct((old: any) => {
                       if (!old || !old.price)
                         old = { ...old, price: {} };
                       old.price.discount = value;
                       return old;
                     });
                     setTimeout(() => {
                       calcTotalPrice();
                     }, 500);

                     objValidator();
                   }}/>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                {product?.price && product.price.isDiscountPercentage === true ? '%' : 'Fixed amount'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setProduct((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = true;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);

                  objValidator();
                }}>
                  %
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setProduct((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = false;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);

                  objValidator();
                }}>
                  Fixed amount
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </InputGroup>

        </FormGroup>

        <FormGroup>
          <Label> Taxes </Label>
          <Select
            isDisabled={!product?.price || !product.price.totalPrice || product.price.totalPrice <= 0}
            value={product?.price?.taxesIds && taxes.filter(t => product.price.taxesIds.includes(t.value))}
            isMulti={true}
            options={taxes}
            onChange={(o: any) => {
              o = o as Array<{ label: string; value: number; }>;
              const taxesIds: Array<number> = [];
              if (o != null) {
                setProduct((old: any) => {
                  for (let i = 0; i < o.length; i++) {
                    const element = o[i].value;
                    taxesIds.push(element);
                  }
                  if (!old?.price)
                    old = { ...old, price: {} };
                  old.price.taxesIds = taxesIds;
                  return old;
                });
              } else {
                setProduct((old: any) => {
                  if (!old?.price?.taxesIds) {
                    old = { ...old, price: { ...old?.price, taxesIds: [] } };
                  } else {

                    old.price.taxesIds = [];
                  }

                  return old;
                });
              }

              setTimeout(() => {
                calcTotalPrice();
              }, 500);
            }}/>
        </FormGroup>

        <FormGroup>
          <Label> Total Price : {" "}
            {product?.price?.amount && <b color="black">{product?.price?.totalPrice} SAR</b>}
          </Label>
          {/*<Input readOnly={true} value={product?.price?.totalPrice}/>*/}
        </FormGroup>
        <FormGroup switch>
          <Label style={{marginRight:"5px"}}>Published</Label>

          <Input
            type="switch"
            checked={product?.isPublished}
            id={"isOnHomePage"}
            onChange={e => {
              setProduct((prevState:any) => ({...prevState,isPublished:e.target.checked}))

            }}
          />
        </FormGroup>
      </ModalBody><ModalFooter className="space-between">
      <Button type="button" color="secondary" onClick={() => handleToggle(false)}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        disabled={loading || !readyToSubmit}
      >
        {loading && (
          <span>
                            <FaSpinner className="icon-spin"/>
                        </span>
        )}{" "}
        Save
      </Button>
    </ModalFooter>
    </Form>
  );

  const handleToggle = (success: boolean) => {
    setImagesFile([]);
    setPreviewImages([]);
    setProduct(null);
    toggle(success);
  }
  return (
    <div>
      {
        loading ?
          <Loader isLoading={true}/>
          : null
      }
      <Modal isOpen={isOpen} toggle={() => handleToggle(false)} size="lg">
        <ModalHeader toggle={() => handleToggle(false)}>Add Product</ModalHeader>
        <Formik
          onSubmit={addProductHandler}
          initialValues={{}}>
          {form}
        </Formik>
      </Modal>
    </div>
  );
}
