import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../api/common/PaginatedResult";
import {
    getPresentersForEvent,
    Presenter,
    PresentersForEventRequest
} from "../api/Presenters";
import { AppThunk } from "./index";

export interface SelectPresentersListState {
    isBusy: boolean;
    eventId: number | null;
    presentersResult: PaginatedResult<Presenter> | null;
    error: string | null;
}

const initialState: SelectPresentersListState = {
    error: null,
    isBusy: false,
    presentersResult: null,
    eventId: null
};

interface ReceiveSelectEventPresentersListAction {
    error: string | null;
    presentersResult: PaginatedResult<Presenter> | null;
    eventId: number | null;
}

const SelectPresentersList = createSlice({
    name: "SelectPresentersList",
    initialState,
    reducers: {
        requestSelectPresentersList: (state, action: PayloadAction) => {
            state.isBusy = true;
        },
        receiveSelectPresentersList: (
            state,
            action: PayloadAction<ReceiveSelectEventPresentersListAction>
        ) => {
            state.error = action.payload.error;
            state.eventId = action.payload.eventId;
            state.isBusy = false;
            state.presentersResult = action.payload.presentersResult;
        }
    }
});

export default SelectPresentersList.reducer;

const {
    requestSelectPresentersList,
    receiveSelectPresentersList
} = SelectPresentersList.actions;

export const loadSelectPresentersList = (
    eventId: number,
    request: PresentersForEventRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestSelectPresentersList());
        const result = await getPresentersForEvent(eventId, request);
        dispatch(
            receiveSelectPresentersList({
                eventId: eventId,
                error: null,
                presentersResult: result
            })
        );
    } catch (e) {
        dispatch(
            receiveSelectPresentersList({
                error: "e.toString()",
                presentersResult: null,
                eventId: eventId
            })
        );
    }
};
