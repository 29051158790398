import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { NewResourceResult } from "./common/NewResourceResult";

export interface UploadCSVRequest {
    CsvFile: File;
    Name: string;
}

export interface CSVMetaData {
    fileName: string;
    contentType: string;
    fullUrl: string;
    sizeInBytes: number;
}

export const uploadFile = async (
    request: UploadCSVRequest,
    eventId: number
): Promise<NewResourceResult<number>> => {
    const formData = objectToFormData(request);
    const result = await Axios.post<NewResourceResult<number>>(
        `/api/Events/${eventId}/RecipientLists/csv`,
        formData,
        {
            headers: { "Content-Type": "multipart/form-data" }
        }
    );

    return result.data;
};

export const getMediaUrl = async (fileId: number): Promise<CSVMetaData> => {
    const result = await Axios.get<CSVMetaData>(
        `/api/MediaLibrary/${fileId}`
    );
    return result.data;
};
