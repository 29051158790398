import Axios from "axios";
import fileDownload from "js-file-download";
import { IDropdownList } from "./common/DropdownList";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface Recipient {
  id: number;
  name: string;
  eventId?: number | null;
  eventName?: string | null;
}

export interface AddRecipientToListUpdate {
  recipientListId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface AddRecipientToList
  extends Omit<AddRecipientToListUpdate, "recipientListId"> {}

export interface AddRecipientGroup extends Omit<Recipient, "id"> {}

export interface PaginatedRequestList
  extends Omit<PaginatedRequest, "eventId"> {
  recipientListId: number;
  query?: string | null;
}

export interface RecipientListItem {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: number | null;
  recipientList: unknown;
}

export const getCSVFile = async (id: number, name: string): Promise<any> => {
  await Axios.get(`/api/RecipientLists/${id}/csv`, {
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, name + ".csv");
  });
};

export const getCSVFileExample = async (): Promise<any> => {
  await Axios.get(`/api/RecipientLists/example`, {
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, "example" + ".csv");
  });
};

export const search = async (
  eventId: number,
  key: string,
  id: number
): Promise<any> => {
  const result = await Axios.get(
    `/api/${eventId}/RecipientLists/${id}/Recipients/Search?key=${key}`
  );

  return result.data;
};

export const getRecipientGroup = async (
  eventId: number,
  request: PaginatedRequest
): Promise<PaginatedResult<Recipient>> => {
  const result = await Axios.get<PaginatedResult<Recipient>>(
    `/api/Events/${eventId}/RecipientLists`,
    { params: request }
  );
  return result.data;
};

// get recipient list regardless of event

export const getAll = async (
  request: PaginatedRequest
): Promise<PaginatedResult<Recipient>> => {
  const response = await Axios.get<PaginatedResult<Recipient>>(
    `api/RecipientLists/GetAll`,
    { params: request }
  );
  return response.data;
};

export const deleteRecepientGroup = async (id: number): Promise<void> => {
  await Axios.delete(`/api/RecipientLists/${id}`);
};
// export  const downloadRecepientGroup = async (id : number) : Promise<void> => {
//   await  Axios.get(`/api/RecipientLists/${id}/csv`)
// }
export const deleteRecepientListItem = async (id: number): Promise<void> => {
  await Axios.delete(`/api/Recipients/${id}`);
};

export const addRecipientGroup = async (
  eventId: number,
  request: AddRecipientGroup
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post<NewResourceResult<number>>(
    `/api/events/${eventId}/RecipientLists`,
    request
  );

  return result.data;
};

// api for add recipient group seperated from event id.

export const AddNewRecipientGroup = async (
  payload: AddRecipientGroup
): Promise<any> => {
  const response = await Axios.post(
    "api/RecipientLists/AddNewRecipientList",
    payload
  );
  return response.data;
};

export const addRecipientToList = async (
  recipientListId: number,
  request: AddRecipientToList
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post<NewResourceResult<number>>(
    `api/RecipientLists/${recipientListId}/Recipients`,
    request
  );

  return result.data;
};

export const updateRecipientToList = async (
  recipientListId: number,
  request: AddRecipientToListUpdate
): Promise<NewResourceResult<number>> => {
  const result = await Axios.put<NewResourceResult<number>>(
    `api/Recipients/${recipientListId}`,
    request
  );

  return result.data;
};

export const getRecipientList = async (
  recipientListId: number,
  request: PaginatedRequestList
): Promise<PaginatedResult<RecipientListItem>> => {
  const result = await Axios.get<PaginatedResult<RecipientListItem>>(
    `/api/RecipientLists/${recipientListId}/Recipients`,
    { params: request }
  );
  return result.data;
};

// get recipient as dropdown list.

export interface IRecipientDropdownList extends IDropdownList {
  email: string;
}

export const getRecipientDropdownList = async (): Promise<Array<
  IRecipientDropdownList
>> => {
  const result = await Axios.get<Array<IRecipientDropdownList>>(
    "/api/Recipients/DropdownList"
  );
  return result.data;
};

// get recipient list as dropdown list.

export interface IRecipientListDropdownList extends IDropdownList {}

export const getRecipientListDropdownList = async (): Promise<Array<
  IRecipientListDropdownList
>> => {
  const result = await Axios.get<Array<IRecipientListDropdownList>>(
    "api/RecipientLists/GetDropdownList"
  );
  return result.data;
};


export interface IRecipientListDropdownListWithPhone extends IDropdownList {
  phoneNumber: string;
}

export const getDropdownListWithPhone = async (): Promise<Array<IRecipientListDropdownListWithPhone>> => {
  const response = await Axios.get(`api/Recipients/GetDropdownListWithPhone`);
  return response.data;
}