/* eslint-disable react/no-deprecated */
import * as React from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addCurrency, editCurrency } from "../../api/CurrencyApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";


export enum ModalStatus {
    __CREATE__, __EDIT__
}
export interface IAddEditCurrencyPaymentModal {
    id: number | null;
    currencyName: string | null;
    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
    modalStatus: ModalStatus;
    item?: IAddEditCurrency | null
}

export interface IAddEditCurrency {
    id: number;
    currencyCode: string | undefined;
    currencySymbol: string | undefined;
    isDefault: boolean;
}


export interface IAddEditCurrencyState {
    __data__: IAddEditCurrency;
    __formValid__: boolean;
    __loading__: boolean;

}

const __initState__: IAddEditCurrencyState = {
    __data__: {
        id: 0,
        currencyCode: undefined,
        isDefault: false,
        currencySymbol: undefined
    },
    __formValid__: false,
    __loading__: false,

};
//@ts-ignore
export class AddEditCurrencyPaymentModal extends React.Component<
    IAddEditCurrencyPaymentModal, IAddEditCurrencyState
> {

    constructor(props: IAddEditCurrencyPaymentModal) {
        super(props);

        this.state = __initState__;

    }

    componentWillMount() {

        if (this.props.item) {
            this.setState({ __data__: this.props.item });
        }
    }


    changeHandler = (e: any) => {
        const name = e.target.name;
        let val: string | boolean = e.target.value;
        let data: IAddEditCurrency = this.state.__data__;
        if (e.target.type === 'checkbox') {
            val = e.target.checked;
        }
        data = { ...data, [name]: val };
        this.setState({ __data__: data }, () => {
            this.validationHandler();
        });
    }

    validationHandler = () => {
        if (this.state.__data__.currencySymbol && this.state.__data__.currencySymbol.length > 0
            && this.state.__data__.currencyCode && this.state.__data__.currencyCode.length > 0
        ) {
            this.setState({ __formValid__: true })
        } else {
            this.setState({ __formValid__: false })
        }
    }

    submitHandler = (e: any) => {
        e.preventDefault();
        this.setState({ __loading__: true });
        if (this.props.modalStatus === ModalStatus.__CREATE__) {
            addCurrency(this.state.__data__).then(__response__ => {
                // log
                this.setState({ __loading__: false });
                if (this.props.onUpdated) {
                    this.props.onUpdated();
                }
                toast.success(
                    <ToastMessage type="Success">
                        Currency Of Code {this.state.__data__.currencyCode} add Successfully;
                    </ToastMessage>
                );
                if (this.props && this.props.close) {
                    this.props.close();
                }
            }).catch(error => {
                toast.error(
                    <ToastMessage type="Error">
                        &quot;  {this.state.__data__.currencyCode}  or {this.state.__data__.currencySymbol} &quot; is Exist Before
                    </ToastMessage>
                );
                this.setState({ __loading__: false });
            })
        } else {
            editCurrency(this.state.__data__.id, this.state.__data__).then(r => {
                this.setState({ __loading__: false });
                if (this.props.onUpdated) {
                    this.props.onUpdated();
                }
                if (this.props && this.props.close) {
                    this.props.close();
                }
                toast.success(
                    <ToastMessage type="Success">
                        Updated Successfully !
                    </ToastMessage>
                );
            }).catch(error => {
                this.setState({ __loading__: false });
                toast.error(
                    <ToastMessage type="Error">
                        Error has been happened !
                    </ToastMessage>
                );
            })

        }

    }
    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    {
                        this.props.modalStatus === ModalStatus.__EDIT__ ? " Edit Currency" : "Add Currency"
                    }
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label> Code  </label>
                        <input value={this.state.__data__.currencyCode} name={"currencyCode"} type="text" className="form-control"
                            onChange={this.changeHandler}
                        />
                    </div>
                    <div className="form-group">
                        <label> Symbol  </label>
                        <input value={this.state.__data__.currencySymbol} name={"currencySymbol"}
                            onChange={this.changeHandler}
                            type="text" className="form-control" />
                    </div>
                    <div className="form-check">
                        <input onChange={this.changeHandler} checked={this.state.__data__?.isDefault} name={"isDefault"} type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1"> Is Default </label>
                    </div>

                </ModalBody>
                <ModalFooter>
                        <button className="btn btn-secondary"
                                onClick={this.props.close}
                        > Cancel </button>
                        <button onClick={this.submitHandler} className="btn btn-primary" disabled={!this.state.__formValid__}> Save </button>
                        {
                            this.state.__loading__ ? <Loader isLoading={true} /> : null
                        }
                </ModalFooter>
            </Modal>
        );
    }
}