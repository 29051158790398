/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { Button, CardBody, CardHeader, Col, Row } from "reactstrap";
import {
    deleteMembershipTerms,
    editMembershipTerms,
    getMembershipTerms,
    IMembershipTerms,
    postMembershipTerms
} from "../../api/membershio-terms-api";
import { Loader } from "../../components/Loader";
import { MainCard } from "../../components/MainCard";

import { FiAlertCircle } from "react-icons/fi";

import { RichTextEditor } from "../../components/RichEditor/RichTextEditor";
import { FaPlus } from "react-icons/fa";

// import 'draft-js/dist/Draft.css';

interface IManageMembershipTermsState {
    data?: IMembershipTerms | null,
    loading: boolean,
    editable: boolean,
    formMode: FormMode
}
enum FormMode {
    create, edit
}

class ManageMembershipTerms extends React.Component<any, IManageMembershipTermsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: {
                content: ''
            },
            loading: false,
            editable: false,
            formMode: FormMode.create
        }
    }
    componentWillMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setState({ loading: true });
        getMembershipTerms().then(data => {
            this.setState({ loading: false, data });
            if (data && data.content === null) {
                this.setState({ formMode: FormMode.create })
            } else {
                this.setState({ formMode: FormMode.edit })
            }
        }, error => {
            this.setState({ loading: false });
        })
    }

    onAddEditHandler = () => {
        this.setState({ loading: true })
        if (this.state.data) {
            if (this.state.formMode === FormMode.create) {
                // post
                postMembershipTerms(this.state.data).then(_ => {
                    this.setState({ loading: false, editable: false })
                }, error => {
                    this.setState({ loading: false, editable: false })
                })
            } else {
                editMembershipTerms(this.state.data).then(_ => {
                    this.setState({ loading: false, editable: false })

                }, error => {
                    this.setState({ loading: false, editable: false })
                })
            }
        }

    }


    onCancelHandler = () => {
        this.setState({ editable: false })
    }


    onDeleteHandler = () => {
        this.setState({ loading: true })
        deleteMembershipTerms().then(_ => {

            this.setState({ loading: false, editable: false, data: null })
        }, error => {
            this.setState({ loading: false, editable: false })

        })
    }


    render() {
        return (
            <>
            <MainCard>
                <CardHeader className={"space-between"}>
                    <span>Membership Terms & conditions</span>

                    <div>
                        <Button
                            onClick={()=>this.setState({ editable: true })}
                            color={'primary'}>
                            <FaPlus /> click here to add content
                        </Button>
                    </div>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col >
                            {
                                this.state.data && this.state.data.content && !this.state.editable ?

                                    <div className={'my-4'}>
                                        <strong>
                                            <i> <FiAlertCircle /> </i>
                                            Note: click on text to edit </strong>
                                    </div>
                                    :
                                    null
                            }
                            <div className={'m-4'}>
                                {
                                    this.state.loading ?
                                        <Loader isLoading={true} />
                                        :
                                        null
                                }

                                {
                                    this.state.editable ?
                                        <div className={'form-group'}>
                                            {/*<textarea  className={'form-control'}*/}
                                            {/*           onChange={e => {*/}
                                            {/*               e.preventDefault();*/}
                                            {/*               const value = e.target.value;*/}
                                            {/*               const data: IMembershipTerms = {*/}
                                            {/*                   content : value*/}
                                            {/*               }*/}
                                            {/*               */}
                                            {/*               this.setState({data})*/}
                                            {/*           }}*/}
                                            {/*           value={this.state.data?.content ?? ""} ></textarea>*/}

                                            {/* eslint-disable-next-line react/jsx-no-undef */}
                                            {/*<Editor editorState={EditorState.createEmpty()}  onChange={() => {*/}
                                            {/*    */}
                                            {/*    */}
                                            {/*}}/>*/}
                                            <div>
                                                <RichTextEditor
                                                    content={this.state.data?.content ?? ""}
                                                    onChange={(state: any) => {
                                                        const data: IMembershipTerms = {
                                                            content: state as string
                                                        }
                                                        this.setState({ data })
                                                    }} />
                                            </div>

                                            <div className={"space-between"}>
                                                <button
                                                    onClick={this.onCancelHandler}
                                                    className={'btn btn-secondary mt-2'}>
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={this.onDeleteHandler}
                                                    className={'btn btn-link mt-1'}>
                                                    Remove Terms
                                                </button>
                                                <button
                                                    onClick={this.onAddEditHandler}
                                                    className={'btn btn-primary mt-2'}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                        : <p onClick={e => {
                                            e.preventDefault();
                                            this.setState({ editable: true })
                                        }}>
                                            {this.state.data?.content ?? null}
                                        </p>
                                }
                            </div>
                        </Col>
                    </Row>

                </CardBody>
            </MainCard>
            </>
        );
    }
}

export default ManageMembershipTerms;