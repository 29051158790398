/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { IDurationPlan } from "../../api/ProductsApi";
import { ITax } from "../../api/TaxApi";
import './DurationPlanItemCard.css';

interface IDurationPlanItemCardProps {
    item: IDurationPlan;
    wholeTaxes: Array<ITax>
}

class DurationPlanItemCard extends React.Component<IDurationPlanItemCardProps, any> {
    constructor(props: IDurationPlanItemCardProps) {
        super(props);
        this.state = {
            taxes: []
        }
    }

    componentWillMount() {

        if (this.props.wholeTaxes && this.props.wholeTaxes.length) {
            const taxes: any = [];
            // this.props.item.price.tax ?? taxesId.forEach((item: number, index: number) => {
                if(this.props.item.price.tax) {
                    const result = this.props.wholeTaxes.find(x => x.id === this.props.item.price.tax.id);
                    taxes.push(result);
            }
            // })

            this.setState({ taxes });
        }
    }

    render() {
        return (
            <div className={'duration-card'}>
                <div className="row">
                    <div className="col-sm-6 mt-3">
                        <span className={'title'}> Duration: </span>
                        <strong> {this.props.item.durationInDays} Days </strong>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <span className={'title'}> Checkin Limits: </span>
                        <strong> {this.props.item.checkInLimits}  </strong>
                    </div>
                    <div className="col-sm-12 mt-3">
                        <span className={'title'}> Taxes: </span>
                        {
                            this.state.taxes.map((item: ITax, index: number) => {
                                return (
                                    <>
                                        <span className={'badge badge-pill badge-success mx-1'}> {item.name + ' - ' + item.rate + ' %'} </span>
                                        
                                    </>
                                        
                                        );
                        
                            })
                        }
                    </div>
                    <div className={'col-sm-6 mt-3'}>
                        <span className={'title'}> Price : </span>
                        <strong> {this.props.item.price.displayPrice} </strong>
                    </div>
                    <div className={'col-sm-6 mt-3'}>
                        <span className={'title'}> Total Price : </span>
                        <strong> {this.props.item.price.displayTotalPrice} </strong>
                    </div>
                </div>
            </div>
        );
    }
}

export default DurationPlanItemCard;