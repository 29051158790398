import React, {useEffect, useState} from "react";
import HtmlViewercomponent from "components/HtmlViewerComponent/html-viewer";
import {Loader} from "components/Loader";
import {AiOutlineSearch} from "react-icons/ai";
import {FaEllipsisH, FaEye, FaPlus, FaPrint} from "react-icons/fa";
import {
  Button,
  ButtonDropdown,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import {getAllFakeInvoices, getInvoiceTemplate, InvoiceDto} from "../../api/InvoicesApi";
import {MainCard} from "../../components/MainCard";
import "./Manage-Invoice.component.css";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import {Helmet} from "react-helmet";
import emptyStateSessionImage from "../../assets/imgs/invoice.svg";
import {EmptyListState} from "../../components/EmptyListState";
import {formatSimpleDate} from "../../util/dateUtil";
import AddDynamicInvoice from "./Add-Dynamic-Invoice.component";
import { printOrderInvoice } from "../../api/OrdersApi";
import printJS from "print-js";
import { PaginationFooter } from "../../components/PaginationFooter";

export const ManageInvoicesComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<InvoiceDto[]>([]);
  const [fakeData, setFakeData] = useState<any[]>([])
  const [showActionCardKey, setShowActionCardKey] = useState(-1);
  const [viewInvoiceTemplate, setViewInvoiceTemplate] = useState<string | null>(null);
  const [value, setValue] = useState<string>();
  const [searchKeys] = useState([
    { label: "Invoice Number", value: "InvoiceNumber" },
    { label: "Full Name", value: "FullName" },
    { label: "Phone Number", value: "PhoneNumber" },
  ]);
  const [addInvoiceModal, setAddInvoiceModal] = useState(false);
  const [page,setPage] = useState(1);
  const [count,setCount] = useState(0);
  const [maximumPerPage]=useState(20);

  useEffect(() => {
    fetchData();
  }, [page]);


  const fetchData = () => {
    setLoading(true);
    getAllFakeInvoices({
      limit: maximumPerPage,
      pageIndex: page,
      text:value
    }).then((data: any) => {
      setFakeData(data.items);
      setCount(data.totalItemsCount)
      setLoading(false);
    }).catch(err=>{
      console.error(err);
      setLoading(false)
    });
  };


  const viewInvoiceHandler = (transactionId: number) => {
    setLoading(true);
    getInvoiceTemplate(transactionId).then(
        (data: { template: any; }) => {
          setViewInvoiceTemplate(data?.template ?? null);
          setLoading(false);
        },
        (error: any) => {
          setLoading(false);
          toast.error(
              <ToastMessage type="Error">
                Something went wrong, please try again later
              </ToastMessage>
          );
        }
    );
    setShowActionCardKey(-1);
  };

  const downloadInvoiceHandler = (transactionId: number) => {
    alert("download transaction id" + transactionId);
    setShowActionCardKey(-1);
  };

  const onChangeSearchValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setValue(value);
  };
  const handlePrint = (invoiceCode:string) => {
    printOrderInvoice(invoiceCode).then((response:any) =>{
      printJS({printable:response,type:'raw-html'})
    });
  };

  return (
      <div>
        <Helmet title="Invoices" />

        {viewInvoiceTemplate ? (
            <HtmlViewercomponent
                isOpen={viewInvoiceTemplate != null}
                title={"Invoice"}
                close={() => {
                  setViewInvoiceTemplate(null);
                }}
                html={viewInvoiceTemplate}
            />
        ) : null}
        <MainCard>
          <CardHeader className="d-flex justify-content-between">
            <div>Invoices </div>
          </CardHeader>
          <CardBody>
            <Form>
              <Row className="align-items-center mb-4">
                <Col sm="4" xs="6">
                  <InputGroup>

                    <input
                        value={value || ""}
                        onChange={onChangeSearchValueHandler}
                        type="text"
                        className="form-control flex-2"
                        placeholder=""
                    />
                    <button
                        onClick={(e) => {
                          e.preventDefault();
                          fetchData();
                        }}
                        className="btn btn-success flex-2"
                    >
                      Search
                      <i>
                        {" "}
                        <AiOutlineSearch />{" "}
                      </i>
                    </button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>

            <React.Fragment>

              {!fakeData || fakeData.length === 0 ?
                  <EmptyListState
                      image={emptyStateSessionImage}
                      title={"There are no Invoices"}
                  />
                  :

                  <table className={"table table-response"}>
                    <thead className="thead-dark">
                    <tr>
                      <td>#</td>
                      <td>Customer</td>
                      <td>Total Payment</td>
                      <td>Type</td>
                      <td>Date</td>
                      <td>Actions</td>
                    </tr>
                    </thead>
                    <tbody>

                    {fakeData.map((item, i) => {
                      return (
                          <tr key={i}>
                            <td> {item.orderId} </td>
                            <td> {item.clientUser?.displayName} </td>
                            <td>{item.total} {" SAR"}</td>
                            <td> {item.iType?.name} </td>
                            <td> {item.createDate ? formatSimpleDate(item.createDate) : ""}</td>
                            <td className="position-relative">
                              <UncontrolledButtonDropdown size="sm">
                                <DropdownToggle caret outline color="dark">
                                  <FaEllipsisH />
                                </DropdownToggle>

                                <DropdownMenu>
                                  <DropdownItem onClick={() => handlePrint(item.code) }>
                                    <FaPrint/> Print Invoice
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colSpan={6}>
                        <PaginationFooter
                          onPageClicked={setPage}
                          pageIndex={page}
                          totalItemsCount={count}
                          pageSize={maximumPerPage}
                          pageItemsCount={fakeData.length}
                        />
                      </td>
                    </tr>
                    </tfoot>
                  </table>
              }
            </React.Fragment>
            {loading ? <Loader isLoading={true} /> : null}
          </CardBody>
        </MainCard>
      </div>
  );
};
