/* eslint-disable react/no-deprecated */
import { getRoles, IRole } from 'api/rolesApi';
import React from 'react';
import Helmet from "react-helmet";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {Button, CardBody, CardHeader, Table} from "reactstrap";
import NoData from '../../assets/imgs/empty_state.png';
import { MainCard } from "../../components/MainCard";
import AddEditRole, { ModalStatus } from "./AddEditRole";
import DeleteRoleModal from "./DeleteRoleModal";
import {Loader} from "../../components/Loader";
import { PaginationFooter } from "../../components/PaginationFooter";
class ManageRole extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            __loading__: false,
            openModal: false,
            __itemEditable__: null,
            __itemDeletable__: null,
            page:1,
            count:0,
            maximumPerPage:20,
        }
    }


    componentWillMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps:any, prevState:any) {
        if (prevState.page !== this.state.page) {
            this.fetchData();
        }
    }
    closeModal = () => {
        this.setState({ openModal: false })
    }

    cancelDeleteHandler = () => {
        this.setState({ __itemDeletable__: null })
    }
    showEditModal = (item: IRole) => {
        this.setState({
            __itemEditable__: {
                id: item.id,
                name: item.name,
            }
        }, () => {

        });
        this.setState({ openModal: true }, () => {
            
        })
    }
    deleteHandler = (item: IRole) => {
        this.setState({ __itemDeletable__: item })
    }

    fetchData = () => {
        this.setState({ __loading__: true })
        getRoles({ limit: this.state.maximumPerPage, pageIndex: this.state.page }).then(data => {
            this.setState({ __loading__: false,data: data.items,count:(data as any).totalItemsCount })
            ;
        }).catch(error => {
            this.setState({ __loading__: false });
        })
    }
    render() {
        return (
            <div>
                <Helmet title="Roles" />
                <MainCard>
                    <CardHeader className="d-flex justify-content-between">
                        <div>Roles</div>
                        <div>
                            <Button
                                onClick={e => {
                                    this.setState({ openModal: true, __itemEditable__: null })
                                }}
                                color={'primary'}>
                                <FaPlus /> Add Role
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {this.state.__loading__ ? <Loader/> :
                                this.state.data.length > 0 ?
                                    <Table>
                                        <thead>
                                        <tr>
                                            <td>
                                                Id
                                            </td>

                                            <td>
                                                Name
                                            </td>
                                            <td>
                                                Permissions
                                            </td>
                                            <td>
                                                Actions
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.data.map((item: IRole) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.id}
                                                            </td>

                                                            <td>
                                                                {item.name}
                                                            </td>

                                                            <td>
                                                                <Link to={`/role-permission/${item.id}`}>
                                                                    <button className={'btn btn-outline-primary'}>
                                                                        Permissions
                                                                    </button>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    color={"primary"}
                                                                    outline={true}
                                                                    onClick={e => {
                                                                        this.showEditModal(item);
                                                                    }}>
                                                                    <FaEdit/>
                                                                </Button>{" "}
                                                                <Button
                                                                    color={"danger"}
                                                                    outline={true}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.deleteHandler(item);
                                                                    }}>
                                                                    <FaTrashAlt/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>

                                                );
                                            })
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan={4}>
                                                <PaginationFooter
                                                  onPageClicked={page => this.setState({page:page}) }
                                                  pageIndex={this.state.page}
                                                  totalItemsCount={this.state.count}
                                                  pageSize={this.state.maximumPerPage}
                                                  pageItemsCount={this.state.data.length}
                                                />
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </Table> :
                                    <div className={'text-center'}>
                                        <img src={NoData} width={'30%'}/>
                                    </div>
                        }
                    </CardBody>
                </MainCard>


                {

                    this.state.openModal ?
                        this.state.__itemEditable__ ?
                            <AddEditRole item={this.state.__itemEditable__} onUpdated={this.fetchData} name={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__EDIT__} history={undefined} />
                            : <AddEditRole onUpdated={this.fetchData} name={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__CREATE__} history={undefined} />
                        : null
                }

                {
                    this.state.__itemDeletable__ ? <DeleteRoleModal cancelDeleting={this.cancelDeleteHandler} roleToDelete={this.state.__itemDeletable__} onUpdated={this.fetchData} /> : null
                }
            </div>
        );
    }
}

export default ManageRole;