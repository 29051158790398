/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';

import { postCiscoCode } from "../../api/ConnectServiceApi";
import CiscoImage from '../../assets/imgs/cisco.png';
class CiscoIntegratedService extends Component<any, any> {

  constructor(props: any) {
    super(props);
  }

  componentWillMount() {

    const code: any = new URLSearchParams(this.props.location.search).get("code");

    if (!code)
      this.props.history.push("/");


    // Send Code to Back-end

    postCiscoCode(code).then(res => {

    })

  }


  render() {
    return (

      <Card>
        <CardBody>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="service-logo">
                  <img src={CiscoImage} />
                </div>
                <div className="mt-4">
                  <h5> Congratulation </h5>
                  <p> you are integrated with cisco meeting successfully ! </p>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>


    );
  }
}

export default CiscoIntegratedService;