import { MediaFile } from "api/common/MediaFile";
import Axios from "axios";
import { objectToFormData } from "util/objectToFormData";
import { PageComponent } from "./PageComponentsApi";

export interface EventPage {
    id: number;
    urlSlug: string;

    title: string;
    darkLogo: MediaFile | null;
    lightLogo: MediaFile | null;
    favIcon: MediaFile | null;

    accent: string;
    createDate: string;

    components: PageComponent[];
    membershipAreaEventPage?:IMembershipAreaEventPage | null
}

export const getEventPage = async (eventId: number): Promise<EventPage> => {
    const result = await Axios.get(`/api/events/${eventId}/eventPage`);

    return result.data;
};

export interface UpdateEventPageRequest {
    urlSlug: string;
    title: string;

    darkLogo: File | null;
    lightLogo: File | null;
    favIcon: File | null;
    accent: string;
     membershipPrimaryColor?:string | null,
    membershipSecondaryColor?:string | null,
    membershipLogo?:File| null

}

export interface IMembershipAreaEventPage {
    primaryColor:string ,
    secondaryColor:string,
    membershipLogo? : MediaFile | null
}

export const updateEventPage = async (
    eventId: number,
    request: UpdateEventPageRequest
) => {
    const formData = objectToFormData(request);

    await Axios.put(`/api/events/${eventId}/eventPage`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    });
};

export interface IEventUrl {
    eventUrl :string;
}
export const getEventUrl = async (eventId : number) : Promise<IEventUrl>  =>{
    const result = await Axios.get<IEventUrl>(`/api/Events/${eventId}/url`);
    return result.data;
}


export const getTenantToken = async () :Promise<string>  => {
    const result = await Axios.get<string> ('/api/EventWebSite/GetToken');
    return result.data;
}


export const getEventName = async (id:number) : Promise<string> => {
    const result = await Axios.get(`api/events/${id}/name`);
    return result.data;
}

