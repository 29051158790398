import {Product, ProductAddon, ProductItem, ProductSession, ProductTicket, Ticket} from "../api/ProductsApi";

export const extractProductItem = (product: Product | null): ProductItem => {
    return {
        name: product?.name??"",
        description: product?.description??null,
        categoryId: product?.categoryId??1,
        minQTYPerOrder: product?.minQTYPerOrder??null,
        maxQTYPerOrder: product?.maxQTYPerOrder??null,
        totalQTY: product?.totalQTY??null,
        availabilityStartDateTime: product?.availabilityStartDateTime,
        availabilityEndDateTime: product?.availabilityEndDateTime,
        price: {
            amount: product?.price?.amount??0,
            discount: product?.price?.discount,
            isDiscountPercentage: product?.price?.isDiscountPercentage,
            taxesIds: product?.price?.taxesIds
        },
        assignedEvents: product?.assignedEvents,
        assignedAddons: product?.assignedAddons,
        isPublished:product?.isPublished
    }
}

export const extractProductSession = (product: Product | null, categoryId: number, speakers: any[], locations: any[]): ProductSession => {
    
    return {
        name: product?.name??"",
        description: product?.description??null,
        categoryId: categoryId,
        minQTYPerOrder: product?.minQTYPerOrder??1,
        maxQTYPerOrder: product?.maxQTYPerOrder??1,
        totalQTY: product?.totalQTY??10,
        availabilityStartDateTime: product?.availabilityStartDateTime,
        availabilityEndDateTime: product?.availabilityEndDateTime,
        price: {
            amount: product?.price?.amount??0,
            discount: product?.price?.discount,
            isDiscountPercentage: product?.price?.isDiscountPercentage,
            taxesIds: product?.price?.taxesIds
        },
        eventId: product?.assignedEvents? product.assignedEvents[0]:null,
        assignedAddons: product?.assignedAddons??[],
        speakers: speakers.map(speaker=>speaker.value),
        locations: locations.map(location=>location.id),
        eventIds:product?.eventIds ?? [],
        isPublished:product?.isPublished,
    }
}


export const extractProductAddon = (product: Product | null, categoryId: number): ProductAddon => {
    return {
        name: product?.name??"",
        description: product?.description??null,
        categoryId: categoryId,
        minQTYPerOrder: product?.minQTYPerOrder??null,
        maxQTYPerOrder: product?.maxQTYPerOrder??null,
        totalQTY: product?.totalQTY??null,
        availabilityStartDateTime: product?.availabilityStartDateTime,
        availabilityEndDateTime: product?.availabilityEndDateTime,
        price: {
            amount: product?.price?.amount??0,
            discount: product?.price?.discount,
            isDiscountPercentage: product?.price?.isDiscountPercentage,
            taxesIds: product?.price?.taxesIds
        },
        eventId: product?.assignedEvents? product.assignedEvents[0]:null,
        assignedToProducts: product?.assignedToProducts??[],
        isPublished:product?.isPublished,
    }
}

export const extractProductTicket = (product: Product | null, locationId: any): ProductTicket => {
    return {
        name: product?.name??"",
        description: product?.description??null,
        categoryId: product?.categoryId??1,
        minQTYPerOrder: product?.minQTYPerOrder??1,
        maxQTYPerOrder: product?.maxQTYPerOrder??1,
        totalQTY: product?.totalQTY??10,
        availabilityStartDateTime: product?.availabilityStartDateTime,
        availabilityEndDateTime: product?.availabilityEndDateTime,
        price: {
            amount: product?.price?.amount??0,
            discount: product?.price?.discount,
            isDiscountPercentage: product?.price?.isDiscountPercentage,
            taxesIds: product?.price?.taxesIds
        },
        eventId: product?.assignedEvents? product.assignedEvents[0]:null,
        daysNoLimit: (product as Ticket)?.daysNoLimit ?? 0,
        perDayLimit: (product as Ticket)?.perDayLimit ?? 0,
        locationId: locationId?.id ?? undefined,
        startDateTime: product ? (product as Ticket).startDateTime : undefined,
        endDateTime: product ? (product as Ticket).endDateTime : undefined,
        isPublished:product?.isPublished,
    }
}