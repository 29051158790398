import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Event, getEventById } from "../api/Events";
import { AppThunk } from "./index";

export interface EventDetailsState {
    isBusy: boolean;
    eventId: number | null;
    result: Event | null;
    error: string | null;
}

const initialState: EventDetailsState = {
    isBusy: false,
    eventId: null,
    result: null,
    error: null
};

interface ReceiveEventDetailAction {
    eventId: number;
    result: Event | null;
    error: string | null;
}

const EventDetailsSlice = createSlice({
    name: "EventDetails",
    initialState,
    reducers: {
        requestEventDetails(state, action) {
            state.isBusy = true;
        },
        receiveEventDetails(
            state,
            action: PayloadAction<ReceiveEventDetailAction>
        ) {
            state.result = action.payload.result;
            state.eventId = action.payload.eventId;
            state.error = action.payload.error;
        }
    }
});

export default EventDetailsSlice.reducer;

export const { requestEventDetails, receiveEventDetails } = EventDetailsSlice.actions;

export const loadEventDetails = (
    eventId: number
): AppThunk => async dispatch => {
    try {
        dispatch(requestEventDetails({}));
        const result = await getEventById(eventId);
        dispatch(
            receiveEventDetails({
                eventId: eventId,
                result: result,
                error: null
            })
        );
    } catch (e) {
        dispatch(
            receiveEventDetails({
                eventId: eventId,
                result: null,
                error: "e.toString()"
            })
        );
    }
};
