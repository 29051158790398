import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { Event, EventRequest, getEvents } from "../api/Events";
import { AppThunk } from "./index";

export interface EventsListState {
    isBusy: boolean;
    error: string | null;
    events?: PaginatedResult<Event>;
}

const initialState: EventsListState = {
    isBusy: false,
    error: null
};

interface ReceiveEventsListAction {
    error: string | null;
    events?: PaginatedResult<Event>;
}

const EventsList = createSlice({
    name: "EventsList",
    initialState,
    reducers: {
        requestEventsList(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveEventsList(
            state,
            action: PayloadAction<ReceiveEventsListAction>
        ) {
            state.isBusy = false;
            state.error = action.payload.error;
            state.events = action.payload.events;
        }
    }
});

export default EventsList.reducer;

const { requestEventsList, receiveEventsList } = EventsList.actions;

export const loadEventsList = (request: EventRequest): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        if (getState().eventsList.isBusy) return;

        dispatch(requestEventsList());
        const result = await getEvents(request);
        dispatch(
            receiveEventsList({
                error: null,
                events: result
            })
        );
    } catch (err) {
        // dispatch(
        //     receiveEventsList({
        //         error: err.toString(),
        //         events: getState().eventsList.events
        //     })
        // );
    }
};
