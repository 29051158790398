import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventSessionRequest, Session } from "api/Sessions";
import { normalize } from "normalizr";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import {
    NormalizedPagination,
    NormalizedPaginationItem
} from "./NormalizedPagination";
import { sessionSchema } from "./Schemas";
import { getAgendaSessions } from "../api/AgendaSessionApi";
import moment from "moment";
import _ from "lodash";

export interface SessionsState {
    [key: string]: Session | {};

    pagination: {
        byEventId: NormalizedPagination & {
            [key: string]: NormalizedPaginationItem & {
                request?: PaginatedRequest & EventSessionRequest;
            };
        };
    };
}

const initialState: SessionsState = {
    pagination: { byEventId: {} }
};

interface RequestSessionsInEventPaginationAction {
    eventId: number;
}

interface ReceiveSessionsPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    request?: PaginatedRequest & EventSessionRequest;
    ids?: string[];
    groupByDate?:string[];
}

const sessionsSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {
        requestSessionsByEventPaginated(
            state,
            action: PayloadAction<RequestSessionsInEventPaginationAction>
        ) {
            const { eventId } = action.payload;

            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    isFetching: true
                }
            };
        },
        receiveSessionsByEventPaginated(
            state,
            action: PayloadAction<ReceiveSessionsPaginationAction>
        ) {
            const { eventId, ...rest } = action.payload;

            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    ...action.payload,
                    isFetching: false
                }
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["sessions"]
            };
        })
    })
});

export default sessionsSlice.reducer;

const {
    requestSessionsByEventPaginated,
    receiveSessionsByEventPaginated
} = sessionsSlice.actions;

export const fetchSessionsByEventIdPaginated = (
    request: PaginatedRequest & EventSessionRequest
): AppThunk => async (dispatch, getState) => {
    const { eventId } = request;
    try {
        dispatch(requestSessionsByEventPaginated({ eventId }));

        const result = await getAgendaSessions(request);

        const normalizedResult = normalize(result, Array(sessionSchema));
        const payload : ReceiveSessionsPaginationAction = {
            eventId,
            request: request,
            ids: normalizedResult.result
        }
        if(!request.FirstStartDate && !request.LastStartDate){
            const groupByStartDate = _(result).sortBy((a) =>
              moment(a.startDateTime)).groupBy((s)=>{
                return moment(s.startDateTime).set({hour:0,minute:0,second:0,millisecond:0}).toISOString()
            }).map((sessions,title)=>title).value()

            payload.groupByDate =groupByStartDate
        }



        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            receiveSessionsByEventPaginated(payload)
        );
    } catch (e) {
        dispatch(
            receiveSessionsByEventPaginated({
                eventId,
                // error: e.toString(),
                ids: getState().sessions.pagination.byEventId[
                    eventId.toString()
                ]?.ids,
                pageIndex: getState().sessions.pagination.byEventId[
                    eventId.toString()
                ]?.pageIndex,
                pageSize: getState().sessions.pagination.byEventId[
                    eventId.toString()
                ]?.pageSize,
                request: getState().sessions.pagination.byEventId[
                    eventId.toString()
                ].request,
                totalItemsCount: getState().sessions.pagination.byEventId[
                    eventId.toString()
                ]?.totalItemsCount
            })
        );
    }
};
