import *  as React from 'react';

interface QuestionTypeProps {
    questionName:string;
    cardIcon:string;
    onClick?:any;
    
}

class QuestionTypeCardComponent extends React.Component<QuestionTypeProps  , any> {
    constructor(props:QuestionTypeProps) {
        super(props);
        this.state = {
            
        }
    }
    render() {
        return (
            <div onClick={this.props.onClick}  style={{border:'1px dashed #333'}} className={'p-2 reg-form-ques-card'}>
               <div className={'text-center'}>
                    <span className={'ico-text'}>
                    <img  src={this.props.cardIcon} alt={this.props.questionName} width={"35px"} />
                </span>
               </div>
                <div className={'text-center'}>
                    <p className={'text-center'}> {this.props.questionName} </p>
                </div>
            </div>
        );
    }
}

export default QuestionTypeCardComponent;