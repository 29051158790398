import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CreateTicket } from '../../api/SmartAccessApi';
import { Loader } from '../../components/Loader/Loader';
import { ToastMessage } from '../../components/ToastMessages';
interface OwnProps {
  close: () => void;
  isOpen: boolean;
  tenantUserName: string;
  membershipNumber: string;
}

type Props = OwnProps;

export default class AddTicketConfirmComponent extends Component<Props, any> {


  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    }

  }

  onConfirmHandler = () => {
    if (this.props.membershipNumber && this.props.membershipNumber.length > 0) {
      this.setState({ loading: true });
      CreateTicket(this.props.membershipNumber).then(_ => {
        this.setState({ loading: false });
        toast.success(
          <ToastMessage>
            Added successfully.
          </ToastMessage>
        );
        this.props.close?.();
      }, _ => {


        this.setState({ loading: false });
        toast.error(
          <ToastMessage>
            Error has been happened !
          </ToastMessage>
        );


      })
    }
  }
  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >

        {this.state.loading ? <Loader isLoading={true} /> : null}

        <ModalHeader toggle={this.props.close}> Create Ticket  </ModalHeader>
        <ModalBody >
          <div className="row">
            <div className="col-sm-12">
              <p> Are you sure to create ticket for <strong>{this.props.tenantUserName}</strong> </p>
              <div className="mt-4 text-center">
                <button className="btn btn-primary rounder-raduis mx-2"

                  onClick={e => {
                    e.preventDefault();
                    this.props.close?.();
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={this.onConfirmHandler}
                  className="btn btn-success rounder-raduis mx-2">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
