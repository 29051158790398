import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { dashboardPage, updateDashboardPage } from "../../api/DashboardApi";
import CKEditor from "../../components/SendEmail/CKEditor";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import { getPlaceHolders } from "../../api/MessagingApi";

const EditPage = ({ isOpen, toggle, type }: any) => {
  const [content, setContent] = useState<any>()
  const [loading, setLoading] = React.useState<boolean>(true);
  


  const initialFormValues = { contentAr: "", contentEn: "" }

  const handleSubmit = () => {
    updateDashboardPage(type.id, content)
      .then(() => {
        toast.success("Page Updated")
      }).catch(reason => {
      toast.error("Error while Updating Page")
    }).finally(() =>
    toggle())
  }

  useEffect(() => {
    if (type)
      dashboardPage(type.id).then(value => {
        setContent(value)
      }).finally(() => {
        setLoading(false)
      })
  }, [type])


  const form = () => (
    <Form>
      <ModalBody>

        <FormGroup>
          <Label>Content Arabic </Label>

          <CKEditor
            content={content.contentAr}
            changeContent={(content: any) => {
              setContent((prev: any) => ({ ...prev, contentAr: content }))
            }}
            lang="ar"
          />
        </FormGroup>
        <FormGroup>
          <Label>Content English</Label>

          <CKEditor
            content={content.contentEn}
            changeContent={(content: any) => {
              setContent((prev: any) => ({ ...prev, contentEn: content }))
            }}
          />
        </FormGroup>

      </ModalBody>
      <ModalFooter className="space-between">
        <Button type="button" color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </ModalFooter>
    </Form>
  );


  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose size="lg">
      <ModalHeader toggle={toggle}>Edit Page</ModalHeader>
      {loading ?
        <Loader isLoading={loading}/>
        :
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}>
          {form}
        </Formik>}
    </Modal>
  )
}

export default EditPage;