import PropTypes from "prop-types";
import * as React from "react";
import { LegacyRef } from "react";
import './SideNav.css';

interface SideNavItemsContainerProps {
    ref?: LegacyRef<HTMLUListElement>;
    children: React.ReactNode;
}

export const SideNavItemsContainer: React.FC<SideNavItemsContainerProps> = props => {
    return (
        <div className="flex-grow-1 side-nav-items-container" >
            <ul ref={props.ref} className="side-nav-item__items">
                {props.children}
            </ul>
        </div>
    );
};

SideNavItemsContainer.propTypes = {
    children: PropTypes.node.isRequired
};
