import React, { useState, ChangeEvent, FormEvent, FC } from 'react';
import { toast } from "react-toastify";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input, InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledButtonDropdown
} from "reactstrap";
import { addTax, ITax } from "../../api/TaxApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";



export interface IAddEditTaxPaymentModal {
    id: number | null;
    name: string | null;
    rate?: number | null;
    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
}


const AddTaxModal: FC<IAddEditTaxPaymentModal> = (props) => {
    const [data, setData] = useState<ITax>({
        isActive: false,
        name: "",
        id: 0,
        shortName: '',
        value: '',
        isPercentage: false,
    });
    const [formValid, setFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let val: string | number | boolean = e.target.value;
        let updatedData: ITax = { ...data, [name]: val };
        setData(updatedData);
        validationHandler(updatedData);
    };

    const validationHandler = (updatedData: ITax) => {
        if (
            updatedData.name &&
            updatedData.name.length > 0 &&
            updatedData.value > 0
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };

    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);

            addTax(data)
                .then((response) => {
                    setLoading(false);
                    if (props.onUpdated) {
                        props.onUpdated();
                    }
                    toast.success(
                        <ToastMessage type="Success">
                            Tax Of Name {data.name} add Successfully;
                        </ToastMessage>
                    );
                    if (props && props.close) {
                        props.close();
                    }
                })
                .catch((error) => {
                    toast.error(
                        <ToastMessage type="Error">
                            "{data.name} or {data.name}" is Exist Before
                        </ToastMessage>
                    );
                    setLoading(false);
                });
    };

    return (
        <Modal unmountOnClose isOpen={props.isOpen} toggle={props.close}>
            <ModalHeader toggle={props.close}>
                Add Tax
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label> Active </label>
                    <Input
                        type="switch"
                        label="Active"
                        id="isActive"
                        name="isActive"
                        onChange={(e)=>{
                            const isActive = e.target.checked;
                            setData({...data, isActive:isActive});
                            validationHandler(data)
                        }}
                        checked={data.isActive}
                    />

                </div>
                <div className="form-group">
                    <label> Name </label>
                    <input
                        value={data.name}
                        name="name"
                        type="text"
                        className="form-control"
                        onChange={changeHandler}
                    />
                </div>
                <div className="form-group">
                    <label> Short Name </label>
                    <input
                        value={data.shortName}
                        name="shortName"
                        type="text"
                        className="form-control"
                        onChange={changeHandler}
                    />
                </div>

                <div className="form-group">
                    <label> Value </label>
                    <InputGroup>
                        <Input type="number"
                               value={data.value}
                               name="value"
                               onChange={changeHandler}
                        />
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret>
                                {data.isPercentage ? '%' : 'Fixed amount'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={(e) => {
                                    e.preventDefault();
                                    setData({...data, isPercentage: true});
                                }}>
                                    %
                                </DropdownItem>
                                <DropdownItem onClick={(e) => {
                                    e.preventDefault();
                                    setData({...data, isPercentage: false});
                                }}>
                                    Fixed amount
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>

                {/*<div className="form-group">*/}
                {/*    <label> Rate (%) </label>*/}
                {/*    <input*/}
                {/*        value={data.rate}*/}
                {/*        name="rate"*/}
                {/*        onChange={changeHandler}*/}
                {/*        type="number"*/}
                {/*        className="form-control"*/}
                {/*    />*/}
                {/*</div>*/}
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-secondary"
                    onClick={props.close}
                >
                    Cancel
                </button>
                <button
                    onClick={submitHandler}
                    className="btn btn-primary"
                    disabled={!formValid}
                >Save
                </button>
                {loading ? <Loader isLoading={true} /> : null}
            </ModalFooter>
        </Modal>
    );
};

export default AddTaxModal;
