import * as React from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import {
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { ISessionBarCode, getSessionBarCode } from '../../api/MembershipAreaSessionApi';
import { Loader } from '../../components/Loader';

interface IBarCodeSessionViewComponentProps {
  isOpen: boolean;
  close: () => void;
  sessionId: number;
  sessionNumber: string;
}

const BarCodeSessionLViewComponent: React.FC<IBarCodeSessionViewComponentProps> = props => {
  const [loading, setLoading] = React.useState(false);
  const [barCode, setBarCode] = React.useState<ISessionBarCode | null>(null);
  const printRef = React.useRef<any>();

  const getBarCodeHandler = React.useCallback(async () => {
    setLoading(true);
    const result = await getSessionBarCode(props.sessionId).catch((err)=>{
      ;
      setLoading(false);
    });
    setLoading(false);
    setBarCode(result??null);
  }, [setBarCode, setLoading])

  React.useEffect(() => {
    getBarCodeHandler();
  }, []);
  return (
    <Modal unmountOnClose isOpen={props.isOpen} toggle={props.close}>
      <ModalHeader toggle={props.close}>
        <h5> Qr code of Session number <strong> {props.sessionNumber} </strong> </h5>
      </ModalHeader>
      <ModalBody>
        {
          loading ?
            <Loader isLoading={true} />
            : null
        }
        <div className="text-center" ref={printRef}>

          {
            barCode ?
              <div className="text-center mt-4">
                <img src={barCode.barCode} />
              </div>
              : null
          }
        </div>

        <div className="text-center mt-4">
          <ReactToPrint
            trigger={() => {

              return (<button className="btn btn-outline-success">
                <i> <AiOutlinePrinter /> </i>
                <span> Print </span>
              </button>);
            }}
            content={() => printRef.current}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default BarCodeSessionLViewComponent;