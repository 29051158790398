/* eslint-disable react/no-deprecated */
import { AddSessionNotes, GetSessionNotes } from 'api/SmartAccessApi';
import { Loader } from 'components/Loader';
import { ToastMessage } from 'components/ToastMessages';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';


interface OwnProps {
  close: () => void;
  isOpen: boolean;
  sessionId: number;
  sessionNumber: string;
  creatingDate: Moment
}

type Props = OwnProps;

export default class AddNoteToSessionComponent extends Component<Props, any> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      notes: null
    }
  }

  componentWillMount() {
    this.fetchSessionNotes();
  }

  fetchSessionNotes = () => {
    this.setState({ loading: true });
    GetSessionNotes(this.props.sessionId).then(data => {
      this.setState({ loading: false })
      if (data) {
        this.setState({ notes: data.notes });
      }
    })
  }

  onChangeNotesHandler = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({ notes: value });
  }

  onSubmitHandler = () => {
    if (this.state.notes) {
      this.setState({ loading: true });
      AddSessionNotes({ notes: this.state.notes, sessionId: this.props.sessionId })
        .then(_ => {
          this.setState({ loading: false });
          toast.success(
            <ToastMessage>
              Add Note Successfully !
            </ToastMessage>
          );
        }, _ => {
          this.setState({ loading: false });
          toast.error(
            <ToastMessage>
              Error has been happened !
            </ToastMessage>
          );
        })

    }
  }
  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >
        <ModalHeader toggle={this.props.close}>{this.props.sessionNumber} {' - '} Session Notes</ModalHeader>

        <ModalBody >
          {this.state.loading ? <Loader isLoading={true} /> : null}
          <ToastContainer />
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <div className="mt-2">
                  <span> Date:  </span>
                  <strong> {moment(this.props.creatingDate).format('MMMM DD YYYY')} </strong>
                </div>

                <div className="mt-2">
                  <span> Time:  </span>
                  <strong> {moment(this.props.creatingDate).format('hh:mm a')} </strong>
                </div>
              </div>

              <FormGroup>
                <Label> Session Notes </Label>
                <Input type="textarea" className="form-control"
                  rows={7}
                  onChange={this.onChangeNotesHandler}
                  value={this.state.notes}
                />

                <div className="text-center">
                  <button
                    onClick={this.onSubmitHandler}
                    className="btn btn-success mt-2 rounder-raduis">
                    Save
                  </button>
                </div>
              </FormGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
