import React, {ChangeEvent, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Loader} from "../../../components/Loader";
import Select from "react-select";
import "./../custom.scss";
import {addPostCategory, editPostCategory, GetPostTypes, PostCategory} from "../../../api/PostCategoriesApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../../components/ToastMessages";
import {PostType} from "../../../api/PostsApi";

export enum ModalStatus {
    __CREATE__, __EDIT__
}

interface IAddEditNewsCategoriesProps {
    isOpen: boolean,
    onClose?: () => void,
    onUpdated?: () => void,
    item?: PostCategory | null,
    modalStatus: ModalStatus,
}

interface IAddEditPostCategoriesState {
    data: PostCategory,
    loading: boolean,
    formValid: boolean
}

const initState: IAddEditPostCategoriesState = {
    data: {
        name: '',
        id: 0,
        pType: '',
    },
    formValid: false,
    loading: false
}

const categoriesTypes = [{id: 'News', name: 'News'}, {id: 'Cases', name: 'Cases'}];

const AddEditPostCategories: React.FC<IAddEditNewsCategoriesProps> = (props) => {
    const [state, setState] = useState<IAddEditPostCategoriesState>(
        !!props.item ? {...initState, data: props.item , formValid: true, loading: false} : initState);

    const [postTypes, setPostTypes] = React.useState<PostType[]>();

    const fetchPostTypes = () => {
        GetPostTypes().then(types=> setPostTypes(types));
    }

    useEffect(()=> {
        fetchPostTypes();
    }, [])


    const submitHandler = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const editCase = state.data.id;
        let submitPromise = editCase ? editPostCategory(state.data) : addPostCategory(state.data);
        submitPromise.then(
            (resp)=>{
                toast.success(
                    <ToastMessage>
                        Category has been {editCase ? "edited" : "added"} successfully
                    </ToastMessage>
                );

                props.onClose?.();
            }
        ).catch(err=>{
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong
                    &quot;, please try again later.
                </ToastMessage>
            )
        })
    }

    const changeHandlerName = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;

        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, name },
            formValid: prevState.data?.name?.length > 0
        }));
    }

    const changeProductType = (type: any) => {
        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, pTypeId : type.id },
            formValid: type.name.length > 0
        }));
    }

    return (
        <Modal style={{ height: '400px' }} unmountOnClose isOpen={props.isOpen} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>
                {
                    props.modalStatus === ModalStatus.__EDIT__ ? " Edit Category" : "Add Category"
                }
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="catName"> Name * </label>
                    <input className={'form-control'}
                           onChange={changeHandlerName}
                           value={state.data.name} id={'catName'} />
                </div>

                <div className="form-group">
                    <label htmlFor="catName"> Type * </label>
                    <Select
                        isDisabled={!!state.data.id}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id + ""}
                        options={postTypes}
                        value={postTypes?.find(pct=>pct.name === state.data.pType)}
                        onChange={(option) => {
                            changeProductType(option)
                        }}
                        placeholder="Select a Category"
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary"
                        onClick={props.onClose}
                > Cancel </button>
                <button onClick={submitHandler} className="btn btn-primary"
                        disabled={!state.formValid}> Save </button>
                {
                    state.loading ? <Loader isLoading={true} /> : null
                }
            </ModalFooter>
        </Modal>
    );
}

export default AddEditPostCategories;
