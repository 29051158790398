import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { reorder } from "util/reorder";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import {
    getSponsorsInSponsorGroup,
    Sponsor,
    SponsorsInGroupRequest
} from "../api/Sponsors";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import { NormalizedPagination } from "./NormalizedPagination";
import { sponsorsListSchema } from "./Schemas";

export interface SponsorsState {
    [key: string]: Sponsor | {};

    pagination: {
        bySponsorGroupId: NormalizedPagination & {
            [key: string]: { reorderIds?: string[] };
        };
    };
}

const initialState: SponsorsState = {
    pagination: { bySponsorGroupId: {} }
};

interface RequestSponsorsPaginationInGroupAction {
    sponsorGroupId: number;
}

interface ReceiveSponsorsPaginationInGroupAction {
    sponsorGroupId: number;

    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}
interface ReorderSponsorLocalAction {
    sponsorGroupId: number;
    currentIndex: number;
    newIndex: number;
}

const sponsorsSlice = createSlice({
    name: "sponsors",
    initialState,
    reducers: {
        requestSponsorsPaginationInGroup(
            state,
            action: PayloadAction<RequestSponsorsPaginationInGroupAction>
        ) {
            const key = action.payload.sponsorGroupId;

            state.pagination.bySponsorGroupId = {
                ...state.pagination.bySponsorGroupId,
                [key]: {
                    ...state.pagination.bySponsorGroupId[key],
                    isFetching: true
                }
            };
        },
        receiveSponsorsPaginationInGroup(
            state,
            action: PayloadAction<ReceiveSponsorsPaginationInGroupAction>
        ) {
            const key = action.payload.sponsorGroupId;

            state.pagination.bySponsorGroupId = {
                ...state.pagination.bySponsorGroupId,
                [key]: {
                    ...state.pagination.bySponsorGroupId[key],
                    ...action.payload,
                    reorderIds: action.payload.ids,
                    isFetching: false
                }
            };
        },
        reorderSponsorsBySponsorGroupIdLocal(
            state,
            action: PayloadAction<ReorderSponsorLocalAction>
        ) {
            const oldIds =
                state.pagination.bySponsorGroupId[
                    action.payload.sponsorGroupId.toString()
                ].reorderIds;
            if (!oldIds) return;

            state.pagination.bySponsorGroupId[
                action.payload.sponsorGroupId.toString()
            ].reorderIds = reorder(
                oldIds,
                action.payload.currentIndex,
                action.payload.newIndex
            );
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["sponsors"]
            };
        })
    })
});

export default sponsorsSlice.reducer;

const {
    requestSponsorsPaginationInGroup,
    receiveSponsorsPaginationInGroup,
    reorderSponsorsBySponsorGroupIdLocal
} = sponsorsSlice.actions;

export { reorderSponsorsBySponsorGroupIdLocal };

export const fetchPaginatedSponsorsBySponsorGroup = (
    request: PaginatedRequest & SponsorsInGroupRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(
            requestSponsorsPaginationInGroup({
                sponsorGroupId: request.sponsorGroupId
            })
        );

        const result = await getSponsorsInSponsorGroup(request);
        const normalized = normalize(result.items, sponsorsListSchema);

        dispatch(addEntities(normalized.entities as any));

        dispatch(
            receiveSponsorsPaginationInGroup({
                ...result.meta,
                sponsorGroupId: request.sponsorGroupId,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveSponsorsPaginationInGroup({
                // error: e.toString(),
                sponsorGroupId: request.sponsorGroupId,
                ids: getState().sponsors.pagination.bySponsorGroupId[
                    request.sponsorGroupId.toString()
                ]?.ids,
                pageIndex: getState().sponsors.pagination.bySponsorGroupId[
                    request.sponsorGroupId.toString()
                ]?.pageIndex,
                pageSize: getState().sponsors.pagination.bySponsorGroupId[
                    request.sponsorGroupId.toString()
                ]?.pageSize,
                totalItemsCount: getState().sponsors.pagination
                    .bySponsorGroupId[request.sponsorGroupId.toString()]
                    ?.totalItemsCount
            })
        );
    }
};

export const reloadSponsorsBySponsorGroup = (
    sponsorGroupId: number
): AppThunk => async (dispatch, getState) => {
    const state = getState().sponsors.pagination.bySponsorGroupId[
        sponsorGroupId.toString()
    ];

    dispatch(
        fetchPaginatedSponsorsBySponsorGroup({
            sponsorGroupId: sponsorGroupId,
            pageIndex: state.pageIndex,
            limit: state.pageSize
        })
    );
};
