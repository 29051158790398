/* eslint-disable react/no-deprecated */
import * as React from 'react';


export default class WindowClickEventComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showActionCardKey: -1
    }
  }

  registerEvent = () => {
    window.addEventListener("click", ({ target }) => {


      let tar = target as any;

      if (
        (tar && tar["classList"].contains("action-btn")) ||
        (tar && tar["classList"].contains("btn-link")) ||
        (tar && tar.parentElement.classList.contains("btn-link"))
      ) {
        return;
      }
      if (this.props.setShowActionCardKey) {
        this.props.setShowActionCardKey(-1)
      }


      if (this.state.showActionCardKey > -1) {
        if (this.props.setShowActionCardKey) {
          this.props.setShowActionCardKey(-1)
        }
        this.setState({ showActionCardKey: -1 });
      }
    });
  };

  componentWillMount() {

    if (this.props.showActionCardKey) {
      this.setState({ showActionCardKey: this.props.showActionCardKey })
    }
    //this.registerEvent();
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.registerEvent);
  }
  render() {
    return (<> </>)
  }
}