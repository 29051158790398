import "./Login.scss";
import * as React from "react";
import { useEffect } from "react";
import { Button, CardBody, CardHeader, Container } from "reactstrap";
import { MainCard } from "../../MainCard";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../configureStore";
import { userLogin } from "../../../store/auth/authAction";
import personalInfo from "../../../assets/imgs/personal_info.svg";
import { toast } from "react-toastify";

export const Login = () => {
    const {loading, userInfo, error,accessToken} = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch();

    const {register, handleSubmit} = useForm()

    const navigate = useNavigate()

    // redirect authenticated user to profile screen
    useEffect(() => {
        if (userInfo && accessToken) {
            navigate('/')
        }
    }, [navigate, userInfo])

    const submitForm = (data: any) => {
        if(data.username && data.password) {
            dispatch(userLogin({ ...data }));
        }else{
            toast.error("Please enter valid username and password")
        }
    }

    return (
        <div className="fill-screen bg-signup">

            <Container>
                <div className="d-flex justify-content-center pt-5">
                    <MainCard className={"loginCard"}>
                        <CardHeader>
                            <img
                                alt="Sample"
                                width={"100%"}
                                src={personalInfo}
                            /><br/>
                            Please login to your account
                        </CardHeader>

                        <CardBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                {error && <p style={{color: "red"}}>{error}</p>}
                                <div className='form-group'>
                                    <label htmlFor='email'>Username</label>
                                    <input
                                        type='input'
                                        className='form-input'
                                        {...register('username')}
                                        required
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='password'>Password</label>
                                    <input
                                        type='password'
                                        className='form-input'
                                        {...register('password')}
                                        required
                                    />
                                </div>
                                <Button type='submit' block className='btn btn-primary button'
                                onClick={handleSubmit(submitForm)} disabled={loading}>
                                    {loading ? 'loading' : 'Login'}
                                </Button>
                            </form>
                        </CardBody>
                    </MainCard>
                </div>
            </Container>
        </div>
    );
}