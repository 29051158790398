import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../api/common/PaginatedResult";
import {
    getSpeakersForEvent, getSpeakersWithoutEvent,
    Speaker,
    SpeakersForEventRequest
} from "../api/Speakers";
import { AppThunk } from "./index";

export interface SelectSpeakersListState {
    isBusy: boolean;
    eventId: number | null;
    speakersResult: PaginatedResult<Speaker> | null;
    error: string | null;
}

const initialState: SelectSpeakersListState = {
    error: null,
    isBusy: false,
    speakersResult: null,
    eventId: null
};

interface ReceiveSelectEventSpeakersListAction {
    error: string | null;
    speakersResult: PaginatedResult<Speaker> | null;
    eventId: number | null;
}

const SelectSpeakersList = createSlice({
    name: "SelectSpeakersList",
    initialState,
    reducers: {
        requestSelectSpeakersList: (state, action: PayloadAction) => {
            state.isBusy = true;
        },
        receiveSelectSpeakersList: (
            state,
            action: PayloadAction<ReceiveSelectEventSpeakersListAction>
        ) => {
            state.error = action.payload.error;
            state.eventId = action.payload.eventId;
            state.isBusy = false;
            state.speakersResult = action.payload.speakersResult;
        }
    }
});

export default SelectSpeakersList.reducer;

const {
    requestSelectSpeakersList,
    receiveSelectSpeakersList
} = SelectSpeakersList.actions;

export const loadSelectSpeakerList = (
    request: SpeakersForEventRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestSelectSpeakersList());
        const result = await getSpeakersWithoutEvent();
        dispatch(
            receiveSelectSpeakersList({
                eventId: request.eventId,
                error: null,
                speakersResult: result
            })
        );
    } catch (e) {
        dispatch(
            receiveSelectSpeakersList({
                error: "e.toString()",
                speakersResult: null,
                eventId: request.eventId 
            }) 
        );
    }
};
