import * as React from "react";
import Helmet from "react-helmet";

import { SendEmailRequest, sendMail } from "api/SendEmail";
import { ToastMessage } from "components/ToastMessages";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { FaSpinner } from "react-icons/fa";
import { TiAttachment } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Button, CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import * as yup from "yup";
import CertificateContext from "../../../Contexts/context";
import { getAll } from "../../../api/RecipientLists";
import ListCertifcatesModal from "../../../components/Certificates/ListCertificatesModal";
import { MainCard } from "../../../components/MainCard";
import { nameof } from "../../../util/nameof";
import { useRef } from "react";
import ReactHtmlParser from "html-react-parser";
import {useNavigate} from "react-router-dom";
import CKEditor from "../../../components/SendEmail/CKEditor";

interface Recipient {
  id: number;
  name: string;
}

interface Certificate {
  id: number | null;
  name: string;
  rawData: string;
  htmlTemplateContent: string;
}
export interface SendEmailForm {
  subject: string;
  sendTo: number | null;
  csv: number | null;
  content: string;
}


export const GlobalSendEmail: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(CertificateContext);
  const item: Certificate = context.item as any;

  // states
  const [recepients, setRecepients] = React.useState<Recipient[]>([]);
  const [fileName, setFilename] = React.useState("");
  const [selectedRec, setSelectedRec] = React.useState("");
  const [isModalOpen, setModal] = React.useState(false);
  // end states
  const formikRef = useRef<FormikProps<SendEmailForm>>(null);

  const toggle = () => setModal(!isModalOpen);
  const addPlaceHolder = (type: string) => {


    formikRef.current?.setFieldValue(
      "content",
      formikRef.current?.values.content + `<p>{{${type}}}</p>`
    );

  };


  const getRecipientList = async () => {
    const res = await getAll({ pageIndex: 1, limit: 100 });
    setRecepients(res.items);
  }

  React.useEffect(() => {
    getRecipientList();
  }, [])


  const ValidatorSchema = React.useMemo<yup.Schema<SendEmailForm>>(
    () =>
      yup.object().shape<SendEmailForm>({
        subject: yup.string().required("Subject is required."),
        sendTo: yup
          .number()
          .nullable()
          .test("sendTo", "Send to field or csv field is required", function (
            value
          ) {
            const { csv } = this.parent;
            if (!csv) return value != null;
            return true;
          }),
        csv: yup.number().nullable(),
        content: yup.string(),
      }),
    []
  );


  const initialValues = React.useMemo<SendEmailForm>(
    () => ({
      subject: "",
      sendTo: null,
      csv: null,
      content: "",
    }),
    []
  );

  const changeContent = React.useCallback((content) => {
    formikRef.current?.setFieldValue("content", content);
  }, []);

  const goToManage = () => {
    navigate(`/recipients`);
  };
 
  const goToHistory = () => {
    navigate(`/Emails/History`);
  }


  const handleSubmit = React.useCallback(
    async (values: SendEmailForm, actions: any) => { 
      try {
        const payload: SendEmailRequest = {
          htmlBodyTemplate: values.content,
          certificateId: item?.id || null,
          recipinetListId: values.sendTo, // Corrected typo 'recipinetListId' to 'recipientListId'
          subject: values.subject,
        };
        const addResult = await sendMail(payload); 
        toast.success('Email Sent successfully.'); 
      } catch (e) {
        toast.error('Something went wrong while sending email.'); 
      } finally {
      }
    },
    [] // Removed the dependency array, as it's not needed in this example
  );




  const renderForm = React.useCallback(
    (formProps: FormikProps<SendEmailForm>) => (
      <Form className="p-3 sendEmail">
        <Row>
          <Col sm={1} md={3} xl={3}>
            <FormGroup>
              <Label>Send to</Label>
              <UncontrolledDropdown className="w-100">
                <DropdownToggle className="align-items-center btn-icon" color={"outline-secondary"}>
                  {selectedRec || "Please select"}
                </DropdownToggle>
                <DropdownMenu>
                  {recepients.map((item) => {
                    return (
                      <DropdownItem
                        key={item.id}
                        onClick={() => {
                          formProps.setFieldValue("sendTo", item.id);
                          setSelectedRec(item.name);
                        }}
                      >
                        {item.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <ErrorMessage
                name={nameof<SendEmailForm>("sendTo")}
                render={(error) => (
                  <FormFeedback className="d-block">{error}</FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm={1} md={6} xl={6}>
            <FormGroup>
              <Label>Subject</Label>
              <Input
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                value={formProps.values.subject}
                name={nameof<SendEmailForm>("subject")}
              />

              <ErrorMessage
                name={nameof<SendEmailForm>("subject")}
                render={(error) => (
                  <FormFeedback className="d-block">{error}</FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm={1} md={3} xl={3}>
            <FormGroup>
              <Label>Placeholders</Label>
            <UncontrolledDropdown>
              <DropdownToggle className="align-items-center btn-icon" color={"outline-secondary"}>
                {"Add placeholder to email"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => addPlaceHolder("FirstName")}>
                  FirstName
                </DropdownItem>
                <DropdownItem onClick={() => addPlaceHolder("MiddleName")}>
                  MiddleName
                </DropdownItem>
                <DropdownItem onClick={() => addPlaceHolder("LastName")}>
                  LastName
                </DropdownItem>
                <DropdownItem onClick={() => addPlaceHolder("FullName")}>
                  FullName
                </DropdownItem>
                <DropdownItem onClick={() => addPlaceHolder("StartDate")}>
                  StartsAt
                </DropdownItem>
                <DropdownItem onClick={() => addPlaceHolder("EndDate")}>
                  EndsAt
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            </FormGroup>
          </Col>

          {item && item.id && (
            <Col className="upload" sm={1} md={6} xl={6}>
              <FormGroup>
                <Label className="d-center">
                  <span>
                    You have a <strong> {item.name} certificate </strong>
                    attached{" "}
                  </span>
                  <TiAttachment></TiAttachment>
                </Label>
              </FormGroup>
            </Col>
          )}
        </Row>

        <FormGroup>
          <Row className="placeholders">
            <Col
              style={{ display: "flex", alignItems: "center" }}
              sm={1}
              md={6}
              xl={9}
            >
              <Label>Email Content</Label>
            </Col>
          </Row>
          <CKEditor
            content={formProps.values.content}
            changeContent={changeContent}
          />
        </FormGroup>

        <div className="space-between mt-2">
          <Button
            type="submit"
            color="primary"
            disabled={formProps.isSubmitting}
            style={{ marginLeft: "auto" }}
          >
            {formProps.isSubmitting && (
              <span>
                <FaSpinner className="icon-spin" />
              </span>
            )}{" "}
            Send Email
          </Button>
        </div>
      </Form>
    ),
    [fileName, changeContent, recepients, selectedRec]
  );

  return (
    <>
      <Helmet title={"Email Config"} />
      {isModalOpen && (
        <ListCertifcatesModal
          toggle={toggle}
          isOpen={isModalOpen}
          className="listModal"
        />
      )}

      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <span>Send email / certificate</span>
            <div>
              <Button
                className="mr-3"
                id="addCommittee"
                onClick={goToManage}
                color="primary"
              >
                Manage Recepients
              </Button>{" "}
              <Button
                className="mr-3"
                id="addCommittee"
                onClick={goToHistory}
                color="primary"
              >
                Email History
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {item && item.id && (
              <div className="certificate-email">
                <Label>
                  <TiAttachment></TiAttachment>
                  Attached Certificate{" "}
                  <span onClick={toggle}> Change Certificate </span>
                </Label>
                <div>{ReactHtmlParser(item.htmlTemplateContent)}</div>
              </div>
          )}

          <Formik
              ref={formikRef as any}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={ValidatorSchema}
              render={renderForm}
          />
        </CardBody>
      </MainCard>
    </>
  );
}