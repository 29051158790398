import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const backendURL: string = 'https://emsapi.shamilapp.com';

export interface LoginData {
    username: string;
    password: string;
}

interface RegisterData {
    firstName: string;
    email: string;
    password: string;
}

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ username, password }: LoginData, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            const { data } = await axios.post(
                `/Connect/Token`,
                { username, password },
                config
            );

            // store user's token in local storage
            localStorage.setItem('accessToken', data?.accessToken);
            localStorage.setItem('refreshToken',data?.refreshToken)

            return data;
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else if (error.response && error.response.data){
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ firstName, email, password }: RegisterData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            await axios.post(
                `${backendURL}/api/user/register`,
                { firstName, email, password },
                config
            );
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);