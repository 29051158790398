/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ITax, deleteTax } from "../../api/TaxApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";


interface IDeleteTaxProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    taxToDelete: ITax
}


interface IDeleteTaxState {
    __loading__: boolean;
    __id__: number;
}
class DeleteTaxModal extends React.Component<IDeleteTaxProps, IDeleteTaxState> {
    constructor(props: IDeleteTaxProps) {
        super(props);
        this.state = {
            __loading__: false,
            __id__: 0,
        }
    }


    componentWillMount() {

        if (this.props.taxToDelete) {
            this.setState({ __id__: this.props.taxToDelete.id });

        }
    }


    handleDeleting = () => {
        this.setState({ __loading__: true })
        deleteTax(this.state.__id__).then(r => {
            this.setState({ __loading__: false })
            toast.success(
                <ToastMessage type="Success">
                    Deleted Successfully !
                </ToastMessage>
            );
            if (this.props.onUpdated) {
                this.props.onUpdated();
            }
            if (this.props.cancelDeleting) {
                this.props.cancelDeleting();
            }

        }).catch(error => {
            this.setState({ __loading__: false })
            toast.error(
                <ToastMessage type="Error">
                    Error Has been happened !
                </ToastMessage>
            );
        })
    }


    render() {
        return (
            <Modal toggle={this.props.cancelDeleting} isOpen={!!this.props.taxToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Tax
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ? <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.taxToDelete &&
                        `${this.props.taxToDelete.name || ""}`}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.taxToDelete &&
                            `${this.props.taxToDelete.name || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDeleting}>
                        Yes, Delete &quot;
                        {this.props.taxToDelete &&
                            `${this.props.taxToDelete.name || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteTaxModal;