import React, { ReactNode } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { FaGripVertical } from "react-icons/fa";
import { ColumnInstance } from "react-table";
import { DndItemTypes } from "../Constants";

interface DraggableTableHeaderProps {
  headerColumn: ColumnInstance;
  draggable: boolean;
  children: ReactNode | ReactNode[];
}

interface DraggableTableHeaderDragObject {
  headerColumn: ColumnInstance;
  type: string;
}

const DraggableTableHeader: React.FC<DraggableTableHeaderProps> = (props) => {
  const [{ isDragging }, dragRef] = useDrag<DraggableTableHeaderDragObject, { isDragging: boolean }, { isDragging: boolean }>(
    () => ({
      type: DndItemTypes.TABLE_HEADER,
      item: {
        headerColumn: props.headerColumn,
        type: DndItemTypes.TABLE_HEADER,
      },
      canDrag: props.draggable,
      collect: (monitor: DragSourceMonitor<DraggableTableHeaderDragObject, { isDragging: boolean }>) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  return (
    <th
      {...props.headerColumn.getHeaderProps()}
      ref={dragRef}
      style={{
        opacity: isDragging ? 0.4 : 1,
        cursor: props.draggable ? (isDragging ? "grabbing" : "grab") : "inherit",
      }}
    >
      <div>
        {props.draggable && (
          <span className="px-2 text-muted">
            <FaGripVertical size={12} />
          </span>
        )}
        {props.children}
      </div>
    </th>
  );
};

export default DraggableTableHeader;
