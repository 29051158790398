import { MainCard } from "components/MainCard";
import * as React from "react";
import { useEffect, useState } from "react";
import emptyStateSessionImage from "../../assets/imgs/empty_state.png";


import Helmet from "react-helmet";
import {
  Badge,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import {
  FaBoxOpen,
  FaEllipsisH,
  FaEye,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaMicrophone,
  FaPrint,
  FaRegUserCircle,
  FaVideo
} from "react-icons/fa";
import { dashboardCounter } from "../../api/DashboardApi";
import { toast } from "react-toastify";
import { LuUndo2 } from "react-icons/lu";
import { HiReceiptRefund } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { getAllOrders, OrderDto, printOrderInvoice } from "../../api/OrdersApi";
import { EmptyListState } from "../../components/EmptyListState";
import { statusColor } from "../ManageOrders/ManageOrders.component";
import { formatSimpleDate } from "../../util/dateUtil";
import printJS from "print-js";

export const Home: React.FC = () => {

  const [stats, setStats] = useState<any>();
  const navigate = useNavigate();
  const [data, setData] = useState<OrderDto[]>([]);

  useEffect(() => {
    fetchData();
    dashboardCounter().then(setStats).catch(reason => toast.error(reason.response?.data?.detail ?? "Error while fetching stats"))
  }, []);

  const fetchData = () => {

    getAllOrders({
      limit: 10,
      pageIndex: 1,
    }).then((data: any) => {
      setData(data.items);

    }).catch(err => {
      console.error(err);
    });
  }

  const viewOrderHandler = (orderCode: string) => {
    navigate(`/Orders/${orderCode}`)
  };

  const handlePrint = (invoiceCode: string) => {
    printOrderInvoice(invoiceCode).then((response: any) => {
      printJS({ printable: response, type: 'raw-html' })
    });
  };


  return (
    <>
      <Helmet title="Home"/>
      <div className={'d-flex flex-column gap-4 justify-content-center'}>
        <Row>
          <Col onClick={() => navigate('/Customers')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaRegUserCircle size={40}
                                 className='mb-2 text-primary bg-primary p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalCustomers ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Customers</span>
              </CardBody>

            </MainCard>
          </Col>

          <Col onClick={() => navigate('/Speakers')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaMicrophone size={40}
                              className='mb-2 text-primary bg-primary p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalSpeakers ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Speakers</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Courses')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaVideo size={40}
                         className='mb-2 text-primary bg-primary p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalCourses ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Courses</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Products')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaBoxOpen size={40}
                           className='mb-2 text-primary bg-primary p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalProducts ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Products</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Orders')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaFileInvoice size={40}
                               className='mb-2 text-primary bg-primary p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalOrders ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Orders</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Orders?status=paid')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <FaFileInvoiceDollar size={40}
                                     className='mb-2 text-success bg-success p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalSalesOrders ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Paid Orders</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Orders?status=refunded')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <LuUndo2 size={40}
                         className='mb-2 text-warning bg-warning p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalReturnOrders ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Refunded</span>
              </CardBody>

            </MainCard>
          </Col>
          <Col onClick={() => navigate('/Orders?status=refund request')} xs={12} md={6} lg={3}>
            <MainCard
              className='d-flex justify-content-center align-items-center rounded-0 mt-2 dashboard-card'>

              <CardBody className='d-flex flex-column justify-content-center align-items-center'>
                <HiReceiptRefund size={40}
                                 className='mb-2 text-danger bg-danger p-2 bg-opacity-25 rounded-circle'/>
                <p className="h3 text-dark-emphasis fw-bold">{stats?.totalAskRefundOrders ?? 0}</p>
                <span className="text-muted text-center text-uppercase fw-semibold">Refund Request</span>
              </CardBody>

            </MainCard>
          </Col>
        </Row>
      </div>

      <MainCard className='mt-4'>
        <CardHeader className="px-4 d-flex justify-content-between align-items-center">
          <h2 className='my-2'>Orders</h2> {data && data.length > 0 ? <Link to='Orders'> Show All Orders</Link> : ""}
        </CardHeader>
        <CardBody>


          {!data || data.length === 0 ?
            <EmptyListState
              image={emptyStateSessionImage}
              title={"There are no orders"}
            />
            :

            <table className={"table table-response"}>
              <thead className="thead-dark">
              <tr>
                <td>#</td>
                <td>Customer</td>
                <td>Phone Number</td>
                <td>Email</td>
                <td>Total Payment</td>
                <td>Status</td>
                <td>Date</td>
                <td>Actions</td>
              </tr>
              </thead>
              <tbody>

              {data.map((item: any, i) => {
                return (
                  <tr key={i}>
                    <td> {item.id} </td>
                    <td> {item.client?.displayName} </td>
                    <td> {item.client?.phoneNumber} </td>
                    <td> {item.client?.email} </td>
                    <td>{item.total} {" SAR"}</td>
                    <td><Badge style={{ width: '100%', height: '20px' }}
                               color={statusColor(item.status)}>{item.status?.name}</Badge></td>
                    <td> {item.createDate ? formatSimpleDate(item.createDate) : ""} </td>
                    <td className="position-relative">
                      <UncontrolledButtonDropdown size="sm">
                        <DropdownToggle caret outline color="dark">
                          <FaEllipsisH/>
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem onClick={(e) => {
                            e.preventDefault();
                            viewOrderHandler(item.code)
                          }}>
                            <FaEye/> View Order
                          </DropdownItem>
                          {item.invoice && <DropdownItem onClick={() => handlePrint(item.invoice.code)}>
                              <FaPrint/> Print Invoice
                          </DropdownItem>}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </td>
                  </tr>
                );
              })}
              </tbody>
              <tfoot>
              <tr>
                <td colSpan={8}>
                  <Link to='Orders'/>
                </td>
              </tr>
              </tfoot>
            </table>
          }

        </CardBody>
      </MainCard>
    </>
  );
}
