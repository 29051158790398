import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DeleteCoupon } from "../../api/CouponApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";

interface OwnProps {
    isOpen: boolean;
    close?: () => void;
    couponId: number;
    onUpdate?: () => void;
}

type Props = OwnProps;
export default class ConfirmDeleteCoupon extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }

    }


    deleteCouponHandler = () => {
        this.setState({ loading: true })
        DeleteCoupon(this.props.couponId).then(_ => {
            this.setState({ loading: false })
            this.props?.close?.();
            this.props?.onUpdate?.();
            toast.success(<ToastMessage>
                Delete Coupon Successfully !
            </ToastMessage>)
        }, _ => {
            this.setState({ loading: false })
            toast.error(<ToastMessage>
                Error has been happened !
            </ToastMessage>)
        })
    }
    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ToastContainer />
                {this.state.loading ? <Loader isLoading={true} /> : null}
                <ModalHeader> Delete Coupon </ModalHeader>
                <ModalBody>
                    Are you sure for Delete Coupon.
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={this.props.close}>
                        Cancel
                    </button>
                        <button
                            onClick={this.deleteCouponHandler}
                            className="btn btn-danger">
                            Confirm
                        </button>
                </ModalFooter>
            </Modal>
        )
    }
}
