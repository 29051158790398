import Axios, { AxiosResponse } from "axios";
import {PaginatedRequestWithFilter} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";
import { GetProductExportData } from "./ProductsApi";

export interface OrderDto {
  transactionId: number;
  OrderNumber: string;
  finalPrice: number;
  fullName: string;
  status: string;
  date: string;
  code:string;
}

export const getAllOrders = async (
  request: PaginatedRequestWithFilter
): Promise<PaginatedResult<OrderDto>> => {
  var result = await Axios.get<PaginatedResult<OrderDto>>(
    `api/Orders/AdminList`,
    { params: request }
  );
  return result.data;
};

export const getOrdersExportData = async (
  request: PaginatedRequestWithFilter
): Promise<string> => {
  var result = await Axios.get<string>(
    `/api/Orders/AdminExport`,
    { params: request }
  );
  return result.data;
};
export const getOrderStatus = async (
): Promise<Array<{name:string,id:number}>> => {
  const result = await Axios.get<Array<{ name: string, id: number }>>(
    `api/Orders/OrderStatus`
  );
  return result.data;
};

//get Order template

export interface OrderTemplateDto {
  template: string;
}

export const getOrderByCode = async (orderCode: string): Promise<any> => {
  const result = await Axios.get<any>(
      `api/Orders/${orderCode}`
  );
  return result.data;
}

export const printOrderInvoice = (invoiceCode: string): any =>
  Axios
    .get(`api/Invoices/InvoiceTemplate/${invoiceCode}`)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error: any) => {
      throw error;
    });
export const getInvoiceByOrderCode = (orderCode: string): any =>
  Axios
    .get(`api/Invoices/ByOrder/${orderCode}`)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error: any) => {
      throw error;
    });

export const getOrderTemplate = async (
  transactionId: number
): Promise<OrderTemplateDto> => {
  const result = await Axios.get<OrderTemplateDto>(
    `api/Orders/${transactionId}/GetOrderTemplate`
  );
  return result.data;
};

export interface IAddDyanmicOrder {}

// calculate Order

export interface ICalculateOrderParam {
  productIds: Array<number>;
  couponCode?: string | null;
  currencyId?: number | null;
}

export interface IcalculateOrderResult {
  productPricesAmount: string;
  displayTotalPrice: string;
  priceWithTax: string;
  priceAfterCouponDiscount: string;
  currencyCode: string;
}

export const CalculateInvocie = async (
  param: ICalculateOrderParam | any
): Promise<IcalculateOrderResult> => {
  let querStr: string | null = null;
  Object.keys(param).forEach((key: string | any) => {
    if (param[key]) {
      if (key === "productIds") {
        param["productIds"].forEach((id: number) => {
          if (!querStr) querStr = `?`;
          else querStr += `&`;
          querStr += `productIds=${id}`;
        });
      } else {
        if (!querStr) querStr = `?`;
        else querStr += `&`;
        querStr += `${key}=${param[key].toString()}`;
      }
    }
  });
  const response = await Axios.get<IcalculateOrderResult>(
    `api/Orders/CalculateOrder${querStr}`
  );
  return response.data;
};

export interface INewUserDynamicOrder {
  firstName: string;
  middleName?: string | null;
  lastName: string;
  email: string;
  phoneNumber: string;
}
export interface IAddDynamicOrder {
  productIds: Array<number>;
  currencyId?: number | null;
  couponCode?: string | null;
  tenantUserId?: number | null;
  newUserObj?: INewUserDynamicOrder | null;
}

export const AddDynamicOrderFC = async (
  payload: IAddDynamicOrder
): Promise<any> => {
  const response = await Axios.post(
    "api/Orders/AddDynamicOrder",
    payload
  );
  return response.data;
};

export interface Order {
  clientId: number;
  orderStatusId: number;
  products: Product[];
  payments: Payment[];
}

export interface Product {
  productId: number;
  membershipDurationId?: number;
  checkedInDate?: string;
  locationId?: number;
  instructorId?: number;
  qty: number;
  couponCode: string;
  addons: Addon[];
}

export interface Addon {
  addonId: number;
  qty: number;
}

export interface Payment {
  amount: number;
  paymentGatewayId: number;
  paymentMethodId: number;
  note: string;
}

export const ReviewOrder = async (
  payload: Order
): Promise<any> => {
  const response = await Axios.post(
    "api/Orders/ReviewOrder",
    payload
  );
  return response.data;
};


export const PlaceOrder = async (
  payload: Order
): Promise<any> => {
  const response = await Axios.post(
    "api/Orders/PlaceOrder",
    payload
  );
  return response.data;
};