import React, { Fragment, useEffect, useState } from "react";
import { Button, FormGroup, Input, InputGroup, Label } from "reactstrap";
import Select from "react-select";
import { SearchCategories } from "../../api/ProductCategoriesApi";
import { GetProductByCategory, GetProductDropdownListByCategories } from "../../api/ProductsApi";
import { SpeakerSelectItem } from "../ManageEventSessions/AddEditSessionFormModal";
import { FormatOptionLabelMeta } from "react-select/base";
import { StringTruncate } from "../../components/StringTruncate";
import { joinNames } from "../../util/nameUtils";
import { FaExclamationCircle, FaExclamationTriangle } from "react-icons/fa";

const AddOrderForm = ({ addProduct,error }: any) => {
  const [productsByCategory, setProductsByCategory] = useState<any[]>([])
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [categories, setCategories] = useState<any[]>([])
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>()
  const [selectedLocation, setSelectedLocation] = useState<any>()
  const [selectedAddOns, setSelectedAddOns] = useState<any[]>([])
  const [coupon, setCoupon] = useState('');
  useEffect(() => {
    SearchCategories().then(categories => setCategories(categories))

  }, [])
  useEffect(() => {
    if (selectedCategory && selectedCategory.id)
      fetchProductsByCategory(selectedCategory.id)
  }, [selectedCategory])

  const fetchProductsByCategory = (val: number) => {
    GetProductByCategory(val).then(products => setProductsByCategory(products))
  }
  useEffect(() => {
    setSelectedAddOns([]);
    setSelectedSpeaker([]);
    setSelectedLocation(undefined)
  }, [selectedCategory, selectedProduct])

  const renderSpeakerOption = (
    option: any,
    labelMeta: FormatOptionLabelMeta<SpeakerSelectItem>
  ) => {
    return (
      <div className="d-flex align-items-center">
        {option.profilePicture
          ? (
            <img
              src={option.profilePicture.fullUrl}
              width={30}
              height={30}
              style={{ width: 30, height: 30 }}
              className="mx-1 rounded-circle float-left border bg-white"
            />
          ) : (
            <div
              style={{ width: 30, height: 30 }}
              className="mx-1 rounded-circle float-left border bg-white"
            />
          )}
        <div>
          <StringTruncate
            text={option.title + " " + joinNames(option.firstName, option.secondName, option.thirdName, option.lastName) || ""}
            truncateSize={100}
          />

          <div className="text-muted small">
            <StringTruncate
              text={option.bio || ""}
              truncateSize={30}
            />
          </div>
        </div>
      </div>
    )
  };

  return (<div>
    {error && <span
      className='text-danger col-lg-8 col-md-12 d-flex flex-row justify-content-center align-items-center gap-2 py-4 '><FaExclamationTriangle/> {error}</span>}
    <FormGroup>
      <Label> Select Category </Label>
      <Select
        isClearable
        className={"mb-4"}
        isMulti={false}
        options={categories}
        value={selectedCategory}
        getOptionLabel={(option) => option.name ?? ''}
        getOptionValue={option => option.id ?? ''}
        onChange={(val: any) => {
          setSelectedProduct(null)
          setSelectedCategory(val)
        }}
      />
    </FormGroup>
    {selectedCategory && selectedCategory.id && ((!productsByCategory || productsByCategory.length === 0) ?
      <span
        className='text-secondary w-100 d-flex flex-row justify-content-center align-items-center gap-2 py-4 '><FaExclamationCircle/>No Product for selected Category</span> :
      <Fragment>
        <FormGroup>
          <Label>Select Product</Label>
          <Select
            isClearable
            className={"mb-4"}
            isMulti={false}
            value={selectedProduct}
            options={productsByCategory}
            getOptionLabel={(option) => option.name}
            getOptionValue={option => option.id}
            onChange={(val: any) => setSelectedProduct(val)}
          />
        </FormGroup>
        {selectedProduct && [3, 4].includes(selectedCategory.pcTypeId) &&
        <Fragment>
          {selectedProduct.speakers && <FormGroup>
              <Label>Select Instructor</Label>
              <Select
                  value={selectedSpeaker}
                  onChange={option => setSelectedSpeaker(option)}
                  options={selectedProduct.speakers}
                  formatOptionLabel={renderSpeakerOption}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => `${option.title} ${joinNames(option.firstName, option.secondName, option.thirdName, option.lastName)}`}/>

          </FormGroup>}
          {selectedProduct.locations && selectedProduct.locations.length > 0 &&<FormGroup>
              <Label>Select Location</Label>
              <Select
                  isMulti={false}
                  value={selectedLocation}
                  onChange={option => setSelectedLocation(option)}
                  options={selectedProduct.locations}
                  noOptionsMessage={s => "No options available for '" +
                    s.inputValue +
                    "'"}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}/>
          </FormGroup>}
          {selectedProduct.addons && selectedProduct.addons.length > 0 &&
            <FormGroup>
                <Label>Select Add Ons</Label>
                <Select
                    isMulti={true}
                    closeMenuOnSelect={false}
                    value={selectedAddOns}
                    onChange={options => setSelectedAddOns(options as any[])}
                    options={selectedProduct.addons}
                    noOptionsMessage={s => "No options available for '" +
                      s.inputValue +
                      "'"}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                />
            </FormGroup>}
        </Fragment>}
        <FormGroup>
          <Label>Coupon</Label>
          <InputGroup>
            <Input placeholder='Enter Coupon' value={coupon} onChange={(event) => {
              setCoupon(event.target.value)
            }}/>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Button
            type="submit"
            color="primary"
            onClick={async () => {
              const productAdded = await addProduct({ ...selectedProduct, selectedLocation, selectedSpeaker, selectedAddOns, coupon })
              if(productAdded){
                setSelectedCategory(null)
              }
            }}
            disabled={!selectedProduct}
          >
            Add Product
          </Button>
        </FormGroup>
      </Fragment>)}
  </div>)

}

export default AddOrderForm;