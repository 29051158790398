import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { MediaFile } from "./common/MediaFile";
import { PaginatedRequest, PaginatedRequestWithFilter } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";
import { ProductCategory } from "./ProductsApi";

export interface Course {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  banner: {
    fileName: string;
    contentType: string;
    fullUrl: string;
    sizeInBytes: number;
  };
  status: number;
}

// export interface IAddEditCourse {
//   name: string;
//   shortDescription: string;
//   description: string;
//   courseCategoryId: number;
//   productCategoryId: number;
//   directLink?: string;
//   youtubeLink?: string;
//   media?: string; // Assuming this is a base64 encoded binary string
//   locationsIds: number[];
//   price: {
//     amount: number;
//     discount: number;
//     isDiscountPercentage: boolean;
//     taxId: number;
//   };
//   totalQuantity: number;
//   minQuantityPerOrder: number;
//   maximumQuantityPerOrder: number;
//   isOnHomePage: boolean;
//   status: string;
// }

export interface IAddEditCourse {
  name: string;
  description?: string;
  content: string;
  courseCategory?: any | null;
  courseCategoryId: number | null;
  productCategory?: any | null;
  productCategoryId: number | null;
  directLink: string | null;
  youtubeLink: string | null;
  vimeoLink: string | null;
  lessonsLink?: string;
  media: any | null;
  price: {
    amount: number;
    discount: number;
    isDiscountPercentage: boolean;
    taxId: number;
    displayPrice?: string;
    totalPrice?: number;
    taxesIds: Array<number>;
    taxes?: any[] | null;
  };
  totalQTY: number | null;
  minQTYPerOrder: number | null;
  maxQTYPerOrder: number | null;
  isOnHomePage: boolean;
  locationsIds: number[];
  speakers?: any[];
  isPublished: boolean;
  locations?: any[];
  availabilityStartDateTime?: string;
  availabilityEndDateTime?: string;
  startDateTime?: string;
  endDateTime?: string;
  // selectedSpeakers: any | null;
}


export interface Ticket extends Course {
  startsAfter: Ticket;
  startDateTime: string;
  endDateTime: string;
}

export interface Price {
  displayPrice: string;
  displayTotalPrice: string;
  amount: number;
  taxesId: Array<number>;
  totalPrice?: number;
}

export interface CourseCategory {
  id: number;
  index?: number | null;
  name: string;
  pcTypeId: number;
  pcType: string;
  type: number;
  changeable: boolean;
}

export interface GetTicketsRequest extends PaginatedRequest {
  eventId: number;
}

export const deleteCourseById = async (id: number) => {
  return (await Axios.delete(`/api/Courses/${id}`)).data;
};

export type ICourseDropdownItem = ProductCategory;
export type ICourseStatus = Pick<ICourseDropdownItem, 'id' | 'name'>;

export interface ICourse {
  name: string;
  description?: string;
  content: string;
  courseCategoryId: number;
  productCategoryId: number | null;
  directLink: string | null;
  youtubeLink: string | null;
  vimeoLink: string | null;
  lessonsLink?: string;
  locationsIds: number[];
  price: {
    amount: number;
    discount: number;
    isDiscountPercentage: boolean;
    taxesIds: number[];
  };
  minQTYPerOrder: number | null;
  maxQTYPerOrder: number | null;
  totalQTY: number | null;
  isOnHomePage: boolean;
  isPublished: boolean;
  speakersIds: number[];
  availabilityStartDateTime?: string;
  availabilityEndDateTime?: string;
  startDateTime?: string;
  endDateTime?: string;
}

export const AddJsonCourse = async (payload: ICourse): Promise<any> => {
  const response = await Axios.post(
    "api/Courses",
    payload,
  );
  return response.data;
};

export const UpdateJsonCourse = async (courseId: number, payload: ICourse): Promise<any> => {
  const response = await Axios.put(
    `api/Courses/${courseId}`,
    payload,
  );
  return response.data;
};

export const EditCourse = async (courseId: number, payload: IAddEditCourse): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  const response = await Axios.put(
    "api/Courses/" + courseId,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

export const AddCourse = async (payload: IAddEditCourse): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  // data.images?.forEach((image) => {
  //   formData.append("images", image);
  // });
  const response = await Axios.post(
    "api/Courses",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};


export const GetCourses = async (request?: PaginatedRequestWithFilter): Promise<PaginatedResult<Course>> => {
  const response = await Axios.get<PaginatedResult<Course>>(
    "api/Courses",
    { params: request }
  );
  return response.data;
};

export const GetCourseById = async (courseId: number): Promise<IAddEditCourse> => {
  const response = await Axios.get<IAddEditCourse>("/api/courses/" + courseId);
  return response.data;
}

export const GetProductCategoriesForCourses = async (): Promise<PaginatedResult<ICourseDropdownItem>> => {
  const response = await Axios.get<PaginatedResult<ICourseDropdownItem>>(
    "api/Courses/ProductCategoriesForCourses"
  );
  return response.data;
};

export const GetCourseCategories = async (): Promise<ICourseDropdownItem[]> => {
  const response = await Axios.get<ICourseDropdownItem[]>(
    "api/CourseCategories/GetDropDownList"
  );
  return response.data;
};
export const GetCourseStatus = async (): Promise<ICourseStatus[]> => {
  const response = await Axios.get<ICourseStatus[]>(
    "api/Courses/PublishStatus"
  );
  return response.data;
};

// add Course session endpoint.

export interface ICourseImage {
  CourseImageId: number;
  mediaDto: MediaFile;
}

export const GetCourseImages = async (
  CourseId: number
): Promise<ICourseImage[]> => {
  const response = await Axios.get(`api/Courses/${CourseId}/Images`);
  return response.data;
};

export const getCourseTimeline = async () => {
  const response = await Axios.get("api/Home/CoursesTimeline")
  return response.data
}
