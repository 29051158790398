import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {deleteLocationById, GetLocations, Location} from "../../api/LocationsApi";
import Helmet from "react-helmet";
import {Badge, Button, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FaEdit, FaPlus, FaSpinner, FaTrash} from "react-icons/fa";
import {MainCard} from "../../components/MainCard";
import {Loader} from "../../components/Loader";
import EmptyImg from "../../assets/imgs/location.svg";
import {DataTable} from "../../components/DataTable";
import {EmptyListState} from "../../components/EmptyListState";
import {AddEditLocation} from "./AddEditLocation";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import blankProfile from "../../assets/imgs/male_avatar.svg";
import {CellProps} from "react-table";

export const Locations : any = () => {
    const [locations, setLocations] = useState<Location[]>([])
    const [loading, setLoading] = useState(false);
    const [showAddLocation, setShowAddLocation] = useState(false);
    const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState(false);
    const [locationToRemove, setLocationToRemove] = useState<Location | null>(null);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [page,setPage] = useState(1);
  const [count,setCount] = useState(0);
  const [maximumPerPage]=useState(20);


    const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);

    const showLocationDeleteModal = React.useCallback(
        (location: Location) => {
            setLocationToRemove(location);
            setDeleteLocationModalOpen(true);
        },
        [setLocationToRemove, setDeleteLocationModalOpen]
    );



    const getLocations = () => {
        GetLocations({pageIndex:page,limit:maximumPerPage}).then(locations => {
            setLocations(locations.items);
            setCount((locations as any).totalItemsCount)
        }).catch(err => {
            console.error(err);
        }).finally(()=>setLoading(false))
    }

    useEffect(()=>{
        setLoading(true);

        getLocations();

    }, [page])


    const handleLocationDelete = useCallback(async () => {
        if (!locationToRemove) return;
        setDeleteInProgress(true);
        try {
            await deleteLocationById(locationToRemove.id);
            toast.success(
                <ToastMessage>
                    Location &quot;{locationToRemove.name}{" "} was deleted successfully.
                </ToastMessage>
            );
            setLocationToRemove(null);
            getLocations();
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while deleting committee &quot;
                    {locationToRemove.name}{" "}
                    &quot;, please try again later
                </ToastMessage>
            );
        } finally {
            setDeleteInProgress(false);
        }
    }, [locationToRemove, setDeleteInProgress]);

    const handleEditClicked = (location : any) => {
        setLocationToEdit(location)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "#",
                Cell: (ctx: CellProps<any>) => {
                    return ctx.row.index+1
                }
            },
            {
                Header: "Image",
                Cell: function renderLocationActions(ctx: { row: { original: any; }; }) {
                    const location = ctx.row.original;
                    if (!location) return null;
                    return (
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                                overflow: "hidden",
                            }}
                        >
                            <img
                                src={location.media?.fullUrl ?? blankProfile}
                                width={"50px"}
                                height={"50px"}
                            />
                        </div>
                    )
                },
                // accessor: "id",
                // canSort :true,
            },
            {
                Header: "Name",
                accessor: "name",
                canSort :true
            },
            {
                Header: "Description",
                Cell: function renderLocationActions(ctx: { row: { original: any; }; }) {
                    const location = ctx.row.original;
                    if (!location) return null;
                    return (
                        <p
                            dangerouslySetInnerHTML={{__html: location ? location.description : ""}}>
                        </p>
                    )
                }
            },
            {
                Header: "City",
                accessor: "city.nameEn",
                canSort :true
            },
            {
                Header: "Country",
                accessor: "city.country.nameEn",
                canSort :true
            },
            {
                Header: "Active",
                Cell: function renderLocationActions(ctx: { row: { original: any; }; }) {
                    const location = ctx.row.original;
                    if (!location) return null;
                    return (
                        <div>
                            {location.isActive ?  <Badge color="success">Active</Badge> : <Badge color="secondary">Inactive</Badge>}
                        </div>
                    )},
                canSort :true
            },
            {
                Header: "Actions",
                Cell: function renderLocationActions(ctx: { row: { original: any; }; }) {
                    const location = ctx.row.original;
                    if (!location) return null;
                    return (
                        <div>
                            <Button
                                size="sm"
                                color="primary"
                                outline={true}
                                onClick={(_) => handleEditClicked(location)}
                            >
                                <FaEdit />
                            </Button>{" "}
                            <Button
                                size="sm"
                                color="danger"
                                outline={true}
                                onClick={() => {
                                    showLocationDeleteModal(location)
                                }}
                            >
                                <FaTrash />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [locations]
    );

    return (
        <div>
            <Helmet title="Locations" />
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>Locations</div>
                    <div>
                        <Button color={'primary'} onClick={()=>setShowAddLocation(true)}>
                            <FaPlus /> Add Location
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>

                    {loading ?
                        <Loader isLoading={true}/>
                        :
                        <DataTable
                            isBusy={false}
                            columns={columns}
                            data={locations || []}
                            pagination={
                              {
                                show: true,
                                onPageClicked: setPage,
                                totalItemsCount: count,
                                pageIndex: page,
                                pageSize: maximumPerPage,

                              }
                            }
                            emptyState={<div className={"text-center"}>
                                <EmptyListState
                                    image={EmptyImg}
                                    title={"You have no Locations yet"}
                                    // callToActionButton={{
                                    //     text: "Add your first Domain now!",
                                    //     onClick: () => setOpenAddModal(true)
                                    // }}
                                />
                            </div>}
                        />

                    }
                </CardBody>

            </MainCard>
            <AddEditLocation
                isOpen={showAddLocation || locationToEdit}
                locationToEdit={locationToEdit}
                toggle={() => {
                    getLocations();
                    setLocationToEdit(null);
                    setShowAddLocation(false);
                }}
            />


            <Modal size={"lg"} isOpen={!!locationToRemove} unmountOnClose>
                <ModalHeader toggle={()=>{setLocationToRemove(null);setDeleteLocationModalOpen(!deleteLocationModalOpen)}}>
                    Delete Location
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete &quot;
                    {locationToRemove && locationToRemove.name}{" "}
                    &quot;?
                </ModalBody>

                <ModalFooter className={"space-between"}>
                    <Button onClick={()=>{setLocationToRemove(null);setDeleteLocationModalOpen(!deleteLocationModalOpen)}}>
                        No, keep &quot;
                        {locationToRemove && locationToRemove.name}{" "}
                        &quot;
                    </Button>
                    <Button
                        color="danger"
                        onClick={handleLocationDelete}
                        disabled={deleteInProgress}
                    >
                        {deleteInProgress && (
                            <span>
                                <FaSpinner className="icon-spin" />
                            </span>
                        )}{" "}
                        Yes, Delete &quot;
                        {locationToRemove && locationToRemove.name}{" "}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );






}