/* eslint-disable react/prop-types */
import CertificateContext from "Contexts/context";
import { getCertificates } from "api/CertificateApi";
import React from "react";
import parse from 'html-react-parser';
import { useParams } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalExample = props => {
    const { className, isOpen, toggle } = props;
    const context = React.useContext(CertificateContext);

    const params = useParams();
    const eventId = Number(params.eventId);

    const [items, setItems] = React.useState([]);

    const fetchCertificates = async () => {
        const request = {
            eventId: eventId
        };
        const response = await getCertificates(request);
        setItems(response.items);
    };

    React.useEffect(() => {
        fetchCertificates();
    }, []);

    const select = i => {
        context.setActiveItem(i);
        if(props.receiveItem) {
            props.receiveItem(i);
        }
        toggle();
    }
    return (
        <div>
            <Modal size={"lg"} isOpen={isOpen} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Select Certificate</ModalHeader>
                <ModalBody>
                    <div className="certificates">
                        {items.reverse().map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${index}Certificate`}
                                >
                                    <div
                                        onClick={() => select(item)}
                                        className={`${index}Cover`}
                                    >
                                        {items[index].name}
                                        <span>Press on me to select</span>
                                    </div>
                                    {parse(item.htmlTemplateContent)}
                                </div>
                            );
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalExample;
