import * as React from "react";
import { CellProps } from "react-table";
import blankProfile from "../assets/imgs/no-photo.png";

interface FullNameObject {
    title: string | null;
    firstName: string;
    middleName: string;
    lastName: string | null;
    profilePicture :any;
}

export const TableFullNameRender: React.FC<CellProps<
    FullNameObject
>> = props => {
    const fullNameObject = props.row.original;
    if (!fullNameObject) return null;

    return (
      <div className="d-flex flex-row align-items-center gap-2">
      <img
        src={fullNameObject.profilePicture?.fullUrl ?? blankProfile}
        className="rounded-circle bg-white border mx-1"
        width={50}
        height={50}
      />
        <div>
            {[
                fullNameObject.title,
                fullNameObject.firstName,
                fullNameObject.middleName,
                fullNameObject.lastName
            ]
                .filter(s => s != null)
                .join(" ") || null}
        </div>
      </div>
    );
};
