import * as React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import Slider from "react-slick";
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {DeleteProductImage, GetProductImages, GetProductMedia, IProductImage} from '../../api/ProductsApi';
import {FaInfoCircle} from "react-icons/fa";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
interface IProductImagesComponentProps {
    isOpen: boolean;
    productId: number;
    images: any[];
    onCancel?: () => void;

}
export const ProductImagesComponent: React.FC<IProductImagesComponentProps> = props => {

    const [productImages, setProductImages] = React.useState<Array<IProductImage>>([]);

    const fetchProductImages = React.useCallback(() => {
        GetProductMedia(props.productId)
            .then(data => {
                setProductImages(data ?? []);
            })
    }, [setProductImages])


    React.useEffect(() => {
        fetchProductImages();
    }, [fetchProductImages])

    const handleDeleteProductImage = (imageId: number | null) => {
        if(!imageId || !props.productId) return;
        DeleteProductImage(props.productId, imageId).then(res=>{
            toast.success(
                <ToastMessage>
                    Image Deleted successfully!
                </ToastMessage>
            );
            props.onCancel && props.onCancel();
        }).catch(err=>{
            toast.error(
                <ToastMessage>
                    Error deleting the image
                </ToastMessage>
            );
        })
    }
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    return <Modal toggle={props.onCancel} isOpen={props.isOpen} size="lg">
        <ModalHeader toggle={props.onCancel}>
            View Images
        </ModalHeader>
        <ModalBody>
            <div>
                {productImages.length > 0 ?
                    <Slider {...sliderSettings}>
                        {
                            productImages.map((item, i) => {
                                return (
                                    <div className="p-2 mx-1 w-auto img-container"
                                         key={i.toString()}
                                    >
                                        <div className="d-none remove-container">
                                            <button

                                                onClick={e => {
                                                    handleDeleteProductImage(item.mediaDto?.id);
                                                }}

                                                className="remove-btn btn btn-danger">
                                                <AiOutlineDelete/>
                                            </button>
                                        </div>

                                        <img
                                            src={item.mediaDto?.fullUrl}
                                            className="rounded thumbnail"
                                            style={{
                                                height: 265,
                                                width: '100%',
                                                padding: '5px',
                                                boxShadow: '0px 0px 2px rgba(0,0,0,0.2)'
                                            }}/>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                    :
                    <Alert color="primary">
                        <FaInfoCircle/>{" "}
                        The product doesn't have any image attached
                    </Alert>
                }
            </div>
        </ModalBody>
    </Modal>
}