/* eslint-disable react/jsx-key */
/* eslint-disable react/no-deprecated */
import React, { ChangeEvent } from 'react';
import { toast } from "react-toastify";
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getRoleList, IRole } from "../../api/rolesApi";
import {
  addSubTenants,
  checkEmail,
  editSubTenants,
  ICheckEmailModel,
  ISubTenant,
  uploadImage
} from "../../api/SubAccountApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import Spinner from '../../assets/imgs/Spinner.gif';
import { getIdTypes } from "../../api/userInfo";
import Select from "react-select";
import imgDefault from "../../assets/imgs/photos.svg";

export enum ModalStatus {
  __CREATE__, __EDIT__
}

export interface IAddEditSubAccountModal {
  id: number | null;
  email?: string | null;
  password?: string | null;
  expireDate?: string | null;
  roleId?: number | null;
  isOpen: boolean;
  close?: () => unknown;
  onUpdated?: () => unknown;
  modalStatus: ModalStatus;
  item?: any | null
}

export interface IAddEditSubAccountState {
  __data__: any;
  __formValid__: boolean;
  __loading__: boolean;
  __roles__: Array<IRole>;
  __idTypes__: any[];
  __emailInsertedValid__: boolean;
  __emailInsertedValidLoading__: boolean;
  __userId__: number;
  __isSpeaker__: boolean;
  __profilePicture__:any,
  __picturePreview__:any,
  __imageEdited__:boolean,
}


class AddEditSubAccounts extends React.Component<IAddEditSubAccountModal, IAddEditSubAccountState> {

  constructor(props: IAddEditSubAccountModal) {
    super(props);
    this.state = {
      __data__: {
        email: null,
        password: null,
        confirmPassword: null,
        roleId: null,
        phoneNumber: null,
        firstName: null,
        secondName: null,
        thirdName: null,
        lastName: null,
        scfhsid: null,
        bio: null,
        idTypeId: null,
        zactaID: null,
        address: null,
        isSpeakerOnHomePage: false,
        linkedIn: null,
        facebook: null,
        twitter: null,
        website: null
      },
      __userId__: -1,
      __formValid__: false,
      __loading__: false,
      __roles__: [],
      __idTypes__: [],
      __emailInsertedValid__: true,
      __emailInsertedValidLoading__: false,
      __isSpeaker__: false,
      __profilePicture__:null,
      __picturePreview__:null,
      __imageEdited__:false,
    }
  }

  async componentWillMount() {
    let data = { ...this.state.__data__ }
    let id = 0;
    let isSpeaker = false;
    let image = undefined;
    // Get Roles.
    const roles = await getRoleList()


    const idTypes = await getIdTypes()
    if (this.props.item) {
      data = { ...this.props.item, }
      id = data.id;
      image = data.image;
      delete data.id;
      delete data.image
      isSpeaker = roles.some(value => value.id === data.roleId && value.name === 'Speaker')
    }

    this.setState({ __data__: data, __isSpeaker__: isSpeaker, __userId__: id, __roles__: roles, __idTypes__: idTypes,__picturePreview__:image })

  }



  // check if email valid handler.
  checkEmailValidHandler = (email: string) => {
    if (email) {
      this.setState({ __emailInsertedValidLoading__: true })
      checkEmail(email).then((data: ICheckEmailModel) => {
        this.setState({ __emailInsertedValidLoading__: false });
        if (data && data.isValid) {
          this.setState({ __emailInsertedValid__: true })
        } else {
          this.setState({ __emailInsertedValid__: false })
        }
      }).catch(error => {
        this.setState({ __emailInsertedValidLoading__: false });
      })
    }
  }


  // Change Handler
  changeHandler = (e: any) => {
    const name = e.target.name;
    let val: string | boolean = e.target.value;
    let data: ISubTenant = this.state.__data__;

    data = { ...data, [name]: val };
    this.setState({ __data__: data }, () => {
      this.validationHandler();
    });
  }
  validationHandler = () => {

    if (this.props.modalStatus === ModalStatus.__CREATE__) {
      if (this.state.__data__.email && this.state.__data__.password && this.state.__data__.password.length > 0 && this.state.__data__.password === this.state.__data__.confirmPassword
        && this.state.__data__.roleId && this.state.__data__.roleId > 0
      ) {
        this.setState({ __formValid__: true })
      } else {
        this.setState({ __formValid__: false })
      }
    } else {
      if (this.state.__data__.email) {
        this.setState({ __formValid__: true })
      } else {
        this.setState({ __formValid__: false })
      }
    }


  }

  generateProfilePicturePreview = (file: File) => {
    if (!file) return;
    const reader = new FileReader();

    reader.onloadend = () => {
      this.validationHandler()
      this.setState({__picturePreview__:reader.result as string,__imageEdited__:true})

    };

    if (file.type.match("image.*")) {
      reader.readAsDataURL(file);
    } else {
      this.setState({__picturePreview__:undefined})
    }
  };

  handleProfilePictureChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    this.setState({__profilePicture__:e.target.files[0]})

    this.generateProfilePicturePreview(e.target.files[0]);
  }

  uploadImage = (userId?:number) => {

    if(this.state.__imageEdited__){
      return uploadImage(userId ?? this.state.__userId__,this.state.__profilePicture__)
    }
    return new Promise<void>(resolve => {
      resolve();
    });

  }



  submitHandler = (e: any) => {
    e.preventDefault();
    this.setState({ __loading__: true });
    const modal: any = {
      ...this.state.__data__
    };

    if (this.props.modalStatus === ModalStatus.__CREATE__) {
      addSubTenants(modal).then((res: any) => {
        this.uploadImage(res).then(() => {
          this.setState({ __loading__: false });
          if (this.props.onUpdated) {
            this.props.onUpdated();
          }
          toast.success(
            <ToastMessage type="Success">
              User with email : {this.state.__data__.email} add Successfully;
            </ToastMessage>
          );
          if (this.props && this.props.close) {
            this.props.close();
          }
        }).catch(reason => {
          toast.error(
            <ToastMessage type="Error">
              Error while Uploading User Image
            </ToastMessage>
          );
        })

      }).catch(error => {
        toast.error(
          <ToastMessage type="Error">
            &quot;  {this.state.__data__.email} or {this.state.__data__.email} &quot; is Exist Before
          </ToastMessage>
        );
        this.setState({ __loading__: false });
      })
    } else {

      delete modal.password;
      editSubTenants(this.state.__userId__, modal).then(r => {
        this.uploadImage().then(() => {
        this.setState({ __loading__: false });
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
        if (this.props && this.props.close) {
          this.props.close();
        }
        toast.success(
          <ToastMessage type="Success">
            Updated Successfully !
          </ToastMessage>
        );}).catch(reason => {
          toast.error(
            <ToastMessage type="Error">
              Error while Uploading User Image
            </ToastMessage>
          );
        })
      }).catch(error => {
        this.setState({ __loading__: false });
        toast.error(
          <ToastMessage type="Error">
            Error has been happened !
          </ToastMessage>
        );
      })

    }

  }


  render() {
    return (
      <Modal size={"lg"} unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>
          {
            this.props.modalStatus === ModalStatus.__EDIT__ ? " Edit User" : "Add User"
          }
        </ModalHeader>
        <ModalBody>
          <FormGroup className="text-center">
            <Label>
              {this.state.__picturePreview__ ? (
                <img
                  src={this.state.__picturePreview__}
                  width={128}
                  height={128}
                  className="rounded-circle border"
                />
              ) : (
                <div
                  className="rounded-circle"
                  style={{ width: 128, height: 128 }}
                >
                  <img src={imgDefault} width={128} height={128} />
                </div>
              )}
              <Input
                type="file"
                className="d-none"

                onChange={this.handleProfilePictureChange}
                value={""}
              />

            </Label>
          </FormGroup>
          <div className="row">
            <div className="form-group col-lg-3 col-md-6 col-sm-12">
              <label> First Name </label>
              <input value={this.state.__data__.firstName} name={"firstName"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12">
              <label> Second Name </label>
              <input value={this.state.__data__.secondName} name={"secondName"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12">
              <label> Third Name </label>
              <input value={this.state.__data__.thirdName} name={"thirdName"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12">
              <label> Last Name </label>
              <input value={this.state.__data__.lastName} name={"lastName"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
          </div>

          <div className="form-group">
            <label> Phone number </label>

            <PhoneInput value={this.state.__data__.phoneNumber} onPhoneNumberUpdated={val => {
              const name: string = 'phoneNumber';
              let data: ISubTenant = this.state.__data__;
              data = { ...data, [name]: val };
              this.setState({ __data__: data }, () => {
                this.validationHandler();
              });

            }}/>
          </div>

          <div className="form-group position-relative">
            <label> Email </label>
            <input value={this.state.__data__.email} name={"email"} type="email" className="form-control"
                   onChange={e => {
                     e.preventDefault();
                     this.checkEmailValidHandler(e.target.value);
                     this.changeHandler(e);
                   }}
            />

            {
              this.state.__emailInsertedValidLoading__ ? <img
                style={{ position: 'absolute', top: 'calc(50% - 8px)', right: 0, width: '50px' }}
                src={Spinner}/> : null
            }
            {
              !this.state.__emailInsertedValid__ ? <div className={'alert alert-danger'}>
                Email already Exist !
              </div> : null
            }
          </div>
          <div className="form-group">
            <label> Address </label>
            <input value={this.state.__data__.address} name={"address"} type="text" className="form-control"
                   onChange={e => {
                     e.preventDefault();
                     this.changeHandler(e);
                   }}
            />
          </div>
          <div className="form-group">
            <label> Bio </label>
            <input value={this.state.__data__.bio} name={"bio"} type="text" className="form-control"
                   onChange={e => {
                     e.preventDefault();
                     this.changeHandler(e);
                   }}
            />
          </div>
          <div className="form-group">
            <label> Roles </label>
            <Select
              name={'roleId'}
              value={this.state.__roles__.find(value => value.id === this.state.__data__.roleId)}
              options={this.state.__roles__}
              onChange={(option) => {
                let data = { ...this.state.__data__, roleId: option?.id }
                let isSpeaker = option?.name === 'Speaker'
                if (!isSpeaker) {
                  data = {
                    ...data,
                    isSpeakerOnHomePage: false,
                    linkedIn: undefined,
                    facebook: undefined,
                    twitter: undefined,
                    website: undefined
                  }
                }
                this.setState({ __data__: { ...data },__isSpeaker__:isSpeaker })
                this.validationHandler();
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id.toString()}
            />

          </div>
          <div className="form-group">
            <label>ID Type</label>
            <Select
              name={'idTypeId'}
              value={this.state.__idTypes__.find(value => value.id === this.state.__data__.idTypeId)}
              options={this.state.__idTypes__}
              onChange={(option) => {
                this.setState({ __data__:{...this.state.__data__, idTypeId: option?.id} })
                this.validationHandler();
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id.toString()}
            />

          </div>
          <div className="form-group">
            <label> Zacta ID </label>
            <input value={this.state.__data__.zactaID} name={"zactaID"} type="text" className="form-control"
                   onChange={e => {
                     e.preventDefault();
                     this.changeHandler(e);
                   }}
            />
          </div>

          {
            this.props.modalStatus !== ModalStatus.__EDIT__ ?
              <>
                <div className="form-group">
                  <label> Password </label>
                  <input value={this.state.__data__.password} name={"password"}
                         onChange={this.changeHandler} autoComplete={"new-password"}
                         type="password" className="form-control"/>
                </div>
                <div className="form-group">
                  <label>Confirm Password </label>
                  <input value={this.state.__data__.confirmPassword} name={"confirmPassword"}
                         onChange={this.changeHandler} autoComplete={"confirm-password"}
                         type="password" className="form-control"/>
                </div>

              </> : null
          }
          {this.state.__isSpeaker__ ? <React.Fragment>
            <div className="form-group">
              <label> LinkedIn </label>
              <input value={this.state.__data__.linkedIn} name={"linkedIn"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group">
              <label> Facebook </label>
              <input value={this.state.__data__.facebook} name={"facebook"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group">
              <label> Twitter </label>
              <input value={this.state.__data__.twitter} name={"twitter"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>
            <div className="form-group">
              <label> Website </label>
              <input value={this.state.__data__.website} name={"website"} type="text" className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       this.changeHandler(e);
                     }}
              />
            </div>

            <FormGroup switch>
              <Label style={{ marginRight: "5px" }}>Show on Home Page</Label>

              <Input
                type="switch"
                checked={this.state.__data__?.isSpeakerOnHomePage}
                id={"isSpeakerOnHomePage"}
                onChange={e => {
                  this.setState({ __data__: { ...this.state.__data__, isSpeakerOnHomePage: e.target.checked } })
                  this.validationHandler();

                }}
              />
            </FormGroup>
          </React.Fragment> : null}


        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary"
                  onClick={this.props.close}
          > Cancel
          </button>
          <button onClick={this.submitHandler} className="btn btn-primary" disabled={!this.state.__formValid__}> Save
          </button>
          {
            this.state.__loading__ ? <Loader isLoading={true}/> : null
          }
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddEditSubAccounts;