import {
    Action, ThunkDispatch
} from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import mobileApps, { MobileAppsState } from "store/mobileApps";
import notificationTemplates, {
    NotificationTemplatesState
} from "store/notificationTemplates";
import sessions, { SessionsState } from "store/sessions";
import recipientGroups, { RecipientGroupsState } from "./RecipientGroups";
import tickets, { TicketsState } from "./Tickets";
import abstractTypes, { AbstractTypesState } from "./abstractTypes";
import accessDomains, { AccessDomainsState } from "./accessDomains";
import addEditSponsorSlice, {
    AddEditSponsorState
} from "./addEditSponsorSlice";
import broadcastDeviceNotificationReducer, {
    BroadcastDeviceNotificationState
} from "./broadcastDeviceNotificationSlice";
import CommitteeGroups, { CommitteeGroupsState } from "./committeeGroups";
import eventAbstractsListSlice, {
    EventAbstractsListState
} from "./eventAbstractsListSlice";
import eventCommitteeListSlice, {
    EventCommitteeListState
} from "./eventCommitteeListSlice";
import eventDetailsSlice, { EventDetailsState } from "./eventDetailsSlice";
import eventPresentersListSlice, {
    EventPresentersListState
} from "./eventPresentersListSlice";
import eventSessionsSlice, { EventSessionsState } from "./eventSessionsSlice";
import eventSpeakersListSlice, {
    EventSpeakersListState
} from "./eventSpeakersListSlice";
import eventSponsorsSlice, { EventSponsorsState } from "./eventSponsorsSlice";
import eventsListSlice, { EventsListState } from "./eventsListSlice";
import orders, { OrderState } from "./orders";
import paymentGateways, { PaymentGatewaysState } from "./paymentGateways";
import productCategories, { ProductCategoriesState } from "./productCategories";
import registrationForm, { RegistrationFormState } from "./registrationForm";
import selectAbstractTypeSlice, {
    SelectAbstractTypesListState
} from "./selectAbstractTypeSlice";
import selectCommitteeGroupListSlice, {
    SelectCommitteeGroupListState
} from "./selectCommitteeGroupListSlice";
import SelectPresenterSlice, {
    SelectPresentersListState
} from "./selectPresenterSlice";
import selectSpeakerSlice, {
    SelectSpeakersListState
} from "./selectSpeakerSlice";
import SponsorGroups, { SponsorGroupsState } from "./sponsorGroups";
import sponsors, { SponsorsState } from "./sponsors";
import {useDispatch} from "react-redux";

//TODO: normalize state with normalizr

export interface RootState {
    broadcastDeviceNotification: BroadcastDeviceNotificationState;
    eventsList: EventsListState;
    eventSessionsList: EventSessionsState;
    eventSponsorsList: EventSponsorsState;
    eventCommitteeList: EventCommitteeListState;
    addEditSponsorDetail: AddEditSponsorState;
    selectPresentersList: SelectPresentersListState;
    selectSpeakersList: SelectSpeakersListState;
    eventSpeakersList: EventSpeakersListState;
    eventAbstractsList: EventAbstractsListState;
    selectAbstractTypesList: SelectAbstractTypesListState;
    selectCommitteeGroupList: SelectCommitteeGroupListState;
    eventDetails: EventDetailsState;
    eventPresentersList: EventPresentersListState;

    //TODO: Move all to normalized store
    abstractTypes: AbstractTypesState;
    committeeGroups: CommitteeGroupsState;
    sponsorGroups: SponsorGroupsState;
    sponsors: SponsorsState;
    sessions: SessionsState;
    notificationTemplates: NotificationTemplatesState;
    mobileApps: MobileAppsState;
    accessDomains: AccessDomainsState;
    paymentGateways: PaymentGatewaysState;
    registrationForm: RegistrationFormState;
    tickets: TicketsState;
    productCategories: ProductCategoriesState;
    orders: OrderState;
    recipientGroups: RecipientGroupsState;
}

export const rootReducer = {
    broadcastDeviceNotification: broadcastDeviceNotificationReducer,
    eventsList: eventsListSlice,
    eventSessionsList: eventSessionsSlice,
    eventSponsorsList: eventSponsorsSlice,
    eventCommitteeList: eventCommitteeListSlice,
    addEditSponsorDetail: addEditSponsorSlice,
    eventSpeakersList: eventSpeakersListSlice,
    selectPresentersList: SelectPresenterSlice,
    selectSpeakersList: selectSpeakerSlice,
    eventAbstractsList: eventAbstractsListSlice,
    selectAbstractTypesList: selectAbstractTypeSlice,
    selectCommitteeGroupList: selectCommitteeGroupListSlice,
    eventDetails: eventDetailsSlice,
    eventPresentersList: eventPresentersListSlice,
    abstractTypes: abstractTypes,
    committeeGroups: CommitteeGroups,
    sponsorGroups: SponsorGroups,
    sponsors: sponsors,
    sessions,
    notificationTemplates,
    mobileApps,
    accessDomains,
    paymentGateways,
    registrationForm,
    tickets,
    productCategories,
    orders,
    recipientGroups
};

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useThunkDispatch = () => useDispatch<ThunkDispatch<RootState, any, any>>();
