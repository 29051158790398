import Axios from "axios";
import {Moment} from "moment";
import {Product} from "./ProductsApi";
import {PaginatedRequest} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface ICouponModel {
  id: number;
  code: string;
  couponCode?: string;
  discountPercentage: number;
  startDate: Moment;
  endDate: Moment;
  includeAllProducts: boolean;
  productIds?: Array<number> | null;
  productCategoryIds?: Array<number> | null;
  forAllMembershipPlans: boolean;
  membershipPlansIds?: Array<number> | null;
  usageQTY?: number | null;
  totalQTY?: number | null;
  maxUsagePerUser?: number | null;
}

// get all

export const GetAll = async (
  request: PaginatedRequest
): Promise<PaginatedResult<ICouponModel>> => {
  const response = await Axios.get<PaginatedResult<ICouponModel>>(
    "api/Coupons",
    { params: request }
  );
  return response.data;
};

export const AddCoupon = async (payload: ICouponModel): Promise<any> => {
  const response = await Axios.post("api/Coupons", payload);
  return response.data;
};

// get products of coupon.

export interface ICouponProduct {
  product: Product;
  finalPriceWithCoupon: String;
}


export interface ICouponProductCategory {
  id: number;
  name: string;
  pcTypeId: number;
  pcType: string;
}

export interface ICouponMembership {
  id: number;
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string | null;
}

export const GetCouponProducts = async (
  couponId: number
): Promise<PaginatedResult<ICouponProduct>> => {
  const response = await Axios.get(`api/Coupons/CouponProductsBy/${couponId}`);
  return response.data;
};

export const GetCouponProductCategories = async (
    couponId: number
): Promise<PaginatedResult<ICouponProductCategory>> => {
  const response = await Axios.get(`api/Coupons/CouponProductCategoriesBy/${couponId}`);
  return response.data;
};

export const GetCouponMemberships = async (
    couponId: number
): Promise<PaginatedResult<ICouponMembership>> => {
  const response = await Axios.get(`api/Coupons/CouponMembershipsBy/${couponId}`);
  return response.data;
};

// delete product from coupon
export const RemoveProductFromCoupon = async (
  productId: number,
  couponId: number
): Promise<any> => {
  const response = await Axios.delete(
    `api/Coupon/${couponId}/RemoveProduct/${productId}`
  );
  return response.data;
};

// delete coupon

export const DeleteCoupon = async (couponId: number): Promise<any> => {
  const response = await Axios.delete(`api/Coupons/${couponId}`);
  return response.data;
};

// edit coupon

export interface IEditCoupon {
  code: string;
  discountPercentage: number;
  startDate: Moment;
  endDate: Moment;
  includeAllProducts: boolean;
  productIds?: Array<number> | null;
  productCategoryIds?: Array<number> | null;
  forAllMembershipPlans: boolean;
  membershipPlansIds?: Array<number> | null
  totalQTY?: number | null;
  maxUsagePerUser?: number | null;
  autoApply?: boolean;
}

export const EditCoupon = async (
  couponId: number,
  payload: IEditCoupon
): Promise<any> => {
  const response = await Axios.put(
    `api/Coupons/${couponId}`,
    payload
  );
  return response.data;
};


//SearchItems
export const SearchItems = async (
    text: string | null
): Promise<any[]> => {
  const response = await Axios.get<any[]>(
      `api/Coupons/SearchItems?${text? 'text="+text':''}`
  );
  return response.data;
};

