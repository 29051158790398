import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { ContactInfo } from "./common/ContactInfo";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";
import { Person } from "./common/Person";

export interface SpeakersForEventRequest extends PaginatedRequest {
  eventId: number;
  query?: string | null;
}

export interface Speaker extends Person {
}

export interface EditSpeakerRequest {
  [key: string]: any;

  speakerId: number;
  profilePicture: File | null;
  title: string;
  firstName: string;
  secondName: string | null;
  thirdName: string | null;
  lastName: string;
  bio?: string;
  contact?: ContactInfo;
  isOnHomePage: boolean;
  AssignedEvents?: AssignedEvent[];
}

export interface AssignSpeakerEventsRequest {
  speakerId: number;
  assignedEvents: number[];
}

export interface AssignSpeakerCoursesRequest {
  speakerId: number;
  assignedCourses: number[];
}

export interface AddSpeakerRequest {
  [key: string]: any;

  // eventId: number;
  profilePicture: File | null;
  title: string;
  firstName: string;
  secondName: string | null;
  thirdName: string | null;
  lastName: string;
  bio?: string;
  contact?: ContactInfo;
  isOnHomePage: boolean;
  AssignedEvents?: AssignedEvent[];
}

export interface AssignedEvent {
  eventId: number,
  eventName?: string,
  isOnHomePage: boolean
}

export const getSpeakersForEvent = async (
  request: SpeakersForEventRequest
): Promise<PaginatedResult<Speaker>> => {
  const { eventId, ...others } = request;
  const result = await Axios.get<PaginatedResult<Speaker>>(
    `/api/events/${eventId}/speakers`,
    {
      params: others
    }
  );

  return result.data;
};

export const GetSpeakerEvents = async (
  id: number
): Promise<any[]> => {
  const result = await Axios.get<any[]>(
    `/api/Speakers/GetSpeakerEvents/${id}`,
  );

  return result.data;
};

export const GetSpeakerSchedule = async (
  id: number
): Promise<any[]> => {
  const result = await Axios.get<any[]>(
    `/api/Speakers/Schedule/${id}`,
  );

  return result.data;
};

export const GetDaysOfWeek = async (): Promise<any[]> => {
  const result = await Axios.get<any[]>(
    `/api/Speakers/DaysOfWeek`,
  );

  return result.data;
};



export const addNewSpeaker = async (
  request: AddSpeakerRequest
): Promise<NewResourceResult<number>> => {
  const { eventId, ...others } = request;
  const formData = objectToFormData(others);

  const result = await Axios.post<NewResourceResult<number>>(
    `/api/Speakers/Add`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );

  return result.data;
};

export const updateSpeaker = async (
  request: EditSpeakerRequest
): Promise<void> => {
  const { speakerId, ...others } = request;
  const formData = objectToFormData(others);

  await Axios.put(`/api/speakers/${speakerId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const updateSpeakerSchedule = async (
  data: any, speakerId: number
): Promise<void> => {
  await Axios.put(`/api/Speakers/Schedule/${speakerId}`, data);
};

export const deleteSpeakerById = async (speakerId: number): Promise<void> => {
  await Axios.delete(`/api/speakers/${speakerId}`);

  return Promise.resolve();
};

export const getSpeakersWithoutEvent = async (request?:any): Promise<PaginatedResult<Speaker>> => {
  const result = await Axios.get<PaginatedResult<Speaker>>(
    `/api/speakers/withoutevent`,{params:request}
  );

  return result.data;
};

export const assignSpeakerEvents = async (
  request: AssignSpeakerEventsRequest
): Promise<void> => {
  const { speakerId, ...others } = request;
  await Axios.put(`/api/speakers/AssginSpeakerEvents/${speakerId}`, others);
};

export const assignSpeakerCourses = async (
  request: AssignSpeakerCoursesRequest
): Promise<void> => {
  const { speakerId, ...others } = request;

  await Axios.put(`/api/speakers/AssginSpeakerCourses/${speakerId}`, others);
};

export const getSpeakerById = async (speakerId: number): Promise<any> => {
  const result = await Axios.get<any>(`/api/speakers/${speakerId}`);
  return result.data;
}

export const searchSpeakers = async (text?: string): Promise<any[]> => {
  const result = await Axios.get<any[]>(`/api/speakers/Search`, { params: { text } });
  return result.data;
}