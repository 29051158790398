import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaChevronDown, FaChevronRight, FaGripVertical, } from "react-icons/fa";
import { Row } from "react-table";

interface ReorderableRowProps {
  row: Row<Record<string, unknown>>;
  canReorder: boolean;
  showFirstCell: boolean;
  onClick?:(row:any)=>void

}

export const ReorderableRow: React.FC<ReorderableRowProps> = (props) => {
  const { row, canReorder, showFirstCell } = props;

  return (
    <Draggable draggableId={row.id} index={row.index} isDragDisabled={true}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={()=> props.onClick && props.onClick(row)}
        >
          {showFirstCell && (
            <td  style={{ width: 1 }} >
              {row.original && canReorder && (
                <div>
                  <span className="text-muted">
                    <FaGripVertical size={12} />
                  </span>
                </div>
              )}
            </td>
          )}
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()} key={cell.getCellProps().key}>
              {cell.isGrouped ? (
                <>
                  <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                  </span>{" "}
                  {cell.render("Cell")} ({row.subRows.length})
                </>
              ) : cell.isAggregated ? (
                cell.render("Aggregated")
              ) : cell.isPlaceholder ? null : cell.value === "null" ? null : (
                cell.render("Cell")
              )}
            </td>
          ))}
        </tr>
      )}
    </Draggable>
  );
};
