import { Loader } from 'components/Loader';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { FaSpinner } from "react-icons/fa";
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { nameof } from 'util/nameof';
import CKEditor from "../../components/SendEmail/CKEditor";
import TooltipHintComponent from "../../components/TooltipHint/TooltipHintComponent";
import { AiOutlineDelete } from "react-icons/ai";
import {
  GetPostCategoriesDropdownListByType,
  GetPostTagsDropdownListByType,
  GetPostTypes
} from "../../api/PostCategoriesApi";
import { AddPost, EditPost, GetPost, IAddEditPost, IPost, PostType } from "../../api/PostsApi";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";
import { GetTenantUserDropdownList, ITenantUserDropdownItem } from "../../api/MembershipAreaApi";
import ReactDatetime from "react-datetime";
import moment from "moment";

const initialPost: IAddEditPost = {
  pTypeId: 0,
  authorsIds: [],
  IsCurrentUserIncluded: false,
  postCategoryId: 0,
  title: '',
  isDraft: false,
  media: [],
  tags: []
};

interface AddEditPostProps {
  postToEdit: IPost | null,
  type: string,
  isOpen: boolean,
  toggle: () => void;
}

export const AddEditPost: React.FC<AddEditPostProps> = ({ postToEdit, type, isOpen, toggle }) => {
  const [post, setPost] = useState<IAddEditPost>(postToEdit ? {
    pTypeId: postToEdit.pType.id,
    authorsIds: postToEdit.authors ? postToEdit.authors.map(a => a.id) : [],
    IsCurrentUserIncluded: postToEdit.currentUserLike,
    postCategoryId: postToEdit.postCategory?.id,
    title: postToEdit.title,
    isDraft: postToEdit.status?.name === "Draft",
    media: postToEdit.media,
    tags: postToEdit.tags ? postToEdit.tags.map(t => t.id) : [],
    postDateTime: postToEdit.postTime
  } : initialPost);
  const [postType, setPostType] = useState(type === "News" ? 0 : 1)
  const [postCategories, setPostCategories] = React.useState<PostType[]>();
  const [postTags, setPostTags] = React.useState<PostType[]>();
  const [postTypes, setPostTypes] = React.useState<PostType[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  const [previewBannerUrl, setPreviewBannerUrl] = useState<string | null>(null);

  const [authorsList, setAuthorsList] = useState<ITenantUserDropdownItem[]>([]);
  const formikRef = useRef<FormikProps<any>>(null);

  const [selectedTags, setSelectedTags] = useState<any[]>();
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>();

  const fetchPostTypes = () => {
    GetPostTypes().then(types => setPostTypes(types));
  }

  const fetchPostCategories = () => {
    GetPostCategoriesDropdownListByType(postType).then(resp => {
      setPostCategories(resp);
    })
  }

  const searchPostGategory = (text: string) => {
    // if(text && text.length > 2){
    //     GetPostCategoriesDropdownListByType(postType, text).then(resp => {
    //         setPostCategories(resp);
    //     })
    // }
  }

  const fetchPostTags = () => {
    GetPostTagsDropdownListByType(postType).then(resp => {
      setPostTags(resp);
    })
  }

  const searchPostTag = (text: string) => {
    // if(text && text.length > 2){
    //     GetPostTagsDropdownListByType(postType, text).then(resp => {
    //         setPostTags(resp);
    //     })
    // }
  }

  const fetchAuthors = () => {
    GetTenantUserDropdownList().then(autors => setAuthorsList([...autors])).catch(err => {
    })
  }

  useEffect(() => {
    fetchAuthors();
    fetchPostTypes();
    fetchPostCategories();
    fetchPostTags();

    return () => {
      setSelectedAuthors([]);
      setSelectedTags([]);
    }
  }, [])

  useEffect(() => {
    if (postToEdit && postToEdit.id) {
      GetPost(postToEdit.id).then((p => {

        setSelectedTags(p.tags);
        setSelectedAuthors(p.authors);

        setTimeout(() => {
          setPost({
            postCategoryId: p.postCategory.id,
            tags: p.tags ? p.tags.map(t => t.id) : [],
            title: p.title,
            content: p.content ?? "",
            authorsIds: p.authors ? p.authors : [],
            media: p.media,
            isDraft: false,
            authorId: 0,
            pTypeId: p.pType.id,
            IsCurrentUserIncluded: false,
            postDateTime: moment(p.postTime).toISOString()

          })
        }, 0);

        if (p.media && p.media.fullUrl) {
          setPreviewBannerUrl(p.media.fullUrl)
        }

      })).catch(err => {
      })
    }
  }, []);

  const initialFormValues = () => {
    let formValues: IAddEditPost = initialPost;


    if (postToEdit) {
      const data: any = {
        postCategoryId: postToEdit.postCategory.id,
        tags: postToEdit.tags ? postToEdit.tags.map(t => t.id) : [],
        title: postToEdit.title,
        content: postToEdit.content ?? "",
        authorsIds: postToEdit.authors ? postToEdit.authors.map(value => value.id) : [],
        media: postToEdit.media,
        isDraft: false,
        pTypeId: postToEdit.pType.id,
        IsCurrentUserIncluded: false,
        postDateTime: postToEdit.postTime
      }
      formValues = {
        ...formValues,
        ...data
      };

      setPost({ ...data });

    } else {
      setPreviewBannerUrl(null)
    }
    return formValues;
  }


  const setPostAuthor = (authors: ITenantUserDropdownItem[]) => {
    setSelectedAuthors(authors);
    setPost(prevState => ({ ...prevState, authorsIds: authors.map(value => value.id) }));
    formikRef.current?.setFieldValue("authorsIds", authors);
  }
  const onChangePublicationDate = (value: any) => {
    setPost(prevState => ({ ...prevState, postDateTime: moment(value).toISOString() }))
    formikRef.current?.setFieldValue("postDateTime", moment(value).toISOString());

  }

  const onChangeTitle = (value: any) => {
    setPost(prevState => ({ ...prevState, title: value.target.value }))
    formikRef.current?.setFieldValue("title", value.target.value);

  }

  const setPostCategory = (option: any) => {
    const optionId = option.id;
    setPost(prevState => ({ ...prevState, pTypeId: postType, postCategoryId: optionId }));
    formikRef.current?.setFieldValue("postCategoryId", optionId);
    formikRef.current?.setFieldValue("pTypeId", postType);
  }

  const changePostTags = (options: PostType[]) => {
    setSelectedTags(options);
    const tagsIds = options.map(tag => tag.id);
    setPost(prevState => ({ ...prevState, tags: [...tagsIds] }));
    formikRef.current?.setFieldValue("tags", tagsIds);
  }

  const changeContent = React.useCallback((content) => {
    formikRef.current?.setFieldValue("content", content);
    setPost(prevState => ({ ...prevState, content: content }))
  }, []);

  const handleClose = () => {
    setSelectedAuthors([]);
    setSelectedTags([]);
    setPost(prevState => ({ ...prevState, content: "" }))
    toggle();
  }
  const addPostHandler = async (
    values: IAddEditPost,
  ) => {
    const postRequest = { ...post, ...values, authorsIds: post.authorsIds };
    setActionInProgress(true);
    console.log("post request", postRequest)

    let promise = null;

    if (postToEdit && postToEdit.id) {
      promise = EditPost(postToEdit.id, postRequest);
    } else promise = AddPost(postRequest);

    promise.then(resp => {
      toast.success(
        <ToastMessage>Post has been added successfully!</ToastMessage>
      );
      toggle();
    }).catch((err) => {
      
      toast.error(
        <ToastMessage type="Error">
          Error Has been happened !
        </ToastMessage>
      );
    })
      .finally(() => {
        setActionInProgress(false);
      })
  }

  const handleImagesChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!e.target.files || !formikRef.current) return;
        const file = e.target.files[0];
        formikRef.current.setFieldValue(
          nameof<IAddEditPost>("media"),
          file
        );

        if (file.type.startsWith("image/")) {
          if (typeof FileReader !== "undefined") {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
              setPreviewBannerUrl(fileReader.result as string);
            };

            fileReader.readAsDataURL(file);
          } else {
            setPreviewBannerUrl(null);
          }
          formikRef.current
        } else {
          setPreviewBannerUrl(null);
        }
      },
      [formikRef.current],
    )
  ;

  const form = (formProps: FormikProps<any>) => (
    <Form>
      <ModalBody>
        <FormGroup>
          <Label> Name* </Label>
          <Field
            name={nameof<IAddEditPost>("title")}
            render={({ field }: FieldProps) => (
              <Input  {...field} onChange={onChangeTitle} value={post.title}/>
            )}
          />
          <ErrorMessage
            name={nameof<IAddEditPost>("title")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label> Post Category* </Label>
          <Select
            isMulti={false}
            options={postCategories}
            onChange={(o: any) => {
              setPostCategory(o);
            }}
            onInputChange={searchPostGategory}
            value={postCategories?.find(c => c.id === post.postCategoryId)}
            name={nameof<IAddEditPost>("postCategoryId")}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
          />
          <ErrorMessage
            name={nameof<IAddEditPost>("postCategoryId")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label>Publication Date</Label>
          <Field
            name={nameof<IAddEditPost>("postDateTime")}
            render={({ field }: FieldProps) => {
              return (
                <ReactDatetime
                  {...field}
                  value={post.postDateTime ? moment(post.postDateTime) : undefined}
                  onChange={onChangePublicationDate}
                />
              )
            }}
          />

        </FormGroup>

        <FormGroup>
          <Label> Post Tags* </Label>
          <Select
            isMulti={true}
            options={postTags}
            onChange={(o: any) => {
              changePostTags(o);
            }}
            onInputChange={searchPostTag}
            name={nameof<IAddEditPost>("tags")}
            value={selectedTags}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
          />
          <ErrorMessage
            name={nameof<IAddEditPost>("tags")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label> Post Author* </Label>
          <Select
            isMulti={true}
            options={authorsList}
            onChange={(o: any) => {
              setPostAuthor(o);
            }}
            name={nameof<IAddEditPost>("authorsIds")}
            value={selectedAuthors}
            getOptionLabel={(option) => option.displayName}
            getOptionValue={(option) => option.id + ""}
          />
          <ErrorMessage
            name={"authors"}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>


        <FormGroup>
          <label> Post Thumbnail
            <TooltipHintComponent text="images of product will display in your site."/>
          </label>
          <Input
            type="file"
            value={undefined}
            name={nameof<IAddEditPost>("media")}
            accept="image/*"
            onChange={handleImagesChange}
          />
          <ErrorMessage
            name={nameof<IAddEditPost>("media")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />

        </FormGroup>

        {previewBannerUrl && (
          <div
            className="rounded thumbnail img-container"
            style={{ height: 265, }}
          >
            <div className="d-none remove-container">
              <button
                onClick={e => {
                  setPreviewBannerUrl(null);
                  formikRef.current && formikRef.current.setFieldValue(
                    nameof<IAddEditPost>("media"),
                    null
                  );
                }}
                className="remove-btn btn btn-danger">
                <AiOutlineDelete/>
              </button>
            </div>
            <img
              src={previewBannerUrl}
              className="rounded thumbnail"
              style={{ height: 265, width: "100%" }}
            />
          </div>
        )}

        <FormGroup>
          <Label> Content* </Label>

          <CKEditor
            content={post.content}
            changeContent={changeContent}
          />
          <ErrorMessage
            name={"Content"}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

      </ModalBody>
      <ModalFooter className="space-between">
        <Button type="button" color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={actionInProgress}
        >
          {actionInProgress && (
            <span>
                            <FaSpinner className="icon-spin"/>
                        </span>
          )}{" "}
          Save
        </Button>
      </ModalFooter>
    </Form>
  );

  return (
    <div>
      {
        loading ?
          <Loader isLoading={true}/>
          : null
      }
      <Modal isOpen={isOpen} toggle={handleClose} size="lg">
        <ModalHeader toggle={handleClose}>{postToEdit ? "Edit" : "Add"} {type}</ModalHeader>
        <Formik
          innerRef={formikRef}
          onSubmit={addPostHandler}
          // validationSchema={PostFormValidation}
          initialValues={initialFormValues}>
          {form}
        </Formik>
      </Modal>
    </div>
  );
}