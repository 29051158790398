import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RegistrationForm } from "api/RegistrationFormApi";
import { normalize } from "normalizr";
import {
    GetRegistrationFormRequest,
    getRegistrationForm
} from "../api/RegistrationFormApi";
import { AddEntitiesAction, addEntities } from "./Actions";
import { registrationFormSchema } from "./Schemas";
import { AppThunk } from "./index";

export interface RegistrationFormState {
    [key: string]: RegistrationForm | {};

    byEventId: {
        [eventId: string]: {
            eventId: number;
            isFetching: boolean;
            error?: string;
            id: string;
        };
    };
}

const initialState: RegistrationFormState = {
    byEventId: {}
};

interface RequestFetchRegistrationFormAction {
    eventId: number;
}

interface ReceiveFetchRegistrationFormAction {
    eventId: number;
    error?: string;
    id: string;
}

const registrationFormSlice = createSlice({
    name: "registrationForm",
    initialState,
    reducers: {
        requestFetchRegistrationForm(
            state,
            action: PayloadAction<RequestFetchRegistrationFormAction>
        ) {
            const eventId = action.payload.eventId.toString();
            state.byEventId[eventId] = {
                ...state.byEventId[eventId],
                isFetching: true
            };
        },

        receiveFetchRegistrationForm(
            state,
            action: PayloadAction<ReceiveFetchRegistrationFormAction>
        ) {
            const { eventId } = action.payload;

            state.byEventId[eventId.toString()] = {
                ...action.payload,
                isFetching: false
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["registrationForm"]
            };
        })
    })
});

export default registrationFormSlice.reducer;

const {
    requestFetchRegistrationForm,
    receiveFetchRegistrationForm
} = registrationFormSlice.actions;

export const fetchRegistrationForm = (
    request: GetRegistrationFormRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(
            requestFetchRegistrationForm({
                eventId: request.eventId
            })
        );

        const result = await getRegistrationForm(request);
        const normalizedResult = normalize(result, registrationFormSchema);

        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            receiveFetchRegistrationForm({
                eventId: request.eventId,
                error: undefined,
                id: normalizedResult.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchRegistrationForm({
                ...getState().registrationForm.byEventId[
                    request.eventId.toString()
                ],
                eventId: request.eventId,
                error: "e.toString()"
            })
        );
    }
};
