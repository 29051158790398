import * as React from "react";
import { DefaultSideNav } from "./DefaultSideNav";
import { EventsSideNav } from "./EventsSideNav";
import { Route, Routes } from "react-router-dom";

export const LayoutSideNavSelector: React.FC<any> = (props: any) => {
    return (
        <Routes>
            <Route
                path={"/events/:eventId/*"} caseSensitive={false}
                Component={() => <EventsSideNav sendEventName={props.sendEventName} sendEventId={props.sendEventId} />}
            />
            <Route path="/*" Component={DefaultSideNav} />
        </Routes>
    );
};
