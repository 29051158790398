import Axios from "axios";
export interface RegistrationForm {
    id: number;
    title: string;
    details: string | null;
    registrationTimeLimit: string;
    messageWhenSoldOut: string;
    attendeeQuestions: AttendeeQuestionItem[];
    conditions: [],
    currencyId:number
}

export interface AttendeeQuestionItem {
    id: number;
    question: string;
    required: boolean;
    type: QuestionType;
    questionOptions: QuestionOption[];
}

export interface QuestionOption {
    id: number;
    name: string;
}

export enum QuestionType {
    Text = "Text",
    CheckBoxesList = "CheckBoxesList",
    RadioButtonList = "RadioButtonList",
    DropDown = "DropDown",
    Paragraph = "Paragraph"
}

export interface GetRegistrationFormRequest {
    eventId: number;
}

export const getRegistrationForm = async (
    request: GetRegistrationFormRequest
): Promise<RegistrationForm> => {
    return (
        await Axios.get(`/api/events/${request.eventId}/RegistrationForm`)
    ).data;
};

export interface UpdateRegistrationFormRequest {
    eventId: number;
    title: string;
    details: string | null;
    registrationTimeLimit: string;
    messageWhenSoldOut: string;
    attendeeQuestions: UpdateAttendeeQuestion[];
}
export interface UpdateAttendeeQuestion {
    id: number | null;
    question: string;
    required: boolean;
    type: QuestionType;
    questionOptions: UpdateQuestionOption[];
}

export interface UpdateQuestionOption {
    id: number | null;
    name: string;
}

export const updateRegistrationForm = async (
    request: UpdateRegistrationFormRequest
) => {
    const { eventId, ...data } = request;

    return (await Axios.put(`/api/events/${eventId}/RegistrationForm`, data))
        .data;
};


//  Delete Question

export const deleteQuestion = async (questionId: number  ,eventId : number) => {
    const result = await  Axios.delete(`/api/events/${eventId}/RegistrationForm/DeleteQuestion/${questionId}`);
    return result.data;
}


