import Axios from "axios";


export interface IRolePermission {
    id: number;
    roleName:string;
    roleId : number;
    moduleName: string;
    moduleId: number;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canRead:boolean;
}


export interface IEditRolePermission {
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canRead:boolean;
}


export  const getRolePermissions = async (roleId: number): Promise<Array<IRolePermission>> => {
    const result = await Axios.get<Array<IRolePermission>>(`api/RolePermission/${roleId}`) ;
    return result.data;
}

export const editRolePermission = async (id: number , model: IEditRolePermission) : Promise<any> => {
    const result = await Axios.put(`api/RolePermission/${id}` , model);
    return result.data;
}