import { Loader } from 'components/Loader';
import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
    ChekinStatus,
    getCheckinsStatus,
    SessionTicketStatus,
    toggleSessions
} from '../../api/MembershipAreaSessionApi';
import Select from "react-select";

interface IConfirmCheckedInComponentProps {
    open: boolean;
    close: () => void;
    sessionId: number;
    sessionNumber: string;
    currentStatus: ChekinStatus;
    onUpdate: () => void
}

export default class ConfirmCheckedInComponent extends React.PureComponent<IConfirmCheckedInComponentProps, any> {

    constructor(props: any) {
        super(props);
        
        this.state = {
            loading: false,
            status:[],
            selectedStatus:props.currentStatus
        }
    }
    componentDidMount() {
        getCheckinsStatus().then(status =>
        this.setState({status})
        )

    }


    updateSessionCheckingHandler = () => {
        this.setState({ loading: true });
        toggleSessions(this.props.sessionId,this.state.selectedStatus?.id)
            .then(_ => {
                this.setState({ loading: false })
                this.props.onUpdate?.();
            }, _ => {
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.open} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    {
                        this.state.loading ?
                            <Loader isLoading={true} />
                            : null
                    }
                    Check in
                </ModalHeader>
                <ModalBody>
                    Please select Check-In status from Session number {this.props.sessionNumber}
                    <Select
                      options={this.state.status}
                      value={this.state.status?.filter((ps:ChekinStatus)=>ps.name === this.state.selectedStatus?.name)}
                      onChange={(o: any) => {
                          this.setState({selectedStatus:o})
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id +""}
                    />
                </ModalBody>
                <ModalFooter>

                    <button onClick={this.props.close} className="mx-2 btn btn btn-secondary">
                        Cancel
                    </button>
                    <button disabled={this.props.currentStatus?.id === this.state.selectedStatus?.id} onClick={this.updateSessionCheckingHandler} className="mx-2 btn btn-success">
                        Confirm
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}