import Axios from "axios";
import moment from "moment";
import { objectToFormData } from "../util/objectToFormData";
import { MediaFile } from "./common/MediaFile";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface PriceWithDiscount {
  amount: number;
  discount: number;
  isDiscountPercentage: boolean;
  taxId: number;
}

export interface Product {
  id: number;

  name: string;
  nameAr?: string | null;
  description: string | null;
  category: ProductCategory;
  categoryId: number;
  currencyId: number;
  taxId: number;
  minQTYPerOrder: number;
  maxQTYPerOrder: number;
  totalQTY: number;
  price: Price;
  eventIds?: Array<number> | null;
  images?: Array<File> | null;
  mediaList?: Array<File> | null;
  assignedToProducts?: Array<number> | null;
  assignedEvents?: Array<number> | null;
  instructors?: Array<number> | null;
  availabilityStartDateTime?: string | null;
  availabilityEndDateTime?: string | null;
  locations?: Array<number> | null;
  assignedAddons?: Array<number> | null;
  isPublished?:boolean;
}

export interface IAddProduct {
  id?: number;

  name?: string;
  description?: string | null;
  category?: ProductCategory;
  categoryId?: number;
  currencyId?: number;
  taxId?: number;
  minQuantityPerOrder?: number;
  maximumQuantityPerOrder?: number;
  totalQuantity?: number;
  price?: Price;
  eventIds?: Array<number> | null;
  images?: Array<File> | null;
}

export enum ProductType {
  session = 3,
  ticket = 1,
  item = 0,
  addon_on = 5,
  course = 4,
  membership = 2
}

export interface SessionProduct extends Product {
  startsAfter: Ticket;
  startDateTime: string;
  endDateTime: string;
  locations?: Array<number> | null;
  speakers?: Array<number> | null;
  eventId?: number | null;
}

export interface Ticket extends Product {
  startsAfter: Ticket;
  startDateTime: string;
  endDateTime: string;
  eventId?: number | null;
  perDayLimit: number,
  daysNoLimit: number,
}

export interface Price {
  discount?: number;
  isDiscountPercentage?: boolean;
  taxId?: number;
  tax?: any;
  taxes?: Array<any>;
  displayPrice?: string;
  displayTotalPrice?: string;
  amount: number;
  taxesIds: Array<number>;
  totalPrice?: number;
}

export interface ProductCategory {
  id: number;
  index?: number | null;
  name: string;
  pcTypeId: number;
  pcType: string;
  type: number;
  changeable: boolean;
  isOnHomePage: boolean;
}

export interface GetTicketsRequest extends PaginatedRequest {
  eventId: number;
}

export const getTickets = async (
  request: GetTicketsRequest
): Promise<PaginatedResult<Ticket>> => {
  const { eventId, ...data } = request;
  return (
    await Axios.get(`/api/Events/${eventId}/Products/Tickets`, {
      params: data,
    })
  ).data;
};

export const getProductById = async (id: number): Promise<any> => {
  return (
    await Axios.get(`/api/Products/${id}`)
  )
}
export const deleteProductById = async (id: number) => {
  return (await Axios.delete(`/api/Products/${id}`)).data;
};

interface AddEditTicketBase {
  name: string;
  description: string | null;
  categoryId: number;

  totalQuantity: number;
  minQuantityPerOrder: number;
  maximumQuantityPerOrder: number;

  price: Price;

  startsAfterId: number | null;
  startDateTime: string;
  endDateTime: string;
}

export interface AddTicketRequest extends AddEditTicketBase {
  eventId: number;
  image?: any | null;
}

export const addTicket = async (
  request: Ticket | AddTicketRequest | any
): Promise<NewResourceResult<number>> => {
  const { ...data } = request;
  data.startDateTime = request.startDateTime.toISOString();
  data.endDateTime = request.endDateTime.toISOString();
  const formData = objectToFormData(data);

  if (data.images) {
    (data as Product)?.images?.forEach((image) => {
      formData.append("images", image);
    });
  }

  const response = await Axios.post(`/api/ProductTickets`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

export interface EditTicketRequest extends AddEditTicketBase {
  id: number;
}

export const editTicket = async (request: EditTicketRequest) => {
  return (await Axios.put(`/api/ProductTickets/${request.id}`, request))
    .data;
};

export const getPlans = async (
  request: PaginatedRequest
): Promise<PaginatedResult<IAddEditMembershipPlanModel>> => {
  const result = await Axios.get("/api/ProductMemberships", {
    params: request,
  });
  return result.data;
};

export interface IAddEditMembershipPlanModel {
  id?: number;
  name: string;
  description?: string | null;
  availabilityStartDateTime?: moment.Moment | string;
  availabilityEndDateTime?: moment.Moment | string;
  durationsPlans: Array<IDurationPlan>;
  image?: File;
  sessionsIds: Array<number>;
  isPublished:boolean;
}

export interface IDurationPlan {
  id: number;
  durationInDays: number;
  price: Price;
  checkInLimits: number;
  index?: number | null;

  autoApplySmallCoupon: boolean;
  autoApplyBigCoupon: boolean;
  isActive: boolean;
}

export const addPlan = async (
  request: IAddEditMembershipPlanModel
): Promise<any> => {
  const result = await Axios.post("/api/ProductMemberships", request);
  return result.data;
};

export const deletePlan = async (id: number): Promise<any> => {
  const result = await Axios.delete(`/api/ProductMemberships/${id}`);
  return result.data;
};

export const editPlan = async (
  id: number,
  payload: IAddEditMembershipPlanModel
): Promise<any> => {
  const result = await Axios.put(`/api/ProductMemberships/${id}`, payload);
  return result.data;
};

export const uploadMembershipImage = async (
  id: number,
  image: File | null
): Promise<any> => {
  const result = await Axios.put(`/api/ProductMemberships/UploadImage/${id}`,
    { image: image },
    {
      headers: { "Content-Type": "multipart/form-data" },
    });
  return result.data;
};

export const getPlan = async (
  planId: number
): Promise<IAddEditMembershipPlanModel> => {
  const result = await Axios.get("/api/ProductMemberships/" + planId);
  return result.data;
};

export const GetProductsRegardlessEvent = async (
  request: PaginatedRequest
): Promise<PaginatedResult<Product>> => {
  const result = await Axios.get("api/Products/GetProductsRegardlessEvent", {
    params: request,
  });
  return result.data;
};

export const GetProductExportData = async (
  request:any
): Promise<string> => {
  const result = await Axios.get("/api/Products/Export", {
    params: request,
  });
  return result.data;
};

export const getSessionsByMembership = async (
  planId: number
): Promise<PaginatedResult<any>> => {
  const result = await Axios.get("/api/ProductMemberships/SessionsByMembership/?EntityId=" + planId);
  return result.data;
};

// retreive product as dropdown list.

export interface IProductDropdownItem {
  id: number;
  name: string;
}

export const GetProductDropdownList = async (): Promise<Array<IProductDropdownItem>> => {
  const response = await Axios.get<Array<IProductDropdownItem>>(
    "api/Products/GetDropdownList"
  );
  return response.data;
};

export const GetProductByCategory = async (
  categoryId: number
): Promise<Array<any>> => {

  const response = await Axios.get<Array<IProductDropdownItem>>(
    `api/Products/SearchForOrder`, { params: { categoryId } }
  );
  return response.data;
};

export const GetProductDropdownListByCategories = async (
  categoryId: number
): Promise<Array<any>> => {

  const response = await Axios.get<Array<IProductDropdownItem>>(
    `api/Home/ProductsByCategory/${categoryId}`
  );
  return response.data;
};

// add product session endpoint.

export const AddSessionProduct = async (
  payload: SessionProduct
): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  data.images?.forEach((image) => {
    formData.append("images", image);
    formData.append("mediaList", image);
  });
  const response = await Axios.post(
    "api/ProductSessions",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

// ticket product
export interface ProductTicket {
  name: string;
  description: string | null;
  categoryId: number;
  eventId?: number | null;
  minQTYPerOrder: number | null;
  maxQTYPerOrder: number | null;
  totalQTY: number | null;
  availabilityStartDateTime?: string | null;
  availabilityEndDateTime?: string | null;
  perDayLimit: number;
  daysNoLimit: number;
  price: {
    amount: number;
    discount?: number | null;
    isDiscountPercentage?: boolean;
    taxesIds?: number[] | null;
  };
  startDateTime?: string;
  endDateTime?: string;
  locationId: number;
  isPublished?:boolean;
}

export const AddJsonTicketProduct = async (payload: ProductTicket): Promise<any> => {
  const response = await Axios.post(
    "api/ProductTickets",
    payload,
  );
  return response.data;
};

export const UpdateJsonTicketProduct = async (productId: number, payload: ProductTicket): Promise<any> => {
  const response = await Axios.put(
    `api/ProductTickets/${productId}`,
    payload,
  );
  return response.data;
};

// addon product
export interface ProductAddon {
  name: string;
  description: string | null;
  categoryId: number;
  eventId?: number | null;
  minQTYPerOrder: number | null;
  maxQTYPerOrder: number | null;
  totalQTY: number | null;
  availabilityStartDateTime?: string | null;
  availabilityEndDateTime?: string | null;
  price: {
    amount: number;
    discount?: number | null;
    isDiscountPercentage?: boolean;
    taxesIds?: number[] | null;
  };
  assignedToProducts: number[];
  isPublished?:boolean;
}

export const AddJsonAddonProduct = async (payload: ProductAddon): Promise<any> => {
  const response = await Axios.post(
    "api/ProductAddons",
    payload,
  );
  return response.data;
};
export const UpdateJsonAddonProduct = async (productId: number, payload: ProductAddon): Promise<any> => {
  const response = await Axios.put(
    `api/ProductAddons/${productId}`,
    payload,
  );
  return response.data;
};

// session product
export interface ProductSession {
  name: string;
  description: string | null;
  categoryId: number;
  eventId?: number | null;
  minQTYPerOrder: number;
  maxQTYPerOrder: number;
  totalQTY: number;
  availabilityStartDateTime?: string | null;
  availabilityEndDateTime?: string | null;
  price: {
    amount: number;
    discount?: number | null;
    isDiscountPercentage?: boolean;
    taxesIds?: number[] | null;
  };
  locations: number[];
  speakers: number[];
  assignedAddons: number[];
  eventIds?:number[];
  isPublished?:boolean;
}

export const AddJsonSessionProduct = async (payload: ProductSession): Promise<any> => {
  const response = await Axios.post(
    "api/ProductSessions",
    payload,
  );
  return response.data;
};
export const UpdateJsonSessionProduct = async (productId: number, payload: ProductSession): Promise<any> => {
  const response = await Axios.put(
    `api/ProductSessions/${productId}`,
    payload,
  );
  return response.data;
};


// add item product

export interface ProductItem {
  name: string;
  nameAr?: string | null;
  description: string | null;
  descriptionAr?: string | null;
  categoryId: number;
  minQTYPerOrder: number | null;
  maxQTYPerOrder: number | null;
  totalQTY: number | null;
  availabilityStartDateTime?: string | null;
  availabilityEndDateTime?: string | null;
  price: {
    amount: number;
    discount?: number | null;
    isDiscountPercentage?: boolean;
    taxesIds?: number[] | null;
  };
  assignedEvents?: number[] | null;
  assignedAddons?: number[] | null;
  isPublished?:boolean;
}

export const AddJsonItemProduct = async (payload: ProductItem): Promise<any> => {
  const response = await Axios.post(
    "api/ProductItems",
    payload,
  );
  return response.data;
};

export const UpdateJsonItemProduct = async (productId: number, payload: ProductItem): Promise<any> => {
  const response = await Axios.put(
    `api/ProductItems/${productId}`,
    payload,
  );
  return response.data;
};

export const AddItemProduct = async (payload: Product): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  data.images?.forEach((image) => {
    formData.append("images", image);
    formData.append("mediaList", image);
  });
  const response = await Axios.post(
    "api/ProductItems",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

// Upload Media
export const UploadProductMedia = async (productId: number, mediaList: any[],existingProduct?:any[]): Promise<any> => {


  const data = mediaList.map((value, index) => {
    if (value.id)
      return {
        mediaId: value.id,
        index
      }
    return {
      index,
      fileName: value.file.name,
      fileBase64: value.base64
    }
  })
  if(existingProduct){
    const deletedProducts =  existingProduct.filter(value => !data.some(value1 => value1.mediaId === value.id))
    for (const value of deletedProducts) {
      await Axios.delete(
        `api/Products/${productId}/file/${value.id}`
      );
    }
  }
  await Axios.put(
    `api/Products/${productId}/ReorderMedia`,
    data.filter(value => value.mediaId)
  );
  const responseUpload = await Axios.put(
    `api/Products/${productId}/UploadMedia`,
    data.filter(value => !value.mediaId)
  );


  return [...responseUpload.data];
};



// product images

export interface IProductImage {
  productImageId: number;
  mediaDto: MediaFile;
}


export const GetProductMedia = async (
  productId: number
): Promise<IProductImage[]> => {
  const response = await Axios.get(`api/Products/${productId}/Media`);
  return response.data;
};

export const GetProductImages = async (
  productId: number
): Promise<IProductImage[]> => {
  const response = await Axios.get(`api/Products/${productId}/Images`);
  return response.data;
};

export const DeleteProductImage = async (
  productId: number,
  imageId: number
): Promise<any> => {
  const response = await Axios.delete(
    `api/Products/${productId}/file/${imageId}`
  );
  return response.data;
}

export interface ISearchItem {
  id: number,
  category: {
    id: number,
    name: string,
    pcType: {
      id: number,
      name: string
    }
  },
  name: string,
  description: string,
  media: [
    {
      fileName: string,
      contentType: string,
      fullUrl: string,
      sizeInBytes: number
    }
  ]
}

export const SearchItemsForAddons = async (
  text: string
): Promise<ISearchItem[]> => {
  const response = await Axios.get('api/ProductAddons/SearchItems?text=' + text);
  return response.data;
}

export const AddAddonProduct = async (payload: Product): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  data.images?.forEach((image) => {
    formData.append("images", image);
    formData.append("mediaList", image);
  });
  const response = await Axios.post(
    "api/ProductAddons",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};


export const editProduct = async (productId: number, url: string, payload: any): Promise<any> => {
  const { ...data } = payload;
  const formData = objectToFormData(data);
  data.images?.forEach((image: string | Blob) => {
    formData.append("images", image);
    formData.append("mediaList", image);
  });

  const response = await Axios.put(
    `api/${url}/${productId}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;

}


export const deleteProduct = async (productId: number, url: string): Promise<any> => {

  const response = await Axios.delete(
    `api/${url}/${productId}`
  );
  return response.data;

}


export const fetchProduct = async (productId: number, url: string): Promise<any> => {

  const response = await Axios.get(
    `api/${url}/${productId}`
  );
  return response.data;

}
export const fetchAddonProductsBy = async (productId: number): Promise<PaginatedResult<any>> => {

  const response = await Axios.get(
    `api/ProductAddons/AddonProductsBy/${productId}`
  );
  return response.data;

}

export const fetchProductItemEventsBy = async (productId: number): Promise<PaginatedResult<any>> => {

  const response = await Axios.get(
    `api/ProductItems/ItemEventsBy/${productId}`
  );
  return response.data;

}

export const fetchProductItemAddonsBy = async (productId: number, url?: string): Promise<PaginatedResult<any>> => {

  const response = await Axios.get(
    `api/${url ?? 'ProductItems/ItemAddonsBy'}/${productId}`
  );
  return response.data;

}

export const SearchSessions = async (
  text: string | null
): Promise<ISearchItem[]> => {
  const response = await Axios.get('api/ProductSessions/Search?text=' + text);
  return response.data;
}

export const fetchAddonsList = async (text?:string): Promise<IProductDropdownItem[]> => {
  const response = await Axios.get<IProductDropdownItem[]>(
    "api/Products/SearchAddons",{params:{text}}
  );
  return response.data;
};

export const fetchProductMedia = async (productId: number): Promise<any> => {

  const response = await Axios.get(
    `/api/Products/${productId}/Media`
  );
  return response.data;

}