import Axios from "axios";
import intlTelInput from "intl-tel-input";
import * as React from "react";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef
} from "react";
import { Input, InputProps } from "reactstrap";

import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";
import "./PhoneInput.scss";

export interface PhoneInputProps extends InputProps {
    initialCountry?: string;
    preferredCountries?: string[];
    value?: string;
    onPhoneNumberUpdated?: (formattedPhoneNumber: string | null) => any;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({   initialCountry,
                                                          preferredCountries,
                                                          value,
                                                          onPhoneNumberUpdated}) => {
    const inputDom = useRef<HTMLInputElement | null>(null);

    const intlInputObj = useMemo(() => {
        // apply phone input
        if (!inputDom.current) return null;

        return intlTelInput(inputDom.current, {
            initialCountry: initialCountry,
            preferredCountries: preferredCountries,
            customPlaceholder: (
                selectedCountryPlaceholder,
                selectedCountryData
            ) => {
                return "e.g. " + selectedCountryPlaceholder;
            },
            geoIpLookup: callback => {
                Axios.create()
                    .get("https://ipinfo.io")
                    .then(resp => resp)
                    .catch(resp => resp)
                    .then(resp =>
                        callback(
                            resp.data && resp.data.country
                                ? resp.data.country
                                : "SA"
                        )
                    );
            }
        });
    }, [inputDom.current]);

    const handleInputChange = useCallback(() => {
        if (!intlInputObj) return;

        let number: string | null = intlInputObj && intlInputObj?.getNumber();

        if (number?.trim() === "") number = null;

        onPhoneNumberUpdated && onPhoneNumberUpdated(number);
    }, [onPhoneNumberUpdated, intlInputObj]);

    useEffect(() => {
        inputDom.current?.addEventListener("countrychange", handleInputChange);

        return () =>
            inputDom.current?.removeEventListener(
                "countrychange",
                handleInputChange
            );
    }, [inputDom.current, handleInputChange]);

    useLayoutEffect(() => {
        intlInputObj?.setNumber((value as string | null) ?? "");
    }, [value, intlInputObj]);
    return (
        <>
            <Input
                innerRef={inputDom}
                type="tel"
                onChange={handleInputChange}
                value={undefined}
            />
        </>
    );
};

PhoneInput.defaultProps = {
    initialCountry: "auto",
    preferredCountries: ["SA"]
};
