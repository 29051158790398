import { ContentState, Editor, EditorState, RichUtils, convertFromHTML, convertToRaw } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import 'draft-js/dist/Draft.css';
import Immutable from "immutable";
import * as React from "react";
import { AiOutlineBold, AiOutlineItalic, AiOutlineStrikethrough, AiOutlineUnderline } from "react-icons/ai";
import './RichTextEdtor.css';

// AiOutlineBold
export const RichTextEditor: React.FC<any> = (props: any) => {
    const blocksFromHTML = convertFromHTML(props.content ?? "");

    let _contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,);

    const raw = convertToRaw(_contentState);
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createWithContent(_contentState),
    );
    const _onBoldClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    }

    const _onItalicClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    }

    const _onActionClick = (action: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, action));
    };



    const blockRenderMap = Immutable.Map({
        'header-two': {
            element: 'h2'
        },
        'unstyled': {
            element: 'h2'
        }
    });


    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    const [contentState, setContentState] = React.useState(raw) // ContentState JSON

    return (
        <div>
            <button className="btn btn-outline-secondary" onClick={_onBoldClick}>
                <i> <AiOutlineBold /> </i>
            </button>
            <button className="btn btn-outline-secondary" onClick={_onItalicClick}>
                <i> <AiOutlineItalic /> </i>
            </button>
            <button className="btn btn-outline-secondary" onClick={() => _onActionClick("UNDERLINE")}>
                <i> <AiOutlineUnderline /> </i>
            </button>
            <button className="btn btn-outline-secondary" onClick={() => _onActionClick("STRIKETHROUGH")}>
                <i> <AiOutlineStrikethrough /> </i>
            </button>
            <br />
            <small>
                Press enter to write in new line
            </small>
            {/* <button onClick={() => _onActionClick("SUPERSCRIPT")}> Superscript </button>
            <button onClick={() => _onActionClick("SUBSCRIPT")}> subscript </button> */}
            <Editor
                blockStyleFn={(contentblock) => "superfancyEditor"}
                editorState={editorState}

                onChange={(x) => {
                    setEditorState(x);
                    if (props.onChange) {
                        props.onChange(stateToHTML(x.getCurrentContent()))
                        // props.onChange(  x.getCurrentContent().getPlainText('\u0001'))
                    }
                }} />
        </div>
    );
}