/* eslint-disable react/no-deprecated */
import { Loader } from 'components/Loader';
import * as React from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  GetMembershipCard
} from "../../api/MembershipAreaApi";


interface OwnProps {
  close: () => void;
  isOpen: boolean;
  tenantUserId: number;
}

type Props = OwnProps;


export class MembershipCardReceiptComponent extends React.Component<Props, any>   {


  componentToPrintRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      html: null
    };
    this.componentToPrintRef = React.createRef();
  }

  componentWillMount() {
    this.fetchTenantUserMembershipCard(this.props.tenantUserId);
  }

  fetchTenantUserMembershipCard = (id: number) => {
    GetMembershipCard(id).then(data => {
      this.setState({ html: data.html, title: "Membership Card" });
    });
  };


  render() {
    return (<Modal
      unmountOnClose
      isOpen={this.props.isOpen}
      toggle={this.props.close}
    >
      <ModalHeader toggle={this.props.close}>Membership Card</ModalHeader>
      <ModalBody >
        {this.state.loading ? <Loader isLoading={true} /> : null}
        {
          this.state.html ?
            <>
              <div className="text-center" ref={this.componentToPrintRef}>
                <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
              </div>


              <div className="row footer-icons text-center">

                <div className="col-sm-6">

                  <ReactToPrint
                    trigger={() => {

                      return (<button>
                        <i> <AiOutlinePrinter /> </i>
                        <span> Print </span>
                      </button>);
                    }}
                    content={() => this.componentToPrintRef.current}
                  />
                </div>

              </div>

            </> : null
        }
      </ModalBody>
    </Modal>);
  }


}