/* eslint-disable react/jsx-key */
import { CrumbsContext } from "Contexts/CrumbsContext";
import { EventIdUrlContext } from "Contexts/EventIdUrlContext";
import { EventUrlContext } from "Contexts/EventUrlContext";
import * as React from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {Button, Container} from "reactstrap";
import { getEventUrl } from "../../../src/api/EventPageApi";
import "./SubNav.css";
import {Breadcrumbs} from "../Breadcrumbs/Breadcrumbs";


export const SubNav: React.FC = () => {
    const navigate = useNavigate();
    const [eventId, setEventId] = React.useState<number | null>(null);
    const [eventUrl, setEventUrl] = React.useState<string>("")

    const fetchEventUrl = (eventId: number | null) => {
        if (eventId) {
            getEventUrl(eventId).then(r => {
                if (r) {
                    setEventUrl("https://client.fahadhummadi.me/" + r.eventUrl);
                }
            }).catch(error=>{
                console.error("getEventUrl error", error)
            })

        }
    }

    return (
        <div className="sub-nav">
            <Container fluid>
                <div className="d-flex justify-content-between">
                    <div>
                        <Breadcrumbs/>
                    </div>

                    <EventIdUrlContext.Consumer>
                        {
                            ((value: number | null) => {
                                if (eventId == null) {
                                    fetchEventUrl(value);
                                    setEventId(value);
                                    return null;
                                }
                            })
                        }
                    </EventIdUrlContext.Consumer>

                    <EventUrlContext.Consumer>
                        {
                            (value => value ? <div>
                                <div className={"container"} style={{ float: 'right' }}>

                                    <div>
                                        <Button
                                            color="green"
                                            onClick={e => {
                                                e.preventDefault();
                                                window.open(eventUrl, '_blank');
                                            }}
                                        >
                                            <span style={{ float: 'left', margin: '0 3px' }}> Preview  </span> <span style={{ float: 'right', margin: '-2px 0px' }}><FaEye /></span>
                                        </Button>

                                        <Button color="link" onClick={()=>navigate(`/Events/${eventId}/appearance/eventPage`)}>
                                            <span style={{ float: 'left', margin: '0 3px' }}> Appearance  </span> <span style={{ float: 'right', margin: '-2px 0px' }}><FaEdit /></span>
                                        </Button>

                                    </div>

                                    {/*<Button*/}
                                    {/*    onClick={e => {*/}
                                    {/*        e.preventDefault();*/}
                                    {/*        window.open(eventUrl, '_blank');*/}

                                    {/*    }}*/}
                                    {/*    className={'mx-1'}*/}
                                    {/*    color={'success'} size={'sm'}>*/}
                                    {/*    <span style={{ float: 'left', margin: '0 3px' }}> Preview  </span> <span style={{ float: 'right', margin: '-2px 0px' }}><FaEye /></span>*/}

                                    {/*</Button>*/}
                                    {/*<Link to={`/Events/${eventId}/appearance/eventPage`} >*/}
                                    {/*    <Button*/}
                                    {/*        className={'mx-1'}*/}
                                    {/*        color={'success'} size={'sm'}>*/}
                                    {/*        <span style={{ float: 'left', margin: '0 3px' }}> Appearance  </span> <span style={{ float: 'right', margin: '-2px 0px' }}><FaEdit /></span>*/}
                                    {/*    </Button>*/}
                                    {/*</Link>*/}
                                </div>
                            </div> : null)
                        }
                    </EventUrlContext.Consumer>

                </div>


            </Container>
        </div>
    );
};

