import Axios from "axios";
import { Moment } from "moment";
import { PaginatedResult } from "./common/PaginatedResult";

export interface IMembershipDetailsDto {
  fullName: string;
  membershipNumber: string;
  checkInLimits: number;
  joinData: Moment;
  expData: Moment;
  tenantUserId: number;
}

export const GetMembershipDetails = async (
  membershipNumber: string
): Promise<IMembershipDetailsDto> => {
  const result = Axios.get<IMembershipDetailsDto>(
    `api/SmartAccess/GetMembershipDetails?membershipNumber=${membershipNumber}`
  );
  return (await result).data;
};

// Invoices

export interface IInvoiceItemUsingMembershipNumberDto {
  id: number;
  invoiceNumber: string;
  creatingDate: Moment;
}

export const GetInvoicesList = async (
  membershipNumber: string
): Promise<PaginatedResult<IInvoiceItemUsingMembershipNumberDto>> => {
  const result = await Axios.get<
    PaginatedResult<IInvoiceItemUsingMembershipNumberDto>
  >(`api/SmartAccess/GetInvoices?membershipNumber=${membershipNumber}`);
  return result.data;
};

export const AddSession = async (membershipNumber: string): Promise<any> => {
  const result = await Axios.post(
    `api/SmartAccess/PostSession?membershipNumber=${membershipNumber}`
  );
  return result.data;
};

export interface ISessionItemUsingMembershipNumberDto {
  id: number;
  sessionNumber: string;
  creatingDate: Moment;
}

export const GetSessions = async (
  membershipNumber: string
): Promise<PaginatedResult<ISessionItemUsingMembershipNumberDto>> => {
  const result = await Axios.get(
    `api/SmartAccess/GetSessions?membershipNumber=${membershipNumber}`
  );
  return result.data;
};

export interface ISessionReceipt {
  membershipNumber: string;
  fullName: string;
  creatingDate: Moment;
  barCode: string;
}

export const GetSessionReceipt = async (
  sessionId: number
): Promise<ISessionReceipt> => {
  const result = await Axios.get(
    `api/SmartAccess/${sessionId}/GetSessionReceipt`
  );
  return result.data;
};

// get session receipt as html view

export interface IHtmlView {
  html: string;
}

export const GetSessionReceiptHtmlView = async (
  sessionId: number
): Promise<IHtmlView> => {
  const response = await Axios.get(
    `api/SmartAccess/${sessionId}/GetSessionReceiptHtmlView`
  );
  return response.data;
};

// working with session notes;

export interface IAddSessionNotes {
  sessionId: number;
  notes: string;
}

export const AddSessionNotes = async (body: IAddSessionNotes): Promise<any> => {
  const result = await Axios.post("api/SmartAccess/AddSessionNotes", body);
  return result.data;
};

export interface ISessionNotes {
  notes: string;
}

export const GetSessionNotes = async (
  sessionId: number
): Promise<ISessionNotes> => {
  const result = await Axios.get(
    `api/SmartAccess/GetSessionNotes/${sessionId}`
  );
  return result.data;
};

// working with session

export const CreateTicket = async (membershipNumber: string): Promise<any> => {
  const result = await Axios.post(
    `api/SmartAccess/${membershipNumber}/CreateTicket`
  );
  return result.data;
};

export interface ITicketItem {
  ticketId: number;
  ticketNumber: string;
  creatingDate: Moment;
}

export const GetTickets = async (
  membershipNumber: string
): Promise<PaginatedResult<ITicketItem>> => {
  const result = await Axios.get<PaginatedResult<ITicketItem>>(
    `api/SmartAccess/${membershipNumber}/GetTickets`
  );
  return result.data;
};

export interface ITicketRecept {
  fullName: string;
  qrCodeHtml: string;
  ticketNumber: string;
}

export const GetTicket = async (ticketId: number): Promise<ITicketRecept> => {
  const result = await Axios.get(`api/SmartAccess/${ticketId}/GetTicket`);
  return result.data;
};

export const GetTicketHtmlView = async (
  ticketId: number
): Promise<IHtmlView> => {
  const response = await Axios.get(
    `api/SmartAccess/${ticketId}/GetTicketHtmlView`
  );
  return response.data;
};

// update checkedin ticket;
export const UpdateCheckedIn = async (ticketId: number): Promise<any> => {
  const result = await Axios.put(
    `api/SmartAccess/${ticketId}/UpdateCheckedIn`
  );
  return result.data;
};

// event Badge
export interface IEventBadge {
  fullName: string;
  membershipNumber: string;
  qrCodeHtml: string;
}

export const GetEventBadgeReceipt = async (
  membershipNumber: string
): Promise<any> => {
  const result = await Axios.get(
    `api/SmartAccess/GetEventBadgeReceipt?membershipNumber=${membershipNumber}`
  );
  return result.data;
};

export const GetEventBadgeReceiptHtml = async (
  membershipNumber: string
): Promise<IHtmlView> => {
  const result = await Axios.get(
    `api/SmartAccess/GetEventBadgeReceiptHtml?membershipNumber=${membershipNumber}`
  );
  return result.data;
};
