import { ContactInfo } from "api/common/ContactInfo";
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import {
    FaEnvelope,
    FaFacebook,
    FaGlobe,
    FaLinkedin,
    FaPhone,
    FaTwitter
} from "react-icons/fa";
import { CellProps } from "react-table";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";

interface ContactObject {
    contact: ContactInfo;
}

export const TableContactDetailRenderer: React.FC<CellProps<
    ContactObject
>> = props => {
    const contactDetails = props.row.original?.contact;
    if (!contactDetails) return null;

    const emailId = uniqueId("contact_email");
    const phoneId = uniqueId("contact_phone");
    const facebookId = uniqueId("contact_facebook");
    const linkedInId = uniqueId("contact_linkedin");
    const twitterId = uniqueId("contact_twitter");
    const websiteId = uniqueId("contact_website");
    return (
        <ButtonGroup>
            {contactDetails.email && (
                <Button
                    color={"outline-secondary"}
                    href={"mailto:" + contactDetails.email} id={emailId}>
                    <FaEnvelope />
                    <UncontrolledTooltip target={emailId}>
                        {contactDetails.email}
                    </UncontrolledTooltip>
                </Button>
            )}

            {contactDetails.phoneNumber && (
                <Button
                    color={"outline-secondary"}
                    href={"tel:" + contactDetails.phoneNumber} id={phoneId}>
                    <FaPhone />
                    <UncontrolledTooltip target={phoneId}>
                        {contactDetails.phoneNumber}
                    </UncontrolledTooltip>
                </Button>
            )}

            {contactDetails.facebook && (
                <Button
                    color={"outline-secondary"}
                    href={contactDetails.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={facebookId}
                >
                    <FaFacebook />
                    <UncontrolledTooltip target={facebookId}>
                        {contactDetails.facebook}
                    </UncontrolledTooltip>
                </Button>
            )}

            {contactDetails.linkedIn && (
                <Button
                    color={"outline-secondary"}
                    href={contactDetails.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={linkedInId}
                >
                    <FaLinkedin />
                    <UncontrolledTooltip target={linkedInId}>
                        {contactDetails.linkedIn}
                    </UncontrolledTooltip>
                </Button>
            )}

            {contactDetails.twitter && (
                <Button
                    color={"outline-secondary"}
                    href={contactDetails.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={twitterId}
                >
                    <FaTwitter />
                    <UncontrolledTooltip target={twitterId}>
                        {contactDetails.twitter}
                    </UncontrolledTooltip>
                </Button>
            )}

            {contactDetails.website && (
                <Button
                    color={"outline-secondary"}
                    href={contactDetails.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={websiteId}
                >
                    <FaGlobe />
                    <UncontrolledTooltip target={websiteId}>
                        {contactDetails.website}
                    </UncontrolledTooltip>
                </Button>
            )}
        </ButtonGroup>
    );
};
