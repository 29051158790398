import Axios from "axios";
import {PaginatedRequestWithFilter} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface InvoiceDto {
  transactionId: number;
  invoiceNumber: string;
  finalPrice: number;
  fullName: string;
  phoneNumber?: string;
  status: string;
  date: string;
}

export const getAllFakeInvoices = async (
    request: PaginatedRequestWithFilter
): Promise<PaginatedResult<any>> => {
  var result = await Axios.get<PaginatedResult<any>>(
      `api/Invoices/AdminList`,
      { params: request }
  );
  return result.data;
};

export const getAllInvoices = async (
  request: PaginatedRequestWithFilter
): Promise<PaginatedResult<InvoiceDto>> => {
  var result = await Axios.get<PaginatedResult<InvoiceDto>>(
    `api/Invoices/GetAllInvoice`,
    { params: request }
  );
  return result.data;
};

//get invoice template

export interface InvoiceTemplateDto {
  template: string;
}

export const getInvoiceTemplate = async (
  transactionId: number
): Promise<InvoiceTemplateDto> => {
  const result = await Axios.get<InvoiceTemplateDto>(
    `api/Invoices/${transactionId}/GetInvoiceTemplate`
  );
  return result.data;
};

export interface IAddDyanmicInvoice {}

// calculate invoice

export interface ICalculateInvoiceParam {
  productIds: Array<number>;
  couponCode?: string | null;
  currencyId?: number | null;
}

export interface IcalculateInvoiceResult {
  productPricesAmount: string;
  displayTotalPrice: string;
  priceWithTax: string;
  priceAfterCouponDiscount: string;
  currencyCode: string;
}

export const CalculateInvocie = async (
  param: ICalculateInvoiceParam | any
): Promise<IcalculateInvoiceResult> => {
  let querStr: string | null = null;
  Object.keys(param).forEach((key: string | any) => {
    if (param[key]) {
      if (key === "productIds") {
        param["productIds"].forEach((id: number) => {
          if (!querStr) querStr = `?`;
          else querStr += `&`;
          querStr += `productIds=${id}`;
        });
      } else {
        if (!querStr) querStr = `?`;
        else querStr += `&`;
        querStr += `${key}=${param[key].toString()}`;
      }
    }
  });
  const response = await Axios.get<IcalculateInvoiceResult>(
    `api/Invoices/CalculateInvoice${querStr}`
  );
  return response.data;
};

export interface INewUserDynamicInvoice {
  firstName: string;
  middleName?: string | null;
  lastName: string;
  email: string;
  phoneNumber: string;
}
export interface IAddDynamicInvoice {
  productIds: Array<number>;
  currencyId?: number | null;
  couponCode?: string | null;
  tenantUserId?: number | null;
  newUserObj?: INewUserDynamicInvoice | null;
}

export const AddDynamicInvoiceFC = async (
  payload: IAddDynamicInvoice
): Promise<any> => {
  const response = await Axios.post(
    "api/Invoices/AddDynamicInvoice",
    payload
  );
  return response.data;
};
