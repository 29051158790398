import logo from "assets/imgs/logo-w-l.svg";
import * as React from "react";
import { useEffect } from "react";
import {
  FaArrowLeft,
  FaBell,
  FaCalendarAlt,
  FaDollarSign,
  FaEdit,
  FaFile,
  FaFileAlt,
  FaGlobe,
  FaLink,
  FaMicrophone,
  FaMobile,
  FaShoppingCart,
  FaTachometerAlt,
  FaTicketAlt,
  FaUsers,
} from "react-icons/fa";
import { FiGrid } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Container, Navbar, NavbarBrand, NavbarToggler, } from "reactstrap";
import { SideNavContext } from "../Contexts/SideNavContext";
import { getEventName } from "../api/EventPageApi";
import {RootState, useThunkDispatch} from "../store";
import SideNav, { DropDownSideNavMenu, GroupSeparator, SideNavItem, SideNavItemsContainer, } from "./SideNav";
import {loadEventDetails} from "../store/eventDetailsSlice";

//FiGrid

export const EventsSideNav: React.FC<any> = (props: any) => {
  const params = useParams<{ eventId: string }>();
  const eventId = Number(params.eventId)
  const dispatch = useThunkDispatch();

  const [eventTitle, setEventTitle] = React.useState<string | null>(null);

  const eventInfoState = useSelector((state: RootState) => state.eventDetails);
  const activeEvent =
    eventInfoState.eventId === eventId ? eventInfoState : null;

  const getEventNameHandler = async () => {
    return await getEventName(eventId);
  }

  getEventName(eventId).then(r => props.sendEventName?.(r));

  useEffect(
    function loadActiveEventEmpty() {
      if (eventId) {
        dispatch(loadEventDetails(eventId));
      }
    },
    [activeEvent,eventInfoState]
  );

  if (props.sendEventId) {

    props.sendEventId(eventId);
  }




  return (

    <SideNavContext.Consumer>
      {({ isOpen, toggle }) => (
        <SideNav className="flex-shrink-0" isMobileOpen={isOpen}>
          <Navbar className="border-bottom border-dark text-white" dark>
            <Container fluid className="navbar-expand-sm ">
              <NavbarBrand>
                <NavbarBrand>
                  <img src={logo} alt="Perfect Touch's logo" />
                </NavbarBrand>
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
            </Container>
          </Navbar>

          <div className="p-4">
            {/*<h5>{activeEvent?.result?.name}</h5>*/}
            <Link className="text-white" to={"/events"}>
              <FaArrowLeft /> Manage another event
            </Link>
          </div>
          <SideNavItemsContainer>
            <div className="border-bottom border-dark" />

            <SideNavItem
              to={`/events/${eventId}`}
              icon={<FaTachometerAlt />}
            >
              DashBoard
            </SideNavItem>

            {/*<GroupSeparator>Event data</GroupSeparator>*/}
            <SideNavItem
              exact
              to={`/events/${eventId}/Details`}
              icon={<FaEdit />}
            >
              Details
            </SideNavItem>

            <SideNavItem
              exact
              to={`/events/${eventId}/Speakers`}
              icon={<FaMicrophone />}
            >
              Speakers
            </SideNavItem>

            <SideNavItem
              exact
              to={`/events/${eventId}/Schedule`}
              icon={<FaCalendarAlt />}
            >
              Schedule
            </SideNavItem>



            <SideNavItem
              exact
              to={`/events/${eventId}/SponsorsGroups`}
              icon={<FaDollarSign />}
            >
              Sponsors Groups
            </SideNavItem>

            {/*
            <DropDownSideNavMenu
              to={`/events/${eventId}/Committees`}
              icon={<FaUsers />}
              title={"Committees"}
            >
              <SideNavItem
                exact
                to={`/events/${eventId}/Committees/CommitteeGroups`}
              >
                Committee Groups
              </SideNavItem>

              <SideNavItem exact to={`/events/${eventId}/Committees`}>
                Committees
              </SideNavItem>
            </DropDownSideNavMenu>

            <DropDownSideNavMenu
              to={`/events/${eventId}/Abstracts`}
              icon={<FaFile />}
              title={"Abstracts"}
            >
              <SideNavItem exact to={`/events/${eventId}/Abstracts`}>
                Abstracts
              </SideNavItem>
              <SideNavItem
                exact
                to={`/events/${eventId}/Presenters`}
              // icon={<FaMicrophone />}
              >
                Presenters
              </SideNavItem>

              <SideNavItem
                exact
                to={`/events/${eventId}/Abstracts/AbstractTypes`}
              >
                Abstract types
              </SideNavItem>
            </DropDownSideNavMenu>

            <DropDownSideNavMenu
              to={`/events/${eventId}/Recepients`}
              icon={<FaUsers />}
              title={"Recipients"}
            >
              <SideNavItem
                exact
                to={`/events/${eventId}/Recepients/RecepientGroups`}
              >
                Recipient Groups
              </SideNavItem>

              <SideNavItem exact to={`/events/${eventId}/Recepients`}>
                Recipients
              </SideNavItem>
            </DropDownSideNavMenu>

            <GroupSeparator>Registration</GroupSeparator>
            <SideNavItem
              exact
              to={`/events/${eventId}/Registration/RegistrationForm`}
              icon={<FaShoppingCart />}
            >
              Registration Form
            </SideNavItem>


            <SideNavItem
              exact
              to={`/events/${eventId}/Registration/Categories`}
              icon={<FiGrid />}
            >
              Categories
            </SideNavItem>

            <SideNavItem
              exact
              to={`/events/${eventId}/Registration/Products`}
              icon={<FaTicketAlt />}
            >
              Tickets & Items
            </SideNavItem>

            <SideNavItem
              exact
              to={`/events/${eventId}/Registration/Orders`}
              icon={<FaUsers />}
            >
              Attendance
            </SideNavItem>

            <GroupSeparator>Notifications</GroupSeparator>

            <SideNavItem
              exact
              to={`/Events/${eventId}/Notifications`}
              icon={<FaBell />}
            >
              Brodcast a notification
            </SideNavItem>

            <SideNavItem
              exact
              to={`/Events/${eventId}/send-email`}
              icon={<FaBell />}
            >
              Send Email
            </SideNavItem>

            <SideNavItem
              exact
              to={`/Events/${eventId}/send-email/Certificates`}
              icon={<FaFileAlt />}
            >
              Send Certificate
            </SideNavItem>

            <GroupSeparator>Appearance</GroupSeparator>

            <SideNavItem
              exact
              to={`/Events/${eventId}/appearance/eventPage`}
              icon={<FaGlobe />}
            >
              Event page
            </SideNavItem>
            <SideNavItem
              exact
              to={`/Events/${eventId}/appearance/mobileApps`}
              icon={<FaMobile />}
            >
              Mobile apps
            </SideNavItem>

            <GroupSeparator>Settings</GroupSeparator>
            <DropDownSideNavMenu
              title={"Templates"}
              to={`/events/${eventId}/templates`}
              icon={<FaFileAlt />}
            >
              <SideNavItem
                exact
                to={`/events/${eventId}/templates/notifications`}
                icon={<FaBell />}
              >
                Notification templates
              </SideNavItem>
              <SideNavItem
                exact
                to={`/events/${eventId}/templates/certificates`}
                icon={<FaBell />}
              >
                Certificate templates
              </SideNavItem>
            </DropDownSideNavMenu>

            <SideNavItem
              exact
              to={`/events/${eventId}/domains`}
              icon={<FaLink />}
            >
              Domains
            </SideNavItem>
      */}
          </SideNavItemsContainer>
        </SideNav>
      )}
    </SideNavContext.Consumer>
  );
};
