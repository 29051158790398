/* eslint-disable react/no-deprecated */
import emptyStateSpeakerImage from "assets/imgs/meeting.svg";
import { EmptyListState } from 'components/EmptyListState';
import moment from "moment";
import React, { Component } from 'react';
import { MdPublic } from "react-icons/md";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { IMeeting, getMeetings } from "../../api/MeetingApi";
import { Loader } from "../../components/Loader";
import { Data_Format_EMS } from "../../types/date-format-type";
import CreateMeetingModal from "./CreateMeetingModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import {MainCard} from "../../components/MainCard";

class ManageMeetingComponent extends Component<any, any> {


    constructor(props: any) {
        super(props);
        this.state = {
            openCreateMeetingModal: false,
            pagedData: {
                pageIndex: 1,
                limit: 20
            },
            data: [],
            loading: false,
            openDeleteModel: false,
            deleteModal: null
        }
    }

    componentWillMount() {
        this.fetchMeeting();
    }

    createMeetingHandler = () => {
        this.setState({ openCreateMeetingModal: true })
    }

    closeCreateMeetingHandler = () => {
        this.setState({ openCreateMeetingModal: false })
    }

    fetchMeeting = () => {
        this.setState({ loading: true })
        getMeetings(this.state.pagedData).then(data => {
            this.setState({ loading: false })
            if (data) {
                this.setState({ data: data.items });
            }
        }).catch(error => {
            this.setState({ loading: false })
        })
    }

    /* delete meeting handler
    *  open delete modal.   
    *  */

    openDeleteMeetingModal = (deleteModal: any) => {
        if (!deleteModal) return;
        this.setState({ openDeleteModel: true, deleteModal })
    }

    cancelDeletingHandler = () => {
        this.setState({ openDeleteModel: false, deleteModal: null })
    }

    render() {
        return (
            <div>

                {
                    this.state.openDeleteModel ?
                        <DeleteMeetingModal cancelDeleting={this.cancelDeletingHandler}
                            onUpdated={this.fetchMeeting}
                            meetingToDelete={this.state.deleteModal} />
                        : null
                }

                {
                    this.state.openCreateMeetingModal ?
                        <CreateMeetingModal isOpen={this.state.openCreateMeetingModal}
                            onUpdated={this.fetchMeeting}
                            close={this.closeCreateMeetingHandler} />
                        : null
                }

                <MainCard>
                    <CardHeader className={"space-between"}>
                        Manage Meetings
                            <button
                                onClick={this.createMeetingHandler}
                                className={"btn btn-primary"}>
                                Create Meeting {" "}
                                <span>
                                    <FaPlus />
                                </span>
                            </button>
                    </CardHeader>
                    <CardBody>
                        {this.state.loading ? (
                            <Loader isLoading={true} />) : null}

                        {
                            this.state.data && this.state.data.length > 0 ?
                                <table className={"table table-responsive"}>
                                    <thead>
                                        <tr>
                                            <td>
                                                #
                                            </td>
                                            <td>
                                                Title
                                            </td>
                                            <td>
                                                Start Date
                                            </td>
                                            <td>
                                                End Date
                                            </td>
                                            <td>
                                                Meeting State
                                            </td>
                                            <td>
                                                Link
                                            </td>
                                            <td>
                                                Actions
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.map((item: IMeeting, key: number) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {item.index}
                                                        </td>
                                                        <td>
                                                            {item.meetingTitle}
                                                        </td>
                                                        <td>
                                                            {moment(item.startDate).format(Data_Format_EMS)}
                                                        </td>
                                                        <td>
                                                            {moment(item.endDate).format(Data_Format_EMS)}
                                                        </td>
                                                        <td>
                                                            {item.meetingState}
                                                        </td>
                                                        <td>
                                                            <a href={item.meetingLink} target="_blank" rel="noreferrer"> <MdPublic /> </a>
                                                        </td>
                                                        <td>

                                                            <button
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    this.openDeleteMeetingModal(item);
                                                                }}
                                                                className={"btn btn-outline-danger"}>
                                                                <FaTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>

                                :
                                <div>
                                    <EmptyListState
                                        image={emptyStateSpeakerImage}
                                        title={"You have no Meetings yet"}
                                        callToActionButton={{
                                            text: "Add your first Meeting now!",
                                            onClick: this.createMeetingHandler,
                                        }}
                                    />
                                </div>

                        }


                    </CardBody>
                </MainCard>
            </div>
        );
    }
}

export default ManageMeetingComponent;