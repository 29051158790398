import { useEffect, useState } from "react";
import { getDeliveryStatus, getMessages } from "../../api/MessagingApi";
import Helmet from "react-helmet";
import { MainCard } from "../../components/MainCard";
import { Button, CardBody, CardHeader, Col, Form, InputGroup, Row } from "reactstrap";
import { FaPlus } from "react-icons/fa";
import DatePicker from "../../components/DatePicker/DatePicker";
import Select from "react-select";
import { AiOutlineSearch } from "react-icons/ai";
import { DataTable } from "../../components/DataTable";
import { EmptyListState } from "../../components/EmptyListState";
import * as React from "react";

import SendSms from "./SendSms";
import moment from "moment/moment";
import { formatSimpleDate } from "../../util/dateUtil";

const Sms = () => {
  const [showSendSms, setShowSendSms] = useState(false);
  const [mails, setMails] = useState<any[]>([])
  const [status,setStatus] = useState<any[]>([])

  const [selectedStatus, setSelectedStatus] = useState<any>(undefined)
  const [fromDate, setFromDate] = useState<any>(undefined)
  const [toDate, setToDate] = useState<any>(undefined)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maximumPerPage] = useState(20);


  const loadSms = () => {
    getMessages({
      messageTypeId: 1,
      deliveryStatusId: selectedStatus?.id,
      fromDate : fromDate ?  moment(fromDate).startOf("day").toISOString():undefined,
      toDate:toDate ? moment(toDate).endOf("day").toISOString():undefined,
      pageIndex: page,
      limit: maximumPerPage
    }).then(value => {
      setMails(value.items);
      setCount(value.meta.totalItemsCount)
    })
  }

  useEffect(() => {
    loadSms()
  }, [page])
  useEffect(() => {
    getDeliveryStatus().then(setStatus)
  },[])

  const columns = [

    {
      Header: "Date",
      accessor: "createDate",
      canSort: true,
      Cell: (ctx:any) => {
        const date = ctx.cell.value;
        return (<span>{formatSimpleDate(date).toUpperCase()}</span>)
      }
    },
    {
      Header: "Recipient",
      accessor: "sentTo",
      canSort: true
    },
    {
      Header: "Subject",
      accessor: "subject",
      canSort: true
    },
    {
      Header: "Status",
      accessor: "status.name",
      canSort: true
    },

  ]

  return (
    <div>
      <Helmet title="Posts"/>
      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>SMS</div>
          <div>
            <Button color={'primary'} onClick={() => setShowSendSms(true)}>
              <FaPlus/> Send SMS
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="align-items-center mb-4">

              <Col sm="3" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'Start Date'}
                    closeOnSelect
                    value={fromDate}
                    onChange={setFromDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="3" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'End Date'}
                    closeOnSelect
                    value={toDate}
                    onChange={setToDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="3" xs="6" className='my-1'>
                <Select
                  isClearable
                  options={status??[]}
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  className="form-control flex-2"
                  placeholder="Select Status"
                  getOptionValue={(o) => o.id}
                  getOptionLabel={(o) => o.name}
                />
              </Col>
              <Col sm="3" xs="6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    loadSms();
                  }}
                  className="btn btn-success flex-2 w-100"
                >
                  <i className='flex justify-content-center align-items-center'>
                    <AiOutlineSearch size={18}/>{" "}Search
                  </i>
                </button>
              </Col>

            </Row>
          </Form>
          <DataTable
            columns={columns}
            data={mails || []}
            pagination={
              {
                show: true,
                onPageClicked: setPage,
                totalItemsCount: count,
                pageIndex: page,
                pageSize: maximumPerPage,

              }
            }
            emptyState={
              <div className={"text-center"}>
                <EmptyListState
                  title={"There is no sent Sms"}
                />
              </div>
            }
          />

        </CardBody>

      </MainCard>
      <SendSms toggle={() => setShowSendSms(prevState => !prevState)} isOpen={showSendSms} onUpdate={loadSms}/>

    </div>
  );

}

export default Sms;