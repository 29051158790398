import Axios, { AxiosResponse } from "axios";
import { redirect } from "react-router-dom";

export interface SubscriptionDetails {
  registrationRequired: boolean;
  needToChangePassword: boolean;
  expiresAt: string | null;
}
Axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if(error.response.status === 403){
      redirect("/Forbidden")
    }
    return Promise.reject(error)
  }
)

export const getMySubscriptionDetails = (): Promise<AxiosResponse<SubscriptionDetails>> => {
    return Axios.get<SubscriptionDetails>("/api/subscriptions/my");

};

export interface NewAccountSubscriptionRequest {
  accessPathUrl: string;
  name: string;
}

export const startNewAccountSubscription = async (
  request: NewAccountSubscriptionRequest
): Promise<void> => {
  await Axios.post("/api/subscriptions", request);
};
