import React from "react";
import ReactDatetime from "react-datetime";
import { FaCalendar, FaRegCalendar } from "react-icons/fa6";
import 'react-datetime/css/react-datetime.css';
import './styles.css'
import { FaRegCalendarAlt } from "react-icons/fa";
import placeholder from "lodash/fp/placeholder";


const DatePicker = (props:ReactDatetime.DatetimepickerProps & {placeHolder?:string}) => (

  <ReactDatetime {...props} renderInput={(prp, openCalendar) => <CustomInput {...prp} placeHolder={props.placeHolder} onClick={openCalendar} />} />

)

const CustomInput = ({ value, onClick,placeHolder }:any) => (
  <div className="custom-input" onClick={onClick}>
    <input type="text" placeholder={placeHolder} value={value} readOnly />
    <FaRegCalendarAlt size={18} />
  </div>
);

export default DatePicker;