import React, {useRef} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import KeywordsPlugin  from './KeywordsPlugin';

const CK = ({ content, changeContent,lang = "en",keywordPlugin=false,keywordsList=[] }) => {

    const editorRef = useRef();
    const toolbar = [...ClassicEditor.defaultConfig.toolbar.items];
    if (keywordPlugin) {
        toolbar.push("keywords");
    }

    return (
        <CKEditor
            ref={editorRef}
            id="CKCaseEditor"
            editor={ClassicEditor}
            config={{
                keywordsList:keywordsList,
                extraPlugins:[uploadPlugin],
                language:lang,
                mediaEmbed: {
                    previewsInData: true
                },
                toolbar:toolbar
            }}
            data={content}
            onChange={(event, editor)=>{
                const data = editor.getData();
                changeContent(data);
            }}
        />
    );
};



export default CK;

const uploadAdapter = (loader) => {
    return {
        upload: async () => loader.file
            .then(file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve({default: reader.result});
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            })),
        abort: () => {
        }
    };
};

function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}

function keywordsPluginTest(editor){
    editor.ui.componentFactory.add('simpleButton', locale => {
        const buttonView = editor.ui.componentFactory.create("button")
            buttonView.set({
            label: 'Simple Button',
            withText: true
        });
        buttonView.on('execute', () => {
        });
        return buttonView;
    });
};