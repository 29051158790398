import PropTypes from "prop-types";
import * as React from "react";

interface TimeLineProps {
    children: React.ReactNode;
}

export const TimeLine =React.forwardRef<HTMLDivElement, TimeLineProps>((props, ref) => {
    return <div ref={ref} className="accordion">{props.children}</div>;
});

TimeLine.propTypes = {
    children: PropTypes.node.isRequired
};
