/* eslint-disable react/no-deprecated */
import * as React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Loader} from "../../components/Loader";
import {IMeeting} from "../../api/MeetingApi";
import {deleteMeeting} from "../../api/ConnectServiceApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";




// props
interface IDeleteMeetingModalProps {
    meetingToDelete: IMeeting,
    onUpdated?: () => unknown,
    cancelDeleting:() => unknown;
    
}

// state
interface IDeleteMeetingModalState {
    __loading__:boolean,
    id:number
}

class DeleteMeetingModal extends React.Component<IDeleteMeetingModalProps , IDeleteMeetingModalState> {
    constructor(props:IDeleteMeetingModalProps) {
        super(props);
        this.state = {
            __loading__ : false,
            id : 0
        }
    }

    componentWillMount() {

        if(this.props.meetingToDelete) {
            this.setState({id: this.props.meetingToDelete.id})
        }
    }

    handleDelete = () => {
        this.setState({__loading__: true})
        deleteMeeting(this.state.id)
            .then(res => {
                this.setState({__loading__: false})
                toast.success(
                    <ToastMessage type="Success">
                        Deleted Successfully !
                    </ToastMessage>
                );
                if(this.props.onUpdated) {
                    this.props.onUpdated();
                }
                if(this.props.cancelDeleting) {
                    this.props.cancelDeleting();
                }
            }).catch(err => {
            this.setState({__loading__: true})
        })
    }
    
    render() {
        return (
            <Modal toggle={this.props.cancelDeleting} isOpen={!!this.props.meetingToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Meeting
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ?   <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.meetingToDelete &&
                    `${this.props.meetingToDelete.meetingTitle || ""}` }
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.meetingToDelete &&
                        `${this.props.meetingToDelete.meetingTitle || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDelete}>
                        Yes, Delete &quot;
                        {this.props.meetingToDelete &&
                        `${this.props.meetingToDelete.meetingTitle || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteMeetingModal;