/* eslint-disable react/no-deprecated */
import {Loader} from "components/Loader";
import moment from "moment";
import React, {Component} from "react";
import Helmet from "react-helmet";
import {FaEdit, FaPlus, FaTrashAlt} from "react-icons/fa";
import {FiEye} from "react-icons/fi";
import {Button, CardBody, CardHeader} from "reactstrap";
import {GetAll, ICouponModel} from "../../api/CouponApi";
import EmptyImg from "../../assets/imgs/no_data.svg";
import {MainCard} from "../../components/MainCard";
import AddEditCouponComponent, {ModalStatus} from "./AddEditCouponComponent";
import ConfirmDeleteCoupon from './ConfirmDeleteCoupon';
import CouponProducsComponent from './CouponProducsComponent';
import "./ManageCouponsStyle.css";
import {formatSimpleDate} from "../../util/dateUtil";
import {EmptyListState} from "../../components/EmptyListState";
import { PaginationFooter } from "../../components/PaginationFooter";

export default class ManageCouponsComponent extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            openModal: false,
            itemToEdit: null,
            showActionCardKey: -1,
            couponProductsModal: false,
            couponId: 0,
            couponToDelete: 0,
            deleteModal: false,
            page:1,
            count:0,
            maximumPerPage:20,
        };
    }

    componentWillMount() {
        this.fetchCoupons();
    }
    componentDidUpdate(prevProps:any, prevState:any) {
        if (prevState.page !== this.state.page) {
            this.fetchCoupons();
        }
    }

    fetchCoupons = () => {
        this.setState({loading: true});
        GetAll({pageIndex:this.state.page,limit:this.state.maximumPerPage}).then((collection) => {
            this.setState({loading: false});
            if (collection) {
                this.setState({data: collection.items,count:(collection as any).totalItemsCount});
            }
        }).catch((err) => {
        });
    };

    toggleCouponProducts = (couponId: number) => {
        this.setState({couponProductsModal: !this.state.couponProductsModal, couponId, showActionCardKey: -1})
    }


    // edit item handler

    editItemHandler = (item: ICouponModel) => {
        this.setState({itemToEdit: item, openModal: true, showActionCardKey: -1});
    }

    // end  edit item handler

    render() {
        return (
            <div>
                {/*<ToastContainer />*/}
                {this.state.openModal ? (
                    <AddEditCouponComponent
                        isOpen={true}
                        close={() => {
                            this.setState({openModal: false, itemToEdit: null});
                        }}
                        onUpdated={this.fetchCoupons}
                        modalStatus={
                            this.state.itemToEdit
                                ? ModalStatus.__EDIT__
                                : ModalStatus.__CREATE__
                        }
                        item={this.state.itemToEdit}
                    />
                ) : null}
                {
                    this.state.couponProductsModal && this.state.couponId !== 0 ?
                        <CouponProducsComponent isOpen={true}
                                                close={() => {
                                                    this.toggleCouponProducts(0);

                                                }}
                                                couponId={this.state.couponId}
                        />
                        : null
                }

                {
                    this.state.deleteModal && this.state.couponToDelete > 0 ?
                        <ConfirmDeleteCoupon isOpen={true} onUpdate={this.fetchCoupons}
                                             couponId={this.state.couponToDelete}
                                             close={() => {
                                                 this.setState({deleteModal: false, couponToDelete: 0})
                                             }}
                        />
                        : null
                }
                <Helmet title="Coupons"/>
                <MainCard>
                    <CardHeader className="d-flex justify-content-between">
                        <div>Coupons</div>
                        <div>
                            <Button
                                onClick={(e) => {
                                    this.setState({openModal: true, __itemEditable__: null});
                                }}
                                color={"primary"}
                            >
                                <FaPlus/> Add Coupon
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {this.state.loading ? <Loader isLoading={true}/> : null}

                        {this.state.data && this.state.data.length > 0 ? (
                            <div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>Code</td>
                                        <td>Discount (%)</td>
                                        <td>Availability Start date</td>
                                        <td>Availability End date</td>
                                        <td>Usage</td>
                                        <td>Per Customer Limit</td>
                                        <td>Actions</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.data.map((item: ICouponModel, i: number) => {
                                        return (

                                            <>
                                                <tr>
                                                    <td> {i+1} </td>
                                                    <td> {item.code} </td>
                                                    <td>
                                                        {" "}
                                                        {item.discountPercentage} {"%"}{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {formatSimpleDate(item.startDate ? item.startDate.toString() : "")}{" "}
                                                    </td>
                                                    <td> {formatSimpleDate(item.endDate ? item.endDate.toString() : "")} </td>
                                                    <td> {item.usageQTY} / {item.totalQTY} </td>
                                                    <td> {item.maxUsagePerUser} </td>

                                                    <td className="position-relative">
                                                        {
                                                            !item.includeAllProducts ?
                                                                <>
                                                                    <Button
                                                                        size={"sm"}
                                                                        color="primary"
                                                                        outline={true}
                                                                        onClick={e => {
                                                                            this.toggleCouponProducts(item.id);
                                                                        }}
                                                                    >
                                                                        <FiEye/>
                                                                    </Button>{" "}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <Button
                                                            color="primary"
                                                            outline={true}
                                                            size={"sm"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.editItemHandler(item);
                                                            }}
                                                        >
                                                            <FaEdit/>
                                                        </Button>{" "}
                                                        <Button
                                                            color="danger"
                                                            outline={true}
                                                            size={"sm"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    couponToDelete: item.id,
                                                                    deleteModal: true,
                                                                    showActionCardKey: -1
                                                                })
                                                            }}
                                                        ><FaTrashAlt/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>

                                        );
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={6}>
                                            <PaginationFooter
                                              onPageClicked={page => this.setState({page:page}) }
                                              pageIndex={this.state.page}
                                              totalItemsCount={this.state.count}
                                              pageSize={this.state.maximumPerPage}
                                              pageItemsCount={this.state.data.length}
                                            />
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        ) : null}
                        {this.state.data.length === 0 && !this.state.loading ? (
                            <EmptyListState
                                image={EmptyImg}
                                title={"You have no Coupons yet"}
                            />
                        ) : null}
                    </CardBody>
                </MainCard>
            </div>
        );
    }
}
