import Axios from "axios";

export interface ConfigurationBody {
  hasCustomConfiguration: boolean;
  senderEmail: string;
  senderName:string;
  userName: string;
  server: string;
  port: number;
  socketSecurity: string;
}

export interface PutConfiguration
  extends Omit<ConfigurationBody, "hasCustomConfiguration"> {
  password: string;
}

export const updateConfiguration = async (request: PutConfiguration) => {
  await Axios.put(`/api/EmailConfigurations/CustomSmtp`, request);
};

export const deleteConfiguration = async () => {
  await Axios.delete(`/api/EmailConfigurations/CustomSmtp`);
};

export const getConfiguration = async (): Promise<ConfigurationBody> => {
  return (await Axios.get(`/api/EmailConfigurations/CustomSmtp`)).data;
};
