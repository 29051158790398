import Axios from "axios";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface SendEmailRequest {
  eventId?: number | null;
  certificateId: number | null;
  recipinetListId: number | null;
  subject: string;
  htmlBodyTemplate: string;
}

export interface EmailHistoryItems {
  id: number;
  certificateId: number;
  recipientListId: number;
  subject: string;
  htmlBodyTemplate: string;
  status: number;
}

export interface Recipient {
  id: number;
  recipientFirstName: string;
  recipientMiddleName: string;
  recipientLastName: string;
  recipientEmail: string;
  recipientPhoneNumber: string;
  status: number;
}

export interface PaginatedRequestList extends PaginatedRequest {
  eventId: number;
}

export const sendMail = async (
  request: SendEmailRequest
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post<NewResourceResult<number>>(
    `api/Mailing/Mailing`,
    request
  );

  return result.data;
};

export const getEmailHistory = async (
  eventId: number,
  request: PaginatedRequestList
): Promise<PaginatedResult<EmailHistoryItems>> => {
  const result = await Axios.get<PaginatedResult<EmailHistoryItems>>(
    `/api/Events/${eventId}/Mailing`,
    { params: request }
  );
  return result.data;
};

export const getRecipientList = async (
  taskId: number,
  request: PaginatedRequestList
): Promise<PaginatedResult<Recipient>> => {
  const result = await Axios.get<PaginatedResult<Recipient>>(
    `/api/Mailing/${taskId}/items`,
    { params: request }
  );
  return result.data;
};

// Global Email History

export const getGlobalEmailHistory = async (
  request: PaginatedRequest
): Promise<PaginatedResult<EmailHistoryItems>> => {
  const result = await Axios.get<PaginatedResult<EmailHistoryItems>>(
    `/api/Mailing/Mailing`,
    { params: request }
  );
  return result.data;
};
