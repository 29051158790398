import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { registerUser, userLogin } from './authAction';

interface AuthState {
    loading: boolean;
    userInfo: any;
    accessToken: string | null;
    error: string | null;
    success: boolean;
}

const accessToken = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : null;

const initialState: AuthState = {
    loading: false,
    userInfo: null,
    accessToken,
    error: null,
    success: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('userToken');
            state.loading = false;
            state.userInfo = null;
            state.accessToken = null;
            state.error = null;
        },
        setCredentials: (state, action: PayloadAction<any>) => {
            state.userInfo = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.userInfo = action.payload;
                state.accessToken = action.payload.accessToken;
            })
            .addCase(userLogin.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;