/* eslint-disable react/jsx-key */
import {
    AddMembershipUser,
    GetDurationsPlan,
    GetMembershipPlans,
    GetQuestionsMembershipArea,
    IAddMembershipUser,
    IDurationPlan,
    IQuestionMembershipPlan,
} from "api/MembershipAreaApi";
import {IDropdownList} from "api/common/DropdownList";
import classnames from "classnames";
import * as React from "react";
import {AiOutlineUser, AiOutlineUsergroupAdd} from "react-icons/ai";
import Select from "react-select";
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import "./AddTenantUserComponent.style.css";

import {Loader} from "components/Loader";
import {FaMoneyBill, FaTicketAlt} from "react-icons/fa";
import {toast} from "react-toastify";
import {PhoneInput} from "../../components/PhoneInput/PhoneInput";
import {GetPaymentGatewaysPos} from "../../api/PaymentGatewaysApi";
import {PaymentPlanComponent, SelectedPayment} from "./PaymentPlanComponent";
import {isValidPhoneNumber} from "react-phone-number-input";

interface AddTenantUserComponentProps {
    close?: () => unknown;
    onUpdated?: () => unknown;
    isOpen: boolean;
}

class AddTenantUserComponent extends React.Component<AddTenantUserComponentProps,
    any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            activeTab: "1",
            activeTabTitle: "User Details",
            currentStage: 1,
            showAlertValidation: false,

            error: null,
            data: {
                1: {
                    email: {
                        type: 'email',
                        value: null,
                        required: true,
                    },
                    firstName: {
                        type: 'text',
                        value: null,
                        required: true,
                    },
                    lastName: {
                        type: 'text',
                        value: null,
                        required: true,
                    },
                    phoneNumber: {
                        type: 'phoneNumber',
                        value: null,
                        required: true,
                    },
                    password: {
                        type: 'password',
                        value: null,
                        required: true,
                    },
                    confirmPassword: {
                        value: null,
                        required: true,
                        compare: {
                            key: "password",
                        },
                    },
                },
                2: {
                    durationPlanId: {
                        value: null,
                        required: true,
                    },
                },
                3: {},
            },
            plans: [],
            durationsPlan: [],
            planId: null,
            questionsPlan: [],
            selectedPlan: null,
            selectedPayments: [] as SelectedPayment[],
            amount:0,
            totalSelectedAmount:0,
            paymentMethods: [],
            validation: {
                1: false,
                2: false,
                3: false,
            },
        };
    }

    isSelected = (gatewayId: number, methodId: number) => {
        return this.state.selectedPayments.some((payment: { gatewayId: number; methodId: number; }) => payment.gatewayId === gatewayId && payment.methodId === methodId);
    }

    setSelectedPayments = (payments: SelectedPayment[])=>{
        const totalAmount = payments.reduce((total, payment) => total + payment.amount, 0);

        this.setState({...this.state, selectedPayments:  payments, totalSelectedAmount: totalAmount})
    }

    handleSetSelectedPayment = (gatewayId: number, methodId: number) => {
        if (this.isSelected(gatewayId, methodId)) {
            const updatedPayments = this.state.selectedPayments.filter((payment: { gatewayId: number; methodId: number; }) => !(payment.gatewayId === gatewayId && payment.methodId === methodId));
            const totalAmount = updatedPayments.reduce((total: any, payment: { amount: any; }) => total + payment.amount, 0);

            this.setState({...this.state, selectedPayments:  [...updatedPayments], totalSelectedAmount: totalAmount})
        } else {
            const paymentPos = {
                gatewayId: gatewayId,
                methodId: methodId,
                amount: 0,
                note: ''
            };
            this.setState({...this.state, selectedPayments:  [...this.state.selectedPayments, paymentPos]})
        }
    }

    fetchMembershipPlansHandler = () => {
        GetMembershipPlans().then(data => {
            ;
            const plans = data.map((item: IDropdownList, i: number) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            this.setState({plans});
        });
    };

    validateInput(type: string, value: string): boolean {
        if (type === "email") {
            // Regular expression for validating email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        } else if (type === "phoneNumber") {
            return isValidPhoneNumber(value);
        } else if (type === "text") {
            // No validation needed for text
            return true;
        } else if (type === "password") {
            // Validate password with minimum 6 characters and at least one numeric and one alphanumeric character
            const passwordRegex  = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            return passwordRegex.test(value);
        } else {
            return true;
        }
    }

    validationStatesHandler = (stage: number) => {
        let isValid: boolean = true;
        if(stage === 1){
            for (const property in this.state.data[stage]) {

                if(this.state.data[stage][property] && this.state.data[stage][property].value){
                    const validInput = this.validateInput(this.state.data[stage][property].type, this.state.data[stage][property].value);
                    const data = this.state.data;
                    if(!validInput){
                        isValid = false;
                        let errorMsg = this.state.data[stage][property].type + " is not valid";
                        if(this.state.data[stage][property].type === "password") errorMsg += " (at least a symbol, upper and lower case letters and a number)"
                        data[stage][property].error = errorMsg;

                        this.setState({currentStage: stage, data: data});

                        break;
                    } else {
                        data[stage][property].error = null;
                        this.setState({data: data});
                    }
                }

            }
        }
        if(!isValid) return;
        for (const property in this.state.data[stage]) {
            if (
                this.state.data[stage][property] &&
                this.state.data[stage][property].required &&
                !this.state.data[stage][property].value
            ) {
                {
                    isValid = false;
                    this.setState({currentStage: stage});
                    break;
                }
            }

            if (
                this.state.data[stage][property] &&
                this.state.data[stage][property].required &&
                this.state.data[stage][property].compare
            ) {
                if (
                    this.state.data[stage][property].value !==
                    this.state.data[stage][this.state.data[stage][property].compare.key]
                        .value
                ) {
                    isValid = false;

                    this.setState({
                        currentStage: stage,
                        error: "confirm password not equal password",
                    });
                    break;
                } else {
                    this.setState({
                        error: null,
                    });
                }
            }
        }

        if (stage == 3 && Object.keys(this.state.data[3]).length === 0) {
            isValid = true;
        }

        this.setState({validation: {...this.state.validation, [stage]: isValid}});
        return isValid;
    };

    toggleTabs = (tab: any, title: string) => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab, activeTabTitle: title});
        }
    };

    onChangeHandler = (value: any, key: string, stage: number) => {
        const data = this.state.data;
        if (!data[stage][key]) return;
        data[stage][key].value = value;
        this.setState({data}, () => {
            // ;
            this.validationStatesHandler(stage);
        });
    };

    activeTabTitleHandler = (stage: number): string => {
        switch (stage) {
            case 1:
                return "User Data";
            case 2:
                return "Membership plan";
            case 3:
                return "Payment";
            default:
                return "";
        }
    };

    onPreviousHandler = (e: any) => {
        e.preventDefault();

        const state = (+this.state.activeTab - 1).toString();

        this.setState({
            currentStage: +state,
        });
        const title = this.activeTabTitleHandler(+state);
        this.toggleTabs(state, title);
        this.stageActions(+state);
    }
    onNextHandler = (e: any) => {
        e.preventDefault();

        const state = (+this.state.activeTab + 1).toString();

        this.setState({
            currentStage: +state,
        });
        const title = this.activeTabTitleHandler(+state);
        this.toggleTabs(state, title);
        this.stageActions(+state);
    };

    stageActions = (stage: number) => {
        if (stage == 2) {
            this.fetchMembershipPlansHandler();
        }
        if (stage == 3) {
            this.fetchPaymentMethods();
            // this.fetchQuestionsPlanHandler();
        }
    };

    getDurationPlanHandler = (planId: number) => {
        GetDurationsPlan(planId).then(data => {
            ;
            this.setState({durationsPlan: data, planId});
        });
    };

    fetchQuestionsPlanHandler = () => {
        if (this.state.planId) {
            GetQuestionsMembershipArea(this.state.planId).then(data => {
                let stage3 = {};
                data.forEach((item: IQuestionMembershipPlan) => {
                    stage3 = {
                        ...stage3,
                        [`q-${item.id}`]: {required: item.required, value: null},
                    };
                });

                ;
                this.setState({
                    questionsPlan: data,
                    data: {...this.state.data, 3: stage3},
                });
            });
        }
    };

    fetchPaymentMethods = () => {
        GetPaymentGatewaysPos().then(pos => {
            ;
            this.setState({
                data: {...this.state.data, paymentMethods: pos}
            })
        })
    }

    onChangeQuestionHandler = (id: number, value: any): void => {
        if (this.state.data[3][`q-${id}`]) {
            this.setState(
                {
                    data: {
                        ...this.state.data,
                        3: {
                            ...this.state.data[3],
                            [`q-${id}`]: {...this.state.data[3][`q-${id}`], value: value},
                        },
                    },
                },
                () => {
                    ;
                    this.validationStatesHandler(3);
                }
            );
        }
    };

    onConfirmHandler = (e: any) => {
        e.preventDefault();
        let payload: any = {};
        const {data} = this.state;
        let questions = [];
        for (const stage in data) {
            for (const key in data[stage]) {
                if (Number(stage) == 3) {
                    questions.push({
                        questionId: key.toString(),
                        value: data[3][key].value,
                    });
                } else {
                    payload = {...payload, [key]: data[stage][key].value};
                }
            }
        }
        payload.questions = null;
        payload.payments = this.state.selectedPayments;
        ;
        this.setState({loading: true});
        AddMembershipUser(payload as IAddMembershipUser).then(
            _ => {
                toast.success("Add User Successfully");
                this.setState({loading: false});
                this.props.onUpdated?.();
            },
            error => {
                this.setState({loading: false});
                toast.error("oops, sorry error has been happened ");
            }
        );
    };

    render() {
        return (
            <Modal
                size="lg"
                unmountOnClose
                isOpen={this.props.isOpen}
                toggle={this.props.close}
            >
                {this.state.loading ? <Loader isLoading={true}/> : null}
                <ModalHeader toggle={this.props.close}>
                    {" "}
                    <i>
                        {" "}
                        <AiOutlineUsergroupAdd/>{" "}
                    </i>{" "}
                    Add Member ({this.state.activeTabTitle})
                </ModalHeader>
                <ModalBody>
                    {this.state.showAlertValidation ? (
                        <div className="mb-2" style={{color: "#f00"}}>
                            <small> Complete the current Stage first. </small>
                        </div>
                    ) : null}

                    <Nav tabs>
                        <NavItem style={{flex: 2}}>
                            <NavLink
                                style={{backgroundColor: "#f3f3f3"}}
                                className={classnames({active: this.state.activeTab === "1"})}
                                onClick={() => {
                                    this.toggleTabs("1", "User Details");
                                }}
                            >
                                <small> <i> <AiOutlineUser/> </i> User Details </small>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{flex: 2}}>
                            <NavLink
                                style={{backgroundColor: "#f3f3f3"}}
                                className={classnames({active: this.state.activeTab === "2"})}
                                onClick={() => {
                                    if (this.state.currentStage < 2) {
                                        this.setState({showAlertValidation: true}, () => {
                                            setTimeout(() => {
                                                this.setState({showAlertValidation: false});
                                            }, 6000);
                                        });
                                        return;
                                    }
                                    this.toggleTabs("2", "Memership Plan");
                                }}
                            >
                                <small> <i> <FaTicketAlt/> </i> Membership Plan </small>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{flex: 2}}>
                            <NavLink
                                style={{backgroundColor: "#f3f3f3"}}
                                className={classnames({active: this.state.activeTab === "3"})}
                                onClick={() => {
                                    if (this.state.currentStage < 3) {
                                        this.setState({showAlertValidation: true}, () => {
                                            setTimeout(() => {
                                                this.setState({showAlertValidation: false});
                                            }, 6000);
                                        });
                                        return;
                                    }
                                    this.toggleTabs("3", "Customer Fields");
                                }}
                            >
                                <small> <i> <FaMoneyBill/> </i> Payment </small>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        {/*  Tenant User Information  */}
                        <TabPane tabId="1">
                            <Form className="p-4" style={{border: "1px solid #ddd"}}>
                                <FormGroup>
                                    <Label> First Name * </Label>
                                    <Input
                                        required={true}
                                        className={"form-control"}
                                        type="text"
                                        onChange={(e: any) => {
                                            this.onChangeHandler(e.target.value, "firstName", 1);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label> Last Name * </Label>
                                    <Input
                                        required={true}
                                        className={"form-control"}
                                        type="text"
                                        onChange={(e: any) => {
                                            this.onChangeHandler(e.target.value, "lastName", 1);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label> Email * </Label>
                                    <Input
                                        required={true}
                                        className={"form-control"}
                                        type="email"
                                        onChange={(e: any) => {
                                            this.onChangeHandler(e.target.value, "email", 1);
                                        }}
                                    />
                                    <FormFeedback className="d-block">{this.state.data[1]['email'].error}</FormFeedback>

                                </FormGroup>

                                <FormGroup>
                                    <Label> Phone Number * </Label>
                                    <PhoneInput
                                        onPhoneNumberUpdated={(formattedPhoneNumber) => {
                                            this.onChangeHandler(formattedPhoneNumber, "phoneNumber", 1);
                                        }}
                                    />
                                    <FormFeedback className="d-block">{this.state.data[1]['phoneNumber'].error}</FormFeedback>

                                    {/*<Input*/}
                                    {/*  required={true}*/}
                                    {/*  className={"form-control"}*/}
                                    {/*  type="text"*/}
                                    {/*  autoComplete={"off"}*/}
                                    {/*  onChange={(e: any) => {*/}
                                    {/*    this.onChangeHandler(e.target.value, "phoneNumber", 1);*/}
                                    {/*  }}*/}
                                    {/*/>*/}
                                </FormGroup>

                                <FormGroup>
                                    <Label> Password * </Label>
                                    <Input
                                        required={true}
                                        className={"form-control"}
                                        type="password"
                                        autoComplete={"new-password"}
                                        onChange={(e: any) => {
                                            this.onChangeHandler(e.target.value, "password", 1);
                                        }}
                                    />
                                    <FormFeedback className="d-block">{this.state.data[1]['password'].error}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label> Confirm Password * </Label>
                                    <Input
                                        required={true}
                                        className={"form-control"}
                                        type="password"
                                        autoComplete={"off"}
                                        onChange={(e: any) => {
                                            this.onChangeHandler(
                                                e.target.value,
                                                "confirmPassword",
                                                1
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        </TabPane>

                        <TabPane tabId="2">
                            <div className="p-4" style={{border: "1px solid #ddd"}}>
                                <FormGroup>
                                    <Label> Choose Membership plan </Label>
                                    <Select
                                        options={this.state.plans}
                                        onChange={(option: any) => {
                                            if (option) {
                                                this.getDurationPlanHandler(+option.value);
                                            }
                                        }}
                                    ></Select>
                                </FormGroup>

                                {/* Durations of Plan */}
                                {this.state.durationsPlan &&
                                this.state.durationsPlan.length > 0 ? (
                                    <FormGroup>
                                        <Label> Durations Plan </Label>
                                        <div className="row durations-content">
                                            {this.state.durationsPlan.map(
                                                (item: IDurationPlan, i: number) => {
                                                    return (
                                                        <>
                                                            <div className="col-sm-6">
                                                                <div className="body">
                                                                    <ul
                                                                        style={{
                                                                            border:
                                                                                this.state.data[2].durationPlanId.value ==
                                                                                item.id
                                                                                    ? "1px solid #1c8879"
                                                                                    : "1px dashed #ddd",
                                                                            boxShadow:
                                                                                this.state.data[2].durationPlanId.value ==
                                                                                item.id
                                                                                    ? "0 0 26px rgb(28 136 121 / 12%)"
                                                                                    : "none",
                                                                        }}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            this.setState(
                                                                                {
                                                                                    data: {
                                                                                        ...this.state.data,
                                                                                        2: {
                                                                                            durationPlanId: {
                                                                                                ...this.state.data[2]
                                                                                                    .durationPlanId,
                                                                                                value: item.id,
                                                                                            },
                                                                                        },
                                                                                        selectedPlan: item,
                                                                                        amount: item.price.totalPrice
                                                                                    },
                                                                                },
                                                                                () => {
                                                                                    ;
                                                                                    this.validationStatesHandler(2);
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <li>
                                                                            {" "}
                                                                            <span>Days:</span>{" "}
                                                                            <strong> {item.durationInDays} </strong>{" "}
                                                                        </li>
                                                                        <li>
                                                                            {" "}
                                                                            <span>Limits:</span>{" "}
                                                                            <strong> {item.checkInLimits} </strong>{" "}
                                                                        </li>
                                                                        <li>
                                                                            {" "}
                                                                            <span>Taxes:</span>{" "}
                                                                            {item.price?.taxes?.length &&
                                                                            item.price?.taxes.map(
                                                                                (tax: any) => {
                                                                                    return (
                                                                                        <span className="btn btn-sm badge btn-success">
                                                                                            {tax.name} {" - "} {tax.value}{" "}
                                                                                        </span>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </li>
                                                                        <li>
                                                                            <span> Price: </span>
                                                                            <strong> {item.price.displayPrice} </strong>
                                                                        </li>
                                                                        <li>
                                                                            <span> Total Price: </span>
                                                                            <strong>
                                                                                {" "}
                                                                                {item.price.displayTotalPrice}{" "}
                                                                            </strong>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>

                                                    );
                                                }
                                            )}
                                        </div>
                                    </FormGroup>
                                ) : null}
                            </div>
                        </TabPane>

                        <TabPane tabId="3">
                            <div style={{border: "1px solid #ddd"}}>

                                <PaymentPlanComponent
                                    selectedPlan={this.state.data.selectedPlan}
                                    amount={this.state.data.amount}
                                    paymentMethods={this.state.data.paymentMethods}
                                    selectedPayments={this.state.selectedPayments}
                                    setSelectedPayments={this.setSelectedPayments}
                                    handleSetSelectedPayment={this.handleSetSelectedPayment}
                                />

                                {/*<QuestionPlanComponent*/}
                                {/*  questions={this.state.questionsPlan}*/}
                                {/*  onChangeEachQuestion={this.onChangeQuestionHandler}*/}
                                {/*  answers={this.state.data[3]}*/}
                                {/*/>*/}
                            </div>
                        </TabPane>
                    </TabContent>
                    {this.state.error ? (
                        <div className="mb-2" style={{color: "#f00"}}>
                            <small> {this.state.error} </small>
                        </div>
                    ) : null}

                </ModalBody>
                <ModalFooter>
                    {+this.state.activeTab !== 3 ? (
                        <>
                            <Button color={"secondary"} onClick={this.props.close}>
                                Cancel
                            </Button>
                            <button
                                onClick={this.onNextHandler}
                                className="btn btn-primary float-end"
                                disabled={!this.state.validation[+this.state.activeTab]}
                            >
                                {" "}
                                Next{" "}
                            </button>
                        </>
                    ) : null}

                    {+this.state.activeTab === 3 ? (
                        <>
                            <Button color={"secondary"} onClick={this.onPreviousHandler}>
                                Back
                            </Button>
                            <button
                                className="btn btn-primary float-end"
                                disabled={this.state.data.amount > 0 && this.state.totalSelectedAmount != this.state.data.amount}
                                onClick={this.onConfirmHandler}
                            >
                                {" "}
                                Confirm{" "}
                            </button>
                        </>

                    ) : null}
                </ModalFooter>
            </Modal>
        );
    }
}

export default AddTenantUserComponent;
