import React from "react";
import TooltipHintComponent from "../TooltipHint/TooltipHintComponent";
import { FormGroup, Input } from "reactstrap";
import { AiOutlineDelete } from "react-icons/ai";

const ImageUploader = ({images,setImages}:any) => {

  const handleFileChange = (event:any) => {
    Array.from(event.target.files as FileList).forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = (reader.result as string)?.split(',')[1];
        setImages((prevImages:any) => [...prevImages, { path: URL.createObjectURL(file), base64: base64String, file: file }]);
      };
      reader.readAsDataURL(file);
    });


  };

  const handleRemoveImage = (id:string) => {
    setImages(images.filter((image:any) => image.path !== id));
  };

  const handleDragStart = (index:number) => (event:any) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (index:number) => (event:any) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    const draggedImage = images[draggedIndex];
    const remainingImages = images.filter((_:any, i:number) => i !== parseInt(draggedIndex));
    const newImages = [...remainingImages.slice(0, index), draggedImage, ...remainingImages.slice(index)];
    setImages(newImages);
  };

  const handleDragOver = (event:any) => {
    event.preventDefault();
  };

  return (
    <div>
      <FormGroup>
        <label> Product Images
          <TooltipHintComponent text="images of product will display in your site."/>
        </label>

        <Input
          type="file"
          multiple
          value={undefined}
          accept="image/*"
          onChange={handleFileChange}/>

      </FormGroup>
      <div style={{maxHeight:'300px' ,overflowY:"auto"}} className='row wrap'>
        {images.map((image:any, index:number) => (
          <div
            key={image.path}
            className={'col-4 img-container d-flex justify-content-center mb-2'}
            draggable
            onDragStart={handleDragStart(index)}
            onDrop={handleDrop(index)}
            onDragOver={handleDragOver}>
            <div className="d-none remove-container">
              <button
                onClick={() => handleRemoveImage(image.path)}
                className="remove-btn btn btn-danger">
                <AiOutlineDelete/>
              </button>
            </div>
            <img src={image.path} alt="Uploaded" style={{  maxWidth:'100%' ,maxHeight:'150px', objectFit:'contain' }} />
          </div>
        ))}
      </div>
    </div>
  );

}

export default ImageUploader;