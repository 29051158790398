import Helmet from "react-helmet";
import { MainCard } from "../../components/MainCard";
import { Button, CardBody, CardHeader, Col, Form, InputGroup, Row, } from "reactstrap";
import { FaPlus, } from "react-icons/fa";
import { DataTable } from "../../components/DataTable";
import { EmptyListState } from "../../components/EmptyListState";
import * as React from "react";
import { useEffect, useState } from "react";
import { getDeliveryStatus, getMessages } from "../../api/MessagingApi";
import Select from "react-select";
import { AiOutlineSearch } from "react-icons/ai";
import DatePicker from "../../components/DatePicker/DatePicker";
import SendEmail from "./SendEmail";
import moment from "moment/moment";
import { formatSimpleDate } from "../../util/dateUtil";





const Mails = () => {

  const [showSendEmail, setShowSendEmail] = useState(false);
  const [mails, setMails] = useState<any[]>([])
  const [status,setStatus] = useState<any[]>([])

  const [selectedStatus, setSelectedStatus] = useState<any>(undefined)
  const [fromDate, setFromDate] = useState<any>(undefined)
  const [toDate, setToDate] = useState<any>(undefined)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maximumPerPage] = useState(20);


  const loadEmails = () => {
    getMessages({
      messageTypeId: 0,
      deliveryStatusId: selectedStatus?.id,
      fromDate : fromDate ?  moment(fromDate).startOf("day").toISOString():undefined,
      toDate:toDate ? moment(toDate).endOf("day").toISOString():undefined,
      pageIndex: page,
      limit: maximumPerPage
    }).then(value => {
      setMails(value.items);
      setCount(value.meta.totalItemsCount)
    })
  }

  useEffect(() => {
    loadEmails()
  }, [page])
  useEffect(() => {
    getDeliveryStatus().then(setStatus)
  },[])

  const columns = [
    {
      Header: "Date",
      accessor: "createDate",
      canSort: true,
      Cell: (ctx:any) => {
        const date = ctx.cell.value;
        return (<span>{formatSimpleDate(date).toUpperCase()}</span>)
      }
    },
    {
      Header: "Recipient",
      accessor: "sentTo",
      canSort: true
    },
    {
      Header: "Subject",
      accessor: "subject",
      canSort: true
    },
    {
      Header: "Status",
      accessor: "status.name",
      canSort: true
    },

  ]

  return (
    <div>
      <Helmet title="Posts"/>
      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>E-Mails</div>
          <div>
            <Button color={'primary'} onClick={() => setShowSendEmail(true)}>
              <FaPlus/> Send E-mail
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="align-items-center mb-4">

              <Col sm="3" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'Start Date'}
                    closeOnSelect
                    value={fromDate}
                    onChange={setFromDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="3" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'End Date'}
                    closeOnSelect
                    value={toDate}
                    onChange={setToDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="3" xs="6" className='my-1'>
                  <Select
                    isClearable
                    options={status??[]}
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    className="form-control flex-2"
                    placeholder="Select Status"
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.name}
                  />
              </Col>
              <Col sm="3" xs="6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    loadEmails();
                  }}
                  className="btn btn-success flex-2 w-100"
                >
                  <i className='flex justify-content-center align-items-center'>
                    <AiOutlineSearch size={18}/>{" "}Search
                  </i>
                </button>
              </Col>

            </Row>
          </Form>
          <DataTable
            columns={columns}
            data={mails || []}
            pagination={
              {
                show: true,
                onPageClicked: setPage,
                totalItemsCount: count,
                pageIndex: page,
                pageSize: maximumPerPage,


              }
            }
            emptyState={
              <div className={"text-center"}>
                <EmptyListState
                  title={"There is no sent Emails"}
                />
              </div>
            }
          />

        </CardBody>

      </MainCard>
    <SendEmail toggle={() => setShowSendEmail(prevState => !prevState)} isOpen={showSendEmail} onUpdate={loadEmails}/>

    </div>
  );

}
export default Mails;