export const DndItemTypes = {
    TABLE_HEADER: "TableHeader",
    TABLE_ROW: "TableRow"
};

export const DefaultGeo = {
    latitude: 24.7255553,
    longitude: 46.5423441,
    accuracy: 0,
    speed: null,
    heading: null,
    altitudeAccuracy: null,
    altitude: null
} as any;

// Cannot find name 'Coordinates'.

export const DefaultPageSize = 20;

export const Urls = {
    ACCESS_PAGE_DEFAULT_URL: "https://ems.ptit.com.sa"
};