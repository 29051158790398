import {getPaymentGateways, getPaymentMethods, PaymentGateway, PaymentMethodMetadata} from "api/PaymentGatewaysApi";
import {DefaultPageSize} from "components/Constants";
import {MainCard} from "components/MainCard";
import * as React from "react";
import {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useStore} from "react-redux";
import {CardHeader} from "reactstrap";
import {Loader} from "../../components/Loader";
import {FiEdit3, FiExternalLink} from "react-icons/fi";
import {EditPaymentGateway} from "./EditPaymentGateway";
import "./ManagePaymentGatewaysComponent.css";
import {FaCircle} from "react-icons/fa";

export const ManagePaymentGateways: React.FC = props => {
    const dispatch = useDispatch();
    const store = useStore();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(DefaultPageSize);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [editPG, setEditPg] = React.useState(false);

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodMetadata[]>([]);
    const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);
    const [paymentGatewayToEdit, setPaymentGatewayToEdit] = useState<PaymentGateway>();

    const fetchPaymentGateways = () => {
        getPaymentGateways().then(pgw=>setPaymentGateways([...pgw])).catch(err=>{});
    }
    useEffect(()=> {
        fetchPaymentGateways();
        getPaymentMethods().then(pms => {
            if (pms.items) {
                setPaymentMethods(pms.items);
            }
        });
    }, [])

    return (
        <div>
            <Helmet title="Payment Gateways"/>
            <div>
                <Helmet title="Payment Gateways" />
                {/*//         className="d-flex justify-content-between">*/}
                {/*//     <div>Payment Gateways</div>*/}
                {/*// </Helmet>*/}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 mt-4">
                            {
                                loading ?
                                    <Loader isLoading={true}/>
                                    : null
                            }
                            <ul style={{listStyle: 'none'}}>

                                <li className="mt-4">
                                    <div className="cat-title mx-3">
                                        <strong> Available Payment Gateways </strong>
                                    </div>
                                    <div className="services row mt-4">
                                        {
                                            paymentGateways && paymentGateways.length > 0 && paymentGateways.map((item: any, i: number) => {
                                                return (
                                                    <div key={i} className="col-md-2 col-sm-4 mx-3" style={{
                                                        padding:'0',
                                                        marginBottom:'15px',
                                                        border: item.isActive ? "1px solid #1c8879" : "1px dashed #ddd",
                                                        boxShadow: item.isActive ? "0 0 26px rgb(28 136 121 / 12%)" : "none"
                                                    }}>
                                                        <div className="service-content" style={{
                                                            width:'100%'
                                                        }}>
                                                            {item.isProduction ?
                                                                <b style={{color: 'red'}}><FaCircle/>{item.name}</b>
                                                                :
                                                                <b>{item.name} </b>
                                                            }

                                                            <div className="service-img">
                                                                <img src={item.imageUrl ?? ""} />
                                                            </div>
                                                            <div className="service-details row">
                                                                <div className="col-sm-6 text-center external-link">
                                                                    <span> <FiExternalLink/> </span>
                                                                    <a href={item.website}
                                                                       target="_blank"> Site </a>
                                                                </div>
                                                                <div
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        setPaymentGatewayToEdit(item);
                                                                        setEditPg(true);
                                                                    }}
                                                                    className="col-sm-6 text-center edit-service">
                                                                    <span> <FiEdit3/> </span>
                                                                    Edit
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {paymentGatewayToEdit &&
                <EditPaymentGateway paymentGateway={paymentGatewayToEdit}
                                    close={(success?: boolean) => {
                                        setEditPg(false);
                                        if(success) fetchPaymentGateways();
                                    }}
                                    isOpen={editPG}
                                    paymentMethods={paymentMethods}/>
            }
        </div>
    );
};
