import { AddEditSessionRequest, addNewSession, Session, updateSessionById } from "api/Sessions";
import { searchSpeakers, Speaker } from "api/Speakers";
import { MediaFile } from "api/common/MediaFile";
import { StringTruncate } from "components/StringTruncate";
import { ToastMessage } from "components/ToastMessages";
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik";
import moment, { Duration } from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import { FaSpinner } from "react-icons/fa";
import { FormatOptionLabelMeta } from "react-select/base";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { nameof } from "util/nameof";
import * as yup from "yup";
import AsyncSelect from "react-select/async";

export interface AddEditSessionFormModalProps {
  eventId: number;
  isOpen: boolean;
  closeModal: () => void;
  editSession?: Session | null;
}

export interface AddEditSessionForm {
  id?: number;
  title: string | null;
  description: string | null;
  startDateTime: moment.Moment | null;
  endDateTime: moment.Moment | null;
  location: string | null;
  selectedSpeakers: SpeakerSelectItem[] | null;
}

export interface NotificationItem {
  id: number | null;
  duration: Duration;
  templateItem: { label: string; value: number } | null;
}

export interface LinkItem {
  displayName: string;
  primaryLink: boolean;
  link: string;
}

export interface SpeakerSelectItem {
  value: number;
  label: string;
  profilePic: MediaFile | null;
  bio: string | null;
}

export interface HandoutItem {
  name: string;
  description: string | null;
  previewFile: MediaFile | null;
  file: File | null;
}

export const AddEditSessionFormModal: React.FC<AddEditSessionFormModalProps> = props => {
  const mapSpeakerToSpeakerSelectItem = (
    speaker: Speaker
  ): SpeakerSelectItem => {
    return {
      label: `${speaker.title || ""} ${speaker.firstName
      } ${speaker.middleName || ""} ${speaker.lastName}`,
      value: speaker.id,
      bio: speaker.bio,
      profilePic: speaker.profilePicture || null
    };
  };

  const initialFormValue = () => {
    let result: AddEditSessionForm = {
      title: null,
      description: null,
      startDateTime: null,
      endDateTime: null,
      location: null,
      selectedSpeakers: [],
    };
    if (props.editSession) {
      const {
        speakers,
        startDateTime,
        endDateTime,
        ...restEditSession
      } = props.editSession;
      result = {
        startDateTime: moment(startDateTime),
        endDateTime: moment(endDateTime),
        selectedSpeakers:
          speakers && speakers.map(mapSpeakerToSpeakerSelectItem),
        ...restEditSession
      };
    }
    return result;
  }


// Define the types for SpeakerSelectItem, NotificationItem, HandoutItem, and LinkItem here

  const validationSchema = () =>
    yup.object().shape<AddEditSessionForm>({
      id: yup.number().notRequired(),
      title: yup.string().nullable().required("Session name is required."),
      description: yup.string().nullable(),
      startDateTime: yup
        .mixed<moment.Moment>()
        .nullable()
        .required("Session requires a start date time."),
      endDateTime: yup
        .mixed<moment.Moment>()
        .nullable()
        .required("Session requires an end date time."),
      location: yup.string().nullable(),
      selectedSpeakers: yup.array<SpeakerSelectItem>().nullable(),
    })
  const [selectedSpeakers, setSelectedSpeakers] = useState<any[]>([])

  const [selectSpeakerList, setSelectSpeakerList] = useState<any[]>([])

  useEffect(() => {
    if(props.isOpen) {
      fetchSpeaker();
      if (props.editSession) {
        setSelectedSpeakers(props.editSession.speakers.map(mapSpeakerToSpeakerSelectItem))
      }
    }
    return () => {
        setSelectedSpeakers([])
    }
    }
    , [props.isOpen]);
  const fetchSpeaker = () => {
    searchSpeakers().then(value => {
      setSelectSpeakerList(value.map(mapSpeakerToSpeakerSelectItem))
    })

  }
  const renderSpeakerOption =
    (
      option: SpeakerSelectItem,
      labelMeta: FormatOptionLabelMeta<SpeakerSelectItem>
    ) => (
      <div className="d-flex align-items-center">
        {option.profilePic ? (
          <img
            src={option.profilePic.fullUrl}
            width={30}
            height={30}
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        ) : (
          <div
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        )}
        <div>
          {option.label}
          <div className="text-muted small">
            <StringTruncate
              text={option.bio || ""}
              truncateSize={30}
            />
          </div>
        </div>
      </div>
    )
  const handleFormSubmit = async (
      values: AddEditSessionForm
    ) => {
      const {
        id,
        startDateTime,
        endDateTime,
        selectedSpeakers,
        description,
        ...others
      } = values;
      if (!endDateTime || !startDateTime) {
        return;
      }

      const isEdit = !!id;
      let endTime = moment(
        startDateTime.format("YYYY-MM-DD") +
        " " +
        endDateTime.format("HH:mm")
      );
      if (endTime.isBefore(startDateTime)) {
        endTime = endTime.add(1, "days");
      }
      const request: AddEditSessionRequest = {
        ...others,
        eventId: props.eventId,
        startDateTime: startDateTime.toISOString(),
        description: description ? description : null,
        endDateTime: endTime.toISOString(),
        speakersIds:
          selectedSpeakers ? selectedSpeakers.map(s => s.value):[],
      } as AddEditSessionRequest;


      try {
        if (isEdit) {
          await updateSessionById(id!, request);
        } else {
          await addNewSession(request);
        }
        toast.success(
          <ToastMessage>
            Session &quot;{others.title}&quot;{" "}
            {isEdit ? "Updated" : "Added"} successfully.
          </ToastMessage>
        );
        setSelectedSpeakers([])
        props.closeModal();
      } catch (e) {
        toast.error(
          <ToastMessage type="Error">
            Something went wrong while{" "}
            {isEdit ? "updating" : "adding"} &quot;{others.title}
            &quot;, please try again later.
          </ToastMessage>
        );
      }
    }

  const getValidTimes = function (dateTime: any) {
    if (!dateTime)
      return undefined;
    // date is today, so only allow future times


    return {
      hours: {
        min: dateTime.hours(),
        max: 23,
        step: 1,
      },
      minutes: {
        min: dateTime.minutes(),
        max: 59,
        step: 5,
      },
    };
  }

  const renderForm =
    (formProps: FormikProps<AddEditSessionForm>) => (
      <Form>
        <ModalBody>
          <FormGroup>
            <Label htmlFor={nameof<AddEditSessionForm>("title")}>
              Name
            </Label>
            <Field
              render={({ field }: FieldProps) => (
                <Input {...field} />
              )}
              name={nameof<AddEditSessionForm>("title")}
            />
            <ErrorMessage
              name={nameof<AddEditSessionForm>("title")}
              render={error => (
                <FormFeedback className="d-block">
                  {error}
                </FormFeedback>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label
              htmlFor={nameof<AddEditSessionForm>("description")}
            >
              Description
            </Label>
            <Field
              render={({ field }: FieldProps) => (
                <Input {...field} type="textarea"/>
              )}
              name={nameof<AddEditSessionForm>("description")}
            />
            <ErrorMessage
              name={nameof<AddEditSessionForm>("description")}
              render={error => (
                <FormFeedback className="d-block">
                  {error}
                </FormFeedback>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label
              htmlFor={nameof<AddEditSessionForm>("location")}
            >
              Place
            </Label>
            <Field
              render={({ field }: FieldProps) => (
                <Input {...field} />
              )}
              name={nameof<AddEditSessionForm>("location")}
            />
            <ErrorMessage
              name={nameof<AddEditSessionForm>("location")}
              render={error => (
                <FormFeedback className="d-block">
                  {error}
                </FormFeedback>
              )}
            />
          </FormGroup>

          <Row form>
            <Col>
              <FormGroup>
                <Label
                  htmlFor={nameof<AddEditSessionForm>(
                    "startDateTime"
                  )}
                >
                  Starts at
                </Label>
                <Field
                  render={({ field }: FieldProps) => (
                    <ReactDatetime
                      {...field}
                      onChange={(
                        dateTime: moment.Moment | string
                      ) => {
                        formProps.setFieldValue(
                          "startDateTime",
                          moment(dateTime)
                        );
                      }}
                    />
                  )}
                  name={nameof<AddEditSessionForm>(
                    "startDateTime"
                  )}
                />
                <ErrorMessage
                  name={nameof<AddEditSessionForm>(
                    "startDateTime"
                  )}
                  render={error => (
                    <FormFeedback className="d-block">
                      {error}
                    </FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label
                  htmlFor={nameof<AddEditSessionForm>(
                    "endDateTime"
                  )}
                >
                  Ends at
                </Label>
                <Field
                  render={({ field }: FieldProps) => (
                    <ReactDatetime
                      {...field}

                      // viewMode={"time"}
                      timeConstraints={getValidTimes(formProps.values.startDateTime)}

                      dateFormat=""
                      onChange={(
                        dateTime: moment.Moment | string
                      ) => {
                        formProps.setFieldValue(
                          "endDateTime",
                          moment(dateTime)
                        );
                      }}
                    />
                  )}
                  name={nameof<AddEditSessionForm>(
                    "endDateTime"
                  )}
                />
                <ErrorMessage
                  name={nameof<AddEditSessionForm>(
                    "endDateTime"
                  )}
                  render={error => (
                    <FormFeedback className="d-block">
                      {error}
                    </FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              Speakers
            </Label>
                <AsyncSelect
                  isClearable
                  isMulti
                  defaultOptions={selectSpeakerList}
                  value={selectedSpeakers}
                  onChange={(options) => {
                    setSelectedSpeakers(options as any[])
                    formProps.setFieldValue("selectedSpeakers",options)
                  }}
                  className="form-control flex-2"
                  placeholder="Select Speakers"
                  formatOptionLabel={renderSpeakerOption}
                  loadOptions={async (val) => {
                    return (await searchSpeakers(val)).map(mapSpeakerToSpeakerSelectItem);
                  }}

                />
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={formProps.isSubmitting}
          >
            {formProps.isSubmitting && (
              <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
            )}{" "}
            Save
          </Button>
        </ModalFooter>
      </Form>
    )
  return (
    <Modal isOpen={props.isOpen} unmountOnClose>
      <ModalHeader toggle={props.closeModal}>
        {props.editSession && props.editSession.id
          ? "Edit Schedule Item"
          : "Add Schedule Item"}
      </ModalHeader>

      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
        initialValues={initialFormValue()}
        render={renderForm}
      />
    </Modal>
  );
};
