import { getEventsDropdownlist } from "api/Events";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { FaSpinner } from "react-icons/fa";
import Select from 'react-select';
import { toast } from "react-toastify";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  PopoverProps,
} from "reactstrap";
import * as yup from "yup";
import { AddNewRecipientGroup } from "../../api/RecipientLists";
import { ToastMessage } from "../../components/ToastMessages";
import TooltipHintComponent from '../../components/TooltipHint/TooltipHintComponent';
import { nameof } from "../../util/nameof";

export interface AddRecipientGroupPopoverProps extends PopoverProps {
  eventId: number;
  onRecipientGroupAdded?: (recipientGroupId: number) => void;
}

export interface AddRecipientGroupForm {
  name: string;
  eventId?: number | null;
}

export const AddRecepientGroupPopover: React.FC<AddRecipientGroupPopoverProps> = (
  props
) => {
  const initialValues = useMemo<AddRecipientGroupForm>(
    () => ({
      name: "",
    }),
    []
  );

  // events dropdownlist.
  const [events, setEvents] = React.useState<Array<{ label: string, value: number }>>([]);
  React.useEffect(() => {
    getEventsDropdownlist().then(data => {

      const events: Array<{ label: string, value: number }> = data ? data.map(x => { return { label: x.name, value: x.id } }) : [];
      setEvents(events);
    })
  }, [setEvents])
  // end -> evnet dropdownlist
  const validationSchema = useMemo<yup.ObjectSchema>(
    () =>
      yup.object().shape<AddRecipientGroupForm>({
        name: yup.string().required("Group name is required."),
      }),
    []
  );
  const handleFormSubmit = useCallback(
    async (
      values: AddRecipientGroupForm,
    ) => {
      try {
        const recipientGroupResult = await AddNewRecipientGroup(
          values
        );
        toast.success(
          <ToastMessage>
            Recipient group &quot;{values.name} Added successfully.
          </ToastMessage>
        );

        if (props.isOpen) {
          props.toggle && (props.toggle as () => void)();
        }

        props.onRecipientGroupAdded &&
          props.onRecipientGroupAdded(recipientGroupResult.id);
      } catch (e) {

        toast.error(
          <ToastMessage type="Error">
            Something went wrong while adding {values.name}, Please try again
            later. Or Name is Exist Before
          </ToastMessage>
        );
      } 
    },
    [props.eventId, props.isOpen, props.toggle]
  );
  const renderForm = useCallback(
    (formProps: FormikProps<AddRecipientGroupForm>) => (
      <Form>
        <FormGroup>
          <Label>Group name</Label>
          <Input
            autoComplete="off"
            onChange={formProps.handleChange}
            onBlur={formProps.handleBlur}
            value={formProps.values.name}
            name={nameof<AddRecipientGroupForm>("name")}
            className="mb-2"
          />

          <ErrorMessage
            name={nameof<AddRecipientGroupForm>("name")}
            render={(error) => (
              <FormFeedback className="d-block">{error}</FormFeedback>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label> Events <small> (Optional) </small>
            <TooltipHintComponent text="You can define recepient group related to specific event only " />
          </Label>
          <Select
            isMulti={false}
            options={events}
            onChange={(option: any) =>
              formProps.setFieldValue(
                nameof<
                  AddRecipientGroupForm
                >("eventId"),
                option?.value
              )
            }
          />
        </FormGroup>

        <div className="space-between">
          <Button type="button" color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={formProps.isSubmitting}
          >
            {formProps.isSubmitting && (
              <span>
                <FaSpinner className="icon-spin" />
              </span>
            )}{" "}
            Create
          </Button>
        </div>
      </Form>
    ),
    [events]
  );



  return (
    <Popover {...props}>
      <PopoverHeader>Add recepient group</PopoverHeader>
      <PopoverBody>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={renderForm}
        />
      </PopoverBody>
    </Popover>
  );
};
