import {
  ConfigurationBody,
  deleteConfiguration,
  getConfiguration,
  updateConfiguration
} from "api/EmailConfiguration";
import { MainCard } from "components/MainCard";
import { ToastMessage } from "components/ToastMessages";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import * as React from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import CertificateContext from "../../Contexts/context";
import { nameof } from "../../util/nameof";
import "./email.css";

import { FaSpinner } from "react-icons/fa";
import {
  Button, CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";


export interface EmailConfigurationForm {
  [key: string]: any;
  server: string;
  senderEmail: string;
  senderName: string;
  password: string;
  port: number;
  userName: string;
  socketSecurity: { label: string; value: number };
}

export const EmailConfiguration: React.FC = (props) => {
  const dispatch = useDispatch();
  const params = useParams<{ eventId: string }>();
  const eventId = Number(params.eventId);
  const [hasCustomConfig, changeConfig] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<ConfigurationBody | null>(null);

  const context = React.useContext(CertificateContext);
  const formikRef = React.useRef<any | null>();

  const getConf = async () => {
    const res = await getConfiguration();
    changeConfig(res.hasCustomConfiguration);
    setConfig(res);
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue("port", res.port ?? 0);
      formikRef.current.setFieldValue("server", res.server ?? "");
      formikRef.current.setFieldValue("userName", res.userName ?? "");
      formikRef.current.setFieldValue("senderEmail", res.senderEmail ?? "");
      formikRef.current.setFieldValue("senderName", res.senderName ?? "");
      formikRef.current.setFieldValue(
        "socketSecurity",
        useSSLOptions.find((item) => item.label == res.socketSecurity)
      );
    }
  };

  React.useEffect(() => {
    getConf();
  }, [formikRef.current]);

  const initialValues = React.useMemo<EmailConfigurationForm>(
    () => ({
      server: "",
      senderEmail: "",
      senderName: "",
      password: "",
      port: 0,
      userName: "",
      socketSecurity: { value: 0, label: "None" },
    }),
    []
  );


  const handleSubmit = React.useCallback(
    async (
      values: EmailConfigurationForm,
      // actions: FormikActions<EmailConfigurationForm>
    ) => {
      try {
        const res = hasCustomConfig
          ? await updateConfiguration({
            ...values,
            socketSecurity: values.socketSecurity.value.toString(),
          })
          : (await deleteConfiguration(), getConf());
        toast.success(
          hasCustomConfig ? (
            <ToastMessage>
              Email Configuration Updated successfully.
            </ToastMessage>
          ) : (
            <ToastMessage>
              Email Configuration deleted successfully.
            </ToastMessage>
          )
        );
      } catch (e) {
        toast.error(
          <ToastMessage type="Error">
            Something went wrong, Please try again later.
          </ToastMessage>
        );
      } finally {
        // actions.setSubmitting(false);
      }
    },
    [hasCustomConfig, formikRef.current]
  );

  const labelStyle = {
    display: "block",
    padding: "15px 0",
  };

  const formStyle = {
    padding: "15px 0",
  };

  const useAuthenticationOptions = React.useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const useSSLOptions = React.useMemo(() => {
    return [
      { label: "None", value: 0 },
      { label: "SSL", value: 1 },
      { label: "TLS", value: 2 },
    ];
  }, []);

  const renderForm = React.useCallback(
    (formProps: FormikProps<EmailConfigurationForm>) => (
      <Form className="p-3">
        <Row>
          <Col xs={12}>
            <div style={formStyle}>
              <Row>
                <Col xs={12}>
                  <FormGroup check>
                    <Label style={labelStyle} check>
                      <Input
                        type="radio"
                        onChange={(e) => changeConfig(false)}
                        checked={!hasCustomConfig}
                        name="radio3"
                      />
                      Use Perfect Touch SMTP Server
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div style={formStyle}>
              <Row>
                <Col xs={12}>
                  <FormGroup check>
                    <Label style={labelStyle} check>
                      <Input
                        type="radio"
                        onChange={(e) => changeConfig(true)}
                        checked={hasCustomConfig}
                        name="radio3"
                      />
                      Use your SMTP Server
                    </Label>
                    {hasCustomConfig && (
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Host</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.server}
                              name={nameof<EmailConfigurationForm>("server")}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>("server")}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Port</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.port}
                              name={nameof<EmailConfigurationForm>("port")}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>("port")}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>User Name</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.userName}
                              name={nameof<EmailConfigurationForm>("userName")}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>("userName")}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Password</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.password}
                              name={nameof<EmailConfigurationForm>("password")}
                              type="password"
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>("password")}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Use Authentication</Label>
                            <Field
                              render={({ field }: FieldProps) => (
                                <Select
                                  {...field}
                                  onChange={(options) =>
                                    formProps.setFieldValue(
                                      nameof<EmailConfigurationForm>(
                                        "isAuthenticated"
                                      ),
                                      options
                                    )
                                  }
                                  options={useAuthenticationOptions}
                                />
                              )}
                              name={nameof<EmailConfigurationForm>(
                                "isAuthenticated"
                              )}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>(
                                "isAuthenticated"
                              )}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                          <FormGroup>
                            <Label>Use TLS/SSL</Label>
                            <Field
                              render={({ field }: FieldProps) => (
                                <Select
                                  {...field}
                                  onChange={(options) =>
                                    formProps.setFieldValue(
                                      nameof<EmailConfigurationForm>(
                                        "socketSecurity"
                                      ),
                                      options
                                    )
                                  }
                                  options={useSSLOptions}
                                />
                              )}
                              name={nameof<EmailConfigurationForm>(
                                "socketSecurity"
                              )}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>(
                                "socketSecurity"
                              )}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6}>
                          <FormGroup>
                            <Label>Sender Email</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.senderEmail}
                              name={nameof<EmailConfigurationForm>(
                                "senderEmail"
                              )}
                            />

                            <ErrorMessage
                              name={nameof<EmailConfigurationForm>(
                                "senderEmail"
                              )}
                              render={(error) => (
                                <FormFeedback className="d-block">
                                  {error}
                                </FormFeedback>
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6}>
                          <FormGroup>
                            <Label>Sender Name</Label>
                            <Input
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values.senderName}
                              name={nameof<EmailConfigurationForm>(
                                "senderName"
                              )}
                            />
                          </FormGroup>
                        </Col>

                      </Row>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="space-between mt-2">
          <Button
            type="submit"
            color="primary"
            disabled={formProps.isSubmitting}
            style={{ marginLeft: "auto" }}
          >
            {formProps.isSubmitting && (
              <span>
                <FaSpinner className="icon-spin" />
              </span>
            )}{" "}
            {hasCustomConfig ? "Update" : "Delete"}
          </Button>
        </div>
      </Form>
    ),
    [hasCustomConfig]
  );

  return (
    <>
      <Helmet title={"Email Config"} />
      <MainCard>
        <CardHeader>Email Config</CardHeader>
        <CardBody>
          <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              // validationSchema={hasCustomConfig ? ValidatorSchema : {}}
              render={renderForm}
              ref={formikRef as any}
          />
        </CardBody>
      </MainCard>
    </>
  );
};
