
import {configureStore} from "@reduxjs/toolkit";

import { combineReducers } from "@reduxjs/toolkit";
import { handleAuthStatusCodesMiddleware } from "./reduxMiddlewares/handleAuthStatusCodesMiddleware";
import { rootReducer } from "./store";
import {createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory} from "history";
import authReducer from './store/auth/authSlice';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const {
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({history: createBrowserHistory()});


const store = configureStore({
    reducer: combineReducers({
      ...rootReducer,
        auth: authReducer,
      router: routerReducer,
    }),
    middleware: (getDefaultMiddleware) =>
     getDefaultMiddleware({ serializableCheck: false})
         .concat(routerMiddleware)
         .concat(handleAuthStatusCodesMiddleware),
    // devTools: process.env.NODE_ENV === 'development',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;