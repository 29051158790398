import Axios from "axios";

export interface ServerDefinedDnsRecord{
    recordType: string;
    host: string;
    value: string;
}

export const getServerDefinedDnsRecords = async (): Promise<ServerDefinedDnsRecord[]> => {
    const result = await Axios.get("/api/DefinedDnsRecords");

    return result.data;
}