import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../api/common/PaginatedResult";
import {
    getPresentersForEvent,
    Presenter,
    PresentersForEventRequest
} from "../api/Presenters";
import { AppThunk } from "./index";

export interface EventPresentersListState {
    isBusy: boolean;
    eventId: number | null;
    error: string | null; //TODO: better error from server to all slices
    request: PresentersForEventRequest | null;

    result: PaginatedResult<Presenter> | null;
}

const initialState: EventPresentersListState = {
    isBusy: false,
    error: null,
    eventId: null,
    request: null,
    result: null
};

interface ReceiveEventPresentersListAction {
    eventId: number | null;
    error: string | null; //TODO: better error from server to all slices
    request: PresentersForEventRequest | null;

    result: PaginatedResult<Presenter> | null;
}

const EventPresentersList = createSlice({
    name: "EventPresentersList",
    initialState,
    reducers: {
        requestEventPresentersList(state, action) {
            state.isBusy = true;
        },
        receiveEventPresentersList(
            state,
            action: PayloadAction<ReceiveEventPresentersListAction>
        ) {
            state.isBusy = false;
            state.eventId = action.payload.eventId;
            state.request = action.payload.request;
            state.error = action.payload.error;
            state.result = action.payload.result;
        }
    }
});

export default EventPresentersList.reducer;

const {
    requestEventPresentersList,
    receiveEventPresentersList
} = EventPresentersList.actions;
export const loadPresentersForEvent = (
    eventId: number,
    request: PresentersForEventRequest
): AppThunk => async dispatch => {
    try {
        dispatch(requestEventPresentersList({}));
        const result = await getPresentersForEvent(eventId, request);
        dispatch(
            receiveEventPresentersList({
                result: result,
                eventId: eventId,
                error: null,
                request: request
            })
        );
    } catch (e) {
        dispatch(
            receiveEventPresentersList({
                request: request,
                eventId: eventId,
                error: "e.toString()",
                result: null
            })
        );
    }
};

export const reloadEventPresentersList = (): AppThunk => async (
    dispatch,
    getState
) => {
    const eventPresenterListState = getState().eventPresentersList;

    if (eventPresenterListState.eventId && eventPresenterListState.request) {
        dispatch(
            loadPresentersForEvent(
                eventPresenterListState.eventId,
                eventPresenterListState.request
            )
        );
    }
};
