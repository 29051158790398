import React from "react";
import {Badge, Card, CardBody, CardImg, CardText, CardTitle} from "reactstrap";
import {IPost} from "../../api/PostsApi";

interface PostPreviewProps {
    post: IPost | null;
}

export const PostPreview = ({post}: PostPreviewProps) => {
    return (post &&
        <Card>
            <CardBody>
                <CardTitle><strong>Title:</strong> {post.title}</CardTitle>
                <CardText>
                    <strong>Authors:</strong> {post.authors && post.authors.map(a=>a.displayName)}
                </CardText>
                <CardText>
                    <strong>Post Time:</strong> {post.postTime}
                </CardText>
                <CardText>
                    <strong>Category:</strong> {post.postCategory.name}
                </CardText>
                <CardText>
                    <strong>Comments:</strong> {post.commentsCount}
                </CardText>
                <CardText>
                    <strong>Likes:</strong> {post.likesCount}
                </CardText>
                <CardText>
                    <strong>Tags:</strong>{' '}
                    {post.tags && post.tags.map((tag) => (
                        <Badge key={tag.id} color="primary" className="mr-1">
                            {tag.name}
                        </Badge>
                    ))}
                </CardText>
                {post.media && (
                    <CardImg top src={post.media.fullUrl} alt="Post Image"/>
                )}
            </CardBody>
        </Card>
    )
}