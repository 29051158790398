import Axios from "axios";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface paymentModeRequest extends  PaginatedRequest{

}

export  interface IPaymentMode {
    id:number;
   name:string;
    description:string;
    isActive:boolean;
}


export const getPaymentMode = async (request: paymentModeRequest ) : Promise<PaginatedResult<IPaymentMode>> => {
    const result = await  Axios.get<PaginatedResult<IPaymentMode>>("api/PaymentMode" , {
        params: request
    });
    return result.data;
}


export const addPaymentMode = async (request: IPaymentMode) : Promise<any> => {
    const result = await Axios.post<any>(
        "/api/PaymentMode",
        request
    );

    return result.data;
}


export const editPaymentMode = async (id: number , request: IPaymentMode) : Promise<any> => {
    const  result = await Axios.put<any>(`/api/PaymentMode/${id}`, request);
    return result.data ;
}


export  const  deletePaymentMode = async (id: number) :Promise<any> => {
    const result = await Axios.delete<any>(`/api/PaymentMode/${id}`) ;
    return result.data;
}