import { Session } from "api/Sessions";
import emptyStateSessionImage from "assets/imgs/empty_state_sessions.svg";
import { EmptyListState } from "components/EmptyListState";
import { Loader } from "components/Loader";
import { LoaderContainer } from "components/Loader/LoaderContainer";
import { MainCard } from "components/MainCard";
import { TimeLine, TimeLineGroup } from "components/TimeLine";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { FaCalendarAlt, FaChevronDown, FaPlus, FaPrint } from "react-icons/fa";
import {
  Button,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { AddEditSessionFormModal } from "./AddEditSessionFormModal";
import { SessionItem } from "./SessionItem";
import { useReactToPrint } from "react-to-print";
import { getAgendaSessions } from "../../api/AgendaSessionApi";
import { getEvents } from "../../api/Events";

interface BetweenDateFilter {
  from?: moment.Moment;
  to?: moment.Moment;
}

export const ManageEventSessions: React.FC = props => {


  const [addEditSessionIsOpen, setAddEditSessionIsOpen] = useState(false);
  const [sessionToEdit, setSessionToEdit] = useState<Session | null>(null);
  const [
    betweenDateFilter,
    setBetweenDateFilter
  ] = useState<BetweenDateFilter | null>(null);
  const [sessionsList, setSessionsList] = useState<any[]>([])
  const [eventId, setEventId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)


  useEffect(() => {
    setLoading(true)
    fetchSessions().then(() => {
      setLoading(false)
    });

  }, [betweenDateFilter])

  const fetchSessions = () => {

    if (eventId) {
      return getAgendaSessions({
        eventId,
        FirstStartDate: betweenDateFilter?.from?.toISOString(),
        LastStartDate: betweenDateFilter?.to?.toISOString()
      }).then(value => {
        setSessionsList(value)
      })
    }

    return getEvents({}).then(result => {
      if (result.items.length > 0) {
        setEventId(result.items[0].id)
        getAgendaSessions({
          eventId: result.items[0].id,
          FirstStartDate: betweenDateFilter?.from?.toISOString(),
          LastStartDate: betweenDateFilter?.to?.toISOString()
        }).then(value => {
          setSessionsList(value)
        })
      }
    })

  }


  useEffect(() => {
    fetchSessions();
  }, [betweenDateFilter]);

  const handleSessionAddClicked = () => {
    setSessionToEdit(null);
    setAddEditSessionIsOpen(true);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => (componentRef as any).current,
  });

  const handleSessionEditClicked = useCallback(
    (session: Session) => {
      setSessionToEdit(session);
      setAddEditSessionIsOpen(true);
    },
    [setAddEditSessionIsOpen, setSessionToEdit]
  );

  const closeAddEditSessionModal = useCallback(() => {
    setAddEditSessionIsOpen(false);
    fetchSessions();
  }, []);
  const hasDateFilter = betweenDateFilter?.from && betweenDateFilter.to;


  return (
    <div>
      <Helmet title="Sessions"/>
      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <span>Schedule</span>
            <div>
              <Button
                onClick={handleSessionAddClicked}
                color="primary"
              >
                Add schedule item <FaPlus/>
              </Button>{" "}
              {(sessionsList && sessionsList.length >= 0)
              && <Button
                  onClick={handlePrint}
                  color="secondary"
              >
                  Print <FaPrint/>
              </Button>}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <LoaderContainer>
            <Loader isLoading={loading}/>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="align-items-center btn-icon" color={"outline-secondary"}>
                    <FaCalendarAlt/> Day:{" "}
                    {hasDateFilter && (
                      <span>
                                                {(betweenDateFilter && betweenDateFilter.from &&
                                                  betweenDateFilter.from.format(
                                                    "MMM DD YYYY"
                                                  )) ||
                                                "All"}
                                            </span>
                    )}
                    <FaChevronDown className="btn-icon__caret"/>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>
                      Date filter
                    </DropdownItem>

                    <DropdownItem
                      onClick={() =>
                        setBetweenDateFilter(null)
                      }
                    >
                      All
                    </DropdownItem>
                    {_(sessionsList).sortBy((a) =>
                      moment(a.startDateTime)).groupBy((s) => {
                      return moment(s.startDateTime).set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                      }).toISOString()
                    }).map((sessions, title) => title).value().map((date, index) => (<DropdownItem
                      onClick={() =>
                        setBetweenDateFilter({
                          from: moment(date),
                          to: moment(date).add(1, "days")
                        })
                      }
                    >
                      Day {index + 1}
                    </DropdownItem>))}

                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <TimeLine ref={componentRef}>
              {
                // !sessionsListState?.isFetching &&
                (!sessionsList || sessionsList.length <= 0)
                && (
                  <EmptyListState
                    image={emptyStateSessionImage}
                    title={"You have no Sessions yet"}
                    callToActionButton={{
                      text: "Add your first Session now!",
                      onClick: handleSessionAddClicked
                    }}
                  />
                )}
              {sessionsList &&
              _(sessionsList)
                .sortBy((a) =>
                  moment(a.startDateTime))
                .groupBy(s => {
                    return moment(s.startDateTime)
                      .local()
                      .format("dddd, MMM DD YYYY")
                  }
                )
                .map((sessions, title) => {
                  return (
                    <TimeLineGroup
                      key={title}
                      title={title}
                    >
                      {sessions.map(session => (
                        <SessionItem
                          key={session.id}
                          session={session}
                          onSessionEditClicked={
                            handleSessionEditClicked
                          }
                          onSessionDeleted={
                            fetchSessions
                          }
                        />
                      ))}
                    </TimeLineGroup>
                  )
                })
                .value()}
            </TimeLine>
          </LoaderContainer>
        </CardBody>
      </MainCard>

            <AddEditSessionFormModal
                eventId={eventId??0}
                isOpen={addEditSessionIsOpen}
                closeModal={closeAddEditSessionModal}
                editSession={sessionToEdit}
            />
        </div>
    );
};

ManageEventSessions.propTypes = {
  eventId: PropTypes.number.isRequired
};
