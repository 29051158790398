/* eslint-disable react/prop-types */
import { addRecipientToList, getRecipientListDropdownList, updateRecipientToList } from "api/RecipientLists";
import "intl-tel-input/build/js/utils.js";
import * as React from "react";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ToastMessage } from "../../components/ToastMessages";
import { AddRecepientGroupPopover } from "../ManageRecepientGroups";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export const AddRecepientModal = (props) => {
  const getValidationErrorFor = (phoneNumber) => {
    return window.intlTelInputUtils.getValidationError(phoneNumber, "SA");
  };
  const params = useParams();
  const eventId = Number(params.eventId);
  const [recepients, updateList] = useState([]);

  const getRecipientList = async () => {
    // const res = await getRecipientGroup(eventId);
    const data = await getRecipientListDropdownList();
    updateList(data);
  };

  React.useEffect(() => {
    getRecipientList();
  }, []);

  const submit = async (data, actions) => {
    const payload = {
      email: data.email,
      firstName: data.firstName,
      recipientListId:
        data && data.recepientId && data.recepientId.id
          ? data.recepientId.id
          : "",
      lastName: data.lastName,
      middleName: data.middleName,
      phoneNumber: data.phoneNumber,
    };

    try {
      if (props.defaultValues && props.defaultValues.id) {
        await updateRecipientToList(props.defaultValues.id, payload);
      } else {
        const res = await addRecipientToList(payload.recipientListId, payload);
      }
      toast.success(
        <ToastMessage>
          Recipient{" "}
          {props.defaultValues && props.defaultValues.id ? "Updated" : "Added"}{" "}
          successfully.
        </ToastMessage>
      );
      props.closeModal();
      props.getRecipients();
    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while{" "}
          {props.defaultValues && props.defaultValues.id
            ? "updating"
            : "adding"}{" "}
          &quot;
          {data.firstName}
          &quot;, please try again later.
        </ToastMessage>
      );
    } finally {
      // actions.setSubmitting(false);
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: null,
    recepientId: props.recepientId,
  };

  const validationSchema = React.useMemo(
    () =>
      yup.object({
        recepientId: yup.string().required(),
        firstName: yup.string().required("First Name is required"),
        middleName: yup.string().notRequired(),
        lastName: yup.string().notRequired(),
        email: yup
          .string()
          .required("email is required")
          .email("email should be a valid email"),
        phoneNumber: yup
          .mixed()
          .notRequired()
          .nullable()
          .test(
            "PhoneTooShort",
            "The phone number you entered is too short for the selected country.",
            (value) => (value ? getValidationErrorFor(value) !== 2 : true)
          )
          .test(
            "PhoneTooLong",
            "The phone number you entered is too long for the selected country.",
            (value) => (value ? getValidationErrorFor(value) !== 3 : true)
          )
          .test(
            "InvalidCountryCode",
            "The phone number you entered has invalid country code.",
            (value) => (value ? getValidationErrorFor(value) !== 1 : true)
          )
          .test(
            "NotANumber",
            "The phone number you entered is invalid.",
            (value) => (value ? getValidationErrorFor(value) !== 4 : true)
          ),
      }),
    []
  );

  const renderForm = (formProps) => {
    return (
      <Form>
        <ModalBody>
          <FormGroup>
            <Label>Recepient group</Label>
            <Row form>
              <Col>
                <Field
                  name="recepientId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={recepients}
                      onChange={(option) => {
                        formProps.setFieldValue("recepientId", option);
                      }}
                      placeholder="Select a group name"
                    />
                  )}
                />

                <ErrorMessage
                  name="recepientId"
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </Col>
              <Col xs={1}>
                <Button
                  type="button"
                  id="recepientGroup"
                  outline={!addRecepientGroupIsOpen}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <Row form>
            <Col xs={4}>
              <FormGroup>
                <Label>First name</Label>
                <Field
                  name="firstName"
                  render={({ field }) => (
                    <Input {...field} placeholder="first name" />
                  )}
                />
                <span className="error">
                  {" "}
                  <ErrorMessage
                    name="firstName"
                    render={(error) => (
                      <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                  />
                </span>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label>Middle name</Label>
                <Field
                  name="middleName"
                  render={({ field }) => (
                    <Input {...field} placeholder="middle name" />
                  )}
                />
                <ErrorMessage
                  name="middleName"
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label>Last name</Label>
                <Field
                  name="lastName"
                  render={({ field }) => (
                    <Input {...field} placeholder="last name" />
                  )}
                />
                <ErrorMessage
                  name="lastName"
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Email</Label>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Input {...field} placeholder="your email" />
                  )}
                />
                <ErrorMessage
                  name="email"
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Phone Number</Label>
                <Field
                  name="phoneNumber"
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      onPhoneNumberUpdated={(formattedPhoneNumber) => {
                        formProps.setFieldValue(
                          "phoneNumber",
                          formattedPhoneNumber
                        );
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  name="phoneNumber"
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={"space-between"}>
          <Button type="button" color="secondary" onClick={props.closeModal}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </ModalFooter>
      </Form>
    );
  };

  const handleRecepientGroupAdded = useCallback(getRecipientList, []);

  const [addRecepientGroupIsOpen, setAddRecepientGroupIsOpen] = useState(false);

  return (
    <Modal isOpen={props.isOpen} size="lg" unmountOnClose>
      <ModalHeader toggle={props.closeModal}>
        {(props.defaultValues && props.defaultValues.id && "Edit") || "Add"}{" "}
        Recepient
      </ModalHeader>

      <Formik
        render={renderForm}
        onSubmit={submit}
        initialValues={
          props.defaultValues && props.defaultValues.id
            ? props.defaultValues
            : initialValues
        }
        validationSchema={validationSchema}
      />

      <AddRecepientGroupPopover
        eventId={props.eventId}
        target="recepientGroup"
        isOpen={addRecepientGroupIsOpen}
        toggle={() => setAddRecepientGroupIsOpen((b) => !b)}
        onRecipientGroupAdded={handleRecepientGroupAdded}
        placement="bottom"
      />
    </Modal>
  );
};
