import * as React from "react";
import { useEffect, useState } from "react";
import { FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { QrScanner } from '@yudiel/react-qr-scanner';
import { checkIn, CheckinData } from "../../api/MembershipAreaSessionApi";
import { toast } from "react-toastify";
import { SearchTenantUsers } from "../../api/MembershipAreaApi";
import AsyncSelect from "react-select/async";

const ScanSessionTicket = (props: any) => {

  const [productId, setProductId] = useState<number>()
  const [user, setUser] = useState<any>()
  const [print, setPrint] = useState<boolean>(true);
  const [customers, setCustomers] = useState<any[]>([])
  useEffect(() => {
    SearchTenantUsers()
      .then(val => setCustomers(val))
      .catch(err => {
        console.error(err);
      })
    return () => {
      setProductId(undefined)
      setUser(undefined)
    }
  }, [props.open])

  const checkInAction = (option?: CheckinData) => {

    const data = option ?? { userId: user.id, orderProductId: productId } ;
    
    if (data) {
      checkIn(data)
        .then(() => {
          props.onUpdate();
          toast.success(`Product ${productId} checked in successfully`)
          if (print && props.openPrint) {
            const selectedSession = props.items.find((s: any) =>  s.product?.orderProductId === productId && (!user || s.user?.id === user.id))
            if (selectedSession) {
              props.close();
              props.openPrint(selectedSession);
            }
          }
        }).catch((error) => {
        toast.error(error.response?.data?.detail ?? "Error while Check-In")
      })
    } else {
      toast.error(`Id is Invalid.`)
    }
  }


  return (<Modal unmountOnClose isOpen={props.open} toggle={props.close}>
    <ModalHeader toggle={props.close}>
      Scan
    </ModalHeader>
    <ModalBody className="d-flex flex-column justify-content-center align-items-center gap-2">
      <InputGroup className="align-items-center gap-1">
        <Label className={"col-2 p-0 m-0"} for="sessionId">
          Product id
        </Label>
        <input
          value={productId}
          onChange={(e) => {
            const { value } = e.target;
            setProductId(Number.parseInt(value));
          }}
          type="number"
          className="form-control col-10"
          placeholder= 'Product id'
        />
      </InputGroup>
      <InputGroup className="align-items-center gap-1">
          <Label className={"col-2 p-0 m-0"} for="sessionId">
              Customer
          </Label>
          <AsyncSelect
              isClearable
              defaultOptions={customers}
              value={user}
              onChange={setUser}
              className="form-control flex-2"
              placeholder="Select Customer"
              getOptionValue={(o) => o.id}
              getOptionLabel={(o) => `${o.id}-${o.displayName}`}
              loadOptions={async (val) => {
                return await SearchTenantUsers({ text: val });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) } as any}
          />
      </InputGroup>

      <div className='w-100 d-flex flex-row justify-content-center align-items-center flex-column gap-2'>
        <QrScanner
          scanDelay={3000}
          onDecode={result => {
            const option :CheckinData= {securityStamp:result}
            checkInAction(option)
          }}/>
        <div className={'row w-100'}>
          <FormGroup switch className='col'>
            <Label>Print</Label>

            <Input
              type="switch"
              checked={print}
              onChange={e => {
                setPrint(e.target.checked)
              }}
            />
          </FormGroup>
        </div>
      </div>
      <ModalFooter className='w-100'>
        <button onClick={props.close} className="mx-2 btn btn btn-secondary">
          Cancel
        </button>
        <button onClick={() => checkInAction()}
                className="mx-2 btn btn-success">
          Check In
        </button>
      </ModalFooter>
    </ModalBody>
  </Modal>);
}

export default ScanSessionTicket;