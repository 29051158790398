import * as React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "./NotFound.css";

export const NotFound: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>404 Not Found</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="text-center mt-5">
                <div className="not-found-container mx-auto">
                    <h1 className="not-found-header">404</h1>
                    <p className="sub-header">
                        We are sorry, the page you are looking for couldn&apos;t
                        be found or no longer exist
                    </p>
                    <NavLink to="/" className="btn btn-primary mt-2">
                        Go back home
                    </NavLink>
                </div>
            </div>
        </>
    );
};
