import {Loader} from 'components/Loader';
import {ToastMessage} from 'components/ToastMessages';
import TooltipHintComponent from 'components/TooltipHint/TooltipHintComponent';
import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikProps} from "formik";
import {FaSpinner} from "react-icons/fa";
import {
    Button,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import {nameof} from 'util/nameof';
import {
    AddLocation,
    EditLocation,
    GetCitiesOfCountry,
    GetCountriesDropDownList,
    GetLocation,
    IAddEditLocation,
    ICity,
    ICountry
} from "../../api/LocationsApi";
import CKEditor from "../../components/SendEmail/CKEditor";
import "./AddEditLocation.css";
import MapContainer from "../../components/MapContainer/MapContainer";

const initialLocation: IAddEditLocation = {
    name: "",
    country: 194,
    cityId: 1,
    address: "Riyadh - Saudi Arabia",
    cityArea: "Riyadh - Saudi Arabia",
    points:{
        longitude: "",
        latitude: "",
    },
    description: "",
    isActive: false,
    media: null,
};


export const AddEditLocation: React.FC<any> = ({locationToEdit, isOpen, toggle}) => {
    const [location, setLocation] = useState<IAddEditLocation> (locationToEdit ? locationToEdit : initialLocation);

    const [selectedCountry, setSelectedCountry] = useState(194);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);

    const formikRef = useRef<FormikProps<any>>(null);

    const [actionInProgress, setActionInProgress] = useState(false);
    const [previewBannerUrl, setPreviewBannerUrl] = useState<string | null>(null);

    useEffect(()=>{
        if(locationToEdit && locationToEdit.id){
            GetLocation(locationToEdit.id).then(location => setLocation({...location, country: location.city.country.id, cityId: location.city.id}));
        }
    }, [locationToEdit]);

    useEffect(()=>{
        GetCountriesDropDownList().then(countries=>setCountries([...countries])).catch(err=>{});

        return()=>{
            setPreviewBannerUrl(null)
        }
    }, [])

    useEffect(()=>{
        if(selectedCountry){
            GetCitiesOfCountry(selectedCountry).then(cities=>setCities([...cities])).catch(err=>{});
        }
    }, [selectedCountry])

    const initialFormValues = useMemo<IAddEditLocation>(() => {
        let formValues: IAddEditLocation = initialLocation;

        if (locationToEdit) {
            formValues = { ...formValues, ...locationToEdit };
            if(locationToEdit.media && locationToEdit.media.fullUrl){
                setPreviewBannerUrl(locationToEdit.media.fullUrl)
            }
        } else {
            setPreviewBannerUrl(null)
        }
        return formValues;
    }, [locationToEdit]);

    const [loading, setLoading] = React.useState<boolean>(false);

    const changeContent = React.useCallback((content) => {
        formikRef.current?.setFieldValue("description", content);
        setLocation({...location, description: content})
    }, []);

    const handleSelectedPosition = (postion: any) => {
        const {lat, lng} = postion;

        const points = {
            latitude: lat,
            longitude: lng
        };
        setLocation({...location, points: points});

        formikRef.current?.setFieldValue("points", points);
    }
    const setLocationCountry = (op: ICountry) => {
        setSelectedCountry(op.id);
        formikRef.current?.setFieldValue("country", op.id);
        setLocation({...location, country: op.id, cityArea: op.nameEn, address: op.nameEn});
    }

    const setLocationCity = (op: ICountry) => {
        formikRef.current?.setFieldValue("cityId", op.id);
        formikRef.current?.setFieldValue("address", op.nameEn);
        formikRef.current?.setFieldValue("cityArea", op.nameEn);
        setLocation({...location, cityId: op.id, cityArea: op.nameEn, address: op.nameEn});
    }

    const addLocationHandler = async (
        values: IAddEditLocation,
    ) => {
        const locationRequest = {...location, ...values};

        setActionInProgress(true);
        let promise = null;
        let editMode = false;
        if(locationToEdit && locationToEdit.id){
            editMode = true;
            promise = EditLocation(locationToEdit.id, locationRequest)
        } else promise = AddLocation(locationRequest)

        promise.then(resp=>{
            toast.success(
                <ToastMessage>
                    Location {editMode ? "edited" :"added"} Successfully !
                </ToastMessage>)
            setActionInProgress(false);
            toggle();
        }).catch(err=>{
            setActionInProgress(false);
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while {editMode ? "editing" :"Adding"}{" "}
                    {location.name}, Please try again
                    later.
                </ToastMessage>
            );
        });
    }

    const handleImagesChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {

            if (!e.target.files || !formikRef.current) return;
            const file = e.target.files[0];
            formikRef.current.setFieldValue(
                nameof<IAddEditLocation>("media"),
                file
            );

            if (file.type.startsWith("image/")) {
                if (typeof FileReader !== "undefined") {
                    const fileReader = new FileReader();
                    fileReader.onloadend = () => {
                        setPreviewBannerUrl(fileReader.result as string);
                    };

                    fileReader.readAsDataURL(file);
                } else {
                    setPreviewBannerUrl(null);
                }
                formikRef.current
            } else {
                setPreviewBannerUrl(null);
            }
        },
        [formikRef.current],
    )

    const form = (formProps: FormikProps<any>) => (
        <Form>
            <ModalBody>


                <FormGroup>
                    <Field
                        name={nameof<IAddEditLocation>("isActive")}
                        render={({ field }: FieldProps) => (
                            <InputGroup>
                                <Label>
                                    Active
                                </Label>
                                <Input
                                    type="switch"
                                    label="Active"
                                    id={field.name}
                                    checked={field.value}
                                    {...field}
                                />
                            </InputGroup>

                        )}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("isActive")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />
                </FormGroup>

                <FormGroup>
                    <Label> Name* </Label>
                    <Field
                        name={nameof<IAddEditLocation>("name")}
                        render={({ field }: FieldProps) => (
                            <Input {...field} />
                        )}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("name")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />
                </FormGroup>

                <FormGroup>
                    <Label> Country </Label>
                    <Select
                        defaultValue={
                            {
                                "id": 194,
                                "nameEn": "Saudi Arabia",
                                "nameAr": "المملكة العربية السعودية",
                                "isoAlpha2": "SA",
                                "isoAlpha3": "SAU",
                                "key": "966"
                            }
                        }
                        value={countries.filter(c=>c.id === location.country)}
                        isMulti={false}
                        options={countries}
                        onChange={(o: any) => {
                            setLocationCountry(o);
                        }}
                        getOptionLabel={(option) => option.nameEn}
                        getOptionValue={(option) => option.id +""}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("cityId")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />
                </FormGroup>
                <FormGroup>
                    <Label> City </Label>
                    <Select
                        isMulti={false}
                        options={cities}
                        value={cities.filter(c=>c.id === location.cityId)}
                        onChange={(o: any) => {
                            setLocationCity(o);
                        }}
                        getOptionLabel={(option) => option.nameEn}
                        getOptionValue={(option) => option.id +""}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("cityId")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />
                    <MapContainer setSelectedPosition={handleSelectedPosition} points={location.points}/>
                </FormGroup>


                <FormGroup>
                    <Label> Description* </Label>

                    <CKEditor
                        content={formProps.values.description}
                        changeContent={changeContent}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("description")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />
                </FormGroup>

                <FormGroup>
                    <label> Location Images
                        <TooltipHintComponent text="images of product will display in your site." />
                    </label>

                    <Input
                        type="file"
                        value={undefined}
                        accept="image/*"
                        onChange={handleImagesChange}
                    />
                    <ErrorMessage
                        name={nameof<IAddEditLocation>("media")}
                        render={error => (
                            <FormFeedback className="d-block">
                                {error}
                            </FormFeedback>
                        )}
                    />

                </FormGroup>

                {previewBannerUrl && (
                    <div
                        className="rounded thumbnail img-container"
                        style={{ height: 265,}}
                    >
                        <div className="d-none remove-container">
                            <button
                                onClick={e => {
                                    setPreviewBannerUrl(null);
                                    formikRef.current && formikRef.current.setFieldValue(
                                        nameof<IAddEditLocation>("media"),
                                        null
                                    );
                                }}
                                className="remove-btn btn btn-danger">
                                <AiOutlineDelete />
                            </button>
                        </div>
                        <img
                            src={previewBannerUrl}
                            className="rounded thumbnail"
                            style={{ height: 265, width: "100%"  }}
                        />
                    </div>
                )}
            </ModalBody>
            <ModalFooter className="space-between">
                <Button type="button" color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    disabled={actionInProgress}
                >
                    {actionInProgress && (
                        <span>
                            <FaSpinner className="icon-spin" />
                        </span>
                    )}{" "}
                    Save
                </Button>
            </ModalFooter>
        </Form>
    );

    return (
        <div>
            {
                loading ?
                    <Loader isLoading={true} />
                    : null
            }
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Add Location</ModalHeader>
                <Formik
                    innerRef={formikRef}
                    onSubmit={addLocationHandler}
                    // validationSchema={LocationFormValidation}
                    initialValues={initialFormValues}>
                    {form}
                </Formik>
            </Modal>
        </div>
    );
}