/* eslint-disable react/no-unescaped-entities */
import moment from "moment";
import * as React from 'react';
import ReactDatetime from "react-datetime";
import { AiOutlineArrowLeft, AiOutlineSetting } from "react-icons/ai";
import Select from "react-select";
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { IWebinarRecurrenceDto, MonthlyWeek, RecurrenceType, WeeklyDays } from "../../api/WebinarApi";

interface IWebinarRecurrenceSettingProps {
    isOpen: boolean;
    close?: () => unknown;
    onConfirm: (data: IWebinarRecurrenceDto) => unknown;
}

interface IWebinarRecurrenceSettingState {
    data: IWebinarRecurrenceDto,
    isMaxIntervalValid: boolean,
    maxIntervalWarningMsg?: string | null,
    isMonthlyDayValid: boolean;
    displayEndTimes: boolean;
}

class WebinarRecurrenceSetting extends React.Component<IWebinarRecurrenceSettingProps, IWebinarRecurrenceSettingState> {
    constructor(props: IWebinarRecurrenceSettingProps) {
        super(props);
        this.state = {
            data: {
                type: RecurrenceType.Daily,
                monthlyDay: 0,
                monthlyWeek: MonthlyWeek.FirstWeek,
                monthlyWeekDay: WeeklyDays.Sunday,
                repeatInterval: 0,
                weeklyDays: []
            },
            isMaxIntervalValid: true,
            maxIntervalWarningMsg: null,
            isMonthlyDayValid: true,
            displayEndTimes: false
        }
    }

    checkMaxIntervalHandler = (e: any) => {
        e.preventDefault();
        let isValid = true;
        let warningMsg = '';
        const value = +e.target.value;
        switch (this.state.data.type) {
            case RecurrenceType.Daily:
                //max =>  90
                if (value > 90) {
                    isValid = false;
                    warningMsg = "For a daily webinar, the maximum interval you can set is 90"
                }
                break;
            case RecurrenceType.Monthly:
                //max =>  3
                if (value > 3) {
                    isValid = false;
                    warningMsg = "For a monthly webinar, the maximum interval you can set is 3"
                }
                break;
            case RecurrenceType.Weekly:
                //max =>  12
                if (value > 12) {
                    isValid = false;
                    warningMsg = "For a weekly webinar, the maximum interval you can set is 12"
                }
                break;
        }
        this.setState({ isMaxIntervalValid: isValid, maxIntervalWarningMsg: warningMsg });

        if (isValid) {
            const { data } = this.state;
            data.repeatInterval = value;
            this.setState({ data })
        }
    }


    checkMonthlyDayValidHandler = (e: any) => {
        e.preventDefault();
        const value = +e.target.value;
        if (value < 1 || value > 31) {
            this.setState({ isMonthlyDayValid: false })
        } else {
            const { data } = this.state;
            data.monthlyDay = value;
            this.setState({ isMonthlyDayValid: true, data })
        }
    }

    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    <i> <AiOutlineSetting /> </i>  Recurrence Settings
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label> type: </Label>
                        </Col>
                        <Col>
                            <Input
                                type="switch"
                                label={'Daily'}
                                name={"daily"}
                                id={"type"}

                                onChange={e => {
                                    const { data } = this.state;
                                    data.type = RecurrenceType.Daily;
                                    this.setState({ data })
                                }}
                                checked={
                                    this.state.data.type == RecurrenceType.Daily
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                disabled={false}
                                id="InputType-switch2"
                                type="switch"
                                label={'Weekly'}
                                name={"weekly"}
                                onChange={e => {
                                    const { data } = this.state;
                                    data.type = RecurrenceType.Weekly;
                                    this.setState({ data })
                                }}
                                checked={
                                    this.state.data.type == RecurrenceType.Weekly
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                disabled={false}
                                id="InputType-switch"
                                type="switch"
                                label={'Monthly'}
                                name={"monthly"}
                                onChange={e => {
                                    const { data } = this.state;
                                    data.type = RecurrenceType.Monthly;
                                    this.setState({ data })
                                }}
                                checked={
                                    this.state.data.type == RecurrenceType.Monthly
                                }
                            />
                        </Col>
                    </Row>
                    <div className={'form-group mt-4'}>
                        <label> Repeat Interval </label>
                        <input type="number"
                            onBlur={this.checkMaxIntervalHandler}
                            className={'form-control'} name="repeatInterval" id="" />
                        <small>
                            Define the interval at which the webinar should recur
                            <b>
                                for example: if you'd like to schedule a webinar that recurs every two months,
                                you must set the value of this field as "2" and choose type "Monthly"
                            </b>
                        </small>

                        {
                            !this.state.isMaxIntervalValid ?
                                <div className={'alert alert-danger'}>
                                    {this.state.maxIntervalWarningMsg}
                                </div>
                                : null
                        }

                    </div>
                    {
                        this.state.data.type === RecurrenceType.Weekly ?

                            <div className={'form-group'}>
                                <label>
                                    Weekly days
                                </label>
                                <Select
                                    isMulti={true}
                                    options={
                                        [{ label: 'Saturday', value: '7' },
                                        { label: 'Sunday', value: '1' },
                                        { label: 'Monday', value: '2' },
                                        { label: 'Tuesday', value: '3' },
                                        { label: 'Wednesday', value: '4' },
                                        { label: 'Thursday', value: '5' },
                                        { label: 'Friday', value: '6' },
                                        ]
                                    }
                                    onChange={(vals: any) => {
                                        const values: Array<{ label: string, value: string }> = vals as Array<{ label: string, value: string }>;
                                        ;
                                        const weeklyDaysValues = values.map(x => x.value).join(',');
                                        const { data } = this.state;

                                        data.weeklyDays = weeklyDaysValues?.split(',').map(w => +w as WeeklyDays);
                                        this.setState({ data }, () => {
                                            ;
                                        });
                                    }}
                                />
                                <small>
                                    use this field to state which day(s) of the week the webinar should repeat.
                                </small>
                            </div> : null
                    }
                    {
                        this.state.data.type === RecurrenceType.Monthly ?
                            <React.Fragment>
                                <div className={'form-group'}>
                                    <label> Monthly Day </label>
                                    <input type={'number'}
                                        name={'monthlyDay'}
                                        onBlur={this.checkMonthlyDayValidHandler}
                                        className={'form-control'} />
                                    <small> Use this field only if <b> you'd like to repeat a meeting in specific day in month specific times. </b>
                                        For example: if you would like to recure on 3rd of each month set value 23 and "1" as the value of the repeat interval
                                    </small>
                                    {
                                        !this.state.isMonthlyDayValid ?

                                            <div className={'alert alert-danger'}>
                                                range of Montly day's value must between 1 - 31
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className={'form-group'}>
                                    <label > Monthly Week </label>
                                    <Select
                                        options={[
                                            { label: 'Last week of the month', value: '-1' },
                                            { label: 'First week of the month', value: '1' },
                                            { label: 'Second week of the month', value: '2' },
                                            { label: 'Third week of the month', value: '3' },
                                            { label: 'Fourth week of the month', value: '4' },
                                        ]}

                                        onChange={(val: any) => {
                                            const value = +val?.value;
                                            if (!Number.isNaN(value)) {
                                                const { data } = this.state;
                                                data.monthlyWeek = value;
                                                this.setState({ data }, () => {
                                                    ;
                                                })
                                            }


                                        }}
                                    />
                                    <small>
                                        if use this field you must also set value of <b> monthly week day </b>
                                    </small>
                                </div>

                                <div className={'form-group'}>
                                    <label > Monthly Week day </label>
                                    <Select
                                        isMulti={true}
                                        options={
                                            [{ label: 'Saturday', value: '7' },
                                            { label: 'Sunday', value: '1' },
                                            { label: 'Monday', value: '2' },
                                            { label: 'Tuesday', value: '3' },
                                            { label: 'Wednesday', value: '4' },
                                            { label: 'Thursday', value: '5' },
                                            { label: 'Friday', value: '6' },
                                            ]
                                        }
                                        onChange={(val: any) => {
                                            const value = +val?.value;
                                            if (!Number.isNaN(value)) {
                                                const { data } = this.state;
                                                data.monthlyWeekDay = value;
                                                this.setState({ data }, () => {
                                                    ;
                                                })
                                            }
                                        }}
                                    />
                                    <small>
                                        you must also use <b> montly week  </b>
                                    </small>
                                </div>
                            </React.Fragment>
                            : null
                    }

                    <div className="form-group d-flex justify-content-center">
                        <div className="form-check">
                            <label className="form-check-label mr-4"
                                htmlFor="exampleCheck1"> End Times </label>
                            <input type="radio"
                                checked={this.state.displayEndTimes}
                                onChange={e => {
                                    const { data } = this.state;
                                    data.endDateTime = null;
                                    data.endTimes = null;
                                    if (e.target.checked) {
                                        this.setState({ displayEndTimes: true, data })
                                    } else {
                                        this.setState({ displayEndTimes: false, data })
                                    }
                                }}
                                className="form-check-input" name={'session'} />
                        </div>

                        <div className="form-check">
                            <label className="form-check-label mr-4"

                                htmlFor="exampleCheck1"> End Date Time </label>
                            <input type="radio"
                                checked={!this.state.displayEndTimes}

                                onChange={e => {
                                    const { data } = this.state;
                                    data.endDateTime = null;
                                    data.endTimes = null;

                                    if (e.target.checked) {
                                        this.setState({ displayEndTimes: false, data })
                                    } else {
                                        this.setState({ displayEndTimes: true, data })

                                    }
                                }}
                                className="form-check-input" name={'session'} />
                        </div>
                    </div>


                    {
                        this.state.displayEndTimes ? <div className={'form-group'}>
                            <label > End times </label>
                            <input value={this.state.data.endTimes ?? 0} type="number"
                                onChange={e => {
                                    const value = +e.target.value;
                                    const { data } = this.state;
                                    if (Number.isNaN(value) || value === 0) {
                                        data.endTimes = null;
                                        this.setState({ data })
                                        return;
                                    }
                                    if (data.endDateTime != null) {
                                        alert("can not user end times with end date times")
                                        return;
                                    }
                                    data.endTimes = value;
                                    this.setState({ data })
                                }}
                                className={"form-control"} name="endTimes" id="" />
                            <small>
                                Select how many times the webinar will recure before it Cancelled
                                <b> Cannot be uses with end date time </b>
                            </small>
                        </div> :
                            <div className={'form-group'}>
                                <label > End date times </label>
                                <ReactDatetime

                                    value={this.state.data.endDateTime ?? ""}
                                    onChange={(
                                        dateTime: moment.Moment | string
                                    ) => {
                                        const date = moment(dateTime).toJSON();
                                        let data = this.state.data;
                                        if (data.endTimes != null) {
                                            alert("can not user end times with end times")
                                            return;
                                        }
                                        if (data) {
                                            data.endDateTime = date;
                                        }

                                        this.setState({ data: data }, () => {
                                            ;
                                        })
                                    }}
                                />
                                <small>
                                    Select how many times the webinar will recure before it Cancelled
                                    <b> Cannot be uses with end date time </b>
                                </small>
                            </div>
                    }



                    <div className="form-group mt-4  d-flex ">
                        <button className="btn btn-light  mx-1 flex-grow-1"
                            onClick={this.props.close}
                        >
                            <i > <AiOutlineArrowLeft /> </i>
                            Back </button>
                        <button
                            onClick={e => {
                                this.props?.onConfirm?.(this.state.data);
                            }}
                            className="btn btn-primary mx-1 flex-grow-1" > Save </button>

                    </div>
                </ModalBody>

            </Modal>
        );
    }
}

export default WebinarRecurrenceSetting;