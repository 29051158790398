import { Session, deleteSessionById } from "api/Sessions";
import { FilePreview } from "components/FilePreview";
import { TimeLineItem } from "components/TimeLine";
import { ToastMessage } from "components/ToastMessages";
import humanizeDuration from "humanize-duration";
import moment from "moment";
import * as React from "react";
import { useCallback, useState } from "react";
import { FaEdit, FaSpinner, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

export interface SessionItemProps {
    session: Session;
    onSessionEditClicked: (session: Session) => void;
    onSessionDeleted?: (sessionId: number) => unknown;
}

export const SessionItem: React.FC<SessionItemProps> = props => {
    const { session } = props;

    const dispatch = useDispatch();

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const sessionStartTime = moment(session.startDateTime);
    const sessionEndTime = moment(session.endDateTime);
    const sessionDuration = moment.duration(
        sessionEndTime.diff(sessionStartTime)
    );

    const handleSessionDelete = useCallback(async () => {
        if (deleteInProgress) return;

        setDeleteInProgress(true);

        try {
            await deleteSessionById(session.id);
            toast.success(
                <ToastMessage>
                    Session &quot;{session.title}&quot; was deleted successfully.
                </ToastMessage>
            );
            setDeleteModalIsOpen(false);
            props.onSessionDeleted && props.onSessionDeleted(session.id);
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while deleting session &quot;
                    {session.title}&quot;, please try again later
                </ToastMessage>
            );
        } finally {
            setDeleteInProgress(false);
        }
    }, [
        props.session,
        deleteInProgress,
        setDeleteInProgress,
        setDeleteModalIsOpen
    ]);
    return (
        <>
            <TimeLineItem
                key={session.id}
                startTime={sessionStartTime}
                endTime={sessionEndTime}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <h6>
                            {(session as any).title} (
                            {humanizeDuration(
                                sessionDuration.asMilliseconds(),
                                {
                                    round: true,
                                    units: ["d", "h", "m"]
                                }
                            )}
                            )
                        </h6>
                        <p className="text-muted">{session.description}</p>
                        <p>
                            {session.speakers?.map(speaker => (

                                <>
                                <img
                                    src={
                                        speaker.profilePicture
                                            ? speaker.profilePicture.fullUrl
                                            : ""
                                    }
                                    title={`${speaker.title} ${speaker.firstName
                                        } ${speaker.middleName || ""} ${speaker.lastName
                                        }`}
                                    width={32}
                                    height={32}
                                    style={{
                                        margin: "0 -3px"
                                    }}
                                    className="rounded-circle border bg-white"
                                />
                                </>

                            ))}
                        </p>
                    </div>
                    <div className="ml-3 no-print">
                            <Button
                                color="primary"
                                outline={true}
                                onClick={_ =>
                                    props.onSessionEditClicked(props.session)
                                }
                            >
                                <FaEdit />
                            </Button>{" "}
                            <Button
                                color="danger"
                                outline={true}
                                onClick={_ => setDeleteModalIsOpen(true)}
                            >
                                <FaTrash />
                            </Button>
                    </div>
                </div>
            </TimeLineItem>

            <Modal
                toggle={() => setDeleteModalIsOpen(false)}
                isOpen={deleteModalIsOpen}
                unmountOnClose
            >
                <ModalHeader toggle={() => setDeleteModalIsOpen(false)}>
                    Delete session
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete &quot;
                    {session.title}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => setDeleteModalIsOpen(false)}>
                        No, keep &quot;
                        {session.title}
                        &quot;
                    </Button>
                    <Button
                        color="danger"
                        onClick={handleSessionDelete}
                        disabled={deleteInProgress}
                    >
                        {deleteInProgress && (
                            <span>
                                <FaSpinner className="icon-spin" />
                            </span>
                        )}{" "}
                        Yes, Delete &quot;
                        {session.title}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
