import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { Button } from "reactstrap";


const GoogleLoginButton = ({ children, onSuccess, onError, disabled }: any) => {

  const googleLogin = useGoogleLogin({
    onSuccess,
    onError,
    scope: 'https://www.googleapis.com/auth/gmail.send',
    flow: 'auth-code',
    redirect_uri:window.location.protocol + "//"+window.location.host
  });

  return <Button
    color="primary"
    onClick={() => googleLogin()}
    disabled={disabled}
  >{children}
  </Button>

}
export default GoogleLoginButton;