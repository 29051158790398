import { schema } from "normalizr";

export const abstractTypeSchema = new schema.Entity("abstractTypes");
export const abstractTypesListSchema = [abstractTypeSchema];

export const committeeGroupSchema = new schema.Entity("committeeGroups");
export const committeeGroupsListSchema = [committeeGroupSchema];

export const sponsorSchema = new schema.Entity("sponsors");
export const sponsorsListSchema = new schema.Array(sponsorSchema);

export const sponsorGroupSchema = new schema.Entity("sponsorGroups", {
    sponsors: sponsorsListSchema
});
export const sponsorGroupsListSchema = new schema.Array(sponsorGroupSchema);

export const sessionLinkSchema = new schema.Entity("sessionLinks", {});

export const speakersSchema = new schema.Entity("speakers", {});

export const handoutsSchema = new schema.Entity("handouts", {});

export const sessionNotificationsSchema = new schema.Entity(
    "sessionNotifications",
    {}
);

export const sessionSchema = new schema.Entity("sessions", {
    //links: Array(sessionLinkSchema),
    //speakers: Array(speakersSchema),
    //handouts: Array(handoutsSchema),
    //notifications: Array(sessionNotificationsSchema)
});

export const notificationTemplateSchema = new schema.Entity(
    "notificationTemplates",
    {}
);

export const mobileAppSchema = new schema.Entity("mobileApps", {});

export const accessDomainSchema = new schema.Entity("accessDomains", {});

export const paymentGatewaySchema = new schema.Entity("paymentGateways", {});

export const registrationFormSchema = new schema.Entity("registrationForm", {});

export const productCategorySchema = new schema.Entity("productCategories", {});
export const ticketsSchema = new schema.Entity("tickets", {
    category: productCategorySchema
});

export const orderSchema = new schema.Entity("orders", {});

export const recipientGroupsListSchema = new schema.Entity(
    "recipientGroups",
    {}
);
