/* eslint-disable react/prop-types */
import {changePassword} from "api/ProfileSettings";
import {ToastMessage} from "components/ToastMessages";
import {ErrorMessage, Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as yup from "yup";
import {getCurrentUserProfile} from "../../api/userInfo";
import {useNavigate} from "react-router-dom";

const ListSentModal = (props) => {
    const {isOpen, toggle} = props;
    const params = useParams();
    const [userId, setUserId] = useState()
    const navigate = useNavigate()


    const [listData, setResults] = React.useState({});

    const initialValues = React.useMemo(
        () => ({
            oldPassword: "",
            password: "",
            confirmPassword: "",
        }),
        []
    );
    useEffect(() => {
        getCurrentUserProfile().then(value => {

            setUserId(value.id)

        }).catch(reason => {
            navigate("/login")
            toast.error("User not connected")
        })
    })

    const ValidatorSchema = React.useMemo(
        () =>
            yup.object().shape({
                oldPassword: yup.string().required("Old password is required"),
                password: yup
                    .string()
                    .required("Please Enter your password")
                    .matches(
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&._-])[A-Za-z\d@$!%*?&._-]{8,}$/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    ),
                confirmPassword: yup
                    .string()
                    .required()
                    .oneOf([yup.ref("password"), null], "Passwords must match"),
            }),
        []
    );

    const formikRef = useRef();

    const renderForm = (formProps) => (
        <><Form>
            <ModalBody>

                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Old Password</Label>
                            <Input
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values.oldPassword}
                                name="oldPassword"
                                type="password"
                            />

                            <ErrorMessage
                                name="oldPassword"
                                render={(error) => (
                                    <FormFeedback className="d-block">{error}</FormFeedback>
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>New Password</Label>
                            <Input
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values.password}
                                name="password"
                                type="password"
                            />
                            <span>
              Password must be at least 7 characters long and contain at least
              one capitalized letter.
            </span>

                            <ErrorMessage
                                name="password"
                                render={(error) => (
                                    <FormFeedback className="d-block">{error}</FormFeedback>
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Confirm New Password</Label>
                            <Input
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values.confirmPassword}
                                name="confirmPassword"
                                type="password"
                            />

                            <ErrorMessage
                                name="confirmPassword"
                                render={(error) => (
                                    <FormFeedback className="d-block">{error}</FormFeedback>
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle}>Cancel</Button>
                <Button type="submit" color="primary">
                    Change Password
                </Button>
            </ModalFooter>
        </Form>
        </>
    );

    const handleSubmit =
        async (values, actions) => {
            try {
                const payload = {
                    "userId": userId,
                    "oldPassword": values.oldPassword,
                    "newPassword": values.password,
                    "newPasswordConfirmation": values.confirmPassword
                }
                await changePassword(payload);
                toggle();
                toast.success(
                    <ToastMessage>Password changed successfully.</ToastMessage>
                );
            } catch (e) {
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong, Please try again later.
                    </ToastMessage>
                );
            } finally {
            }
        }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Change Password</ModalHeader>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={ValidatorSchema}
                    render={renderForm}
                    ref={formikRef}
                />
            </Modal>
        </div>
    );
};

export default ListSentModal;
