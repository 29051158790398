import { PaginatedResult, PaginatedResultV2 } from "./common/PaginatedResult";
import Axios from "axios";
import { OrderDto } from "./OrdersApi";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { AddEditSessionRequest, EventSessionRequest } from "./Sessions";

const agendaSessionPath = '/api/AgendaSessions'

export interface SessionDTO {
  eventId: number;
  title: string;
  description?: string|null;
  speakerId?: number;
  startDateTime: string; // or Date if you prefer to work with Date objects
  endDateTime: string; // or Date if you prefer to work with Date objects
  location?: string;

}

export interface Session {
  id: number;
  title: string;
  description: string;
  speaker: Speaker;
  startDateTime: string; // or Date for Date objects
  endDateTime: string; // or Date for Date objects
  location: string;
}

export interface Speaker {
  id: number;
  profilePicture: ProfilePicture;
  title: string;
  firstName: string;
  secondName: string;
  thirdName: string;
  lastName: string;
  bio: string;
  contact: ContactInfo;
  index: number;
  isOnHomePage: boolean;
}

export interface ProfilePicture {
  id: number;
  fileName: string;
  contentType: string;
  fullUrl: string;
  sizeInBytes: number;
}

export interface ContactInfo {
  phoneNumber: string;
  email: string;
  linkedIn: string;
  facebook: string;
  twitter: string;
  website: string;
}

export const getAgendaSessions = async (request:PaginatedRequest & EventSessionRequest):Promise<Session[]> => {
  const result = await Axios.get<Session[]>(
    '/api/AgendaSessions/Timeline',
    { params: request }
  );
  return result.data;
}

export const postAgendaSessions = async (session:AddEditSessionRequest) => {
  const result = await Axios.post<any>(
    agendaSessionPath,
    session
  );
  return result.data;
}

export const putAgendaSessions = async (id:number,session:Partial<AddEditSessionRequest>) => {
  const result = await Axios.put<PaginatedResultV2<Session>>(
    `${agendaSessionPath}/${id}`,
    session
  );
  return result.data;
}

export const deleteAgendaSessions = async (id:number) => {
  const result = await Axios.delete<PaginatedResultV2<Session>>(
    `${agendaSessionPath}/${id}`
  );
  return result.data;
}
export const getAgendaSessionById = async (id:number):Promise<Session> => {
  const result = await Axios.get<Session>(
    `${agendaSessionPath}/${id}`
  );
  return result.data;
}

export const searchAgendaSessions = async (text:string):Promise<Session[]> => {
  const result = await Axios.get<Session[]>(
    `${agendaSessionPath}/Search`,
    {params:{Text:text}}
  );
  return result.data;
}