import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { getAll, Recipient } from "../api/RecipientLists";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import { NormalizedPagination } from "./NormalizedPagination";
import { recipientGroupsListSchema } from "./Schemas";

export interface RecipientGroupsState {
  [key: string]: Recipient | {};

  pagination: {
    byEventId: NormalizedPagination;
  };
}

const initialState: RecipientGroupsState = {
  pagination: {
    byEventId: {},
  },
};

interface RequestRecipientGroupsPaginationAction {
  eventId: number;
}

interface ReceiveRecipientGroupsPaginationAction {
  eventId: number;
  error?: string;
  pageIndex?: number;
  pageSize?: number;
  totalItemsCount?: number;
  ids?: string[];
}

const recipientGroupListSlice = createSlice({
  name: "recipientGroups",
  initialState,
  reducers: {
    requestRecipientGroupPaginatedList(
      state,
      action: PayloadAction<RequestRecipientGroupsPaginationAction>
    ) {
      const { eventId } = action.payload;
      state.pagination.byEventId = {
        ...state.pagination.byEventId,
        [eventId.toString()]: {
          ...state.pagination.byEventId[eventId.toString()],
          isFetching: true,
        },
      };
    },
    receiveRecipientGroupPaginatedList(
      state,
      action: PayloadAction<ReceiveRecipientGroupsPaginationAction>
    ) {
      const { eventId, ...rest } = action.payload;
      state.pagination.byEventId = {
        ...state.pagination.byEventId,
        [eventId.toString()]: {
          ...state.pagination.byEventId[eventId.toString()],
          ...rest,
          isFetching: false,
        },
      };
    },
  },
  extraReducers: (builder => {
    builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
      return {
        ...state,
        ...action.payload["recipientGroups"],
      };
    })
  })
});

export default recipientGroupListSlice.reducer;
const {
  requestRecipientGroupPaginatedList,
  receiveRecipientGroupPaginatedList,
} = recipientGroupListSlice.actions;

export const fetchPaginatedRecipientGroupByEventId = (
  eventId: number,
  request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(
      requestRecipientGroupPaginatedList({
        eventId: eventId,
      })
    );

    const result = await getAll(request);

    const normalizedResult = normalize(
      result.items,
      Array(recipientGroupsListSchema)
    );

    dispatch(addEntities(normalizedResult.entities as any));

    dispatch(
      receiveRecipientGroupPaginatedList({
        eventId,
        ids: normalizedResult.result,
        pageIndex: result.meta.pageIndex,
        pageSize: result.meta.pageSize,
        totalItemsCount: result.meta.totalItemsCount,
      })
    );
  } catch (e) {
    dispatch(
      receiveRecipientGroupPaginatedList({
        eventId,
        error: "e.toString()",
        ids: getState().recipientGroups.pagination.byEventId[eventId.toString()]
          ?.ids,
        pageIndex: getState().recipientGroups.pagination.byEventId[
          eventId.toString()
        ]?.pageIndex,
        pageSize: getState().recipientGroups.pagination.byEventId[
          eventId.toString()
        ]?.pageSize,
        totalItemsCount: getState().recipientGroups.pagination.byEventId[
          eventId.toString()
        ]?.totalItemsCount,
      })
    );
  }
};
