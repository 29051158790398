import Axios from "axios";
import { IAddEditCurrency } from "../scenes/ManagePaymentGateways/AddEditCurrencyPaymentModal";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface  currencyRequest extends  PaginatedRequest {
}

export  interface  currency {
    id:number;
    code:string;
    symbol:string;
    isDefault:boolean;
}



export const getCurrency = async (request: currencyRequest ) : Promise<PaginatedResult<currency>> => {
    const result = await  Axios.get<PaginatedResult<currency>>("api/Currency" , {
        params: request
    });
    return result.data;
}

export const addCurrency = async (request: IAddEditCurrency) : Promise<any> => {
    const result = await Axios.post<any>(
        "/api/Currency",
        request
    );

    return result.data;
}


export const editCurrency = async (id: number , request: IAddEditCurrency) : Promise<any> => {
    const  result = await Axios.put<any>(`/api/Currency/${id}`, request);
    return result.data ;
}

export  const  deleteCurrency = async (id: number) :Promise<any> => {
    const result = await Axios.delete<any>(`/api/Currency/${id}`) ;
    return result.data;
}


export  const  changeCurrencyDefaultState = async (id:number) : Promise<any> => {
    const result = await Axios.put<any>(`/api/Currency/${id}/ChangeDefaultState`);
    return result.data; 
}


export const currencyDropdownList = async () : Promise<Array<currency>> =>  {
    const result = await Axios.get<Array<currency>>('/api/Currency/GetDropdownList');
    return result.data;
}