import React, { Component } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';

interface OwnProps {
    text: string;
}

type Props = OwnProps;
export default class TooltipHintComponent extends Component<Props> {

    render() {
        return (
            <span style={{ color: '#435560' }} data-tip={this.props.text} data-for="tooltip">
                <AiFillInfoCircle />
            </span>
        );
    }
}
