import React, { useEffect, useState } from "react";
import HtmlViewercomponent from "components/HtmlViewerComponent/html-viewer";
import { Loader } from "components/Loader";
import { AiOutlineSearch } from "react-icons/ai";
import { FaEllipsisH, FaEye, FaFileExport, FaPrint } from "react-icons/fa";
import {
  Badge,
  Button,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import { getAllOrders, getOrdersExportData, getOrderStatus, OrderDto, printOrderInvoice } from "../../api/OrdersApi";
import { MainCard } from "../../components/MainCard";
import "./ManageOrders.component.css";
import { Helmet } from "react-helmet";
import emptyStateSessionImage from "../../assets/imgs/empty_state.png";
import { EmptyListState } from "../../components/EmptyListState";
import { useLocation, useNavigate } from "react-router-dom";
import { formatSimpleDate } from "../../util/dateUtil";
import printJS from "print-js";
import { PaginationFooter } from "../../components/PaginationFooter";
import AddOrder from "./AddOrder";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import moment from "moment";
import DatePicker from "../../components/DatePicker/DatePicker";
import { SearchTenantUsers } from "../../api/MembershipAreaApi";
import { toast } from "react-toastify";

export const statusColor = (status: any) => {
  let color = "primary";
  if (status) {
    const statusName = status.name;
    switch (statusName) {
      case "Paid":
        color = "success";
        break;
      case "Checked":
        color = "success";
        break;
      case "Canceled":
        color = "secondary";
        break;
      case "Refunded":
        color = "warning";
        break;
      case "Unchecked":
        color = "warning";
        break;
      case "Refund Request":
        color = "info";
        break;
      default:
        color = "dark";
        break;
    }
  }

  return color;
}

export const ManageOrdersComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('status');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderDto[]>([]);
  const [showActionCardKey, setShowActionCardKey] = useState(-1);
  const [viewOrderTemplate, setViewOrderTemplate] = useState<string | null>(null);

  const [value, setValue] = useState<string>();
  const [searchKeys] = useState([
    { label: "Order Number", value: "OrderNumber" },
    { label: "Full Name", value: "FullName" },
    { label: "Phone Number", value: "PhoneNumber" },
  ]);
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maximumPerPage] = useState(20);
  const [status, setStatus] = useState<any>(undefined);
  const [clientId, setClientId] = useState<any>(undefined);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [orderStatus, setOrderStatus] = useState<any[]>([])
  const [customers, setCustomers] = useState<any[]>([])

  useEffect(() => {
    fetchData();
  }, [page,orderStatus]);

  const fetchData = () => {
    setLoading(true);
    getAllOrders({
      limit: maximumPerPage,
      pageIndex: page,
      text: value,
      statusId: status?.id,
      clientId:clientId?.id,
      fromDate: startDate ? moment(startDate).toISOString() : undefined,
      endDate: endDate ? moment(endDate).add(1,'day').subtract(1,'second').toISOString() : undefined
    }).then((data: any) => {
      setData(data.items);
      setCount(data.meta.totalItemsCount);
      setLoading(false);
    }).catch(err => {
      setLoading(false)
    });


  };
  useEffect(() => {
    getOrderStatus()
      .then(value1 => {
        if(query){
          const findStatus = value1.find(value2 => value2.name.toLowerCase() === query.toLowerCase())
          setStatus(findStatus)
        }
        setOrderStatus(value1);

      })
      .catch(err => {
        console.error(err);
      })

    SearchTenantUsers()
      .then(val => setCustomers(val))
      .catch(err => {
        console.error(err);
      })

  }, [])


  const viewOrderHandler = (orderCode: string) => {
    setLoading(true);
    navigate(`/Orders/${orderCode}`)

    // // getOrderTemplate(transactionId).then(
    // //     (data: { template: any; }) => {
    // //       ;
    // //       setViewOrderTemplate(data?.template ?? null);
    // //       setLoading(false);
    // //     },
    // //     (error: any) => {
    // //       setLoading(false);
    // //       toast.error(
    // //           <ToastMessage type="Error">
    // //             Something went wrong, please try again later
    // //           </ToastMessage>
    // //       );
    // //     }
    // );
    // setShowActionCardKey(-1);
  };

  const downloadOrderHandler = (transactionId: number) => {
    alert("download transaction id" + transactionId);
    setShowActionCardKey(-1);
  };

  const onChangeSearchValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setValue(value);
  };

  const onChangeSearchClientIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setClientId(value);
  };

  const handlePrint = (invoiceCode: string) => {
    printOrderInvoice(invoiceCode).then((response: any) => {
      printJS({ printable: response, type: 'raw-html' })
    });
  };

  const exportCSV= () =>{
    getOrdersExportData({
      text: value,
      statusId: status?.id,
      clientId:clientId?.id,
      fromDate: startDate ? moment(startDate).toISOString() : undefined,
      endDate: endDate ? moment(endDate).add(1,'day').subtract(1,'second').toISOString() : undefined
    }).then(value => {
      const url = window.URL.createObjectURL(new Blob(["\uFEFF"+value], { type: 'text/csv;charset=utf-8;' }))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Orders Export.csv`;
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(reason => {
      toast.error(reason.response.data?.detail ?? "Error while Exporting Attendances")
    })
  }

  return (
    <div>
      <Helmet title="Orders"/>
      {viewOrderTemplate ? (
        <HtmlViewercomponent
          isOpen={viewOrderTemplate != null}
          title={"Order"}
          close={() => {
            setViewOrderTemplate(null);
          }}
          html={viewOrderTemplate}
        />
      ) : null}
      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>Orders</div>
          <Button
            className={'d-flex align-items-center gap-2'}
            onClick={exportCSV}
            color="success"
          >
            Export<FaFileExport/>
          </Button>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="align-items-center mb-4">
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'Start Date'}
                    closeOnSelect
                    value={startDate}
                    onChange={setStartDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'End Date'}
                    closeOnSelect
                    value={endDate}
                    onChange={setEndDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup>
                  <AsyncSelect
                    isClearable
                    defaultOptions={customers}
                    value={clientId}
                    onChange={setClientId}
                    className="form-control flex-2"
                    placeholder="Select Customer"
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.displayName}
                    loadOptions={async (val) =>{
                      return await SearchTenantUsers({ text: val });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="12" className='my-1'>
                <InputGroup>
                  <Select
                    isClearable
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base:any) => ({ ...base, zIndex: 9999 }) } as any}
                    options={orderStatus}
                    value={status}
                    onChange={setStatus}
                    className="form-control flex-2"
                    placeholder="Select Status"
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.name}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup>
                  <input
                    value={value || ""}
                    onChange={onChangeSearchValueHandler}
                    type="text"
                    className="form-control flex-2"
                    placeholder="Search"
                  />
                </InputGroup>
              </Col >
              <Col sm="4" xs="6" className='my-1'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(1);
                    fetchData();
                  }}
                  className="btn btn-success w-100 "
                >
                  <i className='flex justify-content-center align-items-center'>
                    <AiOutlineSearch size={18}/>{" "}
                    Search

                  </i>
                </button>
              </Col>
            </Row>
          </Form>

          <React.Fragment>

            {!data || data.length === 0 ?
              <EmptyListState
                image={emptyStateSessionImage}
                title={"There are no orders"}
              />
              :

              <table className={"table table-response"}>
                <thead className="thead-dark">
                <tr>
                  <td>#</td>
                  <td>Customer</td>
                  <td>Phone Number</td>
                  <td>Email</td>
                  <td>Total Payment</td>
                  <td>Status</td>
                  <td>Date</td>
                  <td>Actions</td>
                </tr>
                </thead>
                <tbody>

                {data.map((item: any, i) => {
                  return (
                    <tr key={i}>
                      <td> {item.id} </td>
                      <td> {item.client?.displayName} </td>
                      <td> {item.client?.phoneNumber} </td>
                      <td> {item.client?.email} </td>
                      <td>{item.total} {" SAR"}</td>
                      <td><Badge style={{ width: '100%', height: '20px' }}
                                 color={statusColor(item.status)}>{item.status?.name}</Badge></td>
                      <td> {item.createDate ? formatSimpleDate(item.createDate) : ""} </td>
                      <td className="position-relative">
                        <UncontrolledButtonDropdown size="sm">
                          <DropdownToggle caret outline color="dark">
                            <FaEllipsisH/>
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem onClick={(e) => {
                              e.preventDefault();
                              viewOrderHandler(item.code)
                            }}>
                              <FaEye/> View Order
                            </DropdownItem>
                            {item.invoice && <DropdownItem onClick={() => handlePrint(item.invoice.code)}>
                                <FaPrint/> Print Invoice
                            </DropdownItem>}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>

                        {/*<Button*/}
                        {/*    size="sm"*/}
                        {/*    color={"success"}*/}
                        {/*    outline={true}*/}
                        {/*    onClick={(e) => {*/}
                        {/*      e.preventDefault();*/}
                        {/*      viewOrderHandler(item.transactionId);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*  <FiEye/>*/}
                        {/*</Button>{" "}*/}
                        {/*<Button*/}
                        {/*    size="sm"*/}
                        {/*    color={"primary"}*/}
                        {/*    outline={true}*/}
                        {/*    onClick={(e) => {*/}
                        {/*      e.preventDefault();*/}
                        {/*      downloadOrderHandler(item.transactionId);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*  <FiDownload/>*/}
                        {/*</Button>*/}
                      </td>
                    </tr>
                  );
                })}
                </tbody>
                <tfoot>
                <tr>
                  <td colSpan={8}>
                    <PaginationFooter
                      onPageClicked={setPage}
                      pageIndex={page}
                      totalItemsCount={count}
                      pageSize={maximumPerPage}
                      pageItemsCount={data.length}
                    />
                  </td>
                </tr>
                </tfoot>
              </table>
            }
          </React.Fragment>
          {loading ? <Loader isLoading={true}/> : null}
        </CardBody>
      </MainCard>
      <AddOrder isOpen={addOrderModal} setIsOpen={setAddOrderModal} onSubmit={fetchData}/>
    </div>
  );
};
