import {ContactInfo} from "api/common/ContactInfo";
import classNames from "classnames";
import {ToastMessage} from "components/ToastMessages";
import {ErrorMessage, Form, Formik, FormikProps} from "formik";
import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {FaSpinner} from "react-icons/fa";
import {toast} from "react-toastify";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {nameof} from "util/nameof";

import * as yup from "yup";
import {AddEditGlobalSponsorRequest, addSponsor, updateSponsor} from "../../api/GlobalSponsors";
import {PhoneInput} from "../../components/PhoneInput/PhoneInput";
import imgDefault from "../../assets/imgs/photos.svg";
import {IAddEditCourse} from "../../api/CoursesApi";

interface AddSponsorFormProps {
    isOpen: boolean;
    toggle: () => void;
    sponsorToEdit: any | null;
    onSponsorUpdated: (sponsorId: number) => any;
}

export const AddGlobalSponsorFormModal: React.FC<AddSponsorFormProps> = props => {

    const [initialValues, setInitialValues] = useState(props.sponsorToEdit ?? {
        name: '',
        description: '',
        logo: '',
        banner: '',
        contactInfo: {
            email: '',
            phoneNumber: '',
            linkedIn: '',
            faceBook: '',
            twitter: '',
            website: '',
        },
        isOnHomePage: false,
    })

    const formikRef = useRef<typeof Formik | null>(null);

    const addEditSponsorValidator = yup.object().shape<AddEditGlobalSponsorRequest>({
        name: yup.string().required(),
        description: yup.string(),
        logo: yup.string(),
        banner: yup.string(),
        contactInfo: yup.object().shape({
            email: yup.string(),
            phoneNumber: yup.string(),
            linkedIn: yup.string(),
            faceBook: yup.string(),
            twitter: yup.string(),
            website: yup.string(),
        }),
        isOnHomePage: yup.boolean(),
    });

    const [logoChanged, setLogoChanged] = useState(false);
    const [bannerChanged, setBannerChanged] = useState(false);
    const [previewLogo, setPreviewLogo] = useState<string | null>(null);
    const [previewBanner, setPreviewBanner] = useState<string | null>(null);

    const generateLogoPreview = (logo: File, fun: Function) => {
        if (!logo) return;
        const reader = new FileReader();

        reader.onloadend = () => {
            fun(reader.result as string);
        };

        if (logo.type.match("image.*")) {
            reader.readAsDataURL(logo);
        }
    };

    const initialFormValues = useMemo<AddEditGlobalSponsorRequest>(() => {
        let formValues: AddEditGlobalSponsorRequest = initialValues;

        if (props.sponsorToEdit) {
            if(props.sponsorToEdit.logo){
                setPreviewLogo(props.sponsorToEdit.logo.fullUrl)
            }
            if(props.sponsorToEdit.banner){
                setPreviewBanner(props.sponsorToEdit.banner.fullUrl)
            }
            formValues = {...formValues, ...props.sponsorToEdit, contactInfo: {...props.sponsorToEdit.contact}};
            return formValues;
        }
        return formValues;

    }, [props.sponsorToEdit]);

    useEffect(()=>{
        if(!props.isOpen){
            setPreviewLogo(null)
            setPreviewBanner(null);
        }
    }, [props.isOpen])

    const handleFormSubmit = async (
        values: AddEditGlobalSponsorRequest,
    ) => {
        try {
            const request: AddEditGlobalSponsorRequest = {
                sponsorId: values.sponsorId ?? null,
                logo: values.logo,
                banner: values.banner,
                name: values.name,
                description: values.description as string,
                contactInfo: values.contactInfo,
                isOnHomePage: values.isOnHomePage
            };
            if (props.sponsorToEdit?.id) {
                // update
                if(!logoChanged) request.logo = null;
                if(!bannerChanged) request.banner = null;
                await updateSponsor(props.sponsorToEdit.id, request);
                toast.success(
                    <ToastMessage>
                        Sponsor &quot;{values.name}&quot; updated successfully.
                    </ToastMessage>
                );
                props.onSponsorUpdated &&
                    props.onSponsorUpdated(props.sponsorToEdit.id);
            } else {
                const result = await addSponsor(request);
                toast.success(
                    <ToastMessage type="Success">
                        Sponsor &quot;{values.name}&quot; Added successfully.
                    </ToastMessage>
                );
                props.toggle();
                props.onSponsorUpdated && props.onSponsorUpdated(result.id);
            }
            props.toggle();
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    There were an issue{" "}
                    {values.sponsorId ? "Updating" : "Adding"} the sponsor
                </ToastMessage>
            );
        }
    };

    const renderForm = (formProps: FormikProps<AddEditGlobalSponsorRequest>) => (
        <Form>
            <ModalBody className="p-5 sponsors">
                <div>
                    {/*<h3 className="text-center mt-2">Sponsor information</h3>*/}
                    {/*<div className="border-bottom mb-3" />*/}
                    <Row className="uploads">
                        <Col sm={1} md={6} xl={6} className="img-upload">
                            <FormGroup>
                                <Label className="logoLabel" htmlFor="logo">
                                    Logo
                                    {previewLogo ? (
                                        <img
                                            src={previewLogo}
                                            className="img-thumbnail"
                                            style={{ maxHeight: "128px" }}
                                        />
                                    ) :
                                        <img
                                            src={imgDefault}
                                            className="img-thumbnail"
                                            style={{ maxHeight: "128px" }}
                                        />}
                                </Label>
                                <Input
                                    id="logo"
                                    type="file"
                                    style={{ display: "none" }}
                                    className={classNames({
                                        "is-invalid":
                                            formProps.errors.logo &&
                                            formProps.touched.logo
                                    })}
                                    onBlur={formProps.handleBlur}
                                    onChange={e => {
                                        if (!e.target.files) return;
                                        formProps.setFieldValue(
                                            nameof<AddEditGlobalSponsorRequest>("logo"),
                                            e.target.files[0]
                                        );
                                        setLogoChanged(true)
                                        generateLogoPreview(
                                            e.target.files[0],
                                            setPreviewLogo
                                        );
                                    }}
                                    name={nameof<AddEditGlobalSponsorRequest>("logo")}
                                />

                                <ErrorMessage
                                    name={nameof<AddEditGlobalSponsorRequest>("logo")}
                                    render={error => (
                                        <FormFeedback>{error}</FormFeedback>
                                    )}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={1} md={6} xl={6} className="img-upload">
                            <FormGroup>
                                <Label className="bannerLabel" htmlFor="banner">
                                    Banner
                                    {previewBanner ? (
                                        <img
                                            src={previewBanner}
                                            className="img-thumbnail"
                                            style={{ maxHeight: "128px" }}
                                        />
                                    ) :
                                        <img
                                        src={imgDefault}
                                        className="img-thumbnail"
                                        style={{ maxHeight: "128px" }}
                                        />}
                                </Label>
                                <Input
                                    id="banner"
                                    style={{ display: "none" }}
                                    type="file"
                                    className={classNames({
                                        "is-invalid":
                                            formProps.errors.banner &&
                                            formProps.touched.banner
                                    })}
                                    onBlur={formProps.handleBlur}
                                    onChange={e => {
                                        if (!e.target.files) return;
                                        formProps.setFieldValue(
                                            nameof<AddEditGlobalSponsorRequest>(
                                                "banner"
                                            ),
                                            e.target.files[0]
                                        );
                                        setBannerChanged(true)
                                        generateLogoPreview(
                                            e.target.files[0],
                                            setPreviewBanner
                                        );
                                    }}
                                    name={nameof<AddEditGlobalSponsorRequest>("banner")}
                                />
                                <ErrorMessage
                                    name={nameof<AddEditGlobalSponsorRequest>("banner")}
                                    render={error => (
                                        <FormFeedback>{error}</FormFeedback>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label>Name</Label>
                        <Input
                            className={classNames({
                                "is-invalid":
                                    formProps.errors.name &&
                                    formProps.touched.name
                            })}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            value={formProps.values.name}
                            name={nameof<AddEditGlobalSponsorRequest>("name")}
                        />
                        <ErrorMessage
                            name={nameof<AddEditGlobalSponsorRequest>("name")}
                            render={error => (
                                <FormFeedback>{error}</FormFeedback>
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input
                            type="textarea"
                            className={classNames({
                                "is-invalid":
                                    formProps.errors.description &&
                                    formProps.touched.description
                            })}
                            onBlur={formProps.handleBlur}
                            onChange={formProps.handleChange}
                            value={formProps.values.description ?? ""}
                            name={nameof<AddEditGlobalSponsorRequest>("description")}
                        />
                        <ErrorMessage
                            name={nameof<AddEditGlobalSponsorRequest>("description")}
                            render={error => (
                                <FormFeedback>{error}</FormFeedback>
                            )}
                        />
                    </FormGroup>
                    {/*<h3 className="text-center mt-2">Contact Details</h3>*/}
                    {/*<div className="border-bottom mb-3" />*/}

                    <FormGroup>
                        <Label>Email</Label>
                        <Input
                            className={classNames({
                                "is-invalid":
                                    formProps.errors.contactInfo &&
                                    formProps.errors.contactInfo.email &&
                                    formProps.touched.contactInfo &&
                                    formProps.touched.contactInfo.email
                            })}
                            onBlur={formProps.handleBlur}
                            onChange={formProps.handleChange}
                            value={formProps.values.contactInfo?.email ?? ""}
                            name={`${nameof<AddEditGlobalSponsorRequest>(
                                "contactInfo"
                            )}.${nameof<ContactInfo>("email")}`}
                        />

                        <ErrorMessage
                            name={`${nameof<AddEditGlobalSponsorRequest>(
                                "contactInfo"
                            )}.${nameof<ContactInfo>("email")}`}
                            render={error => (
                                <FormFeedback>{error}</FormFeedback>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Phone number</Label>
                        <PhoneInput
                            value={formProps.values.contactInfo.phoneNumber}
                            onPhoneNumberUpdated={(formattedPhoneNumber) => {
                                formProps.values.contactInfo.phoneNumber = formattedPhoneNumber??"";
                            }}
                        />

                        <ErrorMessage
                            name={`${nameof<AddEditGlobalSponsorRequest>(
                                "contactInfo"
                            )}.${nameof<ContactInfo>("phoneNumber")}`}
                            render={error => (
                                <FormFeedback>{error}</FormFeedback>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Website</Label>
                        <Input
                            className={classNames({
                                "is-invalid":
                                    formProps.errors.contactInfo &&
                                    formProps.errors.contactInfo.website &&
                                    formProps.touched.contactInfo &&
                                    formProps.touched.contactInfo.website
                            })}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            name={`${nameof<AddEditGlobalSponsorRequest>(
                                "contactInfo"
                            )}.${nameof<ContactInfo>("website")}`}
                            value={
                                (formProps.values.contactInfo &&
                                    formProps.values.contactInfo.website) ||
                                ""
                            }
                        />
                        <ErrorMessage
                            name={`${nameof<AddEditGlobalSponsorRequest>(
                                "contactInfo"
                            )}.${nameof<ContactInfo>("website")}`}
                            render={error => (
                                <FormFeedback>{error}</FormFeedback>
                            )}
                        />
                    </FormGroup>
                    <FormGroup switch>
                        <Label style={{marginRight: "5px"}}>Show on Home Page</Label>

                        <Input
                          type="switch"
                          name={nameof<AddEditGlobalSponsorRequest>("isOnHomePage")}
                          id={"isOnHomePage"}
                          checked={formProps.values.isOnHomePage}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                        <ErrorMessage
                          name={nameof<AddEditGlobalSponsorRequest>("isOnHomePage")}
                          render={error => (
                            <FormFeedback>{error}</FormFeedback>
                          )}
                        />
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter className={"space-between"}>
                <Button type="button" color="secondary" onClick={props.toggle}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    disabled={formProps.isSubmitting}
                >
                    {formProps.isSubmitting && (
                        <span>
                            <FaSpinner className="icon-spin" />
                        </span>
                    )}{" "}
                    Save
                </Button>
            </ModalFooter>
        </Form>
    );

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
            <ModalHeader toggle={props.toggle}>Add/Edit sponsor</ModalHeader>
            <Formik
                ref={formikRef}
                onSubmit={handleFormSubmit}
                // validationSchema={addEditSponsorValidator}
                initialValues={initialFormValues}
                render={renderForm}
            />

        </Modal>
    );
};
