import PropTypes from "prop-types";
import * as React from "react";
import { FaSpinner } from "react-icons/fa";
import "./Loader.css";

const loaderPropTypes = {
    isLoading: PropTypes.bool.isRequired
};

type LoaderProps = PropTypes.InferType<typeof loaderPropTypes>;

export const Loader: React.FC<LoaderProps> = props => {
    return (
        <>
            {props.isLoading && (
                <div className="table-loader">
                    <FaSpinner className="icon-spin" /> Loading please wait...
                </div>
            )}
        </>
    );
};

Loader.propTypes = loaderPropTypes;
