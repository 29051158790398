import React, {useEffect, useState} from 'react';
import {
    ConnectedService,
    ConnectedServiceByCategory,
    getServiceIntegration,
    IServiceConfigureInfo
} from 'api/ConnectServiceApi';
import Helmet from 'react-helmet';
import {Loader} from 'components/Loader';
import {FiEdit3, FiExternalLink} from "react-icons/fi";
import IntegrateWithServiceModalComponent from './IntegrateWithService.component';
import './ManageConnectServiceComponent.css';
import {ConfigureEmailService} from "./ConfigureEmailService";
import {ConfigureSmsService} from "./ConfigureSmsService";

interface ManageConnectServiceProps {
    // Add any props if needed
}

const ManageConnectServiceComponent: React.FC<ManageConnectServiceProps> = (props) => {
    const [data, setData] = useState<ConnectedServiceByCategory[]>([]);
    const [keysToIntegrate, setKeysToIntegrate] = useState<IServiceConfigureInfo[]>([]);
    const [serviceToIntegrateId, setServiceToIntegrateId] = useState<number>(0);
    const [serviceToIntegrateName, setServiceToIntegrateName] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedEmailService, setSelectedEmailService] = useState<ConnectedService | null>(null);
    const [selectedSmsService, setSelectedSmsService] = useState<ConnectedService | null>(null);

    useEffect(() => {
        setLoading(true);

        getServiceIntegration().then((data: ConnectedServiceByCategory[]) => {
            setLoading(false);
            if (data) {
                setData(data);
            }
        });
    }, []);

    const openModal = (serviceId: number, keys: IServiceConfigureInfo[], serviceName: string) => {
        if (serviceId > 0 && keys && keys.length > 0) {
            setServiceToIntegrateId(serviceId);
            setServiceToIntegrateName(serviceName);
            setKeysToIntegrate(keys);
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setServiceToIntegrateId(0);
        setKeysToIntegrate([]);
        setShowModal(false);
    };

    const onUpdatedHandler = () => {
        getServiceIntegration().then((data: ConnectedServiceByCategory[]) => {
            if (data) {
                setData(data);
            }
        });
    };

    return (
        <div>
            <Helmet title="Connect Services"/>
            {showModal ? (
                <IntegrateWithServiceModalComponent
                    isOpen={showModal}
                    onUpdated={onUpdatedHandler}
                    keys={keysToIntegrate}
                    serviceId={serviceToIntegrateId}
                    serviceName={serviceToIntegrateName ?? ""}
                    close={closeModal}
                />
            ) : null}

            {
                selectedEmailService &&
                <ConfigureEmailService name={selectedEmailService.serviceName}
                                       isOpen={true}
                                       close={(success) => {
                                           setSelectedEmailService(null);
                                           success && onUpdatedHandler();
                                       }}/>
            }

            {
                selectedSmsService &&
                <ConfigureSmsService name={selectedSmsService.serviceName}
                                       isOpen={true}
                                       close={(success) => {
                                           setSelectedSmsService(null);
                                           success && onUpdatedHandler();
                                       }}/>
            }
            {loading ? <Loader isLoading={true}/> :
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 mt-4">
                            <ul style={{listStyle: 'none'}}>
                                {data && data.length > 0 ? (
                                    data.map((item: ConnectedServiceByCategory, i: number) => (
                                        <li key={i} className="mt-4">
                                            <div className="cat-title mx-3">
                                                <strong> {item.categoryName} </strong>
                                            </div>
                                            <div className="services row mt-4">
                                                {item.services.length &&
                                                item.services.map((service: ConnectedService, i: number) => (
                                                    <div key={i} className="col-md-2 col-sm-4 mx-4" style={{
                                                        padding:'0',
                                                        border: service.isDefault ? "1px solid #1c8879" : "1px dashed #ddd",
                                                        boxShadow: service.isDefault ? "0 0 26px rgb(28 136 121 / 12%)" : "none"
                                                    }}>
                                                        <div className="service-content" style={{
                                                            width:'100%'
                                                        }}>
                                                            <div className="service-img">
                                                                <img src={service.imgUrl ? service.imgUrl : ""}/>
                                                            </div>
                                                            <div className="service-details row">
                                                                <div className="col-sm-6 text-center external-link">
                                                                    <span> <FiExternalLink/> </span>
                                                                    <a href={service.serviceUrl ? service.serviceUrl : "#"}
                                                                       target="_blank"> Site </a>
                                                                </div>
                                                                <div
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if(item.categoryName === "Email Services"){
                                                                            setSelectedEmailService(service)
                                                                        } else if(item.categoryName === "SMS Services"){
                                                                            setSelectedSmsService(service)
                                                                        } else
                                                                        openModal(service.id, [], service.serviceName);
                                                                    }}
                                                                    className="col-sm-6 text-center edit-service"
                                                                >
                                                                    <span> <FiEdit3/> </span>
                                                                    Edit
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                    ))
                                ) : null}
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ManageConnectServiceComponent;
