import Helmet from "react-helmet";
import { MainCard } from "../../components/MainCard";
import { Button, CardBody, CardHeader } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { Loader } from "../../components/Loader";
import { DataTable } from "../../components/DataTable";
import * as React from "react";
import { useEffect, useState } from "react";
import { CellProps } from "react-table";
import EditPage from "./EditPage";
import { dashboardPageType } from "../../api/DashboardApi";

const Pages = () => {
  const [loading, setLoading] = useState(true);
  const [pageTypes, setPageTypes] = useState<any[]>([])
  const [selectedType, setSelectedType] = useState<any>()
  const [showEditPage, setShowEditPage] = useState(false);

  const handleEditClicked = (type: any) => {
    setSelectedType(type);
    setShowEditPage(true);
  }

  const columns = [{
    Header: "#",
    Cell: (ctx: CellProps<any>) => {
      return ctx.row.index + 1
    }
  },
    {
      Header: "Name",
      accessor: "name",
      canSort: true
    },
    {
      Header: "Actions",
      Cell: function renderLocationActions(ctx: { row: { original: any; }; }) {
        const type = ctx.row.original;
        if (!type) return null;
        return (
          <div>
            <Button
              size="sm"
              color="primary"
              outline={true}
              onClick={(_) => handleEditClicked(type)}
            >
              <FaEdit/>
            </Button>{" "}
          </div>
        );
      },
    },
  ]
  useEffect(() => {
    dashboardPageType().then(value => {
      setPageTypes(value)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <Helmet title="Pages"/>
      <EditPage
        isOpen={showEditPage}
        toggle={() => {
          setShowEditPage(false)
          setSelectedType(undefined)
        }}
        type={selectedType}
      />

      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>Pages</div>
        </CardHeader>
        <CardBody>

          {loading ?
            <Loader isLoading={true}/>
            :
            <DataTable
              isBusy={false}
              columns={columns}
              data={pageTypes || []}
            />

          }
        </CardBody>

      </MainCard>


    </div>
  );

}


export default Pages;
