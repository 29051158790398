import Axios from "axios";

export interface UserInfoClaims {
    [key: string]: string;

    sub: string;
    email: string;
    picture: string;
    name: string;
}

export const getCurrentUserProfile = async (): Promise<any> => {
    const result = await Axios.get<any>('/api/TenantUser/CurrentUserProfile');
    return result.data;
}
export const getIdTypes = async (): Promise<any[]> => {
    const result = await Axios.get<any[]>('/api/TenantUser/IdTypes');
    return result.data;
}

export const getUserInfoClaims = async (): Promise<UserInfoClaims> => {


    const result = await Axios.get<any>('/api/TenantUser/CurrentUserProfile');
    const user = result.data;
    return {
        sub: user.id,
        email: user.email,
        picture: "string",
        name: user.firstName,

    };


    // const token = localStorage.getItem('accessToken');
    // const decodedToken = jwtDecode(token? token : "") as any;
    //
    // const { given_name, nameid, email } = decodedToken
    //
    // return {
    //     sub: "string",
    //     email: email,
    //     picture: "string",
    //     name: given_name
    // }
};
