/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { deleteRole, IRole } from "../../api/rolesApi";

import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";



interface IDeleteRoleProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    roleToDelete: IRole
}


interface IDeleteRoleState {
    __loading__: boolean;
    __id__: number;
}

class DeleteRoleModal extends Component<IDeleteRoleProps, IDeleteRoleState> {

    constructor(props: IDeleteRoleProps) {
        super(props);
        this.state = {
            __loading__: false,
            __id__: 0,
        }
    }

    componentWillMount() {

        if (this.props.roleToDelete) {
            this.setState({ __id__: this.props.roleToDelete.id });

        }
    }


    handleDeleting = () => {
        this.setState({ __loading__: true })
        deleteRole(this.state.__id__).then(r => {
            this.setState({ __loading__: false })
            toast.success(
                <ToastMessage type="Success">
                    Deleted Successfully !
                </ToastMessage>
            );
            if (this.props.onUpdated) {
                this.props.onUpdated();
            }
            if (this.props.cancelDeleting) {
                this.props.cancelDeleting();
            }

        }).catch(error => {
            this.setState({ __loading__: false })
            toast.error(
                <ToastMessage type="Error">
                    can not delete Role Has Sub tenants.
                </ToastMessage>
            );
        })
    }

    render() {
        return (
            <Modal size={"lg"} toggle={this.props.cancelDeleting} isOpen={!!this.props.roleToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Role
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ? <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.roleToDelete &&
                        `${this.props.roleToDelete.name || ""}`}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.roleToDelete &&
                            `${this.props.roleToDelete.name || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDeleting}>
                        Yes, Delete &quot;
                        {this.props.roleToDelete &&
                            `${this.props.roleToDelete.name || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteRoleModal;