import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface OwnProps {
  isOpen: boolean;
  close: () => void;
  title: string;
  html: string;
  footer?: React.ReactElement | null;
}

type Props = OwnProps;

class HtmlViewercomponent extends React.Component<Props> {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} size={"lg"} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>{this.props.title}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
        </ModalBody>
        {this.props.footer ? (
          <ModalFooter>{this.props.footer}</ModalFooter>
        ) : null}
      </Modal>
    );
  }
}

export default HtmlViewercomponent;
