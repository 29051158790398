import {Link, useLocation} from 'react-router-dom';
import PropTypes from "prop-types";
import React, {ReactNode} from 'react';

export interface SideNavItemProps {
    children: ReactNode;
    to: string;
    icon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
    exact?: boolean;
    classNames?: string;
    onClick?: (e: React.SyntheticEvent<any>) => any;
}

export const SideNavItem: React.FC<SideNavItemProps> = (props) => {
    const location = useLocation();
    const isActive = location.pathname.toUpperCase() === props.to.toUpperCase();

    return (
        <li className={"side-nav-item " + (props.classNames ? props.classNames : "")}>
            <Link
                to={props.to}
                className={"d-flex flex-row " + (isActive ? "active" : "")}
                role="button"
                onClick={ props.onClick ? props.onClick : (e) => {}}
            >
                <div style={{ width: "28px" }}> {props.icon}</div>
                <span className="side-nav-item__text flex-fill">{props.children}</span>
                <div className="side-nav-item__icon--end">{props.trailingIcon}</div>
            </Link>
        </li>
    );
};

SideNavItem.defaultProps = {
    exact: true,
};

SideNavItem.propTypes = {
    trailingIcon: PropTypes.node,
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    icon: PropTypes.node,
};
