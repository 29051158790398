import {ContactInfo} from "api/common/ContactInfo";
import "intl-tel-input/build/js/utils.js";
import * as yup from "yup";

enum validationError {
    IS_POSSIBLE = 0,
    INVALID_COUNTRY_CODE = 1,
    TOO_SHORT = 2,
    TOO_LONG = 3,
    NOT_A_NUMBER = 4
}

const getValidationErrorFor = (phoneNumber: string) => {
    // @ts-ignore
    return window.intlTelInputUtils.getValidationError(
        phoneNumber,
        "SA"
    ) as validationError;
};

export const ContactFormValidationSchema = yup
    .object()
    .nullable()
    .shape<ContactInfo>({
        email: yup.string().required('Email is required').email('Invalid email format'),
        phoneNumber: yup
            .mixed()
            .notRequired()
            .nullable()
            .test(
                "PhoneTooShort",
                "The phone number you entered is too short for the selected country.",
                value =>
                    value
                        ? getValidationErrorFor(value) !==
                          validationError.TOO_SHORT
                        : true
            )
            .test(
                "PhoneTooLong",
                "The phone number you entered is too long for the selected country.",
                value =>
                    value
                        ? getValidationErrorFor(value) !==
                          validationError.TOO_LONG
                        : true
            )
            .test(
                "InvalidCountryCode",
                "The phone number you entered has invalid country code.",
                value =>
                    value
                        ? getValidationErrorFor(value) !==
                          validationError.INVALID_COUNTRY_CODE
                        : true
            )
            .test(
                "NotANumber",
                "The phone number you entered is invalid.",
                value =>
                    value
                        ? getValidationErrorFor(value) !==
                          validationError.NOT_A_NUMBER
                        : true
            )
    });
