/* eslint-disable react/no-deprecated */
import { GetSessionReceipt, GetSessionReceiptHtmlView } from 'api/SmartAccessApi';
import { Loader } from 'components/Loader';
import React, { Component } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { FiMessageCircle } from "react-icons/fi";
import ReactToPrint from 'react-to-print';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AddNoteToSessionComponent from './AddNoteToSessionComponent';

interface OwnProps {
  close: () => void;
  isOpen: boolean;
  sessionId: number;
}

type Props = OwnProps;

export default class SessionReceiptComponent extends Component<Props, any> {
  componentToPrintRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      addNoteModal: false,
      html: null
    };
    this.componentToPrintRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.sessionId) {
      this.setState({ loading: true });
      GetSessionReceipt(this.props.sessionId).then(data => {
        this.setState({ loading: false, data });

      })

      GetSessionReceiptHtmlView(this.props.sessionId).then(data => {
        if (data) {
          this.setState({ html: data.html });

        }
      })
    }
  }

  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >
        <ModalHeader toggle={this.props.close}>Session Receipt</ModalHeader>
        <ModalBody >
          <div>
            {this.state.loading ? <Loader isLoading={true} /> : null}

            {
              this.state.addNoteModal && this.state.data?.sessionId ?
                <AddNoteToSessionComponent sessionId={this.state.data.sessionId}
                  sessionNumber={this.state.data.sessionNumber}
                  creatingDate={this.state.data.creatingDate}
                  close={() => {
                    this.setState({ addNoteModal: false });
                  }}
                  isOpen={true} />
                : null
            }
            {
              this.state.data ?
                <>
                  <div className="text-center" ref={this.componentToPrintRef}>
                    {/* <div>
                      <span> {this.state.data.membershipNumber} </span>
                    </div>


                    <div>
                      <h2> {this.state.data.fullName} </h2>
                    </div>


                    <div className="mt-2">
                      <span> Date:  </span>
                      <strong> {moment(this.state.data.creatingDate).format('MMMM DD YYYY')} </strong>
                    </div>

                    <div className="mt-2">
                      <span> Time:  </span>
                      <strong> {moment(this.state.data.creatingDate).format('hh:mm a')} </strong>
                    </div>

                    <div style={{ marginTop: '50px' } as React.CSSProperties}>
                      <div className="mt-4">
                        <img src={barcodeTest} alt="" width="70%" />
                      </div>
                      <div className="mt-2">
                        <strong>  {this.state.data.sessionNumber} </strong>
                      </div>
                    </div> */}

                    <div dangerouslySetInnerHTML={{ __html: this.state.html }}>

                    </div>

                  </div>

                  <div className="row footer-icons text-center">
                    <div className="col-sm-6">
                      <button onClick={e => {
                        e.preventDefault();
                        this.setState({ addNoteModal: true });
                      }}>
                        <i> <FiMessageCircle /> </i>
                        <span> Note </span>
                      </button>
                    </div>
                    <div className="col-sm-6">

                      <ReactToPrint
                        trigger={() => {

                          return (<button>
                            <i> <AiOutlinePrinter /> </i>
                            <span> Print </span>
                          </button>);
                        }}
                        content={() => this.componentToPrintRef.current}
                      />
                    </div>

                  </div>
                </>
                : null
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
