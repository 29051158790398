
import Axios from "axios";

export interface IServiceToIntegrate {
    id: number;
    serviceName: string;
    serviceUrl?: string | null;
    videoUrlForClarification?: string | null;
    imgUrl?: string | null;
    serviceConfigureInfo : Array<IServiceConfigureInfo>
}

export interface IServiceConfigureInfo {
    id:number;
    keyName: string;
    tenantConfigureServiceDto?: ITenantConfigureServiceDto | null;
}

export interface ITenantConfigureServiceDto {
    id:number;
    keyValue: string;
}

export interface ICategoryService {
    id:number;
    categoryName: string;
    serviceToIntegrates:Array<IServiceToIntegrate>
}

export interface IGetServiceIntegrationDto  {
    categoryServices: Array<ICategoryService>
}


export interface IPostIntegrateWithService {
    serviceId: number;
    keyValues: Array<IServiceKeyValuesPair>
}

export interface IServiceKeyValuesPair {
    keyId: number;
    keyName: string;
    keyValue: string;
}

export interface IEditTenantConfigureServiceModel {
    tenantConfigureServiceId?: number | null;
    keyValue?: string | null;
} 

export interface IEditTenantConfigureServiceModels {
    tenantConfigureServiceModels: Array<IEditTenantConfigureServiceModel>;
}


// export interface ConnectedService {
//     name: string;
//     imageUrl: string;
//     isEmailService: boolean;
// }
//
// export const getServiceIntegrationByType = async (type: string) : Promise<ConnectedService[]> => {
//     const result = await Axios.get<ConnectedService[]>(`api/ServiceIntegration/${type}`);
//     return result.data;
// }

// export const getServiceIntegration = async () : Promise<IGetServiceIntegrationDto> => {
//     const result = await Axios.get<IGetServiceIntegrationDto>(`api/ServiceIntegration/Meetings`)
//     return result.data;
// }

export interface ConnectedService {
    id: number;
    serviceName: string;
    isType: {
        id: number;
        name: string;
    };
    serviceUrl: string | null;
    videoUrlForClarification: string | null;
    imgUrl: string;
    isDefault: boolean;
}

export interface ConnectedServiceByCategory {
    id: number;
    categoryName: string;
    services: ConnectedService[];
}

export const getServiceIntegration = async () : Promise<ConnectedServiceByCategory[]> => {
    const result = await Axios.get<ConnectedServiceByCategory[]>("api/ServiceIntegration");
    return result.data;
}


export const integrateWithService = async (body: IPostIntegrateWithService): Promise<any> => {
    const result = await Axios.post("api/ServiceIntegration/Integrate", body);
    return result.data;
}

export const editIntegrateWithService = async (body: IEditTenantConfigureServiceModels): Promise<any> => {
    const result = await Axios.put("api/ServiceIntegration/EditIntegrate", body);
    return result.data;
}

export const postCiscoCode = async (code:string ) : Promise<any> => {
    const result = await Axios.post(`api/CiscoMeeting/Code?code=${code}`);
    return result.data;
}

export interface ICiscoMeetingInvitee {
    email:string,
    displayName:string,
    coHost:boolean
}
export interface ICreateCiscoMeetingModel {
    title: string;
    password?: string | null;
    startDateTime: Date,
   // endDateTime: Date,
    invitees : Array<ICiscoMeetingInvitee>,
    sessionId: number,
    meetingHours:number,
    timeZone:string,
    createSession:boolean,
    eventId?:number | null
}


export const createMeeting = async (payload : ICreateCiscoMeetingModel) : Promise<any> => {
    const result = await  Axios.post<ICreateCiscoMeetingModel>("api/CiscoMeeting/CreateMeeting", payload);
    return  result.data;
}

// delete meeting.

export  const deleteMeeting = async (id: number) : Promise<any> => {
    const result = await  Axios.delete(`api/CiscoMeeting/DeleteMeeting/${id}`);
    return result.data;
}


// Zoom Meeting

export interface ICreateZoomMeeting {
    topic:string;
    startTime:Date,
    durationInMin: number,
    eventId?: number | null,
    sessionId?: number | null,
    createSession:boolean,
    
}

export  const createZoomMeeting = async (payload: ICreateZoomMeeting) : Promise<any> => {
    const result = await  Axios.post("api/ZoomMeeting/CreateMeeting" , payload );
    return result.data;
}


// Email services

export interface GmailService {
    isDefault: boolean;
    clientId: string;
    clientSecret: string;
    applicationName: string;
    emailForTest?:string;
}

export const configureGmailService = async (payload: GmailService): Promise<any> => {
    const result = await  Axios.put("api/ServiceIntegration/Gmail" , payload );
    return result.data;
}

export const getGmailService = async (): Promise<any> => {
    const result = await  Axios.get("api/ServiceIntegration/Gmail");
    return result.data;
}

export const testGmail = async (payload: { emailForTest:string }): Promise<any> => {
    const result = await  Axios.post("api/ServiceIntegration/TestDefaultEmail",payload);
    return result.data;
}
export const testSms = async (payload: { mobileForTest:string }): Promise<any> => {
    const result = await  Axios.post("api/ServiceIntegration/TestDefaultSms",payload);
    return result.data;
}
export interface SmtpService {
    isDefault: boolean;
    server: string;
    port: number;
    senderName: string;
    senderEmail: string;
    password: string;
}

export const getEmtpService = async (): Promise<any> => {
    const result = await  Axios.get("api/ServiceIntegration/SMTP");
    return result.data;
}
export const configureEmtpService = async (payload: SmtpService): Promise<any> => {
    const result = await  Axios.put("api/ServiceIntegration/SMTP" , payload );
    return result.data;
}

// Sms services

export interface TwilioService {
    isDefault: boolean;
    accountSid: string;
    authToken: string;
    sender: string;
}

export const configureTwilioService = async (payload: TwilioService): Promise<any> => {
    const result = await  Axios.put("api/ServiceIntegration/Twilio" , payload );
    return result.data;
}

export const getTwilioService = async (): Promise<any> => {
    const result = await  Axios.get("api/ServiceIntegration/Twilio");
    return result.data;
}


export interface UnifonicService {
    isDefault: boolean;
    appsId: string;
    sender: string;
    basicAuthUserName: string;
    basicAuthPassword: string;
    encoding: string;
}

export const configureUnifonicService = async (payload: UnifonicService): Promise<any> => {
    const result = await  Axios.put("api/ServiceIntegration/Unifonic" , payload );
    return result.data;
}

export const getUnifonicService = async (): Promise<any> => {
    const result = await  Axios.get("api/ServiceIntegration/Unifonic");
    return result.data;
}