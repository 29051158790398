import { MediaFile } from "api/common/MediaFile";
import Axios from "axios";
import { objectToFormData } from "../util/objectToFormData";
import { Speaker } from "./Speakers";
import { IDropdownList } from "./common/DropdownList";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";
import { deleteAgendaSessions, postAgendaSessions, putAgendaSessions } from "./AgendaSessionApi";

export interface EventSessionRequest {
    eventId: number;
    FirstStartDate?: string;
    LastStartDate?: string;
}

export enum SessionIncludes {
    notifications = "Notifications"
}

export interface NotificationItemRequest {
    id: number;
    notifyBefore: string;
    templateId: number;
}

interface LinkItemRequest {
    displayName: string;
    link: string;
    primaryLink: boolean;
}

export interface HandoutItemRequest {
    name: string;
    description: string | null;
    file: File | null;
}

export interface AddEditSessionRequest {
    eventId?:number;
    title: string;
    description?: string | null;
    startDateTime: string;
    endDateTime: string;
    location?: string | null;
    SpeakersIds?: number[] | null;

}

export interface SessionLink {
    displayName: string;
    primaryLink: boolean;
    linkIcon: MediaFile | null;
    link: string;
}

export interface SessionNotificationTemplate {
    id: number;
    name: string;
    description: string | null;
    content: string;
    createDate: string;
}

export interface SessionNotification {
    id: number;
    notifyBefore: string;
    sentAt: string | null;
    hasBeenSent: boolean;
    template: SessionNotificationTemplate;
    showSpeakerPicture: boolean | null;
}

export interface SessionHandout {
    name: string;
    description: string | null;
    file: MediaFile | null;
}

export interface Session {
    id: number;
    title: string;
    description: string;
    startDateTime: string;
    endDateTime: string;
    location: string;
    speakers: Speaker[];
}


export const addNewSession = async (
    request: AddEditSessionRequest
): Promise<NewResourceResult<number>> => {
    return await postAgendaSessions(request);
};

export const updateSessionById = async (
    sessionId: number,
    request: AddEditSessionRequest
): Promise<void> => {
    delete request.eventId
    await putAgendaSessions(sessionId,request)


};
export const deleteSessionById = async (sessionId: number) => {
    await deleteAgendaSessions(sessionId);
};



// get session As dropdown list.

export interface ISessionDropdownList extends  IDropdownList{
    
}

export  const getSessionsDropdownList = async (eventId : number) : Promise<Array<ISessionDropdownList>> => {
    const result  = await Axios.get<Array<ISessionDropdownList>>(`api/sessions/Dropdownlist/${eventId}`);
    return result.data;
}