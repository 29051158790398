import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { PaginatedResult } from "../api/common/PaginatedResult";
import {
    getSponsorsWithGroups,
    SponsorsWithGroupsRequest,
    SponsorWithGroup
} from "../api/Sponsors";
import { AppThunk } from "./index";

export interface EventSponsorsState {
    isBusy: boolean;
    error: string | null;
    eventId: number | null;
    sponsors?: PaginatedResult<SponsorWithGroup>;
}

const initialState: EventSponsorsState = {
    isBusy: false,
    error: null,
    eventId: null
};

interface ReceiveEventSponsorsListAction {
    error: string | null;
    eventId: number | null;
    sponsors?: PaginatedResult<SponsorWithGroup>;
}

const EventSponsorGroupsList = createSlice({
    name: "EventSponsorsList",
    initialState,
    reducers: {
        requestEventSponsorsList(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveEventSponsorsList(
            state,
            action: PayloadAction<ReceiveEventSponsorsListAction>
        ) {
            state.isBusy = false;
            state.error = action.payload.error;
            state.sponsors = action.payload.sponsors;
            state.eventId = action.payload.eventId;
        }
    }
});

export default EventSponsorGroupsList.reducer;

const {
    requestEventSponsorsList,
    receiveEventSponsorsList
} = EventSponsorGroupsList.actions;

export const loadEventSponsorsList = (
    request: PaginatedRequest & SponsorsWithGroupsRequest
): AppThunk => async (dispatch, getState) => {
    try {
        if (getState().eventSponsorsList.isBusy) return;

        dispatch(requestEventSponsorsList());
        const result = await getSponsorsWithGroups(request);
        dispatch(
            receiveEventSponsorsList({
                error: null,
                sponsors: result,
                eventId: request.eventId
            })
        );
    } catch (err) {
        // dispatch(
        //     receiveEventSponsorsList({
        //         error: err.toString(),
        //         eventId: getState().eventSponsorsList.eventId,
        //         sponsors: getState().eventSponsorsList.sponsors
        //     })
        // );
    }
};
