import { getGlobalEmailHistory } from "api/SendEmail";
import moment from "moment";
import * as React from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { Button, CardBody, CardHeader } from "reactstrap";
import { DefaultPageSize } from "../../../components/Constants";
import { DataTable } from "../../../components/DataTable";
import { EmptyListState } from "../../../components/EmptyListState";
import { MainCard } from "../../../components/MainCard";
import ListSentModal from "../ListSentModel";
export const GlobalEmailHistory: React.FC = (props) => {
  const dispatch = useDispatch();
  const componentRef = React.useRef<any>();
  const [initialFormValues, setInitialFormValues] = React.useState<any>({});
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(DefaultPageSize);
  const [isOpen, changeIsOpen] = React.useState<boolean>(false);
  const [data, setPopupData] = React.useState<any>({});
  const [recepientId, setRecepientId] = React.useState<any>({});
  const [recipientListResult, setResults] = React.useState<any>({});
  const [addEditModalIsOpen, setAddEditModalIsOpen] = React.useState(false);

  const handleAddClicked = React.useCallback(() => {
    setInitialFormValues(undefined);
    setAddEditModalIsOpen(true);
  }, [setAddEditModalIsOpen]);


  const toggle = () => {
    changeIsOpen(!isOpen);
  };


  const openListPopup = (data: any) => {
    ;
    setPopupData(data);
    toggle();
  };

  const getRecipients = async () => {
    const res = await getGlobalEmailHistory({});
    if (res && res.items) {
      setResults(res);
    }
  };
  React.useEffect(() => {
    getRecipients();
  }, [pageIndex, pageSize, recepientId.id]);

  const handlePageChange = React.useCallback(
    (page) => {
      if (page !== pageIndex) {
        setPageIndex(page);
        setTimeout(() => {
          if (componentRef.current) {
            componentRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }
        }, 150);
      }
    },
    [pageIndex, componentRef]
  );


  const columns = React.useMemo(
    () => [
      {
        Header: "Recipents information",
        columns: [
          {
            Header: "Subject",
            accessor: "subject",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Send date",
            accessor: "createDate",
            Cell: (ctx: any) => {
              const date = ctx.cell.value;
              return (<span>{moment(date).format('YYYY MM DD')}</span>)
            }
          },
          {
            Header: "Schedule date ",
            accessor: "scheduledAt",
            Cell: (ctx: any) => {
              const date = ctx.cell.value;
              return (<span>{moment(date).format('YYYY MM DD')}</span>)
            }
          },
          {
            Header: "Actions",
            Cell: function renderTicketActions(ctx: any) {
              const ticket = ctx.row.original;

              return (
                <div>
                  {ticket && (
                    <Button
                      color="primary"
                      outline={true}
                      onClick={() => openListPopup(ticket)}
                    >
                      Show List
                    </Button>
                  )}
                </div>
              );
            },
          },
        ],
      },
    ],
    [recepientId, recipientListResult]
  );


  return (<div ref={componentRef}>
    <Helmet title="History" />
    <ListSentModal isOpen={isOpen} toggle={toggle} data={data} />
    <MainCard>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <span>Email History</span>
        </div>
      </CardHeader>
      <CardBody>
        <DataTable
          columns={columns}
          data={recipientListResult?.items ? recipientListResult.items : []}
          isBusy={false}
          emptyState={<EmptyListState title={"You have no emails"} />}
          pagination={{
            show: true,
            totalItemsCount:
              recipientListResult && recipientListResult.meta
                ? recipientListResult.meta.totalItemsCount
                : 0,
            pageIndex: pageIndex,
            pageSize: pageSize,
            onPageClicked: handlePageChange,
          }}
        />
      </CardBody>
    </MainCard>
  </div>);
}