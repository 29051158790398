import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Button } from "@mui/material";
import { logout } from "../../store/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../configureStore";

const ForbiddenPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center w-100">
      <Row className="text-center">
        <Col>
          <FaExclamationTriangle size={100} className="text-warning"/>
          <h1 className="mt-4">403 Forbidden</h1>
          <p>You do not have permission to access this page.</p>
          <Button
            onClick={(e) => {

            dispatch(logout())
            navigate("/login")
          }
          } color="primary"
          >
            Sign out
          </Button>
        </Col>
      </Row>
    </Container>
  );
}


export default ForbiddenPage;