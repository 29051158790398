/* eslint-disable react/jsx-key */
import {Loader} from 'components/Loader';
import * as React from 'react';
import {useState} from 'react';
import Helmet from "react-helmet";
import {AiOutlineSearch} from 'react-icons/ai';
import {FaEllipsisH, FaEye, FaHistory, FaPrint, FaRegComment} from 'react-icons/fa';
import {
    Badge,
    ButtonDropdown,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    InputGroup,
    Row,
    UncontrolledButtonDropdown
} from "reactstrap";
import {MainCard} from "../../components/MainCard";
import WindowClickEventComponent from '../../components/RegisterClickWindowEvent/WindowClickEventComponent';
import {SmartAccessComponent} from "../ManageTenantUsers/SmartAccessComponent";
import emptyStateTicketImage from "../../assets/imgs/empty_state_sessions.svg";
import {EmptyListState} from "../../components/EmptyListState";
import {getAllTickets} from "../../api/MembershipAreaTicketsApi";
import {statusColor} from "../ManageOrders/ManageOrders.component";
import {formatSimpleDate} from "../../util/dateUtil";

export const ManageTickets: React.FC = props => {

    const [Tickets, setTickets] = React.useState<Array<any>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showActionCardKey, setShowActionCardKey] = React.useState<number>(-1);
    const [barCodeTicketView, setBarCodeTicketView] = React.useState<{ TicketId: number, TicketNumber: string } | null>(null);

    const [searchValue, setSearchValue] = React.useState<string | null>(null);
    const [addEditCommentData, setAddEditCommentData] = React.useState<{ TicketId: number, TicketNumber: string } | null>(null);
    const [toggleCheckInTicket, setToggeCheckInTicket] = React.useState<{ TicketId: number, TicketNumber: string, wantCheckIn: boolean } | null>(null);
    const [showSmartAccess, setShowSmartAccess] = useState(false);

    const searchKeys: { label: string, value: string } [] = React.useMemo(() =>
        [
            {label: 'Ticket Number', value: 'TicketNumber'},
            {label: 'User Name', value: 'FullName'},

        ], [])
    const getTicketsHandler = React.useCallback(async () => {
        setLoading(true);
        const result = await getAllTickets({ text: searchValue ?? undefined});
        setLoading(false);
        setTickets(result?.items ?? []);
    }, [setLoading, setTickets, searchValue, searchValue]);

    React.useEffect(() => {
        getTicketsHandler();
    }, [])

    const OpenAddEditTicketComment = React.useCallback((TicketId: number, TicketNumber) => {
        setAddEditCommentData({TicketId, TicketNumber});
    }, [setAddEditCommentData])

    const toggleShowSmartAccess = () => {
        setShowSmartAccess(!showSmartAccess);
    }

    return (
        <React.Fragment>
            <Helmet title="Tickets"/>
            <WindowClickEventComponent showActionCardKey={showActionCardKey} setShowActionCardKey={(val: number) => {

                setShowActionCardKey(val)
            }}/>
            <MainCard>
                <CardHeader>
                    <div className={"space-between"}>
                        <span>
                            Tickets
                        </span>
                        {/*<button*/}
                        {/*    onClick={toggleShowSmartAccess}*/}
                        {/*    className={"btn btn-primary"}*/}
                        {/*>*/}
                        {/*    Quick access{" "}*/}
                        {/*    <i>*/}
                        {/*        <AiOutlineFullscreen />*/}
                        {/*    </i>*/}
                        {/*</button>*/}
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row className="align-items-center mb-4">
                            <Col sm="4" xs="6">
                                <InputGroup>
                                    <input
                                        value={searchValue ?? ""}
                                        style={{flex: 2}}
                                        type="text"
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            setSearchValue(value);
                                        }}
                                        className="form-control flex-2"
                                        placeholder=""
                                    />

                                    <button
                                        onClick={e => {
                                            e.preventDefault();
                                            getTicketsHandler();
                                        }}
                                        className="btn btn-success flex-2"
                                    >
                                        Search
                                        <i>
                                            {" "}
                                            <AiOutlineSearch/>{" "}
                                        </i>
                                    </button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Form>

                    {
                        loading ?
                            <Loader isLoading={true}/>
                            : null
                    }
                    {
                        Tickets && Tickets.length > 0 ?
                            <table className={"table table-response"}>
                                <thead className="thead-dark">
                                <tr>
                                    <td>
                                        Ticket no.
                                    </td>
                                    <td>
                                        Customer
                                    </td>
                                    <td>
                                        Location
                                    </td>
                                    <td>
                                        Status
                                    </td>
                                    <td>
                                        Date
                                    </td>
                                    <td>
                                        Actions
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {Tickets.map((item: any, key: number) => {
                                    return (
                                        <tr key={key}>
                                            <td> {item.id} </td>
                                            <td>{item.user?.displayName}</td>
                                            <td>{item.location?.name}</td>
                                            <td> <Badge style={{width: '100%', height:'20px'}} color={statusColor(item.status)}>{item.status?.name}</Badge> </td>
                                            <td> {formatSimpleDate(item.checkedInDate)}</td>
                                            {/*<td>{item.checkedIn ? "Checked-In" : "Not Checked-In"}</td>*/}

                                            <td className="position-relative">

                                                <UncontrolledButtonDropdown size="sm">
                                                    <DropdownToggle caret outline color="dark">
                                                        <FaEllipsisH/>
                                                    </DropdownToggle>

                                                    <DropdownMenu>
                                                        <DropdownItem>
                                                            <FaEye/> Change Status
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <FaRegComment/> Add Comment
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <FaPrint/> Print
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <FaHistory/> History Log
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                                </tbody>
                            </table>
                            :
                            <EmptyListState
                                image={emptyStateTicketImage}
                                title={"There are no Tickets"}
                            />
                    }
                </CardBody>
            </MainCard>


            <SmartAccessComponent
                isOpen={showSmartAccess}
                close={toggleShowSmartAccess}
            />
        </React.Fragment>
    );
}