/* eslint-disable react/no-deprecated */
import React, {Component} from 'react';
import Helmet from "react-helmet";
import {FaEdit, FaPlus, FaTrashAlt} from "react-icons/fa";
import {toast} from "react-toastify";
import {Button, CardBody, CardHeader} from "reactstrap";
import {changeCurrencyDefaultState, getCurrency} from "../../api/CurrencyApi";
import BitCoin from '../../assets/imgs/no_data.svg';
import {Loader} from "../../components/Loader";
import {MainCard} from "../../components/MainCard";
import {ToastMessage} from "../../components/ToastMessages";
import {AddEditCurrencyPaymentModal, IAddEditCurrency, ModalStatus} from "./AddEditCurrencyPaymentModal";
import DeleteCurrencyModal from "./DeleteCurrencyModal";
import { PaginationFooter } from "../../components/PaginationFooter";

class ManageCurrencyGateway extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            page:1,
            count:0,
            maximumPerPage:20,
            data: [],
            openModal: false,
            __loading__: false,
            __needToEdit__: false,
            __itemEditable__: null,
            __itemDeletable__: null,
            __activeCurrencyId__: 0
        }
    }

    componentWillMount() {
        // TODO : Fetch Currencies From API!
        // @ts-ignore
        this.fetchData();
    }

    fetchData = () => {
        this.setState({ __loading__: true });
        getCurrency({ limit: this.state.maximumPerPage, pageIndex: this.state.page }).then(data => {
            this.setState({ __loading__: false,data: data.items,count:(data as any).totalItemsCount });
            if (data && data.items.length > 0) {
                const __ = data.items.find(i => i.isDefault);
                if (__) {
                    this.setState({ __activeCurrencyId__: __.id });
                }
            }
        }).catch(error => {
            this.setState({ __loading__: false });
        })
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }



    showEditModal = (item: any) => {

        this.setState({
            __itemEditable__: {
                currencyCode: item.code,
                currencySymbol: item.symbol,
                id: item.id,
                isDefault: item.isDefault
            }
        }, () => {

        });
        this.setState({ openModal: true })
    }


    cancelDeleteHandler = () => {
        this.setState({ __itemDeletable__: null })
    }

    deleteHandler = (item: IAddEditCurrency) => {
        this.setState({ __itemDeletable__: item })
    }

    changeDefaultStateHandler = (id: number) => {


        this.setState({ __loading__: true })
        changeCurrencyDefaultState(id).then(r => {
            this.setState({ __loading__: false })

            toast.success(
                <ToastMessage type="Success">
                    Updated Successfully !
                </ToastMessage>
            );

            this.fetchData();
        }).catch(error => {
            this.setState({ __loading__: false })
            toast.error(
                <ToastMessage type="Error">
                    Error has been happened !
                </ToastMessage>
            );
        })
    }

    render() {

        return (
            <div>
                <Helmet title="Currency" />
                <MainCard>
                    <CardHeader className="d-flex justify-content-between">
                        <div>Currency </div>
                        <div>
                            <Button onClick={e => {
                                this.setState({ openModal: true, __itemEditable__: null })
                            }} color={'primary'}>
                                <FaPlus /> Add Currency
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {
                            this.state.data.length > 0 && !this.state.__loading__ ?

                                <table className={"table table-response"}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <td>
                                                #
                                            </td>
                                            <td>
                                                Currency Code
                                            </td>
                                            <td>
                                                Currency Symbol
                                            </td>
                                            <td>
                                                Is Default
                                            </td>
                                            <td>
                                                Actions
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.map((item: any, index: number) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td>
                                                            {index +1}
                                                        </td>
                                                        <td>
                                                            {item.code}
                                                        </td>
                                                        <td>
                                                            {item.symbol}
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input onClick={e => {
                                                                    e.preventDefault();
                                                                    this.changeDefaultStateHandler(item.id);
                                                                }} checked={item.isDefault} type="checkbox" className="form-check-input" />

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Button
                                                            size={"sm"}
                                                            color={"primary"}
                                                            outline={true}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.showEditModal(item);
                                                            }}
                                                            >
                                                                <FaEdit />
                                                            </Button>{" "}
                                                            <Button
                                                                size={"sm"}
                                                                color={"danger"}
                                                                outline={true}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.deleteHandler(item);
                                                                }}
                                                            >
                                                                <FaTrashAlt />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    </>

                                                );
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={7}>
                                            <PaginationFooter
                                              onPageClicked={page => this.setState({page:page}) }
                                              pageIndex={this.state.page}
                                              totalItemsCount={this.state.count}
                                              pageSize={this.state.maximumPerPage}
                                              pageItemsCount={this.state.data.length}
                                            />
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                !this.state.__loading__ ?
                                    <div className={'text-center'}>
                                        <img src={BitCoin} style={{ opacity: '0.65' }} alt={'img'} width={'350px'} />
                                        <br />
                                        <div className={"text-center mt-4"}>
                                            <strong> Oops, No data yet ! </strong>
                                        </div>
                                    </div> : null
                        }

                        {
                            this.state.__loading__ ?
                                <Loader isLoading={true} />
                                :
                                null
                        }
                    </CardBody>
                </MainCard>
                {

                    this.state.openModal ?
                        this.state.__itemEditable__ ?
                            <AddEditCurrencyPaymentModal item={this.state.__itemEditable__} onUpdated={this.fetchData} currencyName={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__EDIT__} />
                            : <AddEditCurrencyPaymentModal onUpdated={this.fetchData} currencyName={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__CREATE__} />
                        : null
                }


                {
                    this.state.__itemDeletable__ ? <DeleteCurrencyModal cancelDeleting={this.cancelDeleteHandler} currencyToDelete={this.state.__itemDeletable__} onUpdated={this.fetchData} /> : null
                }
            </div>
        );
    }
}

export default ManageCurrencyGateway;