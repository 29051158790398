import Axios from "axios";
import {PaginatedResult} from "./common/PaginatedResult";
import {PostType} from "./PostsApi";
import {IDropdownList} from "./common/DropdownList";
import { PaginatedRequest } from "./common/PaginatedRequest";

export interface PostCategory {
    id: number;
    name: string;
    pType: string;
}


export const addPostCategory = async (request: PostCategory): Promise<PostCategory> => {
    const response = await Axios.post(`/api/PostsCategories`,
        request,
    );

    return response.data;
};

export const editPostCategory = async (request: PostCategory): Promise<PostCategory> => {

    const response = await Axios.put(`/api/PostsCategories/`+request.id, request);

    return response.data;
};

export const deletePostCategoryById = async (id: number) => {
    return (await Axios.delete(`/api/PostsCategories/${id}`)).data;
};

export const GetAllPostsCategories = async ( request?:PaginatedRequest
): Promise<PaginatedResult<PostCategory>> => {
    const response = await Axios.get<PaginatedResult<PostCategory>>(
        "api/PostsCategories",
      {params:request}
    );
    return response.data;
};

export const GetPostCategoriesDropdownListByType = async (typeId: number): Promise<IDropdownList[]> => {
    const response = await Axios.get<IDropdownList[]>(
        "api/PostsCategories/SearchByType?typeId="+typeId
    );
    return response.data;
};

export const GetPostTagsDropdownListByType = async (typeId: number): Promise<IDropdownList[]> => {
    const response = await Axios.get<IDropdownList[]>(
        "api/PostsTags/SearchByType?typeId="+typeId
    );
    return response.data;
};


export const GetPostTypes = async (): Promise<PostType[]> => {
    const response = await Axios.get<PostType[]>(
        "api/Posts/PostType"
    );
    return response.data;
};