import { getMySubscriptionDetails } from "api/SubscriptionsApi";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import LoadingGif from '../assets/imgs/Spin.gif';
import { useNavigate } from "react-router-dom";

interface SubscriptionRedirectionProps {
    children: React.ReactNode;
}
interface SubscriptionRedirectionProps {
    children: React.ReactNode;
}

export const SubscriptionRedirection: React.FC<SubscriptionRedirectionProps> = (props) => {
    const navigate = useNavigate();

    const [needRegistration, setNeedRegistration] = useState<{
        needRegister: boolean;
        needToChangePw: boolean;
    } | null>(null);

    // const [needToChangePassword , setNeedToChangePassword] = useState<{ boolean } | null>(
    //     null
    // );

    const location = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
        const tryAgainTimeout: NodeJS.Timeout | null = null;

        //TODO: Move to a store
        function loadSubscriptionDetails(tryCounter: number) {
            if (tryCounter >= 4) return;
            setLoading(true);
            getMySubscriptionDetails()
                .then(response => {
                    const subscription = response.data
                    setLoading(false);
                    setNeedRegistration({ needRegister: subscription.registrationRequired, needToChangePw: subscription.needToChangePassword });
                }
                )
                .catch(error => {
                    setLoading(false);

                    console.error(error)

                    setTimeout(
                        () => loadSubscriptionDetails(tryCounter + 1),
                        3000
                    );
                });
        }

        loadSubscriptionDetails(1);
        return () => {
            tryAgainTimeout && clearTimeout(tryAgainTimeout);
        };
    }, []);

    useEffect(() => {

        if (needRegistration != null && needRegistration.needToChangePw) {
            navigate("/set-password");
        } else {
            if (location.pathname.startsWith("/cisco")) {

                return;
            }

            if (needRegistration != null && needRegistration.needRegister === false && location.pathname.startsWith("/sub")) {
                const x = location;
                navigate("/");
            }

            if (needRegistration != null && needRegistration.needRegister !== true || location.pathname.startsWith("/sub")){
                // navigate("/sub/signUp");
                // return;
            }
        }

    }, [location, needRegistration]);

    return <>
        {loading ?
            <div className="text-center" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <img src={LoadingGif} width="50px" height="50px" alt="" />
                <h5>LOADING...</h5>
            </div>
            : null}
        {needRegistration != null ? props.children : null}
    </>;
};
