import Axios from "axios";
import { QuestionOption, QuestionType } from "./RegistrationFormApi";
import { IDropdownList } from "./common/DropdownList";

export interface MembershipRegistrationForm {
    id?: number;
    title: string;
    description: string;
    membershipAreaQuestions : Array<MembershipAreaQuestion>;
}


export  interface MembershipAreaQuestion {
    id?: number | null;
    question: string;
    required: boolean;
    type: QuestionType;
    questionOptions: QuestionOption[] ;
    order: number;
}
    
export const  AddMembershipRegistrationForm = async (request: MembershipRegistrationForm ) : Promise<any> => {
    
    const result = await Axios.post('api/MembershipRegistrationForm/Create' , request);
    return result.data;
}

export const getMembershipRegistrationForm = async (): Promise<MembershipRegistrationForm> => {
    const result = await Axios.get<MembershipRegistrationForm>(
        "api/MembershipRegistrationForm/Get"
    );
    return result.data;
}


export const getPlansAsDropdownList = async (): Promise<Array<IDropdownList>> => {
    const result = await Axios.get<Array<IDropdownList>>("api/MembershipRegistrationForm/Plans/DropdownList");
    return result.data;
}


// Add Question Conditions
export enum ConditionAction {
    ShowAndMakeRequired,
    ShowOnly,
    Hide
}

export interface IQuestionConditionExpression {
    logicalOperator: string;
    questionLeftId: number | null;
    planLeftId: number | null ;
    comparisonOperator: string;
    questionRightId: number;
    staticValue: string | null;
}

export interface IAddEditQuestionCondition {
    conditionId?: number;
    action: ConditionAction;
    onQuestionId?: number | null;
    onPlanId?: number | null;
    expressions: Array<IQuestionConditionExpression>;
}


export const addCondition = async (
    payload: IAddEditQuestionCondition
): Promise<number> => {
    const result = await Axios.post<number>(
        "api/MembershipRegistrationForm/AddCondition",
        payload
    );
    return result.data;
};

export const getConditions = async (): Promise<Array<IAddEditQuestionCondition>> => {
    const result = await Axios.get<Array<IAddEditQuestionCondition>>(
        "api/MembershipRegistrationForm/GetConditions"
    );
    return result.data;
};
