/* eslint-disable react/no-deprecated */
import * as React from 'react';
import Helmet from "react-helmet";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Button, CardBody, CardHeader } from "reactstrap";
import { IPaymentMode, getPaymentMode } from "../../api/PaymentModeApi";
import PaymentModeIco from '../../assets/imgs/online_payments.svg';
import { Loader } from "../../components/Loader";
import { MainCard } from "../../components/MainCard";
import {
    ModalStatus
} from "../ManagePaymentGateways/AddEditCurrencyPaymentModal";
import AddEditPaymentModal from "./AddEditPaymentModal";
import DeletePaymentMode from "./DeletePaymentMode";
class ManagePaymentMode extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            pageIndex: 1,
            data: [],
            openModal: false,
            __loading__: false,
            __needToEdit__: false,
            __itemEditable__: null,
            __itemDeletable__: null,
            __activeCurrencyId__: 0
        }
    }
    componentWillMount() {
        // TODO : Fetch Currencies From API!
        // @ts-ignore
        this.fetchData();
    }


    fetchData = () => {
        this.setState({ __loading__: true });
        getPaymentMode({ limit: 10, pageIndex: this.state.pageIndex }).then(data => {
            this.setState({ __loading__: false });
            this.setState({ data: data.items })

        }).catch(error => {
            this.setState({ __loading__: false });
        })
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }


    showEditModal = (item: IPaymentMode) => {

        this.setState({
            __itemEditable__: {
                name: item.name,
                description: item.description,
                id: item.id,
                isActive: item.isActive
            }
        }, () => {

        });
        this.setState({ openModal: true })
    }


    cancelDeleteHandler = () => {
        this.setState({ __itemDeletable__: null })
    }




    deleteHandler = (item: IPaymentMode) => {
        this.setState({ __itemDeletable__: item })
    }




    render() {
        return (
            <div>
                <Helmet title="Payment Mode" />
                <MainCard>
                    <CardHeader className="d-flex justify-content-between">
                        <div>Payment Mode</div>
                        <div>
                            <Button onClick={e => {
                                this.setState({ openModal: true, __itemEditable__: null })
                            }} color={'primary'}>
                                <FaPlus /> Add Payment Mode
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {
                            this.state.data.length > 0 && !this.state.__loading__ ?

                                <table className={"table table-response"}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <td>
                                                Name
                                            </td>
                                            <td>
                                                Description
                                            </td>
                                            <td>
                                                Is Active
                                            </td>
                                            <td>
                                                Actions
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.map((item: IPaymentMode) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.name}
                                                            </td>
                                                            <td>
                                                                {item.description}
                                                            </td>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input onClick={e => {
                                                                        e.preventDefault();

                                                                    }} checked={item.isActive} type="checkbox" className="form-check-input" />

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    size={"sm"}
                                                                    color="primary"
                                                                    outline={true}
                                                                    onClick={e => {
                                                                    e.preventDefault();
                                                                    this.showEditModal(item);
                                                                }}>
                                                                    <FaEdit />
                                                                </Button>{" "}
                                                                <Button
                                                                    size={"sm"}
                                                                    color="danger"
                                                                    outline={true}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.deleteHandler(item);
                                                                    }}
                                                                    >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>

                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                !this.state.__loading__ ?
                                    <div className={'text-center'}>
                                        <img src={PaymentModeIco} alt={'img'} width={'350px'} />
                                        <br />
                                        <div className={"text-center mt-4"}>
                                            <strong> Oops, No data yet ! </strong>
                                        </div>
                                    </div> : null
                        }

                        {
                            this.state.__loading__ ?
                                <Loader isLoading={true} />
                                :
                                null
                        }
                    </CardBody>
                </MainCard>
                {

                    this.state.openModal ?
                        this.state.__itemEditable__ ?
                            <AddEditPaymentModal item={this.state.__itemEditable__} onUpdated={this.fetchData} name={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__EDIT__} />
                            : <AddEditPaymentModal onUpdated={this.fetchData} name={null} id={null} isOpen={this.state.openModal} close={this.closeModal} modalStatus={ModalStatus.__CREATE__} />
                        : null
                }


                {
                    this.state.__itemDeletable__ ? <DeletePaymentMode cancelDeleting={this.cancelDeleteHandler} PaymentModeToDelete={this.state.__itemDeletable__} onUpdated={this.fetchData} /> : null
                }
            </div>
        );
    }
}

export default ManagePaymentMode;