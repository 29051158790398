/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GetRegistrants, IWebinarRegistrants } from "../../api/WebinarApi";
import { Loader } from "../../components/Loader";

interface ManageRegistrantsModelProps {
    webinarId: number,
    close?: () => unknown;
    isOpen: boolean
}

interface ManageRegistrantsModelState {
    data: Array<IWebinarRegistrants>
    loading: boolean
}

class ManageRegistrantsModel extends React.Component<ManageRegistrantsModelProps, ManageRegistrantsModelState> {
    constructor(props: ManageRegistrantsModelProps) {
        super(props);
        this.state = {
            data: [],
            loading: false
        }
    }

    componentWillMount() {
        this.fetchRegistrants();
    }


    fetchRegistrants = () => {
        if (this.props.webinarId) {
            this.setState({ loading: true });
            GetRegistrants(this.props.webinarId).then(data => {
                this.setState({ data, loading: false });
            }).catch(error => {
                this.setState({ loading: false })
            })
        }
    }

    render() {
        return (
            <Modal size={'large'} unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader>
                    Display Registrants
                </ModalHeader>
                <ModalBody>
                    <table className={'table table-responsive table-striped'}>
                        <thead>
                            <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    First name
                                </td>
                                <td>
                                    Last name
                                </td>
                                <td>
                                    Address
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item: IWebinarRegistrants, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td> {item.email} </td>
                                            <td> {item.firstName} </td>
                                            <td> {item.lastName ? item.lastName : "N/A"} </td>
                                            <td> {item.address ? item.address : "N/A"} </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>

                    <div className="form-group mt-4  d-flex ">
                        <button className="btn btn-light  mx-1 flex-grow-1"
                            onClick={this.props.close}
                        >
                            <i > <AiOutlineArrowLeft /> </i>
                            Cancel </button>

                    </div>
                    {
                        this.state.loading ? <Loader isLoading={true} /> : null
                    }
                </ModalBody>
            </Modal>
        );
    }
}

export default ManageRegistrantsModel;