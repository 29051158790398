import * as React from 'react';
import Helmet from "react-helmet";
import { FaMobileAlt } from 'react-icons/fa';
import { Button, CardHeader } from "reactstrap";
import { MainCard } from "../../components/MainCard";
import SendSMSModal from './SensSMSModal';
const ManageSendSmsComponent: React.FC = props => {
    const [openSendSMs, setOpenSendSMS] = React.useState<boolean>(false);


    const toggleOpenSendSMS = () => {
        setOpenSendSMS(!openSendSMs);
    };

    return (
        <>
            {
                openSendSMs ?
                    <SendSMSModal isOpen={true} close={toggleOpenSendSMS} />
                    : null
            }
            <Helmet title="Send SMS"></Helmet>
            <MainCard>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        <span>SMS History</span>
                        <div>
                            <Button
                                color="primary"
                                className="btn-icon"
                                onClick={toggleOpenSendSMS}
                            >
                                <FaMobileAlt /> Send SMS
                            </Button>
                        </div>
                    </div>
                </CardHeader>
            </MainCard>
        </>
    );
}


export default ManageSendSmsComponent;