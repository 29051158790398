import Axios from "axios";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export enum Severity {
  info = "Info",
  warning = "Warning",
  error = "Error",
  success = "Success",
}

export interface AccessDomain {
  id: number;

  domainName: string;
  setupStatusDisplayName: string;

  setupStatusSeverity: Severity;
  index?: number | null;
}

export const listEventAccessDomains = async (
  eventId: number,
  request: PaginatedRequest
): Promise<PaginatedResult<AccessDomain>> => {
  const result = await Axios.get(`/api/events/${eventId}/accessDomains`);

  return result.data;
};

export interface AddNewAccessDomainReuqest {
  domainName: string;
}

export const addAccessDomain = async (
  eventId: number,
  request: AddNewAccessDomainReuqest
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post(
    `/api/events/${eventId}/accessDomains`,
    request
  );

  return result.data;
};

export const deleteAccessDomain = async (
  accessDomainId: number
): Promise<void> => {
  await Axios.delete(`/api/accessDomains/${accessDomainId}`);
};

// Global Access Domain API.

export const getAllAccessDomain = async (): Promise<PaginatedResult<
  AccessDomain
>> => {
  const response = await Axios.get(`api/AccessDomains/AccessDomains`);
  return response.data;
};

export const addGlobalAccessDomain = async (
  payload: AddNewAccessDomainReuqest
): Promise<any> => {
  const response = await Axios.post(
    `api/AccessDomains/AccessDomains`,
    payload
  );
  return response.data;
};
