import React, {useEffect, useState} from 'react';
import {Loader} from 'components/Loader';
import Helmet from 'react-helmet';
import {FaEdit, FaPlus, FaTrash, FaTrashAlt} from 'react-icons/fa';
import {Badge, Button, CardBody, CardHeader} from 'reactstrap';
import { GetAllCategories, SearchCategories } from '../../api/ProductCategoriesApi';
import {ProductCategory} from '../../api/ProductsApi';
import noData from '../../assets/imgs/no_data.svg';
import {MainCard} from '../../components/MainCard';
import AddEditCategories, {ModalStatus} from './AddEditCategoryComponent';
import DeleteCategory from './DeleteCategoryComponent';
import { PaginationFooter } from "../../components/PaginationFooter";

const ProductCategoriesComponent: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ProductCategory[]>([]);
    const [openAddEditModal, setOpenAddEditModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState<ProductCategory | null>(null);
    const [itemToDelete, setItemToDelete] = useState<ProductCategory | null>(null);
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
    const [maximumPerPage]=useState(20);

    useEffect(() => {
        fetchCategories();
    }, [page]);

    const fetchCategories = () => {
        setLoading(true);
        GetAllCategories({pageIndex:page,limit:maximumPerPage}).then(paged => {
            setLoading(false);
            ;
            if (paged) {
                setData(paged.items);
                setCount((paged as any).totalItemsCount)
            }
        });
    };

    const closeAddEditModal = () => {
        setOpenAddEditModal(false);
    };

    const onUpdateModal = () => {
        setOpenAddEditModal(false);
        fetchCategories();
    };

    const displayAddEditModal = (item: ProductCategory) => {
        setItemToEdit({...item, type: item.pcTypeId});
        setOpenAddEditModal(true);
    };

    const cancelDeleteHandler = () => {
        setItemToDelete(null);
    };

    const updateDeleteHandler = () => {
        setItemToDelete(null);
        fetchCategories();
    };

    return (
        <div>
            {openAddEditModal ? (
                <AddEditCategories
                    isOpen={true}
                    onClose={() => {
                        setOpenAddEditModal(false);
                        setItemToEdit(null);
                    }}
                    onUpdated={fetchCategories}
                    item={itemToEdit}
                    modalStatus={itemToEdit ? ModalStatus.__EDIT__ : ModalStatus.__CREATE__}
                />
            ) : null}

            {itemToDelete ? (
                <DeleteCategory
                    cancelDeleting={() => {
                        setItemToDelete(null);
                    }}
                    onUpdated={fetchCategories}
                    deleteItem={itemToDelete}
                />
            ) : null}

            <Helmet title="Categories" />
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>Categories</div>
                    <div>
                        <Button
                            onClick={e => {
                                e.preventDefault();
                                setOpenAddEditModal(true);
                            }}
                            color={'primary'}
                        >
                            <FaPlus /> Add Category
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    {data.length > 0 ? (
                        <div>
                            <table className={"table table-response"}>
                                <thead className="thead-dark">
                                <tr>
                                    <td>#</td>
                                    <td>Name</td>
                                    <td>Type</td>
                                    <td>On Home Page</td>
                                    <td>Actions</td>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item: ProductCategory, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.pcType}</td>
                                            <td>{item.isOnHomePage ?
                                                <Badge color="success">Yes</Badge>
                                                : <Badge color="secondary">No</Badge>}
                                            </td>
                                            <td className="position-relative">
                                                {item.changeable ? (
                                                    <>
                                                        <Button
                                                            size={"sm"}
                                                            outline={true}
                                                            color={"primary"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                displayAddEditModal(item);
                                                            }}
                                                        >
                                                            <FaEdit />
                                                        </Button>{" "}
                                                        <Button
                                                            size={"sm"}
                                                            outline={true}
                                                            color={"danger"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setItemToDelete(item);
                                                            }}
                                                        >
                                                            <FaTrash />
                                                        </Button>
                                                    </>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={6}>
                                        <PaginationFooter
                                          onPageClicked={setPage}
                                          pageIndex={page}
                                          totalItemsCount={count}
                                          pageSize={maximumPerPage}
                                          pageItemsCount={data.length}
                                        />
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    ) : loading ? (
                        <div>
                            <Loader isLoading={true} />
                        </div>
                    ) : (
                        <div className={'text-center'}>
                            <img src={noData} width={"50%"} alt={'no data'} />
                            <p className={'mt-4'}>No Item Exists</p>
                        </div>
                    )}
                </CardBody>
            </MainCard>
        </div>
    );
};

export default ProductCategoriesComponent;
