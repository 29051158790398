import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import OrderProductRow from "./OrderProductRow";
import _ from "lodash";
import AddOrderForm from "./AddOrderForm";
import { FaExclamationCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { GetTenantUsers } from "../../api/MembershipAreaApi";
import Select from "react-select";
import { Order, Payment, PlaceOrder, ReviewOrder } from "../../api/OrdersApi";
import OrderPayment from "./OrderPayment";
import './ProductContainer.css'

const AddOrder = ({
                    isOpen,
                    setIsOpen,
                    onSubmit
                  }: { isOpen: boolean, setIsOpen: (isOpen: boolean) => void, onSubmit: () => void }) => {

  const [step, setStep] = useState(0);
  const [user, setUser] = useState<any>()
  const [users, setUsers] = useState<any[]>()
  const [order, setOrder] = useState<Order>()
  const [confirmedOrder,setConfirmedOrder] = useState<any>()
  const [error,setError] = useState('')
  const [selectedPayment,setSelectedPayment] = useState<Payment[]>([])
  const [maxQuantityMap,setMaxQuantityMap] = useState<Record<number, number>>({})

  useEffect(() => {
    GetTenantUsers({ limit: 50 }).then(response => {
      setUsers(response.items)
    })
  }, [])

  useEffect(() => {
    setUser(null)
    setStep(0)
  }, [isOpen])
  useEffect(() => {
    setConfirmedOrder(order)
    setOrder(undefined)
    setSelectedPayment([])
  }, [user])
  useEffect(() => {
    setError('')
  },[step])

  const reviewOrder=async (orderRequest:Order) =>{
    try {
      const response = await ReviewOrder(orderRequest);
      const productsGrouped = groupById(response.products)
      setConfirmedOrder({ ...response,products:productsGrouped })
      
      setOrder(orderRequest)
      return true;
    }catch (e:any){
      setError(e.response.data?.detail ?? 'Error while Adding the Product')
      return false;
    }
  }


  const addProduct = async (product: any) => {
    
    setMaxQuantityMap(prevState => ({...prevState,[product.id]:product.maxQTYPerOrder}))
    let orderEdit:Order|undefined;
    setError('')
    if (!order) {
      orderEdit = {
        clientId: user.id,
        orderStatusId: 2,
        products: [{
          addons: product.selectedAddOns.map((addon: any) => ({ addonId: addon.id, qty: 1 })),
          qty: product.quantity ?? 1,
          locationId: product.selectedLocation?.id,
          instructorId: product.selectedSpeaker?.id,
          productId: product.id !== 0 ? product.id : product.productId,
          couponCode: product.coupon
        }
        ],
        payments: []
      }
    }else{
      orderEdit = {...order,products:[...order.products]};
      orderEdit.products.push({
        addons: product.selectedAddOns?.map((addon: any) => ({ addonId: addon.id, qty: 1 })),
        qty: product.quantity ?? 1,
        locationId: product.selectedLocation?.id,
        instructorId: product.selectedSpeaker?.id,
        productId: product.id !== 0 ? product.id : product.productId,
        couponCode: product.coupon
      })

    }

    return reviewOrder(orderEdit)

  }

  const groupById = (list:any[]):any[] =>  _(list).groupBy(value => value.productId).map(productsById => {

    return { ...productsById.at(0), qty: productsById.length }

  }).value()




  const deleteProduct = (product: any) => {
    
    const editedProducts = [...order?.products?? []]
    const existingProductIndex = editedProducts.findIndex(p => p.productId === product.productId)
    if (existingProductIndex > -1) {
      editedProducts.splice(existingProductIndex, 1)
    }

    if(order){
      reviewOrder({...order,products:editedProducts})
    }
  }
  const next = () => {
    switch (step) {
      case 0:
        if (user) setStep(prevState => prevState + 1);
        break;
      case 1:
        if (confirmedOrder?.products && confirmedOrder?.products.length > 0) setStep(prevState => prevState + 1);
        break;
      case 2:
        if(order)
        PlaceOrder({...order,payments:selectedPayment}).then((result)=>{
          onSubmit();
          toast.success(`Order id ${result.id} Placed Successfully`)
          setIsOpen(false)
        }).catch((error)=>{
          toast.error(error.response.data?.detail ?? 'Error while Placing Order')
        })
        break;
    }
  }
  const previous = () => {
    if (step > 0) {
      setStep(prevState => prevState - 1)
    } else {
      setUser(null)
      setIsOpen(false)
    }

  }

  return (
    <Modal size="lg" style={{ minHeight: '400px' }} unmountOnClose isOpen={isOpen} onClosed={() => setConfirmedOrder(undefined)}
           toggle={() => setIsOpen(!isOpen)}>

      <ModalHeader>
        <div>Add Order</div>
      </ModalHeader>
      <ModalBody>

        {step === 0 ? <div>
          <FormGroup>
            <Label> Select User </Label>
            <Select
              isClearable
              className={"mb-4"}
              isMulti={false}
              options={users}
              value={user}
              getOptionLabel={(option) => option.fullName}
              getOptionValue={option => option.id ?? ''}
              onChange={(val: any) => {
                setUser(val)
              }}
            />
          </FormGroup>
        </div> : step === 1 ?
          <Fragment>
            <div className=' row'>
              {!confirmedOrder || !confirmedOrder.products || confirmedOrder.products.length === 0 ?
                <span
                  className='text-secondary col-lg-8 col-md-12 d-flex flex-row justify-content-center align-items-center gap-2 py-4 '><FaExclamationCircle/> No Product is Added</span> :
                <div className='products-container col-md-12 col-lg-8 overflow-y-auto'>
                  <span className='fw-semibold mb-4'>Products :</span>
                  {confirmedOrder.products.map(((product:any, index:number) => <OrderProductRow maxQuantity={maxQuantityMap[product.productId]} key={index} index={index} product={product}
                                                                      increment={() => addProduct(product)}
                                                                      decrement={() => deleteProduct(product)}
                                                                      remove={() => deleteProduct(product)}/>))}

                </div>
              }
              <div className='d-flex flex-column gap-1 px-2 col-lg-4 col-md-12 mb-2 justify-content-between'>
                <div className='d-flex flex-row px-2 justify-content-between '>
                  <span className='fw-semibold'>Subtotal</span>
                  <span>{confirmedOrder?.subTotal ?? 0} SAR</span>
                </div>
                <div className='d-flex flex-row px-2 justify-content-between text-danger'>
                  <span className='fw-semibold '>Discount</span>
                  <span>{confirmedOrder?.totalDiscounts ?? 0} SAR</span>
                </div>
                <div className='d-flex flex-row px-2 justify-content-between '>
                  <span className='fw-semibold'>All Taxes</span>
                  <span>{confirmedOrder?.totalVATAmount ?? 0} SAR</span>
                </div>
                <hr/>
                <div className='d-flex flex-row justify-content-between fs-4 '>
                  <span className='fw-bold '>Total :</span>
                  <span>{confirmedOrder?.total ?? 0} SAR</span>
                </div>

              </div>
            </div>
            <div>
              <AddOrderForm error={error} addProduct={addProduct}/>
            </div>
          </Fragment> :
          <OrderPayment amount={confirmedOrder?.total ?? 0} selectedPayments={selectedPayment} setSelectedPayments={setSelectedPayment} />}

      </ModalBody>
      <ModalFooter className={"mt-lg-5"}>
        <button
          onClick={previous}
          className="btn btn-secondary">
          {step > 0 ? 'Previous' : 'Cancel'}
        </button>
        <button
          disabled={step === 0 && !user || step === 1 && !confirmedOrder || step === 2 && confirmedOrder.total !== selectedPayment.reduce((total, payment) => total + payment.amount, 0) }
          onClick={next}
          className="btn btn-success">
          {step < 2 ? 'Next' : 'Submit'}
        </button>
      </ModalFooter>
    </Modal>

  )

}

export default AddOrder;