/* eslint-disable react/no-children-prop */
import { DeleteModal } from "components/DeleteModal";
import { EmptyListState } from "components/EmptyListState";
import { ToastMessage } from "components/ToastMessages";
import * as React from "react";
import Helmet from "react-helmet";
import { FaPlus, FaTrash } from "react-icons/fa";
import { CellProps, Column } from "react-table";
import { toast } from "react-toastify";
import { Badge, Button, ButtonGroup, CardHeader } from "reactstrap";
import {
    AccessDomain,
    Severity,
    deleteAccessDomain,
    getAllAccessDomain
} from "../../api/AccessDomainApi";
import { DataTable } from "../../components/DataTable";
import { MainCard } from "../../components/MainCard";
import { AddAccessDomainModal } from './AddGlobalDomain';

import emptyState from "assets/imgs/no_data.svg";
import {Loader} from "../../components/Loader";
export const ManageGlobalDomains: React.FC = props => {

    const [data, setData] = React.useState<AccessDomain[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
    const [
        accessDomainToRemove,
        setAccessDomainToRemove
    ] = React.useState<AccessDomain | null>(null);
    const [deleteAccessModalIsOpen, setDeleteAccessModalIsOpen] = React.useState(
        false
    );

    const mapSeverityClassName = (severity: Severity) => {
        switch (severity) {
            case Severity.info:
                return "info";
            case Severity.error:
                return "error";
            case Severity.warning:
                return "warning";
            case Severity.success:
                return "success";
        }
    };

    const loadAccessDomain = React.useCallback(async () => {
        setLoading(true);
        const pagination = await getAllAccessDomain();
        setData(pagination?.items ?? []);
        setLoading(false);
    }, [setData])


    React.useEffect(() => {
        loadAccessDomain();
    }, [])


    const showAccessDomainDeleteModal = React.useCallback(
        (accessDomain: AccessDomain) => {
            setAccessDomainToRemove(accessDomain);
            setDeleteAccessModalIsOpen(true);
        },
        [setAccessDomainToRemove, setDeleteAccessModalIsOpen]
    );
    const closeAccessDomainDeleteModal = React.useCallback(
        () => setDeleteAccessModalIsOpen(false),
        [setDeleteAccessModalIsOpen]
    );


    const handleDeleteAccessDomain = React.useCallback(() => {
        if (!accessDomainToRemove) return;
        return deleteAccessDomain(accessDomainToRemove?.id)
            .then(_ => {
                toast.success(
                    <ToastMessage>
                        Access domain &quot;
                        {accessDomainToRemove.domainName}&quot; was deleted
                        successfully.
                    </ToastMessage>
                );

                closeAccessDomainDeleteModal();
                loadAccessDomain();
            })
            .catch(_ => {
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong while deleting &quot;
                        {accessDomainToRemove?.domainName}
                        &quot;.
                    </ToastMessage>
                );
            });
    }, [
        accessDomainToRemove,
        closeAccessDomainDeleteModal,
        loadAccessDomain
    ]);




    const columns = React.useMemo<Column<any>[]>(
        () => [
            {
                Header: "#",
                accessor: "index",
            },
            {
                Header: "Domain",
                accessor: "domainName",
            },
            {
                Header: "Status",
                Cell: (ctx: CellProps<AccessDomain>) => {
                    const accessDomain = ctx.row.original;
                    if (!accessDomain) return null;
                    return (
                        <div>
                            <Badge
                                className="mx-1 px-2 py-1"
                                color={mapSeverityClassName(accessDomain.setupStatusSeverity)}
                            >
                                {accessDomain.setupStatusDisplayName}
                            </Badge>
                        </div>
                    );
                },
            },
            {
                Header: "Actions",
                Cell: function renderEventDomainsActions(ctx: CellProps<AccessDomain>) {
                    const accessDomain = ctx.row.original;
                    if (!accessDomain) return null;

                    return (
                        <Button
                            size="sm"
                            color="danger"
                            outline
                            onClick={() => showAccessDomainDeleteModal(accessDomain)}
                        >
                            <FaTrash />
                        </Button>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <Helmet title="Manage access domains"></Helmet>
            {
                openAddModal ?
                    <AddAccessDomainModal isOpen={true} close={() => {
                        setOpenAddModal(false);
                        loadAccessDomain();
                    }} />
                    : null
            }
            <MainCard>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        <span> Access Domains </span>
                        <div>
                            <Button
                                color="primary"
                                className="btn-icon"
                                onClick={_ => {
                                    setOpenAddModal(true);
                                }}
                            >
                                <FaPlus /> Connect new domain
                            </Button>
                        </div>
                    </div>
                </CardHeader>

                {loading ?
                    <Loader isLoading={true}/>
                    :
                    <DataTable
                        columns={columns}
                        data={data as any}
                        emptyState={<div className={"text-center"}>
                            <EmptyListState
                                image={emptyState}
                                title={"You have no Domains yet"}
                                callToActionButton={{
                                    text: "Add your first Domain now!",
                                    onClick: () => setOpenAddModal(true)
                                }}/>
                        </div>}
                    />

                }
                <DeleteModal
                    isOpen={deleteAccessModalIsOpen}
                    deleteItemPresenter={
                        <b>{accessDomainToRemove?.domainName}</b>
                    }
                    onDelete={handleDeleteAccessDomain}
                    toggle={closeAccessDomainDeleteModal}
                />

            </MainCard>
        </>
    );
}