import Axios from "axios";
import { ProductCategory } from "./ProductsApi";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface ListProductCategoryRequest extends PaginatedRequest {
  eventId: number;
}

export interface AddNewCategoryRequest {
  eventId?: number | null;

  name: string;
  nameAr?: string;
  typeId: number;
  isOnHomePage?: boolean;
}

export interface editCategory {
  name: string;
  nameAr?: string;
  typeId: number;
  isOnHomePage?: boolean;
}

export const getProductCategory = async (
  request: ListProductCategoryRequest
): Promise<PaginatedResult<ProductCategory>> => {
  const { eventId, ...data } = request;

  return (
    await Axios.get(`/api/events/${eventId}/ProductCategories`, {
      params: data,
    })
  ).data;
};

export const addProductCategory = async (
  request: AddNewCategoryRequest
): Promise<NewResourceResult<number>> => {
  return (await Axios.post(`/api/ProductCategories/AddCategory`, request))
    .data;
};

export const editProductCategories = async (
  id: number,
  payload: editCategory
) => {
  const response = await Axios.put(
    `api/ProductCategories/${id}`,
    payload
  );
  return response.data;
};

export const deleteProductCategory = async (id: number) => {
  const response = await Axios.delete(
    `api/ProductCategories/${id}`
  );
  return response.data;
};

export const getMembershipAreaCategory = async () => {
  const response = await Axios.get(
    `api/ProductCategories/ProductCategories/MembershipArea`
  );
  return response.data;
};

export type ICategoryDropdownItem = ProductCategory;

export const GetProductCategoriesTypes = async (): Promise<Array<ICategoryDropdownItem>> => {
  const response = await Axios.get<Array<ICategoryDropdownItem>>(
    "api/ProductCategories/Types"
  );
  return response.data;
};


export const SearchCategories = async (isForProductAddEdit: boolean = false, text?: string): Promise<Array<ProductCategory>> => {
  const response = await Axios.get<Array<ProductCategory>>(
    "api/ProductCategories/Search",
    { params: { text, isForProductAddEdit } }
  );
  return response.data;
};
export const GetAllCategories = async ( request: PaginatedRequest): Promise<PaginatedResult<ProductCategory>> => {
  const response = await Axios.get<PaginatedResult<ProductCategory>>(
    "api/ProductCategories",
    { params: request }
  );
  return response.data;
};

