import Axios from "axios";

export interface IMembershipTerms {
    content: string;
}


 export const getMembershipTerms = async () : Promise<IMembershipTerms> => {
    const result = await Axios.get<IMembershipTerms>('api/MembershipTerms');
    return result.data;
 }
 
 export const postMembershipTerms = async (payload: IMembershipTerms) : Promise<any> => {
    const result = await  Axios.post('api/MembershipTerms' , payload);
    return result.data;
 }
 
 export  const editMembershipTerms = async (payload: IMembershipTerms) : Promise<any> => {
    const result = await  Axios.put('api/MembershipTerms' , payload);
    return result.data;
 }
 
 
 export const deleteMembershipTerms = async (): Promise<any> => {
    const result = await  Axios.delete('api/MembershipTerms');
    return result.data;
 }