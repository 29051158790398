import * as React from "react";
import {
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import {Loader} from "../../components/Loader";
//AiOutlineArrowRight
import {getInvoiceTemplate} from "api/InvoicesApi";
import classnames from "classnames";
import HtmlViewercomponent from "components/HtmlViewerComponent/html-viewer";
import moment from 'moment';
import {AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineEllipsis} from "react-icons/ai";
import {FiDownload, FiEye} from "react-icons/fi";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
    GetInvoicesList,
    GetMembershipDetails,
    GetSessions,
    GetTickets,
    IInvoiceItemUsingMembershipNumberDto,
    ISessionItemUsingMembershipNumberDto,
    ITicketItem
} from '../../api/SmartAccessApi';
import spinner from '../../assets/imgs/Spinner.gif';
import newTicketImg from '../../assets/imgs/add-ticket-01.svg';
import blankImg from '../../assets/imgs/blank-profile-picture.png';
import membershipCardImg from '../../assets/imgs/membership-card.svg';
import newSessionImg from '../../assets/imgs/new-session.svg';
import NoData from '../../assets/imgs/no_data.svg';
import printBadgeImg from '../../assets/imgs/print-badge.svg';
import './SmartAccessComponent.style.css';

import Slider from "react-slick";
import AddSessionSmartAccessComponent from './AddSessionSmartAccess.Component';
import AddTicketConfirmComponent from './AddTicketConfirmComponent';
import {EventBadgeComponent} from './EventBadgeComponent';
import {MembershipCardReceiptComponent} from './MembershipCardReceiptComponent';
import SessionReceiptComponent from './SessionReceiptComponent';
import TicketReceiptComponent from './TicketReceiptComponent';

declare module 'react-slick';

interface OwnProps {
    close: () => void;
    isOpen: boolean;
}

type Props = OwnProps;

export class SmartAccessComponent extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            loadingItems: false,
            stages: {
                1: {
                    isActive: true,
                    membershipNumber: null,
                },
                2: {
                    isActive: false,
                    data: null,
                    tickets: [],
                    sessions: [],
                    invoices: [],
                    activeTab: "1",
                    showActionCardKey: -1,
                    sessionId: 0,
                    ticketId: 0
                },
            },
            validationMsg: null,
            smartAccessKeysAllowed: ['M-', 'S-', 'IN-', 'T-'],
            viewInvoiceTemplate: null,
            addSessionModal: false,
            sessionReceiptModal: false,
            addTicketModal: false,
            ticketReceiptModal: false,
            firstEntry: false,
            membershipCardModal: false,
            eventBadgeModal: false
        };
    }


    onNextHandler = (oldStage: number, newStage: number) => {
        const {stages} = this.state;
        stages[oldStage].isActive = false;
        stages[newStage].isActive = true;
        this.setState({stages});
    };

    validateFirstStage = (action?: () => void): boolean => {
        let result = true;
        if (
            this.state.stages[1].membershipNumber == null ||
            this.state.stages[1].membershipNumber.length == 0
        ) {
            result = false;
            this.setState({validationMsg: "membership number is required !"});
            return result;
        }

        let isValid = false;

        for (let i = 0; i < this.state.smartAccessKeysAllowed.length; i++) {
            isValid = this.state.stages[1].membershipNumber.startsWith(this.state.smartAccessKeysAllowed[i]);
            if (isValid) break;
        }
        if (!isValid) {
            result = false;
            this.setState({validationMsg: `membership number Must begin with ${this.state.smartAccessKeysAllowed.join(' or ')} !`});
            return result;
        }
        if (result) {
            this.setState({validationMsg: null, firstEntry: true});

            action?.();
        }
        return result;
    };

    fetchData = () => {
        this.setState({loading: true});
        GetMembershipDetails(this.state.stages[1].membershipNumber).then(data => {
            this.setState({loading: false});
            if (data) {

                const activeTab = this.initActiveTab();
                const promise = new Promise((resolve, reject) => {
                    resolve(activeTab);
                })

                promise.then((tab) => {
                    if (tab == "1") {
                        this.fetchTicketsHandler();
                    } else if (tab == "2") {
                        this.fetchSessionsHandler();
                    } else if (tab == "3") {
                        this.fetchInvoicesHandler();
                    } else {
                        this.fetchTicketsHandler();
                    }
                })
                this.setState({stages: {...this.state.stages, 2: {...this.state.stages[2], data, activeTab}}}, () => {
                    ;
                })
            }
            ;
        }).catch(error => {
            this.setState({loading: false});

        })
    }


    fetchInvoicesHandler = () => {
        // receive Invoices depends on membership number;
        this.setState({loadingItems: true});
        GetInvoicesList(this.state.stages[2].data.membershipNumber).then(result => {
            this.setState({loadingItems: false});
            if (result) {
                this.registerEvent();
                const {stages} = this.state;
                stages[2].invoices = result.items;
                if (this.state.firstEntry) {
                    const target = result.items.find(x => x.invoiceNumber == this.state.stages[1].membershipNumber);
                    if (target) {
                        this.viewInvoiceHandler(target.id);
                    }
                }

                this.setState({stages});
            }
        }, error => {
            this.setState({loadingItems: false});
        })
    }


    fetchTicketsHandler = () => {
        // receive Tickets depends on membership number;
        this.setState({loadingItems: true});
        GetTickets(this.state.stages[2].data.membershipNumber).then(pagedCollection => {
            this.setState({loadingItems: false});
            this.registerEvent();
            const {stages} = this.state;

            if (this.state.firstEntry) {
                const target = pagedCollection.items.find(x => x.ticketNumber == this.state.stages[1].membershipNumber);
                if (target) {

                    stages[2].ticketId = target.ticketId;
                    this.setState({ticketReceiptModal: true})
                }
            }

            stages[2].tickets = pagedCollection.items;
            this.setState({stages});
        })
    }

    fetchSessionsHandler = () => {
        // receive Sessions depends on membership number;
        this.setState({loadingItems: true});
        GetSessions(this.state.stages[2].data.membershipNumber).then(res => {
            this.setState({loadingItems: false});
            this.registerEvent();
            const {stages} = this.state;

            if (this.state.firstEntry) {
                const target = res.items.find(x => x.sessionNumber == this.state.stages[1].membershipNumber);
                if (target) {

                    stages[2].sessionId = target.id;
                    this.setState({sessionReceiptModal: true})
                }
            }


            stages[2].sessions = res.items;
            this.setState({stages});
        })
    }

    initActiveTab = (): string => {
        const membershipNumber = this.state.stages[1].membershipNumber;
        if (membershipNumber.startsWith("M-")) {
            return "1";
        } else if (membershipNumber.startsWith("S-")) {
            return "2";
        } else if (membershipNumber.startsWith("IN-")) {
            return "3";
        }
        return "1";
    }


    toggleTabs = (tab: any) => {
        if (this.state.stages[2].activeTab !== tab) {
            const {stages} = this.state;
            stages[2].activeTab = tab;
            this.setState({stages}, () => {
                this.tabActionHandler(tab);
            });
        }
    };


    tabActionHandler = (tab: any) => {
        if (tab === '1') {
            this.fetchTicketsHandler();
        } else if (tab === '2') {
            this.fetchSessionsHandler();
        } else if (tab === '3') {
            this.fetchInvoicesHandler();
        }
    }


    registerEvent = () => {
        document.getElementsByClassName('modal-dialog')[0].addEventListener("click", ({target}) => {

            ;
            let tar = target as any;

            if (
                (tar && tar["classList"].contains("action-btn")) ||
                (tar && tar["classList"].contains("btn-link")) ||
                (tar && tar.parentElement.classList.contains("btn-link"))
            ) {
                return;
            }

            if (this.state.stages[2].showActionCardKey > -1) {
                const {stages} = this.state;
                stages[2].showActionCardKey = -1
                this.setState({stages});
            }
        });
    };


    viewInvoiceHandler = (transactionId: number) => {
        // send request to get invoice of transaction id ...
        this.setState({loading: true});
        getInvoiceTemplate(transactionId).then(
            data => {
                ;
                this.setState({
                    loading: false,
                    viewInvoiceTemplate: data?.template ?? null,
                });
            },
            error => {
                this.setState({loading: false});
            }
        );
        this.setState({showActionCardKey: -1});
    };


    render() {


        const sliderSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3
        };

        return (
            <Modal
                unmountOnClose
                isOpen={this.props.isOpen}
                toggle={this.props.close}
            >
                {this.state.loading ? <Loader isLoading={true}/> : null}

                {this.state.viewInvoiceTemplate ? (
                    <HtmlViewercomponent
                        isOpen={this.state.viewInvoiceTemplate != null}
                        title={"Invoice"}
                        close={() => {
                            this.setState({viewInvoiceTemplate: null});
                        }}
                        html={this.state.viewInvoiceTemplate}
                    />
                ) : null}


                {
                    this.state.addSessionModal && this.state.stages[2].data?.membershipNumber ?
                        <AddSessionSmartAccessComponent membershipNumber={this.state.stages[2].data.membershipNumber}
                                                        isOpen={true} close={() => {
                            this.setState({addSessionModal: false}, () => {
                                this.fetchSessionsHandler();
                            })
                        }} tenantUserName={this.state.stages[2].data?.fullName ?? ""}/>
                        : null
                }

                {
                    this.state.eventBadgeModal && this.state.stages[2].data?.membershipNumber ?
                        <EventBadgeComponent isOpen={true}
                                             close={() => {
                                                 this.setState({eventBadgeModal: false})

                                             }}
                                             membershipNumber={this.state.stages[2].data?.membershipNumber}
                        />
                        : null
                }


                {
                    this.state.sessionReceiptModal && this.state.stages[2].sessionId ?

                        <SessionReceiptComponent isOpen={true} close={() => {
                            const {stages} = this.state;
                            stages[2].sessionId = 0;
                            this.setState({sessionReceiptModal: false, stages});
                        }} sessionId={this.state.stages[2].sessionId}/>
                        : null
                }

                {
                    this.state.ticketReceiptModal && this.state.stages[2].ticketId ?
                        <TicketReceiptComponent isOpen={true} close={() => {
                            const {stages} = this.state;
                            stages[2].ticketId = 0;
                            this.setState({ticketReceiptModal: false, stages});

                        }} ticketId={this.state.stages[2].ticketId}/>
                        : null
                }

                {
                    this.state.addTicketModal ?
                        <AddTicketConfirmComponent
                            membershipNumber={this.state.stages[2].data.membershipNumber}
                            close={() => {
                                this.setState({addTicketModal: false}, () => {
                                    this.fetchTicketsHandler();
                                })
                            }}
                            isOpen={true} tenantUserName={this.state.stages[2].data?.fullName ?? ""}/>
                        : null
                }


                {
                    this.state.membershipCardModal && this.state.stages[2]?.data?.tenantUserId ?
                        <MembershipCardReceiptComponent isOpen={true} close={() => {
                            this.setState({membershipCardModal: false})
                        }}
                                                        tenantUserId={this.state.stages[2].data.tenantUserId}
                        />
                        : null
                }

                <ModalHeader toggle={this.props.close}>Quick Access</ModalHeader>
                <ModalBody>
                    {/* first stage */}
                    {this.state.stages[1].isActive ? (
                        <>
                            <div className="mb-2">
                                <small style={{opacity: 0.65}}>
                                    {" "}
                                    *Note: Enter Membership Number , Invoice Number , Ticket Number Or Session Number to
                                    access information about
                                    membership user{" "}
                                </small>
                            </div>
                            <FormGroup>
                                <Label> Membership, Invoice, Session or Ticket Number * </Label>
                                <Input
                                    type="text"
                                    value={this.state.stages[1].membershipNumber}
                                    onChange={e => {
                                        e.preventDefault();
                                        const value = e.target.value;
                                        this.setState({
                                            validationMsg: null,
                                            stages: {
                                                ...this.state.stages,
                                                1: {...this.state.stages[1], membershipNumber: value},
                                            },
                                        });
                                    }}
                                    className={"form-control"}
                                />
                                {this.state.validationMsg ? (
                                    <p style={{color: "red"}}> {this.state.validationMsg} </p>
                                ) : null}
                            </FormGroup>
                            <div className="mt-2 text-center">
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        if (this.validateFirstStage(this.fetchData)) {
                                            this.onNextHandler(1, 2);
                                        }
                                    }}
                                    className="btn btn-primary rounder-raduis"
                                >
                                    {" "}
                                    Next
                                    <i>
                                        {" "}
                                        <AiOutlineArrowRight/>{" "}
                                    </i>{" "}
                                </button>
                            </div>
                        </>
                    ) : null}
                    {/* end-> first stage */}

                    {/* second Stage */}
                    {this.state.stages[2].isActive ? (
                        <>
                            {
                                this.state.stages[2].data ?
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card" style={{
                                                borderRadius: '13px',
                                                border: 'none',
                                                background: '#1c8879',
                                                color: '#fff'
                                            } as React.CSSProperties}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-9">
                                                            <ul style={{
                                                                padding: 0,
                                                                listStyle: 'none'
                                                            } as React.CSSProperties}>
                                                                <li>
                                                                    <small
                                                                        style={{opacity: 0.7} as React.CSSProperties}> Name </small>
                                                                    <div>
                                                                        <p style={{margin: 0} as React.CSSProperties}> {this.state.stages[2].data.fullName} </p>
                                                                    </div>
                                                                </li>


                                                                <li>
                                                                    <small
                                                                        style={{opacity: 0.7} as React.CSSProperties}> Membership
                                                                        Type </small>
                                                                    <div>
                                                                        <p style={{margin: 0} as React.CSSProperties}> {this.state.stages[2].data.membershipPlanName} </p>
                                                                    </div>
                                                                </li>


                                                                <li>

                                                                    <small
                                                                        style={{opacity: 0.7} as React.CSSProperties}> Joining
                                                                        Date </small>
                                                                    <div>
                                                                        <p style={{margin: 0} as React.CSSProperties}> {moment(this.state.stages[2].data.joinData).format("MMMM DD YYYY")}  </p>
                                                                    </div>

                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            {/* display user profile image here  */}
                                                            <div style={{
                                                                overflow: 'hidden',
                                                                width: '80px',
                                                                height: '80px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#fff'
                                                            } as React.CSSProperties}>
                                                                <img
                                                                    src={this.state.stages[2].data.avatar?.fullUrl ?? blankImg}
                                                                    alt="" style={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                } as React.CSSProperties}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <small
                                                                style={{opacity: 0.7} as React.CSSProperties}> Membership
                                                                NO. </small>
                                                            <div>
                                                                <p> {this.state.stages[2].data.membershipNumber}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <small
                                                                style={{opacity: 0.7} as React.CSSProperties}> Check-in
                                                                limits </small>
                                                            <div>
                                                                <p> {this.state.stages[2].data.checkInLimits}  </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <small
                                                                style={{opacity: 0.7} as React.CSSProperties}> Expires </small>
                                                            <div>
                                                                <p> {moment(this.state.stages[2].data.expData).format("MMMM DD YYYY")}  </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/*  Display tabs of Tickets , sessions and Invoices  */}

                                        <div className="col-sm-12 mt-4">
                                            <Nav tabs>
                                                <NavItem style={{flex: 2}}>
                                                    <NavLink style={{backgroundColor: "white"}}
                                                             className={classnames({active: this.state.stages[2].activeTab === "1"})}

                                                             onClick={e => {
                                                                 e.preventDefault();
                                                                 this.toggleTabs("1")
                                                             }}
                                                    >
                                                        Tickets
                                                    </NavLink>

                                                </NavItem>

                                                <NavItem style={{flex: 2}}>
                                                    <NavLink style={{backgroundColor: "white"}}
                                                             className={classnames({active: this.state.stages[2].activeTab === "2"})}

                                                             onClick={e => {
                                                                 e.preventDefault();
                                                                 this.toggleTabs("2")
                                                             }}
                                                    >
                                                        Sessions
                                                    </NavLink>

                                                </NavItem>


                                                <NavItem style={{flex: 2}}>
                                                    <NavLink style={{backgroundColor: "white"}}
                                                             className={classnames({active: this.state.stages[2].activeTab === "3"})}
                                                             onClick={e => {
                                                                 e.preventDefault();
                                                                 this.toggleTabs("3")
                                                             }}
                                                    >

                                                        Invoices


                                                    </NavLink>

                                                </NavItem>
                                            </Nav>


                                            <TabContent activeTab={this.state.stages[2].activeTab}>
                                                <TabPane tabId="1">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {
                                                                this.state.stages[2].tickets && this.state.stages[2].tickets.length > 0 ?
                                                                    <p> Display Tickets </p>
                                                                    :
                                                                    <div className="text-center">
                                                                        <img src={NoData} width="70%"
                                                                             style={{opacity: 0.7}}/>
                                                                    </div>
                                                            }


                                                            {
                                                                this.state.stages[2].tickets && this.state.stages[2].tickets.map((item: ITicketItem, i: number) => {
                                                                    return (

                                                                        <>
                                                                            <div className="row my-2" style={{
                                                                                background: '#f3f3f3',
                                                                                margin: 0,
                                                                                padding: '10px',
                                                                                borderRadius: '13px'
                                                                            } as React.CSSProperties}>
                                                                                <div className="col-sm-9">
                                                                                    <p style={{margin: 0} as React.CSSProperties}> {item.ticketNumber} </p>
                                                                                    <p style={{margin: 0} as React.CSSProperties}> Date: {moment(item.creatingDate).format("MMMM DD YYYY")} </p>
                                                                                </div>
                                                                                <div className="col-sm-3 text-right"
                                                                                     style={{fontSize: '25px'} as React.CSSProperties}>
                                                                                    <p
                                                                                        className={'btn-link'}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            const {stages} = this.state;
                                                                                            stages[2].showActionCardKey = i;
                                                                                            this.setState({stages});
                                                                                        }}

                                                                                        style={{
                                                                                            color: 'rgb(28, 136, 121)',
                                                                                            cursor: 'pointer'
                                                                                        } as React.CSSProperties}>
                                                                                        <AiOutlineEllipsis/>
                                                                                    </p>
                                                                                    <div
                                                                                        className="actions-card"
                                                                                        style={{
                                                                                            display:
                                                                                                this.state.stages[2].showActionCardKey == i
                                                                                                    ? "block"
                                                                                                    : " none",
                                                                                            fontSize: '16px'
                                                                                        }}
                                                                                    >
                                                                                        <ul>
                                                                                            <li
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    const {stages} = this.state;
                                                                                                    stages[2].ticketId = item.ticketId;
                                                                                                    this.setState({
                                                                                                        stages,
                                                                                                        ticketReceiptModal: true
                                                                                                    })
                                                                                                }}
                                                                                                style={{width: '115px'} as React.CSSProperties}
                                                                                                className="action-btn"
                                                                                            >
                                                                                                {" "}
                                                                                                <i style={{color: "rgb(28 136 121)"}}>
                                                                                                    <FiEye/>
                                                                                                </i>{" "}
                                                                                                View{" "}
                                                                                            </li>

                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>

                                                                    );
                                                                })
                                                            }

                                                        </div>
                                                    </div>

                                                </TabPane>


                                                <TabPane tabId="2">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {
                                                                this.state.stages[2].sessions && this.state.stages[2].sessions.length > 0 ?
                                                                    <>
                                                                        {
                                                                            this.state.loadingItems ?
                                                                                <div className="text-center">
                                                                                    <img src={spinner}/>
                                                                                </div>
                                                                                : null
                                                                        }


                                                                        {
                                                                            this.state.stages[2].sessions && this.state.stages[2].sessions.map((item: ISessionItemUsingMembershipNumberDto, i: number) => {
                                                                                return (

                                                                                    <>

                                                                                        <div className="row my-2"
                                                                                             style={{
                                                                                                 background: '#f3f3f3',
                                                                                                 margin: 0,
                                                                                                 padding: '10px',
                                                                                                 borderRadius: '13px'
                                                                                             } as React.CSSProperties}>
                                                                                            <div className="col-sm-9">
                                                                                                <p style={{margin: 0} as React.CSSProperties}> {item.sessionNumber} </p>
                                                                                                <p style={{margin: 0} as React.CSSProperties}> Date: {moment(item.creatingDate).format("MMMM DD YYYY")} </p>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-3 text-right"
                                                                                                style={{fontSize: '25px'} as React.CSSProperties}>
                                                                                                <p
                                                                                                    className={'btn-link'}
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                        const {stages} = this.state;
                                                                                                        stages[2].showActionCardKey = i;
                                                                                                        this.setState({stages});
                                                                                                    }}

                                                                                                    style={{
                                                                                                        color: 'rgb(28, 136, 121)',
                                                                                                        cursor: 'pointer'
                                                                                                    } as React.CSSProperties}>
                                                                                                    <AiOutlineEllipsis/>
                                                                                                </p>
                                                                                                <div
                                                                                                    className="actions-card"
                                                                                                    style={{
                                                                                                        display:
                                                                                                            this.state.stages[2].showActionCardKey == i
                                                                                                                ? "block"
                                                                                                                : " none",
                                                                                                        fontSize: '16px'
                                                                                                    }}
                                                                                                >
                                                                                                    <ul>
                                                                                                        <li
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                const {stages} = this.state;
                                                                                                                stages[2].sessionId = item.id;
                                                                                                                this.setState({
                                                                                                                    stages,
                                                                                                                    sessionReceiptModal: true
                                                                                                                })
                                                                                                            }}
                                                                                                            style={{width: '115px'} as React.CSSProperties}
                                                                                                            className="action-btn"
                                                                                                        >
                                                                                                            {" "}
                                                                                                            <i style={{color: "rgb(28 136 121)"}}>
                                                                                                                <FiEye/>
                                                                                                            </i>{" "}
                                                                                                            View{" "}
                                                                                                        </li>

                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })
                                                                        }


                                                                    </>
                                                                    :
                                                                    <div className="text-center">
                                                                        <img src={NoData} width="70%"
                                                                             style={{opacity: 0.7}}/>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </TabPane>


                                                <TabPane tabId="3">


                                                    <div className="card">
                                                        <div className="card-body" style={{
                                                            borderBottomLeftRadius: '13px',
                                                            borderBottomRightRadius: '13px'
                                                        } as React.CSSProperties}>
                                                            {
                                                                this.state.loadingItems ?
                                                                    <div className="text-center">
                                                                        <img src={spinner}/>
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.stages[2].invoices || this.state.stages[2].invoices.length == 0 ?
                                                                    <div className="text-center">
                                                                        <img src={NoData} width="70%"
                                                                             style={{opacity: 0.7}}/>
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.stages[2].invoices && this.state.stages[2].invoices.map((item: IInvoiceItemUsingMembershipNumberDto, i: number) => {
                                                                    return (
                                                                        <>
                                                                            <div className="row my-2" style={{
                                                                                background: '#f3f3f3',
                                                                                margin: 0,
                                                                                padding: '10px',
                                                                                borderRadius: '13px'
                                                                            } as React.CSSProperties}>
                                                                                <div className="col-sm-9">
                                                                                    <p style={{margin: 0} as React.CSSProperties}> {item.invoiceNumber} </p>
                                                                                    <p style={{margin: 0} as React.CSSProperties}> Date: {moment(item.creatingDate).format("MMMM DD YYYY")} </p>
                                                                                </div>
                                                                                <div className="col-sm-3 text-right"
                                                                                     style={{fontSize: '25px'} as React.CSSProperties}>
                                                                                    <p
                                                                                        className={'btn-link'}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            const {stages} = this.state;
                                                                                            stages[2].showActionCardKey = i;
                                                                                            this.setState({stages});
                                                                                        }}

                                                                                        style={{
                                                                                            color: 'rgb(28, 136, 121)',
                                                                                            cursor: 'pointer'
                                                                                        } as React.CSSProperties}>
                                                                                        <AiOutlineEllipsis/>
                                                                                    </p>
                                                                                    <div
                                                                                        className="actions-card"
                                                                                        style={{
                                                                                            display:
                                                                                                this.state.stages[2].showActionCardKey == i
                                                                                                    ? "block"
                                                                                                    : " none",
                                                                                            fontSize: '16px'
                                                                                        }}
                                                                                    >
                                                                                        <ul>
                                                                                            <li
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    this.viewInvoiceHandler(item.id);
                                                                                                    const {stages} = this.state;
                                                                                                    stages[2].showActionCardKey = -1;
                                                                                                    this.setState({stages});
                                                                                                }}
                                                                                                style={{width: '115px'} as React.CSSProperties}
                                                                                                className="action-btn"
                                                                                            >
                                                                                                {" "}
                                                                                                <i style={{color: "rgb(28 136 121)"}}>
                                                                                                    <FiEye/>
                                                                                                </i>{" "}
                                                                                                View{" "}
                                                                                            </li>
                                                                                            <li

                                                                                                className="action-btn"
                                                                                            >
                                                                                                {" "}
                                                                                                <i style={{color: "rgb(28 136 121)"}}>
                                                                                                    <FiDownload/>
                                                                                                </i>{" "}
                                                                                                Download{" "}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>

                                                                    );
                                                                })
                                                            }

                                                        </div>
                                                    </div>

                                                </TabPane>
                                            </TabContent>
                                        </div>

                                        {/* action buttons */}
                                        <div className="col-sm-12 mt-4">
                                            <p className="text-center">
                                                Quick Actions
                                            </p>
                                            <small className="text-center d-block text-muted"> Double click to
                                                open </small>
                                            <div>
                                                {/* slider */}
                                                <Slider {...sliderSettings}>
                                                    <div className="text-center">

                                                        <div className="quick-btn" onDoubleClick={e => {
                                                            e.preventDefault();
                                                            this.setState({addSessionModal: true})
                                                        }}>
                                                            <div className="quick-btn-card">
                                                                <img src={newSessionImg} alt=""/>
                                                            </div>
                                                            <p>
                                                                New Session
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="text-center">
                                                        <div className="quick-btn"
                                                             onDoubleClick={e => {
                                                                 e.preventDefault();
                                                                 this.setState({membershipCardModal: true})

                                                             }}

                                                        >
                                                            <div className="quick-btn-card">
                                                                <img src={membershipCardImg} alt=""/>
                                                            </div>
                                                            <p>
                                                                Membership card
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="text-center">


                                                        <div className="quick-btn"
                                                             onDoubleClick={e => {
                                                                 e.preventDefault();
                                                                 this.setState({eventBadgeModal: true})
                                                             }}
                                                        >
                                                            <div className="quick-btn-card">
                                                                <img src={printBadgeImg} alt=""/>
                                                            </div>
                                                            <p>
                                                                Print Badge
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="text-center">
                                                        <div className="quick-btn"

                                                             onDoubleClick={e => {
                                                                 e.preventDefault();
                                                                 this.setState({addTicketModal: true});
                                                             }}

                                                        >
                                                            <div className="quick-btn-card">
                                                                <img src={newTicketImg} alt=""/>
                                                            </div>
                                                            <p>
                                                                Add Ticket
                                                            </p>
                                                        </div>
                                                    </div>

                                                </Slider>
                                            </div>
                                        </div>


                                        <div className="col-sm-12 mt-4 text-center">
                                            <button

                                                style={{borderRadius: '36px'}}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    const {stages} = this.state;
                                                    stages[2].isActive = false;
                                                    stages[1].isActive = true;
                                                    this.setState({stages});
                                                }}
                                                className="btn btn-outline-secondary rounder-raduis"
                                            >
                                                {" "}
                                                Previous{" "}
                                                <i>
                                                    {" "}
                                                    <AiOutlineArrowLeft/>{" "}
                                                </i>{" "}
                                            </button>
                                        </div>


                                    </div>
                                    :
                                    <div className="m-4">
                                        <p className="text-center"> No Data Yet ! </p>
                                        <div className="text-center">
                                            <img src={NoData} width="70%" style={{opacity: 0.7}}/>
                                        </div>

                                        <div className="text-center">

                                            <button

                                                style={{borderRadius: '36px'}}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    const {stages} = this.state;
                                                    stages[2].isActive = false;
                                                    stages[1].isActive = true;
                                                    this.setState({stages});
                                                }}
                                                className="btn btn-outline-secondary rounder-raduis mt-4"
                                            >
                                                {" "}
                                                Previous{" "}
                                                <i>
                                                    {" "}
                                                    <AiOutlineArrowLeft/>{" "}
                                                </i>{" "}
                                            </button>
                                        </div>
                                    </div>
                            }
                        </>
                    ) : null}
                    {/*end -> second stages*/}
                </ModalBody>
            </Modal>
        );
    }
}
