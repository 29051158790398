/* eslint-disable react/no-deprecated */
import * as React from "react";
import {
    createMeeting,
    createZoomMeeting,
    ICiscoMeetingInvitee,
    ICreateCiscoMeetingModel, ICreateZoomMeeting
} from "../../api/ConnectServiceApi";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Loader} from "../../components/Loader";
import {getEventsDropdownlist, IEventDropdownList} from "../../api/Events";
import {getSessionsDropdownList, ISessionDropdownList} from "../../api/Sessions";
import {
    getRecipientDropdownList,
    getRecipientListDropdownList,
    IRecipientDropdownList,
    IRecipientListDropdownList
} from "../../api/RecipientLists";
import Select from "react-select";
import {nameof} from "../../util/nameof";
import {AddEditSessionForm, AddEditSessionFormModal} from "../ManageEventSessions/AddEditSessionFormModal";
import CreatableSelect from 'react-select/creatable';
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {FaEdit} from "react-icons/fa";
import RecipientsTableModal from "../../components/ReceipientGroup/RecipientsTableModal";
import {getAvailableTimeZones} from "../../api/TimeZoneApi";

import RecipientGroupSelectorComponent from '../../components/ReceipientGroup/RecipientGroupSelectorComponent';
export interface ICreateMeetingModalProps {
    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
}


export interface ICreateMeetingModalState {
    __formValid__:boolean;
    __loading__:boolean;
    __data__: any,
    events: Array<IEventDropdownList>,
    eventId:number ,
    displayEvents: boolean,
    sessions:Array<ISessionDropdownList>,
    recipients:Array<IRecipientDropdownList>,
    recipientsList : Array<IRecipientListDropdownList>,
    recipientsListEditable?: {id : number , name:string} | null,
    openRecipientsTbl:boolean,
    deSelectIds: Array<{deSelectedIds:Array<number> , recipientListId: number}>,
    meetingType:string,
    isCreateSession:boolean,
    showCreateSessionForm:boolean,
    timeZones:{label:string , value:string}[],

}

class CreateMeetingModal extends React.Component<ICreateMeetingModalProps,  ICreateMeetingModalState> {
    
    constructor(props:ICreateMeetingModalProps) {
        super(props);
        
        this.state = {
            __data__ : {
                startDateTime:new Date()
            },
            __formValid__ : true,
            __loading__ : false,
            events : [],
            displayEvents : false,
            sessions: [],
            eventId : 0,
            recipients:[],
            recipientsList: [],
            recipientsListEditable: null,
            openRecipientsTbl:false,
            deSelectIds:[],
            meetingType : "cisco",
            isCreateSession: false,
            showCreateSessionForm : false,
            timeZones:[]
        };
    }

    
    // create session handling
    createSessionOnChange = (e : any) =>{
       
        this.setState({isCreateSession:true , eventId:0 , displayEvents: true} , () => {
            
            if(this.state.events.length === 0) {
                this.fetchEvents();
            }
            
        })
    }
    // end -> create session handling
    
    
    componentWillMount() {
        this.fetchRecipientsHandler();
      
    }


    // change meeting handler.
    
    onChangeMeetingHandler = (e:any) => {
        const value = e.target.value;
        this.setState({meetingType:value} )
    }
    
    // create zoom meeting.
    
    createZoomMeeting = () => {
        const {__data__} = this.state;
        const payload: ICreateZoomMeeting = {
            topic : __data__.title,
            durationInMin: __data__.meetingHours * 60,
            sessionId: __data__.sessionId,
            startTime:__data__.startDateTime,
            createSession : this.state.isCreateSession
        };

        payload.eventId = this.state.eventId;
        createZoomMeeting(payload).then(res => {
            this.setState({__loading__: false});
            toast.success(
                <ToastMessage type="Success">
                    Create Meeting  Successfully !
                </ToastMessage>
            );
            if(this.props.close){
                this.props.close();
                if(this.props.onUpdated) {
                    this.props.onUpdated();
                }
            }
        }).catch((error:any) => {
            toast.error(
                <ToastMessage type="Error">
                {error.request?.response ?  JSON.parse(error.request.response)?.detail ?? "Error has been happened" : "Error has been happened"} 
             </ToastMessage>
            );
        })
    }

    // Fetch TimeZones...

    fetchTimeZonesHandler =  () => {
        getAvailableTimeZones().then(data => {
            const timeZones = data.map(item => {
                return {label: item , value:item}
            })
            this.setState({timeZones})
        })
    }


    // create cisco meeting
    
    createCiscoMeeting =  () => {
        const {__data__} = this.state;
        __data__.createSession  = this.state.isCreateSession;
        __data__.eventId = this.state.eventId;
        //  __data__.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
        createMeeting(__data__)
            .then(res => {
                this.setState({__loading__: false});
                if(res && res.meetingUrl) {
                    window.open(res.meetingUrl,'targetWindow');
                    toast.success(
                        <ToastMessage type="Success">
                            Create Meeting  Successfully !
                        </ToastMessage>
                    );
                    if(this.props.close){
                        this.props.close();
                        if(this.props.onUpdated) {
                            this.props.onUpdated();
                        }
                    }
                }
            }).catch( (error : any) => {
                console.error("error" , error);

            toast.error(
                <ToastMessage type="Error">
                   {error.request?.response ?  JSON.parse(error.request.response)?.detail ?? "Error has been happened" : "Error has been happened"} 
                </ToastMessage>
            );
            this.setState({__loading__: false});
        })
    }
    
    submitHandler = () => {
        // Save Meetings.
        this.setState({__loading__: true});
         
        
        if(this.state.meetingType === 'cisco') {
            this.createCiscoMeeting();
        }else {
            // todo: create zoom meeting.
            this.createZoomMeeting();
        }

    }
    
    onChangeHandler = (e:any) => {
        const name = e.target.name;
        let val :string = e.target.value;
        let data:ICreateCiscoMeetingModel = this.state.__data__;
        data = {...data , [name] : val};
        this.setState({ __data__ : data} );
    }
    
    SetInSessionHandler = () => {
        this.setState({isCreateSession:false , showCreateSessionForm: false , eventId:0  });
        if(this.state.events.length == 0) 
            this.fetchEvents();
    }
    
    fetchEvents = () => {
        this.setState({__loading__ : true})
        getEventsDropdownlist()
            .then(events => {
                this.setState({__loading__ : false})
                this.setState({events})
            }).catch(error => {
            this.setState({__loading__ : false})
        })
    }
    
    
    // fetch Sessions
    
    fetchSessions = () => {
        this.setState({__loading__:true})
        if(this.state.eventId  != 0) {
            // ready to fetch sessions.
            getSessionsDropdownList(this.state.eventId)
                .then(sessions => {
                    this.setState({sessions , __loading__ : false})
                }).catch(error => {
                this.setState({__loading__:false})
            })
        }
    }
    
    
    onChangeEventHandler = (e : any ) => {
        e.preventDefault();
        const eventId = +e.target.value;
        if(!isNaN(eventId) && eventId > 0) {
            this.setState({eventId} , () => {
                if(this.state.isCreateSession) {
                    this.setState({showCreateSessionForm:true})
                }else {
                    this.fetchSessions();
                }
               
            })
        }
    }
    
    
    onChangeSessionHandler = (e:any) => {
        e.preventDefault();
        const sessionId = e.target.value;
        if(sessionId && sessionId > 0) {
            let data = this.state.__data__;
            if(data) {
                data.sessionId = sessionId;
            }else {
                data = {
                    sessionId
                }
            }
            
            this.setState({__data__ : data} );
        }
    }
    
    
    
    // fetch recipients.
    
    fetchRecipientsHandler = () => {
        this.setState({__loading__: true})
        getRecipientDropdownList().then(recipients =>  {
            this.setState({recipients , __loading__ : false});
        }).catch(error => {
            this.setState({__loading__: false})
        })
    }
    
    // on Select Invitees.
    onChangeInviteesHandler = (options : any)  => {
        if(options) {
            const invitees: Array<ICiscoMeetingInvitee> = options.map((option:any , key: number) => {
                const result :ICiscoMeetingInvitee = {
                    email : option.value,
                    coHost: false,
                    displayName: option.value
                }
                return result;
            })

            let data = this.state.__data__;
            if(data){
                data.invitees = invitees;
            }else {
                data = {invitees}
            }
               
            this.setState({__data__ : data});
        }
      
    }
    
    
    // fetch recipient list;
    
    fetchRecipientListHandler = () => {
        
        if(this.state.recipientsList.length > 0)
             return ;
        this.setState({__loading__ : true})
        
        getRecipientListDropdownList().then( (recipientsList : IRecipientListDropdownList[]) => {
            this.setState({__loading__ : false})

            this.setState({recipientsList})
        }).catch(error => {
            this.setState({__loading__ : false})
        })
    }
    
    
    // formatOptionLabel

    formatOptionLabelHandler = (option: any , labelMeta:any) => {
    
        return (
           <div className={'d-flex'}>
               <option value={option.value} className={"test"}>
                   {option.label}
                   
                  
               </option>
              
               {
                   labelMeta.context === 'value' ?  <button 
                        onClick={e => {
                            e.preventDefault();
                            // TODO : Open Recipient table
                            this.setState({recipientsListEditable: {id : option.value , name : option.label} , openRecipientsTbl : true} , () => {
                                
                            })
                        }}
                       className={'btn css-xb97g8'}> <FaEdit /> </button> :null
               }
               {
                   labelMeta.context === 'value' ?
                       this.state.deSelectIds.length > 0 && this.state.deSelectIds.find(x => x.recipientListId === option.value) ?
                           <div className={'flex-2'}>
                               
                               <small style={{color:'red'}}> {this.state.deSelectIds.find(x => x.recipientListId === option.value)?.deSelectedIds.length.toString() + " deselected"} </small>
                           </div>
                           : null
                       : null
               }
           </div>
        );
    }
    
    
    closeRecipientsListEditable = () => {
        this.setState({recipientsListEditable: null, openRecipientsTbl : false})
        }
        
        
        deSelectedRecipientsIdHandler = (deSelectId: {deSelectedIds:Array<number> , recipientListId: number}) => {
         let {deSelectIds} = this.state;
            deSelectIds = deSelectIds.filter(x => x.recipientListId !== deSelectId.recipientListId);
            if(deSelectId.deSelectedIds.length > 0) {
                deSelectIds.push(deSelectId);
            }
          this.setState({deSelectIds});
        }
        
        // close create session modal 
    
    closeCreateSessionModal = () => {
        this.setState({showCreateSessionForm : false})
    }
    
    render() {
        const recipientOptions = this.state.recipients.map((item ,key) => {
            return  {
              value: item.email,
              label:item.name  
            };
        })
        
        const recipientListOptions = this.state.recipientsList.map( (item , key) => {
            return {
                value: item.id,
                label: item.name
            }
        } );
        
        
        const hoursOption  = Array.from({length:12} , (v , i) => {
            return {
                value: i+1,
                label:`${i+1} hours`
            }
        });
        
        
        return (
           <Modal size={"lg"} unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
               <ModalHeader toggle={this.props.close}>
                    Create Meeting 
               </ModalHeader>
               <ModalBody>
                   
                   
                   {/* Recipient table */}
                   {
                       this.state.openRecipientsTbl && this.state.recipientsListEditable  ?  
                              <RecipientsTableModal  
                                  sendDeSelectIds={this.state.deSelectIds.find(d => d.recipientListId === (this.state.recipientsListEditable ? this.state.recipientsListEditable.id : 0) )}
                                  close={this.closeRecipientsListEditable}
                                  isOpen={this.state.openRecipientsTbl} recipientListId={this.state.recipientsListEditable.id}
                                                     recipientListName={this.state.recipientsListEditable.name} receiveDeSelectIds={this.deSelectedRecipientsIdHandler}/>
                            : null
                   }
                   
                   <div className="form-group">
                       <label > Choose Meeting Service : </label>
                       <select
                            onChange={this.onChangeMeetingHandler}
                           className={"form-control"}>
                               <option > Select Meeting Type </option>
                           <option value={'cisco'}> 
                             Cisco Meeting
                           </option>
                           <option value={'zoom'}>
                               Zoom Meeting
                           </option>
                       </select>
                   </div>
                   
                   <div className="form-group">
                       <label> Meeting Title * </label>
                       <input type="text" name="title"
                              onChange={this.onChangeHandler}
                              className={"form-control"} />
                   </div>
                   
                   <div className="form-group">
                       <label >
                           password (optional)
                       </label>
                       <input
                           onChange={this.onChangeHandler}
                           autoComplete={"new-password"}
                           type="password" name="password" className={'form-control'}/>
                   </div>


                   <div className="form-group">
                       <label> Time Zone </label>
                       <Select
                           isMulti={false}
                           options={this.state.timeZones}
                           onMenuOpen={this.fetchTimeZonesHandler}
                           onChange={(v:any) => {
                               let data = this.state.__data__;
                               if(!data)  {
                                   data  = {};
                               }
                               data.timeZone = v.value;
                               this.setState({__data__ : data});
                           }}
                       />
                   </div>
                   
                   
                   <Row>
                       <Col>
                           <div className="form-group">
                               <label > Start Data * </label>
                               {/*<input*/}
                               {/*    onChange={this.onChangeHandler}*/}
                               {/*    type="datetime-local" name="startDateTime" className={'form-control'}/>*/}
                               <ReactDatetime
                                   value={this.state.__data__ ? moment(this.state.__data__.startDateTime) : moment(new Date())}
                                   onChange={(
                                       dateTime: moment.Moment | string
                                   ) => {
                                      const date = moment(dateTime).toJSON();
                                      let data = this.state.__data__;
                                      if(data){ 
                                          data.startDateTime = date;
                                      }else {
                                          data = {
                                              startDateTime : date
                                          }
                                      }
                                      
                                      this.setState({__data__ : data} )
                                   }}
                               />
                           </div>
                       </Col>
                       <Col>
                           <div className="form-group">
                               <label > Duration of Meeting * </label>
                               {/*<input*/}
                               {/*    onChange={this.onChangeHandler}*/}
                               {/*    type="datetime-local" name="endDateTime" className={'form-control'}/>*/}
                               {/*<ReactDatetime*/}
                               
                               {/*    */}
                               {/*    onChange={(*/}
                               {/*        dateTime: moment.Moment | string*/}
                               {/*    ) => {*/}
                               {/*        const date = moment(dateTime).toJSON();*/}
                               {/*        let data = this.state.__data__;*/}
                               {/*        if(data){*/}
                               {/*            data.endDateTime = date;*/}
                               {/*        }else {*/}
                               {/*            data = {*/}
                               {/*                endDateTime : date*/}
                               {/*            }*/}
                               {/*        }*/}
                               
                               {/*        this.setState({__data__ : data} , () => {*/}
                               {/*            ;*/}
                               {/*        })*/}
                               {/*    }}*/}
                               {/*    */}
                               {/*/>*/}
                               
                           {/*  TODO: Add select option to choose between hours  */}
                           <Select
                               isMulti={false}
                               options={hoursOption}
                               onChange={ (v:any)  => {
                                   let data = this.state.__data__;
                                   if(data){
                                       data.meetingHours = v.value;
                                   }else {
                                       data = {
                                           meetingHours : v.value
                                       }
                                   }
                                   this.setState({__data__: data} )
                               }}
                           />
                               
                           </div>
                       </Col>
                   </Row>
                  
                  <div className="form-group d-flex justify-content-center">
                      <div className="form-check">
                          <label className="form-check-label mr-4"

                                 htmlFor="exampleCheck1"> Set In Session </label>
                          <input type="radio"
                                 onChange={e => {
                                     if(e.target.checked) {
                                         this.SetInSessionHandler();
                                     }
                                     this.setState({displayEvents : e.target.checked})
                                 }}
                                 className="form-check-input" name={'session'} />
                      </div>

                      <div className="form-check">
                          <label className="form-check-label mr-4"

                                 htmlFor="exampleCheck1"> Create Session </label>
                          <input type="radio"
                                 onChange={this.createSessionOnChange}
                                 className="form-check-input" name={'session'} />
                      </div>
                  </div>
                
                   {
                       this.state.events.length > 0 && this.state.displayEvents ?
                           <div className={"form-group"}>
                               <label>
                                   Choose An event *
                               </label>
                               <select 
                                className={"form-control"}
                                onChange={this.onChangeEventHandler}
                                value={this.state.eventId}
                               >
                                   <option value={0}> Select event </option>
                                   {
                                       this.state.events.map ((event, key) => {
                                           return (
                                               <option key={key} value={event.id}>
                                                   {event.name}
                                               </option>
                                           );
                                       } )
                                   }
                               </select>
                           </div>
                       : null
                   }
                   
                   {
                       this.state.sessions.length > 0 && this.state.displayEvents && this.state.eventId > 0 && !this.state.isCreateSession ?
                             <div className={"form-group"}>
                                 <label>
                                     Sessions
                                 </label>
                                 <select
                                     onChange={this.onChangeSessionHandler}
                                     className={"form-control"}>
                                     <option  > Select Session </option>
                                     {
                                         this.state.sessions.map((session , key ) => {
                                             return (
                                                 <option key={key} value={session.id}>
                                                     {session.name}
                                                 </option>
                                             );
                                         })
                                     }
                                 </select>
                             </div>
                           
                            : null
                   }
                   
                   {/*{*/}
                   {/*    this.state.displayEvents && this.state.isCreateSession && this.state.showCreateSessionForm && this.state.eventId > 0 ?*/}
                   
                   {/*        <div style={{zIndex:999}}>*/}
                   {/*            <AddEditSessionFormModal*/}
                   
                   {/*                eventId={this.state.eventId}*/}
                   {/*                isOpen={this.state.showCreateSessionForm}*/}
                   {/*                closeModal={this.closeCreateSessionModal}*/}
                   {/*            />*/}
                   {/*        </div>*/}
                   {/*        :null*/}
                   {/*}*/}
                   
                   
                   
                   {/* <div className={'form-group'}>
                       <label> Recipient Group : </label>
                       <Select
                           
                           isMulti={true}
                           options={recipientListOptions}
                           onMenuOpen={this.fetchRecipientListHandler}
                           isSearchable={true}
                           formatOptionLabel={this.formatOptionLabelHandler}
                           onChange={val => {
                               ;
                           }}
                       /> 
                   </div> */}

                   <RecipientGroupSelectorComponent onChange={(output) => {
                   }} />
                   
                   <div className={'form-group'}>
                       <label >
                           invitees email (optional)
                       </label>
                       {/*<input*/}
                       {/*    */}
                       {/*    onChange={this.onChangeHandler}*/}
                       {/*    type="email" name="invitees" className={'form-control'}/>*/}



                       <CreatableSelect
                           isClearable
                           isMulti={true}
                           onChange={this.onChangeInviteesHandler}
                           // onChange={this.handleChange}
                           // onInputChange={this.handleInputChange}
                           options={recipientOptions}
                       />
                   </div>

               </ModalBody>

               <ModalFooter>
                       <button className="btn btn-secondary"
                               onClick={this.props.close}
                       > Cancel </button>
                       <button  onClick={this.submitHandler} className="btn btn-primary" disabled={!this.state.__formValid__}> Save </button>
                       {
                           this.state.__loading__ ?  <Loader isLoading={true} /> : null
                       }
               </ModalFooter>
           </Modal>
        );
    }
}

export default CreateMeetingModal;