import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import {
    CommitteeGroup,
    getCommitteeGroupsByEvent
} from "../api/CommitteeGroup";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import { NormalizedPagination } from "./NormalizedPagination";
import { committeeGroupsListSchema } from "./Schemas";

export interface CommitteeGroupsState {
    [key: string]: CommitteeGroup | {};

    pagination: {
        byEventId: NormalizedPagination;
    };
}

const initialState: CommitteeGroupsState = {
    pagination: {
        byEventId: {}
    }
};

interface RequestCommitteeGroupsPaginationAction {
    eventId: number;
}

interface ReceiveCommitteeGroupsPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const committeeGroupListSlice = createSlice({
    name: "committeeGroups",
    initialState,
    reducers: {
        requestCommitteeGroupPaginatedList(
            state,
            action: PayloadAction<RequestCommitteeGroupsPaginationAction>
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    isFetching: true
                }
            };
        },
        receiveCommitteeGroupPaginatedList(
            state,
            action: PayloadAction<ReceiveCommitteeGroupsPaginationAction>
        ) {
            const { eventId, ...rest } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    ...rest,
                    isFetching: false
                }
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["committeeGroups"]
            };
        })
    })
});

export default committeeGroupListSlice.reducer;
const {
    requestCommitteeGroupPaginatedList,
    receiveCommitteeGroupPaginatedList
} = committeeGroupListSlice.actions;

export const fetchPaginatedCommitteeGroupByEventId = (
    eventId: number,
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(
            requestCommitteeGroupPaginatedList({
                eventId: eventId
            })
        );

        const result = await getCommitteeGroupsByEvent(eventId, request);

        const normalizedResult = normalize(
            result.items,
            committeeGroupsListSchema
        );

        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            receiveCommitteeGroupPaginatedList({
                eventId,
                ids: normalizedResult.result,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount
            })
        );
    } catch (e) {
        dispatch(
            receiveCommitteeGroupPaginatedList({
                eventId,
                error: "e.toString()",
                ids: getState().committeeGroups.pagination.byEventId[
                    eventId.toString()
                ]?.ids,
                pageIndex: getState().committeeGroups.pagination.byEventId[
                    eventId.toString()
                ]?.pageIndex,
                pageSize: getState().committeeGroups.pagination.byEventId[
                    eventId.toString()
                ]?.pageSize,
                totalItemsCount: getState().committeeGroups.pagination
                    .byEventId[eventId.toString()]?.totalItemsCount
            })
        );
    }
};
