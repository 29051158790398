/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { MainCard } from "../../components/MainCard";
import "./changePasswordSubTenant.css";

class ChangePasswordSubTenant extends Component<any, any> {
    constructor(props: any) { super(props); }

    componentWillMount() {
        const text = new URLSearchParams(this.props.location.search).get('text');
        alert(text)
    }

    render() {
        return (
            <div className={'fill-screen bg-signup'}>
                <div className="d-flex justify-content-center pt-5">
                    <MainCard style={{ flex: 0.3 }}>
                        <>
                            <h5> Set Password </h5>
                        </>
                        <>
                            <div className={'form-group'}>
                                <label> Password </label>
                                <input type={'password'} className={'form-control'} name={'password'} />
                            </div>
                            <div className={'form-group'}>
                                <label> Confirm Password </label>
                                <input type={'password'} className={'form-control'} name={'confirmPassword'} />
                            </div>
                            <div className={'form-group'}>
                                <button className={'btn btn-primary'}>
                                    Save
                                </button>
                            </div>
                        </>
                    </MainCard>
                </div>
            </div>
        );
    }
}

export default ChangePasswordSubTenant;