import { AddSession } from 'api/SmartAccessApi';
import { Loader } from 'components/Loader';
import { ToastMessage } from 'components/ToastMessages';
import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


interface OwnProps {
  close: () => void;
  isOpen: boolean;
  tenantUserName: string;
  membershipNumber: string;
}

type Props = OwnProps;

export default class AddSessionSmartAccessComponent extends React.Component<Props, any> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    }

  }


  onConfirmHandler = () => {
    this.setState({ loading: true });
    AddSession(this.props.membershipNumber).then(res => {
      this.setState({ loading: false });
      toast.success(
        <ToastMessage>
          Added successfully.
        </ToastMessage>
      );
      this.props.close?.();
    }, error => {
      this.setState({ loading: false });
      toast.error(
        <ToastMessage>
          Error has been happened !
        </ToastMessage>
      );
    })
  }

  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >
        <ToastContainer />
        {this.state.loading ? <Loader isLoading={true} /> : null}

        <ModalHeader toggle={this.props.close}> Add Schedule Item  </ModalHeader>
        <ModalBody >
          <div className="row">
            <div className="col-sm-12">
              <p> Are you sure to add session for <strong>{this.props.tenantUserName}</strong> </p>
              <div className="mt-4 text-center">
                <button className="btn btn-primary rounder-raduis mx-2"

                  onClick={e => {
                    e.preventDefault();
                    this.props.close?.();
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={this.onConfirmHandler}
                  className="btn btn-success rounder-raduis mx-2">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
