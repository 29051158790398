import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { FaSpinner } from "react-icons/fa";
import {
  configureEmtpService,
  configureGmailService,
  getEmtpService,
  getGmailService,
  GmailService,
  SmtpService,
  testGmail
} from "../../api/ConnectServiceApi";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "../../components/GoogleLoginButton/GoogleLoginButton";


export interface ConfigureEmailServiceProps {
  name: string;
  isOpen: boolean;
  close: (success?: boolean) => void;
}


export const ConfigureEmailService: React.FC<ConfigureEmailServiceProps> = props => {

  const [isGmailService, setIsGmailService] = useState(props.name === "Gmail");

  const [emailService, setEmailService] = useState<any | null>(null);
  const [testEmail, setTestEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (props.isOpen) {
      if (isGmailService) {
        getGmailService().then(service => setEmailService(service !== "" ? service : null));
      } else {
        getEmtpService().then(service => setEmailService(service !== "" ? service : null));
      }
    }
  }, [props.isOpen])

  const handleFormSubmit = React.useCallback(
    async (
      values: any,
    ) => {


      let promise = null;

      if (isGmailService) {
        promise = configureGmailService(values as GmailService);
      } else {
        promise = configureEmtpService(values as SmtpService);
      }

      promise.then(resp => {
        toast.success(
          <ToastMessage>
            Email Service Configured Successfully!
          </ToastMessage>
        );
        props.close(true);
      }).catch(err => {
        toast.error(
          <ToastMessage type="Error">
            Error Configuring Email Service
          </ToastMessage>
        );
      })
    }
    , [props])
  const testEmailSend = () => {
    testGmail({ emailForTest: testEmail }).then(resp => {
      toast.success(
        <ToastMessage>
          Test Email sent successfully
        </ToastMessage>
      );
    }).catch(err => {
      toast.error(
        <ToastMessage type="Error">
          Error while sending the test Email
        </ToastMessage>
      );
    })

  }


  const renderForm =
    (formProps: FormikProps<any>) =>
      <Form>
        <ModalBody>

          {isGmailService ?
            <>
              <FormGroup>
                <Label>Client Id</Label>
                <Field
                  name={"clientId"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} onChange={event => {
                      setIsAuthenticated(false);
                      field.onChange(event)
                    }}/>
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Label>Client Secret</Label>
                <Field
                  name={"clientSecret"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} type={'password'}/>
                  )}
                />
              </FormGroup>
            </>
            :
            <>
              <FormGroup>
                <Label>Server</Label>
                <Field
                  name={"server"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} />
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Label>Port</Label>
                <Field
                  name={"port"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} type={"number"}/>
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Label>Sender Name</Label>
                <Field
                  name={"senderName"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label>Sender Email</Label>
                <Field
                  name={"senderEmail"}
                  render={({ field }: FieldProps) => (
                    <Input {...field} />
                  )}
                />
              </FormGroup>


              <FormGroup>
                <Label>password</Label>
                <Field
                  name={"password"}
                  render={({ field }: FieldProps) => (
                    <Input
                      {...field}
                      autoComplete={"new-password"}
                      type="password"
                      placeholder={"••••••"}
                    />
                  )}
                />
              </FormGroup>

            </>
          }
          <FormGroup>
            <Label>Email For Test</Label>
            <InputGroup>
              <Input onChange={(event) => {
                setTestEmail(event.target.value)
              }}/>
              <Button color="primary" onClick={() => testEmailSend()}
                      disabled={!testEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(testEmail)}>
                Test
              </Button>
            </InputGroup>
          </FormGroup>


          <Row>
            <Col>
              <FormGroup switch>
                <Label>Is Default</Label>
                <Field
                  name={"isDefault"}
                  render={({ field }: FieldProps) => (
                    <Input
                      type="switch"
                      label="Active"
                      id={field.name}
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            {!isGmailService && <Col>
                <FormGroup switch>
                    <Label>SSL</Label>
                    <Field
                        name={"isSsl"}
                        render={({ field }: FieldProps) => (
                          <Input
                            type="switch"
                            label="SSL"
                            id={field.name}
                            checked={field.value}
                            {...field}
                          />
                        )}
                    />
                </FormGroup>
            </Col>}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => props.close(false)}
          >
            Cancel
          </Button>
          <div className={'gap-2 w-50 d-flex flex-row-reverse'}>

            <Button
              type="submit"
              color="primary"
              disabled={formProps.isSubmitting || isGmailService && (!isAuthenticated || !formProps.values.clientSecret)}
            >
              {formProps.isSubmitting && (
                <span>
                                    <FaSpinner className="icon-spin"/>
                                </span>
              )}{" "}
              Save
            </Button>
            {isGmailService && formProps.values.clientId &&
            <GoogleOAuthProvider
                clientId={formProps.values.clientId ?? ''}
            >
                <GoogleLoginButton
                    disabled={isAuthenticated}
                    onSuccess={async (credentialResponse: any) => {

                      const response = await fetch("https://oauth2.googleapis.com/token", {
                        method: 'POST',
                        body: JSON.stringify({
                          code: credentialResponse.code,
                          client_id: formProps.values.clientId,
                          client_secret: formProps.values.clientSecret,
                          grant_type: "authorization_code",
                          redirect_uri: window.location.protocol + "//" + window.location.host
                        })
                      })
                      if (response.ok) {
                        const jsonResponse = await response.json()
                        setIsAuthenticated(true);
                        await formProps.setFieldValue("googleJsonResponse", JSON.stringify(jsonResponse))
                      } else {
                        toast.error(
                          <ToastMessage type="Error">
                            Error Fetching Token
                          </ToastMessage>
                        );
                      }
                    }}
                    onError={() => {
                      toast.error(
                        <ToastMessage type="Error">
                          Error Login to Google Service
                        </ToastMessage>
                      );
                    }}
                >
                    Authenticate
                </GoogleLoginButton>
            </GoogleOAuthProvider>}
          </div>
        </ModalFooter>
      </Form>


  return (
    <Modal unmountOnClose isOpen={props.isOpen} size="lg">
      <ModalHeader toggle={() => props.close(false)}>
        Configure Email Service {props.name}
      </ModalHeader>

      <Formik
        initialValues={emailService ?? {}}
        enableReinitialize
        onSubmit={handleFormSubmit}
        render={renderForm}
      />
    </Modal>
  );
}