import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalize} from "normalizr";
import {addEntities, AddEntitiesAction} from "store/Actions";
import {AppThunk} from ".";
import {getOrdersList, GetOrdersListRequest, Order} from "../api/EventOrdersApi";
import {NormalizedPagination} from "./NormalizedPagination";
import {orderSchema} from "./Schemas";

export interface OrderState {
    [key: string]: Order | {};

    pagination: {
        byEventId: NormalizedPagination;
    };
}

const initialState: OrderState = {
    pagination: { byEventId: {} }
};

interface RequestFetchOrderPaginationAction {
    eventId: number;
}

interface ReceiveFetchOrderPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        RequestFetchOrderPagination(
            state,
            action: PayloadAction<RequestFetchOrderPaginationAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventId[eventId] = {
                ...state.pagination.byEventId[eventId],
                isFetching: true
            };
        },

        receiveFetchOrderPagination(
            state,
            action: PayloadAction<ReceiveFetchOrderPaginationAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventId[eventId] = {
                ...action.payload,
                isFetching: false
            };
        }
    },

    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["orders"]
            };
        })
    })
});

export default ordersSlice.reducer;

const {
    RequestFetchOrderPagination,
    receiveFetchOrderPagination
} = ordersSlice.actions;

export const fetchPaginatedOrdersByEvent = (
    request: GetOrdersListRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(RequestFetchOrderPagination({ eventId: request.eventId }));

        const result = await getOrdersList(request);
        const normalized = normalize(result.items, Array(orderSchema));

        dispatch(addEntities(normalized.entities as any));

        dispatch(
            receiveFetchOrderPagination({
                ...result.meta,
                eventId: request.eventId,
                error: undefined,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchOrderPagination({
                ...getState().productCategories.pagination.byEventId[
                    request.eventId.toString()
                ],
                eventId: request.eventId,
                error: "e.toString()"
            })
        );
    }
};
