import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DeviceNotificationMessage,
    sendDeviceNotificationBroadcast
} from "../api/DeviceNotification";
import { AppThunk } from "./index";

export interface BroadcastDeviceNotificationState {
    isBusy: boolean;
    error: string | null;
}

const initialState: BroadcastDeviceNotificationState = {
    isBusy: false,
    error: null
};

const broadcastDeviceNotification = createSlice({
    name: "broadcastDeviceNotification",
    initialState,
    reducers: {
        requestBroadcastNotification(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveBroadcastNotification(
            state,
            action: PayloadAction<string | null>
        ) {
            state.isBusy = false;
            state.error = action.payload;
        }
    }
});

export default broadcastDeviceNotification.reducer;

export const sendDeviceNotificationMessage = (
    message: DeviceNotificationMessage
): AppThunk => async (dispatch, getState) => {
    try {
        if (getState().broadcastDeviceNotification.isBusy) return;

        dispatch(
            broadcastDeviceNotification.actions.requestBroadcastNotification()
        );
        const result = await sendDeviceNotificationBroadcast(message);
        dispatch(
            broadcastDeviceNotification.actions.receiveBroadcastNotification(
                null
            )
        );
    } catch (err) {
        ;
        dispatch(
            broadcastDeviceNotification.actions.receiveBroadcastNotification(
                "err.toString()"
            )
        );
    }
};
