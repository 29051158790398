import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IDurationPlan } from "../../api/ProductsApi";
import { getTaxesDropdownList } from "../../api/TaxApi";
import DurationPlanItemCard from "./DurationPlanItemCard";


interface IDurationPlanViewComponentProps {
    DurationsPlan: Array<IDurationPlan>;
    open: boolean;
    close: () => unknown;
    planName: string;
}

class DurationPlanViewComponent extends React.Component<IDurationPlanViewComponentProps, any> {

    constructor(props: IDurationPlanViewComponentProps) {
        super(props);
        this.state = {
            taxes: []
        }
    }
    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
        this.fetchTaxes();
    }

    fetchTaxes = () => {
        getTaxesDropdownList().then(r => {
            this.setState({ taxes: r });
        });
    }

    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.open} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    {`Durations of ${this.props.planName} plan`}
                </ModalHeader>
                <ModalBody>
                    {
                        this.state.taxes.length && this.props.DurationsPlan.map((item: IDurationPlan, index: number) => {
                            return (
                                <>
                                <DurationPlanItemCard item={item} wholeTaxes={this.state.taxes} />
                                </>

                            );
                        })
                    }
                </ModalBody>
            </Modal>
        );
    }
}

export default DurationPlanViewComponent;