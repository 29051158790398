/* eslint-disable react/prop-types */
import { getRecipientList } from "api/SendEmail";
import { EmptyListState } from "components/EmptyListState";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DefaultPageSize } from "../../components/Constants";
import { DataTable } from "../../components/DataTable";

const ListSentModal = (props) => {
  const { isOpen, toggle, data } = props;
  const params = useParams();
  const eventId = Number(params.eventId);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [listData, setResults] = React.useState({});

  const [items, setItems] = React.useState([]);

  const getData = async () => {
    const res = await getRecipientList(props.data.id);
    if (res && res.items) {
      setResults(res);
    }
  };

  React.useEffect(() => {
    getData();
  }, [isOpen]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Records",
        columns: [
          {
            Header: "Email",
            accessor: "recipientEmail",
          },
          {
            Header: "Phone",
            accessor: "recipientPhoneNumber",
          },
          {
            Header: "Status",
            accessor: "status",
          },
        ],
      },
    ],
    [listData]
  );

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Recipents</ModalHeader>
        <ModalBody>
          <DataTable
            columns={columns}
            data={listData?.items ? listData.items : []}
            isBusy={false}
            emptyState={<EmptyListState title={"You have no data"} />}
            pagination={{
              show: true,
              totalItemsCount:
                listData && listData.meta ? listData.meta.totalItemsCount : 0,
              pageIndex: pageIndex,
              pageSize: pageSize,
              //   onPageClicked: handlePageChange,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ListSentModal;
