import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    CommitteeGroup,
    CommitteeGroupByEventRequest,
    getCommitteeGroupsByEvent
} from "../api/CommitteeGroup";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { AppThunk } from "./index";

//TODO: move the whole thing to committeeGroups

export interface SelectCommitteeGroupListState {
    isBusy: boolean;
    request: CommitteeGroupByEventRequest | null;
    result: PaginatedResult<CommitteeGroup> | null;
    eventId: number | null;
    error: string | null;
}

const initialState: SelectCommitteeGroupListState = {
    isBusy: false,
    error: null,
    request: null,
    eventId: null,
    result: null
};

interface ReceiveCommitteeGroupListAction {
    error: string | null;
    request: CommitteeGroupByEventRequest;
    eventId: number;
    result: PaginatedResult<CommitteeGroup> | null;
}

const SelectCommitteeGroupListSlice = createSlice({
    name: "SelectCommitteeGroupList",
    initialState,
    reducers: {
        requestSelectCommitteeGroup(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveSelectCommitteeGroup(
            state,
            action: PayloadAction<ReceiveCommitteeGroupListAction>
        ) {
            state.isBusy = false;
            state.error = action.payload.error;
            state.request = action.payload.request;
            state.eventId = action.payload.eventId;
            state.result = action.payload.result;
        }
    }
});

export default SelectCommitteeGroupListSlice.reducer;

const {
    requestSelectCommitteeGroup,
    receiveSelectCommitteeGroup
} = SelectCommitteeGroupListSlice.actions;

export const loadSelectCommitteeGroupList = (
    eventId: number,
    request: CommitteeGroupByEventRequest
): AppThunk => async dispatch => {
    try {
        dispatch(requestSelectCommitteeGroup());
        const result = await getCommitteeGroupsByEvent(eventId, request);
        dispatch(
            receiveSelectCommitteeGroup({
                eventId: eventId,
                error: null,
                request: request,
                result: result
            })
        );
    } catch (e) {
        dispatch(
            receiveSelectCommitteeGroup({
                eventId: eventId,
                error: "e.toString()",
                request: request,
                result: null
            })
        );
    }
};

export const reloadSelectCommitteeGroupList = (): AppThunk => (
    dispatch,
    getStatus
) => {
    const committeeGroupListState = getStatus().selectCommitteeGroupList;
    if (committeeGroupListState.request && committeeGroupListState.eventId) {
        dispatch(
            loadSelectCommitteeGroupList(
                committeeGroupListState.eventId,
                committeeGroupListState.request
            )
        );
    }
};
