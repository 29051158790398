import PropTypes from "prop-types";
import * as React from "react";

interface SideNavContainerProps {
  className?: string;
  children: React.ReactNode;

  isMobileOpen: boolean;
}

export const SideNav: React.FC<SideNavContainerProps> = (props) => {
  return (
    <div className={props.className}>
      <div
        className={
          "side-nav-container d-flex flex-column " +
          (props.isMobileOpen ? "show" : "")
        }
      >
        {props.children}
      </div>
    </div>
  );
};

SideNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
