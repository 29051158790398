/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/no-deprecated */
import { GetProductDropdownListByCategories } from 'api/ProductsApi';
import { Loader } from 'components/Loader';
import { ToastMessage } from 'components/ToastMessages';
import moment from 'moment';
import React, { Component } from 'react';
import ReactDatetime from "react-datetime";
import { AiOutlinePercentage } from "react-icons/ai";
import { FaSync } from "react-icons/fa";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import {
    Button,
    ButtonGroup,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {
    AddCoupon,
    EditCoupon,
    GetCouponMemberships,
    GetCouponProductCategories,
    GetCouponProducts,
    ICouponModel,
    IEditCoupon,
    SearchItems
} from '../../api/CouponApi';
import Select from "react-select";
import { SearchCategories } from "../../api/ProductCategoriesApi";


export enum ModalStatus {
    __CREATE__, __EDIT__
}

interface IAddEditCouponModalProps {

    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
    modalStatus: ModalStatus;
    item?: ICouponModel | null
}

export default class AddEditCouponComponent extends Component<IAddEditCouponModalProps, any> {

    constructor(props: IAddEditCouponModalProps) {
        super(props);
        this.state = {
            data: {
                includeAllProducts: true,
                applyAutomatically: false,
                forAllMembershipPlans: true,
                membershipPlansIds: [],
                productIds: [],
                productCategoryIds: [],
                byCategory: false,
            },
            loading: false,
            formValid: false,
            products: [],
            categories: [],
            memberships: [],
        }
    }


    componentWillMount() {
        if (this.props.item) {
            this.setState({data: this.props.item});
            const couponId = this.props.item.id;

            if (!this.props.item.includeAllProducts) {
                this.fetchProductsHandler();
                this.fetchCategoriesHandler();
                GetCouponProducts(couponId).then(products => {
                    let {data} = this.state;
                    if (products.items) {
                        data.productIds = [...products.items.map(p => p.product.id)];
                        data.includeAllProducts = false;
                        this.setState({
                            ...this.state,
                            data: data,
                            products: products.items.map(it => it.product)
                        })
                    }
                })

                GetCouponProductCategories(couponId).then(categories => {
                    let {data} = this.state;
                    if (categories.items) {
                        const productCategoryIds = [...categories.items.map(c => c.id)];
                        data.includeAllProducts = false;
                        data.productCategoryIds = productCategoryIds;
                        data.byCategory = productCategoryIds.length > 0;
                        this.setState({
                            ...this.state,
                            data: data,
                            categories: [...categories.items]
                        }, ()=>this.searchCategoriesItems(null));
                    }
                })
            }

            if (!this.props.item.forAllMembershipPlans) {
                GetCouponMemberships(couponId).then(memberships => {
                    let {data} = this.state;
                    if (memberships.items) {
                        data.membershipPlansIds = [...memberships.items.map(m => m.id)];
                    }
                    this.setState({data}, () => this.fetchMemberships())
                })
            }
        }
    }


    generateCouponCodeHandler = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (var i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        let {data} = this.state;
        if (!data) data = {};
        data.code = result
        this.setState({data})
    }

    onChangeHandler = (e: any) => {
        e.preventDefault();
        let {value, name} = e.target;
        if(name==="discountPercentage" && (value > 100 || value < 0)) {
            e.target.value = null;
            return;
        }
        let {data} = this.state;
        if (!data) data = {};
        if(["maxUsagePerUser","totalQuantity"].includes(name)) value = +value;
        data[name] = value;
        this.setState({data});
    }

    searchProductItems = (e: string | null) => {
        if ((e && e.length > 2) || e === null) {
            this.setState({loading: true})
            SearchItems(e).then(items => {
                    this.setState({...this.state, loading: false, products: items });
                }
            ).catch(err => {
                this.setState({loading: false})
            }).finally(()=>{
                this.setState({loading: false})
            })
        }
    }

    searchCategoriesItems = (e: string | null) => {
        if ((e && e.length > 2) || null) {
            this.setState({loading: true})
            SearchCategories().then(categories => {
                this.setState({categories: categories, loading: false});
            });
        }
    }

    fetchProductsHandler = () => {
        if (this.state.products.length === 0) {
            this.searchProductItems(null);
            // GetProductDropdownList().then(products => {
            //     this.setState({loading: false})
            //     if (!products) return;
            //     this.setState({products});
            // }).catch(err=>this.setState({loading: false}))
        }
    }

    fetchCategoriesHandler = () => {
        if (this.state.categories.length === 0) {
            this.setState({loading: true})
            SearchCategories().then(categories => {
                // this.setState({loading: false})
                // if (!categories) return;
                this.setState({categories, loading: false});
            })
        }
    }

    fetchMemberships = () => {
        if (this.state.memberships.length === 0) {
            this.setState({loading: true})
            GetProductDropdownListByCategories(3).then(memberships => {
                // if (!memberships) return;
                this.setState({memberships, loading: false});
            })
        }
    }

    onSubmitHandler = () => {
        if (this.props.modalStatus === ModalStatus.__CREATE__) this.addCouponHandler()
        else if (this.props.modalStatus === ModalStatus.__EDIT__) this.editCouponHandler();
    }

    addCouponHandler = () => {
        if (this.state.data) {
            if (!this.state.data.startDate) this.state.data.startDate = moment(new Date());
            if (!this.state.data.endDate) this.state.data.endDate = moment(new Date().setHours(24 * 3));

            if(this.state.data.includeAllProducts){
                this.state.data.productIds = null;
                this.state.data.productCategoryIds = null;
            } else {
                if (this.state.data.byCategory) {
                    this.state.data.productIds = null;
                } else {
                    this.state.data.productCategoryIds = null;
                }
            }

            if(this.state.data.forAllMembershipPlans){
                this.state.data.membershipPlansIds = null;
            }

            this.setState({loading: true})
            AddCoupon(this.state.data).then(_ => {
                this.setState({loading: false})
                toast.success(
                    <ToastMessage>
                        added successfully !
                    </ToastMessage>
                );
                this.props?.onUpdated?.();
                this.props?.close?.();
            }, _ => {
                this.setState({loading: false})
                toast.error(
                    <ToastMessage>
                        Error adding coupon, please try again later
                    </ToastMessage>
                );
            })
        }
    }


    editCouponHandler = () => {
        if (this.state.data && this.props.modalStatus === ModalStatus.__EDIT__) {
            this.setState({loading: true});

            const payload: IEditCoupon = {
                forAllMembershipPlans: this.state.data.forAllMembershipPlans,
                includeAllProducts: this.state.data.includeAllProducts,
                code: this.state.data.code,
                discountPercentage: this.state.data.discountPercentage,
                endDate: this.state.data.endDate,
                startDate: this.state.data.startDate,
                productIds: !this.state.data.byCategory ? this.state.data.productIds : null,
                productCategoryIds: this.state.data.byCategory ? this.state.data.productCategoryIds : null,
                membershipPlansIds: this.state.data.forAllMembershipPlans ? null : this.state.data.membershipPlansIds,
                maxUsagePerUser: this.state.data.maxUsagePerUser,
                autoApply: this.state.data.autoApply,
                totalQTY: this.state.data.totalQTY
            };

            EditCoupon(this.state.data.id, payload).then(_ => {
                toast.success(
                    <ToastMessage>
                        Edited successfully !
                    </ToastMessage>
                );

                this.setState({loading: false})
                this.props?.onUpdated?.();
                this.props?.close?.();
            }, _ => {
                this.setState({loading: false})
                toast.error(
                    <ToastMessage>
                        error has been happened
                    </ToastMessage>
                );
            })
        }
    }


    render() {
        return (
            <Modal size="lg" unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    {
                        this.props.modalStatus === ModalStatus.__EDIT__ ? " Edit Coupon" : "Add Coupon"
                    }
                </ModalHeader>
                <ModalBody>
                    {/*<ToastContainer/>*/}
                    {this.state.loading ? <Loader isLoading={true}/> : null}
                    <FormGroup>
                        <Label> Coupon Code* </Label>
                        <InputGroup>
                            <Input type="text"
                                   onChange={this.onChangeHandler}
                                   value={this.state.data?.code}
                                   name="code" required/>
                            <Button
                                onClick={this.generateCouponCodeHandler}
                                color="primary"> <i> <FaSync/> </i> Generate Code </Button>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label> Discount </Label>
                        <InputGroup>
                            <Input type="number"
                                   onChange={this.onChangeHandler}
                                   min="0"
                                   max="100"
                                   value={this.state.data?.discountPercentage}
                                   name="discountPercentage" required/>
                            <Button disabled> <i> <AiOutlinePercentage/> </i> </Button>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label> For </Label>
                        <FormGroup check>
                            <Label check>
                                <Input checked={this.state.data?.forAllMembershipPlans}
                                       onChange={e => {
                                           const {checked} = e.target;
                                           const {data} = this.state;
                                           data.forAllMembershipPlans = checked;
                                           if (!checked) this.fetchMemberships();
                                           if (checked && data.membershipPlansIds?.length > 0) data.membershipPlansIds = [];
                                           this.setState({data});
                                       }}
                                       type="checkbox"/>{' '}
                                All users
                            </Label>
                        </FormGroup>
                        {!this.state.data.forAllMembershipPlans &&
                        <FormGroup>
                            <Select
                                isMulti={true}
                                options={this.state.memberships || []}
                                value={this.state.data.membershipPlansIds &&
                                this.state.memberships.filter((m: any) => this.state.data.membershipPlansIds.includes(m.id))}
                                onChange={(o: any) => {
                                    const selectedMemberships = this.state.data.membershipPlansIds;
                                    const {data} = this.state;
                                    data.membershipPlansIds = o.map((m: { id: any; }) => m.id);
                                    this.setState({data});
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id + ""}
                            />
                        </FormGroup>
                        }
                    </FormGroup>

                    <FormGroup>
                        <Label> Apply </Label>
                        <FormGroup check>
                            <Label check>
                                <Input checked={this.state.data?.autoApply}
                                       onChange={e => {

                                           const {checked} = e.target;
                                           const {data} = this.state;
                                           data.autoApply = checked;
                                           this.setState({data});
                                       }}
                                       type="checkbox"/>{' '}
                                Automatically
                            </Label>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label> Total Quantity </Label>
                        <Input type="number"
                               value={this.state.data?.totalQTY}
                               name={'totalQTY'}
                               onChange={this.onChangeHandler}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label> Max usage per user </Label>
                        <Input type="number"
                               value={this.state.data?.maxUsagePerUser}
                               name={'maxUsagePerUser'}
                               onChange={this.onChangeHandler}
                        />
                    </FormGroup>
                    <div className="row">
                        <div className="col ">
                            <FormGroup>
                                <Label> Availability Start Date* </Label>
                                <ReactDatetime
                                    value={this.state.data?.startDate ? moment(this.state.data.startDate) : moment(new Date())}
                                    onChange={(
                                        value
                                    ) => {
                                        const startDate = moment(value).toISOString();
                                        let {data} = this.state;
                                        if (!data) {
                                            data = {startDate: startDate};
                                        }
                                        else data.startDate = startDate;
                                        this.setState({data})
                                    }}
                                />
                            </FormGroup>

                        </div>
                        <div className="col ">

                            <FormGroup>
                                <Label> Availability End Date* </Label>
                                <ReactDatetime
                                    value={this.state.data?.endDate ? moment(this.state.data.endDate) : moment(new Date().setHours(24 * 3))}
                                    onChange={(
                                        value
                                    ) => {
                                        const endDate = moment(value).toISOString();
                                        let {data} = this.state;
                                        if (!data) {
                                            data = {endDate: endDate};
                                        }
                                        else data.endDate = endDate;
                                        this.setState({data})
                                    }}
                                />
                                {/*<ReactDatetime*/}
                                {/*    timeFormat=""*/}
                                {/*    dateFormat="DD/MM/YYYY"*/}
                                {/*    value={this.state.data?.endDate ? moment(this.state.data.endDate) : moment(new Date().setHours(24 * 3))}*/}
                                {/*    onChange={(*/}
                                {/*        dateTime: moment.Moment | string*/}
                                {/*    ) => {*/}
                                {/*        const endDataJson = moment(dateTime).toJSON();*/}
                                {/*        let {data} = this.state;*/}
                                {/*        if (!data) data = {endDate: endDataJson};*/}
                                {/*        else data.endDate = endDataJson;*/}
                                {/*        this.setState({data}, () => {*/}
                                {/*            ;*/}
                                {/*        })*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </FormGroup>
                        </div>
                    </div>

                    <Row className="my-3">
                        <ButtonGroup>
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    const checked = true;
                                    const {data} = this.state;
                                    data.includeAllProducts = checked;
                                    if (checked && data.productIds?.length > 0) data.productIds = [];
                                    this.setState({data});
                                }}
                                active={this.state.data?.includeAllProducts}
                            >
                                Include all products
                            </Button>
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    this.fetchProductsHandler();
                                    const checked = false;
                                    const {data} = this.state;
                                    data.includeAllProducts = false;
                                    data.byCategory = checked;
                                    this.setState({data});
                                }}
                                active={!this.state.data?.includeAllProducts && !this.state.data?.byCategory}
                            >
                                Products
                            </Button>
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    const checked = true;
                                    if (checked) this.fetchCategoriesHandler();
                                    const {data} = this.state;
                                    data.includeAllProducts = false;
                                    data.byCategory = checked;
                                    this.setState({data});
                                }}
                                active={!this.state.data?.includeAllProducts && this.state.data?.byCategory}
                            >
                                Categories
                            </Button>
                        </ButtonGroup>
                    </Row>

                    {
                        !this.state.data?.includeAllProducts ?

                            this.state.data.byCategory ?
                                <FormGroup>
                                    {/*<label> Products * </label>*/}
                                    <Select
                                        isMulti={true}
                                        options={this.state.categories || []}
                                        value={this.state.data.productCategoryIds &&
                                        this.state.categories.filter((p: any) => this.state.data.productCategoryIds.includes(p.id))}
                                        onInputChange={this.searchCategoriesItems}
                                        onChange={(o: any) => {
                                            const {data} = this.state;
                                            data.productCategoryIds = o.map((p: { id: any; }) => p.id);
                                            this.setState({data});
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id + ""}
                                    />
                                </FormGroup>
                                :
                                <FormGroup>
                                    {/*<label> Products * </label>*/}
                                    <Select
                                        isMulti={true}
                                        options={this.state.products || []}
                                        value={this.state.data.productIds &&
                                        this.state.products.filter((p: any) => this.state.data.productIds.includes(p.id))}
                                        onInputChange={this.searchProductItems}
                                        onChange={(o: any) => {
                                            const {data} = this.state;
                                            data.productIds = o.map((p: { id: any; }) => p.id);
                                            this.setState({data});
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id + ""}
                                    />
                                </FormGroup>
                            : null
                    }

                </ModalBody>
                <ModalFooter className={"space-between"}>
                    <Button
                        onClick={this.props.close}
                        color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={this.onSubmitHandler}
                        color="success">
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
