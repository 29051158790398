import Axios from "axios";

export const getCompanyCategories = async () => {
  const result = await Axios.get(`api/CompanyCategories`);
  return result.data;
};

export const getCountries = async () => {
  const result = await Axios.get(`api/Countries`);
  return result.data;
};


export const getOrganization = async () => {
  const result = await Axios.get(`api/Organization`);
  return result.data;
};

export const updateOrganization = async (data) => {
  const result = await Axios.put(`api/Organization`, data);
  return result.data;
};

export const changeEmailAPI = async () => {
  const result = await Axios.post(`api/Account/Email`);
  return result.data;
};

export const changePassword = async (data) => {
  const result = await Axios.put(`api/Account/Password`, data);
  return result.data;
};

export const updateProfile = async (payload) => {
  const result = await Axios.put('api/TenantUser/EditProfile', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return result.data;
}