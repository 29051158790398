import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteProduct, Product } from "../../api/ProductsApi";
import { Loader } from "../../components/Loader";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";

interface IDeleteProductProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    deleteItem: Product | null;
}

const DeleteProduct: React.FC<IDeleteProductProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        if (props.deleteItem) {
            setId(props.deleteItem.id);
        }
    }, [props.deleteItem]);

    const handleDeleting = () => {
        setLoading(true);
        const pcType = props.deleteItem ? props.deleteItem.category.pcType : "Items";

        const endpointUrl = `Product${pcType}s`

        // let url = "ProductItems";

        // if (props.deleteItem && props.deleteItem.category.id === 9) {
        //     url = "ProductAddons";
        // } else if (props.deleteItem && props.deleteItem.category.id === 4) {
        //     url = "ProductSessions";
        // }

        deleteProduct(id, endpointUrl)
            .then(res => {
                setLoading(false);
                props.cancelDeleting?.();
                props.onUpdated?.();
            })
            .catch(err => {
                setLoading(false);
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong while deleting product , {props?.deleteItem?.name ?? ""}
                        Please try again later.
                    </ToastMessage>
                );
            });
    };

    return (
        <Modal toggle={props.cancelDeleting} isOpen={!!props.deleteItem} size={"lg"}>
            <ModalHeader toggle={props.cancelDeleting}>
                Delete Product
            </ModalHeader>
            <ModalBody>
                {loading ? <Loader isLoading={true} /> : null}
                Are you sure you want to delete &quot;
                {props.deleteItem &&
                `${props.deleteItem.name || ""}`}
                &quot;?
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.cancelDeleting}>
                    No, keep &quot;
                    {props.deleteItem &&
                    `${props.deleteItem.name || ""}`}
                    &quot;
                </Button>
                <Button color="danger" onClick={handleDeleting}>
                    Yes, Delete &quot;
                    {props.deleteItem &&
                    `${props.deleteItem.name || ""}`}
                    &quot;
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteProduct;