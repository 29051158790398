import * as React from 'react';
import Select from "react-select";
import {ITax} from "../../api/TaxApi";
//
import {IDurationPlan} from "../../api/ProductsApi";
import './DurationPlanComponentStyle.css';
import {AiOutlineClose} from "react-icons/ai";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    InputGroup,
    Label,
    UncontrolledButtonDropdown
} from "reactstrap";

interface IDurationPlanComponentProps {
    taxes: Array<ITax>;
    _taxes: Array<{ label: string, value: number }>,
    removeFn: () => unknown;
    index: number;
    item: IDurationPlan,
}

class DurationPlanComponent extends React.Component<IDurationPlanComponentProps, any> {
    constructor(props: IDurationPlanComponentProps) {
        super(props);
        this.state = {
            data: null,
            taxes: [],
            price: 0,
            totalPrice: 0,
            checkInLimits: 0,
            durationInDays: 0
        }

    }

    componentWillMount() {

        if (this.props.item) {
            const data = this.props.item;
            this.calcTotalPrice()
            this.setState({ data: data });
        }
    }

    calcTotalPrice = () => {

        const taxes: Array<ITax> = [];
        for (let i = 0; i < this.props.item.price.taxesIds?.length; i++) {
            let _tax = this.props.taxes.find(item => item.id === this.props.item.price.taxesIds[i]);
            if (_tax)
                taxes.push(_tax)
        }

        let totalTax = 0;
        taxes.forEach((tax) => {
            totalTax += tax.value;
        })
        const amount = this.props.item.price.amount;
        let totalPrice = amount;

        const discount = this.props.item.price.discount;
        if(discount){
            if(this.props.item.price.isDiscountPercentage) totalPrice -= amount * discount / 100;
            else totalPrice -= discount;
        }

        if (totalTax > 0) {
            totalPrice = +(+totalPrice * (1 + (totalTax / 100))).toFixed(2);
        }

        this.setState({ totalPrice })
    }

    render() {
        return (
            <div className={'duration-container'} id={`duration${this.props.index}`}>
                <button
                    onClick={this.props.removeFn}
                    className={'btn btn-sm btn-outline-danger float-end'}>
                    <AiOutlineClose/>
                </button>
                <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label > Duration In Days </label>
                            <input type="number"
                                value={this.props.item.durationInDays}
                                onChange={e => {
                                    e.preventDefault();
                                    this.props.item.durationInDays = +e.target.value;
                                    this.setState({ durationInDays: +e.target.value })
                                }}
                                className="form-control" />
                        </div>
                        <div className="form-group">
                            <label > Check In Limits </label>
                            <input type="number"
                                value={this.props.item.checkInLimits}
                                onChange={e => {
                                    e.preventDefault();
                                    this.props.item.checkInLimits = +e.target.value;
                                    this.setState({ checkInLimits: +e.target.value })
                                }}
                                className="form-control" />
                        </div>

                        <div className="form-group">
                            <label > Price </label>
                            <input
                                value={this.props.item?.price?.amount ?? 0}
                                name={"price"}
                                type="number"
                                className="form-control"
                                onChange={e => {
                                    e.preventDefault();
                                    const value = e.target.value as unknown as number;
                                    this.props.item.price.amount = +value;
                                    if(value <= 0){
                                        this.props.item.price.taxesIds = []
                                        this.props.item.price.discount = 0;
                                    }
                                    this.setState({ price: this.props.item.price }, () => {
                                        this.calcTotalPrice();
                                    })

                                }}
                            />
                        </div>

                        <FormGroup>
                            <Label> Discount  </Label>
                            <InputGroup>
                                <Input type="number"
                                       disabled={!this.props.item.price || this.props.item.price.amount <=0}
                                       onChange={e => {
                                           e.preventDefault();
                                           const value = e.target.value as unknown as number;
                                           this.props.item.price.discount = +value;
                                           this.setState({ price: this.props.item.price }, () => {
                                               this.calcTotalPrice();
                                           })

                                       }}
                                    value={this.props.item?.price?.discount}
                                />
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret>
                                        {this.props.item?.price && this.props.item?.price?.isDiscountPercentage === true ? '%' : 'Fixed amount'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(e) => {
                                            e.preventDefault();
                                            this.props.item.price.isDiscountPercentage = true;
                                            this.setState({ price: this.props.item.price }, () => {
                                                this.calcTotalPrice();
                                            })
                                        }}>
                                            %
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => {
                                            e.preventDefault();
                                            this.props.item.price.isDiscountPercentage = false;
                                            this.setState({ price: this.props.item.price }, () => {
                                                this.calcTotalPrice();
                                            })
                                        }}>
                                            Fixed amount
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>

                        </FormGroup>


                        <div className="form-group">
                            <label htmlFor=""> Taxes </label>
                            <Select options={this.props._taxes}
                                    isDisabled={! this.props.item.price}
                                    value={this.props._taxes.filter(x => this.props.item?.price?.taxesIds?.includes(x.value))}
                                    isMulti={true}
                                    onChange={(o: any) => {
                                        o = o as Array<{ label: string, value: number }>;
                                        const values = o?.filter((x: { label: string, value: number }) => x.value)?.map((x: any) => x.value) ?? [];
                                        // this.setState({data:{...this.state.data , price:{...this.state.data.price , taxesId:values}}} , () => {
                                        //     // this.calcTotalPrice();
                                        // })

                                        this.props.item.price.taxesIds = values;
                                        this.setState({taxes: values}, () => {
                                            this.calcTotalPrice();
                                        });
                                    }}
                            />

                        </div>

                        <div className="form-group">
                            <label >  Total Price : {" "}
                             {this.props.item?.price?.amount && <b color="black">{this.state.totalPrice} SAR</b>}
                            </label>
                                {/*<input value={this.props.item?.price?.totalPrice ?? 0} name={"Tota Price"} type="number" className="form-control" readOnly={true}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DurationPlanComponent;