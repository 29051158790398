import moment from "moment";

export const formattedDate = (date: Date): string => {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
};

export const formatSimpleDate = (dateString?: string) => {
    if(dateString){
        const date = moment(dateString);
        return date.format('DD/MM/YYYY h:mm a');
    }
    return "";
};