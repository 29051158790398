import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { getTickets, GetTicketsRequest, Ticket } from "../api/ProductsApi";
import { addEntities, AddEntitiesAction } from "./Actions";
import { AppThunk } from "./index";
import { NormalizedPagination } from "./NormalizedPagination";
import { ticketsSchema } from "./Schemas";

export interface TicketsState {
    [key: string]: Ticket | {};

    pagination: {
        byEventId: NormalizedPagination;
        byEventIdForSelector: NormalizedPagination;
    };
}

const initialState: TicketsState = {
    pagination: { byEventId: {}, byEventIdForSelector: {} }
};

interface RequestFetchTicketsPaginationAction {
    eventId: number;
}

interface ReceiveFetchTicketsPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}
interface RequestFetchTicketsSelectorAction {
    eventId: number;
}

interface ReceiveFetchTicketsSelectorAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const ticketsSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        requestFetchTicketsPagination(
            state,
            action: PayloadAction<RequestFetchTicketsPaginationAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventId[eventId] = {
                ...state.pagination.byEventId[eventId],
                isFetching: true
            };
        },

        receiveFetchTicketsPagination(
            state,
            action: PayloadAction<ReceiveFetchTicketsPaginationAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventId[eventId] = {
                ...action.payload,
                isFetching: false
            };
        },

        requestFetchTicketsForSelector(
            state,
            action: PayloadAction<ReceiveFetchTicketsSelectorAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventIdForSelector[eventId] = {
                ...state.pagination.byEventIdForSelector[eventId],
                isFetching: true
            };
        },
        receiveFetchTicketsForSelector(
            state,
            action: PayloadAction<ReceiveFetchTicketsPaginationAction>
        ) {
            const eventId = action.payload.eventId.toString();

            state.pagination.byEventIdForSelector[eventId] = {
                ...action.payload,
                isFetching: false
            };
        }
    },

    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["tickets"]
            };
        })
    })
});

export default ticketsSlice.reducer;

const {
    requestFetchTicketsPagination,
    receiveFetchTicketsPagination,

    requestFetchTicketsForSelector,
    receiveFetchTicketsForSelector
} = ticketsSlice.actions;

export const fetchPaginatedTicketsByEvent = (
    request: GetTicketsRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchTicketsPagination({ eventId: request.eventId }));

        const result = await getTickets(request);

        const normalized = normalize(result.items, Array(ticketsSchema));

        dispatch(addEntities(normalized.entities as any));
        dispatch(
            receiveFetchTicketsPagination({
                ...result.meta,
                eventId: request.eventId,
                error: undefined,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchTicketsPagination({
                ...getState().tickets.pagination.byEventId[
                    request.eventId.toString()
                ],
                eventId: request.eventId,
                // error: e.toString() 
            })
        );
    }
};

export const fetchTicketsByEventForSelector = (
    request: GetTicketsRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchTicketsForSelector({ eventId: request.eventId }));

        const result = await getTickets(request);

        const normalized = normalize(result.items, Array(ticketsSchema));

        dispatch(addEntities(normalized.entities as any) );
        dispatch(
            receiveFetchTicketsForSelector({
                ...result.meta,
                eventId: request.eventId,
                error: undefined,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchTicketsForSelector({
                ...getState().tickets.pagination.byEventId[
                    request.eventId.toString()
                ],
                eventId: request.eventId,
                // error: e.toString()
            })
        );
    }
};
