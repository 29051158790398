/* eslint-disable react/no-deprecated */
import {GetCouponProducts, ICouponProduct} from 'api/CouponApi';
import React, {Component} from 'react';
import {FaEye} from 'react-icons/fa';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ConfirmDeleteProduct from './ConfirmDeleteProduct';

interface OwnProps {
    isOpen: boolean;
    close?: () => void;
    couponId: number;
}

type Props = OwnProps;

export default class CouponProducsComponent extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            products: [],
            deleteProductId: 0,
            deleteModal: false
        }
    }


    componentWillMount() {
        this.fetchCouponProducts();
    }

    fetchCouponProducts = () => {
        GetCouponProducts(this.props.couponId).then(data => {
            if (data) {
                this.setState({ products: data.items })
            }
        })
    }
    render() {
        return (
            <Modal size='lg' unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>

                {
                    this.state.deleteModal && this.state.deleteProductId > 0 ?
                        <ConfirmDeleteProduct
                            couponId={this.props.couponId}
                            isOpen={true}
                            productId={this.state.deleteProductId}
                            close={() => {
                                this.setState({ deleteModal: false, deleteProductId: 0 })
                            }}
                            onUpdate={this.fetchCouponProducts}
                        />
                        : null
                }
                <ModalHeader toggle={this.props.close}>
                    Products of Coupon
                </ModalHeader>
                <ModalBody>
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td> Name </td>
                                    <td> Category </td>
                                    <td> Total Price </td>
                                    <td> After Coupon </td>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    this.state.products.map((item: ICouponProduct) => {
                                        return <tr key={item.product.id}>
                                            <td> {item.product.name} </td>
                                            <td> {item.product.category.name} </td>
                                            <td> {item.product.price.displayTotalPrice} </td>
                                            <td> {item.finalPriceWithCoupon} </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
