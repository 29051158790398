/* eslint-disable react/jsx-key */
import * as React from "react";
import Helmet from "react-helmet";
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import {
  GetMembershipCard,
  GetTenantUserInvoice,
  GetTenantUsers,
  IUserTenantsModel,
} from "../../api/MembershipAreaApi";
import blankProfile from "../../assets/imgs/no-photo.png";
import { Loader } from "../../components/Loader";
import { MainCard } from "../../components/MainCard";
import NoData from '../../assets/imgs/profile.svg';
// FaFileInvoiceDollar
import HtmlViewercomponent from "../../components/HtmlViewerComponent/html-viewer";
//AiOutlineSearch
import moment from "moment";
import { AiOutlineSearch, } from "react-icons/ai";
import { Data_Format_EMS } from "types/date-format-type";
import AddTenantUserComponent from "./AddTenantUserComponent";
import EditTenantUserComponent from "./EditTenantUserComponent";
import { EventBadgeComponent } from "./EventBadgeComponent";
import { SmartAccessComponent } from "./SmartAccessComponent";
import { PaginationFooter } from "../../components/PaginationFooter";
import { deleteSubTenants } from "../../api/SubAccountApi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class ManageTenantUsers extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageIndex: 1,
      data: [],
      loading: false,
      html: null,
      title: null,
      value: null,
      addNewUser: false,
      searchKeys: [
        { label: "Name", value: "FullName" },
        { label: "Email", value: "Email" },
        { label: "Phone Number", value: "PhoneNumber" },
        { label: "Number", value: "MembershipNumber" },
        { label: "Plan", value: "Plan" },
      ],
      searchTag: (<div> </div>) as JSX.Element,
      inputSearchPadding: 0,
      smartAccess: false,
      showActionCardKey: -1,
      userBadgeOpening: false,
      membershipNumberBadge: null,
      itemToEditId: null,
      itemToDeleteId:null,
      count:0,
      maximumPerPage:20
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.fetchDate();
    // this.registerEvent();
  }
  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.page !== this.state.page) {
      this.fetchDate();
    }
  }

  toggleSmartAccessComponent = () => {
    this.setState({ smartAccess: !this.state.smartAccess });
  };

  toggleUserBadgeOpening = () => {
    this.setState({ userBadgeToggle: !this.state.userBadgeToggle })
  }

  fetchDate = () => {
    this.setState({ loading: true });
    GetTenantUsers({
      limit: 20,
      pageIndex: this.state.pageIndex,
      text: this.state.value,
    }).then(data => {
      ;
      this.setState({ loading: false, data: data.items,count:(data as any).totalItemsCount });

      ;
    });
  };

  fetchTenantUserInvoice = (id: number) => {
    GetTenantUserInvoice(id).then(data => {
      this.setState({ html: data.html, title: "User Invoice" });
    });
  };

  fetchTenantUserMembershipCard = (id: number) => {
    GetMembershipCard(id).then(data => {
      this.setState({ html: data.html, title: "Membership Card" });
    });
  };


  setSearchTagResolver = () => {
    if (this.state.key == 0) {
      this.setState({ searchTag: null, inputSearchPadding: 0 });
      return;
    }
    const searchElement = this.state.searchKeys.find(
      (x: { label: string; value: string }) => x.value == this.state.key
    );
    if (!searchElement) return;
    const tagContent = `
      <h5>
      tag ${searchElement.label}
      </h5>
    `;
    // const html = (
    //   <span
    //     id="tagEle"
    //     style={
    //       {
    //         position: "absolute",
    //         top: "18%",
    //         left: "7px",
    //       } as React.CSSProperties
    //     }
    //   >
    //     Tag <i style={{ color: "blue" }}> {" " + searchElement.label + " /"}</i>
    //   </span>
    // );
    // this.setState({ searchTag: html }, () => {
    //   this.setInputSearchPaddingHandler();
    // });
    // return (

    // );
    // this.setState({searchTag: tagContent});
  };

  setInputSearchPaddingHandler = () => {
    const ele: HTMLElement | null = document.getElementById("tagEle");
    if (!ele) return;
    const width = ele.offsetWidth;
    this.setState({ inputSearchPadding: width + 10 });
    // return width;
  };

  onChangeSearchValueHandler = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({ value });
  };

  addNewUserToggle = () => {
    this.setState({ addNewUser: !this.state.addNewUser });
  };
  deleteCustomer = () =>{
    deleteSubTenants(this.state.itemToDeleteId).then(() => {
      this.fetchDate()
    }).catch(reason => {
      toast.error(reason?.response?.data?.title ?? `Error while deleting Customer with id ${this.state.itemToDeleteId}`)
    }).finally(() => this.setState({itemToDeleteId:undefined}))
  }



  // registerEvent = () => {
  //   window.addEventListener("click", ({ target }) => {
  //     ;
  //     let tar = target as any;
  //
  //     if (
  //       (tar && tar["classList"].contains("action-btn")) ||
  //       (tar && tar["classList"].contains("btn-link")) ||
  //       (tar && tar.parentElement && tar.parentElement.classList.contains("btn-link"))
  //     ) {
  //       return;
  //     }
  //     if (this.state.showActionCardKey > -1) {
  //       this.setState({ showActionCardKey: -1 });
  //     }
  //   });
  // };


  render() {
    return (
      <div>
        {
          this.state.itemToEditId ?
            <EditTenantUserComponent open={true} tenantUserId={this.state.itemToEditId}
              close={() => {
                this.setState({ itemToEditId: null })
              }}
              onSuccess={() => {
                this.fetchDate();
              }}
            />
            : null
        }
        {
          this.state.itemToDeleteId ?
            <Modal size="lg"  isOpen={true}>
              <ModalHeader>
                Delete Customer
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete customer with id : {this.state.itemToDeleteId} ?
              </ModalBody>

              <ModalFooter>
                <Button onClick={event => {this.setState({itemToDeleteId:undefined})}}>
                  No
                </Button>
                <Button color="danger" onClick={this.deleteCustomer}>
                  Yes
                </Button>
              </ModalFooter>
            </Modal>
            : null
        }
        {this.state.addNewUser ? (
          <AddTenantUserComponent
            isOpen={this.state.addNewUser}
            close={this.addNewUserToggle}
            onUpdated={() => {
              this.addNewUserToggle();
              this.fetchDate();
            }}
          />
        ) : null}

        {this.state.html && this.state.title ? (
          <HtmlViewercomponent
            close={() => {
              this.setState({ html: null, title: null });
            }}
            html={this.state.html}
            title={this.state.title}
            isOpen={this.state.html && this.state.title}
          />
        ) : null}

        {/* Smart Access */}
        {this.state.smartAccess ? (
          <SmartAccessComponent
            isOpen={this.state.smartAccess}
            close={this.toggleSmartAccessComponent}
          />
        ) : null}
        {/* end -> smart Access */}
        {
          this.state.userBadgeOpening && this.state.membershipNumberBadge ?
            <EventBadgeComponent isOpen={true} close={() => {
              this.setState({ userBadgeOpening: false })
            }} membershipNumber={this.state.membershipNumberBadge} />
            : null
        }
        <Helmet title="Membership Area Users" />

        <MainCard>
          <CardHeader className="d-flex justify-content-between">
            Customers
          </CardHeader>
          <CardBody>
            <Form>
              <Row className="align-items-center mb-4">
                <Col sm="4" xs="6">
                  <InputGroup>
                    <input
                        value={this.state.value}
                        onChange={this.onChangeSearchValueHandler}
                        type="text"
                        className="form-control flex-2"
                        placeholder=""
                    />
                    <button
                        onClick={(e)=>{e.preventDefault(); this.fetchDate()}}
                        className="btn btn-success flex-2"
                    >
                      Search
                      <i>
                        {" "}
                        <AiOutlineSearch />{" "}
                      </i>
                    </button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>

            {this.state.data.length > 0 && !this.state.loading ? (
              <React.Fragment>
                <table className={"table table-response"}>
                  <thead className="thead-dark">
                    <tr>
                      <td>#</td>
                      <td>Full Name</td>
                      <td>Email</td>
                      <td>Phone</td>
                      <td>Plan</td>
                      <td>Start Date</td>
                      <td>End Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map(
                      (item: IUserTenantsModel, index: number) => {
                        return (
                          <tr key={index}>
                            <td style={{verticalAlign:"middle"}}>{item.id}</td>

                            <td style={{verticalAlign:"middle"}} >
                            <div className="d-flex flex-row align-items-center gap-2">
                              <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={item.image?.fullUrl ?? blankProfile}
                                width={"50px"}
                                height={"50px"}
                              />
                            </div>
                            <Link to={item.id+''} className='text-reset'>{item.fullName}</Link>
                            </div> </td>
                            <td style={{verticalAlign:"middle"}} >{item.email}</td>
                            <td style={{verticalAlign:"middle"}}>
                              {item.phoneNumber ?? "N/A"}
                            </td>

                            <td style={{verticalAlign:"middle"}}>{item.planName}</td>
                            <td style={{verticalAlign:"middle"}}>
                              {item.startDate && moment(item.startDate).format(Data_Format_EMS)}
                            </td>
                            <td style={{verticalAlign:"middle"}}>
                              {item.endDate && moment(item.endDate).format(Data_Format_EMS)}
                            </td>

                          </tr>
                        );
                      }
                    )}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={7}>
                      <PaginationFooter
                        onPageClicked={(page)=>this.setState({pageIndex:page})}
                        pageIndex={this.state.pageIndex}
                        totalItemsCount={this.state.count}
                        pageSize={this.state.maximumPerPage}
                        pageItemsCount={this.state.data.length}
                      />
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </React.Fragment>
            ) : !this.state.loading ? (
              <div className={'text-center'}>
                <img src={NoData} width={'30%'}/>
              </div>
            ) : null}

            {this.state.loading ? <Loader isLoading={true} /> : null}
          </CardBody>
        </MainCard>
      </div>
    );
  }
}

export default ManageTenantUsers;
