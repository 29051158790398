import * as React from "react";
import { useEffect, useState} from "react";
import {Course, deleteCourseById, GetCourses} from "../../api/CoursesApi";
import Helmet from "react-helmet";
import {Button, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FaEdit, FaPlus, FaSpinner, FaTrash} from "react-icons/fa";
import {MainCard} from "../../components/MainCard";
import {Loader} from "../../components/Loader";
import EmptyImg from "../../assets/imgs/online_learning.svg";
import {DataTable} from "../../components/DataTable";
import {EmptyListState} from "../../components/EmptyListState";
import {AddEditCourse} from "./AddEditCourse";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import {CellProps} from "react-table";
import {Product} from "../../api/ProductsApi";
import {ProductImagesComponent} from "../ManageProducts/ProductImagesComponent";
import { useNavigate } from "react-router-dom";

export const Courses : any = () => {
    const [courses, setCourses] = useState<Course[]>([])
    const [loading, setLoading] = useState(false);
    const [showAddCourse, setShowAddCourse] = useState(false);
    const [deleteCourseModalOpen, setDeleteCourseModalOpen] = useState(false);
    const [courseToRemove, setCourseToRemove] = useState<Course | null>(null);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [courseImages, setCourseImages] = React.useState<any[]>([])
    const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
    const navigate = useNavigate();

    const [courseToEdit, setCourseToEdit] = useState<Course | null>(null);

  const [page,setPage] = useState(1);
  const [count,setCount] = useState(0);
  const [maximumPerPage]=useState(20);

    const showCourseDeleteModal =
        (course: Course) => {
            setCourseToRemove(course);
            setDeleteCourseModalOpen(true);
        }


    const getCourses = () => {
        setLoading(true);
        GetCourses({pageIndex:page,limit:maximumPerPage}).then(courses => {
            setCourses(courses.items);
            setCount(courses.meta.totalItemsCount)
        }).catch(err => {})
        .finally(()=>setLoading(false))
    }

    useEffect(()=>{
        getCourses();
    }, [page])


    const handleCourseDelete = async () => {
        if (!courseToRemove) return;
        setDeleteInProgress(true);
        try {
            await deleteCourseById(courseToRemove.id);
            toast.success(
                <ToastMessage>
                    Course &quot;{courseToRemove.name}{" "} was deleted successfully.
                </ToastMessage>
            );
            setCourseToRemove(null);
            getCourses();
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong while deleting committee &quot;
                    {courseToRemove.name}{" "}
                    &quot;, please try again later
                </ToastMessage>
            );
        } finally {
            setDeleteInProgress(false);
        }
    }

    const handleEditClicked = (course : any) => {
        setCourseToEdit(course)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "#",
                Cell: (ctx: CellProps<Product>) => {
                    return ctx.row.index+1
                }
            },
            {
                Header: "Name",
                accessor: "name",
                canSort :true
            },
            {
                Header: "Category",
                accessor: "productCategory.name"
            },
            // {
            //     Header: "Description",
            //     accessor: "description",
            //     canSort :true
            // },
            {
                Header: "Status",
              Cell: (ctx: CellProps<Product>) => {
                const product = ctx.row.original;
                return product.isPublished ? "Published" : "Unpublished"
              }
            },
        {
                Header: "Price",
                accessor: "price.displayPrice"
            },
            {
                Header: "Discount",
                accessor: "price.displayProductDiscountAmount"
            },
            {
                Header: "Total Taxes",
                accessor: "price.displayTotalTaxesAmount"
            },
            {
                Header: "Total Price",
                accessor: "price.displayTotalPrice"
            },
            {
                Header: "Images",
                Cell: (ctx: CellProps<any>) => {
                    const course = ctx.row.original;
                    return <Button
                        onClick={e => {
                            setSelectedCourseId(course.id);
                            setCourseImages(course.media ? [course.media]:[])
                        }}
                        size="sm">
                        View
                    </Button>
                }
            },
            {
                Header: "Actions",
                Cell: function renderCourseActions(ctx: { row: { original: any; }; }) {
                    const course = ctx.row.original;
                    if (!course) return null;
                    return (
                        <div>
                            <Button
                                size="sm"
                                color="primary"
                                outline={true}
                                onClick={(_) => handleEditClicked(course)}
                            >
                                <FaEdit />
                            </Button>{" "}
                            <Button
                                size="sm"
                                color="danger"
                                outline={true}
                                onClick={() => {
                                    showCourseDeleteModal(course)
                                }}
                            >
                                <FaTrash />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [courses]
    );

    return (
        <div>
            <Helmet title="Courses" />
            <MainCard>
                <CardHeader className="d-flex justify-content-between">
                    <div>Courses</div>
                    <div>
                        <Button color={'primary'} onClick={()=>setShowAddCourse(true)}>
                            <FaPlus /> Add Course
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>

                    {loading ?
                        <Loader isLoading={true}/>
                        :
                        <DataTable
                            columns={columns}
                            data={courses || []}
                            pagination={
                              {
                                show: true,
                                onPageClicked: setPage,
                                totalItemsCount: count,
                                pageIndex: page,
                                pageSize: maximumPerPage,

                              }
                            }
                            emptyState={<div className={"text-center"}>
                                <EmptyListState
                                    image={EmptyImg}
                                    title={"You have no Courses yet"}
                                    // callToActionButton={{
                                    //     text: "Add your first Domain now!",
                                    //     onClick: () => setOpenAddModal(true)
                                    // }}
                                />
                            </div>}
                        />

                    }
                </CardBody>

            </MainCard>

            {
                (showAddCourse || courseToEdit) &&
                <AddEditCourse
                    isOpen={showAddCourse || courseToEdit}
                    courseToEdit={courseToEdit}
                    toggle={(success: boolean) => {
                        success && getCourses();
                        setCourseToEdit(null);
                        setShowAddCourse(false);
                    }}
                />
            }


            {courseToRemove &&
                <Modal size={"lg"} isOpen={true} unmountOnClose>
                    <ModalHeader toggle={() => {
                        setCourseToRemove(null);
                        setDeleteCourseModalOpen(!deleteCourseModalOpen)
                    }}>
                        Delete Course
                    </ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete &quot;
                        {courseToRemove && courseToRemove.name}{" "}
                        &quot;?
                    </ModalBody>

                    <ModalFooter className={"space-between"}>
                        <Button onClick={() => {
                            setCourseToRemove(null);
                            setDeleteCourseModalOpen(!deleteCourseModalOpen)
                        }}>
                            No, keep &quot;
                            {courseToRemove && courseToRemove.name}{" "}
                            &quot;
                        </Button>
                        <Button
                            color="danger"
                            onClick={handleCourseDelete}
                            disabled={deleteInProgress}
                        >
                            {deleteInProgress && (
                                <span>
                                    <FaSpinner className="icon-spin"/>
                                </span>
                            )}{" "}
                            Yes, Delete &quot;
                            {courseToRemove && courseToRemove.name}{" "}
                            &quot;
                        </Button>
                    </ModalFooter>
                </Modal>
            }
            {
                selectedCourseId &&
                <ProductImagesComponent isOpen={true}
                    productId={selectedCourseId} images={courseImages}
                    onCancel={() => setSelectedCourseId(null)}
                />
            }
        </div>
    );






}