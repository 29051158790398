import React, {useEffect, useState} from "react"
import {
    EditMoyasarPaymentGateway,
    EditTamaraPaymentGateway,
    getPaymentGatewayByType,
    PaymentGateway,
    PaymentMethodMetadata,
    updateMoyasarPaymentGateway,
    updateOnSitePaymentGateway,
    updateTamaraPaymentGateway
} from "../../api/PaymentGatewaysApi";
import {currencyDropdownList} from "../../api/CurrencyApi";
import {ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikProps} from "formik";
import {Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {nameof} from "../../util/nameof";
import {FaInfoCircle, FaSpinner} from "react-icons/fa";
import {AddEditPaymentGatewayFrom} from "./AddEditPaytabsPaymentGatewayModal";
import Select from "react-select";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";

enum PaymentProvider {
    OnSite,
    Tamara,
    Moyasar
}

interface EditPaymentGatewayProps {
    paymentGateway: PaymentGateway,
    close: (success?: boolean) => void,
    isOpen: boolean,
    paymentMethods: PaymentMethodMetadata[];
}

export const EditPaymentGateway = ({paymentGateway, close, isOpen, paymentMethods}: EditPaymentGatewayProps) => {

    const [paymentMethodsIds, setPaymentMethodsIds] = useState<number[]>([]);

    const [currencies, setCurrencies] = React.useState<Array<{ label: string, value: number }>>([]);
    const [showNoCurrencyMsg, setShowNoCurrencyMsg] = React.useState<boolean>(false);

    const [paymentProvider, setPaymentProvider] = useState<PaymentProvider | null>(null);


    const [initialPg, setInitialPg] = useState<any | null>(null);


    useEffect(() => {
        if (paymentGateway && paymentGateway.type) {
            const typeName = paymentGateway.type.name;
            let paymentGatewayPromise;
            if (typeName === "OnSite") {
                setPaymentProvider(PaymentProvider.OnSite);
                paymentGatewayPromise = getPaymentGatewayByType('OnSite/' + paymentGateway.id);
            } else if(typeName === "Tamara") {
                setPaymentProvider(PaymentProvider.Tamara);
                paymentGatewayPromise = getPaymentGatewayByType('Tamara');
            } else {
                setPaymentProvider(PaymentProvider.Moyasar);
                paymentGatewayPromise = getPaymentGatewayByType('Moyasar');
            }
            paymentGatewayPromise.then(pg => {
                if(pg.currency){
                    pg.currencyId = pg.currency.id;
                }
                setInitialPg(pg);
                if (pg.methods) {
                    setPaymentMethodsIds(pg.methods.filter((pm: { isActive: any; })=>pm.isActive).map((pm: { id: any; })=>pm.id))
                }
            }).catch(err => console.error(err));
        }
    }, [paymentGateway, paymentGateway.type])

    useEffect(() => {
        currencyDropdownList().then(currencies => {
            if (!currencies || currencies.length === 0) {
                setShowNoCurrencyMsg(true);
            } else {
                setCurrencies(currencies?.map(c => {
                    return {label: `${c.code} (${c.symbol})`, value: c.id}
                }) ?? [])
            }
        })
    }, [])

    const renderForm = (
        formProps: FormikProps<any>
    ) => {
        return (
            <Form>
                <ModalBody>
                    <FormGroup>
                        <Label>Active</Label>
                        <Field
                            name={nameof<AddEditPaymentGatewayFrom>("isActive")}
                            render={({field}: FieldProps) => (
                                <Input
                                    type="switch"
                                    label="Active"
                                    id={field.name}
                                    checked={field.value}
                                    {...field}
                                />
                            )}
                        />
                        <p className="text-muted small">
                            <FaInfoCircle/> You can only have one active
                            Payment Gateway at any given time
                        </p>

                        <ErrorMessage
                            name={nameof<AddEditPaymentGatewayFrom>("isActive")}
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Production</Label>
                        <Field
                            name={nameof<AddEditPaymentGatewayFrom>("isProduction")}
                            render={({field}: FieldProps) => (
                                <Input
                                    type="switch"
                                    label="Active"
                                    id={field.name}
                                    checked={field.value}
                                    {...field}
                                />
                            )}
                        />
                        <ErrorMessage
                            name={nameof<AddEditPaymentGatewayFrom>("isProduction")}
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Name</Label>
                        <Field
                            name={"name"}
                            render={({field}: FieldProps) => (
                                <Input {...field} />
                            )}
                        />
                        <ErrorMessage
                            name={"name"}
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Description</Label>
                        <Field
                            name={"description"}
                            render={({field}: FieldProps) => (
                                <Input {...field} />
                            )}
                        />
                        <ErrorMessage
                            name={"description"}
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>

                    {paymentProvider === PaymentProvider.Tamara &&
                        <>
                            <FormGroup>
                                <Label>Web Site</Label>
                                <Field
                                  name={"website"}
                                  render={({field}: FieldProps) => (
                                    <Input {...field} />
                                  )}
                                />
                                <ErrorMessage
                                  name={"website"}
                                  render={error => (
                                    <FormFeedback className="d-block">
                                        {error}
                                    </FormFeedback>
                                  )}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Merchant Key API</Label>
                                <Field
                                    name={"merchantKeyAPI"}
                                    render={({field}: FieldProps) => (
                                        <Input {...field} />
                                    )}
                                />
                                <ErrorMessage
                                    name={"merchantKeyAPI"}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Public Key</Label>
                                <Field
                                    name={"publicKey"}
                                    render={({field}: FieldProps) => (
                                        <Input {...field} />
                                    )}
                                />
                                <ErrorMessage
                                    name={"publicKey"}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Notification Token</Label>
                                <Field
                                    name={"notificationToken"}
                                    render={({field}: FieldProps) => (
                                        <Input {...field} />
                                    )}
                                />
                                <ErrorMessage
                                    name={"notificationToken"}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Request Timeout</Label>
                                <Field
                                    name={"requestTimeout"}
                                    render={({field}: FieldProps) => (
                                        <Input {...field} type="number"/>
                                    )}
                                />
                                <ErrorMessage
                                    name={"requestTimeout"}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />
                            </FormGroup>
                        </>

                    }
                    {paymentProvider === PaymentProvider.Moyasar &&
                    <>
                        <FormGroup>
                            <Label>Publish Key</Label>
                            <Field
                                name={"publishKey"}
                                render={({field}: FieldProps) => (
                                    <Input {...field} />
                                )}
                            />
                            <ErrorMessage
                                name={"publishKey"}
                                render={error => (
                                    <FormFeedback className="d-block">
                                        {error}
                                    </FormFeedback>
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Secret Key</Label>
                            <Field
                                name={nameof<AddEditPaymentGatewayFrom>(
                                    "secretKey"
                                )}
                                render={({field}: FieldProps) => (
                                    <Input
                                        {...field}
                                        autoComplete={"new-password"}
                                        type="password"
                                        placeholder={"••••••"}
                                    />
                                )}
                            />
                            <ErrorMessage
                                name={nameof<AddEditPaymentGatewayFrom>(
                                    "secretKey"
                                )}
                                render={error => (
                                    <FormFeedback className="d-block">
                                        {error}
                                    </FormFeedback>
                                )}
                            />
                        </FormGroup>
                    </>
                    }

                    <FormGroup>
                        <Label> Currency </Label>
                        <FieldArray
                            name={nameof<AddEditPaymentGatewayFrom>('currencyId')}
                            render={field => (
                                <Select
                                    value={formProps.values && formProps.values.currencyId && currencies.filter(curr=>curr.value === formProps.values.currencyId)}
                                    options={currencies}
                                    isMulti={false}
                                    onChange={(value: any) => {
                                        formProps.setFieldValue("currencyId", value["value"]);
                                    }}
                                />
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Accepted Payment Methods</Label>
                        {initialPg &&
                        initialPg.methods &&
                        initialPg.methods.map((pm: { id: number; isActive: boolean | undefined; imageUrl: string | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                            <>
                                <FormGroup check className="mt-2">
                                    <Label
                                        check
                                    >
                                        <Input
                                            checked={paymentMethodsIds && paymentMethodsIds.includes(pm.id)}
                                            onChange={(e=>{
                                                if (e.target.checked) {
                                                    setPaymentMethodsIds([...paymentMethodsIds, pm.id]);
                                                } else {
                                                    setPaymentMethodsIds((prevIds) => prevIds.filter((id) => id !== pm.id));
                                                }
                                            })}
                                            type="checkbox"
                                        />{" "}
                                        <img
                                            style={{marginRight: '10px'} as React.CSSProperties}
                                            src={pm.imageUrl}
                                            height={24}
                                        />
                                        {" "}
                                        {pm.name}
                                    </Label>
                                </FormGroup>
                            </>
                        ))}
                        <ErrorMessage
                            name={nameof<AddEditPaymentGatewayFrom>(
                                "paymentMethods"
                            )}
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>
                </ModalBody>

                <ModalFooter>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={()=>close(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={formProps.isSubmitting}
                    >
                        {formProps.isSubmitting && (
                            <span>
                                    <FaSpinner className="icon-spin"/>
                                </span>
                        )}{" "}
                        Save
                    </Button>{" "}
                </ModalFooter>
            </Form>
        )
    }

    const handleFormSubmit = React.useCallback(
        async (
            values: any,
        ) => {
            const updateRequest = {...values, paymentMethodsIds: paymentMethodsIds}
            let submitPromise;

            if(paymentProvider === PaymentProvider.OnSite) {
                submitPromise = updateOnSitePaymentGateway(paymentGateway.id, updateRequest);
            } else if(paymentProvider === PaymentProvider.Tamara){
                const editRequest : EditTamaraPaymentGateway  = {
                    ...updateRequest,
                    paymentMethodsIds: paymentMethodsIds
                }
                submitPromise = updateTamaraPaymentGateway(editRequest);
            } else {
                const editRequest : EditMoyasarPaymentGateway  = {
                    name: updateRequest.name,
                    description: updateRequest.description,
                    publishKey: updateRequest.publishKey,
                    secretKey: updateRequest.secretKey,
                    supportedNetworks: "Moyasar",
                    methods: "Moyasar",
                    currencyId: updateRequest.currencyId,
                    isActive: updateRequest.isActive,
                    paymentMethodsIds: paymentMethodsIds,
                    isProduction: updateRequest.isProduction
                }
                submitPromise = updateMoyasarPaymentGateway(editRequest);
            }
            submitPromise.then(res=>{
                    toast.success(
                        <ToastMessage>
                            Payment gateway Updated successfully.
                        </ToastMessage>
                    );
                close(true);
            }).catch(err=>{
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong while updating payment gateway,
                        Please try again later.
                    </ToastMessage>
                );
            })

        }, [paymentMethodsIds, close])


    const clearAndClose = ( ) => {
        // setInitialPg(null);
        close();
    }
    return (
        <Modal
            toggle={clearAndClose}
            isOpen={isOpen} size="lg">
            <ModalHeader toggle={clearAndClose}>
                Edit Payment Gateway {paymentGateway ? " - " + paymentGateway.name : ""}
            </ModalHeader>

            <Formik
                initialValues={initialPg}
                enableReinitialize
                onSubmit={handleFormSubmit}
                render={renderForm}
            />
        </Modal>

    )


}