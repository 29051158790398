import * as React from "react";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { sendSms } from "../../api/MessagingApi";
import { GetMembershipPlans, SearchTenantUsers } from "../../api/MembershipAreaApi";
import { getRoleList } from "../../api/rolesApi";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { FaPlus } from "react-icons/fa";
import { Chip } from "@mui/material";
import { toast } from "react-toastify";

const SendSms = ({ isOpen, toggle, onUpdate }: any) => {

  const [message, setMessage] = useState<string>()
  const [selectedRoles, setSelectedRoles] = useState<any[]>([])
  const [selectedMemberships, setSelectedMemberships] = useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [selectedOption, setSelectedOption] = useState<any>({ id: 0, name: "Users" })
  const [selectedValue, setSelectedValue] = useState<any>()


  const [roles, setRoles] = useState<any[]>([])
  const [memberships, setMemberships] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])

  const options = [{ id: 0, name: "Users" }, { id: 1, name: "Roles" }, { id: 2, name: "Memberships" }]


  const initialFormValues = { contentAr: "", contentEn: "" }
  const loadOptions = async (value: string) => {
    switch (selectedOption.id) {
      case 0:
        return (await SearchTenantUsers({ text: value })).filter(user => !selectedUsers?.some(u => u.id === user.id));
      case 1:
        return roles.filter(role => !selectedRoles?.some(r => r.id === role.id) && role.name.includes(value));
      case 2:
        return memberships.filter(membership => !selectedMemberships?.some(m => m.id === membership.id) && membership.name.includes(value));
      default:
        return (await SearchTenantUsers({ text: value })).filter(user => !selectedUsers?.some(u => u.id === user.id));
    }
  }

  const defaultOptions = () => {
    switch (selectedOption.id) {
      case 0:
        return users.filter(user => !selectedUsers?.some(u => u.id === user.id));

      case 1:
        return roles.filter(role => !selectedRoles?.some(r => r.id === role.id));
      case 2:
        return memberships.filter(membership => !selectedMemberships?.some(m => m.id === membership.id));
    }
  }


  const addOption = () => {

    switch (selectedOption.id) {
      case 0:
        setSelectedUsers(prevState => [...prevState, selectedValue]);
        break;
      case 1:
        setSelectedRoles(prevState => [...prevState, selectedValue]);
        break;
      case 2:
        setSelectedMemberships(prevState => [...prevState, selectedValue]);
        break;
    }
    setSelectedValue(undefined);
  }

  const deleteUser = (user: any) => {
    const usersList = [...selectedUsers]
    const index = usersList.findIndex(value => user.id === value.id);
    if (index > -1) {
      usersList.splice(index, 1);
      setSelectedUsers(usersList)
    }
  }
  const deleteRole = (role: any) => {
    const rolesList = [...selectedRoles]
    const index = rolesList.findIndex(value => role.id === value.id);
    if (index > -1) {
      rolesList.splice(index, 1);
      setSelectedRoles(rolesList)
    }
  }
  const deleteMembership = (membership: any) => {
    const membershipsList = [...selectedMemberships]
    const index = membershipsList.findIndex(value => membership.id === value.id);
    if (index > -1) {
      membershipsList.splice(index, 1);
      setSelectedMemberships(membershipsList)
    }
  }

  const closeModal = () => {
    setSelectedRoles([]);
    setSelectedMemberships([]);
    setSelectedUsers([]);
    setMessage(undefined);
    toggle()
  }
  const handleSubmit = () => {
    const requestBody = {
      rolesIds: selectedRoles.map(value => value.id),
      usersIds: selectedUsers.map(value => value.id),
      membershipsIds: selectedMemberships.map(value => value.id),
      message,
    }
    sendSms(requestBody).then(() => {
      toast.success("SMS sent Successfully");
      onUpdate();
      closeModal();
    }).catch(() => {
      toast.error("Error While Sending SMS")
    })
  }

  useEffect(() => {
    GetMembershipPlans().then(setMemberships)
    SearchTenantUsers().then(setUsers)
    getRoleList().then(setRoles)
  }, [])


  const form = () => (
    <Form>
      <ModalBody>
        <FormGroup className={'w-100'}>
          <Row>

            <Col sm="3" xs="6">
              <Select
                value={selectedOption}
                onChange={(val) => {
                  setSelectedOption(val);
                  setSelectedValue(undefined);
                }}
                options={options}
                isClearable={false}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
              />
            </Col>
            <Col sm="6" xs="6">
              <AsyncSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base:any) => ({ ...base, zIndex: 9999 }) } as any}
                placeholder={'Search'}
                value={selectedValue ?? null}
                onChange={setSelectedValue}
                isClearable

                defaultOptions={defaultOptions()}
                loadOptions={async (val) => {
                  return (await loadOptions(val))
                }}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name ?? option.displayName}
              />
            </Col>
            <Col sm="3" xs="6">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  addOption();
                }}
                disabled={!selectedValue}
                className="btn btn-success flex-2 w-100"
              >
                <i className='flex justify-content-center align-items-center'>
                  <FaPlus size={18}/> Add
                </i>
              </button>
            </Col>
          </Row>
        </FormGroup>
        <Label>Selected </Label>
        <div style={{ minHeight: "60px" }} className='bg-light'>
          {selectedUsers.map(user => <Chip
            className="me-1"
            label={user.displayName}
            onDelete={() => deleteUser(user)}
          />)}
          {selectedMemberships.map(membership => <Chip
            className="me-1"
            label={membership.name}
            onDelete={() => deleteMembership(membership)}
          />)}
          {selectedRoles.map(role => <Chip
            className="me-1"
            label={role.name}
            onDelete={() => deleteRole(role)}
          />)}
        </div>

        <FormGroup>
          <Label>message</Label>

          <Input
            value={message}
            placeholder={"Text"}
            onChange={event => setMessage(event.target.value)}
            type="textarea"
          />
        </FormGroup>

      </ModalBody>
      <ModalFooter className="space-between">
        <Button type="button" color="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={(selectedUsers.length === 0 && selectedRoles.length === 0 && selectedMemberships.length === 0) || (!message || message.length === 0)}
        >
          Send SMS
        </Button>
      </ModalFooter>
    </Form>
  );


  return (
    <Modal isOpen={isOpen} toggle={closeModal} unmountOnClose size="xl">
      <ModalHeader toggle={closeModal}>Send Email</ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues}>
        {form}
      </Formik>
    </Modal>
  )

}

export default SendSms;