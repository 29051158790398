import { EmailConfiguration } from "scenes/EmailConfiguration";
import { EventRecepients } from "scenes/EventRecepients";
import { Home } from "scenes/Home";
import { ManageEventSessions } from "scenes/ManageEventSessions";
import { ManagePaymentGateways } from "scenes/ManagePaymentGateways";
import { ManageRecepientGroups } from "scenes/ManageRecepientGroups";
import ManageRole from "scenes/ManageRole/ManageRole";
import ManageSubAccounts from "scenes/ManageSubAccounts/ManageSubAccounts";
import { NotFound } from "scenes/NotFound";
import { ProfileSettings } from "scenes/ProfileSettings";
import { ManageGlobalDomains } from './scenes/GlobalDomains/ManageGlobalDomains';
import ManageConnectServiceComponent from "./scenes/ManageConnectServices/ManageConnectService.component";
import ManageCouponsComponent from './scenes/ManageCoupons/ManageCouponsComponent';
import ManageMeetingComponent from "./scenes/ManageMeetings/ManageMeeting.Component";
import { ManageMembershipAreaPlans } from "./scenes/ManageMembershipAreaProducts/ManageMemebershipAreaProduct";
import ManageMembershipTerms from "./scenes/ManageMembershipTerms/ManageMembershipTerms";
import ManageCurrencyGateway from "./scenes/ManagePaymentGateways/ManageCurrencyGateway";
import ManagePaymentMode from "./scenes/ManagePaymentMode/ManagePaymentMode";
import { ManageProducts } from './scenes/ManageProducts/ManageProductComponent';
import ProductCategoriesComponent from './scenes/ManageProducts/ProductCategoriesComponent';
import { ManageRegistrationFormStyle } from "./scenes/ManageRegistrationFormStyle/manage-registration-form-style";
import ManageRolePermissions from "./scenes/ManageRolePermissions/ManageRolePermissions";
import ManageSessions from './scenes/ManageSessions/ManageSessions';
import ManageTaxes from "./scenes/ManageTaxes/ManageTaxes";
import ManageTenantUsers from "./scenes/ManageTenantUsers/ManageTenantUsers";
import ManageWebinarMeetings from "./scenes/ManageWebinarMeeting/ManageWebinarMeetings";
import CiscoIntegratedService from "./scenes/PagesOfServiceIntegrated/CiscoIntegratedService";
import { RegistrationFormMembershipManage } from "./scenes/RegistrationFormMembershipArea/RegistrationFormMembershipManage";
import { GlobalEmailHistory } from './scenes/SendEmail/EmailHistory/GlobalEmailHistory';
import { GlobalSendEmail } from './scenes/SendEmail/GlobalSendEmail/GlobalSendEmailComponent';
import ManageSendSmsComponent from './scenes/SendSMS/ManageSendSmsComponent';
import { SubscribeEnd } from "./scenes/SubscriptionEnd/SubscribeEnd";
import { LayoutSideNavSelector } from "./components/LayoutSideNavSelector";
import * as React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { Layout } from "components/Layout";
import { SubscriptionRedirection } from "./components/SubscriptionRedirection";
import { ManageSpeakers } from "./scenes/ManageSpeakers";
import { Courses } from "./scenes/Courses";
import { ManageNews } from "./scenes/News";
import PostCategoriesComponent from "./scenes/News/Categories/PostCategoriesComponent";
import { ManageCases } from "./scenes/News/Cases";
import TagsComponent from "./scenes/News/Tags/TagsComponent";
import { Locations } from "./scenes/Locations";
import { ManageOrdersComponent } from "./scenes/ManageOrders/ManageOrders.component";
import { ManageInvoicesComponent } from "./scenes/ManageInvoices/ManageInvoices.component";
import { ManageTickets } from "./scenes/ManageTickets/Manage-Tickets";
import { OrderDetailsComponent } from "./scenes/ManageOrders/OrderDetails.component";
import { ManageGlobalSponsors } from "./scenes/ManageGlobalSponsors/ManageGlobalSponsors";
import Pages from "./scenes/Pages/Pages";
import Mails from "./scenes/Communication/Mails";
import Sms from "./scenes/Communication/Sms";
import { TenantUserDetails } from "./scenes/ManageTenantUsers/TenantUserDetails";
import EventsCalendar from "./scenes/Calendar/EventsCalendar";

export interface RouteData {
  path: string;
  name: string;
  exact: boolean;
  component:
  // | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

interface RouterOutletProps {
  setEventName: (eventName: string) => void,
  setEventId: (eventId: number | null) => void
}

export function RoutesOutlet({ setEventName, setEventId }: RouterOutletProps) {

  const accessToken = localStorage.getItem("accessToken");

  return (
    accessToken ?

      <SubscriptionRedirection>
        <Layout key={"DefaultLayout"}
                sideNav={<LayoutSideNavSelector sendEventName={setEventName} sendEventId={setEventId}/>}>
          <div className="position-relative">
            {/*//TODO: add the Transition component from react-spring*/}
            <Outlet/>
          </div>
        </Layout>
      </SubscriptionRedirection>
      : <Navigate to='/login'/>
  )
}

export default [
  { name: "Home", path: "/", exact: true, component: Home },
  {
    name: "Profile Settings",
    path: "/ProfileSettings",
    exact: true,
    component: ProfileSettings,
  },
  { name: "Speakers", path: "/Speakers", exact: true, component: ManageSpeakers },
  /*{ name: "Events", path: "/Events", exact: true, component: Events },*/
  { name: "Courses", path: "/Courses", exact: true, component: Courses },
  { name: "Orders", path: "/orders", exact: true, component: ManageOrdersComponent },
  { name: "Orders details", path: "/orders/:code", exact: true, component: OrderDetailsComponent },
  { name: "News", path: "/News", exact: true, component: ManageNews },
  { name: "Cases", path: "/Cases", exact: true, component: ManageCases },
  {
    name: "Categories",
    path: "/PostCategories",
    exact: true,
    component: PostCategoriesComponent
  },
  {
    name: "Tags",
    path: "/Tags",
    exact: true,
    component: TagsComponent
  },
  /*{
    name: "Event Details",
    path: "/Events/:eventId/Details",
    exact: true,
    component: ManageEventDetails,
  },
  {
    name: "Event Dashboard",
    path: "/Events/:eventId",
    exact: true,
    component: EventDashboard,
  },*/
  {
    name: "Global Sponsors",
    path: "/Sponsors",
    exact: true,
    component: ManageGlobalSponsors,
  },
  /*{
    name: "Sponsors Groups",
    path: "/Events/:eventId/SponsorsGroups",
    exact: true,
    component: ManageEventSponsors,
  },
  {
    name: "Committees",
    path: "/Events/:eventId/Committees",
    exact: true,
    component: EventCommittees,
  },
  {
    name: "Committee Groups",
    path: "/Events/:eventId/Committees/CommitteeGroups",
    exact: true,
    component: ManageCommitteeGroups,
  },
  {
    name: "Recepients",
    path: "/Events/:eventId/Recepients",
    exact: true,
    component: EventRecepients,
  },
  {
    name: "Recepient Groups",
    path: "/Events/:eventId/Recepients/RecepientGroups",
    exact: true,
    component: ManageRecepientGroups,
  },
  {
    name: "Speakers",
    path: "/Events/:eventId/Speakers",
    exact: true,
    component: ManageEventSpeakers,
  },
  {
    name: "Presenters",
    path: "/Events/:eventId/Presenters",
    exact: true,
    component: ManageEventPresenters,
  },
  {
    name: "Abstracts",
    path: "/Events/:eventId/Abstracts",
    exact: true,
    component: ManageEventAbstracts,
  },
  {
    name: "Abstract Types",
    path: "/Events/:eventId/Abstracts/AbstractTypes",
    exact: true,
    component: ManageEventAbstractTypes,
  },*/
  {
    name: "Schedule",
    path: "Schedule",
    exact: true,
    component: ManageEventSessions,
  },
  /*{
    name: "Notifications",
    path: "/Events/:eventId/Notifications",
    exact: true,
    component: Notifications,
  },
  {
    path: "/events/:eventId/appearance/mobileApps",
    exact: true,
    component: ListMobileApps,
  },
  {
    path: "/events/:eventId/appearance/eventPage",
    exact: true,
    component: ManageEventPage,
  },
  //Registration routes
  {
    name: "Registration",
    path: "/events/:eventId/Registration",
    exact: true,
  },
  {
    name: "Registration Form",
    path: "/events/:eventId/Registration/RegistrationForm",
    exact: true,
    component: ManageRegistrationForm,
  },
  {
    name: "Categories",
    path: "/events/:eventId/Registration/Categories",
    exact: true,
    component: ManageProductCategories,
  },
  {
    name: "Manage Tickets & Items",
    path: "/events/:eventId/Registration/Products",
    exact: true,
    component: ManageProductsAndTickets,
  },
  {
    name: "Orders",
    path: "/events/:eventId/Registration/Orders",
    exact: true,
    component: ManageEventOrders,
  },

  //TODO: Make this redirects to default template page
  {
    name: "Templates",
    path: "/events/:eventId/templates",
    exact: true,
  },
  {
    name: "Notification Templates",
    path: "/events/:eventId/templates/notifications",
    exact: true,
    component: ListNotificationTemplates,
  },
  {
    name: "Certificates",
    path: "/events/:eventId/templates/certificates",
    exact: true,
    component: Certificates,
  },*/

  /*{
    name: "Certificate",
    path: "/events/:eventId/templates/certificates/create",
    exact: true,
    component: Certificate,
  },*/
  /*{
    name: "Certificate",
    path: "/events/:eventId/templates/certificates/edit/:certificateId",
    exact: true,
    component: Certificate,
  },*/
  /*{
    name: "Send Email",
    path: "/events/:eventId/send-email",
    exact: true,
    component: SendEmail,
  },
  {
    name: "With Certificate",
    path: "/events/:eventId/send-email/Certificates",
    exact: true,
    component: SendEmailWithCertificate,
  },
  {
    name: "Domains",
    path: "/events/:eventId/domains",
    exact: true,
    component: ManageEventDomains,
  },*/
  {
    name: "Domains",
    path: "/domains",
    exact: true,
    component: ManageGlobalDomains,
  },
  {
    name: "Payment Gateways",
    path: "/paymentGateways",
    exact: true,
    component: ManagePaymentGateways,
  },
  //CurrencyGateways
  {
    name: "Currency",
    path: "/Currency",
    exact: true,
    component: ManageCurrencyGateway,
  },

  {
    name: "Taxes",
    path: "/Taxes",
    exact: true,
    component: ManageTaxes,
  },
  {
    name: "Payment Mode",
    path: "/paymentMode",
    exact: true,
    component: ManagePaymentMode,
  },
  {
    name: "Email Configuration",
    path: "/email-config",
    exact: true,
    component: EmailConfiguration,
  },

  /*{
    name: "Email History",
    path: "/events/:eventId/Emails/History",
    exact: true,
    component: EmailHistory,
  },*/
  {
    name: "Email History",
    path: "/Emails/History",
    exact: true,
    component: GlobalEmailHistory,
  },
  {
    name: "Users",
    path: "/Users",
    exact: true,
    component: ManageSubAccounts,
  },

  //Roles

  {
    name: "Roles",
    path: "/Roles",
    exact: true,
    component: ManageRole,
  },

  {
    name: "Role Permission",
    path: "/role-permission/:id",
    exact: true,
    component: ManageRolePermissions,
  },
  {
    name: "Connect Services",
    path: "/ConnectServices",
    exact: true,
    component: ManageConnectServiceComponent,
  },
  //CiscoIntegratedService
  {
    name: "Cisco Integration",
    path: "/cisco-service",
    exact: true,
    component: CiscoIntegratedService,
  },

  {
    name: "Manage Meetings",
    path: "/manage-meetings",
    exact: true,
    component: ManageMeetingComponent,
  },
  // manage-webinar
  {
    name: "Manage Webinar",
    path: "/manage-webinar",
    exact: true,
    component: ManageWebinarMeetings,
  },
  // manage tenant user.
  {
    name: "Manage Membership Area Users",
    path: "/Customers",
    exact: true,
    component: ManageTenantUsers,
  },
  {
    name: "Manage Membership Area Users",
    path: "/Customers/:id",
    exact: true,
    component: TenantUserDetails,
  },
  {
    name: "Membership Plans",
    path: "/MembershipPlans",
    exact: true,
    component: ManageMembershipAreaPlans,
  },
  //  /MembershipArea/Terms
  {
    name: "Membership Terms & Conditions",
    path: "/MembershipArea/Terms",
    exact: true,
    component: ManageMembershipTerms,
  },

  {
    name: "Membership  Area Form",
    path: "/membership-form",
    exact: true,
    component: RegistrationFormMembershipManage,
  },
  {
    name: "Membership Style",
    path: "/MembershipArea/style",
    exact: true,
    component: ManageRegistrationFormStyle,
  },
  {
    name: "Subscribe End",
    path: "/renew-subscribe",
    exact: true,
    component: SubscribeEnd,
  },
  {
    name: "Invoices",
    path: "/invoices",
    exact: true,
    component: ManageInvoicesComponent,// ManageInvoiceComponent,
  },
  {
    name: "Products",
    path: "/Products",
    exact: true,
    component: ManageProducts
  },
  // {
  //     name: "Add Product",
  //     path: "/add-product",
  //     exact: true,
  //     component: AddProductComponent
  // },
  {
    name: "Product Categories",
    path: "/ProductCategories",
    exact: true,
    component: ProductCategoriesComponent
  },
  {
    name: "Coupons",
    path: "/Coupons",
    exact: true,
    component: ManageCouponsComponent
  },
  {
    name: "Locations",
    path: "/Locations",
    exact: true,
    component: Locations
  },
  {
    name: "Pages",
    path: "/Pages",
    exact: true,
    component: Pages
  },
  {
    name: "Recipient Group",
    path: "/recipientList",
    exact: true,
    component: ManageRecepientGroups
  },

  {
    name: "Recipients",
    path: "/recipients",
    exact: true,
    component: EventRecepients
  },
  {
    name: "Send Email",
    path: '/send-email',
    exact: true,
    component: GlobalSendEmail
  },
  {
    name: "Send SMS ",
    path: '/send-sms',
    exact: true,
    component: ManageSendSmsComponent
  },
  {
    name: "Check-in",
    path: '/CheckIn',
    exact: true,
    component: ManageSessions
  },
  {
    name: "Calendar",
    path: '/Calendar',
    exact: true,
    component: EventsCalendar
  },
  {
    name: "Tickets",
    path: '/tickets',
    exact: true,
    component: ManageTickets
  },
  {
    name: "Email",
    path: '/Email',
    exact: true,
    component: Mails
  },{
    name: "SMS",
    path: '/SMS',
    exact: true,
    component: Sms
  },
  { name: "Not Found", path: "**", exact: true, component: NotFound },
] as RouteData[];

//EventRecepients