import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";

interface TimeLineItemProps {
    startTime: moment.Moment;
    endTime: moment.Moment;
    children: React.ReactNode;
}

export const TimeLineItem: React.FC<TimeLineItemProps> = props => {
    return (
        <li className="list-group-item">
            <div className="d-flex flex-row">
                <div className="text-center text-primary flex-shrink-0">
                    {props.startTime.local().format("hh:mm A")}
                    <br />
                    <span className="text-muted small">
                        to {props.endTime.local().format("hh:mm A")}
                    </span>
                </div>
                <div className="flex-fill mx-3">{props.children}</div>
            </div>
        </li>
    );
};

TimeLineItem.propTypes = {
    children: PropTypes.node.isRequired,
    startTime: (props, propName, componentName) => {
        let error = null;
        const prop = props[propName];
        if (!moment.isMoment(prop)) {
            error = new Error("'Time' should be a moment object");
        }
        return error;
    }
};
