import * as React from "react";
import {useEffect, useState} from "react";
import { Button, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {Field, FieldProps, Form, Formik, FormikProps} from "formik";
import {FaSpinner} from "react-icons/fa";
import {
    configureTwilioService,
    configureUnifonicService,
    getTwilioService,
    getUnifonicService, testSms,
    TwilioService,
    UnifonicService
} from "../../api/ConnectServiceApi";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import {PhoneInput} from "../../components/PhoneInput/PhoneInput";


export interface ConfigureSmsServiceProps {
    name: string;
    isOpen: boolean;
    close: (success: boolean)=>void;
}


export const ConfigureSmsService: React.FC<ConfigureSmsServiceProps> = props => {

    const [isTwilioService, setIsTwilioService] = useState(props.name === "Twilio");

    const [smsService, setSmsService] = useState<any | null>(null);
    const [testPhoneNumber,setTestPhoneNumber] = useState<string>('')

    const sendTestSms = () => {
        testSms({ mobileForTest:testPhoneNumber }).then(() => {
            toast.success(
              <ToastMessage>
                  Test SMS sent successfully
              </ToastMessage>
            );
        }).catch(() => {
            toast.error(
              <ToastMessage type="Error">
                  Error while sending the test SMS
              </ToastMessage>
            );
        })

    }

    useEffect(()=>{
        if(props.isOpen){
            if(isTwilioService){
                getTwilioService().then(service=>setSmsService(service !== "" ? service : null));
            } else {
                getUnifonicService().then(service=>setSmsService(service !== "" ? service : null));
            }
        }
    }, [props.isOpen])

    const handleFormSubmit = React.useCallback(
        async (
            values: any,
        ) => {

            let promise = null;

            if(isTwilioService){
                promise = configureTwilioService(values as TwilioService);
            } else {
                promise = configureUnifonicService(values as UnifonicService);
            }

            promise.then(resp=>{
                toast.success(
                    <ToastMessage>
                       Sms Service Configured Successfully!
                    </ToastMessage>
                );
                props.close(true);
            }).catch(err=>{
                toast.error(
                    <ToastMessage type="Error">
                        Error Configuring Sms Service
                    </ToastMessage>
                );
            })
        }
    , [props])


    const renderForm =
        (formProps: FormikProps<any>) =>
                <Form>
                    <ModalBody>

                        {isTwilioService ?
                            <>
                                <FormGroup>
                                    <Label>Account Sid</Label>
                                    <Field
                                        name={"accountSid"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field} />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Auth Token</Label>
                                    <Field
                                        name={"authToken"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field} />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Sender</Label>
                                    <Field
                                        name={"sender"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field}/>
                                        )}
                                    />
                                </FormGroup>

                            </>
                            :
                            <>

                                <FormGroup>
                                    <Label>Apps Id</Label>
                                    <Field
                                        name={"appsId"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field} />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Sender</Label>
                                    <Field
                                        name={"sender"}
                                        render={({field}: FieldProps) => (
                                            <PhoneInput
                                                {...field}
                                                onPhoneNumberUpdated={formattedPhoneNumber => {
                                                    formProps.setFieldValue("sender",
                                                        formattedPhoneNumber
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Basic Auth UserName</Label>
                                    <Field
                                        name={"basicAuthUserName"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field} />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Basic Auth Password</Label>
                                    <Field
                                        name={"basicAuthPassword"}
                                        render={({field}: FieldProps) => (
                                            <Input
                                                {...field}
                                                autoComplete={"new-password"}
                                                type="password"
                                                placeholder={"••••••"}
                                            />
                                        )}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Encoding</Label>
                                    <Field
                                        name={"encoding"}
                                        render={({field}: FieldProps) => (
                                            <Input {...field} />
                                        )}
                                    />
                                </FormGroup>

                            </>
                        }
                        <FormGroup>
                            <Label>Phone number For Test</Label>
                            <InputGroup>
                                <Input onChange={(event) => {
                                    setTestPhoneNumber(event.target.value)
                                }}/>
                                <Button color="primary" onClick={() => sendTestSms()}
                                        disabled={!testPhoneNumber}>
                                    Test
                                </Button>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup switch>
                            <Label>Is Default</Label>
                            <Field
                              name={"isDefault"}
                              render={({field}: FieldProps) => (
                                <Input
                                  type="switch"
                                  label="Active"
                                  id={field.name}
                                  checked={field.value}
                                  {...field}
                                />
                              )}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={()=>props.close(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={formProps.isSubmitting}
                        >
                            {formProps.isSubmitting && (
                                <span>
                                    <FaSpinner className="icon-spin" />
                                </span>
                            )}{" "}
                            Save
                        </Button>{" "}
                    </ModalFooter>
                </Form>
        ;

    return (
        <Modal unmountOnClose isOpen={props.isOpen} size="lg">
            <ModalHeader toggle={()=>props.close(false)}>
                Configure Sms Service {props.name}
            </ModalHeader>

            <Formik
                initialValues={smsService??{}}
                enableReinitialize
                onSubmit={handleFormSubmit}
                render={renderForm}
            />
        </Modal>
    );
}