import Axios from "axios";


export interface SendSmsRequest {
    phoneNumbers?: Array<string>;
    recipientListExcludeRecipientItems?: Array<{ recipientListId: number, exclude: Array<number> | null }>;
    body: string;
}

export const SendSMS = async (request: SendSmsRequest): Promise<any> => {
    const response = await Axios.post(`api/MobileMessage/SendSMS`, request);
    return response.data;
}