/* eslint-disable react/no-deprecated */
import * as React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {deleteProductCategory} from "../../api/ProductCategoriesApi";
import {ProductCategory} from "../../api/ProductsApi";
import {Loader} from "../../components/Loader";

interface IDeleteProductCategoryProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    deleteItem: ProductCategory
}


interface IDeleteProductCategoryState {
    loading: boolean,
    id: number
}

class DeleteCategory extends React.Component<IDeleteProductCategoryProps, IDeleteProductCategoryState> {

    constructor(props: IDeleteProductCategoryProps) {
        super(props);
        this.state = {
            loading: false,
            id: 0
        }
    }

    componentWillMount() {
        if (this.props.deleteItem) {
            this.setState({ id: this.props.deleteItem.id })
        }
    }

    handleDeleting = () => {
        this.setState({ loading: true })
        deleteProductCategory(this.state.id).then(res => {
            this.setState({ loading: false })
            this.props.cancelDeleting?.();
            this.props.onUpdated?.();
        })
    }



    render() {
        return (
            <Modal size="lg" toggle={this.props.cancelDeleting} isOpen={!!this.props.deleteItem}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Product Category
                </ModalHeader>
                <ModalBody>
                    {this.state.loading ? <Loader isLoading={true} /> : null}
                    Are you sure you want to delete &quot;
                    {this.props.deleteItem &&
                        `${this.props.deleteItem.name || ""}`}
                    &quot;?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.deleteItem &&
                            `${this.props.deleteItem.name || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDeleting}>
                        Yes, Delete &quot;
                        {this.props.deleteItem &&
                            `${this.props.deleteItem.name || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteCategory;