import * as React from "react";
import { useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";

const PrintSessionTicket = ({ item, close, open }: any) => {
  const isSession = item?.product?.productType?.name === 'Session';


  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  return (<Modal unmountOnClose isOpen={open} toggle={close}>
    <ModalHeader toggle={close}>
      {isSession ? 'Print Session' : 'Print Ticket'}
    </ModalHeader>
    <ModalBody className="d-flex flex-column justify-content-center align-items-center gap-2">
      <button onClick={handlePrint} className="mx-auto btn btn-primary ">
        <FaPrint/> Print
      </button>

      <div ref={printRef}
           className='w-100 d-flex flex-row justify-content-center align-items-center flex-column gap-2 print'>
        <span>User Id : {item?.user?.id}</span>
        <span>Name : {item?.user?.displayName}</span>
        <span>Product : {item?.product?.orderProductId}</span>
        <QRCode size={128} value={item?.product?.securityStamp ?? ''}/>

      </div>
    </ModalBody>
  </Modal>);
}

export default PrintSessionTicket;