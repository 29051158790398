import * as React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import Select from "react-select";
import {
  getRecipientListDropdownList,
  IRecipientListDropdownList
} from "../../api/RecipientLists";
import './RecipientGroupSelectorStyle.css';
import RecipientsTableModal from "./RecipientsTableModal";
interface OwnProps {
  onChange: (
    val: Array<{
      label: string;
      value: number;
      excluded?: Array<{ id: number }>;
    }> | null
  ) => void;
}

type Props = OwnProps;
export default class RecipientGroupSelectorComponent extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deSelectIds: [],
      recipientsList: [],
      options: []
    };
  }

  fetchRecipientListHandler = () => {
    if (this.state.recipientsList.length > 0) return;
    this.setState({ __loading__: true });

    getRecipientListDropdownList()
      .then((recipientsList: IRecipientListDropdownList[]) => {
        this.setState({ __loading__: false });

        this.setState({ recipientsList });
      })
      .catch((error) => {
        this.setState({ __loading__: false });
      });
  };

  // formatOptionLabel

  formatOptionLabelHandler = (option: any, labelMeta: any) => {
    return (
      <div className={"d-flex align-items-center"}>
        <option value={option.value} className={"test"}>
          {option.label}
        </option>


        {labelMeta.context === "value" ? (
          this.state.deSelectIds.length > 0 &&
            this.state.deSelectIds.find(
              (x: any) => x.recipientListId === option.value
            ) ? (
            <div className={"flex-2"}>
              <small style={{ color: "red", fontWeight: 600 }}>
                {" Excluded"}
                (
                {this.state.deSelectIds
                  .find((x: any) => x.recipientListId === option.value)
                  ?.deSelectedIds.length.toString() + ")"}{" "}
              </small>
            </div>
          ) : null
        ) : null}

        {labelMeta.context === "value" ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              // TODO : Open Recipient table
              this.setState(
                {
                  recipientsListEditable: {
                    id: option.value,
                    name: option.label,
                  },
                  openRecipientsTbl: true,
                },
                () => { }
              );
            }}
            className={"btn css-xb97g8 css-xb487"}
          >
            {" "}
            <AiOutlineEdit />{" "}
          </button>
        ) : null}

      </div>
    );
  };

  closeRecipientsListEditable = () => {
    this.setState({ recipientsListEditable: null, openRecipientsTbl: false });
  };


  deSelectedRecipientsIdHandler = (deSelectId: { deSelectedIds: Array<number>, recipientListId: number }) => {
    let { deSelectIds } = this.state;
    deSelectIds = deSelectIds.filter((x: any) => x.recipientListId !== deSelectId.recipientListId);
    if (deSelectId.deSelectedIds.length > 0) {
      deSelectIds.push(deSelectId);
    }
    this.setState({ deSelectIds }, () => {
      this.outputComponentHandler(this.state.options);
    });
  }



  outputComponentHandler = (options: { label: string, value: number }[]) => {
    if (!options) return;
    const outPutData = options.map((option: { label: string, value: number }) => {
      const deSelectedIs = this.state.deSelectIds.find((x: any) => x.recipientListId == option.value);
      let excluded = [];

      if (deSelectedIs?.deSelectedIds && deSelectedIs.deSelectedIds.length > 0) {
        excluded = deSelectedIs.deSelectedIds.map((x: number) => { return { id: x } });
      }
      return {
        label: option.label, value: option.value, excluded
      }
    })

    this.props?.onChange?.(outPutData);
  }


  render() {
    const recipientListOptions = this.state.recipientsList.map((item: any, key: number) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    return (
      <>
        {this.state.openRecipientsTbl && this.state.recipientsListEditable ? (
          <RecipientsTableModal
            sendDeSelectIds={this.state.deSelectIds.find(
              (d: any) =>
                d.recipientListId ===
                (this.state.recipientsListEditable
                  ? this.state.recipientsListEditable.id
                  : 0)
            )}
            close={this.closeRecipientsListEditable}
            isOpen={this.state.openRecipientsTbl}
            recipientListId={this.state.recipientsListEditable.id}
            recipientListName={this.state.recipientsListEditable.name}
            receiveDeSelectIds={this.deSelectedRecipientsIdHandler}
          />
        ) : null}

        <div className={"form-group"}>
          <label> Recipient Group : </label>
          <Select
            isMulti={true}
            options={recipientListOptions}
            onMenuOpen={this.fetchRecipientListHandler}
            isSearchable={true}
            formatOptionLabel={this.formatOptionLabelHandler}
            onChange={(options) => {
              const { deSelectIds } = this.state;
              const newOnes = deSelectIds.filter(
                (d: any) => options.some((x: { label: string; value: number }) => x.value === d.recipientListId)
              );

              this.setState({ deSelectIds: newOnes, options: options as Array<{ label: string; value: number }> });

              this.outputComponentHandler(options as Array<{ label: string; value: number }>);
            }}
          />
        </div>
      </>
    );
  }
}
