/* eslint-disable react/jsx-key */
import { Loader } from 'components/Loader';
import * as React from 'react';
import { useState } from 'react';
import Helmet from "react-helmet";
import { AiOutlineSearch } from 'react-icons/ai';
import { FaEllipsisH, FaEye, FaFileExport, FaPrint, FaQrcode } from 'react-icons/fa';
import {
  Badge,
  Button,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Row,
  UncontrolledButtonDropdown
} from "reactstrap";
import { ChekinStatus, getAllAttendencees, getCheckinsStatus, getExportData } from '../../api/MembershipAreaSessionApi';
import { MainCard } from "../../components/MainCard";
import WindowClickEventComponent from '../../components/RegisterClickWindowEvent/WindowClickEventComponent';
import AddEditSessionComponent from './AddEditSessionComponent';
import ConfirmCheckedInComponent from './ConfirmCheckedInComponent';
import BarCodeSessionLViewComponent from './ViewBarCodeComponent';
import { SmartAccessComponent } from "../ManageTenantUsers/SmartAccessComponent";
import emptyStateSessionImage from "../../assets/imgs/empty_state_sessions.svg";
import { EmptyListState } from "../../components/EmptyListState";
import { statusColor } from "../ManageOrders/ManageOrders.component";
import { formatSimpleDate } from "../../util/dateUtil";
import { PaginationFooter } from "../../components/PaginationFooter";
import moment from "moment";
import Select from "react-select";
import DatePicker from "../../components/DatePicker/DatePicker";
import { SearchTenantUsers } from "../../api/MembershipAreaApi";
import AsyncSelect from "react-select/async";
import PrintSessionTicket from "./PrintSessionTicket";
import ScanSessionTicket from "./ScanSessionTicket";
import { toast } from "react-toastify";

const ManageSessions: React.FC = props => {

  const [sessions, setSessions] = React.useState<Array<any>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showActionCardKey, setShowActionCardKey] = React.useState<number>(-1);
  const [barCodeSessionView, setBarCodeSessionView] = React.useState<{ sessionId: number, sessionNumber: string } | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>();
  const [addEditCommentData, setAddEditCommentData] = React.useState<{ sessionId: number, sessionNumber: string } | null>(null);
  const [toggleCheckInSession, setToggeCheckInSession] = React.useState<{ sessionId: number, sessionNumber: string, currentStatus: ChekinStatus } | null>(null);
  const [showSmartAccess, setShowSmartAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maximumPerPage] = useState(20);
  const [status, setStatus] = useState<any>(undefined);
  const [clientId, setClientId] = useState<any>(undefined);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [attendanceStatus, setAttendanceStatus] = useState<any[]>([])
  const [customers, setCustomers] = useState<any[]>([])
  const [openTicket, setOpenTicket] = useState<boolean>()
  const [selectedSession, setSelectedSession] = useState<any>()
  const [openScan, setOpenScan] = useState<boolean>();


  const getSessionsHandler = async () => {

    setLoading(true);

    const result = await getAllAttendencees({
      text: searchValue ?? undefined,
      pageIndex: page,
      limit: maximumPerPage,
      statusId: status?.id,
      clientId: clientId?.id,
      fromDate: startDate ? moment(startDate).toISOString() : undefined,
      endDate: endDate ? moment(endDate).endOf('day').toISOString() : undefined
    });
    // const result = await getAllSessions({key: searchKey ?? undefined, value: searchValue ?? undefined});
    setLoading(false);
    setSessions([...(result?.items ?? [])]);
    setCount(result?.meta.totalItemsCount);

  }


  const setShowactionCardKeyHandler = React.useCallback((key) => {
    setShowActionCardKey(key);
  }, [setShowActionCardKey])


  React.useEffect(() => {
    getSessionsHandler();

  }, [page])

  React.useEffect(() => {
    getCheckinsStatus().then(value => {
      setAttendanceStatus(value)
    })
    SearchTenantUsers()
      .then(val => setCustomers(val))
      .catch(err => {
        ;
      })

  }, [])

  const OpenAddEditSessionComment = React.useCallback((sessionId: number, sessionNumber) => {
    setAddEditCommentData({ sessionId, sessionNumber });
  }, [setAddEditCommentData])

  const toggleShowSmartAccess = () => {
    setShowSmartAccess(!showSmartAccess);
  }

  const openPrintModal = (item: any) => {
    setSelectedSession(item);
    setOpenTicket(true)
  }
  const closePrintModal = () => {
    setSelectedSession(undefined);
    setOpenTicket(false);
  }
  const exportCSV = () => {
    getExportData({
      text: searchValue ?? undefined,
      statusId: status?.id,
      clientId: clientId?.id,
      fromDate: startDate ? moment(startDate).toISOString() : undefined,
      endDate: endDate ? moment(endDate).add(1, 'day').subtract(1, 'second').toISOString() : undefined
    }).then(value => {
      const url = window.URL.createObjectURL(new Blob(["\uFEFF" + value], { type: 'text/csv;charset=utf-8;' }))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Attendances Export.csv`;
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(reason => {
      toast.error(reason.response.data?.detail ?? "Error while Exporting Attendances")
    })
  }


  return (
    <React.Fragment>
      <Helmet title="Check in"/>
      {
        toggleCheckInSession ?
          <ConfirmCheckedInComponent
            onUpdate={() => {
              setToggeCheckInSession(null);
              getSessionsHandler();
            }}
            open={true}
            sessionId={toggleCheckInSession.sessionId}
            sessionNumber={toggleCheckInSession.sessionNumber}
            currentStatus={toggleCheckInSession.currentStatus}
            close={() => {
              setToggeCheckInSession(null);
            }}
          />
          : null
      }
      {
        addEditCommentData ?
          <AddEditSessionComponent open={true} close={() => {
            setAddEditCommentData(null);
          }}
                                   sessionId={addEditCommentData.sessionId}
                                   sessionNumber={addEditCommentData.sessionNumber}
          />
          : null
      }
      <WindowClickEventComponent showActionCardKey={showActionCardKey} setShowActionCardKey={(val: number) => {

        setShowActionCardKey(val)
      }}/>

      <PrintSessionTicket open={openTicket} close={closePrintModal} item={selectedSession}/>
      <ScanSessionTicket open={openScan} items={sessions} openPrint={openPrintModal} onUpdate={getSessionsHandler}
                         close={() => setOpenScan(false)}/>

      <MainCard>
        <CardHeader>
          <div className={"space-between"}>
                        <span>
                            Check in
                        </span>

            <div className={'d-flex flex-row gap-2'}>
              <Button
                className={'d-flex align-items-center gap-2'}
                onClick={() => setOpenScan(true)}
                color="primary"
              >
                Scan & Check In<FaQrcode/>
              </Button>
              <Button
                className={'d-flex align-items-center gap-2'}
                onClick={exportCSV}
                color="success"
              >
                Export<FaFileExport/>
              </Button>
            </div>
            {/*<button*/}
            {/*    onClick={toggleShowSmartAccess}*/}
            {/*    className={"btn btn-primary"}*/}
            {/*>*/}
            {/*    Quick access{" "}*/}
            {/*    <i>*/}
            {/*        <AiOutlineFullscreen />*/}
            {/*    </i>*/}
            {/*</button>*/}
          </div>
          {
            barCodeSessionView ?
              <BarCodeSessionLViewComponent isOpen={true}
                                            close={() => {
                                              setBarCodeSessionView(null);
                                            }}
                                            sessionId={barCodeSessionView.sessionId}
                                            sessionNumber={barCodeSessionView.sessionNumber}
              />
              : null
          }
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="align-items-center mb-4">
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'Start Date'}
                    closeOnSelect
                    value={startDate}
                    onChange={setStartDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup className='flex-row align-items-center'>
                  <DatePicker
                    timeFormat={false}
                    className='w-100'
                    placeHolder={'End Date'}
                    closeOnSelect
                    value={endDate}
                    onChange={setEndDate}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6" className='my-1'>
                <InputGroup>
                  <AsyncSelect
                    isClearable
                    defaultOptions={customers}
                    value={clientId}
                    onChange={setClientId}
                    className="form-control flex-2"
                    placeholder="Select Customer"
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.displayName}
                    loadOptions={async (val) => {
                      return await SearchTenantUsers({ text: val });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6">
                <InputGroup>
                  <input
                    value={searchValue || ""}
                    onChange={(e) => {
                      const { value } = e.target;
                      setSearchValue(value);
                    }}
                    type="text"
                    className="form-control flex-2"
                    placeholder="Search"
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6">
                <InputGroup>
                  <Select
                    isClearable
                    options={attendanceStatus}
                    value={status}
                    onChange={setStatus}
                    className="form-control flex-2"
                    placeholder="Select Status"
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.name}
                  />
                </InputGroup>
              </Col>
              <Col sm="4" xs="6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    getSessionsHandler();
                  }}
                  className="btn btn-success flex-2 w-100"
                >
                  <i className='flex justify-content-center align-items-center'>
                    <AiOutlineSearch size={18}/>{" "}Search
                  </i>
                </button>
              </Col>

            </Row>
          </Form>

          {
            loading ?
              <Loader isLoading={true}/>
              : null
          }
          {
            sessions && sessions.length > 0 ?
              <table className={"table table-response"}>
                <thead className="thead-dark">
                <tr>
                  <td>
                    #
                  </td>
                  <td>
                    Customer
                  </td>
                  <td>
                    Product
                  </td>
                  <td>
                    Instructor
                  </td>
                  <td>
                    Location
                  </td>
                  <td>
                    Status
                  </td>
                  <td>
                    Date
                  </td>
                  <td>
                    Actions
                  </td>
                </tr>
                </thead>
                <tbody>
                {sessions.map((item: any, key: number) => {
                  return (
                    <tr key={key}>
                      <td> {item.id} </td>
                      <td>{item.user?.displayName}</td>
                      <td> {item.product?.name} </td>
                      {item.instructor ? <td>{item.instructor?.firstName + " " + item.instructor?.lastName}</td> :
                        <td className="text-secondary fst-italic">Not Assigned</td>}
                      {item.location ? <td>{item.location?.name}</td> :
                        <td className="text-secondary fst-italic">Not Assigned</td>}
                      {/*<td>{item.status?.name}</td>*/}
                      <td><Badge style={{ width: '100%', height: '20px' }}
                                 color={statusColor(item.attendanceStatus)}>{item.attendanceStatus?.name}</Badge></td>
                      <td> {formatSimpleDate(item.checkedInDate)}</td>
                      {/*<td>{item.checkedIn ? "Checked-In" : "Not Checked-In"}</td>*/}

                      <td className="position-relative">

                        <UncontrolledButtonDropdown size="sm">
                          <DropdownToggle caret outline color="dark">
                            <FaEllipsisH/>
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem onClick={() => {
                              setToggeCheckInSession({
                                sessionId: item.id,
                                sessionNumber: item.id,
                                currentStatus: item.attendanceStatus
                              })
                            }}>
                              <FaEye/> Change Status
                            </DropdownItem>
                            {/*<DropdownItem>
                              <FaRegComment/> Add Comment
                            </DropdownItem>*/}
                            <DropdownItem onClick={() => openPrintModal(item)}>
                              <FaPrint/> Print
                            </DropdownItem>
                            {/*<DropdownItem>
                              <FaHistory/> History Log
                            </DropdownItem>*/}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        {/*    */}
                        {/*<Button*/}
                        {/*    color={"primary"}*/}
                        {/*    outline={true}*/}
                        {/*    onClick={e => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        setBarCodeSessionView({*/}
                        {/*            sessionId: item.id,*/}
                        {/*            sessionNumber: item.id+""*/}
                        {/*        })*/}
                        {/*        setShowactionCardKeyHandler(-1);*/}
                        {/*    }}*/}
                        {/*><FiEye/>*/}
                        {/*</Button>{" "}*/}
                        {/*<Button*/}
                        {/*    color={"primary"}*/}
                        {/*    outline={true}*/}
                        {/*    onClick={e => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        OpenAddEditSessionComment(item.id, item.id)*/}
                        {/*        setShowactionCardKeyHandler(-1);*/}
                        {/*    }}*/}
                        {/*> <FaRegComment/>*/}
                        {/*</Button>{" "}*/}

                        {/*<Button*/}
                        {/*    color={"success"}*/}
                        {/*    outline={true}*/}
                        {/*    onClick={e => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        setToggeCheckInSession({*/}
                        {/*            sessionId: item.id,*/}
                        {/*            sessionNumber: item.id+"",*/}
                        {/*            wantCheckIn: !item.checkedIn*/}
                        {/*        })*/}
                        {/*        setShowactionCardKeyHandler(-1);*/}
                        {/*    }}*/}
                        {/*> <FaCheck/>*/}
                        {/*</Button>*/}
                      </td>
                    </tr>
                  );
                })
                }
                </tbody>
                <tfoot>
                <tr>
                  <td colSpan={8}>
                    <PaginationFooter
                      onPageClicked={setPage}
                      pageIndex={page}
                      totalItemsCount={count}
                      pageSize={maximumPerPage}
                      pageItemsCount={sessions.length}
                    />
                  </td>
                </tr>
                </tfoot>

              </table>
              :
              <EmptyListState
                image={emptyStateSessionImage}
                title={"There are no attendees"}
              />
          }
        </CardBody>
      </MainCard>


      <SmartAccessComponent
        isOpen={showSmartAccess}
        close={toggleShowSmartAccess}
      />
    </React.Fragment>
  );
}

export default ManageSessions;