import Axios from "axios";
import { NewResourceResult } from "./common/NewResourceResult";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface Certificate {
  id: number;
  name: string;
  htmlTemplateContent: string;
  rawData: string;
}

export interface AddCertificateRequest extends Omit<Certificate, "id"> {
  eventId: number;
}

export interface GetCertificateRequest extends PaginatedRequest {
  eventId: number;
}

export const getCertificates = async (
  request: GetCertificateRequest
): Promise<PaginatedResult<Certificate>> => {
  const result = await Axios.get<PaginatedResult<Certificate>>(
    `/api/Events/${request.eventId}/CertificateTemplates`,
    {
      params: request,
    }
  );
  return result.data;
};

export const addCertificate = async (
  request: AddCertificateRequest
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post<NewResourceResult<number>>(
    `/api/Events/${request.eventId}/CertificateTemplates`,
    request
  );

  return result.data;
};

export const updateCertificate = async (
  request: Certificate
): Promise<NewResourceResult<number>> => {
  const result = await Axios.put<NewResourceResult<number>>(
    `/api/CertificateTemplates/${request.id}`,
    request
  );

  return result.data;
};

export const deleteCertificate = async (
  id: number
): Promise<NewResourceResult<number>> => {
  const result = await Axios.delete<NewResourceResult<number>>(
    `/api/CertificateTemplates/${id}`
  );

  return result.data;
};

// add global certificate

export interface AddGlobalCertificateRequest extends Omit<Certificate, "id"> {}

export const addGlobalCertificate = async (
  request: AddGlobalCertificateRequest
): Promise<NewResourceResult<number>> => {
  const result = await Axios.post<NewResourceResult<number>>(
    `/api/CertificateTemplates/CertificateTemplates`,
    request
  );

  return result.data;
};

export const getGlobalCertificates = async (): Promise<PaginatedResult<
  Certificate
>> => {
  const result = await Axios.get(
    `/api/CertificateTemplates/CertificateTemplates`
  );
  return result.data;
};
