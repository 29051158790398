import axios from "axios";
import store from "./configureStore";

export const configureAxios = () => {
    axios.defaults.baseURL = "https://emsapi.shamilapp.com";
    axios.defaults.xsrfCookieName = "XSRF-TOKEN";
    axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

    // Add an interceptor to inject the authentication header
    axios.interceptors.request.use((config) => {
        if(localStorage.getItem('accessToken')){
            const token = localStorage.getItem('accessToken');
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    axios.interceptors.response.use(undefined,  async error => {
        if (error.response.status === 402) {
            store.dispatch({ type: "INVALID_SUBSCRIPTION" });
        } else if (error.response.status === 401 && !error.response.config.url.includes("Connect/Refresh")) {
            const originalRequest = error.config;
            const refreshToken = localStorage.getItem('refreshToken')
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const { accessToken, refreshToken: newRefreshToken } = await axios.post("https://emsapi.shamilapp.com/Connect/Refresh", { refreshToken }).then(response => response.data)
                    localStorage.setItem("accessToken", accessToken);
                    localStorage.setItem("refreshToken", newRefreshToken)
                    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
                    delete originalRequest.headers
                    return axios.request(originalRequest);
                } catch (refreshError) {
                    store.dispatch({ type: "UNAUTHORIZED", payload: error});
                    return Promise.reject(refreshError);
                }
            }
            store.dispatch({ type: "UNAUTHORIZED", payload: error});
        } else if (error.response.status === 403) {
            store.dispatch({ type: "FORBIDDEN" });
        }
        return Promise.reject(error);
    });
};
