import { addNewSpeaker, AddSpeakerRequest, EditSpeakerRequest, getSpeakerById, updateSpeaker } from "api/Speakers";
import { ContactInfo } from "api/common/ContactInfo";
import { ContactForm } from "components/ContactForm";
import { ToastMessage } from "components/ToastMessages";
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { nameof } from "util/nameof";
import imgDefault from '../../assets/imgs/photos.svg';
import { formValidation } from "./AddEditSpeakerValidation";

export type DefaultSpeakerFormValuesOmit = Omit<AddEditSpeakerForm,
  "profilePicture">;

export interface DefaultSpeakerFormValues extends DefaultSpeakerFormValuesOmit {
  profilePicturePreview?: string;
}

interface AddEditSpeakerFormModalProps {
  isOpen: boolean;
  eventId: number;
  toggle: () => void;
  defaultValue?: DefaultSpeakerFormValues | null;
}

export interface AddEditSpeakerForm {
  [key: string]: any;

  id: number | null;
  profilePicture: File | null;
  title: string | null;
  firstName: string | null;
  secondName: string | null;
  thirdName: string | null;
  lastName: string | null;
  contact: ContactInfo | null;
  isOnHomePage: boolean;
}

export const AddEditSpeakerFormModal: React.FC<AddEditSpeakerFormModalProps> = props => {
  const [profileImagePreview, setProfileImagePreview] = useState<string | undefined>(undefined);

  const formikRef = useRef<FormikProps<AddEditSpeakerForm>>(null)

  const optionsTitle = [
    { value: 'Dr', label: 'Dr' },
    { value: 'Professor', label: 'Professor' },
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Madam', label: 'Miss' },
    { value: 'Eng', label: 'Eng' },
    { value: 'Sir', label: 'Sir' },

  ];
  const [title, setTitle] = React.useState<any>(null);

  //[{eventId: 0, eventName: 'Home Page', isOnHomePage: true}]
  const [actionInProgress, setActionInProgress] = useState(false);


  const initialValues = useMemo<AddEditSpeakerForm>(
    () => {
      if (props && props.defaultValue) {
        const value = optionsTitle.find(x => props.defaultValue != null && x.value.toLowerCase() === props.defaultValue.title?.toLowerCase())
        setTitle(value)
      }
      return props.defaultValue
        ? (props.defaultValue as AddEditSpeakerForm)
        : {
          profilePicture: null,
          id: null,
          title: "",
          firstName: "",
          secondName: "",
          thirdName: "",
          lastName: "",
          contact: {
            email: ''
          },
          isOnHomePage: false,
          AssignedEvents: []
        }
    },
    [props.defaultValue]
  );

  useEffect(() => {
    if (!props.defaultValue) return;
    if (props.defaultValue.id) {
      getSpeakerById(props.defaultValue.id).then(speaker => console.debug('speaker', speaker));
    }
    if (props.defaultValue.profilePicturePreview) {
      setProfileImagePreview(
        props.defaultValue && props.defaultValue.profilePicturePreview
          ? props.defaultValue.profilePicturePreview
          : undefined
      );
    }
  }, [props.defaultValue]);

  const generateProfilePicturePreview = (file: File) => {
    if (!file) return;
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImagePreview(reader.result as string);
    };

    if (file.type.match("image.*")) {
      reader.readAsDataURL(file);
    } else {
      setProfileImagePreview(undefined);
    }
  };

  const handleProfilePictureChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !formikRef.current) return;

    formikRef.current.setFieldValue(
      nameof<AddEditSpeakerForm>("profilePicture"),
      e.target.files[0]
    );
    formikRef.current.setFieldTouched(
      nameof<AddEditSpeakerForm>("profilePicture"),
      true,
      true
    );
    generateProfilePicturePreview(e.target.files[0]);
  }


  const HandleTitle = (value: any) => {
    if (!value || !formikRef || !formikRef.current) return;
    formikRef.current.setFieldValue(
      nameof<AddEditSpeakerForm>("title"),
      value.label
    );

    setTitle(value)
  }
  const closeModal = () => {
    setTitle(null);
    setProfileImagePreview(undefined);
  }
  const handleFormSubmit = async (
    values: AddEditSpeakerForm | DefaultSpeakerFormValues,
  ) => {
    try {
      const _title = title;
      const { id, profilePicturePreview, ...others } = values;
      setActionInProgress(true);

      if (!values.id) {
        addNewSpeaker({
          ...others,
        } as AddSpeakerRequest).then(res => {
          toast.success(
            <ToastMessage>
              Speaker &quot;{values.title} {values.firstName}
              &quot; {values.id ? "Updated" : "Added"} successfully.
            </ToastMessage>
          );

          props.toggle();

        }).catch(err => {
          setActionInProgress(false);
          toast.error(
            <ToastMessage type="Error">
              Something went wrong while{" "}
              {values.id ? "updating" : "adding"} &quot;
              Speaker &quot;{values.title} {values.firstName}
              &quot;, please try again later.
            </ToastMessage>
          );
        }).finally(() => setActionInProgress(false))
      } else {
        await updateSpeaker({
          speakerId: values.id,
          ...others,
          // isOnHomePage: selectedEvents.includes(0)
        } as EditSpeakerRequest).then(res => {
          setActionInProgress(false);
          toast.success(
            <ToastMessage>
              Speaker &quot;{values.title} {values.firstName}
              &quot; {values.id ? "Updated" : "Added"} successfully.
            </ToastMessage>
          );
          props.toggle();
        }).catch(err => {
          setActionInProgress(false);
          toast.error(
            <ToastMessage type="Error">
              Something went wrong while{" "}
              {values.id ? "updating" : "adding"} &quot;
              Speaker &quot;{values.title} {values.firstName}
              &quot;, please try again later.
            </ToastMessage>
          );
        })
      }

    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          There were an issue {values.id ? "Updating" : "Adding"}{" "}
          the speaker
        </ToastMessage>
      );
    }
  }

  const renderSpeakerForm = (formProps: FormikProps<AddEditSpeakerForm>) => (
    <Form>
      <ModalBody>
        <FormGroup className="text-center">
          <Label>
            {profileImagePreview ? (
              <img
                src={profileImagePreview}
                width={128}
                height={128}
                className="rounded-circle border"
              />
            ) : (
              <div
                className="rounded-circle"
                style={{ width: 128, height: 128 }}
              >
                <img src={imgDefault} width={128} height={128}/>
              </div>
            )}

            <Field
              name={nameof<AddEditSpeakerForm>("profilePicture")}
              render={({ field }: FieldProps) => (
                <Input
                  type="file"
                  className="d-none"
                  {...field}
                  onChange={handleProfilePictureChange}
                  value={""}
                />
              )}
            />
          </Label>
          <ErrorMessage
            name={nameof<AddEditSpeakerForm>("profilePicture")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

        <Row form>
          <Col sm={12} md={12} xl={12}>
            <FormGroup>
              <Label>Title</Label>
              {/*<Field*/}
              {/*    name={nameof<AddEditSpeakerForm>("title")}*/}
              {/*    render={({ field }: FieldProps) => (*/}
              {/*        <Input {...field} />*/}
              {/*    )}*/}
              {/*/>*/}

              <Field name={nameof<AddEditSpeakerForm>("title")} render={({ field }: FieldProps) => (
                <Select options={optionsTitle}
                        value={title}
                        onChange={e => {
                          HandleTitle(e as any)
                        }}

                />
              )}/>
              <ErrorMessage
                name={nameof<AddEditSpeakerForm>("title")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </Col>

          <Col sm={12} md={3} xl={3}>
            <FormGroup>
              <Label>First name</Label>
              <Field
                name={nameof<AddEditSpeakerForm>("firstName")}
                render={({ field }: FieldProps) => (
                  <Input {...field} />
                )}
              />
              <ErrorMessage
                name={nameof<AddEditSpeakerForm>("firstName")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={3} xl={3}>
            <FormGroup>
              <Label>Second name</Label>
              <Field
                name={nameof<AddEditSpeakerForm>("secondName")}
                render={({ field }: FieldProps) => (
                  <Input {...field} />
                )}
              />
              <ErrorMessage
                name={nameof<AddEditSpeakerForm>("secondName")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={3} xl={3}>
            <FormGroup>
              <Label>Third name</Label>
              <Field
                name={nameof<AddEditSpeakerForm>("thirdName")}
                render={({ field }: FieldProps) => (
                  <Input {...field} />
                )}
              />
              <ErrorMessage
                name={nameof<AddEditSpeakerForm>("thirdName")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={3} xl={3}>
            <FormGroup>
              <Label>Last name</Label>
              <Field
                name={nameof<AddEditSpeakerForm>("lastName")}
                render={({ field }: FieldProps) => (
                  <Input {...field} />
                )}
              />
              <ErrorMessage
                name={nameof<AddEditSpeakerForm>("lastName")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label>Bio</Label>
          <Field
            name={nameof<AddEditSpeakerForm>("bio")}
            render={({ field }: FieldProps) => (
              <Input type="textarea" {...field} />
            )}
          />
          <ErrorMessage
            name={nameof<AddEditSpeakerForm>("bio")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

        <ContactForm
          name={nameof<AddEditSpeakerForm>("contact")}
          formikProps={formProps}
        />
        <Row form>
          <FormGroup switch>
            <Label style={{ marginRight: "5px" }}>Show on Home Page</Label>

            <Input
              type="switch"
              label={formProps.values.status}
              name={nameof<AddEditSpeakerForm>("")}
              id={"isOnHomePage"}
              onChange={e => {
                formProps.setFieldValue(
                  nameof<AddEditSpeakerForm>("isOnHomePage"),
                  e.target.checked
                );
              }}
              onBlur={formProps.handleBlur}
              checked={
                formProps.values.isOnHomePage
              }
            />
          </FormGroup>
        </Row>
      </ModalBody>

      <ModalFooter className={"space-between"}>
        <Button type="button" color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={actionInProgress}
        >
          {actionInProgress && (
            <span>
                            <FaSpinner className="icon-spin"/>
                        </span>
          )}{" "}
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
  return (
    <Modal isOpen={props.isOpen} onClosed={closeModal} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>
        {props.defaultValue && props.defaultValue.id
          ? "Edit Speaker"
          : "Add speaker"}
      </ModalHeader>

      <Formik
        innerRef={formikRef as any}
        onSubmit={handleFormSubmit}
        validationSchema={formValidation}
        initialValues={initialValues}
      >
        {renderSpeakerForm}
      </Formik>
    </Modal>
  );


};


