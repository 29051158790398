/* eslint-disable no-empty */
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import * as yup from "yup";
import emptyImg from '../../assets/imgs/no_data.svg';
import { ToastMessage } from "../../components/ToastMessages";

import { FiPlus } from "react-icons/fi";
import { IDropdownList } from "../../api/common/DropdownList";
import {
    addCondition,
    getConditions,
    getPlansAsDropdownList,
    IAddEditQuestionCondition
} from "../../api/MembershipRegistrationFormApi";

const AddEditConditionModal: React.FC<any> = (props: any) => {

    const {
        isOpen,
        toggle,
        attendeeQuestions,
        fetchRegistrationFormData,
    } = props;

    React.useEffect(() => {
        if (attendeeQuestions && attendeeQuestions.length && !attendeeQuestions.find((x: any) => x.id === 'plan')) {
            attendeeQuestions.push({ question: 'Membership Plan', id: 'plan' })
        }
    }, attendeeQuestions)
    const [initials, setIntials] = React.useState({
        id: 0,
        action: null,
        onQuestionId: null,
        onPlanId: null,
        questionLeftId: null,
        expressions: [
            {
                logicalOperator: "",
                questionLeftId: null,
                comparisonOperator: "",
                questionRightId: null,
                staticValue: "",
                onPlanId: null
            }
        ],
    });

    const initialValues = React.useMemo(() => initials, [initials]);

    const ValidatorSchema = React.useMemo(
        () =>
            yup.object().shape({
                questionLeftId: yup
                    .object()
                    .nullable()
                    .required("This field is required"),
                action: yup
                    .mixed()
                    .nullable()
                    .required("This field is required"),
                onQuestionId: yup
                    .mixed()
                    .nullable()
                    .required("This field is required")
            }),
        []
    );

    const comarisons = [
        { id: "Bigger Than", name: "Bigger Than" },
        { id: "Bigger Than Or Equal", name: "Bigger Than Or Equal" },
        { id: "Equal", name: "Equal" },
        { id: "Not Equal", name: "Not Equal" },
        { id: "Smaller Than", name: "Smaller Than" },
        { id: "Smaller Than Or Equal", name: "Smaller Than Or Equal" }
    ];

    const planConditipns = [
        { id: "Equal", name: "Equal" },
        { id: "Not Equal", name: "Not Equal" }
    ]
    const logicals = [
        { id: "And", name: "And" },
        { id: "Or", name: "Or" }
    ];

    const actions = [
        { id: "ShowAndMakeRequired", name: "Show and Required" },
        { id: "ShowOnly", name: "Show" },
        { id: "Hide", name: "Hide" }
    ];

    const formikRef = React.useRef();

    const [mode, changeMode] = React.useState("update");
    const [currentView, changeView] = React.useState("list");
    const [viewMembershipPlan, setViewMembershipPlan] = React.useState<boolean>(false);
    const [plans, setPlans] = React.useState<Array<IDropdownList>>([]);
    const [conditions, setConditions] = React.useState<Array<IAddEditQuestionCondition>>([]);

    const fetchConditionsHandler = () => {
        getConditions().then(data => {
            setConditions(data)
        })
    }

    React.useEffect(() => {
        fetchConditionsHandler();
        fetchPlans();
    }, [])




    const fetchPlans = () => {
        getPlansAsDropdownList().then(data => {
            ;
            setPlans(data);
        });
    }





    const renderForm = React.useCallback(formProps => (
        <Form className="p-3">
            <Row>
                <FieldArray name="expressions">
                    {arrayActions => (
                        <>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>If</Label>
                                    <Field
                                        render={({ field }: any) => (
                                            <Select
                                                {...field}
                                                onChange={(options: any) => {

                                                    if (options && options.id === 'plan') {
                                                        // fetchPlans();
                                                        setViewMembershipPlan(true);
                                                    } else {
                                                        setViewMembershipPlan(false);

                                                    }
                                                    formProps.setFieldValue(
                                                        "questionLeftId",
                                                        options
                                                    )

                                                }
                                                }
                                                getOptionLabel={(option: any) =>
                                                    option['question']
                                                }
                                                getOptionValue={(option: any) =>
                                                    option['id']
                                                }
                                                options={attendeeQuestions}
                                            />
                                        )}
                                        name="questionLeftId"
                                    />

                                    <ErrorMessage
                                        name="questionLeftId"
                                        render={error => (
                                            <FormFeedback className="d-block">
                                                {error}
                                            </FormFeedback>
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            {!viewMembershipPlan && formProps.values.expressions.map((item: any, index: number) => {
                                return (
                                    <>
                                        <Col xs={12} md={4}>
                                            <FormGroup className="conditionRow">
                                                {index > 0 && (
                                                    <span
                                                        onClick={() => {
                                                            arrayActions.remove(
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        X
                                                    </span>
                                                )}
                                                <Field
                                                    render={({ field }: any) => (
                                                        <Select
                                                            {...field}
                                                            onChange={options =>
                                                                formProps.setFieldValue(
                                                                    `expressions[${index}].comparisonOperator`,
                                                                    options
                                                                )
                                                            }
                                                            getOptionLabel={(option: any) =>
                                                                option.name
                                                            }
                                                            getOptionValue={(option: any) =>
                                                                option.id
                                                            }
                                                            options={viewMembershipPlan ? planConditipns : comarisons}
                                                        />
                                                    )}
                                                    name={`expressions[${index}].comparisonOperator`}
                                                />

                                                <ErrorMessage
                                                    name={`expressions[${index}].comparisonOperator`}
                                                    render={error => (
                                                        <FormFeedback className="d-block">
                                                            {error}
                                                        </FormFeedback>
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <FormGroup>

                                                {
                                                    viewMembershipPlan ?
                                                        <Field
                                                            render={({ field }: any) => (
                                                                <Select
                                                                    {...field}
                                                                    getOptionLabel={(option: any) =>
                                                                        option['name']
                                                                    }
                                                                    getOptionValue={(option: any) =>
                                                                        option['id']
                                                                    }
                                                                    options={plans}
                                                                />
                                                            )}
                                                            name="membershipPlan"
                                                        />

                                                        :
                                                        <>
                                                            <Input
                                                                onChange={
                                                                    formProps.handleChange
                                                                }
                                                                onBlur={
                                                                    formProps.handleBlur
                                                                }
                                                                value={
                                                                    formProps.values
                                                                        .expressions[index]
                                                                        .staticValue
                                                                }
                                                                name={`expressions[${index}].staticValue`}
                                                            />
                                                            <ErrorMessage
                                                                name={`expressions[${index}].staticValue`}
                                                                render={error => (
                                                                    <FormFeedback className="d-block">
                                                                        {error}
                                                                    </FormFeedback>
                                                                )}
                                                            />
                                                        </>
                                                }


                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Field
                                                    render={({ field }: any) => (
                                                        <Select
                                                            {...field}
                                                            onChange={options => {
                                                                formProps.setFieldValue(
                                                                    `expressions[${index}].logicalOperator`,
                                                                    options
                                                                );
                                                                if (
                                                                    !formProps
                                                                        .values
                                                                        .expressions[index + 1]
                                                                ) {

                                                                    arrayActions.push(
                                                                        {
                                                                            logicalOperator:
                                                                                "",
                                                                            questionLeftId: null,
                                                                            comparisonOperator:
                                                                                "",
                                                                            questionRightId: null,
                                                                            staticValue:
                                                                                ""
                                                                        }
                                                                    );

                                                                }
                                                            }}
                                                            getOptionLabel={(option: any) =>
                                                                option.name
                                                            }
                                                            getOptionValue={(option: any) =>
                                                                option.id
                                                            }
                                                            options={logicals}
                                                        />
                                                    )}
                                                    name={`expressions[${index}].logicalOperator`}
                                                />

                                                <ErrorMessage
                                                    name={`expressions[${index}].logicalOperator`}
                                                    render={error => (
                                                        <FormFeedback className="d-block">
                                                            {error}
                                                        </FormFeedback>
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </>
                                );
                            })}

                            {
                                viewMembershipPlan && formProps.values.expressions.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <Col xs={12} md={4}>
                                                <FormGroup className="conditionRow">
                                                    {index > 0 && (
                                                        <span
                                                            onClick={() => {
                                                                arrayActions.remove(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            X
                                                        </span>
                                                    )}
                                                    <Field
                                                        render={({ field }: any) => (
                                                            <Select
                                                                {...field}
                                                                onChange={options =>
                                                                    formProps.setFieldValue(
                                                                        `expressions[${index}].comparisonOperator`,
                                                                        options
                                                                    )
                                                                }
                                                                getOptionLabel={(option: any) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(option: any) =>
                                                                    option.id
                                                                }
                                                                options={viewMembershipPlan ? planConditipns : comarisons}
                                                            />
                                                        )}
                                                        name={`expressions[${index}].comparisonOperator`}
                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} md={8}>
                                                <FormGroup>
                                                    <Field
                                                        render={({ field }: any) => (
                                                            <Select
                                                                {...field}
                                                                getOptionLabel={(option: any) =>
                                                                    option['name']
                                                                }
                                                                getOptionValue={(option: any) =>
                                                                    option['id']
                                                                }

                                                                onChange={(options: any) => {

                                                                    formProps.setFieldValue(
                                                                        `onPlanId`,
                                                                        options['id']
                                                                    );
                                                                    formProps.setFieldValue(
                                                                        `expressions[${index}].onPlanId`,
                                                                        options
                                                                    );
                                                                }}
                                                                options={plans}
                                                            />
                                                        )}
                                                        name={`expressions[${index}].onPlanId`}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12}>
                                                <FormGroup>
                                                    <Field
                                                        render={({ field }: any) => (
                                                            <Select
                                                                {...field}
                                                                onChange={options => {
                                                                    formProps.setFieldValue(
                                                                        `expressions[${index}].logicalOperator`,
                                                                        options
                                                                    );
                                                                    if (
                                                                        !formProps
                                                                            .values
                                                                            .expressions[index]
                                                                    ) {

                                                                        formProps
                                                                            .values
                                                                            .expressions[index].push(
                                                                                {
                                                                                    leftQuestionId: "",
                                                                                    comparsionOperator: "",
                                                                                    staticValue: "",
                                                                                    logicalOperator: "",
                                                                                    onPlanId: null
                                                                                }
                                                                            );

                                                                    }
                                                                }}
                                                                getOptionLabel={(option: any) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(option: any) =>
                                                                    option.id
                                                                }
                                                                options={logicals}
                                                            />
                                                        )}
                                                        name={`expressions[${index}].logicalOperator`}
                                                    />

                                                    <ErrorMessage
                                                        name={`expressions[${index}].logicalOperator`}
                                                        render={error => (
                                                            <FormFeedback className="d-block">
                                                                {error}
                                                            </FormFeedback>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            {
                                                item.questions && item.questions.map((question: any, i: number) => {
                                                    return (
                                                        <>
                                                            <Col xs={12} md={12}>
                                                                <FormGroup>
                                                                    <Label>If</Label>
                                                                    <Field
                                                                        render={({ field }: any) => (
                                                                            <Select
                                                                                {...field}
                                                                                onChange={(options: any) => {



                                                                                    formProps.setFieldValue(
                                                                                        `membershipExpressions[${index}].questions[${i}].leftQuestionId`,
                                                                                        options
                                                                                    )
                                                                                }
                                                                                }
                                                                                getOptionLabel={(option: any) =>
                                                                                    option['question']
                                                                                }
                                                                                getOptionValue={(option: any) =>
                                                                                    option['id']
                                                                                }
                                                                                options={attendeeQuestions.filter((a: any) => a.id !== 'plan')}
                                                                            />
                                                                        )}
                                                                        name={`membershipExpressions[${index}].questions[${i}].leftQuestionId`}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xs={12} md={4}>
                                                                <FormGroup>
                                                                    <Field
                                                                        render={({ field }: any) => (
                                                                            <Select
                                                                                {...field}
                                                                                onChange={options =>
                                                                                    formProps.setFieldValue(
                                                                                        `membershipExpressions[${index}].questions[${i}].comparsionOperator`,
                                                                                        options
                                                                                    )
                                                                                }
                                                                                getOptionLabel={(option: any) =>
                                                                                    option.name
                                                                                }
                                                                                getOptionValue={(option: any) =>
                                                                                    option.id
                                                                                }
                                                                                options={comarisons}
                                                                            />
                                                                        )}
                                                                        name={`membershipExpressions[${index}].questions[${i}].comparsionOperator`}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xs={12} md={8}>
                                                                <>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        value={
                                                                            formProps.values
                                                                                .membershipExpressions[index].questions[i]
                                                                                .staticValue
                                                                        }
                                                                        name={`membershipExpressions[${index}].questions[${i}].staticValue`}
                                                                    />
                                                                </>
                                                            </Col>

                                                            <Col xs={12} md={12}>
                                                                <FormGroup>
                                                                    <Field
                                                                        render={({ field }: any) => (
                                                                            <Select
                                                                                {...field}
                                                                                onChange={options => {
                                                                                    formProps.setFieldValue(
                                                                                        `membershipExpressions[${index}].questions[${i}].logicalOperator`,
                                                                                        options
                                                                                    );
                                                                                    if (
                                                                                        !formProps
                                                                                            .values
                                                                                            .membershipExpressions[index].questions[index + 1]
                                                                                    ) {

                                                                                        formProps
                                                                                            .values
                                                                                            .membershipExpressions[index].questions.push(
                                                                                                {
                                                                                                    leftQuestionId: "",
                                                                                                    comparsionOperator: "",
                                                                                                    staticValue: "",
                                                                                                    logicalOperator: ""
                                                                                                }
                                                                                            );

                                                                                    }
                                                                                }}
                                                                                getOptionLabel={(option: any) =>
                                                                                    option.name
                                                                                }
                                                                                getOptionValue={(option: any) =>
                                                                                    option.id
                                                                                }
                                                                                options={logicals}
                                                                            />
                                                                        )}
                                                                        name={`membershipExpressions[${index}].questions[${i}].logicalOperator`}
                                                                    />


                                                                </FormGroup>
                                                            </Col>
                                                        </>


                                                    );
                                                })
                                            }

                                        </>
                                    );
                                })
                            }
                        </>
                    )}
                </FieldArray>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>Then</Label>
                        <Field
                            render={({ field }: any) => (
                                <Select
                                    {...field}
                                    onChange={options =>
                                        formProps.setFieldValue(
                                            "action",
                                            options
                                        )
                                    }
                                    getOptionLabel={(option: any) => option.name}
                                    getOptionValue={(option: any) => option.id}
                                    options={actions}
                                />
                            )}
                            name="action"
                        />

                        <ErrorMessage
                            name="action"
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <Label>On</Label>
                        <Field
                            render={({ field }: any) => (
                                <Select
                                    {...field}
                                    onChange={options =>
                                        formProps.setFieldValue(
                                            "onQuestionId",
                                            options
                                        )
                                    }
                                    getOptionLabel={(option: any) => option.question}
                                    getOptionValue={(option: any) => option.id}
                                    options={attendeeQuestions.filter((q: any) => q.id !== 'plan')}
                                />
                            )}
                            name="onQuestionId"
                        />

                        <ErrorMessage
                            name="onQuestionId"
                            render={error => (
                                <FormFeedback className="d-block">
                                    {error}
                                </FormFeedback>
                            )}
                        />
                    </FormGroup>
                </Col>

                <Col xs={12}>
                    <div className="space-between mt-4">
                        <Button onClick={() => changeView("list")}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            {mode == "create" ? "Add" : "Update"} Condition
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    ), [viewMembershipPlan, plans]);

    const onEditCondition = async (data: any) => {
        changeMode("update");
        const newData = {
            ...data,
            questionLeftId: attendeeQuestions.find(
                (q: any) => q.id == data.expressions[0].questionLeftId
            ),
            onQuestionId: attendeeQuestions.find(
                (q: any) => q.id == data.onQuestionId
            ),
            action: actions.find(action => action.id == data.action),
            expressions: data.expressions.map((item: any) => {
                return {
                    logicalOperator: logicals.find(
                        i => i.id === item.logicalOperator
                    ),
                    questionLeftId: attendeeQuestions.find(
                        (i: any) => i.id === item.questionLeftId
                    ),
                    comparisonOperator: comarisons.find(
                        (i: any) => i.id === item.comparisonOperator
                    ),
                    questionRightId: null,
                    staticValue: item.staticValue
                };
            })
        };
        setIntials(newData);
        changeView("");
    };

    const onChangeToCreate = async () => {
        changeView("");
        changeMode("create");
        setIntials({
            id: 0,
            action: null,
            onQuestionId: null,
            questionLeftId: null,
            onPlanId: null,
            expressions: [
                {
                    logicalOperator: "",
                    questionLeftId: null,
                    comparisonOperator: "",
                    questionRightId: null,
                    staticValue: "",
                    onPlanId: null
                }
            ],
        });
    };

    const onDeleteCondition = async (data: any) => {
        try {
            toast.success(
                <ToastMessage>Condition deleted successfully.</ToastMessage>
            );
            fetchRegistrationFormData();
        } catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Something went wrong, Please try again later.
                </ToastMessage>
            );
        } finally {
        }
    };

    const handleSubmit = React.useCallback(
        async values => {
            try {

                const payload: IAddEditQuestionCondition = {
                    action: values.action.id,
                    onQuestionId: values.onQuestionId.id || null,
                    onPlanId: values.onPlanId,
                    conditionId: mode == "update" ? initials.id : undefined,
                    expressions: values.expressions.map((item: any) => {
                        return {
                            logicalOperator: item.logicalOperator.id || "And",
                            questionLeftId: values.questionLeftId.id == "plan" ? null : values.questionLeftId.id,
                            comparisonOperator: item.comparisonOperator.id,
                            questionRightId: values.onQuestionId.id,
                            staticValue: item.staticValue,
                            planLeftId: item.onPlanId?.id ?? null
                        };
                    })
                };

                ;
                const id = await addCondition(payload);
                toast.success(
                    <ToastMessage>
                        Condition {mode == "create" ? "Added" : "Updated"}{" "}
                        successfully.
                    </ToastMessage>
                );
                changeView("list");
                fetchRegistrationFormData();
            } catch (e) {
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong, Please try again later.
                    </ToastMessage>
                );
            } finally {
            }
        },
        [mode, initials.id, fetchRegistrationFormData]
    );

    return (
        <div>
            <Modal className="Modal" isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {" "}
                    {currentView === "create"
                        ? "Add"
                        : currentView === "update"
                            ? "Update"
                            : ""}{" "}
                    {"Condition"}
                </ModalHeader>
                <ModalBody>
                    {currentView != "list" && (
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            validationSchema={ValidatorSchema}
                            render={renderForm}
                        />
                    )}

                    <>
                        {currentView == "list" && (
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <div
                                            className={'text-center mb-4'}
                                            style={{
                                                // float: "right",
                                                padding: "10px 0"
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    onChangeToCreate()
                                                }
                                                className="btn btn-primary"
                                            >
                                                <span className={'mr-2'}>
                                                    <FiPlus />
                                                </span>
                                                Add Condition
                                            </button>
                                        </div>
                                    </Col>
                                </Row>

                                {conditions &&
                                    conditions.length > 0 ?
                                    conditions.map((item: any, index: number) => {
                                        return (
                                            <Row key={index}>
                                                <Col
                                                    xs={5}
                                                    className="conditionData"
                                                >
                                                    <span>
                                                        {
                                                            attendeeQuestions.find(
                                                                (q: any) =>
                                                                    q.id ==
                                                                    item
                                                                        .expressions[index]
                                                                        .questionLeftId
                                                            ) && attendeeQuestions.find(
                                                                (q: any) =>
                                                                    q.id ==
                                                                    item
                                                                        .expressions[index]
                                                                        .questionLeftId
                                                            ).question
                                                        }
                                                        {
                                                            plans && plans.find(
                                                                (q: any) =>
                                                                    q.id ==
                                                                    item
                                                                        .expressions[index]
                                                                        .planLeftId
                                                            ) ? plans?.find(
                                                                (q: any) =>
                                                                    q.id ==
                                                                    item
                                                                        .expressions[index]
                                                                        .planLeftId
                                                            )?.name ?? null : null
                                                        }
                                                    </span>
                                                </Col>
                                                <Col
                                                    xs={5}
                                                    className="conditionData"
                                                >
                                                    <span>
                                                        {
                                                            attendeeQuestions.find(
                                                                (q: any) =>
                                                                    q.id ==
                                                                    item.onQuestionId
                                                            ).question
                                                        }
                                                    </span>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="modalIcons">
                                                        <FaTrash
                                                            onClick={() =>
                                                                onDeleteCondition(
                                                                    item
                                                                )
                                                            }
                                                            className="modalIcon"
                                                        ></FaTrash>
                                                        <FaEdit
                                                            onClick={() =>
                                                                onEditCondition(
                                                                    item
                                                                )
                                                            }
                                                            className="modalIcon"
                                                        ></FaEdit>
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                    : <div className={'text-center mt-4'}>
                                        <img src={emptyImg} width={"80%"} alt={'no data'} />
                                        <p className={'text-center'}> No Conditions </p>
                                    </div>
                                }
                            </div>
                        )}
                    </>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddEditConditionModal;
