import {
    NewAccountSubscriptionRequest,
    startNewAccountSubscription
} from "api/SubscriptionsApi";
import { UserInfoClaims, getUserInfoClaims } from "api/userInfo";
import blankProfileImage from "assets/imgs/blank-profile-picture.png";
import { Urls } from "components/Constants";
import { MainCard } from "components/MainCard";
import { ToastMessage } from "components/ToastMessages";
import {
    ErrorMessage,
    Field,
    FieldProps,
    Form,
    Formik,
    FormikProps
} from "formik";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import {
    Button,
    CardBody,
    Container,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label
} from "reactstrap";
import { nameof } from "util/nameof";
import * as yup from "yup";
import "./EmsSignUp.scss";

export const EmsSignUp: React.FC = props => {
    const [userClaims, setUserClaims] = useState<UserInfoClaims | null>(null);


    useEffect(() => {
        let tryAgainTimeout: NodeJS.Timeout | null = null;

        function loadUserInfoClaims() {
            getUserInfoClaims()
                .then(r => setUserClaims(r))
                .catch(
                    _ =>
                        (tryAgainTimeout = setTimeout(loadUserInfoClaims, 3000))
                );
        }

        loadUserInfoClaims();
        return function cleanUp() {
            tryAgainTimeout && clearTimeout(tryAgainTimeout);
        };
    }, []);

    const handleSwitchAccount = useCallback(() => {
        (document.getElementById("signoutForm") as HTMLFormElement).submit();
    }, []);

    const initialValue: NewAccountSubscriptionRequest = {
        accessPathUrl: "",
        name:""
    };
    const validationSchema = yup.object().shape<NewAccountSubscriptionRequest>({
        accessPathUrl: yup.string().required("Default url is required."),
        name: yup.string().required("Name is required.")

    });
    const handleFormSubmit = useCallback(
        (
            values: NewAccountSubscriptionRequest,
        ) => {
            startNewAccountSubscription(values)
                .then(_ => {
                    toast.success(
                        <ToastMessage>
                            Registration completed succesfully
                        </ToastMessage>
                    );

                    window.location.href = "/";
                })
                .catch(_ => {
                    toast.success(
                        <ToastMessage>
                            Something went wrong while registring, please try
                            again later
                        </ToastMessage>
                    );
                })
        },
        []
    );
    const renderForm = (
        formProps: FormikProps<NewAccountSubscriptionRequest>
    ) => {
        return (
            <Form>
                <CardBody>
                    <FormGroup>
                        <div className="d-flex justify-content-between w-100">
                            <div className="media">
                                <img
                                    src={
                                        blankProfileImage
                                        // (userClaims?.picture ??
                                        //     blankProfileImage) as any
                                    }
                                    alt="Logged in avatar"
                                    width={48}
                                    height={48}
                                    className="bg-light border rounded-circle mr-2"
                                />
                                <div className="media-body">
                                    <h5 className="my-0">{userClaims?.name}</h5>
                                    {userClaims?.email}
                                </div>
                            </div>
                            <div className="media">
                            <Button
                                type="button"
                                color="link"
                                onClick={handleSwitchAccount}
                            >
                                Switch account
                            </Button>
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label>Access url</Label>

                        <InputGroup>
                            <InputGroupText>
                                {Urls.ACCESS_PAGE_DEFAULT_URL}/
                            </InputGroupText>
                            <Field
                                name={nameof<NewAccountSubscriptionRequest>(
                                    "accessPathUrl"
                                )}>
                                {({ field }: FieldProps) => (
                                    <Input {...field} autoComplete="off" />
                                )}
                            </Field>

                            <ErrorMessage
                                name={nameof<NewAccountSubscriptionRequest>(
                                    "accessPathUrl"
                                )}
                                render={error => (
                                    <FormFeedback className="d-block">
                                        {error}
                                    </FormFeedback>
                                )}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Name</Label>

                        <InputGroup>
                            <Field
                                name={nameof<NewAccountSubscriptionRequest>(
                                    "name"
                                )}>
                                {({ field }: FieldProps) => (
                                    <Input {...field} autoComplete="off" />
                                )}
                            </Field>

                            <ErrorMessage
                                name={nameof<NewAccountSubscriptionRequest>(
                                    "name"
                                )}
                                render={error => (
                                    <FormFeedback className="d-block">
                                        {error}
                                    </FormFeedback>
                                )}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <p>
                            By registring in the service you accept{" "}
                            <a href="#">Terms of conditions</a> and{" "}
                            <a href="#">Privacy policy</a>
                        </p>
                    </FormGroup>
                    <FormGroup>
                        <Button
                            color="primary"
                            disabled={formProps.isSubmitting}
                        >
                            {formProps.isSubmitting && (
                                <span>
                                    <FaSpinner className="icon-spin" />
                                </span>
                            )}{" "}
                            Start my free trial
                        </Button>
                    </FormGroup>
                </CardBody>
            </Form>
        );
    };
    return (
        <div className="fill-screen bg-signup">
            <Container>
                <div className="d-flex justify-content-center pt-5">
                    <MainCard>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValue}
                            validationSchema={validationSchema}
                        >
                            {renderForm}
                        </Formik>
                    </MainCard>
                </div>
            </Container>
        </div>
    );
};
