import _ from "lodash";
import PropTypes from "prop-types";
import * as React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationFooterPropsType = {
    pageItemsCount: PropTypes.number.isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    maximumPages: PropTypes.number,
    onPageClicked: PropTypes.func
};

type PaginationFooterProps = PropTypes.InferProps<
    typeof PaginationFooterPropsType
>;

export const PaginationFooter: React.FC<PaginationFooterProps> = props => {
    const offset = (props.pageIndex - 1) * props.pageSize;
    const lastPage = Math.ceil(props.totalItemsCount / props.pageSize);
    const renderPageButton = (page: number) => (
        <PaginationItem active={props.pageIndex === page}>
            <PaginationLink
                onClick={() => props.onPageClicked && props.onPageClicked(page)}
            >
                {page}
            </PaginationLink>
        </PaginationItem>
    );
    return (
        <div className="d-flex justify-content-between py-3 px-4">
            <div>
                <Pagination aria-label="Page navigation">
                    <PaginationItem disabled={props.pageIndex <= 1}>
                        <PaginationLink
                            previous
                            onClick={() =>
                                props.onPageClicked &&
                                props.onPageClicked(props.pageIndex - 1)
                            }
                        />
                    </PaginationItem>
                    {renderPageButton(1)}
                    {props.pageIndex - props.maximumPages! > 1 && (
                        <PaginationItem>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    )}

                    {_.range(
                        props.pageIndex - props.maximumPages!,
                        props.pageIndex + props.maximumPages!
                    )
                        .filter(page => page > 1 && page <= lastPage)
                        .map(page => renderPageButton(page))}

                    {props.pageIndex + props.maximumPages! <= lastPage - 1 && (
                        <PaginationItem>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    )}

                    {props.pageIndex + props.maximumPages! <= lastPage &&
                        renderPageButton(lastPage)}

                    <PaginationItem disabled={lastPage <= props.pageIndex}>
                        <PaginationLink
                            next
                            onClick={() =>
                                props.onPageClicked &&
                                props.onPageClicked(props.pageIndex + 1)
                            }
                        />
                    </PaginationItem>
                </Pagination>
            </div>
            <div>
                Showing {offset + 1} to {offset + props.pageItemsCount} of{" "}
                {props.totalItemsCount} Rows
            </div>
        </div>
    );
};

PaginationFooter.propTypes = PaginationFooterPropsType;
PaginationFooter.defaultProps = {
    maximumPages: 3,
    onPageClicked: () => { }
};
