/* eslint-disable react/no-unescaped-entities */
import {
  AddMembershipAreaStyle,
  EditMembershipAreaStyle,
  GetMembershipAreaStyle,
  IMembershipAreaStyle,
} from "api/MembershipAreaApi";
import { Loader } from "components/Loader";
import { ToastMessage } from "components/ToastMessages";
import * as React from "react";
import { SketchPicker } from "react-color";
import Helmet from "react-helmet";
import { toast } from "react-toastify";
import {
  CardBody, CardFooter,
  CardHeader,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  UncontrolledPopover
} from "reactstrap";
import DefaultImg from "../../assets/imgs/default.png";
import { MainCard } from "../../components/MainCard";
export class ManageRegistrationFormStyle extends React.Component<any, any> {
  pCInputRef: React.RefObject<any>;
  sCInputRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      data: {
        id: 0,
        primaryColor: "#fff",
        secondaryColor: "#fff",
      } as IMembershipAreaStyle,
      darkLogo: DefaultImg,
      lightLogo: DefaultImg,
      banner: DefaultImg,
      favIcon: DefaultImg,
      smallLogo: DefaultImg,
    };
    this.pCInputRef = React.createRef<any>();
    this.sCInputRef = React.createRef<any>();
  }

  generateImagePreview = (
    file: File,
    setter: (value: string | null) => any
  ) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setter(reader.result as string);
    };

    if (file.type.match("image.*")) {
      reader.readAsDataURL(file);
    } else {
      setter(null);
    }
  };

  componentDidMount() {
    this.fetchMembershipAreaStyle();
  }

  // fetch the style.
  fetchMembershipAreaStyle = () => {
    this.setState({ loading: true });
    GetMembershipAreaStyle().then(res => {
      this.setState({ loading: false });
      if (res) {
        if (this.pCInputRef.current) {
          this.pCInputRef.current.value = res.primaryColor;
        }
        if (this.sCInputRef.current) {
          this.sCInputRef.current.value = res.secondaryColor;
        }

        const data: IMembershipAreaStyle = {
          id: res.id,
          primaryColor: res.primaryColor,
          secondaryColor: res.secondaryColor,
        };
        const darkLogo = res.darkLogo?.fullUrl ?? DefaultImg;
        const lightLogo = res.lightLogo?.fullUrl ?? DefaultImg;
        const banner = res.banner?.fullUrl ?? DefaultImg;
        const favIcon = res.favIcon?.fullUrl ?? DefaultImg;
        const smallLogo = res.smallLogo?.fullUrl ?? DefaultImg;
        this.setState({ data, darkLogo, lightLogo, banner, favIcon, smallLogo });
      }
    }).catch(err=>{
      this.setState({ loading: false });
    });
  };

  onSubmitHandler = () => {
    this.setState({ loading: true });
    if (this.state.data.id > 0) {
      // edit
      this.onEditHandler();
    } else {
      //add
      this.onAddHandler();
    }
  };

  onAddHandler = () => {
    AddMembershipAreaStyle(this.state.data as IMembershipAreaStyle).then(
      _ => {
        toast.success(
          <ToastMessage>Add Membership Style Successfully.</ToastMessage>
        );
        this.setState({ loading: false });
      },
      error => {
        toast.error(
          <ToastMessage type="Error">
            Something went wrong while Add Membership Style, Please try
            again later.
          </ToastMessage>
        );
        this.setState({ loading: false });
      }
    );
  };

  onEditHandler = () => {
    EditMembershipAreaStyle(
      this.state.data.id,
      this.state.data as IMembershipAreaStyle
    ).then(
      _ => {
        toast.success(
          <ToastMessage>Update Membership Style Successfully.</ToastMessage>
        );
        this.setState({ loading: false });
      },
      error => {
        toast.error(
          <ToastMessage type="Error">
            Something went wrong while Update Membership Style, Please try
            again later.
          </ToastMessage>
        );
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <div>

        <Helmet title="Membership Style" />
        <div>
          <MainCard>
            <CardHeader className="d-flex justify-content-between">
              <div>Membership's Colors</div>
            </CardHeader>
            <CardBody>
              {this.state.loading ? <Loader isLoading={true} /> : null}
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label> Primary Color </label>
                    <InputGroup>
                      <InputGroup addonType="prepend">
                        <InputGroupText>
                          <div
                            className="px-3 h-100 border"
                            id={"primaryColor"}
                            style={{
                              backgroundColor: this.state.data.primaryColor,
                            }}
                          ></div>
                        </InputGroupText>
                      </InputGroup>

                      <Input
                        id={"primaryColor"}
                        ref={this.pCInputRef}
                        value={this.pCInputRef.current?.value ?? null}
                      />
                    </InputGroup>
                    <UncontrolledPopover
                      target={"primaryColor"}
                      trigger="legacy"
                      placement="bottom-start"
                    >
                      <SketchPicker
                        color={"#000"}
                        onChange={e => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              primaryColor: e?.hex ?? "#fff",
                            },
                          });
                          this.pCInputRef.current.value = e.hex ?? "#fff";
                        }}
                      />
                    </UncontrolledPopover>
                  </div>

                  <div className="form-group">
                    <label> Secondary Color </label>
                    <InputGroup>
                      <InputGroup addonType="prepend">
                        <InputGroupText>
                          <div
                            id="secondaryColor"
                            className="px-3 h-100 border"
                            style={{
                              backgroundColor: this.state.data.secondaryColor,
                            }}
                          ></div>
                        </InputGroupText>
                      </InputGroup>

                      <Input
                        id={"secondaryColor"}
                        ref={this.sCInputRef}
                        value={this.sCInputRef.current?.value ?? null}
                      />
                    </InputGroup>
                    <UncontrolledPopover
                      target={"secondaryColor"}
                      trigger="legacy"
                      placement="bottom-start"
                    >
                      <SketchPicker
                        color={"#000"}
                        onChange={e => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              secondaryColor: e?.hex ?? "#fff",
                            },
                          });

                          this.sCInputRef.current.value = e.hex ?? "#fff";
                        }}
                      />
                    </UncontrolledPopover>
                  </div>
                </div>
              </div>
            </CardBody>
          </MainCard>
        </div>

        {/* Logos */}
        <div className="mt-4">
          <MainCard>
            <CardHeader className="d-flex justify-content-between">
              <div>Membership's Logos</div>
            </CardHeader>
            <CardBody>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <h5> Banner </h5>
                    <Label htmlFor="banner" className="lightLabel">
                      {this.state.banner && (
                        <div className="p-1">
                          <img
                            src={this.state.banner}
                            height={128}
                            className="thumbnail"
                          />
                        </div>
                      )}
                    </Label>
                    <Input
                      type="file"
                      id="banner"
                      style={{ display: "none" }}
                      onChange={e => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              banner: e.target?.files
                                ? e.target?.files[0]
                                : null,
                            },
                          },
                          () => {
                            ;
                          }
                        );

                        e.target.files &&
                          this.generateImagePreview(
                            e.target.files[0],
                            (val: string | null) => {
                              this.setState({ banner: val });
                            }
                          );
                      }}
                      value={
                        this.state.data.banner &&
                        this.state.data.banner.value &&
                        this.state.data.banner.value.filename
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <FormGroup>
                    <h5> Dark logo </h5>
                    <Label htmlFor="darkLogo" className="lightLabel">
                      {this.state.darkLogo && (
                        <div className="p-1">
                          <img
                            src={this.state.darkLogo}
                            height={128}
                            className="thumbnail"
                          />
                        </div>
                      )}
                    </Label>
                    <Input
                      type="file"
                      id="darkLogo"
                      style={{ display: "none" }}
                      onChange={e => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              darkLogo: e.target?.files
                                ? e.target?.files[0]
                                : null,
                            },
                          },
                          () => {
                            ;
                          }
                        );

                        e.target.files &&
                          this.generateImagePreview(
                            e.target.files[0],
                            (val: string | null) => {
                              this.setState({ darkLogo: val });
                            }
                          );
                      }}
                      value={
                        this.state.data.darkLogo &&
                        this.state.data.darkLogo.value &&
                        this.state.data.darkLogo.value.filename
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <FormGroup>
                    <h5>Light logo </h5>
                    <Label htmlFor="lightLogo" className="lightLabel">
                      {this.state.darkLogo && (
                        <div className="p-1">
                          <img
                            src={this.state.lightLogo}
                            height={128}
                            className="thumbnail"
                          />
                        </div>
                      )}
                    </Label>
                    <Input
                      type="file"
                      id="lightLogo"
                      style={{ display: "none" }}
                      onChange={e => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              lightLogo: e.target?.files
                                ? e.target?.files[0]
                                : null,
                            },
                          },
                          () => {
                            ;
                          }
                        );

                        e.target.files &&
                          this.generateImagePreview(
                            e.target.files[0],
                            (val: string | null) => {
                              this.setState({ lightLogo: val });
                            }
                          );
                      }}
                      value={
                        this.state.data.lightLogo &&
                        this.state.data.lightLogo.value &&
                        this.state.data.lightLogo.value.filename
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <FormGroup>
                    <h5>Small logo </h5>
                    <Label htmlFor="smallLogo" className="lightLabel">
                      {this.state.darkLogo && (
                        <div className="p-1">
                          <img
                            src={this.state.smallLogo}
                            height={128}
                            className="thumbnail"
                          />
                        </div>
                      )}
                    </Label>
                    <Input
                      type="file"
                      id="smallLogo"
                      style={{ display: "none" }}
                      onChange={e => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              smallLogo: e.target?.files
                                ? e.target?.files[0]
                                : null,
                            },
                          },
                          () => {
                            ;
                          }
                        );

                        e.target.files &&
                          this.generateImagePreview(
                            e.target.files[0],
                            (val: string | null) => {
                              this.setState({ smallLogo: val });
                            }
                          );
                      }}
                      value={
                        this.state.data.smallLogo &&
                        this.state.data.smallLogo.value &&
                        this.state.data.smallLogo.value.filename
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-4">
                  <FormGroup>
                    <h5>Fav Icon </h5>
                    <Label htmlFor="favIcon" className="lightLabel">
                      {this.state.darkLogo && (
                        <div className="p-1">
                          <img
                            src={this.state.favIcon}
                            height={128}
                            className="thumbnail"
                          />
                        </div>
                      )}
                    </Label>
                    <Input
                      type="file"
                      id="favIcon"
                      style={{ display: "none" }}
                      onChange={e => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              favIcon: e.target?.files
                                ? e.target?.files[0]
                                : null,
                            },
                          },
                          () => {
                            ;
                          }
                        );

                        e.target.files &&
                          this.generateImagePreview(
                            e.target.files[0],
                            (val: string | null) => {
                              this.setState({ favIcon: val });
                            }
                          );
                      }}
                      value={
                        this.state.data.favIcon &&
                        this.state.data.favIcon.value &&
                        this.state.data.favIcons.value.filename
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            </CardBody>
            <CardFooter>
                <button onClick={this.onSubmitHandler} className="btn btn-success float-end">
                  {" "}
                  Save{" "}
                </button>
            </CardFooter>
          </MainCard>


        </div>
      </div>
    );
  }
}
/*

            <Row>
              <div className={"col-sm-12"}>
                <h5> Membership Area </h5>
              </div>
              <Col>
                <FormGroup>
                  <Label> Primary Color </Label>
                  <Field
                    name={nameof<UpdateEventPageRequest>(
                      "membershipPrimaryColor"
                    )}
                    render={({field}: FieldProps) => (
                      <>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <div
                                className="px-3 h-100 border"
                                style={{
                                  backgroundColor: field.value,
                                }}
                              ></div>
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input id={"membershipPrimaryColor"} {...field} />
                        </InputGroup>
                      </>
                    )}
                  />

                  <UncontrolledPopover
                    target={"membershipPrimaryColor"}
                    trigger="legacy"
                    placement="bottom-start"
                  >
                    <SketchPicker
                      color={formProps.values.membershipPrimaryColor ?? ""}
                      onChange={e =>
                        formProps.setFieldValue(
                          nameof<UpdateEventPageRequest>(
                            "membershipPrimaryColor"
                          ),
                          e?.hex ?? null
                        )
                      }
                    />
                  </UncontrolledPopover>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label> Secondary Color </Label>
                  <Field
                    name={nameof<UpdateEventPageRequest>(
                      "membershipSecondaryColor"
                    )}
                    render={({field}: FieldProps) => (
                      <>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <div
                                className="px-3 h-100 border"
                                style={{
                                  backgroundColor: field.value,
                                }}
                              ></div>
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input id={"membershipSecondaryColor"} {...field} />
                        </InputGroup>
                      </>
                    )}
                  />

                  <UncontrolledPopover
                    target={"membershipSecondaryColor"}
                    trigger="legacy"
                    placement="bottom-start"
                  >
                    <SketchPicker
                      color={formProps.values.membershipSecondaryColor ?? ""}
                      onChange={e =>
                        formProps.setFieldValue(
                          nameof<UpdateEventPageRequest>(
                            "membershipSecondaryColor"
                          ),
                          e?.hex ?? null
                        )
                      }
                    />
                  </UncontrolledPopover>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col size={4}>
                <FormGroup>
                  <Label htmlFor="membershipLogo" className="lightLabel">
                    Membership Area logo
                    {membershipAreaLogo && (
                      <div className="p-1">
                        <img
                          src={membershipAreaLogo}
                          height={128}
                          className="thumbnail"
                        />
                      </div>
                    )}
                  </Label>
                  <Field
                    render={({field}: FieldProps) => (
                      <Input
                        {...field}
                        type="file"
                        id="membershipLogo"
                        style={{display: "none"}}
                        onChange={e => {
                          formProps.setFieldValue(
                            nameof<UpdateEventPageRequest>("membershipLogo"),
                            e.target.files && e.target.files[0]
                          );
                          e.target.files &&
                            generateImagePreview(
                              e.target.files[0],
                              setMembershipAreaLogo
                            );
                        }}
                        value={field.value && field.value.filename}
                      />
                    )}
                    name={nameof<UpdateEventPageRequest>("membershipLogo")}
                  />
                  <ErrorMessage
                    name={nameof<UpdateEventPageRequest>("membershipLogo")}
                    render={error => (
                      <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>

*/
