/* eslint-disable react/prop-types */
import {
  deleteRecepientListItem,
  getCSVFile,
  getRecipientList,
  getRecipientListDropdownList,
  search
} from "api/RecipientLists";
import { EmptyListState } from "components/EmptyListState";
import { TableFullNameRender } from "components/TableFullNameRenderer";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import {FaEdit, FaFileExcel, FaPlus, FaSearch, FaTrash} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {Button, ButtonGroup, CardBody, CardHeader, Col, InputGroup, Row} from "reactstrap";
import { DefaultPageSize } from "../../components/Constants";
import { DataTable } from "../../components/DataTable";
import { MainCard } from "../../components/MainCard";
import { ToastMessage } from "../../components/ToastMessages";
import { AddRecepientModal } from "./AddRecepientModal";
export const EventRecepients = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = Number(params.eventId);

  const [addEditModalIsOpen, setAddEditModalIsOpen] = useState(false);

  const handleCloseAddEditModal = useCallback(
    () => setAddEditModalIsOpen(false),
    [setAddEditModalIsOpen]
  );

  const componentRef = React.useRef();
  const [initialFormValues, setInitialFormValues] = useState({});

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);

  const [recepientId, setRecepientId] = useState({});
  const [searchKey , setSearchKey] = useState(null);
  const [recipientListResult, setResults] = React.useState({});
  const handleAddClicked = useCallback(() => {
    setInitialFormValues(undefined);
    setAddEditModalIsOpen(true);
  }, [setAddEditModalIsOpen]);

  const handleEditClicked = (committee) => {
    const {
      id,
      firstName,
      lastName,
      middleName,
      phoneNumber,
      email,
      recipientList,
    } = committee;
    setInitialFormValues({
      id,
      firstName,
      lastName,
      middleName,
      phoneNumber,
      email,
      recepientId: {
        id: recipientList.id,
        name: recipientList.name,
      },
    });
    setAddEditModalIsOpen(true);
  };

  const [recepients, setRecepientsGroups] = useState([]);
  const [recepiantGroupId ,setRecepiantGroupId ] = useState(0);
  const getGroups = async (eventId = null , recepiantGroupId = null) => {
    const  x  = recepiantGroupId;
    let data = await getRecipientListDropdownList();
    if (data && Array.isArray(data)) {
    
      data.push({id:0 , name:'All'})
      data = data.reverse();
      setRecepientsGroups(data);
    
      if (data && data.length){
        if(recepiantGroupId && recepiantGroupId > 0) {
          setRecepientId(data.find(i => i.id === recepiantGroupId));

        }else {
          setRecepientId(data[0]);

        }
      }
      // console.log(res, recepientId);
    }
  };

  useEffect(() => {
    const recepiantGroupId =  +new URLSearchParams(props.location?.search).get("recepientsGroup");
    getGroups(eventId , recepiantGroupId);
  }, []);

  const getRecipients = async () => {
    const res = await getRecipientList(recepientId?.id ?? 0);
    if (res && res.items) {
      setResults(res);
    }
  };
  
  
  useEffect(() => {
    getRecipients();
  }, [pageIndex, pageSize, recepientId.id]);

  const deleteRecepient = async (id) => {
    try {
      await deleteRecepientListItem(id);
      toast.success(
        <ToastMessage>Committee was deleted successfully.</ToastMessage>
      );
      await getRecipients(recepientId.id);
    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while deleting committee &quot; please try again
          later
        </ToastMessage>
      );
    } finally {
    }
  };

  
  const searchHandler = async () => {
      let key = searchKey;
      if(!key)
        key = ''; 
       const res = await  search(eventId , key , recepientId.id);
       if(res && res.items) {
         setResults(res)
       }

  }
  
  
  const exportCSV = async () => {
    try {
      const res = await getCSVFile(recepientId.id, recepientId.name);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePageChange = useCallback(
    (page) => {
      if (page !== pageIndex) {
        setPageIndex(page);
        setTimeout(() => {
          if (componentRef.current) {
            componentRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }
        }, 150);
      }
    },
    [pageIndex, componentRef]
  );

  const columns = React.useMemo(
    () => [
        {
          Header: "Full name",
          Cell: TableFullNameRender,
          canSort :true,
          toggleSortBy : () => {
            alert('hello Sorted')
          }
        },
        {
          Header: "Email",
          accessor: "email",
          canSort :true
        },
        {
          Header: "Phone Number",
          accessor: "phoneNumber",
          canSort :true
        },
        {
          Header: "Group name",
          accessor: "recipientList.name",
          canSort :true

        },
      {
        Header: "Actions",
        Cell: function renderCommitteeActions(ctx) {
          const committee = ctx.row.original;
          if (!committee) return null;
          return (
            <div>
                <Button
                  size="sm"
                  color="primary"
                  outline={true}
                  onClick={(_) => handleEditClicked(committee)}
                >
                  <FaEdit />
                </Button>{" "}
                <Button
                  size="sm"
                  color="danger"
                  outline={true}
                  onClick={() => {
                    deleteRecepient(committee.id);
                  }}
                >
                  <FaTrash />
                </Button>
            </div>
          );
        },
      },
    ],
    [recepientId, recipientListResult]
  );

  return (
    <div ref={componentRef}>
      <Helmet title="Recepients" />
      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <span>
              Recepients{" "}
            </span>
            <div>
              <Button onClick={handleAddClicked} color="primary">
                Add new Recepient <FaPlus/>
              </Button>
              <Button className="mx-2" color={'success'} style={{ cursor: "pointer" , fontSize:'16px' }} onClick={exportCSV}>
                <span>Export </span>  <FaFileExcel />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row className={"mb-3"}>
            <Col md={"6"}>
              <InputGroup>
                <Select
                    name="recepientId"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={recepientId}
                    options={recepients}
                    onChange={(option) => {
                      setRecepientId(option);
                      getRecipientList(option.id);
                    }}
                    placeholder="Select a group name"
                />
                <input placeholder="search"  onChange={e => {
                  setSearchKey(e.target.value);
                }} style={{borderTopRightRadius:'0',borderBottomRightRadius:'0' , outline:'none'}} className="form-control"/>
                <Button onClick={searchHandler} color="success" >
                    Search {" "}<FaSearch />
                </Button>
              </InputGroup>

            </Col>
          </Row>


          <DataTable
            columns={columns}
            defaultCanSort={true}
            disableSortBy={false}
            canReorderRows={true}
            sort={true}
            data={recipientListResult?.items ? recipientListResult.items : []}
            isBusy={false}
            emptyState={
              <EmptyListState
                title={"You have no recipents yet"}
                callToActionButton={{
                  text: "Add your first recipient now!",
                  onClick: handleAddClicked,
                }}
              />
            }
            pagination={{
              show: true,
              totalItemsCount:
                recipientListResult && recipientListResult.meta
                  ? recipientListResult.meta.totalItemsCount
                  : 0,
              pageIndex: pageIndex,
              pageSize: pageSize,
              onPageClicked: handlePageChange,
            }}
          />
        </CardBody>
      </MainCard>
      {
        addEditModalIsOpen ?
        <AddRecepientModal
        eventId={eventId}
        recepientId={recepientId && recepientId.id ? recepientId : {}}
        isOpen={true}
        closeModal={handleCloseAddEditModal}
        defaultValues={initialFormValues}
        getRecipients={() => {
          getRecipients();
          getGroups();
        }}
      />
         : null
      }
     
    </div>
  );
};
