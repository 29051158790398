import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useMatch } from "react-router";
import { animated, useSpring } from "react-spring";
import { Collapse } from "reactstrap";
import "./DropDownSideNavMenu.scss";
import { SideNavItemProps } from "./SideNavItem";
import { SideNavItem } from "./index";

interface DropDownSideNavMenuProps {
    title: React.ReactNode;
    children: React.ReactNode;
    to: string;
}

export const DropDownSideNavMenu: React.FC<
    DropDownSideNavMenuProps & SideNavItemProps
> = (props) => {
    const match = useMatch(props.to);

    const isActive = !!match;

    const [isOpen, setIsOpen] = useState(false);
    const iconAnimation = useSpring({
        transform: `rotate(${isOpen ? 0 : -90}deg)`,
    });

    useEffect(() => {
        if (isActive) {
            setIsOpen(true);
        }
    }, [isActive]);

    return (
        <div
            className={`side-nav--dropdown__container ${isActive ? "active" : ""}`}
        >
            <SideNavItem
                {...props}
                exact={false}
                trailingIcon={
                    <animated.div
                        style={{
                            ...iconAnimation,
                            transformOrigin: "100% 50%",
                        }}
                    >
                        <FaChevronDown />
                    </animated.div>
                }
                classNames="side-nav--dropdown"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen((o) => !o);
                }}
            >
                {props.title}
            </SideNavItem>
            <Collapse isOpen={isOpen}>
                <ul className="side-nav-item__items">{props.children}</ul>
            </Collapse>
        </div>
    );
};

DropDownSideNavMenu.propTypes = {
    children: PropTypes.node.isRequired,
};
