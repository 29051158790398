import Axios from "axios";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface IMeeting {
    index: number;
    meetingTitle: string;
    eventName: string;
    startDate: Date,
    endDate: Date,
    meetingLink: string,
    meetingState:any,
    id:number
}


export const getMeetings = async (request : PaginatedRequest) : Promise<PaginatedResult<IMeeting>>  => {
    const result = await Axios.get<PaginatedResult<IMeeting>>("api/Meeting" , {
        params: request
    });
    return result.data;
}