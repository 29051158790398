/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Button, CardBody, CardHeader } from "reactstrap";
import { getTax, ITax } from "../../api/TaxApi";
import { Loader } from "../../components/Loader";
import { MainCard } from "../../components/MainCard";
// @ts-ignore
import TaxIco from '../../assets/imgs/calculator.svg';
import AddTaxModal from "./AddTaxModal";
import DeleteTaxModal from "./DeleteTaxModal";
import EditTaxModal from "./EditTaxModal";
import { PaginationFooter } from "../../components/PaginationFooter";

const ManageTaxes = () => {


  const [data, setData] = useState<ITax[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [itemEditable, setItemEditable] = useState<ITax | null>(null);
  const [itemDeletable, setItemDeletable] = useState<ITax | null>(null);
  const [page,setPage] = useState(1);
  const [count,setCount] = useState(0);
  const [maximumPerPage]=useState(20);


  useEffect(() => {
    fetchData();
  }, [page])


  const fetchData = () => {
    setLoading(true)
    getTax({ limit: maximumPerPage, pageIndex: page }).then(data => {
      setLoading(false)
      setData(data.items)
      setCount((data as any).totalItemsCount)
      ;

    }).catch(error => {
      setLoading(false)
    })
  }

  const closeAddModal = () => {
    setOpenAddModal(false)
  }
    const closeEditModal = () => {
        setOpenEditModal(false)
        setItemEditable(null)
    }


  const showEditModal = (item: ITax) => {
    setItemEditable({
      id: item.id,
      name: item.name,
      shortName: item.shortName,
      value: item.value,
      isActive: item.isActive,
      isPercentage: item.isPercentage
    })

    setOpenEditModal(true)
  }


  const cancelDeleteHandler = () => {
    setItemDeletable(null)
  }


  const deleteHandler = (item: ITax) => {
    setItemDeletable(item)
  }


  return (
    <div>
      <Helmet title="Taxes"/>
      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>Taxes</div>
          <div>
            <Button onClick={e => {
              setItemEditable(null)
              setOpenAddModal(true)
            }} color={'primary'}>
              <FaPlus/> Add Tax
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {
            data.length > 0 && !loading ?

              <table className={"table table-response"}>
                <thead className="thead-dark">
                <tr>
                  <td>
                    #
                  </td>
                  <td>
                    Name
                  </td>
                  <td>
                    Value
                  </td>
                  <td>
                    Type
                  </td>
                  <td>
                    Status
                  </td>
                  <td>
                    Actions
                  </td>
                </tr>
                </thead>
                <tbody>
                {
                  data.map((item: ITax, index: number) => {
                    return (
                      <>
                        <tr>
                          <td>
                            {index + 1}
                          </td>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {
                              item.value
                            }
                          </td>
                          <td>
                            {
                              item.isPercentage ? "%" : "Fixed value"
                            }
                          </td>
                          <td style={{ color: item.isActive ? 'green' : 'red' }}>
                            {
                              item.isActive ? "Active" : "Deactivated"
                            }
                          </td>
                          <td>
                            <Button
                              size={"sm"}
                              color={"primary"}
                              outline={true}
                              onClick={e => {
                                e.preventDefault();
                                showEditModal(item);
                              }}
                            >
                              <FaEdit/>
                            </Button>{" "}
                            {!item.isActive &&
                            <Button
                                size={"sm"}
                                color={"danger"}
                                outline={true}
                                onClick={e => {
                                  e.preventDefault();
                                  deleteHandler(item);
                                }}
                            >
                                <FaTrashAlt/>
                            </Button>}
                          </td>
                        </tr>
                      </>

                    );
                  })
                }
                </tbody>
                <tfoot>
                <tr>
                  <td colSpan={6}>
                    <PaginationFooter
                      onPageClicked={setPage}
                      pageIndex={page}
                      totalItemsCount={count}
                      pageSize={maximumPerPage}
                      pageItemsCount={data.length}
                    />
                  </td>
                </tr>
                </tfoot>
              </table>
              :
              loading ?
                <div className={'text-center'}>
                  <img src={TaxIco} style={{ opacity: '0.65' }} alt={'img'} width={'350px'}/>
                  <br/>
                  <div className={"text-center mt-4"}>
                    <strong> Oops, No data yet ! </strong>
                  </div>
                </div> : null
          }

          {
            loading ?
              <Loader isLoading={true}/>
              :
              null
          }
        </CardBody>
      </MainCard>

      <AddTaxModal onUpdated={fetchData} name={null} id={null} isOpen={openAddModal} close={closeAddModal}/>

        {itemEditable && <EditTaxModal item={itemEditable} onUpdated={fetchData} name={null} id={null} isOpen={openEditModal}
                       close={closeEditModal}/>}

      {
        itemDeletable ? <DeleteTaxModal cancelDeleting={cancelDeleteHandler} taxToDelete={itemDeletable}
                                        onUpdated={fetchData}/> : null
      }
    </div>
  );

}

export default ManageTaxes;