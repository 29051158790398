import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Abstract,
    AbstractsListForEventRequest,
    getAbstractsForEvent
} from "../api/Abstracts";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { AppThunk } from "./index";

export interface EventAbstractsListState {
    isBusy: boolean;
    eventId: number | null;
    abstractsResult: PaginatedResult<Abstract> | null;
    error: string | null;
}

const initialState: EventAbstractsListState = {
    error: null,
    isBusy: false,
    abstractsResult: null,
    eventId: null
};

interface ReceiveEventAbstractsListAction {
    error: string | null;
    abstractsResult: PaginatedResult<Abstract> | null;
    eventId: number | null;
}

const EventAbstractsList = createSlice({
    name: "EventAbstractsList",
    initialState,
    reducers: {
        requestEventAbstractsList: (state, action: PayloadAction) => {
            state.isBusy = true;
        },
        receiveEventAbstractsList: (
            state,
            action: PayloadAction<ReceiveEventAbstractsListAction>
        ) => {
            state.error = action.payload.error;
            state.eventId = action.payload.eventId;
            state.isBusy = false;
            state.abstractsResult = action.payload.abstractsResult;
        }
    }
});

export default EventAbstractsList.reducer;

const {
    requestEventAbstractsList,
    receiveEventAbstractsList
} = EventAbstractsList.actions;

export const loadEventAbstractsList = (
    request: AbstractsListForEventRequest
): AppThunk => async dispatch => {
    try {
        dispatch(requestEventAbstractsList());
        const result = await getAbstractsForEvent(request);
        dispatch(
            receiveEventAbstractsList({
                eventId: request.eventId,
                error: null,
                abstractsResult: result
            })
        );
    } catch (e) {
        dispatch(
            receiveEventAbstractsList({
                error: "e.toString()",
                abstractsResult: null,
                eventId: request.eventId
            })
        );
    }
};

export const reloadCurrentAbstracts = (): AppThunk => (dispatch, getState) => {
    const abstractsState = getState().eventAbstractsList;

    if (abstractsState.abstractsResult && abstractsState.eventId) {
        dispatch(
            loadEventAbstractsList({
                pageIndex: abstractsState.abstractsResult.meta.pageIndex,
                limit: abstractsState.abstractsResult.meta.pageSize,
                eventId: abstractsState.eventId
            })
        );
    }
};
