import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { CardBody, CardHeader } from "reactstrap";
import {
    editRolePermission,
    getRolePermissions,
    IEditRolePermission,
    IRolePermission
} from "../../api/RolePermissionApi";
import { getRole, IRole } from "../../api/rolesApi";
import { Loader } from "../../components/Loader";
import { MainCard } from "../../components/MainCard";
import {useParams} from "react-router";
import {ManageEventParams} from "../ManageEventParams";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";
import ManagePermissions from "./ManagePermissions";

interface RolePermissionState {
    data?: IRole | null,
    __loading__: boolean,
    permissions: Array<IRolePermission>
}

const ManageRolePermissions: React.FC<any> = (props) => {
    const params = useParams<any>();
    const [state, setState] = useState<RolePermissionState>({
        data: null,
        __loading__: false,
        permissions: []
    });

    useEffect(() => {
        // Get Role From Url;

        const roleId = Number(params.id);
        if (!roleId || typeof roleId !== "number")
            alert("Must Send Role Id In Url");

        // Get Role Details
        setState({ ...state, __loading__: true });



        getRole(roleId)
            .then(data => {
                setState({ ...state, __loading__: false });
                if (data) {
                    // getRolePermissions
                    getRolePermissions(roleId)
                        .then(permissions => {
                            setState({ ...state, data, permissions: permissions, __loading__: false });
                        });
                    // setState({ ...state, role });
                }
            })
            .catch(error => {
                setState({ ...state, __loading__: false });
            });

    }, []);

    const onChangeHandler = (id: number, modal: IEditRolePermission) => {
        editRolePermission(id, modal)
            .then(data => {
                getRolePermissions(state.data?.id ? state.data?.id : 0)
                    .then(data => {
                        setState({ ...state, permissions: data });
                    });
            })
            .catch(error => {
                toast.error(<ToastMessage type="Error">
                    Something went wrong while editing permission
                    Please try again later.
                </ToastMessage>)
            });
    }
    return (
        <div>
            <Helmet title={"Role Permissions"} />
            <MainCard>
                <CardHeader> Role </CardHeader>
                <CardBody>
                    <div>
                        {state.__loading__ ? (
                            <Loader isLoading={true} />
                        ) : (
                            <span>
                                <span> Role Name : </span>{" "}
                                <strong>
                                    {state.data && state.data.name}
                                </strong>
                            </span>
                        )}
                    </div>
                </CardBody>
            </MainCard>
            <div className={'mt-4'}>
                <MainCard>
                    <CardHeader> Permissions </CardHeader>
                    <CardBody>
                        <table className={'table table-md-responsive'}>
                            <thead>
                            <tr style={{ fontWeight: 600 }}>
                                <td>
                                    Module Name
                                </td>
                                <td>
                                    Read
                                </td>
                                <td>
                                    Add
                                </td>
                                <td>
                                    Edit
                                </td>
                                <td>
                                    Delete
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {state.permissions && state.permissions.length > 0 && state.permissions.map((item: IRolePermission) => (
                                <tr key={item.id}>
                                    <td>
                                        {item.moduleName}
                                    </td>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                onChange={e => {
                                                    e.preventDefault();
                                                    onChangeHandler(item.id, {
                                                        canRead: e.target.checked,
                                                        canAdd: item.canAdd,
                                                        canEdit: item.canEdit,
                                                        canDelete: item.canDelete
                                                    });
                                                }}
                                                className="form-check-input" type="checkbox" checked={item.canRead} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                onChange={e => {
                                                    e.preventDefault();
                                                    onChangeHandler(item.id, {
                                                        canRead: item.canRead,
                                                        canAdd: e.target.checked,
                                                        canEdit: item.canEdit,
                                                        canDelete: item.canDelete
                                                    });
                                                }}
                                                className="form-check-input" type="checkbox" checked={item.canAdd} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                onChange={e => {
                                                    e.preventDefault();
                                                    onChangeHandler(item.id, {
                                                        canRead: item.canRead,
                                                        canAdd: item.canAdd,
                                                        canEdit: e.target.checked,
                                                        canDelete: item.canDelete
                                                    });
                                                }}
                                                className="form-check-input" type="checkbox" checked={item.canEdit} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                onChange={e => {
                                                    e.preventDefault();
                                                    onChangeHandler(item.id, {
                                                        canRead: item.canRead,
                                                        canAdd: item.canAdd,
                                                        canEdit: item.canEdit,
                                                        canDelete: e.target.checked
                                                    });
                                                }}
                                                className="form-check-input" type="checkbox" checked={item.canDelete} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </CardBody>
                </MainCard>
            </div>
        </div>
    );
};

export default ManageRolePermissions;