import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ICreateEditRoleModal, IRole, createRole, editRole } from "../../api/rolesApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";
import ManagePermissions from "../ManageRolePermissions/ManagePermissions";
import {useNavigate} from "react-router-dom";

export enum ModalStatus {
    __CREATE__, __EDIT__
}

export interface IAddEditRoleProps {
    id?: number | null;
    name?: string | null;
    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
    modalStatus: ModalStatus;
    item?: IRole | null;
    history: any;
}

export interface IAddEditRoleState {
    __data__: IRole;
    __formValid__: boolean;
    __loading__: boolean;
}

const AddEditRole: React.FC<IAddEditRoleProps> = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState<IRole>({ id: 0, name: '' });
    const [formValid, setFormValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.item) {
            setData(props.item);
            console.table(data);
        }
    }, [props.item]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let val: string | boolean = e.target.value;
        let updatedData: IRole = { ...data, [name]: val };
        setData(updatedData);
        ;
        validationHandler();
    };

    const validationHandler = () => {
        if (data.name && data.name.length > 0) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };

    const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        if (props.modalStatus === ModalStatus.__CREATE__) {
            const modal: ICreateEditRoleModal = {
                name: data.name
            };

            createRole(modal)
                .then((res: any) => {
                    setLoading(false);




                    if (res && res.roleId) {
                        navigate(`/role-permission/${res.roleId}`);
                    }

                    if (props.onUpdated) {
                        props.onUpdated();
                    }

                    toast.success(
                        <ToastMessage type="Success">
                            Role Of Name {data.name} add Successfully;
                        </ToastMessage>
                    );

                    if (props.close) {
                        props.close();
                    }
                })
                .catch(error => {
                    toast.error(
                        <ToastMessage type="Error">
                            &quot;  {data.name}  or {data.name} &quot; is Exist Before
                        </ToastMessage>
                    );
                    setLoading(false);
                });
        } else {
            const modal: ICreateEditRoleModal = {
                name: data.name
            };

            editRole(data.id, modal)
                .then(r => {
                    setLoading(false);

                    if (props.onUpdated) {
                        props.onUpdated();
                    }

                    if (props.close) {
                        props.close();
                    }

                    toast.success(
                        <ToastMessage type="Success">
                            Updated Successfully !
                        </ToastMessage>
                    );
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(
                        <ToastMessage type="Error">
                            Error has been happened !
                        </ToastMessage>
                    );
                });
        }
    };

    return (
        <Modal size={"lg"} unmountOnClose isOpen={props.isOpen} toggle={props.close}>
            <ModalHeader toggle={props.close}>
                {props.modalStatus === ModalStatus.__EDIT__ ? " Edit Role" : "Add Role"}
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label> Name </label>
                    <input
                        value={data.name}
                        name="name"
                        type="text"
                        className="form-control"
                        onChange={changeHandler}
                    />
                </div>


                <ManagePermissions permissions={[]} rolePermissionEdited={()=>{}} modeAdd={true}/>

            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary" onClick={props.close}>
                    Cancel
                </button>
                <button onClick={submitHandler} className="btn btn-primary" disabled={!formValid}>
                    Save
                </button>
                {loading ? <Loader isLoading={true} /> : null}
            </ModalFooter>
        </Modal>
    );
};

export default AddEditRole;