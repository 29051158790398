import Axios from "axios";
import {currencyRequest} from "./CurrencyApi";
import {PaginatedRequest} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface currentRequest extends  PaginatedRequest{
    
}

export  interface ITax {
    id:number;
    name:string;
    shortName:string;
    rate?:number;
    value: any;
    isPercentage: boolean,
    isActive: boolean;
}




export const getTax = async (request: currencyRequest ) : Promise<PaginatedResult<ITax>> => {
    const result = await  Axios.get<PaginatedResult<ITax>>("api/Tax" , {
        params: request
    });
    return result.data;
}


export const addTax = async (request: ITax) : Promise<any> => {
    const result = await Axios.post<any>(
        "/api/Tax",
        request
    );

    return result.data;
}


export const editTax = async (id: number , request: ITax) : Promise<any> => {
    const  result = await Axios.put<any>(`/api/Tax/${id}`, request);
    return result.data ;
}


export  const  deleteTax = async (id: number) :Promise<any> => {
    const result = await Axios.delete<any>(`/api/Tax/${id}`) ;
    return result.data;
}

export const getTaxesDropdownList = async () : Promise<Array<ITax>> => {
    const result = await Axios.get<Array<ITax>>('api/Tax/GetDropdownList');
    return result.data;
}