import React, {useState} from "react";
import {GoogleMap, Libraries, Marker, useLoadScript} from "@react-google-maps/api";
import PlacesAutocomplete from "./PlacesAutocomplete";
import {DefaultGeo} from "../Constants";


export default function MapContainer({setSelectedPosition, points}: any) {
    const [ libraries ] = useState<Libraries>(['places']);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBSDa_SFD1l2kT9pKOmSFudN50CYysI5Tk",
        libraries
    });

    const [selected, setSelected] = useState(null);

    const handleSelected = (position: any) => {
        setSelected(position);

        setSelectedPosition(position);
    }

    if (!isLoaded) return <div>Loading...</div>;
    return <Map selected={selected} setSelected={handleSelected} points={points}/>;
}

function Map({selected, setSelected, points}: any) {
    const center = { lat: points && points.latitude ? points.latitude : DefaultGeo.latitude,
                    lng: points && points.longitude ? points.longitude : DefaultGeo.longitude }

    return (
        <>
            <div className="places-container mt-3">
                <PlacesAutocomplete setSelected={setSelected} />
            </div>

            <GoogleMap
                zoom={10}
                center={selected ? selected : center}
                mapContainerStyle={{
                    width: '100%',
                    height: '400px',
                    marginTop: '15px'
                }}
                mapContainerClassName="map-container"
            >
                {points && <Marker
                    position={center}
                />}
                {selected &&
                <Marker
                    draggable
                    position={selected}
                />}
            </GoogleMap>
        </>
    );
}