import emptyStateImg from "assets/imgs/empty_state.png";
import * as React from "react";
import { Button } from "reactstrap";

export interface EmptyListStateProps {
    image?: string;
    title?: string;
    message?: React.ReactNode;
    callToActionButton?: {
        text: string;
        onClick: () => any;
    };
}

export const EmptyListState: React.FC<EmptyListStateProps> = props => {
    return (
        <div className="text-center p-5">
            <img src={props.image} width={256} />

            <div className="text-muted">
                <span className="h4 mt-1">{props.title}</span>
                {props.message && <p className="mt-1">{props.message}</p>}
                {props.callToActionButton && (
                    <div>
                        <Button
                            color="link"
                            onClick={props.callToActionButton?.onClick}
                        >
                            {props.callToActionButton?.text}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

EmptyListState.defaultProps = {
    image: emptyStateImg,
    title: "No data yet"
};
