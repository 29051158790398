import {ErrorMessage, Field, FieldArray, FieldArrayRenderProps, FieldProps, Form, Formik, FormikProps} from "formik";
import * as React from "react";
import {useCallback, useState} from "react";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import Helmet from "react-helmet";
import {FaChevronDown, FaChevronUp, FaGripVertical, FaPlus, FaSpinner, FaTrash} from "react-icons/fa";
import {
    Button,
    CardBody,
    CardFooter,
    CardHeader,
    Collapse,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Table
} from "reactstrap";
import {
    AddMembershipRegistrationForm,
    getMembershipRegistrationForm,
    MembershipAreaQuestion,
    MembershipRegistrationForm
} from "../../api/MembershipRegistrationFormApi";
import {QuestionOption, QuestionType} from "../../api/RegistrationFormApi";
import CheckboxInput from "../../assets/imgs/checkbox-input.svg";
import ListInputIco from "../../assets/imgs/list-input.svg";
import ParagraphIco from "../../assets/imgs/paragraph.svg";
import TextInputIco from "../../assets/imgs/pen.svg";
import RadioButtonIco from "../../assets/imgs/radio-button.svg";
import {Loader, LoaderContainer} from "../../components/Loader";
import {MainCard} from "../../components/MainCard";
import {nameof} from "../../util/nameof";
import {QuestionOptionFormItem} from "../ManageRegistrationForm/ManageRegistrationForm";
import QuestionTypeCardComponent from "../ManageRegistrationForm/Question-Type-Card.component";
import AddEditConditionModal from "./AddEditConditionModal";
import {toast} from "react-toastify";
import {ToastMessage} from "../../components/ToastMessages";


const getFormItemPathName = (
    formItemName: keyof MembershipAreaQuestion,
    index: number
) =>
    `${nameof<MembershipRegistrationForm>(
        "membershipAreaQuestions"
    )}[${index}].${formItemName}`;

const getOptionItemPathName = (
    optionItemName: keyof QuestionOptionFormItem,
    questionIndex: number,
    optionIndex: number
) =>
    `${getFormItemPathName(
        "questionOptions",
        questionIndex
    )}[${optionIndex}].${optionItemName}`;
const hasOptions = (q: QuestionType) =>
    q === QuestionType.CheckBoxesList ||
    q === QuestionType.DropDown ||
    q === QuestionType.RadioButtonList;
export const RegistrationFormMembershipManage: React.FC = props => {
    // loading

    const [loading, setLoading] = React.useState<boolean>(false);
    const [registrationForm, setRegistrationForm] = React.useState<MembershipRegistrationForm | null>(null);
    const [questionsOpenState, setQuestionOpenState] = useState<{ [key: string]: { isOpen: boolean, firstOpen: boolean } }>({})


    // Reorder questions

    const handlerReorderQuestion = useCallback(
        (result: DropResult, provided: ResponderProvided) => {

            if (
                result.reason === "DROP" &&
                result.source.index !== result.destination?.index &&
                typeof result.destination?.index === "number"
            ) {
                const value = {
                    currentIndex: result.source.index,
                    newIndex: result.destination.index
                }
                ;
            }
        }, []
    )



    // render form question

    const renderFormQuestions = React.useCallback(
        (
            formProps: FormikProps<MembershipRegistrationForm>,
            arrayHelpers: FieldArrayRenderProps
        ) => {
            return (
                <div>
                    <ListGroup>

                        <DragDropContext onDragEnd={handlerReorderQuestion}>
                            <Droppable droppableId={`questionGroupReorder`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}

                                        className="accordion">

                                        {formProps.values.membershipAreaQuestions.map((q, index) => (
                                            <Draggable
                                                key={"questionGroup" + (q.id != 0 && q.id != null ? q.id : index)}
                                                draggableId={
                                                    "questionGroupDrag" + (q.id != 0 && q.id != null ? q.id : index)
                                                }
                                                index={q.order ?? index}
                                            >
                                                {
                                                    (provided, snapshot) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef as any
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="card"
                                                        >


                                                            <ListGroupItem style={{ border: 'none' }} key={q.id ?? index}>

                                                                <CardHeader>
                                                                    <div className="d-flex">
                                                                        <span
                                                                            className={'mr-4'}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <FaGripVertical
                                                                                className="text-muted"
                                                                                size="12"
                                                                            />
                                                                        </span>

                                                                        <span
                                                                            id={"question" + index}
                                                                            className="clickable"
                                                                            onClick={() => {

                                                                                setQuestionOpenState(
                                                                                    old => {
                                                                                        return {
                                                                                            ...old,
                                                                                            ["question" + (q.id != null && q.id > 0 ? q.id : index)]: {
                                                                                                isOpen: !old["question" + (q.id != null && q.id > 0 ? q.id : index)]?.isOpen ?? true,
                                                                                                firstOpen: true
                                                                                            }
                                                                                        };
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            {
                                                                                !questionsOpenState["question" + (q.id != null && q.id > 0 ? q.id : index)]?.isOpen ? <FaChevronDown /> : <FaChevronUp />
                                                                            }{" "}
                                                                        </span>

                                                                        <span className={'mx-2'}>
                                                                            {q.question}
                                                                        </span>
                                                                    </div>
                                                                </CardHeader>
                                                                {/* questionsOpenState["question"+(q.id != null && q.id > 0 ? q.id : index)]?.isOpen */}
                                                                <Collapse isOpen={true}>
                                                                    <div>
                                                                        <FormGroup>
                                                                            <Label> Question Type</Label>
                                                                            <Field
                                                                                name={getFormItemPathName("type", index)}
                                                                                render={({ field }: FieldProps) => (
                                                                                    <Input type="select" {...field} disabled={true}>
                                                                                        {Object.keys(QuestionType).map(
                                                                                            qt => (
                                                                                                <option
                                                                                                    key={qt}
                                                                                                    value={qt}
                                                                                                    selected={
                                                                                                        field.value === qt
                                                                                                    }
                                                                                                >
                                                                                                    {qt}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </Input>
                                                                                )}
                                                                            />

                                                                            <ErrorMessage
                                                                                name={getFormItemPathName("type", index)}
                                                                                render={error => (
                                                                                    <FormFeedback className="d-block">
                                                                                        {error}
                                                                                    </FormFeedback>
                                                                                )}
                                                                            />
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Label>Question</Label>
                                                                            <Field
                                                                                name={getFormItemPathName(
                                                                                    "question",
                                                                                    index
                                                                                )}
                                                                                render={({ field }: FieldProps) => (
                                                                                    <Input {...field} />
                                                                                )}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={getFormItemPathName(
                                                                                    "question",
                                                                                    index
                                                                                )}
                                                                                render={error => (
                                                                                    <FormFeedback className="d-block">
                                                                                        {error}
                                                                                    </FormFeedback>
                                                                                )}
                                                                            />
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Field
                                                                                name={getFormItemPathName(
                                                                                    "required",
                                                                                    index
                                                                                )}
                                                                                render={({ field }: FieldProps) => (
                                                                                    <FormGroup check>
                                                                                        <Label check>
                                                                                            <Input
                                                                                                type="checkbox"
                                                                                                checked={field.value}
                                                                                                {...field}
                                                                                            />{" "}
                                                                                            Is Required.
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                )}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={getFormItemPathName(
                                                                                    "required",
                                                                                    index
                                                                                )}
                                                                                render={error => (
                                                                                    <FormFeedback className="d-block">
                                                                                        {error}
                                                                                    </FormFeedback>
                                                                                )}
                                                                            />
                                                                        </FormGroup>

                                                                        {hasOptions(q.type) && (
                                                                            <FieldArray
                                                                                name={getFormItemPathName(
                                                                                    "questionOptions",
                                                                                    index
                                                                                )}
                                                                                render={qOptionsArrayHelpers => (
                                                                                    <>
                                                                                        <FormGroup>
                                                                                            <Label className="d-flex justify-content-between">
                                                                                                <div>Question Options</div>
                                                                                                <div>
                                                                                                    <Button
                                                                                                        className="btn-icon"
                                                                                                        onClick={() =>
                                                                                                            qOptionsArrayHelpers.push(
                                                                                                                {
                                                                                                                    id: 0,
                                                                                                                    name: "",
                                                                                                                } as QuestionOption
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <FaPlus /> Add
                                                                                                        Option{" "}
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Label>

                                                                                            <Table bordered>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Option Name</th>
                                                                                                        <th></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {formProps.values.membershipAreaQuestions[
                                                                                                        index
                                                                                                    ].questionOptions.map(
                                                                                                        (qo, qoIndex) => (
                                                                                                            <tr
                                                                                                                key={
                                                                                                                    qo.id ??
                                                                                                                    qoIndex
                                                                                                                }
                                                                                                            >
                                                                                                                <td className="w-100">
                                                                                                                    <FormGroup>
                                                                                                                        <Field
                                                                                                                            name={getOptionItemPathName(
                                                                                                                                "name",
                                                                                                                                index,
                                                                                                                                qoIndex
                                                                                                                            )}
                                                                                                                            render={({
                                                                                                                                field
                                                                                                                            }: FieldProps) => (
                                                                                                                                <Input
                                                                                                                                    {...field}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                        <ErrorMessage
                                                                                                                            name={getOptionItemPathName(
                                                                                                                                "name",
                                                                                                                                index,
                                                                                                                                qoIndex
                                                                                                                            )}
                                                                                                                            render={error => (
                                                                                                                                <FormFeedback className="d-block">
                                                                                                                                    {
                                                                                                                                        error
                                                                                                                                    }
                                                                                                                                </FormFeedback>
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </FormGroup>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        <Button
                                                                                                                            color="danger"
                                                                                                                            onClick={() => {

                                                                                                                                qOptionsArrayHelpers.remove(
                                                                                                                                    qoIndex
                                                                                                                                )
                                                                                                                            }
                                                                                                                            }
                                                                                                                            disabled={
                                                                                                                                qoIndex ===
                                                                                                                                0
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FaTrash />
                                                                                                                        </Button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </FormGroup>
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        )}
                                                                        <FormGroup>
                                                                            <div>
                                                                                <Button
                                                                                    color="danger"
                                                                                    outline={true}
                                                                                    onClick={() => {
                                                                                        const target = arrayHelpers.form.values.membershipAreaQuestions[index];
                                                                                        ;
                                                                                        if (target.id != null && target.id > 0) {
                                                                                            // deleteQuestion(target.id, Number(params.eventId)).then(r => {
                                                                                            //     arrayHelpers.remove(index);
                                                                                            // })
                                                                                        } else {
                                                                                            arrayHelpers.remove(index)
                                                                                        }

                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <FaTrash /> Remove question{" "}
                                                                                </Button>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Collapse>

                                                            </ListGroupItem>



                                                        </div>
                                                    )
                                                }
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>


                        </DragDropContext>


                    </ListGroup>

                </div>
            );
        },
        [questionsOpenState]
    );
    const [isOpen, openCloseChange] = React.useState(false);
    const toggle = () => {
        openCloseChange(!isOpen);
    };

    // render registration for of membership area.
    const renderForm = React.useCallback(
        (formProps: FormikProps<MembershipRegistrationForm>) => (

            <Form>

                <div>
                    <MainCard>
                        <CardHeader>Registration Datails </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label>Title</Label>
                                <Field
                                    name={nameof<MembershipRegistrationForm>(
                                        "title"
                                    )}
                                    render={({ field }: FieldProps) => (
                                        <Input {...field} />
                                    )}
                                />
                                <ErrorMessage
                                    name={nameof<MembershipRegistrationForm>(
                                        "title"
                                    )}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />{" "}
                            </FormGroup>

                            <FormGroup>
                                <Label>Description</Label>
                                <Field
                                    name={nameof<MembershipRegistrationForm>(
                                        "description"
                                    )}
                                    render={({ field }: FieldProps) => (
                                        <Input {...field} type="textarea"></Input>
                                    )}
                                />
                                <ErrorMessage
                                    name={nameof<MembershipRegistrationForm>(
                                        "description"
                                    )}
                                    render={error => (
                                        <FormFeedback className="d-block">
                                            {error}
                                        </FormFeedback>
                                    )}
                                />{" "}
                            </FormGroup>
                        </CardBody>

                    </MainCard>


                    <div className="mt-4">
                        <MainCard>
                            <CardHeader>
                                <div className={'d-flex justify-content-center'}>
                                    <span className={'flex-grow-1'}>
                                        Registration Form
                                    </span>

                                    <span>
                                        <button
                                            onClick={toggle}
                                            className={'btn btn-success'}>
                                            Conditions
                                        </button>
                                    </span>

                                </div>
                            </CardHeader>
                            <CardBody>


                                <FieldArray
                                    name={nameof<MembershipRegistrationForm>(
                                        "membershipAreaQuestions"
                                    )}
                                    render={arrayHelper => {

                                        return (
                                            <div className="row">
                                                <div className="col-md-4">

                                                    <div className="row pb-4" style={{ border: '1px solid #ddd' }}>
                                                        <div className="col-sm-12 text-center mb-4" >
                                                            <strong> Custom Questions : </strong>
                                                        </div>
                                                        {/*  Questions Type :  */}

                                                        {

                                                            Object.keys(QuestionType).map(
                                                                qt => {
                                                                    switch (qt) {
                                                                        case "Text":
                                                                            return (
                                                                                <div className={'col-md-4'}>
                                                                                    <QuestionTypeCardComponent onClick={() => {

                                                                                        arrayHelper.push({
                                                                                            id: 0,
                                                                                            order: 0,
                                                                                            question: "",
                                                                                            required: true,
                                                                                            type: QuestionType.Text,
                                                                                            questionOptions: [
                                                                                                {
                                                                                                    id: 0,
                                                                                                    name: ""
                                                                                                }
                                                                                            ]
                                                                                        } as MembershipAreaQuestion)

                                                                                    }} cardIcon={TextInputIco} questionName={qt} />
                                                                                </div>
                                                                            );

                                                                        case "CheckBoxesList":
                                                                            return (
                                                                                <div className={'col-md-4'}>
                                                                                    <QuestionTypeCardComponent
                                                                                        onClick={() => {

                                                                                            arrayHelper.push({
                                                                                                id: 0,
                                                                                                order: 0,
                                                                                                question: "",
                                                                                                required: true,
                                                                                                type: QuestionType.CheckBoxesList,
                                                                                                questionOptions: [
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        name: ""
                                                                                                    }
                                                                                                ]
                                                                                            } as MembershipAreaQuestion)

                                                                                        }}
                                                                                        cardIcon={CheckboxInput} questionName={"Check Boxes List"} />
                                                                                </div>
                                                                            );
                                                                        case "RadioButtonList":
                                                                            return (
                                                                                <div className={'col-md-4'}>
                                                                                    <QuestionTypeCardComponent
                                                                                        onClick={() => {

                                                                                            arrayHelper.push({
                                                                                                id: 0,
                                                                                                question: "",
                                                                                                order: 0,
                                                                                                required: true,
                                                                                                type: QuestionType.RadioButtonList,
                                                                                                questionOptions: [
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        name: ""
                                                                                                    }
                                                                                                ]
                                                                                            } as MembershipAreaQuestion)

                                                                                        }}
                                                                                        cardIcon={RadioButtonIco} questionName={"Radio Button List"} />
                                                                                </div>
                                                                            );
                                                                        case "DropDown":
                                                                            return (
                                                                                <div className={'col-md-4 mt-4'}>
                                                                                    <QuestionTypeCardComponent
                                                                                        onClick={() => {

                                                                                            arrayHelper.push({
                                                                                                id: 0,
                                                                                                order: 0,
                                                                                                question: "",
                                                                                                required: true,
                                                                                                type: QuestionType.DropDown,
                                                                                                questionOptions: [
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        name: ""
                                                                                                    }
                                                                                                ]
                                                                                            } as MembershipAreaQuestion)

                                                                                        }}
                                                                                        cardIcon={ListInputIco} questionName={"Dropdown"} />
                                                                                </div>
                                                                            );
                                                                        case "Paragraph":
                                                                            return (
                                                                                <div className={'col-md-4 mt-4'}>
                                                                                    <QuestionTypeCardComponent
                                                                                        onClick={() => {

                                                                                            arrayHelper.push({
                                                                                                id: 0,
                                                                                                question: "",
                                                                                                order: 0,
                                                                                                required: true,
                                                                                                type: QuestionType.Paragraph,
                                                                                                questionOptions: [
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        name: ""
                                                                                                    }
                                                                                                ]
                                                                                            } as MembershipAreaQuestion)

                                                                                        }}
                                                                                        cardIcon={ParagraphIco} questionName={"Paragraph"} />
                                                                                </div>
                                                                            );
                                                                    }
                                                                }
                                                            )

                                                        }

                                                    </div>
                                                </div>
                                                <div className={'col-md-8'}>
                                                    {renderFormQuestions(formProps, arrayHelper)}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />



                            </CardBody>

                            <CardFooter>
                                <Button
                                    className={"float-end"}
                                    type="submit"
                                    color="primary"
                                    disabled={formProps.isSubmitting}
                                >
                                    {formProps.isSubmitting && (
                                        <span>
                                            <FaSpinner className="icon-spin" />
                                        </span>
                                    )}{" "}
                                    Save changes
                                </Button>{" "}
                            </CardFooter>

                        </MainCard>

                    </div>
                </div>
            </Form>

        ), [registrationForm, renderFormQuestions]
    );


    // initalize Form;

    const formInitialValue = React.useMemo<MembershipRegistrationForm>(() => {
        const initialValue: MembershipRegistrationForm = {
            description: registrationForm?.description ?? "",
            title: registrationForm?.title ?? "",
            id: registrationForm?.id ?? 0,
            membershipAreaQuestions: registrationForm?.membershipAreaQuestions ?? []
        };

        return initialValue;
    }, [registrationForm]);



    // const fetchRegistrationForm = () => {
    //     getMembershipRegistrationForm().then(res => {
    //         setRegistrationForm(res);
    //     });
    // }

    React.useEffect(() => {
        getMembershipRegistrationForm().then(res => {
            ;
            setRegistrationForm(res);
        });
    }, [setRegistrationForm, getMembershipRegistrationForm])

    const onSubmitHandler = React.useCallback(
        async (
            values: MembershipRegistrationForm,
        ) => {
            if (values.id && values.id > 0) {
                // edit
            } else {
                // add 
                AddMembershipRegistrationForm(values).then(data=>{
                    toast.success(
                        <ToastMessage>
                           Added successfully.
                        </ToastMessage>
                    );
                }).catch((err)=>{
                    toast.error(
                        <ToastMessage type="Error">
                            Something went wrong, please try again later
                        </ToastMessage>
                    );
                    }
                );
            }
        }
        , []);

    return (
        <div>
            <Helmet title="Membership Registration Form" />
            {
                isOpen && <AddEditConditionModal
                    toggle={toggle}
                    isOpen={isOpen}
                    attendeeQuestions={registrationForm?.membershipAreaQuestions ?? []}
                />
            }
            {/*<LeavePrompt*/}
            {/*    when={isDirty}*/}
            {/*    message="You have unsaved changes, are you sure you want to leave?*/}
            {/*    any unsaved changes will be lost."*/}
            {/*/>*/}

            <LoaderContainer>
                <Loader isLoading={loading} />
                {/*<CardHeader>Registration Form</CardHeader>*/}
                <fieldset disabled={loading}>
                    <Formik
                        onSubmit={onSubmitHandler}
                        initialValues={formInitialValue}
                        // validationSchema={validationSchema}
                        enableReinitialize
                        render={renderForm}
                    />
                </fieldset>
            </LoaderContainer>
        </div>
    );
} 