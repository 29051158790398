import Axios from "axios";
import { PaginatedRequest, PaginatedRequestWithFilter } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface currentRequest extends PaginatedRequest {

}


export interface ISubTenant {
    id:number,
    email:string,
    password:string;
    createdAt:any,
    expireDate: any,
    lastActiveDate : any,
    roleId:number,
    firstName:string,
    secondName:string,
    thirdName:string,
    lastName:string,
    phoneNumber:string,
    confirmPassword:string,
}

export interface ICreateSubTenantModal {
  email: string,
  password: string,
  expireDate: any,
  roleId: number,
  phoneNumber: string,
  firstName: string,
  secondName: string,
  thirdName: string,
  lastName: string,
}

export interface IEditSubTenantModal {
  email: string,
  expireDate: any,
  roleId: number,
  phoneNumber: string,
  firstName: string,
  secondName: string,
  thirdName: string,
  lastName: string,
}

export interface ICheckEmailModel {
  isValid: boolean;
}

export const getSubTenants = async (request: PaginatedRequestWithFilter): Promise<PaginatedResult<any>> => {
  const result = await Axios.get<PaginatedResult<any>>("api/SubTenant",{params:request});
  return result.data;
}

export const getSubTenantsById = async (id:string): Promise<any> => {
  const result = await Axios.get<any>(`api/SubTenant/${id}`);
  return result.data;
}

export const GetSubTenantsExportData = async (
  ): Promise<string> => {
  const result = await Axios.get("/api/SubTenant/Export");
  return result.data;
};

export const addSubTenants = async (modal: ICreateSubTenantModal): Promise<any> => {
  const result = await Axios.post("api/SubTenant", modal);
  return result.data;
}


export const editSubTenants = async (id: number, modal: IEditSubTenantModal): Promise<any> => {
  const result = await Axios.put(`api/SubTenant/${id}`, modal);
  return result.data;
}

export const deleteSubTenants = async (id: number): Promise<any> => {
  const result = await Axios.delete(`api/SubTenant/${id}`);
  return result.data;
}

//CheckEmail
export const checkEmail = async (email: string): Promise<ICheckEmailModel> => {
  const result = await Axios.get<ICheckEmailModel>(`api/SubTenant/CheckEmail?email=${email}`);
  return result.data;
}

export const uploadImage = async (userId: number, image: any): Promise<any> => {
  const formData = new FormData();
  formData.append("image",image);
  const result = await Axios.put(`/api/SubTenant/${userId}/UploadImage`, formData,{
    headers: { "Content-Type": "multipart/form-data" }
  });
  return result.data;
}