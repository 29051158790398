import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedRequest } from "api/common/PaginatedRequest";
import {
    getNotificationTemplates,
    NotificationTemplate, NotificationTemplateRequest
} from "api/NotificationTemplatesApi";
import { normalize } from "normalizr";
import { addEntities, AddEntitiesAction } from "store/Actions";
import { AppThunk } from "./index";
import {
    NormalizedPagination,
    NormalizedPaginationItem
} from "store/NormalizedPagination";
import { notificationTemplateSchema } from "store/Schemas";

export interface NotificationTemplatesState {
    [key: string]: NotificationTemplate | {};

    pagination: {
        byEventId: NormalizedPagination;
        byEventIdSelectList: NormalizedPagination;
    };
}

const initialState: NotificationTemplatesState = {
    pagination: { byEventId: {}, byEventIdSelectList: {} }
};

interface RequestFetchNotificationTemplatesByEventIdAction {
    eventId: number;
}

interface ReceiveFetchNotificationTemplatesByEventIdAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const notificationTemplateSlice = createSlice({
    name: "notificationTemplates",
    initialState,
    reducers: {
        requestFetchNotificationTemplatesByEventId(
            state,
            action: PayloadAction<
                RequestFetchNotificationTemplatesByEventIdAction
            >
        ) {
            const { eventId } = action.payload;

            state.pagination.byEventId[eventId.toString()] = {
                ...state.pagination.byEventId[eventId.toString()],
                isFetching: true
            } as NormalizedPaginationItem;
        },
        receiveFetchNotificationTemplatesByEventId(
            state,
            action: PayloadAction<
                ReceiveFetchNotificationTemplatesByEventIdAction
            >
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventId[eventId.toString()] = {
                ...state.pagination.byEventId[eventId.toString()],
                ...action.payload,
                isFetching: false
            } as NormalizedPaginationItem;
        },
        requestFetchSelectNotificationTemplate(
            state,
            action: PayloadAction<
                RequestFetchNotificationTemplatesByEventIdAction
            >
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventIdSelectList[eventId.toString()] = {
                ...state.pagination.byEventIdSelectList[eventId.toString()],
                isFetching: true
            };
        },
        receiveFetchSelectNotificationTemplate(
            state,
            action: PayloadAction<
                ReceiveFetchNotificationTemplatesByEventIdAction
            >
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventIdSelectList[eventId.toString()] = {
                ...state.pagination.byEventIdSelectList[eventId.toString()],
                ...action.payload,
                isFetching: false
            } as NormalizedPaginationItem;
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["notificationTemplates"]
            };
        })
    })
});

export default notificationTemplateSlice.reducer;

const {
    requestFetchNotificationTemplatesByEventId,
    receiveFetchNotificationTemplatesByEventId,

    requestFetchSelectNotificationTemplate,
    receiveFetchSelectNotificationTemplate
} = notificationTemplateSlice.actions;

export const fetchPaginatedNotificationTemplates = (
    eventId: number,
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchNotificationTemplatesByEventId({ eventId }));
        const result = await getNotificationTemplates(eventId, request);
        const normalizedResult = normalize(
            result.items,
            Array(notificationTemplateSchema)
        );

        dispatch(addEntities(normalizedResult.entities as any));
        dispatch(
            receiveFetchNotificationTemplatesByEventId({
                eventId,
                error: undefined,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount,
                ids: normalizedResult.result
            } as ReceiveFetchNotificationTemplatesByEventIdAction)
        );
    } catch (e) {
        dispatch(
            receiveFetchNotificationTemplatesByEventId({
                ...getState().notificationTemplates.pagination.byEventId[
                    eventId.toString()
                ],
                eventId,
                error: "e.toString()"
            } as ReceiveFetchNotificationTemplatesByEventIdAction)
        );
    }
};

export const fetchPaginatedSelectNotificationList = (
    eventId: number,
    request: NotificationTemplateRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchSelectNotificationTemplate({ eventId }));
        const result = await getNotificationTemplates(eventId, request);
        const normalizedResult = normalize(
            result.items,
            Array(notificationTemplateSchema)
        );

        dispatch(addEntities(normalizedResult.entities as any));
        dispatch(
            receiveFetchSelectNotificationTemplate({
                eventId,
                error: undefined,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount,
                ids: normalizedResult.result
            } as ReceiveFetchNotificationTemplatesByEventIdAction)
        );
    } catch (e) {
        dispatch(
            receiveFetchSelectNotificationTemplate({
                ...getState().notificationTemplates.pagination
                    .byEventIdSelectList[eventId.toString()],
                eventId,
                error: "e.toString()"
            } as ReceiveFetchNotificationTemplatesByEventIdAction)
        );
    }
};
