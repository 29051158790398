import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

interface ScrollToTopRouteProps {
  children: React.ReactNode;
}

export const ScrollToTopRoute: React.FC<ScrollToTopRouteProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return <>{props.children}</>;
};
