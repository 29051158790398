import React, { useEffect, useState } from "react";
import { getAllOrders } from "../../api/OrdersApi";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import { MainCard } from "../../components/MainCard";
import { formatSimpleDate } from "../../util/dateUtil";
import { getAllAttendencees } from "../../api/MembershipAreaSessionApi";
import { EmptyListState } from "../../components/EmptyListState";
import emptyStateSessionImage from "../../assets/imgs/empty_state.png";
import { PaginationFooter } from "../../components/PaginationFooter";
import { statusColor } from "../ManageOrders/ManageOrders.component";
import { joinNames } from "../../util/nameUtils";
import { FaEdit } from "react-icons/fa";
import { ModalStatus } from "../ManagePaymentGateways/AddEditCurrencyPaymentModal";
import AddEditSubAccounts from "../ManageSubAccounts/AddEditSubAccounts";
import { CardContent } from "@mui/material";
import { getSubTenantsById } from "../../api/SubAccountApi";

interface CustomerImage {
  fileName: string;
  contentType: string;
  fullUrl: string;
  sizeInBytes: number;
}

interface Customer {
  id: number;
  email: string;
  displayName: string;
  image: CustomerImage;
  phoneNumber?: string;

}

interface Product {
  id: number;
  name: string;
  description: string;
  checkedInDate: string;
  location: Location;
  unit: string;
  couponCode: string;
  qty: number;
  price: number;
  subTotal: number;
  discountPercentage: number;
  discountAmount: number;
  couponDiscountPercentage: number;
  couponDiscountAmount: number;
  subTotalAfterDiscount: number;
  vatAmount: number;
  otherTaxesAmount: number;
  total: number;
  media: any;
}

interface OrderDetails {
  subTotal: number;
  allowancePercentage: number;
  allowanceAmount: number;
  totalDiscounts: number;
  totalVATAmount: number;
  totalOtherTaxesAmount: number;
  total: number;
  id: number;
  client: Customer;
  status: {
    id: number;
    name: string;
  };
  createDate: string;
  updateDate: string;
  products: Product[];
  answers: any[];
  invoice: any;
  payments: any[];
}

export const TenantUserDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const customerId = params.id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [customer, setCustomer] = useState<any>(null);
  const [customerEdit,setCustomerEdit] = useState<any>(null);

  const [bookingMaxPerPage] = useState(20)
  const [bookingPage, setBookingPage] = useState(1)
  const [bookingCount, setBookingCount] = useState(0)
  const [booking, seTBooking] = useState<any[]>([])


  const [ordersMaxPerPage] = useState(20)
  const [ordersPage, setOrdersPage] = useState(1)
  const [ordersCount, setOrdersCount] = useState(0)
  const [orders, seTOrders] = useState<OrderDetails[]>([])

  const [showEdit, setShowEdit] = useState(false)

  const fetchUser = () => {
    getSubTenantsById(customerId ?? '')
      .then(setCustomer)
      .finally(() => {
        setLoading(false)
      })
  }

  const setEdit = () => {
    const customerToEdit = {...customer.fullName,
      roleId: customer.roles && customer.roles.length > 0 ? customer.roles[0].id : undefined,
      idTypeId:customer.idType ? customer.idType.id : undefined,
      email:customer.email,
      phoneNumber:customer.phoneNumber,
      scfhsid:customer.scfhsid,
      bio:customer.bio,
      zactaID:customer.zactaID,
      address:customer.address,
      isSpeakerOnHomePage:customer.isSpeakerOnHomePage,
      linkedIn:customer.linkedIn,
      facebook:customer.facebook,
      twitter:customer.twitter,
      website:customer.website,
      id:customer.id,
      image:customer.image && customer.image.fullUrl}
    setCustomerEdit(customerToEdit);
    setShowEdit(true);
  }

  useEffect(() => {
    setLoading(true);
    fetchUser();


  }, [])
  useEffect(() => {
    if (customerId)
      getAllOrders({
        limit: ordersMaxPerPage,
        pageIndex: ordersPage,
        clientId: customerId,
      }).then((data: any) => {
        seTOrders(data.items);
        setOrdersCount(data.meta.totalItemsCount);
      })
  }, [ordersPage])

  useEffect(() => {
    if (customerId)
      getAllAttendencees({
        limit: bookingMaxPerPage,
        pageIndex: bookingPage,
        clientId: customerId,
      }).then((data: any) => {
        seTBooking(data.items);
        setBookingCount(data.meta.totalItemsCount);
      })
  }, [bookingPage])


  const toggleTabs = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };


  return (
    <div>
      <Helmet title="Orders details"/>
      <MainCard>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <div>
            <h2>{joinNames(customer?.fullName?.firstName, customer?.fullName?.secondName, customer?.fullName?.thirdName, customer?.fullName?.lastName)}</h2>
            <h6>{customer?.membershipPlanName}</h6>
          </div>
          <Button className="d-flex align-items-center gap-2" size="sm" color="primary" outline={true}
                  onClick={setEdit}>Edit <FaEdit/></Button>
        </CardHeader>
        <CardBody>
          <Nav tabs>
            <NavItem style={{ flex: 2 }}>
              <NavLink
                style={{ backgroundColor: "#f3f3f3", cursor: 'pointer', color: activeTab === "1" ? "#FFF" : "#4e71bf" }}
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                <small>Customer Details</small>
              </NavLink>
            </NavItem>

            <NavItem style={{ flex: 2 }}>
              <NavLink
                style={{ backgroundColor: "#f3f3f3", cursor: 'pointer', color: activeTab === "2" ? "#FFF" : "#4e71bf" }}
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                <small>Orders</small>
              </NavLink>
            </NavItem>
            <NavItem style={{ flex: 2 }}>
              <NavLink
                style={{ backgroundColor: "#f3f3f3", cursor: 'pointer', color: activeTab === "3" ? "#FFF" : "#4e71bf" }}
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTabs("3");
                }}
              >
                <small>List of bookings</small>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="order-detail">
                <div className="details-myprofile">
                  <Card>
                    <CardBody className={'gap-2'}>
                      <CardTitle tag="h5">Customer#{customerId}</CardTitle>
                      <CardContent>
                        <CardText className='fw-bold'>Full Name: <span
                          className='fw-normal'>{joinNames(customer?.fullName?.firstName, customer?.fullName?.secondName, customer?.fullName?.thirdName, customer?.fullName?.lastName)}</span></CardText>
                        <CardText className='fw-bold'>Email: <span
                          className='fw-normal'>{customer?.email}</span></CardText>
                        <CardText className='fw-bold'>Phone: <span className='fw-normal'>{customer?.phoneNumber}</span></CardText>
                        <CardText className='fw-bold'>Bio : <span
                          className='fw-normal'>{customer?.bio}</span></CardText>
                        <CardText className='fw-bold'>SCFHS ID : <span className='fw-normal'>{customer?.scfhsid}</span></CardText>
                      </CardContent>

                    </CardBody>
                  </Card>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="invoice">
                {!orders || orders.length === 0 ?
                  <EmptyListState
                    image={emptyStateSessionImage}
                    title={`There are no orders for customer Id : ${customerId}`}
                  />
                  :

                  <table className={"table table-response"}>
                    <thead className="thead-dark">
                    <tr >
                      <td>#</td>
                      <td>Customer</td>
                      <td>Phone Number</td>
                      <td>Email</td>
                      <td>Total Payment</td>
                      <td>Status</td>
                      <td>Date</td>
                    </tr>
                    </thead>
                    <tbody>

                    {orders.map((item: any, i) => {
                      return (
                        <tr className='cursor-pointer' onClick={event => navigate(`/Orders/${item.code}`) } key={i}>
                          <td> {item.id} </td>
                          <td> {item.client?.displayName} </td>
                          <td> {item.client?.phoneNumber} </td>
                          <td> {item.client?.email} </td>
                          <td>{item.total} {" SAR"}</td>
                          <td><Badge style={{ width: '100%', height: '20px' }}
                                     color={statusColor(item.status)}>{item.status?.name}</Badge></td>
                          <td> {item.createDate ? formatSimpleDate(item.createDate) : ""} </td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colSpan={7}>
                        <PaginationFooter
                          onPageClicked={setOrdersPage}
                          pageIndex={ordersPage}
                          totalItemsCount={ordersCount}
                          pageSize={ordersMaxPerPage}
                          pageItemsCount={orders.length}
                        />
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                }

              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="invoice">
                {!booking || booking.length === 0 ?
                  <EmptyListState
                    image={emptyStateSessionImage}
                    title={`There are no booking for customer Id : ${customerId}`}
                  />
                  :

                  <table className={"table table-response"}>
                    <thead className="thead-dark">
                    <tr>
                      <td>
                        #
                      </td>
                      <td>
                        Customer
                      </td>
                      <td>
                        Product
                      </td>
                      <td>
                        Instructor
                      </td>
                      <td>
                        Location
                      </td>
                      <td>
                        Status
                      </td>
                      <td>
                        Date
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    {booking.map((item: any, key: number) => {
                      return (
                        <tr key={key}>
                          <td> {item.id} </td>
                          <td>{item.user?.displayName}</td>
                          <td> {item.product.name} </td>
                          <td>{item.instructor?.firstName + " " + item.instructor?.lastName}</td>
                          <td>{item.location?.name}</td>
                          {/*<td>{item.status?.name}</td>*/}
                          <td><Badge style={{ width: '100%', height: '20px' }}
                                     color={statusColor(item.attendanceStatus)}>{item.attendanceStatus?.name}</Badge>
                          </td>
                          <td> {formatSimpleDate(item.checkedInDate)}</td>
                          {/*<td>{item.checkedIn ? "Checked-In" : "Not Checked-In"}</td>*/}


                        </tr>
                      );
                    })
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colSpan={7}>
                        <PaginationFooter
                          onPageClicked={setBookingPage}
                          pageIndex={bookingPage}
                          totalItemsCount={bookingCount}
                          pageSize={bookingMaxPerPage}
                          pageItemsCount={booking.length}
                        />
                      </td>
                    </tr>
                    </tfoot>

                  </table>
                }

              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </MainCard>
      {customerEdit  && <AddEditSubAccounts item={customerEdit} onUpdated={fetchUser} email={null} id={null}
                           isOpen={showEdit} close={() => {
        setShowEdit(false)
        setCustomerEdit(null)
      }}
                           modalStatus={ModalStatus.__EDIT__}/>}

    </div>
  );
}