import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { AbstractType, getAbstractTypes } from "../api/AbstractTypes";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { AddEntitiesAction, addEntities } from "./Actions";
import { NormalizedPagination } from "./NormalizedPagination";
import { abstractTypesListSchema } from "./Schemas";
import { AppThunk } from "./index";

export interface AbstractTypesState {
    [key: string]: AbstractType | {};

    pagination: {
        byEventId: NormalizedPagination;
    };
}

const initialState: AbstractTypesState = {
    pagination: {
        byEventId: {}
    }
};

interface RequestAbstractTypesPaginationAction {
    eventId: number;
}

interface ReceiveAbstractTypesPaginationAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const abstractTypesListSlice = createSlice({
    name: "abstractTypes",
    initialState,
    reducers: {
        requestAbstractTypePaginatedList(
            state,
            action: PayloadAction<RequestAbstractTypesPaginationAction>
        ) {
            const { eventId } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    isFetching: true
                }
            };
        },
        receiveAbstractTypePaginatedList(
            state,
            action: PayloadAction<ReceiveAbstractTypesPaginationAction>
        ) {
            const { eventId, ...rest } = action.payload;
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...state.pagination.byEventId[eventId.toString()],
                    ...rest,
                    isFetching: false
                }
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(
        addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["abstractTypes"]
            }
        })
    })
});

export default abstractTypesListSlice.reducer;
const {
    requestAbstractTypePaginatedList,
    receiveAbstractTypePaginatedList
} = abstractTypesListSlice.actions;

export const fetchPaginatedAbstractTypesByEventId = (
    eventId: number,
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(
            requestAbstractTypePaginatedList({
                eventId
            })
        );

        const result = await getAbstractTypes(eventId, request);

        const normalizedResult = normalize(
            result.items,
            abstractTypesListSchema
        );

        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            receiveAbstractTypePaginatedList({
                eventId,
                ids: normalizedResult.result,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount
            })
        );
    } catch (e) {
        dispatch(
            receiveAbstractTypePaginatedList({
                eventId,
                error: "e.toString()",
                ids: getState().abstractTypes.pagination.byEventId[
                    eventId.toString()
                ]?.ids,
                pageIndex: getState().abstractTypes.pagination.byEventId[
                    eventId.toString()
                ]?.pageIndex,
                pageSize: getState().abstractTypes.pagination.byEventId[
                    eventId.toString()
                ]?.pageSize,
                totalItemsCount: getState().abstractTypes.pagination.byEventId[
                    eventId.toString()
                ]?.totalItemsCount
            })
        );
    }
};
