import logo from "assets/imgs/logo-w-l.svg";
import * as React from "react";

import {
  FaBoxOpen,
  FaDollarSign,
  FaFileInvoiceDollar,
  FaHashtag,
  FaHome,
  FaIdCard,
  FaList,
  FaLocationArrow,
  FaMailBulk,
  FaMicrophone,
  FaNewspaper,
  FaPager,
  FaPuzzlePiece,
  FaSms,
  FaTicketAlt,
  FaUsers
} from "react-icons/fa";
import { FiCalendar, FiClock, FiFile, FiGrid, FiSettings, FiVideo } from "react-icons/fi";
import { MdCreditCard, MdSupervisorAccount } from "react-icons/md";
import { Container, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { SideNavContext } from "../Contexts/SideNavContext";
import SideNav, { DropDownSideNavMenu, SideNavItem, SideNavItemsContainer } from "./SideNav";
import { FaMessage, FaRegCalendarDays } from "react-icons/fa6";


export const DefaultSideNav: React.FC = () => {
  return (
    <SideNavContext.Consumer>
      {({ isOpen, toggle }) => (
        <SideNav className="flex-shrink-0" isMobileOpen={isOpen}>
          <Navbar className="border-bottom border-dark text-white" dark>
            {/* TODO: Better way to handle route change navbar items */}
            <Container fluid className="navbar-expand-sm">
              <NavbarBrand>
                <img src={logo} alt="Perfect Touch's logo"/>
              </NavbarBrand>
              <NavbarToggler onClick={toggle}/>
            </Container>
          </Navbar>

          <SideNavItemsContainer>
            <SideNavItem to="/" icon={<FaHome/>}>
              Dashboard
            </SideNavItem>
            {/*<DropDownSideNavMenu*/}
            {/*  to={"/Customers"}*/}
            {/*  title={"Membership"}*/}
            {/*  icon={<FaUsers />}*/}
            {/*>*/}
            <SideNavItem exact to={`/Customers`} icon={<FaUsers/>}>
              Customers
            </SideNavItem>

            {/*<SideNavItem*/}
            {/*    exact*/}
            {/*    to={`/membership-form`}*/}
            {/*    icon={<AiOutlineForm />}*/}
            {/*>*/}
            {/*  Registration Form*/}
            {/*</SideNavItem>*/}
            {/*<SideNavItem*/}
            {/*  exact*/}
            {/*  to={`/MembershipArea/Style`}*/}
            {/*  icon={<FaPaintBrush />}*/}
            {/*>*/}
            {/*  Membership Area*/}
            {/*</SideNavItem>*/}
            {/*<SideNavItem*/}
            {/*  exact*/}
            {/*  to={`/MembershipArea/Terms`}*/}
            {/*  icon={<FaIndent />}*/}
            {/*>*/}
            {/*  Membership Terms*/}
            {/*</SideNavItem>*/}
            {/*</DropDownSideNavMenu>*/}

            {/*<DropDownSideNavMenu*/}
            {/*  to={"/manage-meetings"}*/}
            {/*  title={"Communications"}*/}
            {/*  icon={<FaVideo />}*/}
            {/*>*/}
            {/*  <SideNavItem exact to={`/email-config`} icon={<MdEmail />}>*/}
            {/*    Email Configuration*/}
            {/*  </SideNavItem>*/}

            {/*  <SideNavItem exact to={`/send-email`} icon={<FiMail />}>*/}
            {/*    Send Email*/}
            {/*  </SideNavItem>*/}
            {/*  <SideNavItem exact to={`/send-email/certificate`} icon={<FaCertificate />}>*/}
            {/*    Send Certificate*/}
            {/*  </SideNavItem>*/}
            {/*  <SideNavItem exact to={`/templates/certificates`} icon={<FaCertificate />}>*/}
            {/*    Certificate Template*/}
            {/*  </SideNavItem>*/}
            {/*  /!* TODO: need to build *!/*/}
            {/*  /!* <SideNavItem exact to={`/manage-meetings`} icon={<FiMail />}>*/}
            {/*    Automated Email*/}
            {/*  </SideNavItem> *!/*/}
            {/*  <SideNavItem exact to={`/manage-meetings`} icon={<FaVideo />}>*/}
            {/*    Meetings*/}
            {/*  </SideNavItem>*/}
            {/*  <SideNavItem exact to={`/manage-webinar`} icon={<MdLiveTv />}>*/}
            {/*    Webinar*/}
            {/*  </SideNavItem>*/}
            {/*  <SideNavItem exact to={`/recipientList`} icon={<FiUsers />}>*/}
            {/*    Recpiants Group*/}
            {/*  </SideNavItem>*/}
            {/*  <SideNavItem exact to={`/recipients`} icon={<FiUsers />}>*/}
            {/*    Recpiants*/}
            {/*  </SideNavItem>*/}

            {/*  <SideNavItem exact to={`/send-sms`} icon={<FaMobileAlt />}>*/}
            {/*    Send sms*/}
            {/*  </SideNavItem>*/}
            {/*</DropDownSideNavMenu>*/}

            <SideNavItem to="/Speakers" icon={<FaMicrophone/>} exact={false}>
              Speakers
            </SideNavItem>

            <SideNavItem to="/Schedule" icon={<FiCalendar/>} exact={false}>
              Schedule
            </SideNavItem>
            <SideNavItem to="/Calendar" icon={<FaRegCalendarDays/>} exact={false}>
              Calendar
            </SideNavItem>

            <SideNavItem to="/CheckIn" icon={<FiClock/>} exact={false}>
              Check in
            </SideNavItem>

            {/*//TODO : see if should be managed by session*/}
            {/*<SideNavItem to="/tickets" icon={<FaTicketAlt />} exact={false}>*/}
            {/*  Tickets*/}
            {/*</SideNavItem>*/}

            <SideNavItem to="/Sponsors" icon={<FaDollarSign/>} exact={false}>
              Sponsors
            </SideNavItem>
            <SideNavItem
              exact
              to={`/Locations`}
              icon={<FaLocationArrow/>}
            >
              Locations
            </SideNavItem>
            <SideNavItem
              exact
              to={`/Pages`}
              icon={<FaPager/>}
            >
              Pages
            </SideNavItem>
            <DropDownSideNavMenu
              to={`/NewsCases`}
              icon={<FaNewspaper/>}
              title={"News / Cases"}
            >
              <SideNavItem to="/News" exact icon={<FaNewspaper/>}>
                Manage News
              </SideNavItem>
              <SideNavItem exact to={`/Cases`} icon={<FaNewspaper/>}>
                Manage Cases
              </SideNavItem>
              <SideNavItem
                exact
                to={`/PostCategories`}
                icon={<FaList/>}
              >
                Categories
              </SideNavItem>
              <SideNavItem
                exact
                to={`/Tags`}
                icon={<FaHashtag/>}
              >
                Tags
              </SideNavItem>
            </DropDownSideNavMenu>

            <DropDownSideNavMenu
              to={`/ManageProducts`}
              icon={<FaBoxOpen/>}
              title={"Products"}
            >
              <SideNavItem to="/Products" exact icon={<FaBoxOpen/>}>
                Manage Produts
              </SideNavItem>
              {/*<SideNavItem exact to={`/add-product`} icon={<MdLibraryAdd />}>*/}
              {/*  Add new product*/}
              {/*</SideNavItem>*/}
              <SideNavItem exact to={`/ProductCategories`} icon={<FiGrid/>}>
                Product category
              </SideNavItem>

              <SideNavItem to="/Courses" icon={<FiVideo/>} exact={false}>
                Courses
              </SideNavItem>

              <SideNavItem
                exact
                to={`/MembershipPlans`}
                icon={<FaIdCard/>}
              >
                Membership Plans
              </SideNavItem>

              <SideNavItem
                exact
                to={`/Coupons`}
                icon={<FaTicketAlt/>}
              >
                Coupons
              </SideNavItem>
            </DropDownSideNavMenu>

            <DropDownSideNavMenu
              to={`/Finances`}
              icon={<FaDollarSign/>}
              title={"Finances"}
            >
              <SideNavItem to="/Orders" icon={<FiFile/>} exact={false}>
                Orders
              </SideNavItem>
              <SideNavItem to="/PaymentGateways" exact icon={<FaDollarSign/>}>
                Payment Gateways
              </SideNavItem>
              <SideNavItem exact to={`/Currency`} icon={<MdCreditCard/>}>
                Currency
              </SideNavItem>
              <SideNavItem exact to={`/Taxes`} icon={<FaFileInvoiceDollar/>}>
                Taxes
              </SideNavItem>
              {/*<SideNavItem*/}
              {/*  exact*/}
              {/*  to={`/paymentMode`}*/}
              {/*  icon={<FaFileInvoiceDollar />}*/}
              {/*>*/}
              {/*  Payment Modes*/}
              {/*</SideNavItem>*/}
            </DropDownSideNavMenu>
            <DropDownSideNavMenu
              to={`/Communication`}
              icon={<FaMessage/>}
              title={"Communication"}
            >
              <SideNavItem to="/Email" exact icon={<FaMailBulk/>}>
                Email
              </SideNavItem>
              <SideNavItem exact to={`/SMS`} icon={<FaSms/>}>
                SMS
              </SideNavItem>
            </DropDownSideNavMenu>

            <DropDownSideNavMenu
              to={`/Roles`}
              icon={<FiSettings/>}
              title={"Settings"}
            >
              {/*<DropDownSideNavMenu*/}
              {/*    to={`/PaymentGateways`}*/}
              {/*    icon={<MdSecurity />}*/}
              {/*    title={"Security"}*/}
              {/*>*/}
              <SideNavItem to="/ProfileSettings" exact icon={<FiSettings/>}>
                Profile
              </SideNavItem>
              <SideNavItem classNames='d-none' to="/Roles" exact icon={<FaDollarSign/>}>
                Roles
              </SideNavItem>
              {/*</DropDownSideNavMenu>*/}
              <SideNavItem
                exact
                to={`/Users`}
                icon={<MdSupervisorAccount/>}
              >
                Users
              </SideNavItem>
              {/*<SideNavItem
                exact
                to={`/domains`}
                icon={<MdSupervisorAccount />}
              >
                Domain Settings
              </SideNavItem>*/}
              <SideNavItem
                exact
                to={`/ConnectServices`}
                icon={<FaPuzzlePiece/>}
              >
                Connect Services
              </SideNavItem>
            </DropDownSideNavMenu>
            {/*<SideNavItem to="/PaymentGateways" exact icon={<FaDollarSign />}>*/}
            {/*  Payment Gateways*/}
            {/*</SideNavItem>*/}

            {/*<SideNavItem to="/CurrencyGateways" exact icon={<FaFileInvoiceDollar />}>*/}
            {/*  Currency Gateways*/}
            {/*</SideNavItem>*/}
          </SideNavItemsContainer>
        </SideNav>
      )}
    </SideNavContext.Consumer>
  );
};
