/* eslint-disable react/no-deprecated */
import * as React from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {IDropdownList} from "api/common/DropdownList";
import classnames from "classnames";
import {Loader} from "components/Loader";
import {ToastMessage} from "components/ToastMessages";
import TooltipHintComponent from "components/TooltipHint/TooltipHintComponent";
import moment from "moment";
import ReactDatetime from "react-datetime";
import {AiOutlineUser} from "react-icons/ai";
import {FaTicketAlt, FaWpforms} from 'react-icons/fa';
import {toast} from "react-toastify";
import {
  EditTenantUser,
  GetDurationPlanEditable,
  GetMembershipPlans,
  GetTenantUserById,
  IEditTenantUser
} from "../../api/MembershipAreaApi";

interface OwnProps {
  open: boolean;
  close: () => void;
  onSuccess?: () => void;
  tenantUserId: number;
}

type Props = OwnProps;

export default class EditTenantUserComponent extends React.Component<
  Props,
  any
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tenantUser: null,
      loading: true,
      activeTab: "1",
      activeTabTitle: "Edit User Data",
      currentStage: 1,
      plans: [],
      durationsPlan: [],
      durationPlanDataEditable: null
    };
  }


  componentWillMount() {
    if (this.props.tenantUserId) {
      this.fetchTenantUserDataHandler();
    }

  }


  fetchMembershipPlansHandler = () => {
    GetMembershipPlans().then(data => {
      const plans = data.map((item: IDropdownList, i: number) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.setState({ plans });
    });
  };

  // getDurationPlanHandler = (planId: number) => {
  //   GetDurationsPlan(planId).then(data => {
  //     ;
  //     this.setState({durationsPlan: data, planId});
  //   });
  // };

  getDurationPlanDataEditableHandler = (tenantUserId: number) => {
    this.setState({ loading: true })
    GetDurationPlanEditable(tenantUserId)
      .then(data => {
        this.setState({ durationPlanDataEditable: data, loading: false });
      }, _ => {
        this.setState({ loading: false })
      })
  }
  fetchTenantUserDataHandler = () => {
    this.setState({ loading: true })
    GetTenantUserById(this.props.tenantUserId)
      .then(result => {
        this.setState({ loading: false, tenantUser: result });

      }, _ => {
        this.setState({ loading: false })
      })
  }

  toggleTabs = (tab: any, title: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, activeTabTitle: title });
    }
  };


  onChangeHandler = (value: any, key: string, stage: number) => {

    const data = this.state.tenantUser;
    if (!data[key]) return;
    data[key] = value;
    this.setState({ tenantUser: data });
  };


  onSubmitHandler = () => {
    const { durationPlanId, email, firstName, lastName, phoneNumber } = this.state.tenantUser;;
    const payload: IEditTenantUser = {
      durationPlanId,
      email,
      firstName,
      lastName,
      phoneNumber,
      tenantUserQuestionAnswers: [],
      durationPlanEditable: this.state.durationPlanDataEditable
    };
    this.setState({ loading: true });
    EditTenantUser(this.props.tenantUserId, payload)
      .then(_ => {
        this.setState({ loading: false })
        toast.success(<ToastMessage>
          Edit Successfully
        </ToastMessage>)
        this.props.close?.();
        this.props.onSuccess?.();
      }, _ => {
        this.setState({ loading: false })
        toast.error(<ToastMessage>
          Error has been happened
        </ToastMessage>)
      })
  }


  render() {
    return (
      <Modal size={"lg"} unmountOnClose isOpen={this.props.open} toggle={this.props.close}>
        <ModalHeader>Edit Membership User
          {
            this.state.loading ?
              <Loader isLoading={true} />
              : null
          }

        </ModalHeader>
        <ModalBody>


              <div className="p-4" style={{ border: "1px solid #ddd" }}>


                {
                  this.state.tenantUser ?
                    <>
                      <FormGroup>
                        <Label> Email * </Label>
                        <Input
                          required={true}
                          className={"form-control"}
                          type="email"
                          value={this.state.tenantUser.email}
                          onChange={(e: any) => {
                            this.onChangeHandler(e.target.value, "email", 1);
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label> First Name * </Label>
                        <Input
                          required={true}
                          className={"form-control"}
                          type="text"
                          value={this.state.tenantUser.firstName}
                          onChange={(e: any) => {
                            this.onChangeHandler(e.target.value, "firstName", 1);
                          }}
                        />
                      </FormGroup>


                      <FormGroup>
                        <Label> Last Name * </Label>
                        <Input
                          required={true}
                          className={"form-control"}
                          type="text"
                          value={this.state.tenantUser.lastName}
                          onChange={(e: any) => {
                            this.onChangeHandler(e.target.value, "lastName", 1);
                          }}
                        />
                      </FormGroup>



                      <FormGroup>
                        <Label> Phone Numer * </Label>
                        <Input
                          required={true}
                          className={"form-control"}
                          type="text"
                          value={this.state.tenantUser.phoneNumber}
                          onChange={(e: any) => {
                            this.onChangeHandler(e.target.value, "phoneNumber", 1);
                          }}
                        />
                      </FormGroup>
                    </>
                    : null
                }



              </div>



        </ModalBody>
        <ModalFooter>
          <button onClick={this.props.close} className="btn btn-secondary"> Cancel </button>
          <button onClick={this.onSubmitHandler} className="btn btn-success"> Confirm </button>
        </ModalFooter>
      </Modal>
    );
  }
}
