import * as React from "react";
import { Card, CardProps } from "reactstrap";

export type MainCardProps = CardProps;

export const MainCard: React.FC<MainCardProps> = props => {
    return (
        <Card
            color="main"
            {...props}
            className={(props.className ?? "") + " big shadow"}
        >
            {props.children}
        </Card>
    );
};
