import * as React from "react";
import { useEffect, useState } from "react";
import {
  ChangePostPin,
  ChangePostsStatus,
  deletePost,
  GetPostsByCategory,
  GetPostsStatus,
  IPost
} from "../../api/PostsApi";
import Helmet from "react-helmet";
import {
  Button,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledButtonDropdown
} from "reactstrap";
import { FaEdit, FaEllipsisH, FaEye, FaMapPin, FaPlus, FaSpinner, FaTrash } from "react-icons/fa";
import { MainCard } from "../../components/MainCard";
import { Loader } from "../../components/Loader";
import EmptyImg from "../../assets/imgs/posts.svg";
import { DataTable } from "../../components/DataTable";
import { EmptyListState } from "../../components/EmptyListState";
import { AddEditPost } from "./AddEditPost";
import Select from "react-select";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";
import { PostPreview } from "./PostPreview";
import { LuPin, LuPinOff } from "react-icons/lu";

interface ManagePostsProps {
  type: "News" | "Cases"
}

export const ManagePosts: any = ({ type }: ManagePostsProps) => {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [postType, setPostType] = useState(type === "News" ? 0 : 1)
  const [loading, setLoading] = useState(false);
  const [showAddPosts, setShowAddPosts] = useState(false);
  const [deletePostsModalOpen, setDeletePostsModalOpen] = useState(false);
  const [postsToRemove, setPostsToRemove] = useState<IPost | null>(null);
  const [pinPostsModalOpen, setPinPostModalOpen] = useState(false);
  const [postsToPin, setPostsToPin] = useState<any | null>(null);
  const [postsToChange, setPostsToChange] = useState<IPost | null>(null);
  const [postsToView, setPostsToView] = useState<IPost | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<any | null>(null);

  const [actionInProgress, setActionInProgress] = useState(false);

  const [postStatus, setPostStatus] = useState<{ id: number, name: string }[] | null>(null);

  const [postsToEdit, setPostsToEdit] = useState<IPost | null>(null);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maximumPerPage] = useState(20);

  const showPostsDeleteModal = React.useCallback(
    (posts: IPost) => {
      setPostsToRemove(posts);
      setDeletePostsModalOpen(true);
    },
    [setPostsToRemove, setDeletePostsModalOpen]
  );

  const showPostsPinnedModal = (post: IPost) => {
    setPostsToPin(post);
    setPinPostModalOpen(true);
  }

  const getPostStatus = () => {
    GetPostsStatus().then(status => setPostStatus(status)).catch(err => {
    });
  }

  const getPosts = () => {
    setLoading(true);
    GetPostsByCategory(postType,{pageIndex:page,limit:maximumPerPage}).then(
      data => {
        setPosts(data.items);
        setCount(data.meta.totalItemsCount)
        setLoading(false);
      }
    ).catch(err => {
      ;
      setLoading(false);
    })
  }

  useEffect(() => {
    getPostStatus();
    getPosts();

    return (
      setPosts([])
    );
  }, [page])


  const showPostStatus = (status: any, post: IPost) => {
    setPostsToChange(post);
    setSelectedStatus(status);
  }

  const closeChangeStatus = () => {
    setPostsToChange(null);
    setSelectedStatus(null);
  }
  const handlePostsChangeStatus = () => {
    if (!postsToChange) return
    ChangePostsStatus(postsToChange.id, selectedStatus.id).then(() => {
      toast.success(
        <ToastMessage type="Success">
          Status changed successfully !
        </ToastMessage>
      )
      getPosts();
      closeChangeStatus();
    }).catch(err => {
      ;
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while changing post status,
          Please try again later.
        </ToastMessage>
      );
    })
  }

  const handlePinPost = () => {
    if (!postsToPin) return;
    ChangePostPin(postsToPin).then(() => {
      toast.success(
        <ToastMessage type="Success">
          Post {postsToPin?.isPinned?"unpinned":"pinned"} successfully !
        </ToastMessage>
      )
      getPosts();
      setPinPostModalOpen(false);
    }).catch(err => {
      ;
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while changing post status,
          Please try again later.
        </ToastMessage>
      );
    })

  }
  const handlePostsDelete = async () => {
    deletePost(postsToRemove!.id).then(() => {
        toast.success(
          <ToastMessage type="Success">
            Post deleted successfully !
          </ToastMessage>
        )
        getPosts();
        setDeletePostsModalOpen(false);
        setPostsToRemove(null);
      }
    ).catch(err => {
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while deleting post
          Please try again later.
        </ToastMessage>
      );
    })
  }

  const handleEditClicked = (posts: any) => {
    setPostsToEdit(posts)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        canSort: true,
      },
      {
        Header: "Title",
        accessor: "title",
        canSort: true
      },
      {
        Header: "Category",
        accessor: "postCategory.name",
        canSort: true
      },
      // {
      //     Header: "Authors",
      //     accessor: (data :IPost) => {
      //         return data.authors && data.authors.map(a=>a.displayName)
      //         //"authors && authors.map(a=>a.displayName)"
      //     },
      //     canSort :true
      // },
      {
        Header: "Authors",
        // accessor: "authors",
        Cell: function renderPostStatus(ctx: { row: { original: any; }; }) {
          const posts: IPost = ctx.row.original;
          if (!posts || !posts.authors) return null;
          // return row.authors && row.authors[0].displayName;
          return (
            posts.authors
              .map((author: any) => (
                <p>{author.displayName}</p>
              ))
          );
        },
      },
      {
        Header: "Status",
        Cell: function renderPostStatus(ctx: { row: { original: any; }; }) {
          const posts: IPost = ctx.row.original;
          if (!posts) return null;
          return (
            <Select
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              options={postStatus || []}
              value={postStatus?.filter(ps => ps.name === posts.status.name)}
              onChange={(o: any) => {
                showPostStatus(o, posts);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id + ""}
            />
          );
        },
      },
      {
        Header: "Actions",
        Cell: function renderPostsActions(ctx: { row: { original: any; }; }) {
          const posts = ctx.row.original;
          if (!posts) return null;
          return (
            <div>
              {/*<Button*/}
              {/*    size="sm"*/}
              {/*    color="success"*/}
              {/*    outline={true}*/}
              {/*    onClick={(_) => setPostsToView(posts)}*/}
              {/*>*/}
              {/*    <FaEye />*/}
              {/*</Button>{" "}*/}
              {/*<Button*/}
              {/*    size="sm"*/}
              {/*    color="warning"*/}
              {/*    outline={true}*/}
              {/*    onClick={() => {*/}
              {/*        showPostsPinnedModal(posts)*/}
              {/*    }}*/}
              {/*>*/}
              {/*    <FaMapPin />*/}
              {/*</Button>{" "}*/}
              <Button
                size="sm"
                color="primary"
                outline={true}
                onClick={(_) => handleEditClicked(posts)}
              >
                <FaEdit/>
              </Button>{" "}
              <Button
                size="sm"
                color="danger"
                outline={true}
                onClick={() => {
                  showPostsDeleteModal(posts)
                }}
              >
                <FaTrash/>
              </Button>{" "}
              <UncontrolledButtonDropdown size="sm">
                <DropdownToggle caret outline color="dark">
                  <FaEllipsisH/>
                </DropdownToggle>

                <DropdownMenu container={'body'}>
                  <DropdownItem>
                    <Button
                      size="sm"
                      color="success"
                      outline={true}
                      onClick={(_) => setPostsToView(posts)}
                    ><FaEye/> Preview
                    </Button>
                  </DropdownItem>
                  <DropdownItem color={"warning"}
                                onClick={() => {
                                  showPostsPinnedModal(posts)
                                }}
                  >
                    <Button
                      size="sm"
                      color="warning"
                      outline={true}
                      onClick={() => {
                        showPostsPinnedModal(posts)
                      }}
                    >{posts.isPinned ?<LuPinOff/>:<LuPin/>} {posts.isPinned ?"Unpin":"Mark as pinned"}
                    </Button>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>

            </div>
          );
        },
      },
    ],
    [posts]
  );

  return (
    <div>
      <Helmet title="Posts"/>
      <MainCard>
        <CardHeader className="d-flex justify-content-between">
          <div>{type}</div>
          <div>
            <Button color={'primary'} onClick={() => setShowAddPosts(true)}>
              <FaPlus/> Add {type}
            </Button>
          </div>
        </CardHeader>
        <CardBody>

          {loading ?
            <Loader isLoading={true}/>
            :
            <DataTable
              columns={columns}
              data={posts || []}
              pagination={
                {
                  show: true,
                  onPageClicked: setPage,
                  totalItemsCount: count,
                  pageIndex: page,
                  pageSize: maximumPerPage,

                }
              }
              emptyState={
                <div className={"text-center"}>
                  <EmptyListState
                    image={EmptyImg}
                    title={"You have no Posts yet"}
                  />
                </div>
              }
            />

          }
        </CardBody>

      </MainCard>

      {
        (showAddPosts || !!postsToEdit)
        &&
        <AddEditPost
            isOpen={showAddPosts || !!postsToEdit}
            postToEdit={postsToEdit}
            toggle={() => {
              getPosts();
              setPostsToEdit(null);
              setShowAddPosts(false);
            }}
            type={type}
        />
      }

      <Modal size={"lg"} isOpen={!!postsToChange} unmountOnClose>
        <ModalHeader toggle={() => {
          closeChangeStatus();
        }}>
          Change Post Status
        </ModalHeader>
        <ModalBody>
          Are you sure you want to change &quot;
          {postsToChange && postsToChange.title}{" "} Status to &quot;
          {selectedStatus && selectedStatus.name}
          &quot;?
        </ModalBody>

        <ModalFooter className={"space-between"}>
          <Button onClick={() => {
            closeChangeStatus();
          }}>
            No, Don't change it
          </Button>
          <Button
            color="danger"
            onClick={handlePostsChangeStatus}
            disabled={actionInProgress}
          >
            {actionInProgress && (
              <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
            )}{" "}
            Yes, Change Status
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size={"lg"} isOpen={!!postsToChange} unmountOnClose>
        <ModalHeader toggle={() => {
          setPostsToChange(null);
          setSelectedStatus(null)
        }}>
          Change Post Status
        </ModalHeader>
        <ModalBody>
          Are you sure you want to change &quot;
          {postsToChange && postsToChange.title}{" "} Status to &quot;
          {selectedStatus && selectedStatus.name}
          &quot;?
        </ModalBody>

        <ModalFooter className={"space-between"}>
          <Button onClick={() => {
            setPostsToChange(null);
            setSelectedStatus(null)
          }}>
            No, Don't change it
          </Button>
          <Button
            color="success"
            onClick={handlePostsChangeStatus}
            disabled={actionInProgress}
          >
            {actionInProgress && (
              <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
            )}{" "}
            Yes, Change Status
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size={"lg"} isOpen={!!postsToView} unmountOnClose>
        <ModalHeader toggle={() => {
          setPostsToView(null)
        }}>
          Preview Post
        </ModalHeader>
        <ModalBody>
          <PostPreview post={postsToView}/>
        </ModalBody>

        <ModalFooter className={"space-between"}>
          <Button onClick={() => {
            setPostsToView(null);
          }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size={"lg"} isOpen={pinPostsModalOpen} unmountOnClose>
        <ModalHeader toggle={() => {
          setPostsToPin(null);
          setPinPostModalOpen(!pinPostsModalOpen)
        }}>
          {postsToPin?.isPinned?"Unpin":"Pin"} Post
        </ModalHeader>
        <ModalBody>
          Are you sure you want to {postsToPin?.isPinned?"unpin":"pin"} &quot;
          {postsToPin && postsToPin.title}{" "}
          &quot;?
        </ModalBody>

        <ModalFooter className={"space-between"}>
          <Button onClick={() => {
            setPostsToPin(null);
            setPinPostModalOpen(!pinPostsModalOpen)
          }}>
            No, cancel {postsToPin?.isPinned?"unpinning":"pinning"} &quot;
            {postsToPin && postsToPin.title}{" "}
            &quot;
          </Button>
          <Button
            color="success"
            onClick={handlePinPost}
            disabled={actionInProgress}
          >
            {actionInProgress && (
              <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
            )}{" "}
            Yes, {postsToPin?.isPinned?"Unpin":"Pin"} &quot;
            {postsToPin && postsToPin.title}{" "}
            &quot;
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size={"lg"} isOpen={deletePostsModalOpen} unmountOnClose>
        <ModalHeader toggle={() => {
          setPostsToRemove(null);
          setDeletePostsModalOpen(!deletePostsModalOpen)
        }}>
          Delete Post
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete &quot;
          {postsToRemove && postsToRemove.title}{" "}
          &quot;?
        </ModalBody>

        <ModalFooter className={"space-between"}>
          <Button onClick={() => {
            setPostsToRemove(null);
            setDeletePostsModalOpen(!deletePostsModalOpen)
          }}>
            No, keep &quot;
            {postsToRemove && postsToRemove.title}{" "}
            &quot;
          </Button>
          <Button
            color="danger"
            onClick={handlePostsDelete}
            disabled={actionInProgress}
          >
            {actionInProgress && (
              <span>
                                <FaSpinner className="icon-spin"/>
                            </span>
            )}{" "}
            Yes, Delete &quot;
            {postsToRemove && postsToRemove.title}{" "}
            &quot;
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

}