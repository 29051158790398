import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSponsorById, SponsorWithGroup } from "../api/Sponsors";
import { AppThunk } from "./index";

export interface AddEditSponsorState {
    isBusy: boolean;
    error: string | null;
    sponsor: SponsorWithGroup | null;
}

const initialState: AddEditSponsorState = {
    error: null,
    isBusy: false,
    sponsor: null
};

interface ReceiveAddEditSponsorDetailAction {
    error: string | null;
    sponsor: SponsorWithGroup | null;
}

const AddEditSponsorDetail = createSlice({
    name: "AddEditSponsorDetail",
    initialState,
    reducers: {
        requestAddEditSponsorDetail(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receievAddEditSponsorDetail(
            state,
            action: PayloadAction<ReceiveAddEditSponsorDetailAction>
        ) {
            state.isBusy = false;
            state.error = action.payload.error;
            state.sponsor = action.payload.sponsor;
        }
    }
});

export default AddEditSponsorDetail.reducer;

const {
    requestAddEditSponsorDetail,
    receievAddEditSponsorDetail
} = AddEditSponsorDetail.actions;

export const loadSponsorById = (sponsorId: number): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        if (getState().addEditSponsorDetail.isBusy) return;

        dispatch(requestAddEditSponsorDetail());
        const result = await getSponsorById(sponsorId);

        dispatch(receievAddEditSponsorDetail({ sponsor: result, error: null }));
    } catch (e) {
        dispatch(
            receievAddEditSponsorDetail({
                error: "e.toString()",
                sponsor: getState().addEditSponsorDetail.sponsor
            })
        );
    }
};
