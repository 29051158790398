/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deletePlan, IAddEditMembershipPlanModel } from "../../api/ProductsApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";


interface IDeleteMembershipPlanProps {
    cancelDeleting: () => unknown;
    onUpdated: () => unknown;
    itemToDelete: IAddEditMembershipPlanModel
}
interface IDeleteMembershipPlanstate {
    __loading__: boolean;
    __id__: number;
}

class DeleteMembershipPlan extends React.Component<IDeleteMembershipPlanProps, IDeleteMembershipPlanstate> {

    constructor(props: IDeleteMembershipPlanProps) {
        super(props);
        this.state = {
            __loading__: false,
            __id__: 0
        }
    }
    componentWillMount() {
        if (this.props.itemToDelete) {
            this.setState({ __id__: this.props.itemToDelete.id! });

        }
    }

    handleDeleting = () => {
        this.setState({ __loading__: true })
        deletePlan(this.state.__id__).then(_ => {
            this.setState({ __loading__: false })
            toast.success(
                <ToastMessage type="Success">
                    Deleted Successfully !
                </ToastMessage>
            );
            if (this.props.onUpdated) {
                this.props.onUpdated();
            }
            if (this.props.cancelDeleting) {
                this.props.cancelDeleting();
            }

        }).catch(error => {
            this.setState({ __loading__: false })
            toast.error(
                <ToastMessage type="Error">
                    Error Has been happened !
                </ToastMessage>
            );
        })
    }
    render() {
        return (
            <Modal size={"lg"} toggle={this.props.cancelDeleting} isOpen={!!this.props.itemToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Membership Plan
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ? <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.itemToDelete &&
                        `${this.props.itemToDelete.name || ""}`}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.itemToDelete &&
                            `${this.props.itemToDelete.name || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDeleting}>
                        Yes, Delete &quot;
                        {this.props.itemToDelete &&
                            `${this.props.itemToDelete.name || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteMembershipPlan;