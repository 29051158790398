import { Recipient, deleteRecepientGroup, getCSVFile, getCSVFileExample } from "api/RecipientLists";
import { DefaultPageSize } from "components/Constants";
import { DataTable } from "components/DataTable";
import { DeleteModal } from "components/DeleteModal";
import { EmptyListState } from "components/EmptyListState";
import { MainCard } from "components/MainCard";
import { ToastMessage } from "components/ToastMessages";
import { denormalize } from "normalizr";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { FaDownload, FaEdit, FaPlus, FaTrash, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router";
import {Link, useNavigate} from "react-router-dom";
import { CellProps, Column } from "react-table";
import { toast } from "react-toastify";
import {Button, CardBody, CardHeader} from "reactstrap";
import { ManageEventParams } from "scenes/ManageEventParams";
import { RootState } from "store";
import { fetchPaginatedRecipientGroupByEventId } from "store/RecipientGroups";
import { recipientGroupsListSchema } from "store/Schemas";
import { AddRecepientGroupPopover } from "../ManageRecepientGroups";
import UploadCSVModal from "./UploadCSVModal";
export const ManageRecepientGroups: React.FC = (props) => {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();
  const params = useParams<ManageEventParams>();
  const eventId = Number(params.eventId);

  const recipientGroupsListState = useSelector(
    (state: RootState) => state.recipientGroups.pagination.byEventId[eventId]
  );

  const [isAddCommitteOpened, openAddComitte] = useState(false);

  const handleAddCommitte = useCallback(() => {
    openAddComitte(true);
  }, [openAddComitte]);

  const rGroup = useMemo(
    () =>
      denormalize(
        recipientGroupsListState?.ids,
        Array(recipientGroupsListSchema),
        store.getState()
      ),
    [recipientGroupsListState, store]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [
    recepientToDelete,
    setRecepientGroupToDelete,
  ] = useState<Recipient | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const fetchCommitteeGroupEffect = () => {
    dispatch(
      fetchPaginatedRecipientGroupByEventId(eventId, {
        pageIndex: currentPage,
        limit: pageSize,
      } ) as any
    );
  };
  useEffect(fetchCommitteeGroupEffect, [eventId, currentPage, pageSize]);



  const downloadCsvFile = (id: number, name: string) => {
    try {
      const response = getCSVFile(id, name);
    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          Error has been happened
        </ToastMessage>
      )
    }
  }


  const downloadCsvExample = () => {
    try {
      const response = getCSVFileExample();
    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          Error has been happened
        </ToastMessage>
      )
    }
  }

  const deleteCommitteeGroup = () => {
    if (!recepientToDelete) return;

    return deleteRecepientGroup(recepientToDelete.id)
      .then((_) => {
        toast.success(
          <ToastMessage>
            Recepient gorup &quot;
            {recepientToDelete?.name}
            &quot; was deleted successfully.
          </ToastMessage>
        );

        setIsDeleteModalOpen(false);
        fetchCommitteeGroupEffect();
      })
      .catch((_) =>
        toast.error(
          <ToastMessage type="Error">
            Something went wrong while deleting &quot;
            {recepientToDelete?.name}
            &quot;.
          </ToastMessage>
        )
      );
  };

  const goToRecepiants = (id: number) => {
    navigate(`/recipients?recepientsGroup=${id}`);
  }

  const downloadRecepiants = (id: number) => {
    const x = (props as any);
    const eventId = x.match.params.eventId;
  }

  const renderCommitteeGroupActions = useCallback(
    (ctx: CellProps<Recipient>) => {
      const committeeGroup = ctx.row.original;
      if (!committeeGroup) return null;

      return (
          <>
          <Button
            outline
            size="md"
            className="mx-1"
            color="primary"
            onClick={() => {
              goToRecepiants(committeeGroup.id);
            }}
          >
            <FaEdit />
          </Button>{" "}
          <Button onClick={e => {
            downloadCsvFile(committeeGroup.id, committeeGroup.name);
          }} color="success" outline={true} size="md"
            className="mx-1">
            <FaDownload />
          </Button>{" "}

          <Button
            outline
            size="md"
            className="mx-1"
            color="danger"
            onClick={() => {
              setRecepientGroupToDelete(committeeGroup);
              setIsDeleteModalOpen(true);
            }}
          >
            <FaTrash />
          </Button>
          </>
      );
    },
    []
  );

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Recipients Count",
        accessor: "recipientsCount"
      },
      {
        Header: "Event name",
        accessor: "eventName",
        Cell: (ctx: CellProps<Recipient>) => {
          const element = ctx.row.original;
          return element?.eventName && element?.eventId ?
            <Link to={`/Events/${element?.eventId}/Details`} >
              {element?.eventName}
            </Link>
            : <span> _ </span>
        }
      },
      {
        Header: "Actions",
        Cell: renderCommitteeGroupActions,
      },
    ],
    [renderCommitteeGroupActions]
  );
  const [isUploadOpen, openCloseUpload] = useState(false);
  const openUploadPopup = () => {
    openCloseUpload(!isUploadOpen);
  };

  return (
    <div>
      <Helmet title="Recepient Groups" />
      {isUploadOpen && (
        <UploadCSVModal
          refresh={fetchCommitteeGroupEffect}
          isOpen={isUploadOpen}
          toggle={openUploadPopup}
        />
      )}
      {
        isAddCommitteOpened ?
          <AddRecepientGroupPopover
            eventId={eventId}
            target="addCommittee"
            isOpen={true}
            toggle={() => openAddComitte((b) => !b)}
            onRecipientGroupAdded={fetchCommitteeGroupEffect}
            placement="bottom"
          />
          : null
      }


      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <span>Recepient groups</span>
            {/*<div>*/}
            {/*    <Button onClick={e => {*/}
            {/*        downloadCsvExample();*/}
            {/*    }} outline={true} color={"success"} > Download CSV Example <span className={"mx-2"}> <FaFileExcel /> </span>  </Button>*/}
            {/*</div>*/}
            <div>
              <Button
                className="mr-3"
                onClick={openUploadPopup}
                color="success"
              >
                Upload Group list
                <span className={"mx-1"}>
                  <FaUpload />
                </span>
              </Button> {" "}
              <Button
                className="mr-3"
                id="addCommittee"
                onClick={handleAddCommitte}
                color="primary"
              >
                Add recepient group
                <span className={"mx-1"}>
                  <FaPlus />
                </span>
              </Button>
            </div>
          </div>
        </CardHeader>
          <CardBody>
              <DataTable
                  columns={columns}
                  data={rGroup || []}
                  isBusy={recipientGroupsListState?.isFetching}
                  emptyState={
                      <EmptyListState title={"You have no recepient group yet"} />
                  }
                  pagination={{
                      show: true,
                      onPageClicked: (page) => setCurrentPage(page),
                      pageIndex: recipientGroupsListState?.pageIndex || 1,
                      pageSize: pageSize,
                      totalItemsCount: recipientGroupsListState?.totalItemsCount || 0,
                  }}
              />
          </CardBody>
      </MainCard>
      <DeleteModal
        deleteItemPresenter={<b>{recepientToDelete?.name}</b>}
        toggle={() => setIsDeleteModalOpen(false)}
        onDelete={deleteCommitteeGroup}
        isOpen={isDeleteModalOpen}
      />
    </div>
  );
};
