import usePlacesAutocomplete, {getGeocode, getLatLng,} from "use-places-autocomplete";
import React from "react";
import Select from "react-select";
import {FormGroup, Label} from "reactstrap";

const PlacesAutocomplete = ({ setSelected }: any) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address: any) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng });
    };

    const changeValue = (value: any) => {
        setValue(value);
    }
    return (
            <FormGroup>
                <Label> Location </Label>
            <Select
                placeholder={"Search..."}
                isDisabled={!ready}
                // value={value}
                onChange={(e)=>e && handleSelect(e.description)}
                onInputChange={changeValue}
                isMulti={false}
                options={status === "OK" ? data : []}
                getOptionLabel={(option) => option.description}
                getOptionValue={(option) => option.place_id +""}
            />
            </FormGroup>
    );
};

export default PlacesAutocomplete;