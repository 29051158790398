import Axios from "axios";
import {NewResourceResult} from "./common/NewResourceResult";
import {PaginatedRequest} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface PaymentGateway {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  type: {
    id: number;
    name: string;
  };
  currency: {
    id: number;
    code: string;
    symbol: string;
    isDefault: boolean;
  };
  isPOS: boolean;
  isActive: boolean;
  paymentGatewayMethods: any[];
}

export interface PaymentMethodMetadata {
  id: number;
  name: string;
  imageUrl: string;
}

export interface PaymentMethod extends Omit<PaymentMethodMetadata, "id"> {
  paymentGatewayMethodId: number;
  isActive: boolean;
}

export interface TamaraPaymentGateway {
  id?: number;
  publishKey: string;
  secretKey: string;
  paymentGatewayId?: number;
  methods: PaymentMethod[];
  isActive: boolean;
  currencyId: number;
}

export interface MoyasarPaymentGateway {
  id?: number;
  publishKey: string;
  secretKey: string;
  paymentGatewayId?: number;
  methods: PaymentMethod[] | string;
  isActive: boolean;
  currencyId: number;
  paymentMethodIds?: number[] | null;
}

export interface PaytabsPaymentGateway {
  id: number;
  email: string;
  merchantId: string;
  isActive: boolean;
  paymentGatewayId: number;
  methods: PaymentMethod[];
}

export const getPaymentGatewayByType = async (type: string): Promise<any> => {
  const result = await Axios.get(`/api/PaymentGateways/`+type);
  return result.data;
}

export const updateOnSitePaymentGateway = async (id: number, payload: any): Promise<any> => {
  const result = await Axios.put(`/api/PaymentGateways/OnSite/${id}`, payload);
  return result.data;
}

export interface EditMoyasarPaymentGateway {
  name: string;
  description: string;
  publishKey: string;
  secretKey: string;
  supportedNetworks: string;
  methods: string;
  currencyId: number;
  isActive: boolean;
  paymentMethodsIds: number[];
  isProduction: boolean;
}

export const updateMoyasarPaymentGateway = async (payload: EditMoyasarPaymentGateway): Promise<any> => {
  const result = await Axios.post(`/api/PaymentGateways/Moyasar`, payload);
  return result.data;
}

export interface EditTamaraPaymentGateway {
  name: string;
  description: string;
  website?: string;
  currencyId: number;
  isProduction: boolean;
  isActive: boolean;
  paymentMethodsIds: number[];
  requestTimeout: number;
  apiToken: string;
  notificationToken: string;
}

export const updateTamaraPaymentGateway = async (payload: EditTamaraPaymentGateway): Promise<any> => {
  const result = await Axios.post(`/api/PaymentGateways/Tamara`, payload);
  return result.data;
}

export const getPaymentGateways = async (
  request?: PaginatedRequest
): Promise<PaymentGateway[]> => {
  const result = await Axios.get(`/api/PaymentGateways`, {
    params: request,
  });

  return result.data;
};

export interface PaymentGatewayMetadata
  extends Omit<PaymentGateway, "paymentGatewayMethods"> {
  paymentGatewayMethods: PaymentMethodMetadata[];
}
export const getPaymentGatewaysMetadata = async (): Promise<PaymentGatewayMetadata[]> => {
  const result = await Axios.get(`/api/PaymentGateways/Metadata`);

  return result.data;
};

export const getPaymentMethods = async (): Promise<PaginatedResult<PaymentMethodMetadata>> => {
  const result = await Axios.get(`/api/PaymentMethods`);

  return result.data;
};

export const getPaytabsPaymentGateway = async (
  id: number
): Promise<PaytabsPaymentGateway> => {
  return (await Axios.get(`/api/PaymentGateways/Paytabs/${id}`)).data;
};

export interface AddPaytabsPaymentGatewayRequest {
  email: string;
  secretKey: string;
  merchantId: string;
  isActive: boolean;
  paymentMethods: EditPaymentMethodItem[];
  currencyId: number;
}

export interface UpdatePaytabsPaymentGatewayRequest
  extends AddPaytabsPaymentGatewayRequest {
  id: number;
}

export interface EditPaymentMethodItem {
  paymentGatewayMethodId: number;
  isActive: boolean;
}

export const updatePaytabsPaymentGateway = async (
  request: UpdatePaytabsPaymentGatewayRequest
) => {
  const { id, ...data } = request;

  const result = await Axios.put(`/api/PaymentGateways/paytabs/${id}`, data);

  return result.data;
};

export const addPaytabsPaymentGateway = async (
  request: AddPaytabsPaymentGatewayRequest
): Promise<NewResourceResult<number>> => {
  return (await Axios.post(`/api/PaymentGateways/paytabs`, request)).data;
};

export const deletePaytabsPaymentGateway = async (id: number) => {
  return (await Axios.delete(`/api/PaymentGateways/paytabs/${id}`)).data;
};

// Moyasar aPi's

export const AddMoyasar = async (
  payload: MoyasarPaymentGateway
): Promise<any> => {
  const response = await Axios.post<MoyasarPaymentGateway>(
    `api/PaymentGateways/Moyasar`,
    payload
  );
  return response.data;
};

export const getMoyasar = async (
  id: number
): Promise<MoyasarPaymentGateway> => {
  const response = await Axios.get(`api/PaymentGateways/Moyasar/${id}`);
  return response.data;
};

export const editMoyasar = async (
  id: number,
  payload: MoyasarPaymentGateway
): Promise<any> => {
  const response = await Axios.put(
    `api/PaymentGateways/Moyasar/${id}`,
    payload
  );
  return response.data;
};


//EDITED for Members
export interface PaymentMethod2 {
  id: number;
  name: string;
  imageUrl: string;
}

interface Currency {
  id: number;
  code: string;
  symbol: string;
  isDefault: boolean;
}

interface PaymentType {
  id: number;
  name: string;
}

export interface PaymentOption {
  id: number;
  name: string;
  description: string;
  type: PaymentType;
  currency: Currency;
  isPOS: boolean;
  isActive: boolean;
  paymentMethods: PaymentMethod2[];
}

export const GetPaymentGatewaysPos = async (): Promise<PaymentOption[]> => {

  const result = await Axios.get('/api/PaymentGateways/POS');
  return result.data;

}