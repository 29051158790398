/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { uploadFile } from "api/UploadCSV";
import classnames from 'classnames';
import { ToastMessage } from "components/ToastMessages";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { AiFillFileExcel, AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav, NavItem, NavLink,
  Row,
  TabContent, TabPane,
  UncontrolledDropdown
} from "reactstrap";
import * as yup from "yup";
import {
  getCSVFileExample,
  getRecipientGroup
} from "../../api/RecipientLists";

const UploadCSVModal = ({ isOpen, toggle, refresh }) => {
  const initialValues = React.useMemo(
    () => ({
      name: "",
      csv: "",
    }),
    []
  );
  const [fileName, setFilename] = React.useState("");
    const [activeTab, setActiveTab] = React.useState('1');
    const toggleTabs = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
  const params = useParams();
  const eventId = Number(params.eventId);

  const handleSubmit = React.useCallback(async (values, actions) => {
    // try {
    //   const payload = {
    //     eventId,
    //     htmlBodyTemplate: values.content,
    //     certificateId: item.id || null,
    //     recipinetListId: values.csv,
    //     name: values.name,
    //   };
    //   const addResult = await sendMail(payload);
    //   toast.success(<ToastMessage>Email Sent successfully.</ToastMessage>);
    // } catch (e) {
    //   toast.error(
    //     <ToastMessage type="Error">
    //       Something went wrong while sending email.
    //     </ToastMessage>
    //   );
    // } finally {
    // }
  }, []);

  const ValidatorSchema = React.useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Name is required."),
        csv: yup.string().required("csv field is required"),
      }),
    []
  );
    const [recepientId, setRecepientId] = React.useState({});
    const [recepients, setRecepientsGroups] = useState([]);





    const getGroups = async (eventId ) => {

        const res = await getRecipientGroup(eventId);
        if (res && res.items) {
            res.items = res.items.reverse();
            setRecepientsGroups(res.items);

            // if (res.items && res.items.length){
            //     setRecepientId(res.items[0]);
            // }
            // console.log(res, recepientId);
        }
    };










    const downloadCsvExample = () => {
        try {
            const response  =  getCSVFileExample();
        }catch (e) {
            toast.error(
                <ToastMessage type="Error">
                    Error has been happened
                </ToastMessage>
            )
        }
    }
    
    
    
    
    
    
    
    
    
    
    
  const renderForm = React.useCallback(
    (formProps) => (
      <Form className="p-3 sendEmail">
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.name}
                  name={"name"}
                />

                <ErrorMessage
                  name={"name"}
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col className="upload" sm={12}>
              <FormGroup>   
                {!fileName && (
                  <Label 
                      className="d-center btn btn-outline-success text-center"
                      style={{ justifyContent: "center" , alignItems:'center'  }}
                    htmlFor="csv"
                  >
                    {/*<span>You can also Upload CSV </span>*/}
                    {/*<AiOutlineUpload></AiOutlineUpload>*/}
                      <span> Upload CSV </span>
                      <span style={{fontSize:'20px'}}>
                        <AiFillFileExcel > </AiFillFileExcel>
                      </span>
                  </Label>
                )}
                {fileName && (
                  <span className="d-center">
                    {fileName}{" "}
                    <AiOutlineClose
                      style={{
                        cursor: "pointer",
                        marginLeft: "75px",
                      }}
                      onClick={() => {
                        setFilename("");
                        formProps.setFieldValue("csv", 0);
                      }}
                    ></AiOutlineClose>
                  </span>
                )}
                <Input
                  id="csv"
                  type="file"
                  style={{ display: "none" }}
                  onBlur={formProps.handleBlur}
                  onChange={async (e) => {
                    if (!e.target.files) return;
                    try {
                      setFilename(formProps.values.name);
                      const response = await uploadFile(
                        {
                          CsvFile: e.target.files[0],
                          Name: formProps.values.name,
                        },
                        eventId
                      );
                      formProps.setFieldValue("csv", response.id);
                      refresh();
                      toggle();
                      toast.success(
                        <ToastMessage type="Success">
                          File uploaded successfully
                        </ToastMessage>
                      );
                    } catch (e) {
                      toast.error(
                        <ToastMessage type="Error">
                          This file has wron format, Please try another file.
                        </ToastMessage>
                      );
                    }
                  }}
                  name={"csv"}
                />
                <ErrorMessage
                  name={"csv"}
                  render={(error) => (
                    <FormFeedback className="d-block">{error}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        {/*<ModalFooter>*/}
        {/*  <Button color="secondary" onClick={toggle}>*/}
        {/*    Cancel*/}
        {/*  </Button>*/}
        {/*</ModalFooter>*/}
      </Form>
    ),
    []
  );
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Import Recepiant Group
        <br />
          {/*TODO: Add Download Functionality*/}
          <small>
              <em>
                  Check CSV Structure <span  onClick={downloadCsvExample}  style={{color:'blue' , cursor:'pointer'}}>  from here </span>
              </em>
          </small>
      </ModalHeader>
        <ModalBody>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggleTabs('1'); }}
                    >
                       Upload File To  New Group
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggleTabs('2'); }}
                    >
                        Upload File To  Exist Group
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={ValidatorSchema}
                        render={renderForm}
                    />
                </TabPane>
                <TabPane tabId="2" >
                    <FormGroup> 
                        {/*<Label> Choose A group </Label>*/}
                        <div style={{padding:'25px'}}>
                            <UncontrolledDropdown>
                                {/*<Select*/}
                                {/*    onMenuOpen={ _ => {*/}
                                {/*        */}
                                {/*        if(!recepients || recepients.length === 0) {*/}
                                {/*            getGroups(eventId)*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    name="recepientId"*/}
                                {/*    getOptionLabel={(option) => option.name}*/}
                                {/*    getOptionValue={(option) => option.id}*/}
                                {/*    value={recepientId}*/}
                                {/*    options={recepients} */}
                                {/*    onChange={(option) => {*/}
                                {/*        */}
                                {/*        setRecepientId(option);*/}
                                {/*    }}*/}
                                {/*    placeholder="Select a group name"*/}
                                {/*/>*/}
                                <select className={"form-control"}
                                  onClick={e => {
                                      if(!recepients || recepients.length === 0) {
                                          getGroups(eventId)
                                      }
                                  }}
                                        
                                  onChange={e => {
                                      e.preventDefault();
                                      setRecepientId(e.target.value)
                                  }}
                                        
                                        
                                >
                                    {recepients && recepients.length > 0 && recepients.map(item => {
                                        return (
                                            <option value={item.id}> {item.name} </option>
                                        );
                                    })}
                                </select>
                            </UncontrolledDropdown>
                          
                        </div>
                        <Col className="upload" sm={12}>
                            <FormGroup>
                                {!fileName && (
                                    <Label
                                        className="d-center btn btn-outline-success text-center"
                                        style={{ justifyContent: "center" , alignItems:'center'  }}
                                        htmlFor="csv"
                                    >
                                        {/*<span>You can also Upload CSV </span>*/}
                                        {/*<AiOutlineUpload></AiOutlineUpload>*/}
                                        <span> Upload CSV </span>
                                        <span style={{fontSize:'20px'}}>
                        <AiFillFileExcel > </AiFillFileExcel>
                      </span>
                                    </Label>
                                )}
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </TabPane>
            </TabContent>
           
        </ModalBody>
    
    </Modal>
  );
};

export default UploadCSVModal;
