import PropTypes from "prop-types";
import * as React from "react";
import { Card, CardHeader } from "reactstrap";

interface TimeLineGroupProps {
    title: string;
    children: React.ReactNode;
}

export const TimeLineGroup: React.FC<TimeLineGroupProps> = props => {
    return (
        <Card color="default" className='mt-2' >
            <CardHeader className="h5 py-3 ">{props.title}</CardHeader>
            <ul className="list-group timeline-group">{props.children}</ul>
        </Card>
    );
};

TimeLineGroup.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired
};
