/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import {InputGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { RecipientListItem, getRecipientList } from "../../api/RecipientLists";
import { PaginatedResult } from "../../api/common/PaginatedResult";
import { Loader } from "../../components/Loader";
import './RecipientGroupSelectorStyle.css';

interface IProps {
    recipientListId: number;
    recipientListName: string;
    isOpen: boolean;
    close?: () => unknown;
    receiveDeSelectIds: (deSelectIds: { deSelectedIds: Array<number>, recipientListId: number }) => unknown;
    sendDeSelectIds?: { deSelectedIds: Array<number>, recipientListId: number } | null
}

interface IState {
    recipients: Array<any>,
    loading: boolean,
    deSelectIds: Array<number>,
    searchKey?: string | null
}

class RecipientsTableModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            recipients: [],
            loading: false,
            deSelectIds: [],
            searchKey: null
        }
    }

    componentWillMount() {
        if (this.props.recipientListId > 0) {
            this.fetchRecipientByRecipientList(this.props.recipientListId)
        }

        if (this.props.sendDeSelectIds && this.props.sendDeSelectIds.deSelectedIds && this.props.sendDeSelectIds.deSelectedIds.length > 0) {
            this.setState({ deSelectIds: this.props.sendDeSelectIds.deSelectedIds })
        }
    }

    fetchRecipientByRecipientList = (recipientListId: number) => {
        this.setState({ loading: true })
        getRecipientList(recipientListId, { pageIndex: 1, limit: 10, recipientListId }).then((data: PaginatedResult<RecipientListItem>) => {

            this.setState({ loading: false })
            if (data) {
                this.setState({ recipients: data.items });
            }

        }).catch((err: any) => {
            this.setState({ loading: false })

        })
    }


    onSearchHandler = () => {

        const { searchKey } = this.state;
        /*
        * metadata: {
        *  recpientListId:int,
        *  
        * }
        * */
        this.setState({ loading: true })
        getRecipientList(this.props.recipientListId, {
            query: searchKey,
            recipientListId: this.props.recipientListId,
            limit: 50,
            pageIndex: 1
        }).then(data => {
            this.setState({ loading: false })
            if (data) {
                this.setState({ recipients: data.items });
            }
        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    confirmHandler = () => {
        const deSelectedIds = this.state.deSelectIds;
        const recipientListId = this.props.recipientListId;
        const result = {
            recipientListId, deSelectedIds
        }
        if (this.props.receiveDeSelectIds) {
            this.props.receiveDeSelectIds(result);
            if (this.props.close) {
                this.props.close()
            }
        }
    }

    onSelectOrDeselectAll = (e: any) => {

        if (this.state.deSelectIds.length > 0) {
            this.setState({ deSelectIds: [] })
        } else {
            const ids = this.state.recipients.map((item, index) => item.id);
            this.setState({ deSelectIds: ids })
        }
    }

    render() {
        return (
            <Modal unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close} size={'lg'}>
                <ModalHeader toggle={this.props.close}>
                    <div className={'d-flex'}>
                        <span className={'flex-grow'}>
                            Recipients of group {this.props.recipientListName}
                        </span>

                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mx-auto">

                            <InputGroup>
                                <input
                                    onBlur={e => {
                                        e.preventDefault();
                                        this.setState({ searchKey: e.target.value })
                                    }}
                                    className={'form-control'} placeholder={'e.g mohamed , 966.. or @gmail.com'} />
                                <button
                                    onClick={this.onSearchHandler}
                                    className={'btn btn-primary d-flex'}>
                                    Search
                                    <i className={'mx-2'} style={{ fontSize: '17px', transform: 'rotate(87deg)', fontWeight: 'bold' }}>
                                        <AiOutlineSearch />
                                    </i>
                                </button>
                            </InputGroup>
                        </div>
                    </div>
                    <div className={'row mt-3'}>
                        <div className="col-sm-12">
                            <table className={'table table-responsive'}>
                                <thead className={'thead-light'}>
                                    <tr>
                                        <td>
                                            #
                                        </td>
                                        <td>
                                            F.Name
                                        </td>
                                        <td>
                                            M.Name
                                        </td>
                                        <td>
                                            L.Name
                                        </td>
                                        <td>
                                            Email
                                        </td>
                                        <td>
                                            Phone
                                        </td>
                                        <td>
                                            <div className={'form-group d-flex'}>
                                                <label className="form-check-label"> Select {" "}
                                                    <input
                                                        onChange={this.onSelectOrDeselectAll}
                                                        type={'checkbox'} className={'form-check-input'} checked={this.state.deSelectIds.length === 0} />
                                                </label>
                                                {/*<div className="form-check d-flex justify-content-center align-items-center">*/}
                                                {/*    <input*/}

                                                {/*        onChange={this.onSelectOrDeselectAll}*/}
                                                {/*        type={'checkbox'} className={'form-check-input'} checked={this.state.deSelectIds.length === 0} />*/}
                                                {/*</div>*/}
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.loading ?
                                            <Loader isLoading={true} />
                                            : null
                                    }
                                    {


                                        this.state.recipients.map((item: any, key: number) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {item.index}
                                                    </td>
                                                    <td>
                                                        {item.firstName}
                                                    </td>
                                                    <td>
                                                        {item.middleName ? item.middleName : "N/A"}
                                                    </td>
                                                    <td>
                                                        {item.lastName}
                                                    </td>
                                                    <td>
                                                        {item.email}
                                                    </td>
                                                    <td>
                                                        {item.phoneNumber}
                                                    </td >
                                                    <td>
                                                        <div className={'form-group form-check'}>
                                                            <input type={'checkbox'}
                                                                onChange={e => {
                                                                    if (this.state.deSelectIds.includes(item.id)) {
                                                                        // remove
                                                                        let { deSelectIds } = this.state;
                                                                        deSelectIds = deSelectIds.filter(x => x !== item.id);
                                                                        this.setState({ deSelectIds })

                                                                    } else {
                                                                        // push
                                                                        const { deSelectIds } = this.state;

                                                                        deSelectIds.push(item.id);
                                                                        this.setState({ deSelectIds })
                                                                    }


                                                                }}
                                                                checked={!this.state.deSelectIds.includes(item.id)} className={'form-check-input'} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary"
                            onClick={this.props.close}
                    > Cancel </button>
                    <button
                        onClick={this.confirmHandler}
                        className="btn btn-success" > Confirm </button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default RecipientsTableModal;