import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import {
    ListProductCategoryRequest,
    getProductCategory
} from "../api/ProductCategoriesApi";
import { ProductCategory } from "../api/ProductsApi";
import { AddEntitiesAction, addEntities } from "./Actions";
import { NormalizedPagination } from "./NormalizedPagination";
import { productCategorySchema } from "./Schemas";
import { AppThunk } from "./index";
export interface ProductCategoriesState {
    [key: string]: ProductCategory | {};

    pagination: {
        byEventId: NormalizedPagination;
    };
}

const initialState: ProductCategoriesState = {
    pagination: { byEventId: {} }
};

interface RequestFetchProductCategories {
    eventId: number;
}

interface ReceiveFetchProductCategories {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const productCategoriesSlice = createSlice({
    name: "productCategories",
    initialState,
    reducers: {
        requestFetchProductCategories(
            state,
            action: PayloadAction<RequestFetchProductCategories>
        ) {
            const eventId = action.payload.eventId.toString();
            state.pagination.byEventId[eventId] = {
                ...state.pagination.byEventId[eventId],
                isFetching: true
            };
        },

        receiveFetchProductCategories(
            state,
            action: PayloadAction<ReceiveFetchProductCategories>
        ) {
            const eventId = action.payload.eventId.toString();
            state.pagination.byEventId[eventId] = {
                ...action.payload,
                isFetching: false
            };
        }
    },

    extraReducers: (builder => {
        builder.addCase(addEntities.type,(state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["productCategories"]
            };
        })
    })
});

export default productCategoriesSlice.reducer;

const {
    requestFetchProductCategories,
    receiveFetchProductCategories
} = productCategoriesSlice.actions;

export const fetchPaginatedProductCategories = (
    request: ListProductCategoryRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestFetchProductCategories({ eventId: request.eventId }));

        const result = await getProductCategory(request);
        const normalized = normalize(
            result.items,
            Array(productCategorySchema)
        );

        dispatch(addEntities(normalized.entities as any));

        dispatch(
            receiveFetchProductCategories({
                ...result.meta,
                eventId: request.eventId,
                error: undefined,
                ids: normalized.result
            })
        );
    } catch (e) {
        dispatch(
            receiveFetchProductCategories({
                ...getState().productCategories.pagination.byEventId[
                    request.eventId.toString()
                ],
                eventId: request.eventId,
                error: "e.toString()"
            })
        );
    }
};
