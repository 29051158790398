/* eslint-disable react/no-deprecated */
import { getSubTenants, GetSubTenantsExportData, ISubTenant } from 'api/SubAccountApi';
import React from 'react';
import Helmet from "react-helmet";
import { FaEdit, FaFileExport, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Button, CardBody, CardHeader, Col, Form, InputGroup, Row, Table } from "reactstrap";
import NoData from '../../assets/imgs/profile.svg';
import { MainCard } from "../../components/MainCard";
import { ModalStatus } from "../ManagePaymentGateways/AddEditCurrencyPaymentModal";
import AddEditSubAccounts from "./AddEditSubAccounts";
import DeleteSubAccountsModal from "./DeleteSubAccountsModal";
import { Loader } from "../../components/Loader";
import { PaginationFooter } from "../../components/PaginationFooter";
import { joinNames } from "../../util/nameUtils";
import blankProfile from "../../assets/imgs/male_avatar.svg";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";

class ManageSubAccounts extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      __loading__: false,
      openModal: false,
      __itemEditable__: null,
      __itemDeletable__: null,
      page: 1,
      count: 0,
      maximumPerPage: 20,
      searchValue:''
    }
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.page !== this.state.page ) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    this.setState({ __loading__: true })

    getSubTenants({ limit: this.state.maximumPerPage, pageIndex: this.state.page,text:this.state.searchValue }).then(data => {
      this.setState({
        __loading__: false, data: data.items, count: (data as any).totalItemsCount
      })
    }).catch(error => {
      this.setState({ __loading__: false });
    })
  }

  closeModal = () => {
    this.setState({ openModal: false })
  }


  cancelDeleteHandler = () => {
    this.setState({ __itemDeletable__: null })
  }


  showEditModal = (item: any) => {

    this.setState({
      __itemEditable__: {
        ...item.fullName,
        roleId: item.roles && item.roles.length > 0 ? item.roles[0].id : undefined,
        idTypeId:item.idType ? item.idType.id : undefined,
        email:item.email,
        phoneNumber:item.phoneNumber,
        scfhsid:item.scfhsid,
        bio:item.bio,
        zactaID:item.zactaID,
        address:item.address,
        isSpeakerOnHomePage:item.isSpeakerOnHomePage,
        linkedIn:item.linkedIn,
        facebook:item.facebook,
        twitter:item.twitter,
        website:item.website,
        id:item.id,
        image:item.image && item.image.fullUrl
      },
    }, () => {

    });
    this.setState({ openModal: true })
  }


  deleteHandler = (item: ISubTenant) => {
    this.setState({ __itemDeletable__: item })
  }
  exportCSV= () =>{
    GetSubTenantsExportData().then(value => {
      const url = window.URL.createObjectURL(new Blob(["\uFEFF"+value], { type: 'text/csv;charset=utf-8;' }))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Users Export.csv`;
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(reason => {
      toast.error(reason.response.data?.detail ?? "Error while Exporting Attendances")
    })
  }


  render() {
    return (
      <div>
        <Helmet title="Users"/>
        <MainCard>
          <CardHeader className="d-flex justify-content-between">
            <div>Users</div>
            <div className="d-flex flex-row gap-2">
              <Button
                onClick={e => {
                  this.setState({ openModal: true, __itemEditable__: null })
                }}
                color={'primary'}>
                <FaPlus/> Add User
              </Button>
              <Button
                className={'d-flex align-items-center gap-2'}
                onClick={this.exportCSV}
                color="success"
              >
                Export<FaFileExport/>
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Form>
              <Row className="align-items-center mb-4">
                <Col sm="4" xs="6">
                  <InputGroup>
                    <input
                      value={this.state.searchValue ?? ""}
                      style={{ flex: 2 }}
                      type="text"
                      onChange={(e) => {
                        const { value } = e.target;
                        this.setState({ searchValue:value });
                      }}
                      className="form-control flex-2"
                      placeholder=""
                    />

                    <button
                      onClick={e => {
                        e.preventDefault();
                        this.fetchData();
                      }}
                      className="btn btn-success flex-2"
                    >
                      Search
                      <i>
                        {" "}
                        <AiOutlineSearch/>{" "}
                      </i>
                    </button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
            {this.state.data.length > 0 && !this.state.__loading__ ?
              <Table>
                <thead>
                <tr>
                  <td>
                    #
                  </td>
                  <td>
                    Full Name
                  </td>
                  <td>
                    Email
                  </td>
                  <td>
                    Phone
                  </td>
                  <td>
                    Plan
                  </td>
                  <td>
                    Role
                  </td>
                  <td>
                    Last Active
                  </td>
                  <td>
                    Actions
                  </td>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.data.map((item: any, index: number) => {
                    return (
                      <>
                        <tr>
                           <td style={{verticalAlign:"middle"}} >
                            {index + 1}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                             <div className="d-flex flex-row align-items-center gap-2">
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={item.image?.fullUrl ?? blankProfile}
                                width={"50px"}
                                height={"50px"}
                              />
                            </div>
                               <span>{joinNames(item.fullName.firstName, item.fullName.secondName, item.fullName.thirdName, item.fullName.lastName)}</span>
                             </div>
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            {item.email}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            {item.phoneNumber}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            {item.membershipActiveDurationPlan?.name}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            {item.roles.map((role:any)=> role.name).join(",")}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            {item.lastActiveDate != '0001-01-01T00:00:00+00:00' ? new Date(item.lastActiveDate).toLocaleString() : 'N/A'}
                          </td>
                           <td style={{verticalAlign:"middle"}} >
                            <Button
                              outline={true}
                              color={"primary"}
                              onClick={e => {
                                this.showEditModal(item);
                              }}>
                              <FaEdit/>
                            </Button> {" "}
                            <Button
                              outline={true}
                              color={"danger"}
                              onClick={e => {
                                this.deleteHandler(item);
                              }}
                            >
                              <FaTrashAlt/>
                            </Button>
                          </td>
                        </tr>
                      </>

                    );
                  })
                }
                </tbody>
                <tfoot>
                <tr>
                  <td colSpan={8}>
                    <PaginationFooter
                      onPageClicked={page => this.setState({ page: page })}
                      pageIndex={this.state.page}
                      totalItemsCount={this.state.count}
                      pageSize={this.state.maximumPerPage}
                      pageItemsCount={this.state.data.length}
                    />
                  </td>
                </tr>
                </tfoot>
              </Table> : !this.state.__loading__ ?
                <div className={'text-center'}>
                  <img src={NoData} width={'30%'}/>
                </div> : null
            }
            {
              this.state.__loading__ ?
                <Loader isLoading={true}/>
                :
                null
            }
          </CardBody>
        </MainCard>


        {

          this.state.openModal ?
            this.state.__itemEditable__ ?
              <AddEditSubAccounts item={this.state.__itemEditable__} onUpdated={this.fetchData} email={null} id={null}
                                  isOpen={this.state.openModal} close={this.closeModal}
                                  modalStatus={ModalStatus.__EDIT__}/>
              : <AddEditSubAccounts onUpdated={this.fetchData} email={null} id={null} roleId={null}
                                    isOpen={this.state.openModal} close={this.closeModal}
                                    modalStatus={ModalStatus.__CREATE__}/>
            : null
        }

        {
          this.state.__itemDeletable__ ? <DeleteSubAccountsModal cancelDeleting={this.cancelDeleteHandler}
                                                                 subTenantToDelete={this.state.__itemDeletable__}
                                                                 onUpdated={this.fetchData}/> : null
        }
      </div>
    );
  }
}

export default ManageSubAccounts;