import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from "moment";
import React, { useEffect, useState } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getAgendaSessions } from "../../api/AgendaSessionApi";
import { toast } from "react-toastify";
import { getCourseTimeline } from "../../api/CoursesApi";
import { useNavigate } from "react-router-dom";
import { AddEditSessionFormModal } from "../ManageEventSessions/AddEditSessionFormModal";
import { Button, CardBody, CardHeader } from "reactstrap";
import { FaPlus } from "react-icons/fa";
import { MainCard } from "../../components/MainCard";
import { getEvents } from "../../api/Events";

const localiser = momentLocalizer(moment);

const EventsCalendar = () => {

  const [currentDate, setCurrentDate] = useState(moment());
  const [events, setEvents] = useState<any[]>([]);
  const [eventId, setEventId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<View>('month');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [addSessionIsOpen, setAddSessionIsOpen] = useState(false);

  const navigate = useNavigate();


  const getSessionsHandler = async () => {
    const result = await getAgendaSessions({
      eventId: 1,
      FirstStartDate: currentDate.startOf('month').toISOString(),
      LastStartDate: currentDate.endOf('month').toISOString()
    });
    const courses = (await getCourseTimeline()).map((course: any) => ({
      title: course.name,
      isCourse: true, ...course
    }))
    setEvents([...result, ...courses]);

  }
  useEffect(() => {
    getSessionsHandler().catch(() => {
      toast.error("Error while fetching calendar events")
    }).finally(() => {
      setLoading(false)
    })
    getEvents({}).then(result => {
      if (result.items.length > 0) {
        setEventId(result.items[0].id)
      }
    })
  }, [currentDate])

  const formatEventTitle = (event: any) => {
    const startTime = moment(event.startDateTime).format('LT');
    const endTime = moment(event.endDateTime).format('LT');
    return `${startTime} - ${endTime} ${event.title}`;
  };


  return (
    <MainCard>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <span>Schedule</span>
          <div>
            <Button
              onClick={() => setAddSessionIsOpen(true)}
              color="primary"
            >
              Add schedule item <FaPlus/>
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Calendar
          localizer={localiser}
          events={events}
          startAccessor={event => moment(event.startDateTime).toDate()}
          endAccessor={event => {
            return moment(event.endDateTime).toDate()
          }}
          onView={(view) => setView(view)}
          style={{ height: 900 }}
          onNavigate={(date) => {
            setCurrentDate(moment(date))
            setSelectedDate(date)
          }}
          views={['month', 'day', "week"]}
          titleAccessor={formatEventTitle}
          enableAutoScroll
          view={view}
          doShowMoreDrillDown
          onShowMore={(events1, date) => {
            setSelectedDate(date);
            setView('day')
          }}
          date={selectedDate}
          timeslots={1}
        />

        <AddEditSessionFormModal
          eventId={eventId}
          isOpen={addSessionIsOpen}
          closeModal={() => {
            getSessionsHandler().catch(() => {
              toast.error("Error while fetching calendar events")
            })
            setAddSessionIsOpen(false);

          }}
          editSession={null}
        />
      </CardBody>
    </MainCard>
  );

}

export default EventsCalendar;