import Axios from "axios";
import {Moment} from "moment";
import {PaginatedRequestWithFilter} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";

export interface IMembershipAreaTicket {
  id: number;
  ticketNumber: string;
  creatingDate: Moment;
  userFullName: string;
  checkedIn: boolean;
}

export const getAllTickets = async (
  request: PaginatedRequestWithFilter
): Promise<PaginatedResult<IMembershipAreaTicket>> => {
  const response = await Axios.get(`api/UserCheckins/Tickets`, {
    params: request,
  });
  return response.data;
};

export interface IMembershipAreaTicketQRCode {
  qrCode: string;
}

export const getQrCode = async (
  ticketId: number
): Promise<IMembershipAreaTicketQRCode> => {
  const response = await Axios.get(
    `api/MembershipAreaTickets/${ticketId}/GetQrCode`
  );
  return response.data;
};
