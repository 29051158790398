import * as yup from "yup";
import {ContactFormValidationSchema} from "../../ValidationSchemas/ContactFormValidationSchema";
import {AddEditSpeakerForm} from "./AddEditSpeakerFormModal";

export const formValidation = yup.object().shape<AddEditSpeakerForm>({
    id: yup.number().nullable(),
    title: yup.string().nullable(),
    firstName: yup
        .string()
        .nullable()
        .required(),
    secondName: yup.string().nullable(),
    thirdName: yup.string().nullable(),
    lastName: yup
        .string()
        .nullable()
        .required(),
    profilePicture: yup.mixed<File>().when("id", {
        is: id => !!id,
        then: yup
            .mixed<File>()
            .nullable()
            .notRequired()
            .test("FileType", "only image files are supported", value =>
                value ? !!(value as File).type.match("image.*") : true
            ),
        otherwise: yup
            .mixed<File>()
            .nullable()
            .required()
            .test(
                "FileType",
                "only image files are supported",
                value => value && !!(value as File).type.match("image.*")
            )
    }),
    contact: ContactFormValidationSchema,
    isOnHomePage: yup.boolean(),
    createAccount: yup.boolean(),
    AssignedEvents: yup.array().of(yup.string()).nullable(),
});