import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MobileApp, getMobileAppsList } from "api/MobileAppsApi";
import { PaginatedRequest } from "api/common/PaginatedRequest";
import { normalize } from "normalizr";
import { AddEntitiesAction, addEntities } from "store/Actions";
import {
    NormalizedPaginationItem
} from "store/NormalizedPagination";
import { mobileAppSchema } from "store/Schemas";
import { AppThunk } from "./index";

export interface MobileAppsState {
    [key: string]: MobileApp | {};

    pagination: {
        list: NormalizedPaginationItem | null;
    };
}

const initialState: MobileAppsState = {
    pagination: {
        list: {
            isFetching: false
        }
    }
};

interface ReceiveAllMobileAppsListPaginatedAction {
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const mobileAppSlice = createSlice({
    name: "mobileApps",
    initialState,
    reducers: {
        requestAllMobileListPaginated(state, action) {
            state.pagination.list = {
                ...state.pagination.list,
                isFetching: true
            };
        },
        receiveAllMobileListPaginated(
            state,
            action: PayloadAction<ReceiveAllMobileAppsListPaginatedAction>
        ) {
            state.pagination.list = {
                ...state.pagination.list,
                ...action.payload,
                isFetching: false
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["mobileApps"]
            };
        })
    })
});

export default mobileAppSlice.reducer;

const {
    requestAllMobileListPaginated,
    receiveAllMobileListPaginated,
} = mobileAppSlice.actions;


export const fetchMobileApps = (
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(requestAllMobileListPaginated({}));
        const result = await getMobileAppsList(request);
        const normalizedResult = normalize(
            result.items,
            Array(mobileAppSchema)
        );

        dispatch(addEntities(normalizedResult.entities as any));
        dispatch(
            receiveAllMobileListPaginated({
                error: undefined,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount,
                ids: normalizedResult.result
            })
        );
    } catch (e) {
        dispatch(
            receiveAllMobileListPaginated({
                ...getState().mobileApps.pagination.list,
           
            })
        );
    }
};
