import React, { useState } from "react";
import {
    Card,
    Container,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { NavMenu } from "./NavMenu";

import { EventUrlContext } from "Contexts/EventUrlContext";
import PropTypes from "prop-types";

import { FaEnvelope, FaGlobe, FaTwitter } from "react-icons/fa";
import { useLocation } from "react-router";
import { SideNavContext } from "../Contexts/SideNavContext";
import { SubNav } from "./SubNav";
import {Footer} from "./Footer";

interface LayoutProps {
    children: React.ReactNode;
    sideNav?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps | any> = props => {

    const location: any = useLocation();
    const [eventId, setEventId] = React.useState<number | null>(null)

    const [isSideNavOpen, setIsSideNavOpen] = useState(false);

    return (
        <EventUrlContext.Provider
            value={location && location.pathname && location.pathname.toLowerCase().includes('/events/')}>


            <SideNavContext.Provider
                value={{
                    isOpen: isSideNavOpen,
                    toggle: () => {
                        setIsSideNavOpen(sideNavOpen => !sideNavOpen);
                    }
                }}
            >
                <div>
                    {props.sideNav}
                    <div className="w-100">
                        <div className="navbar-container">
                            <NavMenu/>
                            <SubNav/>
                        </div>
                        <div className="page-content">
                            <Container fluid className="content-wrapper">{props.children}</Container>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </SideNavContext.Provider>
        </EventUrlContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    sideNav: PropTypes.node
};
