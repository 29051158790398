/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteWebinar, IWebinarList } from "../../api/WebinarApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";


// props
interface IDeleteWebinarModalProps {
    webianrToDelete: IWebinarList,
    onUpdated?: () => unknown,
    cancelDeleting: () => unknown;

}

// state
interface IDeleteWebinarModalState {
    __loading__: boolean,
    id: number
}
class DeleteWebinarModal extends React.Component<IDeleteWebinarModalProps, IDeleteWebinarModalState> {

    constructor(props: IDeleteWebinarModalProps) {
        super(props);
        this.state = {
            __loading__: false,
            id: 0
        }
    }

    componentWillMount() {

        if (this.props.webianrToDelete) {
            this.setState({ id: this.props.webianrToDelete.id })
        }
    }

    handleDelete = () => {
        this.setState({ __loading__: true })
        deleteWebinar(this.state.id)
            .then(res => {
                this.setState({ __loading__: false })
                toast.success(
                    <ToastMessage type="Success">
                        Deleted Successfully !
                    </ToastMessage>
                );
                this.props.onUpdated?.();
                this.props.cancelDeleting?.();

            }).catch(err => {
                this.setState({ __loading__: true })
            })
    }
    render() {
        return (
            <Modal toggle={this.props.cancelDeleting} isOpen={!!this.props.webianrToDelete}>
                <ModalHeader toggle={this.props.cancelDeleting}>
                    Delete Meeting
                </ModalHeader>
                <ModalBody>

                    {this.state.__loading__ ? <Loader isLoading={true} /> : null}


                    Are you sure you want to delete &quot;
                    {this.props.webianrToDelete &&
                        `${this.props.webianrToDelete.topic || ""}`}
                    &quot;?
                </ModalBody>

                <ModalFooter>
                    <Button onClick={this.props.cancelDeleting}>
                        No, keep &quot;
                        {this.props.webianrToDelete &&
                            `${this.props.webianrToDelete.topic || ""}`}
                        &quot;
                    </Button>
                    <Button color="danger" onClick={this.handleDelete}>
                        Yes, Delete &quot;
                        {this.props.webianrToDelete &&
                            `${this.props.webianrToDelete.topic || ""}`}
                        &quot;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteWebinarModal;