import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

export interface DeleteModalProps {
    isOpen: boolean;
    toggle: () => void;
    typeToConfirm?: string;

    onDelete: () => Promise<any> | undefined;
    deleteItemPresenter: React.ReactNode;
}

export const DeleteModal: React.FC<DeleteModalProps> = props => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (props.isOpen) return;
        setIsDeleting(false);
        setInputText("");
    }, [props.isOpen, setInputText, setIsDeleting]);

    const canDelete =
        inputText === props.typeToConfirm || props.typeToConfirm === undefined;
    const onDeleteClicked = useCallback(() => {
        if (canDelete) {
            setIsDeleting(true);

            const deletePromise = props.onDelete();
            if (deletePromise) {
                deletePromise.finally(() => {
                    setIsDeleting(false);
                });
            } else {
                setIsDeleting(false);
            }
        }
    }, [canDelete, props]);
    return (
        <Modal unmountOnClose isOpen={props.isOpen} size="lg">
            <ModalHeader toggle={props.toggle}>
                Permanently Delete {props.deleteItemPresenter}
            </ModalHeader>
            <ModalBody>
                <p>
                    You are about to delete &quot;{props.deleteItemPresenter}
                    &quot; and all it&apos;s contents. this operatin can&apos;t
                    be undone.
                </p>

                {props.typeToConfirm && (
                    <div>
                        Type &quot;{props.typeToConfirm}&quot; to confirm
                        <Input
                            value={inputText}
                            onChange={e => setInputText(e.target.value)}
                        />
                    </div>
                )}
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.toggle} disabled={isDeleting}>
                    No, Keep &quot;{props.deleteItemPresenter}&quot;
                </Button>

                <Button
                    onClick={onDeleteClicked}
                    color="danger"
                    disabled={isDeleting || !canDelete}
                >
                    {isDeleting && (
                        <span>
                            <FaSpinner className="icon-spin" />
                        </span>
                    )}{" "}
                    Yes, Delete &quot;{props.deleteItemPresenter}&quot;
                </Button>
            </ModalFooter>
        </Modal>
    );
};

DeleteModal.defaultProps = {
    typeToConfirm: "delete"
};
