import { LoadScript } from "@react-google-maps/api";
import React from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { unregister } from "registerServiceWorker";
import App from "./App";
import { configureAxios } from "./configureAxios";
import store from "./configureStore";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "simplebar/dist/simplebar.min.css";
import "react-datetime/css/react-datetime.css";

import {createRoot} from 'react-dom/client';
import {ErrorBoundary} from "react-error-boundary";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");


configureAxios();

const root = createRoot(rootElement!);

root.render(
    <Provider store={store}>
        <Router basename={baseUrl || undefined}>
            <Helmet titleTemplate="%s - Ems Dashboard" defaultTitle="Ems Dashboard"/>
            <form id="signInForm" method="get" action="/oidc">
                <input
                    type="hidden"
                    name="_CSRF_TOKEN_INPUT"
                    value={"5524154210512020001521023451023120"}
                />
            </form>
            <LoadScript
                googleMapsApiKey={"AIzaSyBSDa_SFD1l2kT9pKOmSFudN50CYysI5Tk"} libraries={["places"]}>
                {/*<ErrorBoundary fallback={<div>Something went wrong</div>} onError={(err)=>}>*/}
                    <App />
                {/*</ErrorBoundary>*/}
            </LoadScript>
            <ToastContainer
                hideProgressBar={false}
                progressStyle={{height: "2px"}}
                className="mt-5"
            />
        </Router>
    </Provider>
);

unregister();
