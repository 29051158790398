/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GetTicket, GetTicketHtmlView, UpdateCheckedIn } from '../../api/SmartAccessApi';
import { Loader } from '../../components/Loader/Loader';


interface OwnProps {
  close: () => void;
  isOpen: boolean;
  ticketId: number;
}

type Props = OwnProps;

export default class TicketReceiptComponent extends Component<Props, any> {
  componentToPrintRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      html: null,
      data: null
    };
    this.componentToPrintRef = React.createRef();
  }



  componentWillMount() {
    if (this.props.ticketId) {
      this.setState({ loading: true });
      GetTicket(this.props.ticketId).then(data => {
        this.setState({ loading: false, data });

      })

      GetTicketHtmlView(this.props.ticketId).then(data => {
        this.setState({ loading: false })
        if (data) {
          this.setState({ html: data.html })
        }
      })
    }
  }

  updateCheckedInHandler = () => {
    UpdateCheckedIn(this.props.ticketId).then(_ => {
      GetTicket(this.props.ticketId).then(data => {
        this.setState({ loading: false, data });

      })
    })
  }

  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >
        <ModalHeader toggle={this.props.close}>Ticket Receipt</ModalHeader>
        <ModalBody >
          <div>
            {this.state.loading ? <Loader isLoading={true} /> : null}


            {
              this.state.html ?
                <>
                  <div className="text-center" ref={this.componentToPrintRef}>

                    {/* <div>
                      <h2> {this.state.data.fullName} </h2>
                    </div>


                    <div style={{ marginTop: '50px' } as React.CSSProperties}>
                      <div className="mt-4">
                        {/* <img src={
                         
                        } width="70%" /> */}
                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.data.qrCodeHtml }}>

                        </div>
                      </div>
                      <div className="mt-2">
                        <strong>  {this.state.data.ticketNumber} </strong>
                      </div>
                    </div> */}

                    <div dangerouslySetInnerHTML={{ __html: this.state.html }}>

                    </div>



                  </div>

                  <div className="row footer-icons text-center">
                    <div className="col-sm-6">
                      <Input type={'checkbox'} size={100}
                        onClick={e => {
                          this.updateCheckedInHandler();
                        }} checked={this.state.data.checkedIn} />
                    </div>
                    <div className="col-sm-6">

                      <ReactToPrint
                        trigger={() => {

                          return (<button>
                            <i> <AiOutlinePrinter /> </i>
                            <span> Print </span>
                          </button>);
                        }}
                        content={() => this.componentToPrintRef.current}
                      />
                    </div>

                  </div>
                </>
                : null
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
