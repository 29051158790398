import { ContactInfo } from "api/common/ContactInfo";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { nameof } from "util/nameof";

export interface ContactFormProps {
    formikProps: FormikProps<any>;
    name: string;
}

export const ContactForm: React.FC<ContactFormProps> = props => {
    return (
        <>
            <FormGroup>
                <Label>Email *</Label>
                <Field
                    name={props.name + "." + nameof<ContactInfo>("email")}
                    render={({ field }: FieldProps) => <Input {...field} required />}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("email")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label>Phone number</Label>
                <Field
                    name={props.name + "." + "phoneNumber"}
                    render={({ field }: FieldProps) => (
                        <PhoneInput
                            {...field}
                            onPhoneNumberUpdated={formattedPhoneNumber => {
                                props.formikProps.setFieldValue(
                                    props.name +
                                    "." +
                                    nameof<ContactInfo>("phoneNumber"),
                                    formattedPhoneNumber
                                );
                            }}
                        />
                    )}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("phoneNumber")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label>Linkedin</Label>
                <Field
                    name={props.name + "." + nameof<ContactInfo>("linkedIn")}
                    render={({ field }: FieldProps) => <Input {...field} />}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("linkedIn")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label>Facebook</Label>
                <Field
                    name={props.name + "." + nameof<ContactInfo>("facebook")}
                    render={({ field }: FieldProps) => <Input {...field} />}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("facebook")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label>Twitter</Label>
                <Field
                    name={props.name + "." + nameof<ContactInfo>("twitter")}
                    render={({ field }: FieldProps) => <Input {...field} />}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("twitter")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label>Website</Label>
                <Field
                    name={props.name + "." + nameof<ContactInfo>("website")}
                    render={({ field }: FieldProps) => <Input {...field} />}
                />
                <ErrorMessage
                    name={props.name + "." + nameof<ContactInfo>("website")}
                    render={error => (
                        <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                />
            </FormGroup>
        </>
    );
};
