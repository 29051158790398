/* eslint-disable react/no-deprecated */
import classnames from 'classnames';
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { IEditTenantConfigureServiceModel, IEditTenantConfigureServiceModels, IPostIntegrateWithService, IServiceConfigureInfo, IServiceKeyValuesPair, editIntegrateWithService, integrateWithService } from '../../api/ConnectServiceApi';

interface IIntegrateWithServiceProps {
  isOpen: boolean;
  close?: () => unknown;
  onUpdated?: () => unknown;
  keys: Array<IServiceConfigureInfo>;
  serviceId: number;
  serviceName: string;
}

interface IIntegrateWithServiceState {
  activeTab: string;
  serviceKeysValue: Array<IServiceKeyValuesPair>
  editableServiceKeys: Array<IEditTenantConfigureServiceModel>,
  isCiscoService: boolean
}

class IntegrateWithServiceModalComponent extends Component<IIntegrateWithServiceProps, IIntegrateWithServiceState> {

  constructor(props: IIntegrateWithServiceProps) {
    super(props);
    this.state = {
      activeTab: "1",
      serviceKeysValue: [],
      editableServiceKeys: [],
      isCiscoService: false
    }
  }



  componentWillMount() {

    if (this.props.keys && this.props.keys.length > 0
      && this.props.keys.every(x => x.tenantConfigureServiceDto != null)
    ) {
      // Edit mode verify;
      const editableKeys: Array<IEditTenantConfigureServiceModel> = this.props.keys.map((item: IServiceConfigureInfo, i: number) => {
        let result: IEditTenantConfigureServiceModel = {
          keyValue: item.tenantConfigureServiceDto?.keyValue,
          tenantConfigureServiceId: item.tenantConfigureServiceDto?.id
        };
        return result;
      })


      if (this.props.serviceName == "Cisco Meeting") {
        this.setState({ isCiscoService: true })
      }


      this.setState({ editableServiceKeys: editableKeys }, () => {
      })

    }

  }




  handleAfterSubmit = (client_Id: string) => {
    if (this.state.isCiscoService) {
      const baseUrl = "https://webexapis.com/v1/authorize?client_id=Cd7655588bf14865cc55dc93f9d5e4c675eb81aa6a13d71e1136fb9a82567464f&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A44389%2Fcisco-service&scope=meeting%3Aadmin_participants_read%20meeting%3Aadmin_recordings_read%20meeting%3Aadmin_recordings_write%20meeting%3Aadmin_schedule_read%20meeting%3Aadmin_schedule_write%20meeting%3Acontrols_read%20meeting%3Acontrols_write%20meeting%3Aparticipants_read%20meeting%3Aparticipants_write%20meeting%3Apreferences_read%20meeting%3Apreferences_write%20meeting%3Arecordings_read%20meeting%3Arecordings_write%20meeting%3Aschedules_read%20meeting%3Aschedules_write%20spark%3Aall%20spark%3Akms&state=set_state_here";
      //const  baseUrl = `https://webexapis.com/v1/authorize?client_id=${client_Id}&response_type=code&redirect_uri=https://localhost:44389/cisco-service&scope=meeting%3Arecordings_read%20spark%3Aall%20meeting%3Aadmin_schedule_write%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20meeting%3Aadmin_schedule_read&state=set_state_here`;
      const x: any = window.open(baseUrl, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=650,screenX=600,screenY=600')
    }
  }


  toggleTabs = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }



  onChangeHandler = (e: any, keyId: number, tenantConfigureServiceId: number = 0) => {
    e.preventDefault();
    const value = e.target.value;

    if (tenantConfigureServiceId == 0) {
      // create Mode;
      const name = e.target.name;
      let { serviceKeysValue } = this.state;
      if (!serviceKeysValue) {
        serviceKeysValue = [];
      }

      const target = serviceKeysValue.find(x => x.keyId === keyId);
      if (!target) {
        serviceKeysValue.push({ keyName: name, keyValue: value, keyId: keyId });
      } else {
        serviceKeysValue.forEach((element, index) => {
          if (element.keyId === keyId) {
            element.keyName = name;
            element.keyValue = value;
          }
        })
      }

      this.setState({ serviceKeysValue });
    } else {
      // Edit_mode.

      const __editableServiceKeys__ = this.state.editableServiceKeys;
      __editableServiceKeys__.forEach((element: IEditTenantConfigureServiceModel, i) => {

        if (element.tenantConfigureServiceId == tenantConfigureServiceId) {
          element.keyValue = value;
        }


        this.setState({ editableServiceKeys: __editableServiceKeys__ }, () => {

        });

      })

    }

  }


  submitHandler = () => {


    // detect whether create_mode or edit_mode;

    if (this.state.editableServiceKeys.length > 0) {
      // edit_mode.
      const __editableServiceKeys__ = this.state.editableServiceKeys;
      const body: IEditTenantConfigureServiceModels = {
        tenantConfigureServiceModels: __editableServiceKeys__
      }

      editIntegrateWithService(body).then(_ => {
        if (this.state.isCiscoService) {
          this.handleAfterSubmit("Cd7655588bf14865cc55dc93f9d5e4c675eb81aa6a13d71e1136fb9a82567464f");
        }
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
        if (this.props.close) {
          this.props.close();
        }
      })


    } else {
      // create_mode. 

      const __keyValues__: Array<IServiceKeyValuesPair> = this.state.serviceKeysValue;

      if (__keyValues__ && __keyValues__.length > 0) {
        const body: IPostIntegrateWithService = {
          serviceId: this.props.serviceId,
          keyValues: __keyValues__
        };

        integrateWithService(body).then(res => {
          if (this.props.onUpdated) {
            this.props.onUpdated();
          }
          if (this.props.close) {
            this.props.close();
          }
        })
      }
    }




  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>
          <h5> {this.props.serviceName}</h5>
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem style={{ flex: 2 }}>
              <NavLink
                style={{ backgroundColor: '#f3f3f3' }}
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggleTabs('1'); }}
              >
                Link Data
              </NavLink>
            </NavItem>
            <NavItem style={{ flex: 2 }}>
              <NavLink
                style={{ backgroundColor: '#f3f3f3' }}
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggleTabs('2'); }}
              >
                How to link?
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <div className="mt-4">
                {
                  this.props.keys.length && this.props.keys.map((key: IServiceConfigureInfo, i: number) => {

                    return (
                      <div className="form-group" key={i}>
                        <label >  {key.keyName} </label>
                        <input type="text" className="form-control"
                          value={key.tenantConfigureServiceDto != null ? key.tenantConfigureServiceDto.keyValue : this.state.serviceKeysValue.find(x => x.keyId === key.id) ? this.state.serviceKeysValue.find(x => x.keyId === key.id)?.keyValue : ""}
                          onChange={e => {
                            this.onChangeHandler(e, key.id, key.tenantConfigureServiceDto != null ? key.tenantConfigureServiceDto.id : 0);
                            if (key.tenantConfigureServiceDto != null) {
                              key.tenantConfigureServiceDto.keyValue = e.target.value;
                            }
                          }}
                          name={key.keyName} />
                      </div>
                    );
                  })
                }
                <div className="form-group mt-4">
                  <button
                    onClick={this.submitHandler}
                    className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>


            </TabPane>
            <TabPane tabId="2">
              how to link ?
            </TabPane>
          </TabContent>

        </ModalBody>
      </Modal>
    );
  }
}

export default IntegrateWithServiceModalComponent;