/* eslint-disable react/jsx-key */
import { EmptyListState } from "components/EmptyListState";
import * as React from "react";
import { useCallback, useMemo } from "react";
import {
    DragDropContext,
    DropResult,
    Droppable,
    ResponderProvided
} from "react-beautiful-dnd";
import {
    Column,
    useExpanded,
    useGroupBy,
    useRowSelect,
    useSortBy,
    useTable
} from "react-table";
import { Table } from "reactstrap";
import {Loader, LoaderContainer} from "../Loader";
import { PaginationFooter } from "../PaginationFooter";
import DraggableTableHeader from "./DraggableTableHeader";
import { ReorderableRow } from "./ReorderableRow";
import './table.css';

interface DataTableProps {
    sort?: boolean;
    isBusy?: boolean;
    bordered?: boolean;
    striped?: boolean;
    hover?: boolean;
    columns: Column[];
    rowOrderChange?: (currentIndex: number, newIndex: number) => void;
    data: object[] | undefined | false | null;
    initialGroupBy?: string[];
    canReorderRows?: boolean;
    canSelectMultipleRows?: boolean;
    emptyState?: React.ReactNode;
    pagination?: {
        show: boolean;
        pageSize: number;
        pageIndex: number;
        totalItemsCount: number;
        onPageClicked: (page: number) => any;
        maximumPages?: number;
    };
    onClickRow?:(row:any)=>void
}

export const DataTable: React.FC<DataTableProps> = props => {
    const tableState: { [key: string]: any } = useMemo(() => {
        return {
            groupBy: props.initialGroupBy || []
        };
    }, [props.initialGroupBy]);

    const columns = useMemo(() => props.columns, [props.columns]);
    const data = useMemo(() => props.data || [], [props.data]);
    const table = useTable(
        {
            columns: columns,
            data: data,
            initialState: tableState,
            defaultColumn: {
                Cell: ctx => <>{ctx.cell.value && String(ctx.cell.value)}</>
            }
        },
        useGroupBy,
        useSortBy,
        useExpanded,
        useRowSelect
    );

    const onReorder = useCallback(
        (result: DropResult, provided: ResponderProvided) => {
            if (!props.rowOrderChange) return;

            if (
                result.reason === "DROP" &&
                result.source.index !== result.destination?.index &&
                typeof result.destination?.index === "number"
            ) {
                props.rowOrderChange(
                    result.source.index,
                    result.destination.index
                );
            }
        },
        [table, props.rowOrderChange]
    );
    return (
        <div className="table-responsive">
            <LoaderContainer>
                <Loader isLoading={props.isBusy} />

                {props.children && (
                    <div className="d-flex justify-content-between px-4 py-2">
                        <div></div>
                        <div className="my-auto">{props.children}</div>
                    </div>
                )}

                {props.data && props.data.length > 0 && (
                    <Table bordered={props.bordered} striped={props.striped} hover={props.hover} {...table.getTableProps()}>
                        <thead>
                            {table.headerGroups.map((headerGroup,index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} >
                                    {(props.canReorderRows ||
                                        props.canReorderRows) && <th></th>}
                                    {headerGroup.headers.map(column => (
                                        <DraggableTableHeader
                                            key={column.id}
                                            headerColumn={column}
                                            draggable={false}
                                        >
                                            {/*{column.render("Header" )}*/}
                                            {/*{*/}
                                            {/*    props.sort && column.canSort?*/}
                                            {/*        <span>*/}
                                            {/*            {*/}
                                            {/*                column.isSortedDesc*/}
                                            {/*                    ?  ' 🔽'*/}
                                            {/*                    : ' 🔼'*/}
                                            {/*            }*/}
                                            {/*        </span>*/}
                                            {/*        : null*/}
                                            {/*}*/}

                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </th>
                                        </DraggableTableHeader>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <DragDropContext onDragEnd={onReorder} >
                            <Droppable droppableId="tableRowDroppable" isDropDisabled={true}>
                                {(provided, snapshot) => (
                                    <tbody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        {...table.getTableBodyProps()}
                                    >
                                        {table.rows.map((row, index) => (
                                            <>
                                                {table.prepareRow(row)}

                                                <ReorderableRow
                                                  onClick={props.onClickRow}
                                                  key={`row-${index}`}
                                                    row={row as any}
                                                    showFirstCell={
                                                        !!props.canReorderRows ||
                                                        !!props.canReorderRows
                                                    }
                                                    canReorder={
                                                        !!props.canReorderRows &&
                                                        !(table.state as any)
                                                            .groupBy.length
                                                    }
                                                />
                                            </>
                                        ))}

                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                )}

                {!props.data ||
                    (!(props.data.length > 0) &&
                        !props.isBusy &&
                        props.emptyState)}
                {props.pagination && props.pagination.show && (
                    <PaginationFooter
                        onPageClicked={props.pagination.onPageClicked}
                        pageSize={props.pagination.pageSize}
                        pageIndex={props.pagination.pageIndex}
                        pageItemsCount={table.data.length}
                        totalItemsCount={props.pagination.totalItemsCount}
                        maximumPages={props.pagination.maximumPages}
                    />
                )}
            </LoaderContainer>
        </div>
    );
};

DataTable.defaultProps = {
    bordered: false,
    striped: false,
    hover: false,
    emptyState: <EmptyListState />
};
