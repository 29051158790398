import * as React from 'react';
import { useEffect, useState } from 'react';

import {
    editRolePermission,
    getRolePermissions,
    IEditRolePermission,
    IRolePermission
} from "../../api/RolePermissionApi";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";

interface ManagePermissionsProps {
    permissions : Array<IRolePermission>,
    rolePermissionEdited: (data: any)=>void;
    modeAdd?: boolean;
}

const ManagePermissions: React.FC<ManagePermissionsProps> = ({permissions, rolePermissionEdited, modeAdd}) => {

    const [rolePermissions, setRolePermissions] = useState(permissions);

    useEffect(()=>{
        if(modeAdd){
            getRolePermissions(1)
                .then(data => {

                    data.map(per=>{
                        per.canEdit = false;
                        per.canAdd = false;
                        per.canRead = false;
                        per.canDelete = false;
                        per.id = 0;
                        return per;
                    })
                    setRolePermissions(data);
                });
        } else {
            setRolePermissions(permissions)
        }
    }, [])

    const onChangeHandler = (id: number, modal: IEditRolePermission) => {
        ;
        editRolePermission(id, modal)
            .then(data => {
                rolePermissionEdited(data);
            })
            .catch(error => {
                toast.error(<ToastMessage type="Error">
                    Something went wrong while editing permission
                    Please try again later.
                </ToastMessage>)
            });
    };


    return (
        rolePermissions && rolePermissions.length > 0 ?
        <table className={'table table-md-responsive'}>
            <thead>
            <tr style={{ fontWeight: 600 }}>
                <td>
                    Module Name
                </td>
                <td>
                    Read
                </td>
                <td>
                    Add
                </td>
                <td>
                    Edit
                </td>
                <td>
                    Delete
                </td>
            </tr>
            </thead>
            <tbody>
            {rolePermissions.map((item: IRolePermission) => (
                <tr key={item.id}>
                    <td>
                        {item.moduleName}
                    </td>
                    <td>
                        <div className="form-check">
                            <input
                                onChange={e => {
                                    e.preventDefault();
                                    onChangeHandler(item.id, {
                                        canRead: e.target.checked,
                                        canAdd: item.canAdd,
                                        canEdit: item.canEdit,
                                        canDelete: item.canDelete
                                    });
                                }}
                                className="form-check-input" type="checkbox" checked={item.canRead} />
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input
                                onChange={e => {
                                    e.preventDefault();
                                    onChangeHandler(item.id, {
                                        canRead: item.canRead,
                                        canAdd: e.target.checked,
                                        canEdit: item.canEdit,
                                        canDelete: item.canDelete
                                    });
                                }}
                                className="form-check-input" type="checkbox" checked={item.canAdd} />
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input
                                onChange={e => {
                                    e.preventDefault();
                                    onChangeHandler(item.id, {
                                        canRead: item.canRead,
                                        canAdd: item.canAdd,
                                        canEdit: e.target.checked,
                                        canDelete: item.canDelete
                                    });
                                }}
                                className="form-check-input" type="checkbox" checked={item.canEdit} />
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input
                                onChange={e => {
                                    e.preventDefault();
                                    onChangeHandler(item.id, {
                                        canRead: item.canRead,
                                        canAdd: item.canAdd,
                                        canEdit: item.canEdit,
                                        canDelete: e.target.checked
                                    });
                                }}
                                className="form-check-input" type="checkbox" checked={item.canDelete} />
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        : null
    )
}

export default ManagePermissions;