/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/display-name */
import GlobalState from "Contexts/GlobalState";
import { ScrollToTopRoute } from "components/ScrollToTopRoute";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import routes, { RoutesOutlet } from "routes";
import ChangePasswordSubTenant from "scenes/ChangeSubTenantPassword/ChangePasswordSubTenant";
import { EmsSignUp } from "scenes/CreateAccount/EmsSignUp";
import { EventIdUrlContext } from "./Contexts/EventIdUrlContext";
import { EventNameContext } from "./Contexts/EventNameContext";
import { Login } from "./components/auth/Login/Login";
import { NotFound } from "./scenes/NotFound";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ForbiddenPage from "./scenes/Forbidden/Forbidden";

const App: React.FC = () => {
    const [eventId, setEventId] = React.useState<number | null>(null);
    const [eventName, setEventName] = React.useState<string>("");

    return (
        <DndProvider backend={HTML5Backend}>
            <GlobalState>
                <EventIdUrlContext.Provider value={eventId}>
                    <EventNameContext.Provider value={eventName}>

                        <ScrollToTopRoute>
                            <Routes>
                                <Route path={"/login"} element={<Login/>}/>
                                <Route path="/forbidden" element={<ForbiddenPage/>} />

                                <Route path={"/sub/signUp"} element={<EmsSignUp/>}/>
                                <Route path={"/set-password"} element={<ChangePasswordSubTenant/>}/>

                                <Route path="*" element={<NotFound />} />
                                <Route path={""}
                                       element={
                                           <RoutesOutlet setEventName={setEventName} setEventId={setEventId}/>
                                       }>
                                            {routes.map((routeData) => (
                                                <Route
                                                    key={routeData.path}
                                                    path={routeData.path}
                                                    Component={routeData.component}
                                                />
                                            ))}
                                </Route>
                            </Routes>
                        </ScrollToTopRoute>
                    </EventNameContext.Provider>
                </EventIdUrlContext.Provider>

            </GlobalState>
        </DndProvider>
    );
};

export default App;
