import Axios from "axios";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";
export interface currentRequest extends  PaginatedRequest{

}


export interface  IRole {
  id: number ;
  name:string;
}

export interface ICreateEditRoleModal {
    name:string;
}

export const  getRoles = async (request:currentRequest) : Promise<PaginatedResult<IRole>> => {
    const result = await  Axios.get<PaginatedResult<IRole>>("api/Role");
    return result.data;
}


export  const  getRole = async (id: number) : Promise<IRole> => {
    const result = await Axios.get<IRole>(`api/Role/${id}`);
    return result.data; 
}


export const createRole = async (model : ICreateEditRoleModal) : Promise<any> => {
    const result = await Axios.post<ICreateEditRoleModal>("api/Role", model);
    return result.data;
}


export const editRole = async(id:number ,model : ICreateEditRoleModal) : Promise<any> => {
    const result = await Axios.put<ICreateEditRoleModal>(`api/Role/${id}?name=${model.name}`);
    return result.data;
}

export const deleteRole = async (id:number) :  Promise<any> => {
    const result = await Axios.delete(`api/Role/${id}`);
    return result.data;
}

export const getRoleList = async () :Promise<Array<IRole>> => {
    const result = await Axios.get('api/Role/List');
    return result.data;
}