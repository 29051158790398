import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactDatetimeClass from "react-datetime";
import { FaMinusCircle, FaPlusCircle, FaRegCopy } from "react-icons/fa";
import { GetDaysOfWeek, GetSpeakerSchedule, updateSpeakerSchedule } from "../../api/Speakers";
import { toast } from "react-toastify";

interface ScheduleItem {
  from: string;
  to: string;
  dayOff?: boolean;
  breaks?: { from: string; to: string }[]
}

const SUPPORTED = false;

const TIME_FORMAT = "HH:mm:ss"

const EditSpeakerSchedules = ({ open, setOpen, speaker }: any) => {

  const [schedule, setSchedule] = useState<Record<number, ScheduleItem>>([])
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<any[]>([])

  const fetchData = async () => {
    const scheduleItems: Record<string, ScheduleItem> = {};
    const speakerSchedule = await GetSpeakerSchedule(speaker.id);
    const days = await GetDaysOfWeek();
    if(speakerSchedule.length > 0){
      speakerSchedule.forEach(value => {
        scheduleItems[value.dayOfWeek.id] = {from:value.from,to:value.to}
      })
    }else{
      days.forEach(day => {
        scheduleItems[day.id] = {
          from: "08:00:00",
          to: '16:00:00',
          dayOff: false,
          breaks: []
        };
      })
    }
    setDaysOfTheWeek(days);
    setSchedule(scheduleItems)

  }


  useEffect(() => {
    fetchData().then(r => {});

  }, [open])

  return (
    <Modal size="lg" toggle={() => setOpen(false)} isOpen={open} unmountOnClose>
      <ModalHeader toggle={() => setOpen(!open)}>
        Edit Speaker Schedule
      </ModalHeader>
      <ModalBody>
        {daysOfTheWeek.map((day, index) => {
          const value = { ...schedule[day.id] }
          return (
            <div key={day.id} className="d-flex flex-column gap-2 mb-4">
              <div className='d-flex align-items-start flex-row gap-1'>
                <span className='text-primary'>{index + 1}.{day.name}</span>
                <FaRegCopy
                  size={12}
                  className='text-secondary cursor-pointer'
                  onClick={() => {
                    const scheduleItems = { ...schedule };
                    daysOfTheWeek.forEach(keySchedule => scheduleItems[keySchedule.id] = { ...value })
                    setSchedule(scheduleItems)
                  }}
                />

              </div>
              <div className='d-flex flex-row justify-content-around align-items-center gap-2'>
                <ReactDatetimeClass
                  timeFormat={TIME_FORMAT}
                  value={value.from}
                  dateFormat={false}
                  inputProps={{ placeholder: 'Start Time', disabled: value.dayOff }}
                  onChange={date => {
                    setSchedule((prevState) => ({
                      ...prevState,
                      [day.id]: { ...value, from: moment(date).format(TIME_FORMAT) }
                    }))
                  }}
                />

                <ReactDatetimeClass
                  timeFormat={TIME_FORMAT}
                  value={value.to}
                  dateFormat={false}
                  inputProps={{ placeholder: 'End Time', disabled: value.dayOff }}
                  onChange={date => {
                    setSchedule((prevState) => ({
                      ...prevState,
                      [day.id]: { ...value, to: moment(date).format(TIME_FORMAT) }
                    }))
                  }}
                />


                {SUPPORTED && <FormGroup className='col-3' switch>
                    <Label>Day off</Label>

                      <Input
                          type="switch"

                          checked={value.dayOff}
                          onChange={e => {
                            setSchedule((prevState) => ({
                              ...prevState,
                              [day.id]: { ...value, dayOf: e.target.checked }
                            }))
                          }}

                      />
                </FormGroup>}

              </div>
              {SUPPORTED && <>
                  <div
                      className={`${value.dayOff ? 'text-secondary' : 'cursor-pointer'} d-flex align-items-center align-self-start gap-2 px-2`}
                      onClick={() => {
                        if (!value.dayOff) {
                          const newBreaks = [...(value.breaks ?? [])];
                          newBreaks.push({ from: '12:00 PM', to: '01:00 PM' })
                          setSchedule(prevState => ({
                            ...prevState,
                            [day.id]: { ...value, breaks: newBreaks }
                          }))
                        }
                      }}><FaPlusCircle size={18} className='text-secondary'/> <span>Add Break</span></div>

                {value.breaks && value.breaks.length > 0 &&
                <div className={"d-flex flex-column gap-2 "}>
                    <span>Breaks:</span>
                  {value.breaks.map((brk, index) => (
                    <div key={index} className='d-flex flex-row justify-content-between  align-items-center gap-2'>
                      <ReactDatetimeClass
                        timeFormat={TIME_FORMAT}
                        className='col-5'
                        value={brk.from}
                        dateFormat={false}
                        inputProps={{ placeholder: 'Start Time', disabled: value.dayOff }}
                        onChange={date => {
                          const newBreaks = [...(value.breaks ?? [])];
                          newBreaks[index].from = moment(date).format(TIME_FORMAT);
                          setSchedule(prevState => ({
                            ...prevState,
                            [day.id]: { ...value, breaks: newBreaks }
                          }))
                        }}
                      />
                      <ReactDatetimeClass
                        timeFormat={TIME_FORMAT}
                        className='col-5'
                        value={brk.to}
                        dateFormat={false}
                        inputProps={{ placeholder: 'End Time', disabled: value.dayOff }}
                        onChange={date => {
                          const newBreaks = [...(value.breaks ?? [])];
                          newBreaks[index].to = moment(date).format(TIME_FORMAT);
                          setSchedule(prevState => ({
                            ...prevState,
                            [day.id]: { ...value, breaks: newBreaks }
                          }))
                        }}
                      />
                      <FaMinusCircle
                        onClick={() => {
                          if (!value.dayOff) {
                            const newBreaks = [...(value.breaks ?? [])];
                            newBreaks.splice(index, 1)
                            setSchedule(prevState => ({
                              ...prevState,
                              [day.id]: { ...value, breaks: newBreaks }
                            }))
                          }
                        }}
                        size={18}
                        className={`text-secondary col-2 ${value.dayOff ? '' : 'cursor-pointer'}`}/>
                    </div>
                  ))
                  }
                </div>}
              </>}
            </div>

          )
        })}

      </ModalBody>

      <ModalFooter>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => {
          const scheduleToEdit = { ...schedule }
          const scheduleData = {
            schedule: daysOfTheWeek.map(value => {
              delete scheduleToEdit[value.id].dayOff
              delete scheduleToEdit[value.id].breaks
              return { ...scheduleToEdit[value.id], dayOfWeekId: value.id }
            })
          }
          updateSpeakerSchedule(scheduleData, speaker.id).then(value => {
            toast.success("Speaker Schedule updated successfully")
            setOpen(false)
          }).catch(reason => {
            toast.error("Error while updating speaker schedule")
          })
        }}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )

}

export default EditSpeakerSchedules;