/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import * as React from 'react';
import {
    Input, InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

import { Loader } from 'components/Loader';
import RecipientGroupSelectorComponent from 'components/ReceipientGroup/RecipientGroupSelectorComponent';
import { ToastMessage } from 'components/ToastMessages';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { SendSMS, SendSmsRequest } from '../../api/MobileMessageApi';
import { IRecipientListDropdownListWithPhone, getDropdownListWithPhone } from '../../api/RecipientLists';
const SendSMSModal: React.FC<any> = ({ isOpen, close }) => {

    const [recepient, SetRecepient] = React.useState<Array<IRecipientListDropdownListWithPhone>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [recipientSelected, setRecipientSelected] = React.useState<Array<{ label: string, value: number, excluded?: Array<{ id: number }> }> | null>(null);
    const [body, setBody] = React.useState<string | null>(null);
    const [anonymousUser, setAnonymousUser] = React.useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = React.useState<Array<{ index: number, value: string }> | null>(null);
    const [phoneNumberInputLength, setPhoneNumberInputLength] = React.useState<number>(1);
    const getReceipientHandler = React.useCallback(async () => {
        setLoading(true);
        const data = await getDropdownListWithPhone();
        setLoading(false);
        SetRecepient(data ?? []);

    }, [SetRecepient, setLoading]);


    React.useEffect(() => {
        getReceipientHandler();
    }, [])


    const sendSMSHandler = React.useCallback(async () => {
        try {
            const recipientList: Array<{ recipientListId: number, exclude: Array<number> | null }> = recipientSelected?.map(x => {
                return {
                    recipientListId: x.value,
                    exclude: x.excluded != null ? x.excluded?.map(v => v.id) : null
                }
            }) ?? []
            const payload: SendSmsRequest = {
                recipientListExcludeRecipientItems: recipientList,
                body: body ?? "",
                phoneNumbers: phoneNumber && phoneNumber.length > 0 ? phoneNumber.map(x => x.value) : undefined
            }
            setLoading(true);

            const response = await SendSMS(payload);
            setLoading(false);

            ;
            if (response && response.length > 0 && response.some((x: any) => x.status === "Failed")) {

                response.forEach((res: any) => {
                    res.status === "Failed" ?

                        toast.error(<ToastMessage>
                            {res.message}
                        </ToastMessage>) :
                        toast.success(<ToastMessage>
                            {res.message}
                        </ToastMessage>)
                });

            }

        } catch (_) {

            toast.error(<ToastMessage>
                Error has been happened
            </ToastMessage>)
        }



    }, [recipientSelected, body])

    return (
        <Modal isOpen={isOpen} size="lg" unmountOnClose>

            <ModalHeader toggle={close}> Send SMS </ModalHeader>
            <ModalBody>
                {
                    loading ?
                        <Loader isLoading={true} />
                        : null
                }
                <div>
                    <Label>
                        Send to anonymous user
                    </Label>
                    <Input
                        onChange={e => {
                            setAnonymousUser(!anonymousUser)
                            setRecipientSelected(null);
                            setPhoneNumber(null);

                        }}
                        checked={anonymousUser}
                        type="switch" id="anonymous" name="anonymous" />
                </div>
                {
                    anonymousUser ?
                        <div className="form-group mt-4">
                            <Label>Phone numbers</Label>
                            {
                                [...Array(phoneNumberInputLength)].map((x, i) => {
                                    return (
                                        <InputGroup>
                                            <input type="tel"
                                                onChange={e => {
                                                    const { value } = e.target;
                                                    if (phoneNumber != null) {
                                                        let item = phoneNumber.find(x => x.index == i);
                                                        if (!item) item = { index: i, value };
                                                        else item.value = value;
                                                        setPhoneNumber([...phoneNumber, item]);
                                                    } else {
                                                        setPhoneNumber([{ index: i, value }]);
                                                    }

                                                }}
                                                className="form-control" name="phonenumber" />
                                            <button onClick={e => {
                                                setPhoneNumberInputLength(phoneNumberInputLength + 1);
                                            }} className="btn btn-outline-success">
                                                <small className="d-flex"> <i className="mr-2"> <FaPlus /> </i> Add </small>
                                            </button>
                                            {
                                                i > 0 ?
                                                    <button onClick={e => {
                                                        setPhoneNumberInputLength(phoneNumberInputLength - 1);
                                                    }} className="btn btn-outline-danger">
                                                        <small className="d-flex"> <i className="mr-2"> <FaTrash /> </i> Remove </small>
                                                    </button>
                                                    : null
                                            }
                                        </InputGroup>
                                    );
                                })
                            }

                        </div>
                        :
                        //        <div className="form-group mt-4">
                        //        <label htmlFor=""> Select Receipient  </label>
                        //        <Select
                        //          isMulti={true}
                        //          onChange={ (options: any) => {
                        //
                        //              setRecipientSelected(options);
                        //          }}
                        //          value={recipientSelected}
                        //          options={recepient.map(x => {return {label: `${x.name} (${x.phoneNumber})` , value: x.id}})}
                        //         />
                        //    </div>
                        <RecipientGroupSelectorComponent onChange={(output) => {
                            ;
                            setRecipientSelected(output);
                        }} />
                }


                <div className="form-group">
                    <label htmlFor=""> SMS Body </label>
                    <textarea
                        rows={7}
                        className="form-control"
                        onChange={e => {
                            const { value } = e.target;
                            setBody(value);
                        }}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                    <button onClick={close} className="btn btn-secondary">
                        Cancel
                    </button>
                    <button
                        onClick={sendSMSHandler}
                        className="btn btn-success">
                        Send
                    </button>
            </ModalFooter>
        </Modal>
    );
}

export default SendSMSModal;