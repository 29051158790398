/* eslint-disable react/no-deprecated */
import moment from "moment";
import * as React from 'react';
import ReactDatetime from "react-datetime";
import { AiOutlineArrowLeft, AiOutlineSetting } from "react-icons/ai";
import Select from "react-select";
import { toast } from "react-toastify";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import { getEventsDropdownlist, IEventDropdownList } from "../../api/Events";
import { getSessionsDropdownList, ISessionDropdownList } from "../../api/Sessions";
import { getAvailableTimeZones } from "../../api/TimeZoneApi";
import { createWebinar, ICreateWebinar, IWebinarRecurrenceDto, WebinarType } from "../../api/WebinarApi";
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";
import WebinarRecurrenceSetting from "./WebinarRecurrenceSetting";

interface ICreateWebinarProps {
    isOpen: boolean;
    close?: () => unknown;
    onUpdated?: () => unknown;
}

interface ICreateWebinarState {
    __formValid__: boolean;
    __loading__: boolean;
    data: ICreateWebinar,
    openRecurrenceSetting: boolean,
    timeZones: { label: string, value: string }[],
    events: Array<IEventDropdownList>,
    displayEvents: boolean,
    sessions: Array<ISessionDropdownList>,
    isCreateSession: boolean,
    showCreateSessionForm: boolean,
    displayRecurrenceSetting: boolean

}




class CreateWebinarMeetingModal extends React.Component<ICreateWebinarProps, ICreateWebinarState> {
    constructor(props: ICreateWebinarProps) {
        super(props);
        this.state = {
            __formValid__: false,
            __loading__: false,
            data: {
                duration: 0,
                startTime: '',
                eventId: null,
                password: null,
                topic: '',
                type: WebinarType.Webinar,
                webinarRecurrence: null,
                timeZone: null,
                createSession: false,
            },
            openRecurrenceSetting: false,
            timeZones: [],
            events: [],
            displayEvents: false,
            sessions: [],
            isCreateSession: false,
            showCreateSessionForm: false,
            displayRecurrenceSetting: false

        }
    }

    componentWillMount() {
        const { data } = this.state;
        data.startTime = moment(new Date()).toJSON();
        this.setState({ data })
        this.fetchEventsHandler();
    }

    openRecurrenceSettingToggleHandler = () => {
        const { openRecurrenceSetting } = this.state;
        this.setState({ openRecurrenceSetting: !openRecurrenceSetting });
    }

    changeValueHandler = (e: any) => {
        const name: string = e.target.name;
        const value = e.target.value;

        let { data } = this.state;
        data = { ...data, [name]: value };
        this.setState({ data })
    }



    // Fetch TimeZones...

    fetchTimeZonesHandler = () => {
        getAvailableTimeZones().then(data => {
            const timeZones = data.map(item => {
                return { label: item, value: item }
            })
            this.setState({ timeZones })
        })
    }

    // onSubmit

    onSubmitHandler = () => {
        this.setState({ __loading__: true })
        const { data } = this.state;
        data.createSession = this.state.isCreateSession;
        createWebinar(data).then(res => {
            this.setState({ __loading__: false })
            this.props.close?.();
            this.props.onUpdated?.();
        }).catch(error => {
            this.setState({ __loading__: false })
            if (error && error.response?.data) {
                toast.error(
                    <ToastMessage type="Error">
                        {error.response?.data.detail}
                    </ToastMessage>
                );
            }
        })
    }


    // Fetch Sessions;

    fetchEventsHandler = () => {
        this.setState({ __loading__: true })
        getEventsDropdownlist()
            .then(events => {
                this.setState({ __loading__: false })
                this.setState({ events })
            }).catch(error => {
                this.setState({ __loading__: false })
            })
    }


    SetInSessionHandler = () => {
        const { data } = this.state;
        data.eventId = 0;
        this.setState({ isCreateSession: false, showCreateSessionForm: false, data });
        if (this.state.events.length == 0)
            this.fetchEventsHandler();
    }


    // create session handling
    createSessionOnChange = (e: any) => {
        const { data } = this.state;
        data.eventId = 0;
        this.setState({ isCreateSession: true, data, displayEvents: true }, () => {

            if (this.state.events.length === 0) {
                this.fetchEventsHandler();
            }

        })
    }


    onChangeEventHandler = (e: any) => {
        e.preventDefault();
        const eventId = +e.target.value;
        if (!isNaN(eventId) && eventId > 0) {
            const { data } = this.state;
            data.eventId = eventId;
            this.setState({ data }, () => {
                if (this.state.isCreateSession) {
                    this.setState({ showCreateSessionForm: true })
                } else {
                    this.fetchSessions();
                }

            })
        }
    }

    // fetch Sessions

    fetchSessions = () => {
        this.setState({ __loading__: true })
        if (this.state.data.eventId != null && this.state.data.eventId != 0) {
            // ready to fetch sessions.
            getSessionsDropdownList(this.state.data.eventId)
                .then(sessions => {
                    this.setState({ sessions, __loading__: false })
                }).catch(error => {
                    this.setState({ __loading__: false })
                })
        }
    }


    onChangeSessionHandler = (e: any) => {
        e.preventDefault();
        const sessionId = e.target.value;
        if (sessionId && sessionId > 0) {
            const data = this.state.data;
            if (data) {
                data.sessionId = sessionId;
            }

            this.setState({ data: data });
        }
    }

    onConfirmSettingRecurHandler = (settings: IWebinarRecurrenceDto) => {
        const { data } = this.state;
        data.webinarRecurrence = settings;
        this.setState({ data }, () => {
            ;
            this.openRecurrenceSettingToggleHandler();
        });
    }
    render() {

        const hoursOption = Array.from({ length: 12 }, (v, i) => {
            return {
                value: i + 1,
                label: `${i + 1} hours`
            }
        });
        return (
            <Modal size={"lg"} unmountOnClose isOpen={this.props.isOpen} toggle={this.props.close}>
                <ModalHeader toggle={this.props.close}>
                    Create Webinar Meeting
                </ModalHeader>
                <ModalBody>

                    {
                        this.state.openRecurrenceSetting ?
                            <WebinarRecurrenceSetting isOpen={this.state.openRecurrenceSetting} close={this.openRecurrenceSettingToggleHandler} onConfirm={this.onConfirmSettingRecurHandler} /> : null
                    }
                    <div className={'form-group'}>
                        <label> Topic </label>
                        <input className={'form-control'}
                            onBlur={this.changeValueHandler}
                            type={'text'} name={'topic'} placeholder={'ex. meeting title'} />
                    </div>
                    <div className="form-group">
                        <label> Meeting type </label>
                        <select

                            onChange={e => {
                                const value = +e.target.value;
                                const { data } = this.state;
                                data.type = value as WebinarType;
                                this.setState({ data })
                            }}

                            className={'form-control'} name={"type"}>
                            <option  > Select Type </option>
                            <option value={5} selected={true}> Webinar </option>
                            <option value={6}> Recurring With Fixed Time </option>
                            <option value={9}> Recurring Without Fixed Time </option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label> Time Zone </label>
                        <Select
                            isMulti={false}
                            options={this.state.timeZones}
                            onMenuOpen={this.fetchTimeZonesHandler}
                            onChange={(v: any) => {
                                let data = this.state.data;
                                data.timeZone = v.value;
                                this.setState({ data });
                            }}
                        />
                    </div>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label > Start Data * </label>
                                <ReactDatetime
                                    value={this.state.data.startTime !== '' ? moment(this.state.data.startTime) : moment(new Date())}
                                    onChange={(
                                        dateTime: moment.Moment | string
                                    ) => {
                                        const date = moment(dateTime).toJSON();
                                        let data = this.state.data;
                                        if (data) {
                                            data.startTime = date;
                                        }

                                        this.setState({ data: data }, () => {
                                            ;
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label > Duration of Meeting * </label>

                                <Select
                                    isMulti={false}
                                    options={hoursOption}
                                    onChange={(v: any) => {
                                        let data = this.state.data;
                                        if (data) {
                                            data.duration = v.value;
                                        }
                                        this.setState({ data }, () => {
                                            
                                        })
                                    }}
                                />

                            </div>
                        </Col>
                    </Row>


                    <div className="form-group d-flex justify-content-center">
                        <div className="form-check">
                            <label className="form-check-label mr-4"

                                htmlFor="exampleCheck1"> Set In Session </label>
                            <input type="radio"
                                onChange={e => {
                                    if (e.target.checked) {
                                        this.SetInSessionHandler();
                                    }
                                    this.setState({ displayEvents: e.target.checked })
                                }}
                                className="form-check-input" name={'session'} />
                        </div>

                        <div className="form-check">
                            <label className="form-check-label mr-4"

                                htmlFor="exampleCheck1"> Create Session </label>
                            <input type="radio"
                                onChange={this.createSessionOnChange}
                                className="form-check-input" name={'session'} />
                        </div>
                    </div>

                    {
                        this.state.events.length > 0 && this.state.displayEvents ?
                            <div className={"form-group"}>
                                <label>
                                    Choose An event *
                                </label>
                                <select
                                    className={"form-control"}
                                    onChange={this.onChangeEventHandler}
                                    value={this.state.data.eventId ?? 0}
                                >
                                    <option value={0}> Select event </option>
                                    {
                                        this.state.events.map((event, key) => {
                                            return (
                                                <option key={key} value={event.id}>
                                                    {event.name}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            : null
                    }



                    {
                        this.state.sessions.length > 0 && this.state.displayEvents && this.state.data.eventId != null && this.state.data.eventId > 0 && !this.state.isCreateSession ?
                            <div className={"form-group"}>
                                <label>
                                    Sessions
                                </label>
                                <select
                                    onChange={this.onChangeSessionHandler}
                                    className={"form-control"}>
                                    <option  > Select Session </option>
                                    {
                                        this.state.sessions.map((session, key) => {
                                            return (
                                                <option key={key} value={session.id}>
                                                    {session.name}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>

                            : null
                    }


                    <div className="form-group">
                        <label > Password  <small> (Optional) </small> </label>
                        <input type="text"
                            onBlur={this.changeValueHandler}
                            name="password" className={'form-control'} />
                    </div>
                    {
                        this.state.data.type === WebinarType.RecurringWithFixedTime ?
                            <div className={'form-group'}>
                                <label> Want to make meeting recur ? </label>
                                <button className={'btn btn-small btn-success mx-2'}
                                    onClick={this.openRecurrenceSettingToggleHandler}
                                >
                                    <i> <AiOutlineSetting /></i>
                                </button>
                            </div>
                            : null
                    }
                </ModalBody>
                <ModalFooter>
                        <button className="btn btn-secondary"
                                onClick={this.props.close}
                        >Cancel </button>
                        <button
                            onClick={this.onSubmitHandler}
                            className="btn btn-primary" > Save </button>

                    {
                        this.state.__loading__ ? <Loader isLoading={true} /> : null
                    }
                </ModalFooter>
            </Modal>
        );
    }
}

export default CreateWebinarMeetingModal;