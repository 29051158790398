import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICategoryDropdownItem, SearchCategories } from 'api/ProductCategoriesApi';
import { getTaxesDropdownList, ITax } from 'api/TaxApi';
import { FaSpinner } from 'react-icons/fa';
import Select from "react-select";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { nameof } from 'util/nameof';
import {
  fetchAddonProductsBy,
  fetchAddonsList,
  fetchProduct,
  fetchProductItemAddonsBy,
  fetchProductMedia,
  IProductDropdownItem,
  ISearchItem,
  Product,
  ProductType,
  SearchItemsForAddons,
  SessionProduct,
  Ticket,
  UpdateJsonAddonProduct,
  UpdateJsonItemProduct,
  UpdateJsonSessionProduct,
  UpdateJsonTicketProduct,
  UploadProductMedia
} from '../../api/ProductsApi';
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessages";
import { searchSpeakers, Speaker } from "../../api/Speakers";
import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";
import { SpeakerSelectItem } from "../ManageEventSessions/AddEditSessionFormModal";
import { GetLocationsDropdownList, ILocationDropdownItem } from "../../api/LocationsApi";
import { FormatOptionLabelMeta } from "react-select/base";
import { StringTruncate } from "../../components/StringTruncate";
import {
  extractProductAddon,
  extractProductItem,
  extractProductSession,
  extractProductTicket
} from "../../util/productsUtil";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import AsyncSelect from "react-select/async";

interface OwnProps {
  item?: Product,
  isOpen: boolean,
  close: (success: boolean) => void
}

interface ProductImage {
  id: number | null,
  path: string
}

const EditProductComponent: React.FC<OwnProps> = (props) => {
  const [loadingDependencies, setLoadingDependencies] = useState(true);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<any>(props.item);
  const [categories, setCategories] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [taxData, setTaxData] = useState<ITax[]>([]);
  const [previewImages, setPreviewImages] = React.useState<ProductImage[]>([]);
  const [imagesFiles, setImagesFile] = React.useState<Array<File>>([]);
  const [imagesChanged, setImagesChanged] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);

  const [selectSpeakerListState, setSelectSpeakerListState] = useState<SpeakerSelectItem[]>([]);

  const [itemsForAddons, setItemsForAddons] = useState<ISearchItem[]>([]);
  const [addonsList, setAddonsList] = useState<IProductDropdownItem[]>([]);

  const [locationsList, setLocationsList] = useState<ILocationDropdownItem[]>([]);

  const [speakers, setSpeakers] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [addons, setAddons] = useState<any[]>([]);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  React.useEffect(() => {
    setProduct((old: Product) => {
      return { ...old, images: imagesFiles } as Product
    })
  }, [imagesFiles])

  useEffect(() => {
    if (!props.isOpen) return;
    if (props.item && props.item?.id) {
      const productId = props.item.id;

      const pcType = props.item.category.pcType;
      /**
       * this will return :
       *      ProductItems in case category pcType = Item
       *      ProductSessions in case category pcType = Session
       *      ProductTickets in case category pcType = Ticket
       *      ProductAddons in case category pcType = Addon
       */
      const endpointUrl = `Product${pcType}s`

      // let url = "ProductItems";
      const categoryId = props.item.category.id;
      let assignedToProducts: any[] = [];
      let assignedToEvents: any[] = [];
      let assignedToAddons: any[] = [];
      if (pcType === "Addon") {
        itemsForAddonsFetching(null);
        fetchAddonProductsBy(productId).then(relatedProduct => {
          if (relatedProduct.items && relatedProduct.items.length > 0) {
            assignedToProducts = relatedProduct.items.map(rp => rp.id);
            setProduct((prev: any) => ({
              ...prev, assignedToProducts: assignedToProducts
            }))
          }
        })
        // url = "ProductAddons";
        // setProductUrl(endpointUrl);
      }
      if (pcType === "Session") {
        // url = "ProductSessions";
        // setProductUrl("ProductSessions");
        fetchProductItemAddonsBy(productId, 'ProductSessions/SessionAddonsBy').then(assignedAddons => {
          if (assignedAddons.items && assignedAddons.items.length > 0) {
            assignedToAddons = assignedAddons.items.map(rp => rp.id);
            setProduct((prev: any) => ({
              ...prev, assignedAddons: assignedToAddons
            }))
            setAddons(assignedAddons.items)
          }
        })
      }
      if (categoryId === 1) {
        fetchProductItemAddonsBy(productId).then(assignedAddons => {
          if (assignedAddons.items && assignedAddons.items.length > 0) {
            assignedToAddons = assignedAddons.items.map(rp => rp.id);
            setAddons(assignedAddons.items)
            // setTimeout(()=> {
            //     setProduct({...product, assignedAddons: assignedToAddons})
            // },0);
            setProduct((prevState: any) => {
              return { ...prevState, assignedAddons: assignedToAddons }
            })
          }
        })
      }

      fetchProduct(productId, endpointUrl).then(prod => {

        const price = prod.price;
        let instructors: any[] = [];
        let locations: any[] = [];
        if (price.taxes && price.taxes.length > 0) {
          price.taxesIds = [...price.taxes.map((t: { id: any; }) => t.id)];
        }
        if (prod.speakers && prod.speakers.length > 0) {
          instructors = prod.speakers.filter((it: any) => it != null).map(mapSpeakerToSpeakerSelectItem);
          setSpeakers(instructors)
        }
        if (prod.locations && prod.locations.length > 0) {
          locations = prod.locations.filter((it: any) => it != null);
          setLocations(locations);
        }
        if (prod.events && prod.events.length > 0) {
          assignedToEvents = prod.events.filter((it: any) => it != null).map((sp: any) => sp.id);
        }
        if (prod.event && prod.event.id) {
          assignedToEvents = [prod.event.id]
        }

        setProduct((prevState: any) => {
          return {
            ...prevState, ...prod,
            category: prod.category,
            price: price,
            instructors: instructors,
            locations: locations,
            assignedToProducts: assignedToProducts,
            assignedEvents: assignedToEvents,
            assignedAddons: assignedToAddons,
          }
        });
      })

      fetchProductMedia(productId).then(value => {

        setProduct((prevState: any) => ({
          ...prevState,
          images: value
        }))

        setPreviewImages(value.map((image: any) => ({
          id: image.mediaDto.id,
          path: image.mediaDto.fullUrl
        })))

      })


    }
  }, [props.item, props.isOpen])

  const loadDependencies = async () => {

    await fetchProductCategories();
    await fetchTaxes();
    await speakersFetching();
    // productsFetching();
    await locationsFetching();
    await addonsFetching();

  }

  useEffect(() => {
    if (props.isOpen) {
      loadDependencies().then(() => setLoadingDependencies(false));
    }
  }, [props.isOpen])

  useEffect(() => {

    if (product?.images) {
      const images = product.images as any[];
      // setPreviewImages(images.map(im => im.fullUrl));
      setImagesFile(product.images)
    }

  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { name, value } = e.target;
    setProduct((old: any) => {

      if (!old) {
        old = {};
      }
      old = { ...old, [name]: value }
      return old;
    });
  }

  const handleCategoryChange = (cat: any) => {
    if (cat.value === ProductType.addon_on) itemsForAddonsFetching(null);
  }

  const renderSpeakerOption =
    (
      option: SpeakerSelectItem,
      labelMeta: FormatOptionLabelMeta<SpeakerSelectItem>
    ) => (
      <div className="d-flex align-items-center">
        {option.profilePic ? (
          <img
            src={option.profilePic.fullUrl}
            width={30}
            height={30}
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        ) : (
          <div
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        )}
        <div>
          {option.label}
          <div className="text-muted small">
            <StringTruncate
              text={option.bio || ""}
              truncateSize={30}
            />
          </div>
        </div>
      </div>
    )

  const mapSpeakerToSpeakerSelectItem = (
    speaker: Speaker
  ): SpeakerSelectItem => {
    return {
      label: `${speaker.title || ""} ${speaker.firstName
      } ${speaker.middleName || ""} ${speaker.lastName}`,
      value: speaker.id,
      bio: speaker.bio,
      profilePic: speaker.profilePicture || null
    };
  };


  const speakersFetching = () => {
    searchSpeakers()
      .then(data => setSelectSpeakerListState(data.map(mapSpeakerToSpeakerSelectItem)))
  }

  const addonsFetching = () => {
    fetchAddonsList().then(products => setAddonsList([...products])).catch(err => {
    })
  }


  const itemsForAddonsFetching = (text: string | null) => {
    if (text && text.length > 2 || text == null)
      SearchItemsForAddons(text ?? "").then(items => {
        setItemsForAddons(items);
      })
  }

  const locationsFetching = () => {
    GetLocationsDropdownList().then(data => setLocationsList([...data])).catch(err => {
    });
  };


  const fetchProductCategories = () => {
    if (!categories || categories.length === 0)
      SearchCategories(true).then(data => {
        if (data) {
          const fetchedCategories = data.map((item: ICategoryDropdownItem) => {
            return {
              value: item.id,
              label: item.name
            };
          })
          setCategories([...fetchedCategories]);
        }
      })
  }

  const fetchTaxes = () => {
    getTaxesDropdownList().then(data => {
      if (data) {
        const fetchedTaxes = data.map((item: ITax) => {
          return {
            value: item.id,
            label: `${item.name} (${item.value}%)`
          };
        });

        setTaxes(fetchedTaxes);
        setTaxData(data);
      }
    })
  }

  const calcTotalPrice = () => {
    if (product) {
      const _taxes: Array<ITax> = [];
      if (!product?.price) return;

      const amount = product.price.amount;
      const discount = product?.price.discount;
      const discountPercentage = product?.price.isDiscountPercentage;
      let totalTax = 0;
      // let discount = 0;
      if (product?.price.taxesIds) {
        for (let i = 0; i < product?.price.taxesIds.length; i++) {
          let _tax = taxData.find((item: ITax) => item.id === product?.price.taxesIds[i]);
          if (_tax) {
            // discount = _tax.rate;
            _taxes.push(_tax)
          }
        }

        _taxes.forEach((tax: ITax) => {
          totalTax += tax.value;
        })

      }
      let totalPrice = amount;

      if (discount) {
        if (discountPercentage) totalPrice -= amount * discount / 100;
        else totalPrice -= discount;
      }

      if (totalTax > 0) {
        totalPrice = +(+totalPrice * (1 + (totalTax / 100))).toFixed(2);
      }

      const price = product?.price;
      // price.amount = amount;
      price.totalPrice = totalPrice;
      price.displayTotalPrice = "" + totalPrice;
      setProduct((prev: any) => ({ ...prev, price: price }))
    }
  }

  const handleProductEdition = () => {
    let editPromise = null;

    const productCategory = product?.category;
    const pcType = productCategory.pcType as any;
    if (pcType) {
      setLoading(true);
      editPromise = editProductHandler(pcType);
    }

    editPromise && editPromise.then((resp: any) => {
      if (imagesChanged) {

        const existingImages = product?.images.map((image: any) => ({ id: image.mediaDto.id }))
        UploadProductMedia(product?.id, previewImages, existingImages ?? props.item?.images).then(r => {
          setLoading(false);
          toast.success(
            <ToastMessage>
              Product edited successfully !
            </ToastMessage>
          );
          props.close(true);
        }).catch(reason => {
          toast.error(
            <ToastMessage>
              {reason.response?.data?.detail ?? "Error while Updating Images"}
            </ToastMessage>
          );
        });
      } else {
        setLoading(false);
        toast.success(
          <ToastMessage>
            Product edited successfully !
          </ToastMessage>
        );
        props.close(true);
      }
    }).catch((err: any) => {
      setLoading(false)
      toast.error(
        <ToastMessage>
          Error editing product, please try again later
        </ToastMessage>
      );
    }).finally(() => setLoading(false))

  }

  const editProductHandler = (pcType: any): Promise<any> => {
    if (pcType.name && pcType.name === "Addon" || pcType === "Addon") {
      const productAddon = extractProductAddon(product, product?.category.id);
      return UpdateJsonAddonProduct(product?.id, productAddon);
    } else if (pcType.name && pcType.name === "Session" || pcType === "Session") {
      const productSession = extractProductSession(product, product?.category.id, speakers, locations);
      return UpdateJsonSessionProduct(product?.id, productSession);
    } else if (pcType.name && pcType.name === "Ticket" || pcType === "Ticket") {
      const productTicket = extractProductTicket(product, locations[0]);
      return UpdateJsonTicketProduct(product?.id, productTicket);
    } else {//Product Item by default
      const productItem = extractProductItem(product);
      return UpdateJsonItemProduct(product?.id, productItem);
    }
  }


  return (
    <Modal
      size="lg"
      unmountOnClose
      isOpen={props.isOpen}
      toggle={() => props.close(false)}
    >
      {loading ? <Loader isLoading={true}/> : null}
      <ModalHeader toggle={() => props.close(false)}>
        Edit Product ({props.item?.name ?? ""})
      </ModalHeader>
      {loadingDependencies ? <FaSpinner className="icon-spin"/> : <ModalBody>
        <FormGroup>
          <Label> Name </Label>
          <Input type="text" name={nameof<Product>("name")}
                 onChange={onChangeHandler}
                 value={product?.name ?? ""}/>
        </FormGroup>
        {/*<FormGroup>
          <Label> Name Arabic</Label>
          <Input type="text" name={nameof<Product>("nameAr")}
                 onChange={onChangeHandler}
                 value={product?.nameAr ?? ""}/>
        </FormGroup>*/}
        <FormGroup>
          <Label> Description </Label>
          <Input type="text" name={nameof<Product>("description")}
                 onChange={onChangeHandler}
                 value={product?.description ?? ""}/>
        </FormGroup>

        <FormGroup>
          <Label> Category </Label>
          <Select
            isDisabled
            isMulti={false}
            value={categories.find((c: { value: number, label: string }) => c.value === product?.category.id)}
            options={categories}
          />
        </FormGroup>


        {product && product.category && (product.category.id != 3) &&
        <>
            <Row>
                <Col sm={12} md={6} xl={6}>
                    <FormGroup>
                        <Label>Availability Start Date Time</Label>
                        <ReactDatetime
                            dateFormat={"DD/MM/YYYY"}
                            value={product?.availabilityStartDateTime ? moment(product.availabilityStartDateTime) : undefined}
                            onChange={value => {
                              setProduct((prev: any) => ({
                                ...prev,
                                availabilityStartDateTime: moment(value).toISOString()
                              }))
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    <FormGroup>
                        <Label> Availability End Date Time </Label>
                        <ReactDatetime
                            dateFormat={"DD/MM/YYYY"}
                            value={product?.availabilityEndDateTime ? moment(product.availabilityEndDateTime) : undefined}
                            onChange={(value: Moment | string) => {
                              setProduct((prev: any) => ({
                                ...prev,
                                availabilityEndDateTime: moment(value).toISOString()
                              }))
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>


        </>
        }
        {
          product && product.category &&
          (product.category.id === 2) &&
          <>
              <FormGroup>
                  <Label>
                      Locations
                  </Label>
                  <AsyncSelect
                      isClearable
                      isMulti={false}
                      defaultOptions={locationsList}
                      value={locations[0] ?? undefined}
                      onChange={options => {
                        if(options) {
                          const selected = options.id;
                          setLocations([options]);
                          setProduct((prev: any) => ({
                            ...prev, locations: [selected]
                          }))
                        }else{
                          setLocations([]);
                          setProduct((prev: any) => ({
                            ...prev, locations: []
                          }))
                        }
                      }}
                      className="form-control flex-2"
                      placeholder="Select Location"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      loadOptions={async (val) => {
                        return await GetLocationsDropdownList(val);
                      }}

                  />
                {/*<Select
                      isMulti={true}
                      options={locationsList}
                      value={locationsList.filter(loc => locations?.includes(loc.id))}
                      onChange={options => {
                        const selected = options.map(op => op.id);
                        setLocations([...selected]);
                        setProduct({ ...product, locations: selected })
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id + ""}
                  />*/}
              </FormGroup>
              <Row>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Start Date Time</Label>
                          <ReactDatetime
                              dateFormat={"DD/MM/YYYY"}
                              value={(product as Ticket | SessionProduct).startDateTime ? moment((product as Ticket | SessionProduct).startDateTime) : undefined}
                              onChange={value => {
                                setProduct((old: any) => {
                                  if (!old) old = {};
                                  old = old as Ticket | SessionProduct;
                                  old.startDateTime = moment(value).toDate();
                                  return old;
                                })
                              }}
                          />
                      </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label> End Date Time </Label>
                          <ReactDatetime
                              dateFormat={"DD/MM/YYYY"}
                              value={(product as Ticket | SessionProduct).endDateTime ? moment((product as Ticket | SessionProduct).endDateTime) : undefined}
                              onChange={(value: Moment | string) => {
                                setProduct((old: any) => {
                                  if (!old) old = {};
                                  old = old as Ticket | SessionProduct;
                                  old.endDateTime = moment(value).toDate();
                                  return old;
                                })
                              }}
                          />
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Limit Per Day</Label>
                          <Input type="number" name={nameof<Ticket>("perDayLimit")}
                                 value={(product as Ticket).perDayLimit}

                                 onChange={e => {
                                   e.preventDefault();
                                   const value = e.target.value as unknown as number;
                                   setProduct((prevState: any) => {
                                     let newState: any = {}
                                     if (prevState) newState = { ...prevState }
                                     newState.perDayLimit = value;
                                     return newState;
                                   })
                                 }}/>
                      </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                      <FormGroup>
                          <Label>Days Limit</Label>
                          <Input type="number" name={nameof<Ticket>("daysNoLimit")}
                                 value={(product as Ticket).daysNoLimit}
                                 onChange={e => {
                                   e.preventDefault();
                                   const value = e.target.value as unknown as number;
                                   setProduct((prevState: any) => {
                                     let newState: any = {}
                                     if (prevState) newState = { ...prevState }
                                     newState.daysNoLimit = value;
                                     return newState;
                                   })
                                 }}/>
                      </FormGroup>
                  </Col>
              </Row>
          </>
        }

        {product && product?.category && (product?.category.id === 4 || product?.category.id === 5 || product?.category.id === 6) &&
        <>
            <FormGroup>
                <Label>
                    Instructors
                </Label>
                <AsyncSelect
                    isClearable
                    isMulti
                    defaultOptions={selectSpeakerListState}
                    value={speakers}
                    onChange={options => {
                      const selected = options.map(op => op.value);
                      setSpeakers(options as any[]);
                      setProduct((prev: any) => ({
                        ...prev, instructors: selected
                      }))
                    }}
                    className="form-control flex-2"
                    placeholder="Select Instructors"
                    formatOptionLabel={renderSpeakerOption}
                    loadOptions={async (val) => {
                      return (await searchSpeakers(val)).map(mapSpeakerToSpeakerSelectItem);
                    }}

                />
            </FormGroup>

            <FormGroup>
                <Label>
                    Locations
                </Label>
                <AsyncSelect
                    isClearable
                    isMulti
                    defaultOptions={locationsList}
                    value={locations}
                    onChange={options => {
                      const selected = options.map(op => op.id);
                      setLocations(options as any[]);
                      setProduct((prev: any) => ({
                        ...prev, locations: selected
                      }))
                    }}
                    className="form-control flex-2"
                    placeholder="Select Location"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    loadOptions={async (val) => {
                      return await GetLocationsDropdownList(val);
                    }}

                />
            </FormGroup>
        </>
        }

        {

          product && product.category && (product.category.id === 4 || product.category.id === 1) &&
          <FormGroup>
              <Label>
                  Assigned Addons
              </Label>
              <AsyncSelect
                  isClearable
                  isMulti
                  defaultOptions={addonsList}
                  value={addons}
                  onChange={options => {
                    const selected = options.map(op => op?.id);
                    setAddons(options as any[]);
                    setProduct((prev: any) => ({
                      ...prev, assignedAddons: selected
                    }))
                  }}
                  className="form-control flex-2"
                  placeholder="Select Addons"
                  getOptionLabel={(option) => option.nameEn ?? option.name}
                  loadOptions={async (val) => {
                    return await fetchAddonsList(val);
                  }}

              />
            {/*<Select
                  isMulti={true}
                  options={addonsList}
                // onInputChange={itemsForAddonsFetching}
                  value={addonsList.filter(it => product.assignedAddons?.includes(it.id))}
                  onChange={(o: any) => {
                    const productIds = o.map((p: { id: any; }) => p.id);
                    setProduct({ ...product, assignedAddons: productIds });
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id + ""}
              />*/}
            {/*<ErrorMessage*/}
            {/*    name={"assignedAddons"}*/}
            {/*    render={error => (*/}
            {/*        <FormFeedback className="d-block">*/}
            {/*            {error}*/}
            {/*        </FormFeedback>*/}
            {/*    )}/>*/}
          </FormGroup>
        }

        {product && (product.category?.id === 9) &&
        <FormGroup>
            <Label>
                Related Products
            </Label>
            <Select
                isMulti={true}
                value={itemsForAddons.filter(it => {
                  return product?.assignedToProducts?.includes(it.id)
                })}
                options={itemsForAddons}
                onInputChange={itemsForAddonsFetching}
                onChange={(o: any) => {
                  const productIds = o.map((p: { id: any; }) => p.id);
                  setProduct((prev: any) => ({
                    ...prev, assignedToProducts: productIds
                  }))
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
            />
        </FormGroup>
        }

        {/* Add Set of images. */}

        <FormGroup>
          <ImageUploader images={previewImages} setImages={(images: any[]) => {
            setImagesChanged(true);
            setPreviewImages(images)
          }}/>

        </FormGroup>


        <button
          className={"btn btn-outline-secondary"}
          onClick={e => {
            e.preventDefault();
          }}
        >
          Display Images File
        </button>

        <Button
          className="my-4"
          block
          color="secondary"
          outline
          onClick={e => {
            e.preventDefault();
            setShowQuantity(!showQuantity)
          }}
        >
          {showQuantity ? "Hide" : "Display"} Quantity
        </Button>
        {showQuantity ? <>
            <FormGroup>
              <Label>Minimum Quantity Per Order</Label>
              <Input type="number"
                     onChange={onChangeHandler}
                     value={product?.minQTYPerOrder}
                     name={nameof<Product>("minQTYPerOrder")}/>
            </FormGroup>

            <FormGroup>
              <Label>Maximum Quantity Per Order</Label>
              <Input type="number"
                     onChange={onChangeHandler}
                     value={product?.maxQTYPerOrder}
                     name={nameof<Product>("maxQTYPerOrder")}/>
            </FormGroup>


            <FormGroup>
              <Label>Total Quantity</Label>
              <Input type="number"
                     onChange={onChangeHandler}
                     value={product?.totalQTY}
                     name={nameof<Product>("totalQTY")}/>
            </FormGroup>

          </>
          : null
        }

        <FormGroup>
          <Label> Price </Label>
          <Input type="number"
                 onChange={e => {
                   e.preventDefault();
                   const value = e.target.value as unknown as number;
                   const price = product?.price ?? {};
                   if (price) {
                     price.amount = value;
                     if (value <= 0) {
                       price.taxesIds = [];
                       price.discount = 0;
                     }
                     setProduct((prev: any) => ({
                       ...prev,
                       price: price
                     }))
                     setTimeout(() => {
                       calcTotalPrice();
                     }, 500);
                   }
                 }}
                 value={product ? product?.price?.amount : 0} name={nameof<Product>("price") +
          ".amount"}/>
        </FormGroup>

        <FormGroup>
          <Label> Discount </Label>
          <InputGroup>
            <Input type="number"
                   disabled={!product?.price || product?.price.amount <= 0}
                   value={product?.price?.discount}
                   onChange={e => {
                     e.preventDefault();
                     const value = e.target.value as unknown as number;
                     // if(!product?.price || value > product?.price?.amount) return;
                     const price = product?.price;
                     price.discount = value;
                     setProduct((prev: any) => ({
                       ...prev,
                       price: price
                     }));
                     setTimeout(() => {
                       calcTotalPrice();
                     }, 500);
                   }}/>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                {product?.price && product?.price.isDiscountPercentage === true ? '%' : 'Fixed amount'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setProduct((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = true;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);
                }}>
                  %
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setProduct((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = false;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);
                }}>
                  Fixed amount
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </InputGroup>

        </FormGroup>

        <FormGroup>
          <Label> Taxes </Label>
          <Select
            isDisabled={!product?.price || !product.price.totalPrice || product.price.totalPrice <= 0}
            value={product?.price
            && (product.price.taxesIds ?? product.price.taxes).map((taxId: any) => {
              return taxes.find(taxe => taxe.value === taxId || taxe.value === taxId.id)
            })}
            isMulti={true}
            options={taxes}
            onChange={(o: any) => {
              o = o as Array<{ label: string, value: number }>;
              if (o != null && product) {

                const taxesIds = o.map((t: { value: any; }) => t.value);

                const price = product?.price;
                price.taxesIds = taxesIds;
                price.taxes = o;
                setProduct((prev: any) => ({
                  ...prev, price: price
                }))
              }

              setTimeout(() => {
                calcTotalPrice();
              }, 500);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label> Total Price : {" "}
            {product?.price?.amount && <b color="black">{product?.price?.totalPrice} SAR</b>}
          </Label>
          {/*<Input readOnly={true} value={product.price.totalPrice}/>*/}
        </FormGroup>
        <FormGroup switch>
          <Label style={{ marginRight: "5px" }}>Published</Label>

          <Input
            type="switch"
            checked={product?.isPublished ?? false}
            id={"isOnHomePage"}
            onChange={e => {
              setProduct((prevState: any) => ({ ...prevState, isPublished: e.target.checked }))

            }}
          />
        </FormGroup>
      </ModalBody>}

      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={() => props.close(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={handleProductEdition}
        >
          {loading ? <FaSpinner className="icon-spin"/> : "Edit Product"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  )
}

export default EditProductComponent;