import { Loader } from "components/Loader";
import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IUpdateSessionNote, getSessionNote, updateSessionNote } from '../../api/MembershipAreaSessionApi';
import PrintSessionTicket from "./PrintSessionTicket";

enum FormState {
    Create, Edit
}
interface OwnProps {
    open: boolean;
    close: () => void;
    sessionId: number;
    sessionNumber: string;
}

type Props = OwnProps;
const AddEditSessionComponent: React.FC<Props> = props => {

    const [formState, setFormState] = React.useState<FormState>(FormState.Create);
    const [sessionNote, setSessionNote] = React.useState<IUpdateSessionNote>({ sessionId: props.sessionId, note: "" });
    const [loading, setLoading] = React.useState<boolean>(false);
    const getSessionNoteHandler = React.useCallback(async () => {
        setLoading(true);
        const result = await getSessionNote(props.sessionId).catch((err)=>{
            
            setLoading(false);
        });
        if (result?.note && result?.note.length > 0) {
            setFormState(FormState.Edit);
        }
        setLoading(false);
        setSessionNote({ ...sessionNote, note: result?.note ?? "" });
    }, [setSessionNote, setLoading, setFormState, sessionNote])

    React.useEffect(() => {
        getSessionNoteHandler();
    }, [])


    const updateSessionNoteHandler = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await updateSessionNote({ note: sessionNote?.note ?? "", sessionId: props.sessionId });
            setLoading(false);
            props.close?.();
        } catch (error) {
            setLoading(false);
            alert('error has been happened')
        }

    }, [setLoading, sessionNote])

    return (<Modal unmountOnClose isOpen={props.open} toggle={props.close}>
        <ModalHeader toggle={props.close}>
            {formState == FormState.Create ? 'Add' : 'Edit'} Comment of Session Number {props.sessionNumber}
        </ModalHeader>
        <ModalBody>
            {
                loading ?
                    <Loader isLoading={true} />
                    : null
            }
            <div className="form-group">
                <label htmlFor="comment"> Comment </label>
                <textarea
                    onChange={e => {
                        const { value } = e.target;

                        setSessionNote({ ...sessionNote, note: value })
                    }}
                    value={sessionNote.note}
                    name="comment" id="comment" rows={6} className="form-control"></textarea>
            </div>
        </ModalBody>
        <ModalFooter>




            <button onClick={props.close} className="mx-2 btn btn-secondary ">
                Cancel
            </button>
            <button
                onClick={updateSessionNoteHandler}
                className="mx-2 btn btn-success">
                Confirm
            </button>

        </ModalFooter>
    </Modal>);
}

export default AddEditSessionComponent;