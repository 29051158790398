import {ConfigurationBody} from "api/EmailConfiguration";
import {MainCard} from "components/MainCard";
import {ToastMessage} from "components/ToastMessages";
import {ErrorMessage, Form, Formik,} from "formik";
import * as React from "react";
import {ChangeEvent, useRef, useState} from "react";
import Helmet from "react-helmet";
import {FaEdit} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import * as yup from "yup";
import {nameof} from "../../util/nameof";
import {updateProfile} from "api/ProfileSettings";
import {
  Button,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import ChangePassword from "./ChangePassword";
import "./index.css";
import {getCurrentUserProfile} from "../../api/userInfo";
import avatar from './assets/avatar.svg';
import {PhoneInput} from "../../components/PhoneInput/PhoneInput";

export interface UserProfileForm {
  email: string;
  firstName: string;
  secondName: string | null;
  thirdName: string | null;
  lastName: string;
  bio: string | null;
  // companyName: string;
  // city: string;
  // country: { id: number; name: string } | null;
  // mailingAddress: string;
  // logo: number | null;
  // comapnyCategory: { id: number; name: string } | null;
  // slug: string;
  phoneNumber: string;
  scfhsid: string | null;
  image: File | null;
  currentPassword: string | null;
}

export const ProfileSettings: React.FC = (props) => {
  const dispatch = useDispatch();
  const params = useParams<{ eventId: string }>();
  const eventId = Number(params.eventId);
  const [hasCustomConfig, changeConfig] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<ConfigurationBody | null>(null);

  const [editProfile, setEditProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const [image, setImage] = useState<string | null | undefined>(currentUser?.image);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');


  // const [initials, setInitials] = React.useState<UserProfileForm>(
  //     {
  //       email: "",
  //       firstName: "",
  //       secondName: null,
  //       thirdName: null,
  //       lastName: "",
  //       bio: null,
  //       phoneNumber: "",
  //       scfhsid: null,
  //       image: null as any,
  //     }
  // )

  const [formData, setFormData] = useState<UserProfileForm>({
    email: "",
    firstName: "",
    secondName: null,
    thirdName: null,
    lastName: "",
    bio: null,
    phoneNumber: "",
    scfhsid: null,
    image: null as any,
    currentPassword:null
  });

  const formikRef = useRef<typeof Formik | null>(null);

  const [isOpen, openCloseChange] = React.useState(false);

  const toggle = () => {
    openCloseChange(os => !os);
  };

  const getValidationErrorFor = (phoneNumber: string) => {
    return (window.intlTelInputUtils as any).getValidationError(
      phoneNumber,
      "SA"
    );
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // const initialValues = React.useMemo<UserProfileForm>(
  //   () => (initials),
  //   [initials]
  // );

  const ValidatorSchema = React.useMemo<yup.Schema<UserProfileForm>>(
    () =>
      yup.object().shape<UserProfileForm>({
        email: yup
          .string()
          .email("It should be a valid email")
          .required("Email address is required."),
        // mailingAddress: yup
        //   .string()
        //   .email("It should be a valid email")
        //   .required("Sender is required."),
        // slug: yup.string().required("Slug is required"),
        firstName: yup.string().required("First name is required"),
        secondName: yup.string().nullable(),
        thirdName: yup.string().nullable(),
        lastName: yup.string().required("Last name is required"),
        bio: yup.string().nullable(),
        scfhsid: yup.string().nullable(),
        // companyName: yup.string().required("Company name is required"),
        // city: yup.string().required("City is required"),
        // country: yup
        //   .mixed()
        //   .nullable()
        //   .required("Country is required"),
        // comapnyCategory: yup
        //   .mixed()
        //   .nullable()
        //   .required("Comapny category field is required"),
        // logo: yup.number().nullable(),
        phoneNumber: yup
          .mixed()
          .required("Phone Number is required ")
          .nullable()
          .test(
            "PhoneTooShort",
            "The phone number you entered is too short for the selected country.",
            (value) => (value ? getValidationErrorFor(value) !== 2 : true)
          )
          .test(
            "PhoneTooLong",
            "The phone number you entered is too long for the selected country.",
            (value) => (value ? getValidationErrorFor(value) !== 3 : true)
          )
          .test(
            "InvalidCountryCode",
            "The phone number you entered has invalid country code.",
            (value) => (value ? getValidationErrorFor(value) !== 1 : true)
          )
          .test(
            "NotANumber",
            "The phone number you entered is invalid.",
            (value) => (value ? getValidationErrorFor(value) !== 4 : true)
          ),
        image: yup
              .mixed<File>()
              .nullable()
              .required()
              .test(
                  "FileType",
                  "only image files are supported",
                  value => value && !!(value as File).type.match("image.*")
              ),
        currentPassword: yup.string().nullable()
      }),
    []
  );

  // const [categories, setCategories] = React.useState([]);
  // const [countries, setCountries] = React.useState([]);
  // const [orgnizationLogo, setLogo] = React.useState();

  // const getCompanies = async () => {
  //   const res = await getCompanyCategories();
  //   setCategories(res.items);
  // };
  //
  // const getCountriesList = async () => {
  //   const res = await getCountries();
  //   setCountries(res.items);
  // };
  //
  // const getSettings = async () => {
  //   const res = await getOrganization();
  //   const values: any = {
  //     email: res.accountEmail,
  //     firstName: res.firstName,
  //     lastName: res.lastName,
  //     companyName: res.companyName,
  //     mailingAddress: res.mailingAddress,
  //     country: res.country,
  //     city: res.city,
  //     logo: res.orgnizationLogo?.id,
  //     slug: res.accessUrl,
  //     comapnyCategory: res.companyCategory,
  //     phoneNumber: res.phoneNumber,
  //   }
  //   setFormData(values);
  // }

  const joinNames = (...names: string[]) => {
    return names.filter(name => name && name !== '').join(' ');
  }

  const getCurrentUser = () => {
    getCurrentUserProfile().then(profile=>{
    const {firstName, secondName, thirdName, lastName} = profile;
    const fullName = joinNames(firstName, secondName, thirdName, lastName)
    setCurrentUser({...profile, unique_name:fullName});
    setFormData({...profile});
    if(profile.image) setImage(profile.image.fullUrl)
    }).catch(err=>console.error(err));
  }

  React.useEffect(() => {
    // getSettings();
    // getCompanies();
    // getCountriesList();
    getCurrentUser();
  }, []);

  const handleSubmit = async () => {
      try {
        await updateProfile({...formData, CurrentUserId: currentUser.id, CurrentPassword: currentPassword});
        toast.success(
            <ToastMessage>
              Profile updated successfully
            </ToastMessage>
          )
          setShowConfirmation(false);
          setEditProfile(false);
          getCurrentUser();
      } catch (e) {
        toast.error(
          <ToastMessage type="Error">
            Something went wrong, Please try again later.
          </ToastMessage>
        );
      } finally {
      }
    }

  const labelStyle = {
    display: "block",
    padding: "15px 0",
  };

  const formStyle = {
    padding: "15px 0",
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {

    const file = e.target && e.target.files && e.target.files[0];

    if(!file) return;

    setFormData({
      ...formData,
      image: file
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result as string);
    };

    if (file.type.match("image.*")) {
      reader.readAsDataURL(file);
    } else {
      setImage(undefined);
    }
  };

  const renderForm =
    () => (
      <Form className="p-3">
        <Row>
          <Col xs={12} className='slug'>
            <div style={formStyle}>
              <div style={{ width: "100%", marginBottom:'40px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="img-edit-profile">
                  <img loading="lazy" src={image ?? avatar} alt=""/>
                  <div className="chenge-img">
                    <input type="file" id="chenge1" name="chenge-img" onChange={handleImageChange}/>
                    <label htmlFor="chenge1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          fill="currentColor"
                          className="bi bi-camera-fill"
                          viewBox="0 0 16 16"
                      >
                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path
                            d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label>First Name :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.firstName}
                    />
                    <ErrorMessage
                        name={nameof<UserProfileForm>("firstName")}
                        render={(error) => (
                            <FormFeedback className="d-block">{error}</FormFeedback>
                        )}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className="email">
                    <Label>Second Name :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.secondName??''}
                        name={nameof<UserProfileForm>("secondName")}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <FormGroup className="email">
                    <Label>Third Name :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.thirdName??''}
                        name={nameof<UserProfileForm>("thirdName")}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label>Last Name :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.lastName}
                        name={nameof<UserProfileForm>("lastName")}
                    />
                    <ErrorMessage
                        name={nameof<UserProfileForm>("lastName")}
                        render={(error) => (
                            <FormFeedback className="d-block">{error}</FormFeedback>
                        )}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <FormGroup className="email">
                    <Label>Bio :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.bio??''}
                        name={nameof<UserProfileForm>("bio")}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <FormGroup className="email">
                    <Label>Email Address :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.email??''}
                        name={nameof<UserProfileForm>("email")}
                    />
                    <ErrorMessage
                        name={nameof<UserProfileForm>("email")}
                        render={(error) => (
                            <FormFeedback className="d-block">{error}</FormFeedback>
                        )}
                    />
                  </FormGroup>

                  {/*<FormGroup className="email">*/}
                  {/*  <Label>Email Address :</Label>*/}
                  {/*  <InputGroup>*/}
                  {/*    <Input*/}
                  {/*      onChange={formProps.handleChange}*/}
                  {/*      onBlur={formProps.handleBlur}*/}
                  {/*      value={formProps.values.email}*/}
                  {/*      name={nameof<UserProfileForm>("email")}*/}
                  {/*      disabled={!isChange}*/}
                  {/*    />*/}
                  {/*    <button*/}
                  {/*      className="btn-change"*/}
                  {/*      onClick={(e) => changeEmail(e)}*/}
                  {/*    >*/}
                  {/*      Change*/}
                  {/*    </button>*/}
                  {/*  </InputGroup>*/}
                  {/*  <ErrorMessage*/}
                  {/*      name={nameof<UserProfileForm>("email")}*/}
                  {/*      render={(error) => (*/}
                  {/*          <FormFeedback className="d-block">{error}</FormFeedback>*/}
                  {/*      )}*/}
                  {/*  />*/}
                  {/*</FormGroup>*/}
                </Col>
              </Row>


              <Row>
                <Col xs={12} md={12}>
                <FormGroup>
                  <Label>Phone Number :</Label>
                    <PhoneInput
                      value={formData.phoneNumber}
                      onPhoneNumberUpdated={(formattedPhoneNumber) => {
                        setFormData({...formData, phoneNumber: formattedPhoneNumber??''})
                      }}
                    />
                  <ErrorMessage
                    name={nameof<UserProfileForm>("phoneNumber")}
                    render={(error) => (
                      <FormFeedback className="d-block">{error}</FormFeedback>
                    )}
                  />
                </FormGroup>
              </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <FormGroup className="email">
                    <Label>SCFHS ID :</Label>
                    <Input
                        onChange={handleChange}
                        value={formData.scfhsid??''}
                        name={nameof<UserProfileForm>("scfhsid")}
                    />
                  </FormGroup>
                </Col>
              </Row>

            </div>
          </Col>
        </Row>

        <div className="space-between mt-2">
          <Button style={{ marginRight: "auto" }} onClick={toggle}>
            Change Password
          </Button>
          <Button
            type="submit"
            color="primary"
            style={{ marginLeft: "auto" }}
          >
            Update
          </Button>
        </div>
      </Form>
  );

  return (
    <>
      <Helmet title={"Profile Settings"} />
      <ChangePassword toggle={toggle} isOpen={isOpen} />
      <Modal isOpen={showConfirmation} toggle={()=>setShowConfirmation(!showConfirmation)}>
        <ModalHeader>Confirm updating profile</ModalHeader>
        <ModalBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>Current Password</Label>
                  <Input
                      onChange={(e)=>{
                        setFormData({...formData, currentPassword: e.target.value??''})
                      }}
                      value={formData.currentPassword??''}
                      name="currentPassword"
                      type="password"
                  />
                </FormGroup>
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=>setShowConfirmation(!showConfirmation)}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit} color="primary">
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
          <span>Profile</span>
            <div>
              <Button
                  color={editProfile ? 'secondary': "primary"}
                  onClick={()=>setEditProfile(!editProfile)}
              >
                {editProfile ? 'Cancel' : <>Edit Profile <FaEdit/></>}
              </Button>
            </div>
          </div>
        </CardHeader>

        {editProfile ?
          <Formik
              onSubmit={()=>setShowConfirmation(true)}
              initialValues={formData}
              // validationSchema={ValidatorSchema}
              enableReinitialize
              ref={formikRef as any}>
            {renderForm}
          </Formik> :

            <div className="p-3 details-myprofile">
              <div className="img-details-myprofile">
                <img src={currentUser?.image ? currentUser.image.fullUrl : avatar} alt="User Profile"/>
              </div>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <span className="label">FullName :</span>
                  <span className="value">{currentUser?.unique_name}</span>
                </li>
                <li>
                  <span className="label">Bio :</span>
                  <span className="value">{currentUser?.bio}</span>
                </li>
                <li>
                  <span className="label">Email :</span>
                  <span className="value">{currentUser?.email}</span>
                </li>
                <li>
                  <span className="label">Mobile no :</span>
                  <span className="value">{currentUser?.phoneNumber}</span>
                </li>
                <li>
                  <span className="label">Country :</span>
                  <span className="value">Saudi Arabia</span>
                </li>
                <li>
                  <span className="label">SCFHS ID :</span>
                  <span className="value">{currentUser?.scfhsid}</span>
                </li>
              </ul>
            </div>

        }
      </MainCard>
    </>
  );
};
