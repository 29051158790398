import Axios from "axios";
import moment, {Moment} from "moment";
import {objectToFormData} from "util/objectToFormData";
import {QuestionOption, QuestionType} from "./RegistrationFormApi";
import {IDropdownList} from "./common/DropdownList";
import {MediaFile} from "./common/MediaFile";
import {PaginatedRequestWithFilter} from "./common/PaginatedRequest";
import {PaginatedResult} from "./common/PaginatedResult";
import {SelectedPayment} from "../scenes/ManageTenantUsers/PaymentPlanComponent";

export interface IUserTenantsModel {
  id: number;
  eventId: number;
  eventName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  image?: MediaFile | null;
  planName: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  membershipNumber: string;
}

export const GetTenantUsers = async (
  request?: PaginatedRequestWithFilter
): Promise<PaginatedResult<IUserTenantsModel>> => {
  const result = await Axios.get<PaginatedResult<IUserTenantsModel>>(
    "api/TenantUser/GetTenantUsers",
    {
      params: request,
    }
  );
  return result.data;
};
export const SearchTenantUsers = async (
  request?: PaginatedRequestWithFilter
): Promise<Array<IUserTenantsModel>> => {
  const result = await Axios.get<Array<IUserTenantsModel>>(
    "api/TenantUser/Search",
    {
      params: request,
    }
  );
  return result.data;
};

export interface IHtmlView {
  html: string;
}

export const GetTenantUserInvoice = async (
  tenantUserId: number
): Promise<IHtmlView> => {
  const result = await Axios.get<IHtmlView>(
    `api/TenantUser/${tenantUserId}/GetTenantUserInvoice`
  );
  return result.data;
};

export const GetMembershipCard = async (
  tenantUserId: number
): Promise<IHtmlView> => {
  const result = await Axios.get<IHtmlView>(
    `api/TenantUser/${tenantUserId}/MembershipCard`
  );
  return result.data;
};

// Membership Area Style.
export interface IMembershipAreaStyle {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  darkLogo?: File | null;
  lightLogo?: File | null;
  banner?: File | null;
  smallLogo?: File | null;
  FavIcon?: File | null;
}

export const AddMembershipAreaStyle = async (
  body: IMembershipAreaStyle
): Promise<any> => {
  const formData = objectToFormData(body);
  const result = await Axios.post<IMembershipAreaStyle>(
    `api/MembershipAreaStyle/AddStyle`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return result.data;
};

// get style for specific tenant.
export interface IGetMembershipAreaStyle {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  darkLogo?: MediaFile | null;
  lightLogo?: MediaFile | null;
  banner?: MediaFile | null;
  smallLogo?: MediaFile | null;
  favIcon?: MediaFile | null;
}
export const GetMembershipAreaStyle = async (): Promise<IGetMembershipAreaStyle> => {
  const result = await Axios.get<IGetMembershipAreaStyle>(
    "api/MembershipAreaStyle/GetStyle"
  );
  return result.data;
};

// edit membership area style

export const EditMembershipAreaStyle = async (
  id: number,
  body: IMembershipAreaStyle
): Promise<any> => {
  const formData = objectToFormData(body);
  const result = await Axios.put(
    `api/MembershipAreaStyle/EditStyle/${id}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return result;
};
// End -> Membership area style.

// membership plans list

export const GetMembershipPlans = async (): Promise<Array<IDropdownList>> => {
  const result = await Axios.get<IDropdownList[]>(
    `api/MembershipPlan/GetPlansList`
  );
  return result.data;
};

export interface IDurationPlanPrice {
  amount: number;
  totalPrice: number;
  displayPrice: string;
  displayTotalPrice: string;
  taxes: { id: number; name: string; value: number }[];
  tax: any;
}
export interface IDurationPlan {
  id: number;
  checkInLimits: number;
  durationInDays: number;
  price: IDurationPlanPrice;
}

export const GetDurationsPlan = async (
  planId: number
): Promise<IDurationPlan[]> => {
  const result = await Axios.get<IDurationPlan[]>(
    `api/MembershipPlan/GetDurationPlans?planId=${planId}`
  );
  return result.data;
};

// get questions specific plan;

export interface IQuestionMembershipPlan {
  id: number;
  actionOnQuestion: string;
  order: number;
  question: string;
  required: boolean;
  type: QuestionType;
  options: QuestionOption[];
}

export const GetQuestionsMembershipArea = async (
  planId: number
): Promise<IQuestionMembershipPlan[]> => {
  const result = await Axios.get<IQuestionMembershipPlan[]>(
    `api/MembershipPlan/GetQuestionsMembershipArea?planId=${planId}`
  );
  return result.data;
};

// end => membership plans list

// add Memership User
export interface IAddMembershipUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  durationPlanId: number;
  questions: { questionId: string; value: any }[];
  payments: SelectedPayment[];
}

export const AddMembershipUser = async (
  body: IAddMembershipUser
): Promise<any> => {
  const result = await Axios.post(
    "api/MembershipPlan/AddMembershipUserViewModel",
    body
  );
  return result.data;
};
// end add membership user

// tenant User dropdown list

export interface ITenantUserDropdownItem extends IDropdownList {}

export const GetTenantUserDropdownList = async (): Promise<Array<
  any
>> => {
  const response = await Axios.get(
    `api/TenantUser/Search`
  );
  return response.data;
};

export interface ISingleTenantUser {
  id: number;
  firstName: string;
  secondName?: string;
  thirdName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  durationPlanId: number;
  membershipPlanId: number;
  tenantUserQuestionAnswers: Array<ITenantUserQuestionAnswer>;
  scfhsid?:string;
  bio?:string;
  membershipPlanName?: string;

}

export interface ITenantUserQuestionAnswer {
  questionId: number;
  question: string;
  answer: string | null;
  questionOptionId: number | null;
  questionOptionName: string | null;
}

export const GetTenantUserById = async (
  tenantUserId: number
): Promise<ISingleTenantUser> => {
  const response = await Axios.get(`api/TenantUser/${tenantUserId}/GetById`);
  return response.data;
};

export interface IEditTenantUser
  extends Omit<ISingleTenantUser, "id" | "membershipPlanId"> {
  durationPlanEditable?: IDurationPlanEditable;
}

export const EditTenantUser = async (
  tenantUserId: number,
  request: IEditTenantUser
): Promise<any> => {
  const response = await Axios.put(
    `api/TenantUser/${tenantUserId}`,
    request
  );
  return response.data;
};

// get duration plan data which editable

export interface IDurationPlanEditable {
  startFrom: Moment;
  checkInLimits: number;
  durationInDays: number;
}

export const GetDurationPlanEditable = async (
  tenantUserId: number
): Promise<any> => {
  const response = await Axios.get(
    `api/TenantUserMembershipPlan/${tenantUserId}/GetDurationPlanEditable`
  );
  return response.data;
};
