import Axios from "axios";
import { PaginatedRequest } from "./common/PaginatedRequest";
import { PaginatedResult } from "./common/PaginatedResult";

export interface webinarRequest extends  PaginatedRequest {
    
}

export interface IWebinarList {
    id:number,
    index:number;
    topic:string;
    startDate: Date,
    endDate: Date,
    meetingUrl: string
}


export const getWebinarList = async (request : webinarRequest) : Promise<PaginatedResult<IWebinarList>> => {
    const result = await Axios.get<PaginatedResult<IWebinarList>>('api/Webinar/GetMeetings' , {params : request});
    return result.data;
}

/* Create Webinar */

export enum WebinarType {
    Webinar = 5,
    RecurringWithFixedTime = 6,
    RecurringWithoutFixedTime = 9
}

export  enum RecurrenceType {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

export  enum WeeklyDays {
    Sunday= 1,
    Monday = 2,
    Thuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7
}


export enum MonthlyWeek
{
    LastWeek  = -1,
    FirstWeek = 1,
    SecondWeek = 2,
    ThirdWeek = 3,
    FourthWeek = 4
}

export  interface IWebinarRecurrenceDto {
    type:RecurrenceType;
    repeatInterval:number;
    weeklyDays: Array<WeeklyDays>;
    monthlyDay: number;
    monthlyWeek: MonthlyWeek;
    monthlyWeekDay:WeeklyDays;
    endTimes?: number | null;
    endDateTime?: string | null;
}

export interface ICreateWebinar {
    topic:string;
    type: WebinarType;
    startTime:string;
    duration: number;
    password? : string | null;
    webinarRecurrence?: IWebinarRecurrenceDto | null;
    eventId?: number | null;
    timeZone?:string | null,
    sessionId?:number | null
    createSession:boolean
}


export  const createWebinar = async (payload: ICreateWebinar) : Promise<any> => {
    const result = await Axios.post(`api/Webinar/CreateMeeting` , payload);
    return result.data;
}

/*end: Create Webinar*/


/* Delete Webinar*/


export  const deleteWebinar = async (id : number)  : Promise<any> => {
    const result =  await Axios.delete(`api/Webinar/DeleteMeeting/${id}`);
    return result.data;
}

/*end => Delete Webninar*/



/* Create Registrants*/
export  interface IWebinarRegistrants {
    email:string;
    firstName:string;
    lastName?:string | null;
    address?:string | null;
    recipientGroupAsRegistrants?:Array<IRecipientGroupAsRegistrants> | null
}

export interface IRecipientGroupAsRegistrants {
    deSelectedIds?:number[] | null,
    recipientListId:number
}

export  const CreateRegistrants = async (webinarId : number , payload: IWebinarRegistrants ) : Promise<any> => {
    const result =  await  Axios.post(`api/Webinar/${webinarId}/Registrants` , payload);
    return result.data;
}

/*end -> create registrants*/


export const GetRegistrants = async(webinarId : number) : Promise<Array<IWebinarRegistrants>> => {
    const result = await Axios.get<Array<IWebinarRegistrants>>(`api/Webinar/${webinarId}/Registrants`);
    return result.data;
}