/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Loader } from '../../components/Loader/Loader';

import { GetEventBadgeReceipt, GetEventBadgeReceiptHtml } from '../../api/SmartAccessApi';

interface OwnProps {
  close: () => void;
  isOpen: boolean;
  membershipNumber: string;
}

type Props = OwnProps;

export class EventBadgeComponent extends Component<Props, any> {
  componentToPrintRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      html: null
    };
    this.componentToPrintRef = React.createRef();
  }

  componentWillMount() {

    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true })
    GetEventBadgeReceipt(this.props.membershipNumber).then(data => {
      this.setState({ data, loading: false });
    }, error => {
      this.setState({ loading: false })
    })

    GetEventBadgeReceiptHtml(this.props.membershipNumber).then(data => {
      if (data) {
        this.setState({ html: data.html })
      }
    })
  }

  render() {
    return (
      <Modal
        unmountOnClose
        isOpen={this.props.isOpen}
        toggle={this.props.close}
      >
        <ModalHeader toggle={this.props.close}>Ticket Receipt</ModalHeader>
        <ModalBody >
          <div>
            {this.state.loading ? <Loader isLoading={true} /> : null}


            {
              this.state.data ?
                <>
                  <div className="text-center" ref={this.componentToPrintRef}>

                    {/* <div>
                          <h2> {this.state.data.fullName} </h2>
                        </div>
    
    
                        <div style={{ marginTop: '50px' } as React.CSSProperties}>
                          <div className="mt-4">
                            {/* <img src={
                             
                            } width="70%" /> */}
                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.data.qrCodeHtml }}>
    
                            </div>
                          </div>
                          <div className="mt-2">
                            <strong>  {this.state.data.membershipNumber} </strong>
                          </div>
                        </div> */}

                    <div dangerouslySetInnerHTML={{ __html: this.state.html }}>

                    </div>

                  </div>

                  <div className="row footer-icons text-center">

                    <div className="col-sm-6">

                      <ReactToPrint
                        trigger={() => {

                          return (<button>
                            <i> <AiOutlinePrinter /> </i>
                            <span> Print </span>
                          </button>);
                        }}
                        content={() => this.componentToPrintRef.current}
                      />
                    </div>

                  </div>
                </>
                : null
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
