import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import routes from "../../routes";
import { Breadcrumb } from "reactstrap";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const Breadcrumbs = () => {

  const eventInfoState = useSelector((state: RootState) => state.eventDetails);
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumb tag="nav" listTag="div">
      {breadcrumbs.map(({ match, breadcrumb }) => {
        return (
          <Link to={match.pathname} className="breadcrumb-item" key={match.pathname}>
            {match.pathname.match(/\d+$/) && eventInfoState.result?.name ? eventInfoState.result?.name : breadcrumb}
          </Link>
        )
      })}
    </Breadcrumb>
  );

}