import React, {ChangeEvent, useEffect, useState} from 'react';
import {ProductCategory} from "../../api/ProductsApi";
import {Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Loader} from "../../components/Loader";
import {
    AddNewCategoryRequest,
    addProductCategory,
    editCategory,
    editProductCategories,
    GetProductCategoriesTypes,
    ICategoryDropdownItem
} from "../../api/ProductCategoriesApi";
import Select from "react-select";

export enum ModalStatus {
    __CREATE__, __EDIT__
}

interface IAddEditProductCategoryProps {
    isOpen: boolean,
    onClose?: () => void,
    onUpdated?: () => void,
    item?: ProductCategory | null,
    modalStatus: ModalStatus,
}

interface IAddEditProductCategoryState {
    data: any,
    loading: boolean,
    formValid: boolean
}

const initState: IAddEditProductCategoryState = {
    data: {
        name: '',
        id: 0,
        type: 0,
        isOnHomePage: false,
        changeable: true
    },
    formValid: false,
    loading: false
}

const AddEditCategories: React.FC<IAddEditProductCategoryProps> = (props) => {
    const [state, setState] = useState<IAddEditProductCategoryState>(
        !!props.item ? {...initState, data: props.item , formValid: true, loading: false} : initState);
    const [productCategoriesTypes, setProductCategoriesTypes] = useState<ICategoryDropdownItem[]>([])

    useEffect(() => {

        GetProductCategoriesTypes()
            .then((types) => setProductCategoriesTypes(types))
    },[]);

    const submitHandler = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        if (props.modalStatus === ModalStatus.__CREATE__) {
            const payload: AddNewCategoryRequest = {
                name: state.data.name,
                nameAr: state.data.nameAr,
                typeId: state.data.type,
                isOnHomePage: state.data.isOnHomePage
            };

            addProductCategory(payload).then(res => {
                props.onClose?.();
                props.onUpdated?.();
            });
        } else {
            const payload: editCategory = {
                name: state.data.name,
                nameAr: state.data.nameAr,
                typeId: state.data.type,
                isOnHomePage: state.data.isOnHomePage
            };

            editProductCategories(state.data.id, payload).then(res => {
                props.onClose?.();
                props.onUpdated?.();
            });
        }
    }

    const changeHandlerName = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;

        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, name },
            formValid: prevState.data?.name?.length > 0
        }));
    }

    const changeHandlerNameAr = (e: ChangeEvent<HTMLInputElement>) => {
        const nameAr = e.target.value;

        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, nameAr },
        }));
    }

    const changeProductType = (type: any) => {
        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, type : type.id },
            formValid: type.name.length > 0
        }));
    }

    return (
        <Modal style={{ height: '500px' }} unmountOnClose isOpen={props.isOpen} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>
                {
                    props.modalStatus === ModalStatus.__EDIT__ ? " Edit Product Category" : "Add Product Category"
                }
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="catName"> Name * </label>
                    <input className={'form-control'}
                           onChange={changeHandlerName}
                           value={state.data.name} id={'catName'} />
                </div>
                <div className="form-group">
                    <label htmlFor="catName"> Name Arabic </label>
                    <input className={'form-control'}
                           onChange={changeHandlerNameAr}
                           value={state.data.nameAr} id={'catName'} />
                </div>

                <div className="form-group">
                    <label htmlFor="catName"> Type * </label>
                    <Select
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id + ""}
                        options={productCategoriesTypes}
                        value={productCategoriesTypes.find(pct=>pct.id === state.data.type)}
                        onChange={(option) => {
                            changeProductType(option)
                        }}
                        placeholder="Select a Product Category"
                    />
                </div>

                <div className="form-inline">
                    <label htmlFor="catName"> Is On Home Page * </label>
                    <Input
                        type="switch"
                        name={"isOnHomePage"}
                        id={"isOnHomePage"}
                        onChange={(e: any) => {
                            setState(prevState => ({
                                ...prevState,
                                data: { ...prevState.data, isOnHomePage: e.target.checked },
                                formValid: prevState.data?.name?.length > 0
                            }));
                        }}
                        checked={
                            state.data.isOnHomePage
                        }
                    />
                </div>

            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary"
                        onClick={props.onClose}
                > Cancel </button>
                <button onClick={submitHandler} className="btn btn-primary"
                        disabled={!state.formValid}> Save </button>
                {
                    state.loading ? <Loader isLoading={true} /> : null
                }
            </ModalFooter>
        </Modal>
    );
}

export default AddEditCategories;
