import * as React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

interface ToastMessageProps {
    icon?: React.ReactNode;
    type?: "Error" | "Success";
    children: React.ReactNode;
}

export const ToastMessage: React.FC<ToastMessageProps> = props => {
    let typeIcon: React.ReactNode;

    switch (props.type) {
        case "Error":
            typeIcon = <FaTimes size="1.5em" />;
            break;
        case "Success":
            typeIcon = <FaCheck size="1.5em" />;
            break;
    }
    return (
        <div className="d-flex flex-row">
            <div className="d-inline-block px-3 my-auto toast--icon">
                {props.icon || typeIcon}
            </div>
            <div className="my-auto">{props.children}</div>
        </div>
    );
};

ToastMessage.defaultProps = {
    type: "Success"
};
