import { GetProductExportData, GetProductsRegardlessEvent, Product, Ticket } from "api/ProductsApi";
import { DataTable } from "components/DataTable";
import * as React from "react";
import { useState } from "react";
import Helmet from "react-helmet";
import { FaEdit, FaFileExport, FaPlus, FaTrash } from "react-icons/fa";
import { CellProps, Column } from "react-table";
import { Button, CardBody, CardHeader } from "reactstrap";
import { MainCard } from "../../components/MainCard";
import DeleteProduct from './DeleteProductComponent';
import EditProductComponent from './EditProductComponent';
import { ProductImagesComponent } from './ProductImagesComponent';
import { AddProductComponent } from "./AddProductComponent";
import { toast } from "react-toastify";


export const ManageProducts: React.FC = props => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [productList, setProductList] = React.useState<Product[]>([]);
    const [productImageId, setProductImageId] = React.useState<number | null>(null);
    const [productImages, setProductImages] = React.useState<any[]>([])
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
    const [maximumPerPage]=useState(20);
    React.useEffect(
        () => {
            setIsLoading(true);
            getProductsList();
        },
        [setProductList, setIsLoading,page]
    );


    const [showAddProduct, setShowProductAdd] = React.useState<boolean>(false);
    const [productToEdit, setProductToEdit] = React.useState<Ticket | null>(null);
    const [showProductToEdit,setShowProductToEdit] = React.useState<boolean>(false);
    const [productToDelete, setProductToDelete] = React.useState<Ticket | null>(
        null
    );

    const showAddEditForProduct = React.useCallback((product: Ticket | null) => {
        setProductToEdit(product)
      setShowProductToEdit(true)
    }, []);

    const showDeleteForProduct = React.useCallback((product: Ticket) => {
        setProductToDelete(product);
    }, []);


    const getProductsList = () => {
        GetProductsRegardlessEvent({ limit: maximumPerPage, pageIndex: page }).then(collectionPage => {
            setIsLoading(false);
            if (collectionPage) {
                setProductList(collectionPage.items);
                setCount((collectionPage as any).totalItemsCount)
            }
        })
    }

  const exportCSV= () =>{
    GetProductExportData({}).then(value => {
      const url = window.URL.createObjectURL(new Blob(["\uFEFF"+value], { type: 'text/csv;charset=utf-8;' }))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Products Export.csv`;
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(reason => {
      toast.error(reason.response.data?.detail ?? "Error while Exporting Attendances")
    })
  }

    const columns = React.useMemo<Column[]>(
        () => [
            {
                Header: "#",
                Cell: (ctx: CellProps<Product>) => {
                    return ctx.row.index+1
                }
            },
            {
                Header: "Name",
                accessor: "name"
            },
            // {
            //     Header: "Description",
            //     // accessor: "description",
            //     Cell: (ctx: CellProps<Product>) => {
            //         const product = ctx.row.original;
            //         return <p
            //             dangerouslySetInnerHTML={{__html: product.description??""}}>
            //         </p>
            //     }
            // },
            {
                Header: "Category",
                accessor: "category.name"
            },
            {
                Header: "Price",
                accessor: "price.displayPrice"
            },
            {
                Header: "Discount",
                accessor: "price.displayProductDiscountAmount"
            },
            {
                Header: "Total Taxes",
                accessor: "price.displayTotalTaxesAmount"
            },
            {
                Header: "Total Price",
                accessor: "price.displayTotalPrice"
            },
            {
                Header: "Images",
                Cell: (ctx: CellProps<Product>) => {
                    const product = ctx.row.original;
                    return <Button
                        onClick={e => {
                            setProductImageId(product.id);
                            setProductImages(product.images??[])
                        }}
                        size="sm">
                        View
                    </Button>
                }
            },
          {
            Header: "Status",
            Cell: (ctx: CellProps<Product>) => {
              const product = ctx.row.original;
              return product.isPublished ? "Published" : "Unpublished"
            }
          },
            {
                Header: "Actions",
                Cell: function renderTicketActions(ctx: CellProps<Ticket>) {
                    const product = ctx.row.original;

                    return (
                        <div>
                            {product && (
                                <>
                                    <Button
                                        size="sm"
                                        color="primary"
                                        outline={true}
                                        onClick={() =>
                                            showAddEditForProduct(product)
                                        }
                                    >
                                        <FaEdit />
                                    </Button>{" "}
                                    <Button
                                        size="sm"
                                        color="danger"
                                        outline={true}
                                        onClick={() =>
                                            showDeleteForProduct(product)
                                        }
                                    >
                                        <FaTrash />
                                    </Button>
                                </>
                            )}
                        </div>
                    );
                }
            }
        ],
        [setProductImageId]
    );

    return <div>
        <Helmet title="Manage Products" />
        {
            productToDelete ?
                <DeleteProduct deleteItem={productToDelete} onUpdated={() => {
                    getProductsList();
                }} cancelDeleting={() => {
                    setProductToDelete(null);
                }} />
                : null
        }
        <MainCard>
            <CardHeader className="d-flex justify-content-between">
                <div>Products</div>

                <div className="d-flex flex-row gap-2">
                    <Button
                        onClick={e => {
                            setShowProductAdd(true);
                        }}
                        color={'primary'}>
                        <FaPlus /> Add Product
                    </Button>
                  <Button
                    className={'d-flex align-items-center gap-2'}
                    onClick={exportCSV}
                    color="success"
                  >
                    Export<FaFileExport/>
                  </Button>
                </div>
            </CardHeader>
            <CardBody>

                <DataTable
                    columns={columns}
                    data={productList}
                    isBusy={isLoading}
                    pagination={
                      {
                        show: true,
                        onPageClicked: setPage,
                        totalItemsCount: count,
                        pageIndex: page,
                        pageSize: maximumPerPage,

                      }
                    }
                />

                {
                    showAddProduct &&
                    <AddProductComponent
                        isOpen={showAddProduct}
                        toggle={(setSuccessfulAdd: boolean) => {
                            if(setSuccessfulAdd) getProductsList();
                            setShowProductAdd(false);
                        }}
                    />
                }

            </CardBody>
        </MainCard>

        {
            productToEdit &&
            <EditProductComponent
                isOpen={showProductToEdit}
                item={productToEdit ?? undefined}
                close={(success) => {
                    if(success) getProductsList();
                    setProductToEdit(null)
                  setShowProductToEdit(false);
                }}
            />
        }

        {
            productImageId &&
            <ProductImagesComponent isOpen={true}
                productId={productImageId} images={productImages}
                onCancel={() => setProductImageId(null)}
            />
        }

    </div>
}