import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { PaginatedResult } from "../api/common/PaginatedResult";
import { EventSessionRequest, Session } from "../api/Sessions";
import { AppThunk } from "./index";

export interface EventSessionsState {
    isBusy: boolean;
    error: string | null;
    eventId: number | null;
    sessions?: PaginatedResult<Session>;
    request?: PaginatedRequest & EventSessionRequest;
}

const initialState: EventSessionsState = {
    isBusy: false,
    error: null,
    eventId: null
};

interface ReceiveEventSessionsListAction {
    error: string | null;
    eventId: number;
    sessions?: PaginatedResult<Session>;
    request?: PaginatedRequest & EventSessionRequest;
}

const EventSessionsList = createSlice({
    name: "EventSessionsList",
    initialState,
    reducers: {
        requestEventSessionsList(state, action: PayloadAction) {
            state.isBusy = true;
        },
        receiveEventSessionsList(
            state,
            action: PayloadAction<ReceiveEventSessionsListAction>
        ) {
            state.isBusy = false;
            state.error = action.payload.error;
            state.sessions = action.payload.sessions;
            state.eventId = action.payload.eventId;
            state.request = action.payload.request;
        }
    }
});

export default EventSessionsList.reducer;

const {
    requestEventSessionsList,
    receiveEventSessionsList
} = EventSessionsList.actions;


export const reloadEventSessions = (): AppThunk => async (
    dispatch,
    getState
) => {
    getState().eventSessionsList.request;

};
