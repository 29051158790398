import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { AppThunk } from ".";
import { AccessDomain, listEventAccessDomains } from "../api/AccessDomainApi";
import { PaginatedRequest } from "../api/common/PaginatedRequest";
import { AddEntitiesAction, addEntities } from "./Actions";
import { NormalizedPagination } from "./NormalizedPagination";
import { accessDomainSchema } from "./Schemas";

export interface AccessDomainsState {
    [key: string]: AccessDomain | {};

    pagination: {
        byEventId: NormalizedPagination;
    };
}

const initialState: AccessDomainsState = {
    pagination: { byEventId: {} }
};

interface RequestAccessDomainsByEventAction {
    eventId: number;
}

interface ReceiveAccessDomainsByEventAction {
    eventId: number;
    error?: string;
    pageIndex?: number;
    pageSize?: number;
    totalItemsCount?: number;
    ids?: string[];
}

const accessDomainListSlice = createSlice({
    name: "accessDomains",
    initialState,
    reducers: {
        RequestAccessDomainsByEvent(
            state,
            action: PayloadAction<RequestAccessDomainsByEventAction>
        ) {
            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [action.payload.eventId.toString()]: {
                    ...state.pagination.byEventId[
                        action.payload.eventId.toString()
                    ],
                    isFetching: true
                }
            };
        },

        ReceiveAccessDomainsByEvent(
            state,
            action: PayloadAction<ReceiveAccessDomainsByEventAction>
        ) {
            const { eventId, ...rest } = action.payload;

            state.pagination.byEventId = {
                ...state.pagination.byEventId,
                [eventId.toString()]: {
                    ...rest,
                    isFetching: false
                }
            };
        }
    },
    extraReducers: (builder => {
        builder.addCase(addEntities.type, (state, action: PayloadAction<AddEntitiesAction>) => {
            return {
                ...state,
                ...action.payload["accessDomains"]
            };
        })
    })
});

export default accessDomainListSlice.reducer;

const {
    RequestAccessDomainsByEvent,
    ReceiveAccessDomainsByEvent
} = accessDomainListSlice.actions;

export const fetchAccessDomainsListPaginated = (
    eventId: number,
    request: PaginatedRequest
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(RequestAccessDomainsByEvent({ eventId }));

        const result = await listEventAccessDomains(eventId, request);

        const normalizedResult = normalize(result.items, [accessDomainSchema]);

        dispatch(addEntities(normalizedResult.entities as any));

        dispatch(
            ReceiveAccessDomainsByEvent({
                eventId,
                ids: normalizedResult.result,
                pageIndex: result.meta.pageIndex,
                pageSize: result.meta.pageSize,
                totalItemsCount: result.meta.totalItemsCount
            })
        );
    } catch (e) {
        dispatch(
            ReceiveAccessDomainsByEvent({
                eventId,
                error: "e.toString()",
                ...getState().accessDomains.pagination.byEventId[
                    eventId.toString()
                ]
            })
        );
    }
};
