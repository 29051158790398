import PropTypes from "prop-types";
import * as React from "react";
import "./LoaderContainer.css";

const loaderContainerPropTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

type LoaderContainerProps = React.PropsWithChildren<{}>;

export const LoaderContainer: React.FC<LoaderContainerProps> = props => {
    return <div className="loader-container">{props.children}</div>;
};

LoaderContainer.propTypes = loaderContainerPropTypes;
