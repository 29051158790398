import emptyStateSpeakerImage from "assets/imgs/empty_state_speaker.svg";
import { EmptyListState } from "components/EmptyListState";
import { TableContactDetailRenderer } from "components/TableContactDetailRenderer";
import { TableFullNameRender } from "components/TableFullNameRenderer";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { FaCalendarAlt, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { CellProps, Column } from "react-table";
import { toast } from "react-toastify";
import { Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Presenter } from "../../api/Presenters";
import { deleteSpeakerById, getSpeakersWithoutEvent, Speaker } from "../../api/Speakers";
import { DefaultPageSize } from "../../components/Constants";
import { DataTable } from "../../components/DataTable";
import { MainCard } from "../../components/MainCard";
import { StringTruncate } from "../../components/StringTruncate";
import { ToastMessage } from "../../components/ToastMessages";
import { AddEditSpeakerFormModal, DefaultSpeakerFormValues } from "./AddEditSpeakerFormModal";
import { PaginatedResult } from "../../api/common/PaginatedResult";
import EditSpeakerSchedules from "./EditSpeakerSchedules";

export const ManageSpeakers: React.FC = props => {
  const [speakers, setSpeakers] = useState<PaginatedResult<Speaker> | null>(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [showEditSpeakerSchedule, setShowEditSpeakerSchedule] = useState(false);
  const [editSpeakerSchedule, setEditSpeakerSchedule] = useState<Speaker | null>(null);

  const [
    defaultEditValues,
    setDefaultEditValues
  ] = useState<DefaultSpeakerFormValues | null>(null);

  const [addEditSpeakerIsOpen, setAddEditSpeakerIsOpen] = useState(false);

  useEffect(() => {
    loadSpeakersData();
  }, [])

  const loadSpeakersData = () => {
    getSpeakersWithoutEvent({ PageIndex: pageIndex, Limit: pageSize }).then(data => setSpeakers(data));
  }

  const [speakerToDelete, setSpeakerToDelete] = useState<Speaker | null>(
    null
  );

  //  delete speaker
  const cancelDeleteSpeaker = useCallback(() => {
    setSpeakerToDelete(null);
  }, [setSpeakerToDelete]);

  const showSpeakerDeleteModal = (speaker: Speaker) =>
    setSpeakerToDelete(speaker);

  const handleSpeakerDelete = useCallback(async () => {
    if (!speakerToDelete) return;
    try {
      await deleteSpeakerById(speakerToDelete.id);
      toast.success(
        <ToastMessage>
          The Speaker &quot;
          {`${speakerToDelete.title || ""} ${speakerToDelete.firstName
          }
                    `}
          &quot; was deleted successfully.
        </ToastMessage>
      );
      setSpeakerToDelete(null);
      loadSpeakersData();
    } catch (e) {
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while deleting &quot;
          {`${speakerToDelete.title || ""} ${speakerToDelete.firstName
          }
                    `}
          &quot;.
        </ToastMessage>
      );
    }
  }, [speakerToDelete, setSpeakerToDelete]);

  const closeSpeakerAddEditModal = () => {
    setAddEditSpeakerIsOpen(false);
    setDefaultEditValues(null);

    loadSpeakersData();
  }
  // edit speaker
  const showSpeakerEditModal = (speaker: Speaker) => {
    if (!speaker) return;
    const { profilePicture, ...formValues } = speaker;
    setDefaultEditValues({
      profilePicturePreview: profilePicture
        ? profilePicture.fullUrl
        : undefined,
      ...formValues
    });
    setAddEditSpeakerIsOpen(true);
  };

  const showAddSpeakerModal = useCallback(() => {
    setDefaultEditValues(null);
    setAddEditSpeakerIsOpen(true);
  }, [setDefaultEditValues, setAddEditSpeakerIsOpen]);
  useEffect(() => {
    loadSpeakersData();
  }, [pageIndex, pageSize]);


  const [index, setIndex] = React.useState(0);

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: "#",
        accessor: "index",
        Cell: function renderIndexes(ctx: CellProps<Presenter>) {
          // const _i : number = index + 1;
          // setIndex(prevState =>prevState++);
          return (<strong> {ctx.cell.value} </strong>);
        },
      },
      {
        Header: "Full name",
        Cell: TableFullNameRender
      },
      {
        Header: "Bio",
        accessor: "bio",
        Cell: function renderBio(ctx) {
          if (!ctx.cell.value) return null;
          return (
            <StringTruncate
              text={ctx.cell.value}
              truncateSize={80}
            />
          );
        }
      },
      {
        Header: "Contact information",
        Cell: TableContactDetailRenderer
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableGrouping: false,
        Cell: function SpeakerActions(ctx) {
          return (
            <div>
              {ctx.row.original && (
                <>
                  <Button
                    color="primary"
                    outline={true}
                    onClick={() =>
                      showSpeakerEditModal(
                        ctx.row.original as Speaker
                      )
                    }
                  >
                    <FaEdit/>
                  </Button>{" "}
                  <Button
                    color="danger"
                    outline={true}
                    onClick={() =>
                      showSpeakerDeleteModal(
                        ctx.row.original as Speaker
                      )
                    }
                  >
                    <FaTrash/>
                  </Button>{" "}
                  <Button
                    color="success"
                    outline={true}
                    onClick={() => {
                      setShowEditSpeakerSchedule(true)
                      setEditSpeakerSchedule(ctx.row.original as Speaker)
                    }

                    }
                  >
                    <FaCalendarAlt/>
                  </Button>
                </>
              )}
            </div>
          );
        }
      }
    ],
    []
  );

  return (
    <div>
      <Helmet title="Speakers"/>

      <MainCard>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <span>Speakers </span>
            <div>
              <Button
                onClick={showAddSpeakerModal}
                color="primary"
              >
                Add new speaker <FaPlus/>
              </Button>
            </div>
          </div>
        </CardHeader>
        <DataTable
          columns={columns}
          data={!!speakers && speakers.items}
          emptyState={
            <EmptyListState
              image={emptyStateSpeakerImage}
              title={"You have no speakers yet"}
              callToActionButton={{
                text: "Add your first speaker now!",
                onClick: showAddSpeakerModal
              }}
            />
          }
          pagination={{
            show: true,
            onPageClicked: page => {
              setPageIndex(page);
            },
            pageIndex: pageIndex,
            pageSize: pageSize,
            totalItemsCount: speakers
              ? speakers.meta.totalItemsCount
              : 0
          }}
        />
      </MainCard>

      {addEditSpeakerIsOpen &&
      <AddEditSpeakerFormModal
          eventId={0}
          isOpen={addEditSpeakerIsOpen}
          toggle={closeSpeakerAddEditModal}
          defaultValue={defaultEditValues}
      />
      }
      <EditSpeakerSchedules
        open={showEditSpeakerSchedule}
        setOpen={setShowEditSpeakerSchedule}
        speaker={editSpeakerSchedule}

      />
      {speakerToDelete &&
      <Modal size="lg" toggle={cancelDeleteSpeaker} isOpen={true}>
          <ModalHeader toggle={cancelDeleteSpeaker}>
              Delete speaker
          </ModalHeader>
          <ModalBody>
              Are you sure you want to delete &quot;
            {speakerToDelete &&
            `${speakerToDelete.title || ""} ${speakerToDelete.firstName
            } ${speakerToDelete.middleName || ""} ${speakerToDelete.lastName
            }`}
              &quot;?
          </ModalBody>

          <ModalFooter>
              <Button onClick={cancelDeleteSpeaker}>
                  No, keep &quot;
                {speakerToDelete &&
                `${speakerToDelete.title || ""} ${speakerToDelete.firstName
                }`}
                  &quot;
              </Button>
              <Button color="danger" onClick={handleSpeakerDelete}>
                  Yes, Delete &quot;
                {speakerToDelete &&
                `${speakerToDelete.title || ""} ${speakerToDelete.firstName
                }`}
                  &quot;
              </Button>
          </ModalFooter>
      </Modal>
      }
    </div>
  );
};
