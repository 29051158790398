import PropTypes from "prop-types";
import * as React from "react";

interface StringTruncateProps {
    trailing?: React.ReactNode;
    truncateSize: number;
    alwaysShowTrailing?: boolean;
    text: string | null;
}

export const StringTruncate: React.FC<StringTruncateProps> = props => {
    const canTruncate = props.text && props.text.length > props.truncateSize;
    let truncatedString = props.text;
    if (canTruncate) {
        truncatedString = truncatedString!.substring(
            0,
            truncatedString!.lastIndexOf(" ", props.truncateSize)
        );
    }
    return (
        <span title={props.text || undefined}>
            {truncatedString}
            {canTruncate && props.trailing}
        </span>
    );
};

StringTruncate.defaultProps = {
    trailing: "...",
    alwaysShowTrailing: false
};

StringTruncate.propTypes = {
    trailing: PropTypes.node,
    alwaysShowTrailing: PropTypes.bool,
    text: PropTypes.string.isRequired,
    truncateSize: PropTypes.number.isRequired
};
