import Axios from "axios";

export const dashboardCounter = async () => {
  const result = await Axios.get(
    `/api/Dashboard/Counter`);
  return result.data;
}

export const dashboardPage = async (pageTypeId :number) => {
  const result = await Axios.get(
    `/api/Dashboard/HomePage`,{params:{pageTypeId}});
  return result.data;
}
export const updateDashboardPage = async (pageTypeId :number,content:any) => {
  const result = await Axios.put(
    `/api/Dashboard/UpdateHomePage`,{pageTypeId,...content});
  return result.data;
}

export const dashboardPageType = async () => {
  const result = await Axios.get(
    `api/Home/HomePageTypes`);
  return result.data;
}