import { getTaxesDropdownList, ITax } from 'api/TaxApi';
import { Loader } from 'components/Loader';
import { ToastMessage } from 'components/ToastMessages';
import TooltipHintComponent from 'components/TooltipHint/TooltipHintComponent';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { FaSpinner } from "react-icons/fa";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { nameof } from 'util/nameof';
import {
  AddJsonCourse,
  GetCourseById,
  GetProductCategoriesForCourses,
  IAddEditCourse,
  ICourse,
  UpdateJsonCourse
} from "../../api/CoursesApi";
import CKEditor from "../../components/SendEmail/CKEditor";
import { AddEditSessionForm, SpeakerSelectItem } from "../ManageEventSessions/AddEditSessionFormModal";
import { getSpeakersWithoutEvent, searchSpeakers, Speaker } from "../../api/Speakers";
import { FormatOptionLabelMeta } from "react-select/base";
import { StringTruncate } from "../../components/StringTruncate";
import "./AddEditCourse.css";
import { GetLocationsDropdownList, ILocationDropdownItem } from "../../api/LocationsApi";
import { DeleteProductImage, ProductCategory, UploadProductMedia } from "../../api/ProductsApi";
import { clearFileInput } from "../../util/formUtils";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment, { Moment } from "moment";

const initialCourse: IAddEditCourse = {
  name: "",
  description: undefined,
  content: "",
  courseCategoryId: null,
  productCategoryId: null,
  directLink: "",
  youtubeLink: "",
  vimeoLink: "",
  media: null,
  price: {
    amount: 0,
    taxId: 0,
    discount: 0,
    isDiscountPercentage: false,
    taxesIds: []
  },
  totalQTY: null,
  minQTYPerOrder: null,
  maxQTYPerOrder: null,
  isOnHomePage: false,
  locationsIds: [],
  isPublished: false,
  // selectedSpeakers: undefined,
};

const media = {
  directLink: "",
  youtubeLink: "",
  vimeoLink: "",
  media: null
}


export const AddEditCourse: React.FC<any> = ({ courseToEdit, isOpen, toggle }) => {
  const [course, setCourse] = useState<IAddEditCourse>(courseToEdit ? courseToEdit : initialCourse);
  const [productCategories, setProductCategories] = React.useState<ProductCategory[]>([]);

  const formikRef = useRef<FormikProps<any>>(null);
  const [selectedSpeakers, setSelectedSpeakers] = useState<any[]>([]);
  const [showQuantity, setShowQuantity] = useState(false);
  const [taxes, setTaxes] = React.useState<Array<{ value: number, label: string }>>([]);
  const [taxesData, setTaxesData] = React.useState<Array<ITax>>([]);

  const [actionInProgress, setActionInProgress] = useState(false);
  const [previewBannerUrl, setPreviewBannerUrl] = useState<string | null>(null);
  const [imagesChanged, setImagesChanged] = useState(false);

  const [locationsList, setLocationsList] = useState<ILocationDropdownItem[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);

  const [mediaType, setMediaType] = useState<string>('Images');
  const [content, setContent] = useState<any>("");

  const locationsFetching = () => {
    GetLocationsDropdownList().then(data => setLocationsList([...data])).catch(err => {
    });
  };


  // working with images context.

  const taxesFetching = () => {
    getTaxesDropdownList().then(data => {
      if (data) {

        const taxes = data.map((item: ITax) => {
          return {
            value: item.id,
            label: `${item.name} (${item.value}%)`
          };
        });
        setTaxesData(data);
        setTaxes(taxes);
      }
    })
  }

  const handleDeleteProductImage = (imageId: number | null) => {
    if (!imageId || !courseToEdit.id) return;
    DeleteProductImage(courseToEdit.id, imageId).then(res => {
      toast.success(
        <ToastMessage>
          Image Deleted successfully!
        </ToastMessage>
      );
    }).catch(err => {
      toast.error(
        <ToastMessage>
          Error deleting the image
        </ToastMessage>
      );
    })
  }

  const setProductCourseCategory = (option: any) => {
    const optionId = option.id;
    setCourse({ ...course, productCategory: option, productCategoryId: optionId });
    formikRef.current?.setFieldValue("ProductCategoryId", optionId);
  }


  const setCourseLocations = (locations: any) => {
    const locationsIds = locations.map((l: { id: any; }) => l.id);
    setCourse({ ...course, locationsIds: locationsIds })
    setSelectedLocations(locations)
  }
  // TODO: make speakers selection as separated component

  const mapSpeakerToSpeakerSelectItem = (
    speaker: Speaker
  ): SpeakerSelectItem => {
    return {
      label: `${speaker.title || ""} ${speaker.firstName
      } ${speaker.middleName || ""} ${speaker.lastName}`,
      value: speaker.id,
      bio: speaker.bio,
      profilePic: speaker.profilePicture || null
    };
  };


  const [selectSpeakerListState, setSelectSpeakerListState] = useState<SpeakerSelectItem[]>([]);

  const renderSpeakerOption =
    (
      option: SpeakerSelectItem,
      labelMeta: FormatOptionLabelMeta<SpeakerSelectItem>
    ) => (
      <div className="d-flex align-items-center">
        {option.profilePic ? (
          <img
            src={option.profilePic.fullUrl}
            width={30}
            height={30}
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        ) : (
          <div
            style={{ width: 30, height: 30 }}
            className="mx-1 rounded-circle float-left border bg-white"
          />
        )}
        <div>
          {option.label}
          <div className="text-muted small">
            <StringTruncate
              text={option.bio || ""}
              truncateSize={30}
            />
          </div>
        </div>
      </div>
    )

  // END TODO

  const categoriesFetching = () => {
    GetProductCategoriesForCourses().then(data => {
      if (data) {
        setProductCategories(data.items);
      }
    })
  };
  ;

  const speakersFetching = () => {
    getSpeakersWithoutEvent()
      .then(data => setSelectSpeakerListState(data.items.map(mapSpeakerToSpeakerSelectItem)))
  }
  

  const loadDependencies = () => {
    categoriesFetching();
    taxesFetching();
    speakersFetching();
    locationsFetching();
  }

  useEffect(() => {
    if (isOpen) {
      loadDependencies();
    }
    return () => {
      setCourse(initialCourse);
      setPreviewBannerUrl(null);
      setSelectedSpeakers([]);
      setContent("")
      setMediaType("Images")
    }
  }, [isOpen])


  useEffect(() => {
    if (courseToEdit && courseToEdit.id) {
      GetCourseById(courseToEdit.id).then(course => {
        const price = course.price;
        if (price.taxes && price.taxes.length > 0) {
          price.taxesIds = [...price.taxes.map((t: { id: any; }) => t.id)];
        }
        if (course.productCategory) course.productCategoryId = course.productCategory.id;

        if (course.speakers && course.speakers.length > 0) {
          setSelectedSpeakers(course.speakers.map(mapSpeakerToSpeakerSelectItem))
        }
        if (course.locations && course.locations.length > 0) {
          setSelectedLocations(course.locations)
        }
        setContent(course.content)
        if (course.media && course.media.fullUrl) {
          setPreviewBannerUrl(course.media.fullUrl)
          setMediaType("Images")
        } else {
          setPreviewBannerUrl(null)
        }

        if (course.directLink) setMediaType("Direct Link")
        if (course.youtubeLink) setMediaType("Youtube Link")
        setTimeout(() => {
          setCourse({ ...course, price: price })
        }, 0)
      }).catch(err => {
      })
    }


  }, [courseToEdit])


  const calcTotalPrice = () => {
    const _taxes: Array<ITax> = [];
    if (!course?.price) return;

    const amount = course.price.amount;
    const discount = course.price.discount;
    const discountPercentage = course.price.isDiscountPercentage;
    let totalTax = 0;
    if (course.price.taxesIds) {
      for (let i = 0; i < course.price.taxesIds.length; i++) {
        let _tax = taxesData.find((item: ITax) => item.id === course.price.taxesIds[i]);
        if (_tax)
          _taxes.push(_tax)
      }

      _taxes.forEach((tax: ITax) => {
        totalTax += tax.value;
      })
    }

    let totalPrice = amount;

    if (discount) {
      if (discountPercentage) totalPrice -= amount * discount / 100;
      else totalPrice -= discount;
    }

    if (totalTax > 0) {
      totalPrice = +(+totalPrice * (1 + (totalTax / 100))).toFixed(2);
    }


    const price = course.price;
    // price.amount = amount;
    price.totalPrice = totalPrice;

    setCourse({
      ...course,
      price: price
    })

  }

  const onChangeHandler = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value })
  }
  const [loading, setLoading] = React.useState<boolean>(false);

  const changeContent = (content: any) => {
    setContent(content);
    setCourse({ ...course, content: content })
  }

  const addCourseHandler = async (
    values: IAddEditCourse,
  ) => {
    const { ...courseRequest } = { ...course };
    // courseRequest.media = imagesFiles[0]
    // @ts-ignore
    delete courseRequest['selectedSpeakers'];
    courseRequest.courseCategoryId = 2;
    setActionInProgress(true);

    let submitPromise;
    let editCase = false;
    if (courseToEdit && courseToEdit.id) {
      editCase = true;
    }

    const newCourse: ICourse = {
      content: courseRequest.content,
      courseCategoryId: courseRequest.courseCategoryId,
      description: courseRequest.description,
      isOnHomePage: courseRequest.isOnHomePage,
      locationsIds: courseRequest.locationsIds ?? [],
      maxQTYPerOrder: courseRequest.maxQTYPerOrder,
      minQTYPerOrder: courseRequest.minQTYPerOrder,
      name: courseRequest.name,
      price: {
        amount: courseRequest.price.amount,
        discount: courseRequest.price.discount,
        isDiscountPercentage: courseRequest.price.isDiscountPercentage,
        taxesIds: courseRequest.price.taxesIds
      },
      productCategoryId: courseRequest.productCategoryId,
      isPublished: courseRequest.isPublished,
      totalQTY: courseRequest.totalQTY,
      directLink: mediaType === 'Direct Link' ? courseRequest.directLink !== "" ? courseRequest.directLink : null : null,
      youtubeLink: mediaType === 'Youtube Link' ? courseRequest.youtubeLink !== "" ? courseRequest.youtubeLink : null : null,
      vimeoLink: mediaType === 'Vimeo Link' ? courseRequest.vimeoLink !== "" ? courseRequest.vimeoLink : null : null,
      lessonsLink: courseRequest.lessonsLink,
      speakersIds: selectedSpeakers.map(s => s.value),
      availabilityEndDateTime: courseRequest.availabilityEndDateTime,
      availabilityStartDateTime: courseRequest.availabilityStartDateTime,
      endDateTime: courseRequest.endDateTime,
      startDateTime: courseRequest.startDateTime
    }

    if (editCase) {
      submitPromise = UpdateJsonCourse(courseToEdit.id, newCourse);
    } else {
      submitPromise = AddJsonCourse(newCourse);
    }

    submitPromise.then(resp => {
      if (imagesChanged && course.media) {


        UploadProductMedia(editCase ? courseToEdit.id : resp, mediaType === 'Images' ? [course.media] : []).then(r => {
        });
      }
      toast.success(
        <ToastMessage>
          Course &quot;{course.name}
          &quot; Added successfully.
        </ToastMessage>
      );
      toggle(true);
      setActionInProgress(false);
    }).catch(err => {
      setActionInProgress(false);
      toast.error(
        <ToastMessage type="Error">
          Something went wrong while Adding{" "}
          {course.name}, Please try again
          later.
        </ToastMessage>
      );
    });
  }

  const handleImagesChange =
    (e: React.ChangeEvent<HTMLInputElement>) => {

      if (!e.target.files || !formikRef.current) return;
      const file = e.target.files[0];
      if (!file) return;


      if (file.type.startsWith("image/")) {
        if (typeof FileReader !== "undefined") {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            const base64String = (fileReader.result as string)?.split(',')[1];
            setPreviewBannerUrl(fileReader.result as string);
            setCourse({ ...course, media: { file, base64: base64String } });
          };
          setImagesChanged(true);
          fileReader.readAsDataURL(file);
        } else {
          setPreviewBannerUrl(null);
        }
        formikRef.current
      } else {
        setPreviewBannerUrl(null);
      }
    }

  // @ts-ignore
  const form = (formProps: FormikProps<any>) => (
    <Form>
      <ModalBody>
        <FormGroup>
          <Label> Name* </Label>
          <Input
            name={nameof<IAddEditCourse>("name")}
            value={course.name}
            onChange={onChangeHandler}
          />
          <ErrorMessage
            name={nameof<IAddEditCourse>("name")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label> Description </Label>
          <Input
            name={nameof<IAddEditCourse>("description")}
            value={course.description}
            onChange={onChangeHandler}
          />
          <ErrorMessage
            name={nameof<IAddEditCourse>("description")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label> Lessons Link </Label>
          <Input
            name={nameof<IAddEditCourse>("lessonsLink")}
            value={course.lessonsLink}
            onChange={onChangeHandler}
          />
          <ErrorMessage
            name={nameof<IAddEditCourse>("lessonsLink")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>
        <Row>
          <Col sm={12} md={6} xl={6}>
            <FormGroup>
              <Label>Start Date Time</Label>
              <ReactDatetime
                value={course.startDateTime ? moment(course.startDateTime) : undefined}
                onChange={value => {
                  setCourse({
                      ...course,
                      startDateTime: moment(value).toISOString()
                    }
                  )
                }}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={6} xl={6}>
            <FormGroup>
              <Label>End Date Time</Label>
              <ReactDatetime
                value={course.endDateTime ? moment(course.endDateTime) : undefined}
                onChange={(value: Moment | string) => {
                  setCourse({
                      ...course,
                      endDateTime: moment(value).toISOString()
                    }
                  )
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} xl={6}>
            <FormGroup>
              <Label>Availability Start Date Time</Label>
              <ReactDatetime
                value={course.availabilityStartDateTime ? moment(course.availabilityStartDateTime) : undefined}
                onChange={value => {
                  setCourse({
                      ...course,
                      availabilityStartDateTime: moment(value).toISOString()
                    }
                  )
                }}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={6} xl={6}>
            <FormGroup>
              <Label> Availability End Date Time </Label>
              <ReactDatetime
                value={course.availabilityEndDateTime ? moment(course.availabilityEndDateTime) : undefined}
                onChange={(value: Moment | string) => {
                  setCourse({
                      ...course,
                      availabilityEndDateTime: moment(value).toISOString()
                    }
                  )
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label> Content </Label>

          <CKEditor
            content={content ?? ""}
            changeContent={changeContent}
          />
        </FormGroup>


        {/*<FormGroup>*/}
        {/*    <Label> Course Category </Label>*/}
        {/*    <Select*/}
        {/*        isMulti={false}*/}
        {/*        options={courseCategories}*/}
        {/*        value={course.courseCategory}*/}
        {/*        onChange={(o: any) => {*/}
        {/*            setCourseCategory(o);*/}
        {/*        }}*/}
        {/*        getOptionLabel={(option) => option.name}*/}
        {/*        getOptionValue={(option) => option.id + ""}*/}
        {/*    />*/}
        {/*    <ErrorMessage*/}
        {/*        name={nameof<IAddEditCourse>("courseCategoryId")}*/}
        {/*        render={error => (*/}
        {/*            <FormFeedback className="d-block">*/}
        {/*                {error}*/}
        {/*            </FormFeedback>*/}
        {/*        )}*/}
        {/*    />*/}
        {/*</FormGroup>*/}
        <FormGroup>
          <Label> Product Type </Label>
          <Select
            isMulti={false}
            options={productCategories}
            value={course.productCategory}
            onChange={(o: any) => {
              setProductCourseCategory(o);
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
          />
          <ErrorMessage
            name={nameof<IAddEditCourse>("productCategoryId")}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label>
            Speakers
          </Label>
          <Field
            render={({ field }: FieldProps) => (
              <AsyncSelect
                isClearable
                isMulti
                defaultOptions={selectSpeakerListState}
                value={selectedSpeakers}
                onChange={options => {
                  setSelectedSpeakers(options as any[]);
                }}
                className="form-control flex-2"
                placeholder="Select Customer"
                formatOptionLabel={renderSpeakerOption}
                loadOptions={async (val) => {
                  return (await searchSpeakers(val)).map(mapSpeakerToSpeakerSelectItem);
                }}

              />
              /*<Select
                {...field}
                isMulti={true}
                closeMenuOnSelect={false}
                value={selectSpeakerListState.filter(sp => selectedSpeakers.includes(sp.value))}
                onChange={options => {
                  const selected = options.map(op => op.value);
                  setSelectedSpeakers([...selected]);
                  formProps.setFieldValue(
                    nameof<AddEditSessionForm>(
                      "selectedSpeakers"
                    ),
                    options
                  )
                }
                }
                options={selectSpeakerListState}
                noOptionsMessage={s =>
                  "No options available for '" +
                  s.inputValue +
                  "'"
                }
                formatOptionLabel={renderSpeakerOption}
              />*/
            )}
            name={nameof<AddEditSessionForm>(
              "selectedSpeakers"
            )}
          />
          <ErrorMessage
            name={nameof<AddEditSessionForm>(
              "selectedSpeakers"
            )}
            render={error => (
              <FormFeedback className="d-block">
                {error}
              </FormFeedback>
            )}
          />
        </FormGroup>
        {course && course.productCategoryId === 6 &&
        <FormGroup>
            <Label>
                Locations
            </Label>
            <AsyncSelect
                isClearable
                isMulti
                defaultOptions={locationsList}
                value={selectedLocations}
                onChange={options => {
                  setCourseLocations(options)
                }}
                className="form-control flex-2"
                placeholder="Select Location"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                loadOptions={async (val) => {
                  return await GetLocationsDropdownList(val);
                }}

            />
          {/*<Select
                isMulti={true}
                options={locationsList}
                onChange={(o: any) => {
                  setCourseLocations(o);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />*/}
            <ErrorMessage
                name={"relatedProducts"}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}/>
        </FormGroup>
        }


        <FormGroup>
          <label> Course Images
            <TooltipHintComponent text="images of product will display in your site."/>
          </label>

          <InputGroup>

            {mediaType === "Images" &&
            <>
                <Input
                    type="file"
                    value={undefined}
                    accept="image/*"
                    onChange={handleImagesChange}
                />
                <ErrorMessage
                    name={nameof<IAddEditCourse>("media")}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}
                />
            </>
            }
            {mediaType === "Direct Link" &&
            <>
                <Input
                    value={course.directLink ?? ""}
                    name={nameof<IAddEditCourse>("directLink")}
                    onChange={onChangeHandler}
                />
                <ErrorMessage
                    name={nameof<IAddEditCourse>("directLink")}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}
                />
            </>
            }
            {mediaType === "Youtube Link" &&
            <>
                <Input
                    value={course.youtubeLink ?? ""}
                    name={nameof<IAddEditCourse>("youtubeLink")}
                    onChange={onChangeHandler}
                />
                <ErrorMessage
                    name={nameof<IAddEditCourse>("youtubeLink")}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}
                />
            </>
            }
            {mediaType === "Vimeo Link" &&
            <>
                <Input
                    value={course.vimeoLink ?? ""}
                    name={nameof<IAddEditCourse>("vimeoLink")}
                    onChange={onChangeHandler}
                />
                <ErrorMessage
                    name={nameof<IAddEditCourse>("vimeoLink")}
                    render={error => (
                      <FormFeedback className="d-block">
                        {error}
                      </FormFeedback>
                    )}
                />
            </>
            }
            <UncontrolledButtonDropdown>
              <DropdownToggle caret style={{ zIndex: 0 }}>
                {mediaType}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setMediaType("Images")
                }}>
                  Images
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setMediaType("Direct Link")
                }}>
                  Direct Link
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setMediaType("Youtube Link")
                }}>
                  Youtube Link
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setMediaType("Vimeo Link")
                }}>
                  Vimeo Link
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </InputGroup>


        </FormGroup>

        {/*<FormGroup>*/}
        {/*    <label> Course Images*/}
        {/*        <TooltipHintComponent text="images of product will display in your site."/>*/}
        {/*    </label>*/}

        {/*    <Input*/}
        {/*        type="file"*/}
        {/*        value={undefined}*/}
        {/*        accept="image/*"*/}
        {/*        onChange={handleImagesChange}*/}
        {/*    />*/}
        {/*    <ErrorMessage*/}
        {/*        name={nameof<IAddEditCourse>("media")}*/}
        {/*        render={error => (*/}
        {/*            <FormFeedback className="d-block">*/}
        {/*                {error}*/}
        {/*            </FormFeedback>*/}
        {/*        )}*/}
        {/*    />*/}

        {/*</FormGroup>*/}

        {previewBannerUrl && mediaType === "Images" && (
          <div
            className="rounded thumbnail img-container"
            style={{ height: 265, }}
          >
            <div className="d-none remove-container">
              <button
                onClick={e => {
                  setPreviewBannerUrl(null);
                  formikRef.current && formikRef.current.setFieldValue(
                    nameof<IAddEditCourse>("media"),
                    null
                  );
                  handleDeleteProductImage(course.media?.id)
                  clearFileInput(e);
                }}
                className="remove-btn btn btn-danger">
                <AiOutlineDelete/>
              </button>
            </div>
            <img
              src={previewBannerUrl}
              className="rounded thumbnail"
              style={{ height: 265, width: "100%" }}
            />
          </div>
        )}

        <Button
          className="my-4"
          block
          color="secondary"
          outline
          onClick={e => {
            e.preventDefault();
            setShowQuantity(!showQuantity)
          }}
        >
          {showQuantity ? "Hide" : "Add"} Quantity
        </Button>
        {showQuantity ? <>
            <FormGroup>
              <Label> Minimum Quantity Per Order </Label>
              <Input type="number" name={nameof<IAddEditCourse>('minQTYPerOrder')}
                     onChange={onChangeHandler}
                     value={course.minQTYPerOrder ?? ""}
              />
              <ErrorMessage
                name={nameof<IAddEditCourse>("minQTYPerOrder")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>

            <FormGroup>
              <Label> Maximum Quantity Per Order </Label>
              <Input type="number"
                     name={nameof<IAddEditCourse>('maxQTYPerOrder')}
                     onChange={onChangeHandler}
                     value={course.maxQTYPerOrder ?? ""}
              />
              <ErrorMessage
                name={nameof<IAddEditCourse>("maxQTYPerOrder")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>


            <FormGroup>
              <Label> Total Quantity </Label>
              <Input type="number"
                     name={nameof<IAddEditCourse>('totalQTY')}
                     onChange={onChangeHandler}
                     value={course.totalQTY ?? ""}
              />
              <ErrorMessage
                name={nameof<IAddEditCourse>("totalQTY")}
                render={error => (
                  <FormFeedback className="d-block">
                    {error}
                  </FormFeedback>
                )}
              />
            </FormGroup>
          </>
          : null
        }

        <FormGroup>
          <Label> Price* </Label>
          <Input type="number"
                 value={course.price?.amount}
                 onChange={e => {
                   e.preventDefault();
                   const value = e.target.value as unknown as number;
                   const price = course.price;
                   price.amount = value;
                   if (value <= 0) {
                     price.taxesIds = [];
                     price.discount = 0;
                   }
                   setCourse({
                     ...course,
                     price: price
                   });
                   setTimeout(() => {
                     calcTotalPrice();
                   }, 500);
                 }}/>
        </FormGroup>

        <FormGroup>
          <Label> Discount </Label>
          <InputGroup>
            <Input type="number"
                   max={course.price.amount}
                   disabled={!course?.price || course?.price.amount <= 0}
                   value={course?.price.discount}
                   onChange={e => {
                     e.preventDefault();
                     const value = e.target.value as unknown as number;
                     // if(!course?.price || value > course?.price?.amount) return;
                     const price = course.price;
                     price.discount = value;
                     setCourse({
                       ...course,
                       price: price
                     });
                     setTimeout(() => {
                       calcTotalPrice();
                     }, 500);

                   }}/>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                {course?.price && course.price.isDiscountPercentage === true ? '%' : 'Fixed amount'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setCourse((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = true;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);

                }}>
                  %
                </DropdownItem>
                <DropdownItem onClick={(e) => {
                  e.preventDefault();
                  setCourse((old: any) => {
                    if (!old || !old.price)
                      old = { ...old, price: {} };
                    old.price.isDiscountPercentage = false;
                    return old;
                  });
                  setTimeout(() => {
                    calcTotalPrice();
                  }, 500);

                }}>
                  Fixed amount
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </InputGroup>

        </FormGroup>

        <FormGroup>
          <Label> Taxes </Label>
          <Select
            isDisabled={!course.price || !course.price.totalPrice || course.price.totalPrice <= 0}
            value={course.price.taxesIds && taxes.filter(t => course.price.taxesIds.includes(t.value))}
            isMulti={true}
            options={taxes}
            onChange={(o: any) => {
              o = o as Array<{ label: string; value: number; }>;
              const taxesIds: Array<number> = [];
              if (o != null) {
                setCourse((old: any) => {
                  for (let i = 0; i < o.length; i++) {
                    const element = o[i].value;
                    taxesIds.push(element);
                  }
                  if (!old?.price)
                    old = { ...old, price: {} };
                  old.price.taxesIds = taxesIds;
                  return old;
                });
              } else {
                setCourse((old: any) => {
                  if (!old?.price?.taxesIds) {
                    old = { ...old, price: { ...old?.price, taxesIds: [] } };
                  } else {

                    old.price.taxesIds = [];
                  }

                  return old;
                });
              }

              setTimeout(() => {
                calcTotalPrice();
              }, 500);
            }}/>
        </FormGroup>

        <FormGroup>
          <Label> Total Price : {" "}
            {course.price?.amount && <b color="black">{course?.price?.totalPrice} SAR</b>}
          </Label>
          {/*<Input readOnly={true} value={course?.price?.totalPrice}/>*/}
        </FormGroup>
        <Row>
          <FormGroup switch>
            <Label style={{ marginRight: "5px" }}>Published</Label>

            <Input
              type="switch"
              name={nameof<IAddEditCourse>("isPublished")}
              id={"published"}
              checked={course.isPublished}
              onChange={e => {
                setCourse({ ...course, isPublished: e.target.checked })
              }}
              onBlur={formProps.handleBlur}
            />
          </FormGroup>
        </Row>

      </ModalBody>
      <ModalFooter className="space-between">
        <Button type="button" color="secondary" onClick={clearAndClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={actionInProgress}
        >
          {actionInProgress && (
            <span>
                            <FaSpinner className="icon-spin"/>
                        </span>
          )}{" "}
          Save
        </Button>
      </ModalFooter>
    </Form>
  );

  const clearAndClose = () => {
    setCourse(initialCourse)
    toggle();
  }
  return (
    <div>
      {
        loading ?
          <Loader isLoading={true}/>
          : null
      }
      <Modal unmountOnClose isOpen={isOpen}
             toggle={clearAndClose}
             size="lg">
        <ModalHeader toggle={clearAndClose}>{courseToEdit ? "Edit" : "Add"} Course</ModalHeader>
        <Formik
          innerRef={formikRef}
          onSubmit={addCourseHandler}
          // validationSchema={CourseFormValidation}
          initialValues={{}}>
          {form}
        </Formik>
      </Modal>
    </div>
  );
}