/* eslint-disable react/jsx-key */
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { FaInfoCircle, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import {
    Alert,
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from "reactstrap";
import * as yup from "yup";
import { ServerDefinedDnsRecord, getServerDefinedDnsRecords } from "../../api/ServerDefinedDnsRecordsApi";
import { ToastMessage } from "../../components/ToastMessages";
import { nameof } from "../../util/nameof";

import { addGlobalAccessDomain } from '../../api/AccessDomainApi';
interface AddNewAccessDomainModalProps {
    isOpen: boolean;
    close: () => any;

}

interface AddAccessDomainForm {
    domainName: string;
    [key: string]: any;
}

export const AddAccessDomainModal: React.FC<AddNewAccessDomainModalProps> = props => {
    const [serverDnsRecords, setServerDnsRecords] = React.useState<ServerDefinedDnsRecord[]>([]);
    const validationSchema = React.useMemo(() => yup.object().shape<AddAccessDomainForm>({
        domainName: yup.string().required("Domain name is required.")
    }), []);


    React.useEffect(() => {
        if (!props.isOpen) return;
        // TODO: handle error
        getServerDefinedDnsRecords()
            .then(dnsRecords => setServerDnsRecords(dnsRecords));
    }, [props.isOpen]);



    // submit handler = 

    const handleFormSubmit = React.useCallback((values: AddAccessDomainForm) => {
        addGlobalAccessDomain({ ...values })
            .then(_ => {
                toast.success(
                    <ToastMessage>
                        Access Domain &quot;{values.domainName}&quot; Added
                        successfully.
                    </ToastMessage>
                )
                props.close && props.close();
            })
            .catch(_ => {
                toast.error(
                    <ToastMessage type="Error">
                        Something went wrong while adding{" "}
                        &quot;{values.domainName}
                        &quot;, please try again later.
                    </ToastMessage>
                )
            })
            .then(_ => {
                // finally

            })
    }, [props.close]);


    const handleRenderForm = React.useCallback((formProps: FormikProps<AddAccessDomainForm>) => <Form>
        <ModalBody>

            <FormGroup>
                <Label>Domain</Label>
                <Field
                    name={nameof<AddAccessDomainForm>("domainName")}
                    render={({ field }: FieldProps) => <Input {...field} />}
                />
            </FormGroup>

        </ModalBody>
        <FormGroup>
            <ModalBody className="pb-0">
                <Alert color="primary">
                    <FaInfoCircle />{" "}
                    Please note that domain changes can take up
                    to 24-hours to take effect. Also, remember to remove old A records from your dns provider
                </Alert>
                <p>Add these A records to your domain by visiting your dns provider or registrar.</p>
            </ModalBody>

            <Table>
                <thead>
                    <tr>
                        <th>Record type</th>
                        <th>Host</th>
                        <th>Value</th>
                    </tr>
                </thead>

                <tbody>
                    {serverDnsRecords.map(dnsRecord => <tr>
                        <td>{dnsRecord.recordType}</td>
                        <td>{dnsRecord.host}</td>
                        <td>{dnsRecord.value}</td>
                    </tr>)}
                </tbody>
            </Table>
        </FormGroup>

        <ModalFooter>
            <Button
                type="button"
                color="secondary"
                onClick={props.close}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                color="primary"
                disabled={formProps.isSubmitting}
            >
                {formProps.isSubmitting && (
                    <span>
                        <FaSpinner className="icon-spin" />
                    </span>
                )}{" "}
                Connect domain
            </Button>{" "}
        </ModalFooter>

    </Form>, [serverDnsRecords]);

    return (<Modal isOpen={props.isOpen} size="lg" unmountOnClose>
        <ModalHeader toggle={props.close}>Connect new domain</ModalHeader>

        <Formik
            initialValues={{ domainName: "" }}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            render={handleRenderForm} />
    </Modal>);
}