/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-deprecated */
import * as React from 'react';
import Helmet from "react-helmet";
import { CardBody, CardHeader } from "reactstrap";
import { deleteWebinar, getWebinarList, IWebinarList } from "../../api/WebinarApi";

import moment from "moment";
import { toast } from "react-toastify";
import noData from '../../assets/imgs/video_call.svg';
import { Loader } from "../../components/Loader";
import { ToastMessage } from "../../components/ToastMessages";
import { Data_Format_EMS } from "../../types/date-format-type";
import AddRegistrantsModel from "./AddRegistrantsModel";
import CreateWebinarMeetingModal from "./CreateWebinarMeetingModal";
import DeleteWebinarModal from "./DeleteWebinarModal";
import ManageRegistrantsModel from "./ManageRegistrantsModel";
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { MdVideoCall } from 'react-icons/md';
import {MainCard} from "../../components/MainCard";

interface IWebinarListState {
    data: Array<IWebinarList>,
    loading: boolean,
    openCreateModal: boolean,
    openDeleteModel: boolean,
    deleteModal?: IWebinarList | null,
    openAddRegistrantModal: boolean,
    addRegistrantWebinarId: number,
    displayRegistrants: boolean,
    displayRegistrantsWebinarId: number

}
class ManageWebinarMeetings extends React.Component<any, IWebinarListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            openCreateModal: false,
            openDeleteModel: false,
            deleteModal: null,
            openAddRegistrantModal: false,
            addRegistrantWebinarId: 0,
            displayRegistrants: false,
            displayRegistrantsWebinarId: 0
        }
    }





    componentWillMount() {
        this.fetchData();
    }


    openRegistrantModalHandler = (id: number) => {
        this.setState({ openAddRegistrantModal: true, addRegistrantWebinarId: id });
    }

    closeRegistrantModalHandler = () => {
        this.setState({ openAddRegistrantModal: false, addRegistrantWebinarId: 0 });
    }

    fetchData = () => {
        this.setState({ loading: true })
        getWebinarList({ pageIndex: 1, limit: 20 }).then(data => {
            this.setState({ loading: false })

            if (data) {
                this.setState({ data: data.items })
            }
        })
    }

    openCreateModalToggle = () => {
        const { openCreateModal } = this.state;
        this.setState({ openCreateModal: !openCreateModal })
    }


    deleteWebinarHandler = (id: number) => {
        this.setState({ loading: true })
        deleteWebinar(id).then(res => {
            this.setState({ loading: false })
            toast.success(
                <ToastMessage>
                    Deleted Successfully !
                </ToastMessage>
            );
            this.fetchData();
        }).catch(error => {
            this.setState({ loading: true })
            toast.error(
                <ToastMessage>
                    Error has been happened
                </ToastMessage>
            )
        })
    }

    openDeleteMeetingModal = (deleteModal: any) => {
        if (!deleteModal) return;
        this.setState({ openDeleteModel: true, deleteModal })
    }

    cancelDeletingHandler = () => {
        this.setState({ openDeleteModel: false, deleteModal: null })
    }

    openDisplayRegistrantsModal = (webinarId: number) => {
        this.setState({ displayRegistrants: true, displayRegistrantsWebinarId: webinarId })
    }

    closeDisplayRegistrantsModal = () => {
        this.setState({ displayRegistrants: false, displayRegistrantsWebinarId: 0 })

    }
    render() {
        return (
            <>

                {
                    this.state.openDeleteModel && this.state.deleteModal ?
                        <DeleteWebinarModal cancelDeleting={this.cancelDeletingHandler}
                            onUpdated={this.fetchData}
                            webianrToDelete={this.state.deleteModal} />
                        : null
                }

                {
                    this.state.openCreateModal ?
                        <CreateWebinarMeetingModal
                            onUpdated={this.fetchData}
                            isOpen={this.state.openCreateModal}
                            close={this.openCreateModalToggle}
                        />
                        : null
                }
                <Helmet title="Webinar Meeting" />
                <MainCard>
                    <CardHeader className={"space-between"}>
                        Webinar Meeting
                        <button
                            onClick={this.openCreateModalToggle}
                            className={"btn btn-primary"}>
                            Create Webinar Meeting
                            <span className={'mx-1'}>
                                <MdVideoCall />
                            </span>
                        </button>
                    </CardHeader>

                    <CardBody>
                        {
                            this.state.loading ? <Loader isLoading={true} /> : null
                        }
                        {
                            this.state.data.length > 0 ?

                                <table className={'table table-sm-responsive table-striped'}>
                                    <thead>
                                        <tr>
                                            <td>
                                                SN
                                            </td>
                                            <td>
                                                Topic
                                            </td>
                                            <td>
                                                Start date
                                            </td>
                                            <td>
                                                End date
                                            </td>
                                            <td>
                                                Meeting Link
                                            </td>
                                            <td>
                                                Registants
                                            </td>
                                            <td>
                                                Actions
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td> {item.index} </td>
                                                        <td> {item.topic} </td>
                                                        <td>
                                                            {moment(item.startDate).format(Data_Format_EMS)}
                                                        </td>
                                                        <td>
                                                            {moment(item.endDate).format(Data_Format_EMS)}
                                                        </td>
                                                        <td>
                                                            <a href={item.meetingUrl} target={"_blank"} rel="noreferrer">
                                                                Link
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.openDisplayRegistrantsModal(item.id);
                                                                }}
                                                                className={'btn btn-secondary'}>
                                                                Display
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.openRegistrantModalHandler(item.id);

                                                                }}
                                                                className={'btn btn-outline-primary mx-1'}>
                                                                <AiOutlineUsergroupAdd />
                                                            </button>
                                                            <button
                                                                onClick={e => {
                                                                    this.openDeleteMeetingModal(item);
                                                                }}
                                                                className={"btn btn-outline-danger mx-1"}>
                                                                <FaTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                : !this.state.loading ? <div className={'text-center'}>
                                    <img src={noData} width={'256'} alt={'no data'}/>
                                    <div className={'mt-4'}>
                                        <h5> You haven't create webinar meeting yet !  </h5>
                                        <small> connect with your team using webinar </small>
                                    </div>
                                </div> : null
                        }
                    </CardBody>
                </MainCard>

                {
                    this.state.openAddRegistrantModal ?

                        <AddRegistrantsModel isOpen={this.state.openAddRegistrantModal} close={this.closeRegistrantModalHandler} webinarId={this.state.addRegistrantWebinarId} />
                        :
                        null
                }
                {
                    this.state.displayRegistrants ?
                        <ManageRegistrantsModel isOpen={this.state.displayRegistrants}
                            close={this.closeDisplayRegistrantsModal}
                            webinarId={this.state.displayRegistrantsWebinarId} />
                        : null
                }
            </>
        );
    }
}

export default ManageWebinarMeetings;